import {CollectionClass} from "../decorators/database/collectionClass";
import {ModelBase} from "./modelBase";
import {IndexField} from "../decorators/database/indexField";
import {ServerField} from "../decorators/database/serverField";

@CollectionClass({name: "raws", revisionEnabled: false, draftEnabled: false})
export class Raw extends ModelBase<Raw> {

  @IndexField() referenceId: string;
  @IndexField() uniqueId: string;
  referenceCollection: string;

  meta;
  @ServerField input;
  @ServerField output;
  @ServerField indicatorOutput;
  extra;

  constructor(doc?, draftFlag?: boolean) {
    super(doc, draftFlag);
    this.init(doc, this.draftFlag);
  }

  getSecuredDoc(): Raw {
    let obj: Raw = super.getSecuredDoc();
    obj.referenceId = this.referenceId;
    obj.referenceCollection = this.referenceCollection;

    return obj;
  }

  getMetaProvider() {
    if (this.meta) {
      return this.meta.provider;
    }
  }
}
