export const loginInterstitialProvider = {
  removeInfo: 'removeInfo',
} as const;

type LoginInterstitialProviderType = typeof loginInterstitialProvider[keyof typeof loginInterstitialProvider];

export const loginInterstitialClickMode = {
  accept: 'accept',
  decline: 'decline',
} as const;

type LoginInterstitialClickModeType = typeof loginInterstitialClickMode[keyof typeof loginInterstitialClickMode];

export const loginInterstitialPaymentResult = {
  success: 'success',
  fail: 'fail',
} as const;

type LoginInterstitialPaymentResultType = typeof loginInterstitialPaymentResult[keyof typeof loginInterstitialPaymentResult];

export class LoginInterstitialHelper {
  private static readonly type: string = 'loginInterstitial';

  static makeLIViewTrackParam(provider: LoginInterstitialProviderType) {
    return {
      type: LoginInterstitialHelper.type,
      action: 'view',
      provider,
      section: 'LI',
    };
  }

  static makeLIClickTrackParam(provider: LoginInterstitialProviderType, mode: LoginInterstitialClickModeType) {
    return {
      type: LoginInterstitialHelper.type,
      action: 'click',
      provider,
      section: 'LI',
      mode,
    };
  }

  static makePaymentViewTrackParam(provider: LoginInterstitialProviderType, skip: boolean) {
    return {
      type: LoginInterstitialHelper.type,
      action: 'view',
      provider,
      section: 'payment',
      mode: skip ? 'skip' : 'perform',
    };
  }

  static makePaymentSubmitTrackParam(provider: LoginInterstitialProviderType) {
    return {
      type: LoginInterstitialHelper.type,
      action: 'submit',
      provider,
      section: 'payment',
    };
  }

  static makePaymentTransactionTrackParam(provider: LoginInterstitialProviderType, result: LoginInterstitialPaymentResultType) {
    return {
      type: LoginInterstitialHelper.type,
      action: 'transaction',
      provider,
      section: 'payment',
      result,
    };
  }
}