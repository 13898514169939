import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewEncapsulation, NgModule } from '@angular/core';

export enum Format {
  Outlined = 'Outlined',
  Filled = 'Filled',
  Plain = 'Plain',
  NoWrap = 'Nowrap'
}

export enum Size {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
}

@Component({
  selector: 'ux-5-2-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  host: {
    class: 'ux-5-2-button',
  },
  encapsulation: ViewEncapsulation.None,
})
export class ButtonComponent implements OnInit, OnChanges {
  @Input() format: Format = null;
  @Input() size: Size;
  @Input() buttonClass = '';
  @Input() color: 'primary' | 'default' = 'primary';
  @Input() disabled = false;
  @Input() width: 'full' | 'default' = 'default';
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.size?.firstChange && changes.format?.firstChange) {
      const classes = [];
      if (this.format && Format[this.format]) classes.push(Format[this.format]);
      if (this.size && Size[this.size]) classes.push(Size[this.size]);
      this.buttonClass += classes.join(' ');
    }
  }

  ngOnInit(): void {}
}

// @NgModule({
//   declarations: [ButtonComponent],
//   imports: [CommonModule],
//   exports: [ButtonComponent],
// })
// export class ButtonComponentModule {}
