// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.member-upsell-premium-badge {
  display: inline-flex;
  background-color: #5B92DB;
  border-radius: 6px;
  align-items: center;
  font-size: 12px;
  line-height: 32px;
  color: white;
  font-weight: 400;
  padding: 0 0 0 8px;
}
.member-upsell-premium-badge__icon {
  width: 32px;
  height: 32px;
}
.member-upsell-premium-badge__icon img {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/app/member/components/upsells/premium-badge/premium-badge.component.scss"],"names":[],"mappings":"AAAA;EACI,oBAAA;EACA,yBAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;AACJ;AACI;EACI,WAAA;EACA,YAAA;AACR;AACQ;EACI,cAAA;AACZ","sourcesContent":[".member-upsell-premium-badge {\n    display: inline-flex;\n    background-color: #5B92DB;\n    border-radius: 6px;\n    align-items: center;\n    font-size: 12px;\n    line-height: 32px;\n    color: white;\n    font-weight: 400;\n    padding: 0 0 0 8px;\n\n    &__icon {\n        width: 32px;\n        height: 32px;\n\n        img {\n            display: block;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
