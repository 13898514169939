import { Injectable } from '@angular/core';
import { LogUtils } from '../../common/utils/logUtils';
import { User } from '../../common/models/user/user';
import { JsonService } from './json.service';
import { RequestEvent } from '../../common/event/requestEvent';
import { ResponseEvent } from '../../common/event/responseEvent';
import { serverPaths } from '../../common/helpers/pathHelpers';
import { TrackingService } from './tracking.service';
import { CookieService } from './cookie.service';
import { ServiceHelperService } from './serviceHelper.service';
import { UxComposite } from '../../common/models/ux/uxComposite';
import { commerceUtils } from "../../common/utils/commerceUtils";
import { BehaviorSubject} from 'rxjs';


@Injectable()
export class AuthenticationService {
  storageKey = 'currentUser';

  private accountAuthUrl = '/api/account/authenticate';
  private manageAuthUrl = '/api/manage/authenticate';
  private passwordUpdateUrl = '/' + serverPaths.accountActionUpdatePassword;
  private user = new BehaviorSubject<User>(this.getUser())

  /**
   *
   * @param jsonService to access localStorage for managing token etc.
   * @param cookieService to set or access the cookies
   * @param trackingService to track the different auth event
   */
  constructor(private jsonService: JsonService,
    private cookieService: CookieService,
    private trackingService: TrackingService) {
  }

  /**
   *
   * @returns boolean for the session flag from the local storage
   */
  getSessionFlag(): boolean {
    return this.jsonService.getSessionFlag();
  }

  /**
   * remove the current user info from the localStorage
   */
  clearUser() {
    localStorage.removeItem(this.storageKey);
    this.jsonService.clearToken();
  }

  /**
   * this set the the provided user info in the local storage
   * @param user info to be stored in the local stroage
   */
  setUser(user, sessionFlag: boolean) {
    this.clearUser();
    localStorage.setItem(this.storageKey, JSON.stringify(user));
  }

  //Adding this to update the user in storage when topBanner is disabled
  updateUser(user) {
    localStorage.setItem(this.storageKey, JSON.stringify(user))
    this.user.next(user);
  }

  /**
   * check if we have token, then it return user info from local storage or session Storage if its not in localStorage
   * @returns the user info stored in localStorage/sessionStorage
   */
  getUser(): User {
    let user = null;
    if (this.jsonService.hasToken()) {
      let userJson = localStorage.getItem(this.storageKey);
      if (!userJson) {
        userJson = sessionStorage.getItem(this.storageKey);
      }
      if (userJson) {
        user = new User(JSON.parse(userJson));
      }
    }
    return user;
  }

  getUserAsObservable() {
    this.user.next(this.getUser());
    return this.user.asObservable()
  }

  /**
   *
   * @returns the userId by first getting the user from localStorage and extracting the id from it
   */
  getUserId(): string {
    const user = this.getUser();

    if (user) {
      return user._id;
    }
  }

  /**
   * this login the user by setting it info in localStorage
   * @param user intacne have user info like email, phone etc
   * @param sessionFlag boolean for session to be set along side token
   */
  public loginAsUser(user: User, sessionFlag: boolean) {
    this.setUser(user, sessionFlag);
    this.jsonService.setToken(user.tempClient.jsonToken, sessionFlag);
    this.trackingService.setTrackUser(user);
  }

  /**
   *
   * @param url account authentication url
   * @param brandId of the brand for which user have signed in.
   * @param username unqiue username provided by user
   * @param password password provided by the user
   * @param roles allowed for the user to be logged in
   * @param sessionFlag boolean for session to be set along side token
   */
  private login(url: string, brandId: string, username: string, password: string, roles: string[], sessionFlag: boolean): Promise<any> {
    // set the params for login API request
    const requestEvent = new RequestEvent();
    requestEvent.param = { brandId, username, password, roles };

    // call to the login API
    return this.jsonService.json(url, requestEvent).then((responseEvent: ResponseEvent) => {
      if (responseEvent.isSuccess()) {
        const docs = responseEvent.getDocs();
        let user = null;
        // check if we get the valid User instacne from login API response
        docs.forEach((obj) => {
          if (obj instanceof User) {
            if (obj && obj.isValid(roles)) {
              user = obj;
            }
          }
        });

        if (user) {
          // loggin user by storing its info in browser Storage
          this.loginAsUser(user, sessionFlag);
          return Promise.resolve(docs);
        } else {
          return Promise.reject(false);
        }
      } else {
        return Promise.reject(false);
      }
    }).catch((e) => {
      if (commerceUtils.isNotAuthorizedRequestError(e)) {
        LogUtils.debug(e);
      } else {
        LogUtils.error(e);
      }

      return Promise.reject(e);
    });
  }

  /**
   *
   * @param brandId of the brand for which user have signed in.
   * @param username unqiue username provided by user
   * @param password password provided by the user
   * @param roles allowed for the user to be logged in
   * @param sessionFlag boolean for session to be set along side token
   * @param recaptchaToken google recaptha token for the site verify on backend
   * @param preFailed boolean for the recaptcha
   */
  loginCustomer(brandId: string, username: string, password: string, sessionFlag: boolean): Promise<any> {
    return this.login(this.accountAuthUrl, brandId, username, password, [User.ROLES.customer], sessionFlag);
  }

  /**
   * This is to login the user with admin role
   * @param username unqiue username provided by user
   * @param password password provided by the user
   */
  loginManager(username: string, password: string): Promise<any> {
    return this.login(this.manageAuthUrl, 'admin', username, password, null, true).then((docs) => {
      let user = null;
      docs.forEach((doc) => {
        if (doc instanceof User) {
          user = doc;
        }
      });

      if (user.isValid([User.ROLES.admin]) || user.isValid([User.ROLES.csr])) {
        return user;
      } else {
        return Promise.reject(false);
      }
    });
  }

  // this clear the user from browser storage to logout
  logout() {
    this.clearUser();
  }

  /**
   * This fucntion is to login the user via url along with hash
   * @param serviceHelperService will provide access to different required services such as authentication service, uxc service etc.
   * @param url account authentication url
   * @param hash for the logging in via url
   */
  private loginLink(serviceHelperService: ServiceHelperService, url: string, hash: string): Promise<any> {
    // set the params for login API request
    const requestEvent = new RequestEvent();
    // calll to login API
    return this.jsonService.json(`${url}/${hash}`, requestEvent).then((responseEvent: ResponseEvent) => {
      if (responseEvent.isSuccess()) {
        const user: User = null;
        const uxComposite: UxComposite = null;
        responseEvent.getDocs().forEach((obj) => {
          if (obj instanceof User) {
            this.loginAsUser(obj, true);
          } else if (obj instanceof UxComposite) {
            serviceHelperService.uxcService.setUxComposite(obj);
          }
        });

        return Promise.resolve(user);
      } else {
        return Promise.reject(false);
      }
    }).catch((e) => {
      if (commerceUtils.isNotAuthorizedRequestError(e)) {
        LogUtils.debug(e);
      } else {
        LogUtils.error(e);
      }
      return Promise.reject(e);
    });
  }

  /**
   * This one is to login the user via url
   * @param serviceHelperService will provide access to different required services such as authentication service, uxc service etc.
   * @param hash for the logging in via url
   */
  loginLinkCustomer(serviceHelperService: ServiceHelperService, hash): Promise<any> {
    return this.loginLink(serviceHelperService, serverPaths.loginLinkLogin, hash);
  }

  /**
   * This function is to send login link to the customers on their emails
   * @param email of the user to send the login link
   * @param brandId for the brand on which user have signed up
   */
  sendLoginLink(email, brandId): Promise<any> {
    const requestEvent = new RequestEvent();
    requestEvent.param = { email, brandId };
    return this.jsonService.json(serverPaths.loginLinkSendEmail, requestEvent).then((responseEvent: ResponseEvent) => {
      LogUtils.debug(responseEvent);
    }).catch((e) => {
      if (commerceUtils.isNotAuthorizedRequestError(e)) {
        LogUtils.debug(e);
      } else {
        LogUtils.error(e);
      }
      return Promise.reject(e);
    });
  }

  /**
   *
   * @param url of an api to update the password
   * @param oldPassword old password provided by the user
   * @param newPassword new password provided by the user
   */
  private updatePassword(url, oldPassword, newPassword): Promise<any> {
    const requestEvent = new RequestEvent();
    requestEvent.param = { oldPassword, newPassword };

    return this.jsonService.json(url, requestEvent).then((responseEvent: ResponseEvent) => {
      if (responseEvent.isSuccess()) {
        /*
        let user = responseEvent.getSingleDoc();
        if (user && user.isValid()) {
          this.loginAsUser(user, this.getSessionFlag());
          return user;
        } else {
          //Login Failed
          return Promise.reject(false);
        }
        */
      } else {
        return Promise.reject(false);
      }
    }).catch((e) => {
      LogUtils.error(e);
      return Promise.reject(e);
    });
  }

  /**
   * @param oldPassword old password provided by the user
   * @param newPassword new password provided by the user
   */
  updatePasswordCustomer(oldPassword, newPassword): Promise<any> {
    return this.updatePassword(this.passwordUpdateUrl, oldPassword, newPassword);
  }

  /**
   * check if user is logged in or not
   * @returns boolean, by cheking if the user info is stored in local storage
   */
  isLoggedIn() {
    return !!this.getUser();
  }
}
