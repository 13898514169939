import { LogUtils } from "./logUtils";

export class FireAndForget {
    request(url: string, requestOptions: Request) {
        fetch(url, requestOptions).catch((err) => {
            debugger;
            LogUtils.error(err);
        });
    }
    method(func: Function, ...args) {
        setTimeout(() => func(...args), 0);
    }
}

export const fireAndForgetUtils = new FireAndForget();

// e.g. fireAndForgetUtils.request('url', new Request(''));
