import {ModelBase} from "../modelBase";
import {CollectionClass} from "../../decorators/database/collectionClass";
import {CommerceProduct, CommerceProductDetail} from "./commerceProduct";
import {ForeignKeyField} from "../../decorators/database/foreignKeyField";
import {TransientField} from "../../decorators/database/transientField";
import {ForeignObjectField} from "../../decorators/database/foreignObjectField";
import {ReferenceField} from "../../decorators/database/referenceField";
import {CommercePrice} from "./commercePrice";
import {IndexField} from "../../decorators/database/indexField";
import {User} from "../user/user";
import {CompositeClassField} from "../../decorators/database/compositeClassField";

export class CommerceOfferDetail {
  commerceOfferId: string;
  commerceOfferRevisionId?: string;
  price: { code: string, amount: number, options };
  commerceProductDetails: CommerceProductDetail[] = [];

  constructor(doc?) {
    if (doc) {
      Object.assign(<any>this, doc);
    }
  }

  getProductIds(): string[] {
    return this.commerceProductDetails.map((commerceProductDetail) => commerceProductDetail.commerceProductId);
  }

  hasProducts(productIds: string[]) {
    let currentIds = this.getProductIds();
    let matchIds = [];
    productIds.forEach((productId) => {
      if (currentIds.indexOf(productId) !== -1) {
        matchIds.push(productId);
      }
    });

    return matchIds;
  }
}

@CollectionClass({name: "commerceOffers"})
export class CommerceOffer extends ModelBase<CommerceOffer> {

  public static TYPE = {
    item: "product",
    refer: "refer",
  };

  public static PROVIDES = {
    membership: "membership",
  };

  @IndexField() name: string;
  description: string;
  extName: string;
  extDesc: string;

  @IndexField() @ForeignKeyField(User) payeeId: string;
  @IndexField() @ReferenceField(CommerceProduct) @ForeignKeyField(CommerceProduct) commerceProductIds: string[] = [];
  @TransientField @ForeignObjectField("commerceProductIds") commerceProducts: CommerceProduct[];
  commerceProductDetails: CommerceProductDetail[] = [];


  @CompositeClassField(CommercePrice) commercePrice: CommercePrice = new CommercePrice();
  combinable: boolean = false; // can be combined in transaction
  provides: string[] = [];
  category;
  nonMemberOnly = true; // only non-member can order this offer
  contentTypeMultiple = false; //  can order same contentType while previous order is in active state
  options: any = {};

  constructor(doc?, draftFlag?: boolean) {
    super(doc, draftFlag);
    this.init(doc, this.draftFlag);
  }

  getSecuredDoc(): CommerceOffer {
    var obj: CommerceOffer = super.getSecuredDoc();

    obj.name = this.name;
    obj.description = this.description;
    obj.contentTypeMultiple = this.contentTypeMultiple;
    obj.commerceProductIds = this.commerceProductIds;
    obj.commerceProductDetails = this.commerceProductDetails;
    obj.commercePrice = this.commercePrice;
    obj.combinable = this.combinable;
    obj.nonMemberOnly = this.nonMemberOnly;
    obj.extName = this.extName;
    obj.extDesc = this.extDesc;
    obj.options = this.options;

    return obj;
  }

  isCombinable(): boolean {
    return !!this.combinable;
  }

  getCommerceProduct(commerceProductId: string): CommerceProduct {
    return <CommerceProduct>this.getObjById("commerceProducts", commerceProductId);
  }
}

