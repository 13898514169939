import {Component, Input, OnChanges, OnInit} from "@angular/core";
import {UxHelper} from "../../../../../clientCommon/helper/uxHelper";
import {LoaderPeopleSearch} from "../../../loaderPeopleSearch";
import {UxComposite} from "../../../../../common/models/ux/uxComposite";

@Component({
  selector: 'particle-1-loader-name-search',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css'],
})
export class Particle1LoaderNameSearchComponent implements OnInit, OnChanges {
  @Input() base: LoaderPeopleSearch;

  @Input() uxHelper: UxHelper;
  @Input() pageType: string;
  @Input() pageCategory: string;
  @Input() page: string;

  constructor() {
  }

  init() {
  }

  ngOnInit() {
    return this.init();
  }

  ngOnChanges() {
    return this.init();
  }
}
