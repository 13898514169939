import { Component, NgModule } from '@angular/core';

@Component({
  selector: 'member-upsell-court-features',
  templateUrl: './court-features.component.html',
})
export class MemberUpsellCourtFeaturesComponent {}

@NgModule({
  declarations: [MemberUpsellCourtFeaturesComponent],
  exports: [MemberUpsellCourtFeaturesComponent],
})
export class MemberUpsellCourtFeaturesComponentModule {}
