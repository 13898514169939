import { animate, state, style, transition, trigger } from "@angular/animations";
import { AfterViewInit, Component, Input, OnDestroy } from "@angular/core";
import { BaseComponent } from "@clientCommon/components/BaseComponent";
import { UxHelper } from "@clientCommon/helper/uxHelper";
import { ServiceHelperService } from "@clientCommon/services/serviceHelper.service";
import { LogUtils } from "@common/utils/logUtils";

@Component({
  selector: 'app-privacy-policy-agreement-modal',
  templateUrl: './privacyPolicyAgreementModal.component.html',
  styleUrl: './privacyPolicyAgreementModal.component.scss',
  animations: [
    trigger('slide', [
      state('closed', style({ transform: 'translate(-50%) translateY(-100vh)', opacity: 0, display: 'none' })),
      state('open', style({ transform: 'translate(-50%) translateY(0)', opacity: 1, display: 'block' })),
      transition('closed <=> open', [
        animate('0.5s ease'),
      ]),
    ]),
    trigger('fade', [
      state('hidden', style({ opacity: 0, display: 'none' })),
      state('visible', style({ opacity: 1, display: 'block' })),
      transition('closed <=> open', [
        animate('0.5s ease'),
      ]),
    ]),
  ],
})
export class PrivacyPolicyAgreementModalComponent implements AfterViewInit, OnDestroy {
  @Input() uxHelper: UxHelper;

  constructor(
    private serviceHelperService: ServiceHelperService,
  ) {}

  show: boolean = false;

  checked: boolean = false;

  timer;

  themeClass: string = "ux1";

  showHost: boolean = true;

  readonly titleSectionKey = 'comp.sale.general.privacyPolicyAgreementModal.title';
  
  readonly firstSectionKey = 'comp.sale.general.privacyPolicyAgreementModal.first';
  
  readonly secondSectionKey = 'comp.sale.general.privacyPolicyAgreementModal.second';
  
  readonly thirdSectionKey = 'comp.sale.general.privacyPolicyAgreementModal.third';
  
  private readonly enableKey = 'comp.sale.general.privacyPolicyAgreementModal.enable';

  private readonly sessionKey = 'privacyPolicyAgreement';

  private readonly agreed = '1';

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setThemeClass();
  
      this.show = this.enabled();
  
      if (!this.show) {
        this.showHost = false;
      }
    });
  }

  ngOnDestroy(): void {
    try {
      clearTimeout(this.timer);
    } catch (e) {}
  }

  agree() {
    if (this.checked) {
      return;
    }

    this.checked = true;

    sessionStorage.setItem(this.sessionKey, this.agreed);

    this.trackingAgree();

    this.timer = setTimeout(() => {
      this.show = false;
    }, 1000);
  }

  private enabled(): boolean {
    if (this.uxHelper.pageType !== BaseComponent.PAGE_TYPE.sales) {
      return false;
    }

    if (sessionStorage.getItem(this.sessionKey) === this.agreed) {
      return false;
    }

    const setting = this.uxHelper.getValue(this.enableKey);

    return setting?.[this.uxHelper.page] === true;
  }

  private setThemeClass() {
    const theme = this.uxHelper.getTheme();
    
    const themeString: string = typeof theme === 'string' ? theme : '';

    if (themeString.startsWith('ux_2')) {
      this.themeClass = 'ux2';
    } else if (themeString.startsWith('ux_3')) {
      this.themeClass = 'ux3';
    } else if (themeString.startsWith('ux_5')) {
      this.themeClass = 'ux5';
    } else {
      this.themeClass = 'ux1';
    }
  }

  private trackingAgree() {
    try {
      const param = {
        page: this.uxHelper.page,
        pageType: this.uxHelper.pageType,
        action: 'agree',
        method: 'form',
        data: {
          uxcomps: {
            [this.titleSectionKey]: {
              id: this.uxHelper.uxComposite.ids[this.titleSectionKey],
              revisionId: this.uxHelper.uxComposite.revisions[this.titleSectionKey],
            },
            [this.firstSectionKey]: {
              id: this.uxHelper.uxComposite.ids[this.firstSectionKey],
              revisionId: this.uxHelper.uxComposite.revisions[this.firstSectionKey],
            },
            [this.secondSectionKey]: {
              id: this.uxHelper.uxComposite.ids[this.secondSectionKey],
              revisionId: this.uxHelper.uxComposite.revisions[this.secondSectionKey],
            },
            [this.thirdSectionKey]: {
              id: this.uxHelper.uxComposite.ids[this.thirdSectionKey],
              revisionId: this.uxHelper.uxComposite.revisions[this.thirdSectionKey],
            },
          },
        },
      };

      this.serviceHelperService.trackingService.report(param);
    } catch (e) {
      LogUtils.error('PrivacyPolicyAgreementModalComponent.trackingAgree Error', e);
    }
  }
}
