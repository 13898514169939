export const Benefits = [
  { label: 'Find Current Addresses', icon: 'assets/ux5/address-icon/address-icon.svg' },
  { label: 'See Criminal Records', icon: 'assets/ux5/crinimal-report/crinimal-report.svg' },
  { label: 'Get Marriage Records', icon: 'assets/ux5/marriage-record/marriage-record.svg' },
  { label: 'Get Phone Numbers', icon: 'assets/ux5/phone-icon/phone-icon.svg' },
  { label: 'View Social Profiles', icon: 'assets/ux5/social-profile-icon/social-profile-icon.svg' },
  { label: 'Locate Family Members', icon: 'assets/ux5/family-icon/family-icon.svg' },
  { label: 'Find Email Addresses', icon: 'assets/ux5/email-icon/email-icon.svg' },
  { label: 'View Online Photos', icon: 'assets/ux5/online-photos-icon/online-icon.svg' },
  { label: 'See Employment Info', icon: 'assets/ux5/employment-icon/employment.svg' },
];
