import {
    ChangeDetectorRef,
    Component,
    Output,
    SimpleChanges,
    EventEmitter,
    Input,
    ViewChild,
    ElementRef,
    Renderer2,
  } from '@angular/core';
  import { ActivatedRoute, Router } from '@angular/router';
  import { ServiceHelperService } from '../../../../../../clientCommon/services/serviceHelper.service';
  import { PersonDetail } from '../../../personDetail';
  import { ToastrService } from '../../../../../service/toastr.service';
  import { BaseComponent } from '../../../../../../clientCommon/components/BaseComponent';
  import { peopleSearchProductKeys } from '../../../../../../common/custom/peopleSearch/peopleSearchProductKeys';
  import { AuthenticationService, NoteService } from '../../../../../../clientCommon/services';
  
  declare var $: any;
  
  @Component({
    template: '',
    standalone: false
})
  export class MiniCriminalRecordsBaseComponent extends PersonDetail  {
    href = '';
  
    @Input() isPdfPrintView: boolean = false;
    @Output() showLoader = new EventEmitter<void>();
    showAllOffenses: boolean = false;
    showAllTrafficViolation: boolean = false;
    showAllSexOffender: boolean = false;
  
    @ViewChild('scrollToTop', { static: true })
    scrollToTopBtnElement: ElementRef;
    
    model: any = {
      fName: '',
      lName: '',
      city: '',
      state: '',
      age: '',
      number: '',
      emailAddress: '',
    };
  
    tcpaConsentAndPrivacy =
      'TCPA Consent & Privacy: Notwithstanding any current or prior election to opt in or opt out of receiving telemarketing calls or SMS messages (including text messages) from us, our agents, representatives, affiliates, or anyone calling on our behalf, you expressly consent to be contacted by us, our agents, representatives, affiliates, or anyone calling on our behalf for any and all purposes arising out of or relating to your loan and/or account, at any telephone number, or physical or electronic address you provide or at which you may be reached. You agree we may contact you in any way, including SMS messages (including text messages), calls using prerecorded messages or artificial voice, and calls and messages delivered using auto telephone dialing system or an automatic texting system. Automated messages may be played when the telephone is answered, whether by you or someone else. In the event that an agent or representative calls, he or she may also leave a message on your answering machine, voice mail, or send one via text.. You consent to receive SMS messages (including text messages), calls and messages (including prerecorded and artificial voice and autodialed) from us, our agents, representatives, affiliates or anyone calling on our behalf at the specific number(s) you have provided to us, or numbers we can reasonably associate with your account (through skip trace, caller ID capture or other means), with information or questions about your application, loan and/or account.';
    ageList = [];
    criminalOffenseList = []; //this.mockCriminalOffenseList;
    trafficViolationList = []; //this.mockTrafficViolationList;
    sexOffenderList = []; //this.mockSexOffenderList;
    additionalResource = {
      summary: `We have curated the very best resources to get even more Life Event
      Records, including marriages, divorces, death records, birth
      certificates. Select below for more details`,
      arr: [
        {
          imgSrc: 'assets/ux1/images/criminal-3.png',
          url: 'https://www.vinelink.com/',
        },
        {
          imgSrc: 'assets/ux1/images/criminal-2.png',
          url: 'https://pacer.uscourts.gov/',
        },
        {
          imgSrc: 'assets/ux1/images/criminal-1.png',
          url: 'https://www.jailbase.com/',
        },
        {
          imgSrc: 'assets/ux1/images/united-states-courts.png',
          url: 'https://www.uscourts.gov/',
        },
      ],
    };
    showAllCriminalOffenses: boolean = false;
    criminalOffenseArr: Array<Object> = [];
    showMore: any;
    showLess: any;
  
    constructor(
      public serviceHelperService: ServiceHelperService,
      public changeDetectorRef: ChangeDetectorRef,
      activatedRoute: ActivatedRoute,
      protected router: Router,
      public renderer: Renderer2,
      public toastr: ToastrService,
      public noteService: NoteService,
      public authService: AuthenticationService
    ) {
      super(serviceHelperService, activatedRoute, toastr, noteService, authService);
    }
  
    ngOnInit() {
      window.scrollTo(0, 0);
      this.showAllCriminalOffenses = this.isPdfPrintView;
      this.showAllOffenses = this.isPdfPrintView;
      this.showAllSexOffender = this.isPdfPrintView;
      this.showAllTrafficViolation = this.isPdfPrintView;
  
      // creating ageList for dropdown
      let g = 18;
      while (g > 17 && g < 60) {
        this.ageList.push(g);
        g++;
      }
      // ! splitting criminal records as traffic, sex and crime.
      // if(this.person.criminalRecords.length > 0) {
      //   this.person.criminalRecords.forEach((criminalRecord, index) => {
      //     if(criminalRecord.category && criminalRecord.category.toUpperCase().startsWith('TRAFFIC')){
      //       this.trafficViolationList.push(criminalRecord);
      //     } else if (criminalRecord.category && criminalRecord.category.toUpperCase().startsWith('SEX')){
      //       // !TODO : crosscheck if sex offender data is proper
      //       this.sexOffenderList.push(criminalRecord);
      //     } else {
      //       this.criminalOffenseList.push(criminalRecord);
      //     }
      //   });
      // }
  
      //sex offender list
      this.sexOffenderList = this.person.criminalRecords.filter((item) => {
        return !this.isEmptyObject(item.sexOffender);
      });
  
      //filter sex offender list from offense list
      this.criminalOffenseList = this.person.criminalRecords.filter((item) => {
        return this.isEmptyObject(item.sexOffender);
      });
      this.getCriminalList();
      // this.criminalOffenseArr = this.criminalOffenseList.reduce((c, v) => c.concat(v), []).map(o => o.id);
      // if(this.person.criminalRecords.length > 0) {
      // if(this.person.criminalRecords[0].offense && this.person.criminalRecords[0].offense.length > 0){
  
      //   this.person.criminalRecords[0].offense.forEach((criminalRecord, index) => {
      //     if(criminalRecord.category && criminalRecord.category.toUpperCase().startsWith('TRAFFIC')){
      //       this.trafficViolationList.push(criminalRecord);
      //     } else {
      //       this.criminalOffenseList.push(criminalRecord);
      //     }
      //   });
      // }
      // }
      this.href = this.router.url;
  
      return this.init().then(() => {
        this.serviceHelperService.trackingService.report({
          page: BaseComponent.PAGE.detail,
          pageType: BaseComponent.PAGE_TYPE.member,
          type: peopleSearchProductKeys.nameSearch,
          section: 'criminalRecords',
          action: 'view',
        });
        this.changeDetectorRef.detectChanges();
      });
    }
    ngAfterViewInit() {
      // Register scroll to top event listener.
      const screenHeight = window.screen.availHeight;
      window.onscroll = () => {
        if (document.body.scrollTop > screenHeight || document.documentElement.scrollTop > screenHeight) {
          this.renderer.setStyle(this.scrollToTopBtnElement.nativeElement, 'display', 'flex');
        } else {
          this.renderer.setStyle(this.scrollToTopBtnElement.nativeElement, 'display', 'none');
        }
      };
    }
    scrollToTopHandler() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
    ngOnDestroy() {
      return super.onDestroy();
    }
  
    ngOnChanges(changes: SimpleChanges): void {}
  
    init() {
      // Tooltip init
      setTimeout(() => {
        $('.tooltip-container').tooltip({
          html: true,
          trigger: 'click',
        });
      }, 0);
      return super.init();
    }
  
    handleLoader() {
      this.showLoader.emit();
    }
    switchShowAllOffenses() {
      this.showAllOffenses = !this.showAllOffenses;
    }
    switchShowAllTrafficViolation() {
      this.showAllTrafficViolation = !this.showAllTrafficViolation;
    }
    switchShowAllSexOffender() {
      this.showAllSexOffender = !this.showAllSexOffender;
    }
    switchShowAllCriminalOffenses() {
      this.showAllCriminalOffenses = !this.showAllCriminalOffenses;
    }
    getTotalRecords(recordArr) {
      let totalRecords = 0;
      recordArr.forEach((item, index) => {
        totalRecords += item.offense.length;
      });
      return totalRecords;
    }
  
    isEmptyObject(obj) {
      return obj && Object.keys(obj).length === 0;
    }
  
    getCriminalList() {
      this.criminalOffenseArr = this.criminalOffenseList.reduce(
        (r, o) => r.concat(o['offense'].map((v) => ({ ...o, ['offense']: v }))),
        []
      );
    }
    showMoreDetails(index) {
      this.showMore = index;
      this.showLess = -1;
    }
    hideMoreDetails(index) {
      this.showLess = index;
      this.showMore = -1;
    }
  }

  