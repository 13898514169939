import { Step } from '@ux5.2/types';

export const STEPS: Step[] = [
  {
    time: 9.99,
    component: 'popular-uses',
    title: 'Searching Millions of Records for ',
  },
  {
    time: 18.99,
    component: 'media-scan',
    title: 'Searching Millions of Records for ',
  },
  {
    time: 27.99,
    component: 'records-review',
    title: 'Searching Millions of Records for ',
  },
  {
    time: 36.99,
    component: 'contact-information',
    title: 'Saving Results for ',
  },
  {
    time: 44.99,
    component: 'completing-results',
    title: 'Completing Results for ',
  },
  {
    time: 54.99,
    component: 'completing-results',
    title: 'Completing Results for ',
  },
  {
    time: 63.99,
    component: 'completing-results',
    title: 'Completing Results for ',
  },
  {
    time: 72.99,
    component: 'completing-results',
    title: 'Completing Results for ',
  },
  {
    time: 81.99,
    component: 'completing-results',
    title: 'Completing Results for ',
  },
  {
    time: 90.99,
    component: 'completing-results',
    title: 'Completing Results for ',
  },
  { time: 100, component: 'monitoring', title: 'Preparing Monitoring for ' },
];
