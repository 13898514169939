import { Component, Input, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ReportReviewResults } from '@ux5.2/types';

@Component({
  selector: 'ux-5-2-completing-results',
  templateUrl: './completing-results.component.html',
  styleUrls: ['./completing-results.component.scss'],
})
export class CompletingResultsComponent implements OnInit {
  @Input() data: ReportReviewResults = null;

  constructor(private viewportScroller: ViewportScroller) {}

  ngOnInit(): void {
    this.viewportScroller.scrollToPosition([0, 0]);
  }
}
