import { Component, OnDestroy, OnInit, Input, EventEmitter, Output, NgModule } from '@angular/core';
import { UxHelper } from '../../../../../../src/clientCommon/helper/uxHelper';
import { CommonModule } from '@angular/common';

declare const $: any;

@Component({
    selector: 'member-upsell-premium-badge',
    templateUrl: './premium-badge.component.html',
    styleUrls: ['./premium-badge.component.scss'],
    standalone: false
})
export class MemberUpsellPremiumBadgeComponent implements OnInit, OnDestroy {
  @Input() uxHelper: UxHelper;
  enhancedflag = false;
  bonusflag = false;

  ngOnInit() {
    const upsellnameflag = this.uxHelper.uxComposite.get('comp.billing.enhanced.name.enabled');

    this.bonusflag = upsellnameflag.bonus;
    this.enhancedflag = upsellnameflag.enhanced;
  }

  ngOnDestroy() {}
}

@NgModule({
  declarations: [MemberUpsellPremiumBadgeComponent],
  imports: [CommonModule],
  exports: [MemberUpsellPremiumBadgeComponent],
})
export class MemberUpsellPremiumBadgeComponentModule {}
