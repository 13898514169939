import {Injectable} from "@angular/core";
import {NavigationStart, Router} from "@angular/router";
import {BehaviorSubject} from "rxjs";

@Injectable()
export class AlertService {
  // definations for all the properties required for alert
  TYPE = {
    success: "success",
    fail: "fail",
    error: "error",
  };
  POSITION = {
    top: "top",
    bottom: "bottom",
  };

  count = 0;
  subject: BehaviorSubject<any>;
  messages: {
    id: number,
    message: string,
    type: string,
    timeout: number,
    keep: boolean,
    position: string,
    class?: string,
  }[] = [];
  delay = 100;
  easeDuration = 500;

  constructor(private router: Router) {
    this.subject = new BehaviorSubject(this.messages);

    // clear alert message on route change
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
      }
    });
  }

  text(message, timeout: number = 20000, keep = true) {

  }

  /**
   *
   * @param message success message to dispaly
   * @param timeout timeout for the success message alert
   * @param keep boolean to keep it visible or not, by default true
   * @param position alert message on screen position, by default true
   */
  success(message: string, timeout: number = 5000, keep = true, position = this.POSITION.bottom) {
    this.addMessage(this.TYPE.success, message, timeout, keep, position);
  }

    /**
   *
   * @param message error message to dispaly
   * @param timeout timeout for the error message alert
   * @param keep boolean to keep it visible or not, by default true
   * @param position alert message on screen position, by default true
   */
  error(message: string, timeout: number = 5000, keep = true, position = this.POSITION.bottom) {
    this.addMessage(this.TYPE.error, message, timeout, keep, position);
  }

  /**
   *
   * @param type of the message, success/error
   * @param message to dispaly on success/error
   * @param timeout timeout for the message alert
   * @param keep boolean to keep it visible or not, by default true
   * @param position alert message on screen position, by default true
   */
  addMessage(type, message, timeout, keep, position) {
    this.count++;
    let id = this.count;

    // This add the message at the start of the messsages array if postion is top other wise push at the end of the messages array
    if (position === this.POSITION.top) {
      this.messages.unshift({id: id, message: message, type: type, timeout: timeout, keep: keep, position: position, class: "ready"});
    } else {
      this.messages.push({id: id, message: message, type: type, timeout: timeout, keep: keep, position: position, class: "ready"});
    }

    // update to alert messsage behaviorSubject
    this.subject.next(this.messages);

    // timeouts to the set the messages show, done class or remove the message after the delays & timeouts
    setTimeout(() => {
      this.messages.some((m, index) => {
        if (m.id === id) {
          m.class = "show";
          return true;
        }
      });
    }, this.delay);
    setTimeout(() => {
      this.messages.some((m, index) => {
        if (m.id === id) {
          m.class = "done";
          return true;
        }
      });
    }, timeout);
    setTimeout(() => {
      this.messages.some((m, index) => {
        if (m.id === id) {
          this.messages.splice(index, 1);
          return true;
        }
      });
    }, timeout + this.easeDuration);
  }

}
