import {Component, Input, OnChanges, OnInit} from "@angular/core";
import {UxHelper} from "../../../../../clientCommon/helper/uxHelper";
import {LandingPeopleSearch} from "../../../../peopleSearch/landingPeopleSearch";

type utmParamValueType = {
  header1: string;
  header2: string;
}

@Component({
  selector: 'ux-2-0-landing-name-search',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class Ux2Dot0LandingNameSearchComponent implements OnInit, OnChanges {
  @Input() base: LandingPeopleSearch;

  @Input() uxHelper: UxHelper;
  @Input() pageType: string;
  @Input() pageCategory: string;
  @Input() page: string;
  @Input() utmParamValue: utmParamValueType;

  constructor() {
  }

  init() {
  }

  ngOnInit() {
    return this.init();
  }

  ngOnChanges() {
    return this.init();
  }
}
