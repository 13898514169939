import { Component, ElementRef, Input, NgModule, ViewEncapsulation } from '@angular/core';

export type ButtonSize = 'sm' | 'md' | 'lg';
export type Color = 'primary' | 'secondary' | 'danger' | 'info' | 'dark';

const BUTTON_HOST_ATTRIBUTES = ['cos-button', 'cos-icon-button', 'cos-stroked-button', 'cos-flat-button'];

// TODO: work in progress

@Component({
  selector:
    'button[cos-button], button[cos-icon-button], button[cos-stroked-button], button[cos-flat-button], a[cos-button], a[cos-flat-button]',
  template: '<ng-content></ng-content>',
  styleUrls: ['./button.component.scss'],
  host: {
    '[attr.data-cos-button-color]': 'color',
    '[attr.data-cos-button-size]': 'size',
  },
  encapsulation: ViewEncapsulation.None,
})
export class CosButtonComponent {
  @Input() size: ButtonSize = 'md';
  @Input() color: Color = 'primary';

  constructor(private readonly _elementRef: ElementRef) {
    for (const attr of BUTTON_HOST_ATTRIBUTES) {
      if (this._hasHostAttributes(attr)) {
        (this._getHostElement() as HTMLElement).classList.add(attr);
      }
    }
  }

  protected _getHostElement() {
    return this._elementRef.nativeElement;
  }

  /** Gets whether the button has one of the given attributes. */
  private _hasHostAttributes(...attributes: string[]) {
    return attributes.some((attribute) => this._getHostElement().hasAttribute(attribute));
  }
}

// @NgModule({
//   declarations: [CosButtonComponent],
//   exports: [CosButtonComponent],
// })
// export class CosButtonModule {}
