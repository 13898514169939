// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-content .close {
  position: relative;
  padding: 10px;
  z-index: 100;
}

::ng-deep .user-hidden {
  color: #525252;
  -webkit-user-select: none;
          user-select: none;
  filter: blur(6px);
}

::ng-deep .sms-headline {
  text-decoration: underline;
  line-height: 16px;
  color: #404040;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 11px;
}

#trust-seals {
  border-top: 2px solid #dddddd;
  margin-top: 10px;
}

.seal-wrapper .customer-privacy {
  margin: 15px 30px;
  width: 90px;
}

.seal-wrapper .encrypted-connection img {
  float: left;
  margin-right: 10px;
  width: 48px;
}

.seal-wrapper .encrypted-connection {
  margin-top: 30px;
  width: 200px;
}`, "",{"version":3,"sources":["webpack://./src/app/peopleSearch/templates/nameSearch/default/signup.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,YAAA;AACF;;AAGE;EACE,cAAA;EACA,yBAAA;UAAA,iBAAA;EACA,iBAAA;AAAJ;;AAKE;EACE,0BAAA;EACA,iBAAA;EACA,cAAA;EACA,iBAAA;EACA,eAAA;EACA,mBAAA;AAFJ;;AAOA;EACE,6BAAA;EACA,gBAAA;AAJF;;AAMA;EACE,iBAAA;EACA,WAAA;AAHF;;AAKA;EACE,WAAA;EACA,kBAAA;EACA,WAAA;AAFF;;AAIA;EACE,gBAAA;EACA,YAAA;AADF","sourcesContent":[".modal-content .close {\n  position: relative;\n  padding: 10px;\n  z-index: 100;\n}\n\n::ng-deep {\n  .user-hidden {\n    color: #525252;\n    user-select: none;\n    filter: blur(6px);\n  }\n}\n\n::ng-deep {\n  .sms-headline {\n    text-decoration: underline;\n    line-height: 16px;\n    color: #404040;\n    font-weight: bold;\n    font-size: 16px;\n    margin-bottom: 11px;\n  }\n}\n\n\n#trust-seals { \n  border-top: 2px solid #dddddd; \n  margin-top: 10px; \n} \n.seal-wrapper .customer-privacy { \n  margin: 15px 30px; \n  width: 90px; \n} \n.seal-wrapper .encrypted-connection img { \n  float: left; \n  margin-right: 10px; \n  width: 48px; \n} \n.seal-wrapper .encrypted-connection { \n  margin-top: 30px; \n  width: 200px; \n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
