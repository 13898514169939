import {customClientPaths} from "../../common/custom/customPathHelpers";
import {CustomBaseComponent} from "./customBaseComponent";

export class CustomPageHelper {

  /**
   * @param pageType page can be of sales or member site etc.
   * @param pageCategory page may belong to categories like nameSearch,phoneSearch etc.
   * @param page page name
   * @returns route url for from customClientPath after checking the pageInfo against each available pageType, category and pageName
   */
  getPageUrl(pageType, pageCategory, page) {
    let url;
    if (pageType === CustomBaseComponent.PAGE_TYPE.sales) {
      if (pageCategory === CustomBaseComponent.PAGE_CATOGORY.nameSearch) {
        if (page === CustomBaseComponent.PAGE.landing) {
          url = customClientPaths.salesNameSearchLanding;
        } else if (page === CustomBaseComponent.PAGE.loader) {
          url = customClientPaths.salesNameSearchLoader;
        } else if (page === CustomBaseComponent.PAGE.searchResult) {
          url = customClientPaths.salesNameSearchSearchResult;
        } else if (page === CustomBaseComponent.PAGE.reportReview) {
          url = customClientPaths.salesNameSearchReportReview;
        } else if (page === CustomBaseComponent.PAGE.signup) {
          url = customClientPaths.salesNameSearchSignup;
        }
      } else if (pageCategory === CustomBaseComponent.PAGE_CATOGORY.phoneSearch) {
        if (page === CustomBaseComponent.PAGE.landing) {
          url = customClientPaths.salesPhoneSearchLanding;
        } else if (page === CustomBaseComponent.PAGE.loader) {
          url = customClientPaths.salesPhoneSearchLoader;
        } else if (page === CustomBaseComponent.PAGE.searchResult) {
          url = customClientPaths.salesPhoneSearchSearchResult;
        } else if (page === CustomBaseComponent.PAGE.reportReview) {
          url = customClientPaths.salesPhoneSearchReportReview;
        } else if (page === CustomBaseComponent.PAGE.signup) {
          url = customClientPaths.salesPhoneSearchSignup;
        }
      }
    } else if (pageType === CustomBaseComponent.PAGE_TYPE.member) {
      if (pageCategory === CustomBaseComponent.PAGE_CATOGORY.nameSearch) {
        if (page === CustomBaseComponent.PAGE.landing) {
          url = customClientPaths.memberNameSearchLanding;
        } else if (page === CustomBaseComponent.PAGE.loader) {
          url = customClientPaths.memberNameSearchLoader;
        } else if (page === CustomBaseComponent.PAGE.searchResult) {
          url = customClientPaths.memberNameSearchSearchResult;
        } else if (page === CustomBaseComponent.PAGE.reportReview) {
          url = customClientPaths.memberNameSearchReportReview;
        } else if (page === CustomBaseComponent.PAGE.process) {
          url = customClientPaths.memberNameSearchProcess;
        }
      } else if (pageCategory === CustomBaseComponent.PAGE_CATOGORY.phoneSearch) {
        if (page === CustomBaseComponent.PAGE.landing) {
          url = customClientPaths.memberPhoneSearchLanding;
        } else if (page === CustomBaseComponent.PAGE.loader) {
          url = customClientPaths.memberPhoneSearchLoader;
        } else if (page === CustomBaseComponent.PAGE.searchResult) {
          url = customClientPaths.memberPhoneSearchSearchResult;
        } else if (page === CustomBaseComponent.PAGE.reportReview) {
          url = customClientPaths.memberPhoneSearchReportReview;
        } else if (page === CustomBaseComponent.PAGE.process) {
          url = customClientPaths.memberPhoneSearchProcess;
        }
      } else if (pageCategory === CustomBaseComponent.PAGE_CATOGORY.removeInfo) {
        if (page === CustomBaseComponent.PAGE.landing) {
          url = customClientPaths.memberRemoveInfoLanding;
        } else if (page === CustomBaseComponent.PAGE.loader) {
          url = customClientPaths.memberRemoveInfoLoader;
        } else if (page === CustomBaseComponent.PAGE.searchResult) {
          url = customClientPaths.memberRemoveInfoSearchResult;
        } else if (page === CustomBaseComponent.PAGE.signup) {
          url = customClientPaths.memberRemoveInfoSignup;
        }
      }
    }
    return url;
  }
}

// creating singleHelper instance for CustomPageHelper class, so it can be imported and accessed directly wherever needed
const customPageHelper = new CustomPageHelper();
export default customPageHelper;
