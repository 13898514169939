import { Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ReportReviewPeopleSearchSales } from '../../reportReviewPeopleSearchSales';
import { BaseComponent } from '../../../../../clientCommon/components/BaseComponent';
import { ServiceHelperService } from '../../../../../clientCommon/services/serviceHelper.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  templateUrl: './reportReview.component.html',
})

/**
 * ReportReviewNameSearchSalesComponent render the respective theme/layout component based on current theme
 * @extends ReportReviewPeopleSearchSales which further extends ReportReviewPeopleSearch to get all required properties
 */
export class ReportReviewNameSearchSalesComponent extends ReportReviewPeopleSearchSales implements OnInit, OnDestroy {
  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;

  /**
   *
   * @param serviceHelperService will provide access to different required services such as authentication service, uxc service etc.
   * @param activatedRoute provides access to information about a route associated with a component that is loaded in an outlet.
   *
   */
  constructor(serviceHelperService: ServiceHelperService, activatedRoute: ActivatedRoute, private _cfr: ComponentFactoryResolver) {
    super(serviceHelperService, activatedRoute);
    this.pageCategory = BaseComponent.PAGE_CATOGORY.nameSearch;
  }

  ngOnInit() {
    return this.onInit().then(() => {
      this.initDone = true;
      // if (this.theme === this.themes.ux_5_2) {
      //   this._loadUx5_2ReportReview();
      // }
    });
  }

  ngOnDestroy() {
    return super.onDestroy();
  }

  // private async _loadUx5_2ReportReview() {
  //   this.container.clear();
  //   const { Ux5Dot2ReportReviewNameSearchComponent } = await import('@ux5.2/peopleSearch/nameSearch/report-review');
  //   const componentFactory = this._cfr.resolveComponentFactory(Ux5Dot2ReportReviewNameSearchComponent);
  //   const component = this.container.createComponent(componentFactory);
  //   component.instance.base = this;
  //   component.instance.uxHelper = this.uxHelper;
  // }
}
