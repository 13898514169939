import {Component, Input, OnChanges, OnInit} from "@angular/core";
import {SearchedPerson} from "../../../../../common/custom/models/peopleSearch/searchedPerson";
import {SearchResultPeopleSearch} from "../../../searchResultPeopleSearch";
import {UxHelper} from "../../../../../clientCommon/helper/uxHelper";
import {UxComposite} from "../../../../../common/models/ux/uxComposite";

@Component({
  selector: 'default-srp-person',
  templateUrl: './defaultSrpPerson.component.html',
  styleUrls: ['./defaultSrpPerson.component.css'],
})
export class DefaultSrpPersonNameSearchComponent implements OnInit, OnChanges {

  @Input() base: SearchResultPeopleSearch;
  @Input() searchedPerson: SearchedPerson;

  constructor() {
  }

  init() { }

  ngOnInit() {
    return this.init();
  }

  ngOnChanges() {
    return this.init();
  }
}
