import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, NgModule, Output, EventEmitter } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BaseComponent } from '@clientCommon/components/BaseComponent';
import { LogUtils } from '@common/utils/logUtils';
import { NgSelectModule } from '@ng-select/ng-select';
import { UiSwitchModule } from 'ngx-ui-switch';

declare var $: any;

@Component({
  selector: 'app-mini-report-note',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
})
export class MiniReportNotesComponent implements OnInit {
  @Input() base: any;
  @Input() isPdfPrintView = false; 
  @Input() isPrivate: boolean = true;

  @Output() updateNotes = new EventEmitter<any>();
  @Output() noteModified = new EventEmitter<any>();

  private = false;
  invalidNote = false;
  noteContent = '';
  LogUtils: any;

  constructor() {}

  ngOnInit(): void {}

  checkNote() {
    if (this.noteContent.length) {
      this.invalidNote = false;
    } else {
      this.invalidNote = true;
    }
  }

  addNote() {
    $('#add-note-modal').modal('hide');
    return Promise.resolve(true).then(() => {
      const userId = this.base.serviceHelperService.authenticationService.getUserId();
      return this.base.noteService
        .createNote(this.noteContent, this.private, this.base.person?.extId, userId)
        .then(() => {
          this.base.noteHandleReoload();
          this.noteContent = '';
          this.isPrivate = false;
          this.base.serviceHelperService.trackingService.report({
            page: BaseComponent.PAGE.detail,
            pageType: BaseComponent.PAGE_TYPE.member,
            type: 'notes',
            action: 'submit',
            private: this.private,
          });
          if (this.private) {
            $('#success-private-note-modal').modal('show');
          } else {
            $('#success-public-note-modal').modal('show');
          }
        })
        .catch((err) => {
          LogUtils.error(err);
        });
    });
  }
  
}

@NgModule({
  declarations: [MiniReportNotesComponent],
  imports: [
    CommonModule, 
    FormsModule,
    UiSwitchModule,
    NgSelectModule
  ],
  exports: [MiniReportNotesComponent],
})
export class MiniReportNotesComponentModule {}
