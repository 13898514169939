import { Component, Input } from '@angular/core';
import { UxComp } from '@ux5.2/types';

@Component({
  selector: 'ux-5-2-faq-card',
  templateUrl: './faq-card.component.html',
  styleUrls: ['./faq-card.component.scss'],
})
export class FaqCardComponent {
  @Input() title: UxComp;
  @Input() subTitle: UxComp;
  @Input() faqHeader: UxComp;
  @Input() description: UxComp;
  @Input() faqSecondHeader: UxComp;
  @Input() faqCancelMembershipContent: UxComp;
}
