import { ACCOUNT_DECLARATIONS } from "./account/index";
import { MEMBER_COMPONENTS_DECLARATIONS, MEMBER_COMPONENT_IMPORTS } from "./components/index";
import { PEOPLE_SEARCH_MEMBER_DECLARATIONS, PEOPLE_SEARCH_MEMBER_IMPORTS } from "./peopleSearch/index";

export const MEMBER_DECLARATIONS = [
  ...ACCOUNT_DECLARATIONS,
  ...MEMBER_COMPONENTS_DECLARATIONS,
  ...PEOPLE_SEARCH_MEMBER_DECLARATIONS,
];

export const MEMBER_PAGE_MODULES = [...MEMBER_COMPONENT_IMPORTS, ...PEOPLE_SEARCH_MEMBER_IMPORTS];
