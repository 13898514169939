// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 425px) {
    .disclaimer {
      font-size: 11px;
      font-weight: 400;
      line-height: 20px;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/sales/components/salesDisclaimer.component.css"],"names":[],"mappings":"AAAA;IACI;MACE,eAAe;MACf,gBAAgB;MAChB,iBAAiB;IACnB;AACJ","sourcesContent":["@media only screen and (max-width: 425px) {\n    .disclaimer {\n      font-size: 11px;\n      font-weight: 400;\n      line-height: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
