class RandomUtils {
  chooseWeightedRandom(options: {
                         id: string,
                         weight: number,
                       }[]
  ) {
    let weight = 0;
    let candidates = [];
    options.forEach((option) => {
      if (option.weight > 0) {
        weight += option.weight;
        candidates.push({weight: weight, id: option.id});
      }
    });

    let selected = Math.random() * weight;

    if (candidates.length) {
      let selectedId = null;
      candidates.some((candidate) => {
        selectedId = candidate.id;
        if (candidate.weight > selected) {
          return true;
        }
      });
      return selectedId;
    }
  }
}


export var randomUtils = new RandomUtils();
