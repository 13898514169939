import {
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  EventEmitter,
  Input,
  NgModule,
  AfterViewInit,
  Renderer2,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceHelperService } from '../../../../../../clientCommon/services/serviceHelper.service';
import { PersonDetail } from '../../../personDetail';
import { ToastrService } from '../../../../../service/toastr.service';
import { BaseComponent } from '../../../../../../clientCommon/components/BaseComponent';
import { peopleSearchProductKeys } from '../../../../../../common/custom/peopleSearch/peopleSearchProductKeys';
import { NoteService, AuthenticationService } from '../../../../../../clientCommon/services';
import { Resource } from '../../../../../../common/models/resource';
import { CensusDataService } from '../../../../../../clientCommon/services/censusData.service';
import { LogUtils } from '../../../../../../common/utils/logUtils';
import { CommonModule } from '@angular/common';
import { MiniReportHeaderModule } from '@member/peopleSearch/components/mini/header/header.component';
import { PropertyMapComponentModule, PropertyMapMarker } from '@member/peopleSearch/components/map.component';
import { MiniPropertyComponentModule } from '@member/peopleSearch/components/mini/property/property.component';
import { MemberUpsellOwnedPropertiesComponentModule } from '@member/components/upsells/owned-properties';
import { MemberUpsellPremiumBadgeComponentModule } from '@member/components/upsells/premium-badge';
import { MiniCensusAccordionComponentModule } from '@member/peopleSearch/components/mini/census-accordion/accordion.component';
import { MemberUpsellCensusComponentModule } from '@member/components/upsells/census';
import { AdditionalResourcesComponentModule } from '../additional-resources/additional-resources.component';
import { MiniButtonComponentModule } from '@member/peopleSearch/components/mini/button/button.component';
import { MiniReportNotesComponentModule } from '@member/peopleSearch/components/mini/notes/notes.component';
import { AdComponentModule } from 'app/components/ad/ad.component';
import { MiniReportFooterModule } from '@member/peopleSearch/components/mini/footer/footer.component';
import { SexOffenderComponentModule } from '@member/peopleSearch/components/sexOffender.component';
import { MiniPropertiesBaseComponent } from './miniPropertiesBase.component';
import { FactFindersComponentModule } from '../factFinders/factFinders.component';

declare var $: any;

@Component({
    selector: 'app-mini-properties',
    templateUrl: './miniProperties.component.html',
    styleUrls: ['./miniProperties.component.scss'],
    standalone: false
})
export class MiniPropertiesComponent extends MiniPropertiesBaseComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  
  constructor(
    public serviceHelperService: ServiceHelperService,
    public changeDetectorRef: ChangeDetectorRef,
    activatedRoute: ActivatedRoute,
    protected router: Router,
    public renderer: Renderer2,
    public toastr: ToastrService,
    public noteService: NoteService,
    public authService: AuthenticationService,
    protected censusService: CensusDataService
  ) {
    super(serviceHelperService, changeDetectorRef, activatedRoute, router, renderer, toastr, noteService, authService, censusService);
  }

  ngOnInit() {
    return super.ngOnInit();
  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
  }
  ngOnDestroy() {
    return super.ngOnDestroy();
  }
  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }
}

@NgModule({
  declarations: [MiniPropertiesComponent],
  imports: [
    CommonModule,
    FactFindersComponentModule,
    MiniReportHeaderModule,
    MiniPropertyComponentModule,
    MiniCensusAccordionComponentModule,
    MiniButtonComponentModule,
    MiniReportNotesComponentModule,
    MiniReportFooterModule,

    MemberUpsellOwnedPropertiesComponentModule,
    MemberUpsellPremiumBadgeComponentModule,
    MemberUpsellCensusComponentModule,

    PropertyMapComponentModule,
    SexOffenderComponentModule,
    AdditionalResourcesComponentModule,
    AdComponentModule,
  ],
  exports: [MiniPropertiesComponent],
})
export class MiniPropertiesComponentModule { }
