import {Component, OnDestroy, OnInit} from "@angular/core";
import {BaseComponent} from "../../clientCommon/components/BaseComponent";
import {ServiceHelperService} from "../../clientCommon/services/serviceHelper.service";
import {ActivatedRoute} from "@angular/router";
import {redirectHelper} from "../../clientCommon/helper/redirectHelper";
import {LogUtils} from "../../common/utils/logUtils";

@Component({
  template: '<div></div>',
})

/**
 *  @extends BaseComponent to get theme and uxComp template etc. needed from uxHelper
 */
export class LandingSalesComponent extends BaseComponent implements OnInit, OnDestroy {

/**
 *
 * @param serviceHelperService will provide access to different required services such as authentication service, uxc service etc.
 * @param activatedRoute provides access to information about a route associated with a component that is loaded in an outlet.
 * @param pageType getting from BaseComponent to be provided as input to the child(theme template) component.
 * @param pageCategory getting from BaseComponent to be provided as input to the child(theme template) component.
 * @param page getting from BaseComponent to be provided as input to the child(theme template) component.
 *
 */
  constructor(serviceHelperService: ServiceHelperService,
              activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
    this.pageType = BaseComponent.PAGE_TYPE.sales;
    this.page = BaseComponent.PAGE.landing;
    this.pageCategory = BaseComponent.PAGE_CATOGORY.general;
  }

  ngOnInit() {
    return super.baseInit().then(() => {
      // check if there is history in session storage for visited location
      let visited = this.hasHistory();
      // set history for location
      this.setHistory();

      /**
       * if not visited navigate to landingIntermediate other get url from general routing comp
       */

      let url = "";
      if (!visited) {
        url = '/' + this.clientPaths.landingIntermediate;
      } else {
        url = this.uxHelper.getValue("comp.sales.general.landing.route");
      }
      if (window.location.search) {
        url += window.location.search;
      }

      // if user is logged in redirect to Membership Home Page (MHP)
      if (this.serviceHelperService.authenticationService.isLoggedIn()) {
        return redirectHelper.redirect(this.serviceHelperService, '/member');
      }
      redirectHelper.redirect(this.serviceHelperService, url);
    }).catch((e) => {
      LogUtils.error(e);
    });
  }

  ngOnDestroy() {
    return super.onDestroy();
  }
}

