// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ! FOR  EYE  */
.eye-backdrop {
  height: 100vh;
  width: 100vw;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 64px;
}

.eye-container {
  position: relative;
}

.eye-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.eye-spinner {
  animation: spin infinite 3s linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/member/peopleSearch/nameSearch/detail/loader-eye/loader-eye.component.scss"],"names":[],"mappings":"AAAA,gBAAA;AACA;EACE,aAAA;EACA,YAAA;EACA,uBAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;AACF;;AACA;EACE,kBAAA;AAEF;;AAAA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAGF;;AADA;EACE,kCAAA;AAIF;;AADA;EACE;IACE,uBAAA;EAIF;EAFA;IACE,yBAAA;EAIF;AACF","sourcesContent":["/* ! FOR  EYE  */\n.eye-backdrop {\n  height: 100vh;\n  width: 100vw;\n  background-color: white;\n  position: fixed;\n  top: 0;\n  left: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding-top: 64px;\n}\n.eye-container {\n  position: relative;\n}\n.eye-image {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.eye-spinner {\n  animation: spin infinite 3s linear;\n}\n\n@keyframes spin {\n  0% {\n    transform:rotate(0deg);\n  }\n  100% {\n    transform:rotate(360deg);\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
