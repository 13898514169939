// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.credit-card-fields {
  overflow: hidden;
}

.underInput {
  color: #969696;
  padding-left: 12px;
}

.terms {
  color: #969696;
  margin-bottom: 32px;
}

#credit-card-fields ::ng-deep .terms a {
  color: #777 !important;
}

#credit-card-fields ::ng-deep input {
  box-shadow: 0.25rem 0.25rem 0.625rem rgba(0, 0, 0, 0.4) !important;
}

#credit-card-fields input.has-error::placeholder {
  color: #F20926;
}

@media (max-width: 768px) {
  .terms {
    font-size: 12px;
    margin-bottom: 32px;
  }
}
.cccard__spinner {
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cccard__spinner img {
  display: block;
}

.cccard__spinner-spin {
  animation: spin infinite 3s linear;
}

.cccard__spinner-title {
  text-align: center;
  font-weight: 900;
  font-size: 24px;
  margin: 0;
}

.cccard__spinner-footer {
  text-align: center;
}

.cccard__spinner-header {
  background: #76B509 !important;
  padding-bottom: 15px;
  margin-bottom: 15px;
  color: white;
  font-weight: 900;
  font-size: 24px;
}

::placeholder {
  color: #F20926;
}`, "",{"version":3,"sources":["webpack://./src/app/member/components/cpcc-entery-card/cpcc-entery-card.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;;AAEA;EACI,cAAA;EACA,kBAAA;AACJ;;AAEA;EACI,cAAA;EACA,mBAAA;AACJ;;AAEA;EACI,sBAAA;AACJ;;AAEA;EACI,kEAAA;AACJ;;AAEA;EACI,cAAA;AACJ;;AAGA;EACI;IACI,eAAA;IACA,mBAAA;EAAN;AACF;AAGA;EACI,aAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AADJ;;AAIA;EACI,cAAA;AADJ;;AAIA;EACI,kCAAA;AADJ;;AAIA;EACI,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,SAAA;AADJ;;AAIA;EACI,kBAAA;AADJ;;AAIA;EACI,8BAAA;EACA,oBAAA;EACA,mBAAA;EACA,YAAA;EACA,gBAAA;EACA,eAAA;AADJ;;AAIA;EACI,cAAA;AADJ","sourcesContent":[".credit-card-fields {\n    overflow: hidden;\n}\n\n.underInput {\n    color: #969696;\n    padding-left: 12px;\n}\n\n.terms {\n    color: #969696;\n    margin-bottom: 32px;\n}\n\n#credit-card-fields ::ng-deep .terms a {\n    color: #777 !important;\n}\n\n#credit-card-fields ::ng-deep input {\n    box-shadow: 0.25rem 0.25rem 0.625rem rgba(0, 0, 0, 0.4) !important;\n}\n\n#credit-card-fields input.has-error::placeholder {\n    color: #F20926;\n}\n\n\n@media (max-width: 768px) {\n    .terms {\n        font-size: 12px;\n        margin-bottom: 32px;\n    }\n}\n\n.cccard__spinner {\n    height: 240px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.cccard__spinner img {\n    display: block;\n}\n\n.cccard__spinner-spin {\n    animation: spin infinite 3s linear;\n}\n\n.cccard__spinner-title {\n    text-align: center;\n    font-weight: 900;\n    font-size: 24px;\n    margin: 0;\n}\n\n.cccard__spinner-footer {\n    text-align: center;\n}\n\n.cccard__spinner-header {\n    background: #76B509 !important;\n    padding-bottom: 15px;\n    margin-bottom: 15px;\n    color: white;\n    font-weight: 900;\n    font-size: 24px;\n}\n\n::placeholder {\n    color: #F20926;\n\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
