import { Component, NgModule } from '@angular/core';

@Component({
    selector: 'member-upsell-court-features',
    templateUrl: './court-features.component.html',
    standalone: false
})
export class MemberUpsellCourtFeaturesComponent {}

@NgModule({
  declarations: [MemberUpsellCourtFeaturesComponent],
  exports: [MemberUpsellCourtFeaturesComponent],
})
export class MemberUpsellCourtFeaturesComponentModule {}
