import {CollectionClass} from "../decorators/database/collectionClass";
import {IndexField} from "../decorators/database/indexField";
import {ModelBase} from "./modelBase";

@CollectionClass({name: "trackings", revisionEnabled: false, draftEnabled: false})
export class Tracking extends ModelBase<Tracking> {
  @IndexField() sessionHash: string;
  @IndexField() sessionHash2: string;
  @IndexField() userId: string;
  @IndexField() referenceId: string;
  referenceCollection: string;
  externalName: string;
  @IndexField() externalId: string;

  uxcId: string;
  uxlId: string;
  extra: any;

  param: any;

  constructor(doc?, draftFlag?: boolean) {
    super(doc, draftFlag);
    this.init(doc, this.draftFlag);
  }
}

