import { ReplaySubject, Subscription } from "rxjs";
import { LogUtils } from "../../common/utils/logUtils";
import { CaptchaType } from "../../common/helpers/captchaCommonHelper";

type RecaptchaV2LoadedEvent = {
  target: HTMLElement | string, 
  config: RecaptchaV2Config,
};

type RecaptchaV3LoadedEvent = {
  action: string, 
  config: RecaptchaV3Config,
};

type TurnstileV0LoadedEvent = {
  target: string, 
  config: TurnstileV0Config,
};

export interface RecaptchaV2Config {
  sitekey: string,
  action: string,
  callback: (token: string) => void,
  failCallback?: (err?: any) => void,
  widgetIdCallback?: (widgetId: string) => void,
}

export interface RecaptchaV3Config {
  sitekey: string,
  callback: (token: string) => void,
  failCallback?: (err?: any) => void,
  duringClass?: string,
}

export interface TurnstileV0Config {
  sitekey: string,
  action: string,
  callback: (token: string) => void,
  failCallback?: (err?: any) => void,
}

export class UnsurpportedBrowserError extends Error {
  constructor() {
    super('Your current browser does not support a captcha. Please update your OS or browser.');
  }
}

export class CaptchaHelper {
  private constructor() {}

  private static instance: CaptchaHelper;

  private static readonly recaptchaV2ScriptId: string = 'Recaptcha_V2_Script';

  private static readonly recaptchaV3ScriptId: string = 'Recaptcha_V3_Script';

  private static readonly turnstileV0ScriptId: string = 'Turnstile_V0_Script';

  // Consider buffer size
  private loadedRecaptchaV2 = new ReplaySubject<RecaptchaV2LoadedEvent>(1);

  private loadedRecaptchaV3 = new ReplaySubject<RecaptchaV3LoadedEvent>(1);

  private loadedTurnstileV0 = new ReplaySubject<TurnstileV0LoadedEvent>(1);

  private subRecaptchaV2: Subscription;
  
  private subRecaptchaV3: Subscription;
  
  private subTurnstileV0: Subscription;

  public static getInstance() {
    if (!CaptchaHelper.instance) {
      CaptchaHelper.instance = new CaptchaHelper();
    }

    return CaptchaHelper.instance;
  }

  public reset(type: CaptchaType, widgetId: string) {
    try {
      switch (type) {
        case CaptchaType.RecaptchaV2:
          window?.['grecaptcha']?.reset(widgetId);
          break;
      }
    } catch (err) {
      LogUtils.warn('CaptchaHelper.reset Fail', err);
    }
  }

  public unload(type: CaptchaType) {
    let script;

    switch (type) {
      case CaptchaType.RecaptchaV2:
        this.subRecaptchaV2?.unsubscribe();
        
        script = document.getElementById(CaptchaHelper.recaptchaV2ScriptId);

        break;
      case CaptchaType.RecaptchaV3:
        this.subRecaptchaV3?.unsubscribe();

        script = document.getElementById(CaptchaHelper.recaptchaV3ScriptId);
        
        break;
      case CaptchaType.TurnstileV0:
        this.subTurnstileV0?.unsubscribe();

        script = document.getElementById(CaptchaHelper.turnstileV0ScriptId);

        break;
    }

    if (script) {
      document.body.removeChild(script);
    }
  }

  public unloadAll() {
    this.unload(CaptchaType.RecaptchaV2);

    this.unload(CaptchaType.RecaptchaV3);

    this.unload(CaptchaType.TurnstileV0);
  }

  public loadRecaptchaV2(target: HTMLElement | string, config: RecaptchaV2Config) {
    // Avoid loading duplicate scripts in v2 and v3
    this.unload(CaptchaType.RecaptchaV3);

    if (!document.getElementById(CaptchaHelper.recaptchaV2ScriptId)) {
      window['Recaptcha_V2_Script_OnLoad'] = this.onLoadedRecaptchaV2.bind(this);

      const script = document.createElement('script');
      script['id'] = CaptchaHelper.recaptchaV2ScriptId;
      script['src'] = 'https://www.google.com/recaptcha/api.js?onload=Recaptcha_V2_Script_OnLoad&render=explicit';
      document.body.appendChild(script);
    }

    this.loadedRecaptchaV2.next({
      target,
      config,
    });
  }

  public loadRecaptchaV3(action: string, config: RecaptchaV3Config) {
    // Avoid loading duplicate scripts in v2 and v3
    this.unload(CaptchaType.RecaptchaV2);

    if (!document.getElementById(CaptchaHelper.recaptchaV3ScriptId)) {
      window['Recaptcha_V3_Script_OnLoad'] = this.onLoadedRecaptchaV3.bind(this);

      const script = document.createElement('script');
      script['id'] = CaptchaHelper.recaptchaV3ScriptId;
      script['src'] = `https://www.google.com/recaptcha/api.js?onload=Recaptcha_V3_Script_OnLoad&render=${config.sitekey}`;
      document.body.appendChild(script);
    }

    this.loadedRecaptchaV3.next({
      action,
      config
    });
  }

  public loadTurnstileV0(target: string, config: TurnstileV0Config) {
    if (!document.getElementById(CaptchaHelper.turnstileV0ScriptId)) {
      window['Turnstile_V0_Script_OnLoad'] = this.onLoadedTurnstileV0.bind(this);

      const script = document.createElement('script');
      script['id'] = CaptchaHelper.turnstileV0ScriptId;
      script['src'] = 'https://challenges.cloudflare.com/turnstile/v0/api.js?onload=Turnstile_V0_Script_OnLoad';
      document.body.appendChild(script);
    }

    this.loadedTurnstileV0.next({
      target,
      config
    });
  }

  private onLoadedRecaptchaV2() {
    this.subRecaptchaV2 = this.loadedRecaptchaV2.subscribe((e: RecaptchaV2LoadedEvent) => {
      try {
        const widgetId = window?.['grecaptcha'].render(e.target, e.config);

        if (e.config?.widgetIdCallback) {
          e.config.widgetIdCallback?.(widgetId);
        }
      } catch (err) {
        LogUtils.warn('CaptchaHelper.onLoadedRecaptchaV2 Fail', err);
        e.config?.failCallback?.(err);
      }
    });
  }

  private onLoadedRecaptchaV3() {
    this.subRecaptchaV3 = this.loadedRecaptchaV3.subscribe((e: RecaptchaV3LoadedEvent) => {
      let removeClass: (() => void) | undefined;

      try {
        if (e.config?.duringClass) {
          const badge = document.getElementsByClassName('grecaptcha-badge')?.[0];

          if (badge) {
            badge.classList.add(e.config?.duringClass);

            removeClass = () => badge.classList.remove(e.config!.duringClass!);
          }
        }

        window?.['grecaptcha'].execute(
          e.config.sitekey, 
          { action: e.action },
        ).then((token) => {
          removeClass?.();

          e.config.callback(token);
        }).catch(err => {
          LogUtils.warn('CaptchaHelper.onLoadedRecaptchaV3 Fail', err);
          
          removeClass?.();
          
          e.config?.failCallback?.(err);
        });
      } catch (err) {
        LogUtils.warn('CaptchaHelper.onLoadedRecaptchaV3 Fail', err);
        
        removeClass?.();

        e.config?.failCallback?.(err);
      }
    });
  }

  private onLoadedTurnstileV0() {
    this.subTurnstileV0 = this.loadedTurnstileV0.subscribe((e: TurnstileV0LoadedEvent) => {
      try {
        window?.['turnstile'].render(e.target, {
          ...e.config,
          'unsupported-callback': () => {
            e.config?.failCallback?.(new UnsurpportedBrowserError());
          },
        });
      } catch (err) {
        LogUtils.warn('CaptchaHelper.onLoadedTurnstileV0 Fail', err);
        e.config?.failCallback?.(err);
      }
    });
  }
}
