// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mini-button {
  padding: 10px;
  outline: 0;
  border: none;
  font-weight: 900;
  color: white;
  background-color: #276fcf;
  border-radius: 6px;
  transition: box-shadow 0.2s;
  font-size: 20px;
  cursor: pointer;
  width: 100%;
  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.2509803922);
  height: 32px;
  width: 130px;
}
.mini-button:hover {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
}
.mini-button.green {
  background-color: #81b34c;
}
.mini-button.yellow {
  background-color: #e89f10;
}
.mini-button.silver {
  background-color: #acacac;
}
.mini-button.block {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mini-button.block .mini-button__wrapper {
  justify-content: center;
}
.mini-button.small {
  padding: 6px 12px;
  font-size: 12px;
  line-height: 14px;
}
.mini-button.small:hover {
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
}
.mini-button.rounded {
  border-radius: 50px;
}
.mini-button__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mini-button__icon {
  margin-right: 8px;
}
.mini-button__icon img {
  display: block;
  width: 16px;
  height: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/member/peopleSearch/components/mini/button/button.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,UAAA;EACA,YAAA;EACA,gBAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,2BAAA;EACA,eAAA;EACA,eAAA;EACA,WAAA;EACA,uDAAA;EACA,YAAA;EACA,YAAA;AACF;AACE;EACE,2CAAA;AACJ;AAEE;EACE,yBAAA;AAAJ;AAGE;EACE,yBAAA;AADJ;AAGE;EACE,yBAAA;AADJ;AAIE;EACE,WAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAFJ;AAII;EACE,uBAAA;AAFN;AAOE;EACE,iBAAA;EACA,eAAA;EACA,iBAAA;AALJ;AAOI;EACE,2CAAA;AALN;AASE;EACE,mBAAA;AAPJ;AAUE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AARJ;AAWE;EACE,iBAAA;AATJ;AAWI;EACE,cAAA;EACA,WAAA;EACA,YAAA;AATN","sourcesContent":[".mini-button {\n  padding: 10px;\n  outline: 0;\n  border: none;\n  font-weight: 900;\n  color: white;\n  background-color: #276fcf;\n  border-radius: 6px;\n  transition: box-shadow 0.2s;\n  font-size: 20px;\n  cursor: pointer;\n  width: 100%;\n  box-shadow: 2px 2px 2px 0px #00000040;\n  height: 32px;\n  width: 130px;\n\n  &:hover {\n    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);\n  }\n\n  &.green {\n    background-color: #81b34c;\n  }\n\n  &.yellow {\n    background-color: #e89f10;\n  }\n  &.silver {\n    background-color: #acacac;\n  }\n\n  &.block {\n    width: 100%;\n    text-align: center;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    \n    .mini-button__wrapper {\n      justify-content: center;\n      \n    }\n  }\n\n  &.small {\n    padding: 6px 12px;\n    font-size: 12px;\n    line-height: 14px;\n\n    &:hover {\n      box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);\n    }\n  }\n\n  &.rounded {\n    border-radius: 50px;\n  }\n\n  &__wrapper {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n\n  &__icon {\n    margin-right: 8px;\n\n    img {\n      display: block;\n      width: 16px;\n      height: 16px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
