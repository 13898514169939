import {Component, Input, OnChanges, OnInit} from "@angular/core";
import {UxHelper} from "../../../../../clientCommon/helper/uxHelper";
import {SearchResultPeopleSearch} from "../../../../peopleSearch/searchResultPeopleSearch";

@Component({
  selector: 'ux-3-0-searchResult-name-search',
  templateUrl: './searchResult.component.html',
  styleUrls: ['./searchResult.component.scss'],
})
export class Ux3Dot0SearchResultNameSearchComponent implements OnInit, OnChanges {
  @Input() base: SearchResultPeopleSearch;

  @Input() uxHelper: UxHelper;

  pageNumber = 0;
  totalCount = 0;
  startIndex = 0;

  constructor() {
  }

  init() {
    if (!this.base.commerceContent) return;
    if (!this.base.commerceContent.getMainRaw()) return;
    if (!this.base.commerceContent.getMainRaw().tempClient) return;
    if (!this.base.commerceContent.getMainRaw().tempClient.processed) return;
    if (!this.base.commerceContent.getMainRaw().tempClient.processed.person) return;
    this.totalCount = this.base.commerceContent.getMainRaw().tempClient.processed?.person?.length || 0;
  }

  ngOnInit() {
    return this.init();
  }

  ngOnChanges() {
    return this.init();
  }

  nextPage() {
    if (this.startIndex + this.base.searchResultLimit < this.totalCount) {
      this.startIndex += this.base.searchResultLimit;
      this.pageNumber ++;
    }
  }

  prevPage() {
    if (this.startIndex > 0) {
      this.startIndex -= this.base.searchResultLimit;
      this.pageNumber --;
    }
  }

  get adsDisplayInterval() {
    return this.uxHelper.getUxcomp('ad.bottom.1.config')?.interval;
  }

  shouldDisplayAd(i: number) {
    if ((!this.adsDisplayInterval) || (i < this.adsDisplayInterval)) return false;
    return parseFloat((i % this.adsDisplayInterval).toString()) === 0
  }
}
