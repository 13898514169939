import {
  Component,
  ComponentFactoryResolver,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { LoaderPeopleSearchSales } from '../../loaderPeopleSearchSales';
import { BaseComponent } from '../../../../../clientCommon/components/BaseComponent';
import { ServiceHelperService } from '../../../../../clientCommon/services/serviceHelper.service';
import { ActivatedRoute } from '@angular/router';
import { LogUtils } from '../../../../../common/utils/logUtils';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HeadlessHelper } from '../../../../helper/headlessHelper';

@Component({
  templateUrl: './loader.component.html',
})

/**
 * LoaderNameSearchSalesComponent render the respective theme/layout component based on current theme
 * @extends LoaderPeopleSearchSales which further extends LoaderPeopleSearch to have all the required properties i.e commerceContent, count...
 */
export class LoaderNameSearchSalesComponent extends LoaderPeopleSearchSales implements OnInit, OnDestroy {
  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;
  private readonly _destroyed$ = new Subject<void>();

  enableHeadless: boolean = false;

  /**
   *
   * @param serviceHelperService will provide access to different required services such as authentication service, uxc service etc.
   * @param activatedRoute provides access to information about a route associated with a component that is loaded in an outlet.
   *
   */
  constructor(
    serviceHelperService: ServiceHelperService,
    activatedRoute: ActivatedRoute,
    private _cfr: ComponentFactoryResolver
  ) {
    super(serviceHelperService, activatedRoute);
    this.pageCategory = BaseComponent.PAGE_CATOGORY.nameSearch;
  }

  ngOnInit() {
    this.init$
      .pipe(
        tap(() => {
          if (HeadlessHelper.isEnabled(this.uxComposite)) {
            this.enableHeadless = true;
          } 
          // else if (this.theme === this.themes.ux_5_2) {
          //   this._loadUx5_2LoaderPage();
          // }
        }),
        takeUntil(this._destroyed$)
      )
      .subscribe();

    return this.onInit()
      .then(() => {
        // TODO: This piece of code is never called. Remove it
        this.initDone = true;
      })
      .catch((e) => {
        LogUtils.error(e);
        if (this.stillInThisPage()) {
          // return redirectHelper.redirect(this.serviceHelperService, '/'+path);
          if (this.theme !== this.themes.ux_5_2) {
            return this.next();
          }
        }
      });
  }

  ngOnDestroy() {
    super.onDestroy();
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  @HostListener('window:scroll', ['$event'])
  onScroll($event) {
    super.onScroll($event);
  }

  // private async _loadUx5_2LoaderPage() {
  //   this.container.clear();
  //   const { Ux5Dot2LoaderNameSearchComponent } = await import('@ux5.2/peopleSearch/nameSearch/loader');
  //   const componentFactory = this._cfr.resolveComponentFactory(Ux5Dot2LoaderNameSearchComponent);
  //   const component = this.container.createComponent(componentFactory);
  //   component.instance.base = this;
  //   component.instance.uxHelper = this.uxHelper;
  // }
}
