import { ANIMATED_SEARCHING_LOADER_COMPONENTS_UX_5_2_THEME_DECLARATIONS } from './animated-searching';
import { CONFIRMATION_LOADER_COMPONENTS_UX_5_2_THEME_DECLARATIONS } from './confirmation';
import { FOLLOW_UP_QUESTION_LOADER_COMPONENTS_UX_5_2_THEME_DECLARATIONS } from './follow-up-question';
import { MAGNIFIER_LOADER_COMPONENTS_UX_5_2_THEME_DECLARATIONS } from './magnifier';
import { PROGRESS_INDICATOR_LOADER_COMPONENTS_UX_5_2_THEME_DECLARATIONS } from './progress-indicator';
import { TILE_LIST_LOADER_COMPONENTS_UX_5_2_THEME_DECLARATIONS } from './tile-list';

export const LOADER_COMPONENTS_UX_5_2_THEME_DECLARATIONS = [
    ...ANIMATED_SEARCHING_LOADER_COMPONENTS_UX_5_2_THEME_DECLARATIONS,
    ...CONFIRMATION_LOADER_COMPONENTS_UX_5_2_THEME_DECLARATIONS,
    ...FOLLOW_UP_QUESTION_LOADER_COMPONENTS_UX_5_2_THEME_DECLARATIONS,
    ...MAGNIFIER_LOADER_COMPONENTS_UX_5_2_THEME_DECLARATIONS,
    ...PROGRESS_INDICATOR_LOADER_COMPONENTS_UX_5_2_THEME_DECLARATIONS,
    ...TILE_LIST_LOADER_COMPONENTS_UX_5_2_THEME_DECLARATIONS,
];
