// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  max-width: 636px;
  margin-bottom: 0;
}
@media (max-width: 1100px) {
  .container {
    max-width: 100%;
  }
}
@media screen and (max-width: 420px) {
  .container {
    margin: 16px 10px;
  }
}

.hideContainer {
  display: none;
}

h4 {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
}

p {
  width: 95%;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

.sub-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
@media only screen and (max-width: 420px) {
  .sub-container p {
    font-size: 12px;
    line-height: 14px;
  }
}

.user-checkbox {
  padding-right: 7px;
}

@media only screen and (max-width: 600px) {
  p {
    width: auto;
  }
  h4 {
    font-weight: 700;
    font-size: 8px;
    line-height: 10px;
  }
}
.bold {
  font-weight: 700;
}

.top-container {
  margin-bottom: 1.25rem;
}`, "",{"version":3,"sources":["webpack://./src/app/themes/2234200bd3cc36ba97274ecbdc6c6a47/components/sup/payment-disclosure/payment-disclosure.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,gBAAA;AACF;AAAE;EAHF;IAII,eAAA;EAGF;AACF;AAFE;EANF;IAOI,iBAAA;EAKF;AACF;;AAFA;EACE,aAAA;AAKF;;AAFA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;AAKF;;AAHA;EACE,UAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAMF;;AAHA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AAMF;AALE;EACE;IACE,eAAA;IACA,iBAAA;EAOJ;AACF;;AAHA;EACE,kBAAA;AAMF;;AAJA;EACE;IACE,WAAA;EAOF;EALA;IACE,gBAAA;IACA,cAAA;IACA,iBAAA;EAOF;AACF;AAJA;EACE,gBAAA;AAMF;;AAHA;EACE,sBAAA;AAMF","sourcesContent":[".container {\n  max-width: 636px;\n  margin-bottom: 0;\n  @media (max-width: 1100px) {\n    max-width: 100%;\n  }\n  @media screen and (max-width: 420px) {\n    margin: 16px 10px;\n  }\n}\n\n.hideContainer {\n  display: none;\n}\n\nh4 {\n  font-weight: 700;\n  font-size: 12px;\n  line-height: 15px;\n}\np {\n  width: 95%;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 15px;\n}\n\n.sub-container {\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n  @media only screen and (max-width: 420px) {\n    p {\n      font-size: 12px;\n      line-height: 14px;\n    }\n  }\n}\n\n.user-checkbox {\n  padding-right: 7px;\n}\n@media only screen and (max-width: 600px) {\n  p {\n    width: auto;\n  }\n  h4 {\n    font-weight: 700;\n    font-size: 8px;\n    line-height: 10px;\n  }\n}\n\n.bold {\n  font-weight: 700;\n}\n\n.top-container {\n  margin-bottom: 1.25rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
