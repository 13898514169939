// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.more-result-title {
    font-family: Roboto;
    font-size: 30px;
    font-weight: 900;
    line-height: 35.16px;
    text-align: center;    
    color: #727272;
    margin-top: 40px;
    margin-bottom: 20px;
    @media screen and (max-width: 834px) {
        font-size: 24px;
        line-height: 28.13px;
    }
    @media screen and (max-width: 534px) {
        font-size: 20px;
        line-height: 23.44px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}
.more-result-content {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    text-align: center;
    color: #525252;    
    margin-bottom: 40px;
    @media screen and (max-width: 534px) {
        font-size: 14px;
        line-height: 16.41px;
        margin-bottom: 20px;
    }    
}
.more-result-content-color {
    font-weight: 600;
    color: #81B34C;
}

#loadMore {
    margin-top: 20px;
    margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/peopleSearch/templates/nameSearch/default.1.2/searchResult.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,kBAAkB;IAClB,cAAc;IACd,gBAAgB;IAChB,mBAAmB;IACnB;QACI,eAAe;QACf,oBAAoB;IACxB;IACA;QACI,eAAe;QACf,oBAAoB;QACpB,gBAAgB;QAChB,mBAAmB;IACvB;AACJ;AACA;IACI,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,kBAAkB;IAClB,cAAc;IACd,mBAAmB;IACnB;QACI,eAAe;QACf,oBAAoB;QACpB,mBAAmB;IACvB;AACJ;AACA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".more-result-title {\n    font-family: Roboto;\n    font-size: 30px;\n    font-weight: 900;\n    line-height: 35.16px;\n    text-align: center;    \n    color: #727272;\n    margin-top: 40px;\n    margin-bottom: 20px;\n    @media screen and (max-width: 834px) {\n        font-size: 24px;\n        line-height: 28.13px;\n    }\n    @media screen and (max-width: 534px) {\n        font-size: 20px;\n        line-height: 23.44px;\n        margin-top: 20px;\n        margin-bottom: 20px;\n    }\n}\n.more-result-content {\n    font-family: Roboto;\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 18.75px;\n    text-align: center;\n    color: #525252;    \n    margin-bottom: 40px;\n    @media screen and (max-width: 534px) {\n        font-size: 14px;\n        line-height: 16.41px;\n        margin-bottom: 20px;\n    }    \n}\n.more-result-content-color {\n    font-weight: 600;\n    color: #81B34C;\n}\n\n#loadMore {\n    margin-top: 20px;\n    margin-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
