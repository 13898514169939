import { BaseComponent } from "../../../clientCommon/components/BaseComponent";
import { LandingPeopleSearch } from "../../peopleSearch/landingPeopleSearch";
import { CommerceOrder } from "../../../common/models/commerce/commerceOrder";
import { LogUtils } from "../../../common/utils/logUtils";
import { ServiceHelperService } from "../../../clientCommon/services/serviceHelper.service";
import { ActivatedRoute } from "@angular/router";
import { CommerceContent } from "../../../common/models/commerce/commerceContent";
import { ResponseEvent } from "../../../common/event/responseEvent";
import { PeopleSearchContentHelper, peopleSearchContentHelper } from "../../../common/custom/peopleSearch/peopleSearchHelper";
import { peopleSearchProductKeys } from "../../../common/custom/peopleSearch/peopleSearchProductKeys";
import { timeUtils } from "../../../common/utils/timeUtils";
import { SearchedPerson } from "../../../common/custom/models/peopleSearch/searchedPerson";
import { redirectHelper } from "../../../clientCommon/helper/redirectHelper";
import {ModelBase} from "../../../common/models/modelBase";
import { SpyIdHelper } from "../../../clientCommon/helper/spyIdHelper"

declare var $: any;

class ContentInfo {
  commerceContents: CommerceContent[] = [];
  loadMoreFlag = false;
  page = 0;
}

export class LandingPeopleSearchMember extends LandingPeopleSearch {
  timeUtils = timeUtils;
  commerceOrders: CommerceOrder[] = [];
  isNameSearchAllowed = true;
  isPhoneSearchAllowed = true;
  hasAccess: boolean;
  contentUpdatedCount = 0;
  idProtectionEnabled = false;

  tabs = {
    // Order is significant
    updates: "updates",
    monitoredNameSearchs: "monitoredNameSearchs",
    all: "all",
  };

  currentTab = "all";

  commerceContentInfos: {
    [tab: string]: ContentInfo;
  } = {};
  pastSearches: any[] = [];

  candidateCommerceContents: CommerceContent[] = []; // TO REMOVE
  candidatePersons: SearchedPerson[] = []; // TO REMOVE

  constructor(
    serviceHelperService: ServiceHelperService,
    activatedRoute: ActivatedRoute
  ) {
    super(serviceHelperService, activatedRoute);
    this.pageType = BaseComponent.PAGE_TYPE.member;
    Object.keys(this.tabs).forEach((key) => {
      this.commerceContentInfos[key] = new ContentInfo();
    });
  }

  ads = {
    main_1: "",
    lhs_1: "",
    rhs_1: "",
  };

  spyIdHelper = new SpyIdHelper()

  onInit() {
    let promises = [];
    this.serviceHelperService.spinnerService.spin();
    return super
      .onInit()
      .then(() => {
        promises.push(this.checkOrder());
        promises.push(this.findcountContentUpdated());
        promises.push(this.findAllContents());
        promises.push(this.findUpdatedContents());
        promises.push(this.findMonitoredNameSearch());
        promises.push(this.findCandidates()); // Candidate case
        promises.push(this.findTeaserSearch());

        return Promise.all(promises);
      })
      .then(() => {
        this.findIdProtectionContentIfApplicable();
        LogUtils.debug('landingPeopleSearchMembers', this.commerceContentInfos);
        this.autoTab();
        this.serviceHelperService.spinnerService.unspin();

        // Hash Fragment redirect
        this.activatedRoute.fragment.subscribe((fragment: string) => {
          if (
            this.tabs[fragment] &&
            this.commerceContentInfos[fragment].commerceContents.length > 0
          ) {
            this.currentTab = fragment;
          } else {
            this.autoTab();
          }
        });
      })
      .then(() => {
        this.ads.main_1 = this.uxHelper.getUxcompKey("ad.main.1");
        this.ads.lhs_1 = this.uxHelper.getUxcompKey("ad.lhs.1");
        this.ads.rhs_1 = this.uxHelper.getUxcompKey("ad.rhs.1");
      })
      .then(() => {
        this.showHideSearchBox(true);
        //TODO: if peoplesearch monitoring data existed, research data
        if (this.commerceContentInfos[this.tabs.monitoredNameSearchs]?.commerceContents[0]?.data?.subscribed
          && this.commerceContentInfos[this.tabs.monitoredNameSearchs]?.commerceContents[0]?.data?.idiCount?.idiCheck
          ) {
          // idiCheck update
          let param = {
            commerceContentId: this.commerceContentInfos[this.tabs.monitoredNameSearchs]?.commerceContents[0]?._id,
          };

          return this.serviceHelperService.commerceService
            .updateIdiCount(param)
            .then((responseEvent: ResponseEvent) => {
              // landingPeopleSearch.ts call
              this.peopleSearchUpdate(this.commerceContentInfos[this.tabs.monitoredNameSearchs]?.commerceContents[0]);
            })
            .catch((e) => {
              LogUtils.error(e);
            });          
        }
      }).catch((e) => {
        LogUtils.error(e);
      });
  }
  peopleSearchUpdate(sCommerceContent:CommerceContent) {
    // TODO: for test
    // need to logic check
    // idiCheck field is true, research
    // base.commerceContent?.getMainRaw()?.tempClient?.processed?.person
    this.uxComposite.setCode("commerceContent", sCommerceContent);
    let commerceContent = sCommerceContent;
    peopleSearchContentHelper.processResult(
      this.uxComposite,
      commerceContent  // this.commerceContent
    );

    LogUtils.debug('peopleSearchUpdate ', commerceContent, commerceContent?.getMainRaw()?.tempClient?.processed?.person);

    this.userInput.fname = commerceContent.content[0].contentInfo.firstName;
    this.userInput.lname = commerceContent.content[0].contentInfo.lastName;
    this.userInput.state = commerceContent.content[0].contentInfo.searchContent[0].contentInfo.state;
    this.userInput.extId = commerceContent.content[0].contentInfo.id;

    let person = new SearchedPerson();
    person.lName = this.userInput.lname;
    person.fName = this.userInput.fname;
    person.extId = this.userInput.extId;
    
    this.serviceHelperService.peopleSearchService.setSearchPersonToSearch(this.serviceHelperService, person, true);
    return this.next(false);
 
  }

  autoTab() {
    this.currentTab = 'all';
    // Object.keys(this.tabs).some((key) => {
    //   if (this.commerceContentInfos[key].commerceContents.length > 0) {
    //     this.currentTab = key;
    //     return true;
    //   }
    // });
  }

  checkOrder(): Promise<any> {
    return this.getCommerceOrdersByPageCategory()
      .then((commerceOrders: CommerceOrder[]) => {
        this.commerceOrders = commerceOrders;
        const [firstCommerceOrder] = commerceOrders;
        if (firstCommerceOrder) {
          this._setSearchAccess(firstCommerceOrder);
        }
        if (commerceOrders && commerceOrders.length > 0) {
          this.hasAccess = true;
        }
      });
  }


  private _setSearchAccess(commerceOrder: CommerceOrder) {
    this.isNameSearchAllowed = commerceOrder.tempClientSecured?.processes?.nameSearch?.canProcess ?? true;
    this.isPhoneSearchAllowed = commerceOrder.tempClientSecured?.processes?.phoneSearch?.canProcess ?? true;
  }


  doFindContents(tab, query): Promise<any> {

    query.type = PeopleSearchContentHelper.COMMERCE_CONTENT_TYPE;
    return this.serviceHelperService.commerceService
      .findContents({
        raw: true,
        status: ModelBase.STATUS.fulfilled,
        selectQuery: query,
        page: this.commerceContentInfos[tab].page
      })
      .then((responseEvent: ResponseEvent) => {
        this.commerceContentInfos[tab].commerceContents = this.commerceContentInfos[tab].commerceContents.concat(responseEvent.getDocs());
        this.commerceContentInfos[tab].loadMoreFlag = responseEvent.data.next;
      })
      .catch((e) => {
        LogUtils.error(e);
      });
  }

  doFindPastSearches(query): Promise<any> {
    query.type = PeopleSearchContentHelper.COMMERCE_CONTENT_TYPE;
    return this.serviceHelperService.commerceService
      .findPastSearches({
        status: "fulfilled",
        selectQuery: query,
        page: 0,
      })
      .then((responseEvent: ResponseEvent) => {
        this.pastSearches = responseEvent.data.searchInfo;
      })
      .catch((e) => {
        LogUtils.error(e);
      });
  }

  findAllContents(): Promise<any> {
    let query = {  };
    return this.doFindContents(this.tabs.all, query);
  }

  findTeaserSearch(): Promise<any> {
    let query = {teaser: true};
    return this.doFindPastSearches(query);
  }

  findUpdatedContents(): Promise<any> {
    let query = {
      "data.updatedTimestamp": { DOLLAR_GT: 0 },
    };
    return this.doFindContents(this.tabs.updates, query);
  }

  findMonitoredNameSearch(): Promise<any> {
    let query = {
      productKey: peopleSearchProductKeys.nameSearch,
      "data.subscribed": true,
    };
    return this.doFindContents(this.tabs.monitoredNameSearchs, query);
  }

  // findMonitoredPhoneSearch(): Promise<any> {
  //   let query = {
  //     productKey: peopleSearchProductKeys.phoneSearch,
  //     "data.subscribed": true,
  //   };
  //   return this.doFindContents(this.tabs.monitoredPhoneSearchs, query);
  // }

  hasTitle(commerceContent: CommerceContent) {
    if (
      commerceContent &&
      commerceContent.tempClient &&
      commerceContent.tempClient.raw &&
      commerceContent.tempClient.raw.person &&
      commerceContent.tempClient.raw.person[0]
    ) {
      return true;
    }

    return false;
  }

  // getTitleByCommerceContent(commerceContent: CommerceContent) {
  //   return landingPeopleSearchMemberUtils.getTitleByCommerceContent(commerceContent);
  // }

  // getShortTitleByCommerceContent(commerceContent: CommerceContent) {
  //   return landingPeopleSearchMemberUtils.getShortTitleByCommerceContent(commerceContent);
  // }

  // getSubTitleByCommerceContent(commerceContent: CommerceContent) {
  //   return landingPeopleSearchMemberUtils.getSubTitleByCommerceContent(commerceContent);
  // }

  // getContentUrl(commerceContent: CommerceContent) {
  //   return landingPeopleSearchMemberUtils.getContentUrl(commerceContent);
  // }

  findCandidates(): Promise<any> {
    return this.serviceHelperService.commerceService
      .findContents({
        raw: true,
        selectQuery: {
          type: PeopleSearchContentHelper.COMMERCE_CONTENT_TYPE,
          subType: PeopleSearchContentHelper.COMMERCE_CONTENT_SUBTYPE.candidate,
          teaser: true,
        },
      })
      .then((responseEvent: ResponseEvent) => {
        this.candidateCommerceContents = responseEvent.getDocs();
        this.candidateCommerceContents.forEach(this.pushCandidatePersons.bind(this));
        LogUtils.debug('findCandidates', this.candidateCommerceContents, this.candidatePersons);
      })
      .then((e) => {
        if (this.candidatePersons.length === 0) {
          return this.findCandidatesFromFirstReport();
        }
      })
      .catch((e) => {
        LogUtils.error(e);
      });
  }

  findCandidatesFromFirstReport() {
    return this.serviceHelperService.commerceService
      .findContents({
        raw: true,
        perPage: 1,
        selectQuery: {
          type: PeopleSearchContentHelper.COMMERCE_CONTENT_TYPE,
          teaser: false,
        },
      })
      .then((responseEvent: ResponseEvent) => {
        this.candidateCommerceContents = responseEvent.getDocs();
        this.candidateCommerceContents.forEach(this.pushCandidatePersons.bind(this));
        LogUtils.debug('findCandidatesFromFirstReport', this.candidateCommerceContents, this.candidatePersons);
      })
      .catch((e) => {
        LogUtils.error(e);
      });
  }

  // for idProtection Offer and content processing
  idProtectionFeatures: any = {}

  // check if id protection offer is applicable, if yes then find it's commerceContent and update the commerceStorage accordingly
  findIdProtectionContentIfApplicable() {
    this.spyIdHelper.getIdProtectionEnabledFeatures(this.uxComposite, this.serviceHelperService)
    .then((idProtectionFeatures) => {
      if (idProtectionFeatures?.idDarkWebMonitoring?.enabled ||
        idProtectionFeatures?.idProtectionTheftRecovery?.enabled ||
        idProtectionFeatures?.idProtectionLostWallet?.enabled ||
        idProtectionFeatures?.idBreachScanReport?.enabled ||
        idProtectionFeatures?.idProtectionFraudInsurance?.enabled ||
        idProtectionFeatures?.idProtectionFraudInsurance1M?.enabled
        ) {          
        this.processCommerceStorage(idProtectionFeatures)
        //return;
      }
    })
    .catch((e) => {
      LogUtils.error(e)
    })
    .finally(() => {
      const commerceStorage = this.serviceHelperService.commerceService.getStorage()

      const idProtectionCommerceContentId = commerceStorage?.idProtectionContentId;
      const idProtectionFrontEnabled = this.uxHelper.getValue("comp.member.idProtection.enable") === 'true'? true: false;  
      const idProtectionBackendEnabled = this.uxHelper.getValue("comp.member.idProtection.registration.enable") === 'true'? true: false;    
      if (idProtectionBackendEnabled && idProtectionFrontEnabled && idProtectionCommerceContentId) {
        this.idProtectionEnabled = true;
      }
      this.serviceHelperService.commerceService.updateStorage({
        ...commerceStorage,
        idProtectionFeatures: this.idProtectionFeatures
      })
    })
  }

  processCommerceStorage(idProtectionFeatures) {
    const commerceStorage = this.serviceHelperService.commerceService.getStorage()

    // getting commerceContent for IdProtection
    this.serviceHelperService.commerceService.findContents({
      userId: this.serviceHelperService.authenticationService.getUserId(),
      type: "idProtection",
      raw: true
    })
    .then((responseEvent: ResponseEvent) => {
      const commerceContent: CommerceContent = responseEvent.getSingleDoc()
      // Assign enabled features only if user have idProtection enrollment
      if (commerceContent?._id) {
        this.idProtectionFeatures = idProtectionFeatures;
      }
      this.setupMonitoringAndAlertsInfo(commerceStorage, commerceContent);  // commerceContent?._id, commerceContent?.content?.monitored_data?.monitoredSpyDwbCommerceContentIds);
    })
    .catch((e) => {
      LogUtils.error('error in getting commerceContent', e)
      this.serviceHelperService.alertService.error("Error");
    })
  }

  async setupMonitoringAndAlertsInfo(commerceStorage, commerceContent) {
    //const commerceStorage = this.serviceHelperService.commerceService.getStorage()
    
    const idProtectionCommerceContentId = commerceContent?._id;
    const claimedSpyDwbCommerceContentIds = commerceContent?.content?.monitored_data?.monitoredSpyDwbCommerceContentIds;

    let unreadAlerts = 0;
    const promises: Promise<void>[] = [];

    if(claimedSpyDwbCommerceContentIds) { 
      for(const spyDwbCommerceContentId of claimedSpyDwbCommerceContentIds) {
        const promise = this.serviceHelperService.commerceService.findContent(spyDwbCommerceContentId)
        .then((commerceContent: CommerceContent ) => {
          unreadAlerts = unreadAlerts + commerceContent?.content?.monitored_data?.unreadAlerts || 0
        })
        .catch((e) => {
          LogUtils.error('error in getting commerceContent', e)
          this.serviceHelperService.alertService.error("Error");
        });
        promises.push(promise);
      }
    }

    if(idProtectionCommerceContentId) { 
      const promise = this.serviceHelperService.commerceService.findContent(idProtectionCommerceContentId)
      .then((commerceContent: CommerceContent ) => {
        unreadAlerts = unreadAlerts + commerceContent?.content?.monitored_data?.unreadAlerts || 0
      })
      .catch((e) => {
        LogUtils.error('error in getting commerceContent', e)
        this.serviceHelperService.alertService.error("Error");
      });  
      promises.push(promise);
    } 

    await Promise.all(promises);
    
    this.serviceHelperService.commerceService.updateStorage({
      ...commerceStorage,
      idProtectionFeatures: this.idProtectionFeatures,
      idProtectionContentId: commerceContent?._id,  // commerceContentId for idProtection enrollment
      reportClaimedContentId: commerceContent?.data?.idProtection?.reportClaimedContentId, // commerceContentId of the report claimed for dwb id protection
      claimedSpyDwbCommerceContentId: commerceContent?.data?.idProtection?.spyDwbCommerceContentId,
      claimedSpyDwbCommerceContentIds: commerceContent?.content?.monitored_data?.monitoredSpyDwbCommerceContentIds || [],
      monitoredPeopleSearchCommerceContentIds: commerceContent?.content?.monitored_data?.monitoredPeopleSearchCommerceContentIds || [],
      idClaimBannerRejected: commerceContent?.data?.idProtection?.idClaimBannerRejected,
      idClaimPopupRejected: commerceContent?.data?.idProtection?.idClaimPopupRejected,
      unreadAlerts: unreadAlerts,
    });

  }

  searchPerson(person: SearchedPerson) {
    this.serviceHelperService.peopleSearchService.setSearchPersonToSearch(
      this.serviceHelperService,
      person
    );
    redirectHelper.redirect(
      this.serviceHelperService,
      "/" + this.customClientPaths.memberNameSearchLoader
    );
    return false;
  }

  findcountContentUpdated() {
    return this.serviceHelperService.commerceService
      .countContentUpdated()
      .then((count) => {
        this.contentUpdatedCount = count;
      });
  }

  showHideSearchBox(flag) {
    $("#navsearch").collapse(flag ? "show" : "hide");
  }

  private pushCandidatePersons(commerceContent) {
    try {
      if (commerceContent.raws) {
        const person = commerceContent.getMainRaw().tempClient?.processed?.person?.[0];
        if (person?.associates?.length) {
          person.associates.forEach((person) => {
            this.candidatePersons.push(person);
          });
        }
        if (person?.relatives?.length) {
          person.relatives.forEach((person) => {
            this.candidatePersons.push(person);
          });
        }
      }
    } catch (e) {
      LogUtils.error(e);
    }
  }
}
