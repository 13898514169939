import { CASX_ALERT_SUP_COMPONENTS_UX_5_2_THEME_DECLARATIONS } from './casx-alert';
import { FAQ_CARD_SUP_COMPONENTS_UX_5_2_THEME_DECLARATIONS } from './faq-card';
import { FREE_TRIAL_TOP_SUP_SUP_COMPONENTS_UX_5_2_THEME_DECLARATIONS } from './free-trial-top-sup';
import { ICON_LABEL_BENEFITS_CARD_SUP_COMPONENTS_UX_5_2_THEME_DECLARATIONS } from './icon-label-benefits-card';
import { ICON_LABEL_CARD_SUP_COMPONENTS_UX_5_2_THEME_DECLARATIONS } from './icon-label-card';
import { MEMBERSHIP_CARD_SUP_COMPONENTS_UX_5_2_THEME_DECLARATIONS } from './membership-card';
import { PAYMENT_DISCLOSURE_SUP_COMPONENTS_UX_5_2_THEME_DECLARATIONS } from './payment-disclosure';
import { PAYMENT_INFO_SUP_COMPONENTS_UX_5_2_THEME_DECLARATIONS } from './payment-info';
import { PAYMENT_SUBMIT_SUP_COMPONENTS_UX_5_2_THEME_DECLARATIONS } from './payment-submit';
import { PROFILE_CARD_SUP_COMPONENTS_UX_5_2_THEME_DECLARATIONS } from './profile-card';
import { SIDE_CARDS_SUP_COMPONENTS_UX_5_2_THEME_DECLARATIONS } from './side-cards';
import { TOP_SUP_SUP_COMPONENTS_UX_5_2_THEME_DECLARATIONS } from './top-sup';
import { USER_INFO_SUP_COMPONENTS_UX_5_2_THEME_DECLARATIONS } from './user-info';

export const SUP_COMPONENTS_UX_5_2_THEME_DECLARATIONS = [
    ...CASX_ALERT_SUP_COMPONENTS_UX_5_2_THEME_DECLARATIONS,
    ...FAQ_CARD_SUP_COMPONENTS_UX_5_2_THEME_DECLARATIONS,
    ...FREE_TRIAL_TOP_SUP_SUP_COMPONENTS_UX_5_2_THEME_DECLARATIONS,
    ...ICON_LABEL_BENEFITS_CARD_SUP_COMPONENTS_UX_5_2_THEME_DECLARATIONS,
    ...ICON_LABEL_CARD_SUP_COMPONENTS_UX_5_2_THEME_DECLARATIONS,
    ...MEMBERSHIP_CARD_SUP_COMPONENTS_UX_5_2_THEME_DECLARATIONS,
    ...PAYMENT_DISCLOSURE_SUP_COMPONENTS_UX_5_2_THEME_DECLARATIONS,
    ...PAYMENT_INFO_SUP_COMPONENTS_UX_5_2_THEME_DECLARATIONS,
    ...PAYMENT_SUBMIT_SUP_COMPONENTS_UX_5_2_THEME_DECLARATIONS,
    ...PROFILE_CARD_SUP_COMPONENTS_UX_5_2_THEME_DECLARATIONS,
    ...SIDE_CARDS_SUP_COMPONENTS_UX_5_2_THEME_DECLARATIONS,
    ...TOP_SUP_SUP_COMPONENTS_UX_5_2_THEME_DECLARATIONS,
    ...USER_INFO_SUP_COMPONENTS_UX_5_2_THEME_DECLARATIONS,
    
];
