import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter, NgModule } from '@angular/core';
import { MiniButtonComponentModule } from '@member/peopleSearch/components/mini/button/button.component';
import { TutorialModalComponentModule } from '../tutorialModal/tutorialModal.component';

@Component({
  selector: 'app-next-report',
  templateUrl: 'nextReport.component.html',
  styleUrls: ['nextReport.component.scss'],
})
export class NextReportComponent implements OnInit {
  @Input() title: string;
  @Input() desc: string;
  @Input() imgUrl: string;
  @Output() onNext = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}

  goNext() {
    this.onNext.emit();
    window.scrollTo(0, 0);
  }
}

@NgModule({
  declarations: [NextReportComponent],
  imports: [CommonModule, MiniButtonComponentModule, TutorialModalComponentModule],
  exports: [NextReportComponent],
})
export class NextReportComponentModule {}
