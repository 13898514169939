import {Injectable} from "@angular/core";

import { RemoveInfoAlertType, RemoveInfoRequestParam, RemoveInfoUtils } from "../../common/utils/removeInfoUtils";
import { AuthenticationService, JsonService, UxcService } from ".";
import { UserRemoveInfoSegment } from "../../common/models/user/userRemoveInfo";
import { LogUtils } from "../../common/utils/logUtils";
import { redirectHelper } from "../helper/redirectHelper";
import { clientPaths } from "../../common/helpers/pathHelpers";
import { ServiceHelperService } from "./serviceHelper.service";
import { customClientPaths, customServerPaths } from "../../common/custom/customPathHelpers";
import { ResponseEvent } from "../../common/event/responseEvent";
import { CommerceContent } from "../../common/models/commerce/commerceContent";
import { PeopleSearchService } from "../../clientCommon/custom/services/peopleSearch.service";
import { peopleSearchProductKeys } from "../../common/custom/peopleSearch/peopleSearchProductKeys";
import { loginInterstitialClickMode, LoginInterstitialHelper, loginInterstitialProvider } from "../../clientCommon/helper/loginInterstitialHelper";
import { TrackingService } from "./tracking.service";

@Injectable()
export class RemoveInfoService {
  constructor(
    private authentication: AuthenticationService,
    private peopleSearch: PeopleSearchService,
    private jsonService: JsonService,
    private trackService: TrackingService,
    private uxc: UxcService,
  ) {}

  private inProgressHandleAction: boolean = false;

  private alreadyPayment: boolean | null = null;

  private alreadySignup: boolean | null = null;

  private readonly updateModeSessionKey: string = 'removeInfoUpdateMode';

  isShowInterstitial(): boolean {
    const enableInterstitial = RemoveInfoUtils.isEnableInterstitial(
      this.uxc.uxComposite,
    );

    let user;

    try {
      user = this.authentication.getUser();
    } catch (e) {
      LogUtils.error('RemoveInfoService.showInterstitial getUser Error', e);
    }

    return enableInterstitial && user?.removeInfo?.segment === UserRemoveInfoSegment.D;
  }

  clearState() {
    this.alreadyPayment = null;
    this.alreadySignup = null;
  }

  clearPaymentState() {
    this.alreadyPayment = null;
  }

  clearSignupState() {
    this.alreadySignup = null;

    this.setUpdateMode(false);

    this.peopleSearch.clear();
  }

  isUpdateMode() {
    return sessionStorage.getItem(this.updateModeSessionKey) === 'true';
  }

  setUpdateMode(flag: boolean) {
    if (flag) {
      sessionStorage.setItem(this.updateModeSessionKey, 'true');
    } else {
      sessionStorage.removeItem(this.updateModeSessionKey);
    }
  }

  async hasPayment(options?: { 
    throwErrorFlag?: boolean,
    clearPrevState?: boolean,
   }): Promise<boolean> {
    if (options?.clearPrevState) {
      this.clearPaymentState();
    }

    if (this.alreadyPayment !== null) {
      return this.alreadyPayment;
    }

    let commerceOrders = [];

    try {
      commerceOrders = await this.peopleSearch.hasOrder(
        [peopleSearchProductKeys.removeInfo],
      );
    } catch (e) {
      commerceOrders = [];

      if (e === false) {
        // No Order Case
      } else {
        LogUtils.error('RemoveInfoService hasOrder fail', e);

        if (options?.throwErrorFlag) {
          throw e;
        }
      }
    }

    this.alreadyPayment = RemoveInfoUtils.checkPaidByOrders(commerceOrders);

    return this.alreadyPayment;
  }

  async hasContent(): Promise<boolean> {
    if (this.alreadySignup !== null) {
      return this.alreadySignup;
    }


    this.alreadySignup = !!await this.findRemoveInfo();

    return this.alreadySignup;
  }

  async handleAccept(helperService: ServiceHelperService): Promise<void> {
    this.handleAction(async () => {
      await this.updateSegment(UserRemoveInfoSegment.A);

      const trackParam = LoginInterstitialHelper.makeLIClickTrackParam(
        loginInterstitialProvider.removeInfo,
        loginInterstitialClickMode.accept,
      );

      this.trackService.report(trackParam);

      await redirectHelper.redirect(helperService, '/' + customClientPaths.memberRemoveInfoPayment);
    });
  }

  async handleDecline(helperService: ServiceHelperService, ignoreRedirect?: boolean) {
    this.handleAction(async () => {
      await this.updateSegment(UserRemoveInfoSegment.B);

      const trackParam = LoginInterstitialHelper.makeLIClickTrackParam(
        loginInterstitialProvider.removeInfo,
        loginInterstitialClickMode.decline,
      );

      this.trackService.report(trackParam);

      if (!ignoreRedirect) {
        await redirectHelper.redirect(helperService, '/' + clientPaths.member);
      }
    });
  }

  async handleContinueToMember(helperService: ServiceHelperService) {
    this.handleAction(async () => {
      await this.updateSegment(UserRemoveInfoSegment.C);

      await redirectHelper.redirect(helperService, '/' + clientPaths.member);
    });
  }

  async requestRemoveInfo(params: RemoveInfoRequestParam) {
    try {
      return this.jsonService.json(customServerPaths.contentRemoveInfoRequest, {
        param: { 
          ...params,
        },
      });
    } catch (e) {
      LogUtils.error('RemoveInfoService.requestRemoveInfo Error', e);
    }
  }

  async findRemoveInfo(): Promise<CommerceContent | null> {
    try {
      const result = await this.jsonService.json(customServerPaths.contentRemoveInfoFind, {});

      if (result instanceof ResponseEvent) {
        return result.getDocs()?.[0] ?? null;
      }

      return null;
    } catch (e) {
      LogUtils.error('RemoveInfoService.requestRemoveInfo Error', e);

      return null;
    }
  }

  async updateSegment(segment: UserRemoveInfoSegment): Promise<void> {
    try {
      const user = this.authentication.getUser();

      await this.jsonService.json(customServerPaths.contentRemoveInfoSegment, {
        param: { 
          segment,
        },
      });

      user.removeInfo.segment = segment;

      const removeInfo = this.uxc.uxComposite.get('code.user.removeInfo');

      if (removeInfo) {
        removeInfo['segment'] = segment;
      }

      this.authentication.updateUser(user);
    } catch (e) {
      LogUtils.error('RemoveInfoService updateSegment error', e);
    }
  }

  async sendAlert(param: {
    alertType: RemoveInfoAlertType,
  }) {
    try {
      await this.jsonService.json(customServerPaths.contentRemoveInfoAlert, {
        param,
      });
    } catch (e) {
      LogUtils.error('RemoveInfoService.sendAlert Error', e);
    }
  }

  private async handleAction(callback: () => Promise<void>) {
    if (this.inProgressHandleAction) {
      return;
    }

    this.inProgressHandleAction = true;

    try {
      await callback();
    } catch (e) {
      LogUtils.error('RemoveInfoService handleAction Error', e);
    }

    this.inProgressHandleAction = false;
  }
}
