import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import { CommonModule, ViewportScroller } from '@angular/common';
import {BaseComponent} from "../../../../../clientCommon/components/BaseComponent";
import {ServiceHelperService} from "../../../../../clientCommon/services/serviceHelper.service";
import {ActivatedRoute} from "@angular/router";
import { SearchResultPeopleSearch } from "../../../../peopleSearch/searchResultPeopleSearch";
import { UxHelper } from "../../../../../clientCommon/helper/uxHelper";
import { SearchResultPeopleSearchSales } from "../../../../../app/sales/peopleSearch/searchResultPeopleSearchSales";

@Component({
  selector: 'ux-2-0-searchResult-name-search-optOut',
  templateUrl: "./searchResult.component.html",
  styleUrls: ['./searchResult.component.scss'],
})

/**
 * @fileOverview show the search results on the requested name search for optOut by the user
 * @extends SearchResultPeopleSearchSales to have all the required properties
 */
export class Ux2Dot0SearchResultOptOutNameSearchComponent extends SearchResultPeopleSearchSales implements OnInit, OnDestroy {


  /**
 *
 * @param serviceHelperService will provide access to different required services such as authentication service, uxc service etc.
 * @param activatedRoute provides access to information about a route associated with a component that is loaded in an outlet.
 *
 */
  constructor(serviceHelperService: ServiceHelperService,
              activatedRoute: ActivatedRoute, private viewportScroller: ViewportScroller) {
    super(serviceHelperService, activatedRoute);
    this.pageCategory = BaseComponent.PAGE_CATOGORY.nameSearchOptOut;
    this.locations = this.addressField.getAdministrativeAreaOptionsArray().map(item => ({ ...item, count: 0 }));
  }
  @Input() base: SearchResultPeopleSearch;
  @Input() uxHelper: UxHelper;

  pageNumber = 0;
  totalCount = 0;
  startIndex = 0;
  countsByState = null;
  locations: { code: string; name: string; count: number; }[] = [];
  showAllLocation: boolean = false;
  locationLimit: number = 10;
  selectedStateLocation : string = '';
  searchedPersons = [];
  saveLocations =[];

  ngOnInit() {

    return this.onInit().then(() => {
      this.searchedPersons = this.commerceContent.getMainRaw().tempClient.processed.person;
    });
  }

  ngOnDestroy() {
    return super.onDestroy();
  }
  get adsDisplayInterval() {
    return this.uxHelper.getUxcomp('ad.bottom.1.config')?.interval;
  }

  goToAnchor(anchor: string) {
    this.viewportScroller.scrollToAnchor(anchor);
  }

  nextPage() {
    if (this.startIndex + this.base.searchResultLimit < this.totalCount) {
      this.startIndex += this.base.searchResultLimit;
      this.pageNumber ++;
    }
  }

  prevPage() {
    if (this.startIndex > 0) {
      this.startIndex -= this.base.searchResultLimit;
      this.pageNumber --;
    }
  }

  getLocations() {
    if(this.commerceContent){

      let people = this.commerceContent.getMainRaw().tempClient.processed.person;

      this.totalCount = this.commerceContent?.getMainRaw()?.tempClient?.processed?.person?.length;

      if (!this.countsByState) {
        const counts = {};
        this.locations.unshift({code: 'ALL', name: 'ALL', count: this.totalCount});

        this.locations.forEach(location => {
          counts[location.code] = 0;
        });
        counts['ALL'] = this.totalCount;
        people.forEach((x: any) => {
          if (x.cityState){
            this.saveLocations.push(x.cityState);
          }
        })

        people.forEach(person => {
          let state = null;
          if (person.tempClient && person.tempClient.address)
            state = person.tempClient.address.state;
          const index = this.locations.findIndex(location => location.code === state);
          if (index > -1)
            this.locations[index].count += 1;
          if (state)
            counts[state] = counts[state] + 1;
        });
        this.locations.sort((a, b) => b.count - a.count);
        this.countsByState = counts;
      }
    }
    return this.showAllLocation ? this.locations : this.locations.slice(0, this.locationLimit);
  }

  resultForState(addr) {
    this.setSearchedPerson();
    if (addr.code !== 'ALL') {

      this.searchedPersons = this.searchedPersons.filter(s => s.tempClient.address?.state == addr.code);

      if(!this.countsByState || this.countsByState[addr.code] == 0) return;

      if(this.selectedStateLocation == addr.code){
        this.selectedStateLocation = '';
      } else {
        this.selectedStateLocation = addr.code;
      }
    }
  }

  setSearchedPerson () {
    this.searchedPersons = this.commerceContent?.getMainRaw()?.tempClient?.processed?.person;
  }

  switchShowLocation() {
    this.showAllLocation = !this.showAllLocation;
  }

  shouldDisplayAd(i: number) {
    if ((!this.adsDisplayInterval) || (i < this.adsDisplayInterval)) return false;
    return parseFloat((i % this.adsDisplayInterval).toString()) === 0
  }

}
