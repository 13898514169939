import { Component, Directive, Input, NgModule } from '@angular/core';

@Directive({
  selector: 'wiHeroSectionImage',
})
export class HeroSectionImageDirective {}

@Directive({
  selector: 'wiHeroSectionContent',
})
export class HeroSectionContentDirective {}

@Component({
  selector: 'wi-hero-section',
  templateUrl: './hero-section.component.html',
  styleUrls: ['./hero-section.component.scss'],
})
export class HeroSectionComponent {
  @Input() imageLocation: 'start' | 'end' = 'end';
  @Input() imageUrl: string;
}
