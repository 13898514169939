import { Component, OnInit, OnDestroy, NgModule } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UxHelper } from '../../../../../../clientCommon/helper/uxHelper';
import { BaseComponent } from '../../../../../../clientCommon/components/BaseComponent';
import { SpinnerService } from '../../../../../../clientCommon/services/spinner.service';
import { ServiceHelperService } from '../../../../../../clientCommon/services/serviceHelper.service';
import { CommonModule } from '@angular/common';
import { MemberHeaderComponentModule } from '@member/components/memberHeader.component';
import { MemberFooterComponentModule } from '@member/components/memberFooter.component';

@Component({
    selector: 'app-redirect-warning',
    templateUrl: './redirect-warning.component.html',
    styleUrls: ['./redirect-warning.component.scss'],
    standalone: false
})
export class RedirectWarningComponent extends BaseComponent implements OnInit, OnDestroy {
  thirdPartyURL: string = '';
  uxHelper: UxHelper = new UxHelper();
  page: string = '';
  pageType: string = '';
  pageCategory: string = '';
  constructor(
    activatedRoute: ActivatedRoute,
    serviceHelperService: ServiceHelperService,
    private spinnerService: SpinnerService
  ) {
    super(serviceHelperService, activatedRoute);
    this.page = BaseComponent.PAGE.detail;
  }
  ngOnInit() {
    super.baseInit().then(() => {
      let urrl = '';
      this.spinnerService.unspin();
      this.activatedRoute.queryParams.subscribe((params) => {
        if (params && params.thirdpartyURL) {
          urrl = params.thirdpartyURL;
          setTimeout(() => {
            this.goToLink(params.thirdpartyURL);
          }, 5000);
        }
      });
    });
  }

  ngOnDestroy() {}

  goToLink(urrl) {
    window.open(urrl, '_self');
  }
}

@NgModule({
  declarations: [RedirectWarningComponent],
  imports: [CommonModule, MemberHeaderComponentModule, MemberFooterComponentModule],
  exports: [RedirectWarningComponent],
})
export class RedirectWarningComponentModule {}
