import { Component, Input, NgModule, OnInit, Renderer2, NgZone, ElementRef, ViewChild, HostListener, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceHelperService } from '../../../clientCommon/services/serviceHelper.service';
import { BaseDirective } from '../../../clientCommon/directives/BaseDirective';
import { User } from '@common/models/user/user';
import { CommonModule } from '@angular/common';
import { CommerceToken } from '@common/models/commerce/commerceToken';
import { LogUtils } from '../../../common/utils/logUtils';
import { UxHelper } from '../../../clientCommon/helper/uxHelper';

declare var FlowContainer: any; 
@Component({
  selector: 'fluent-ad-loader',
  templateUrl: './fluent-ad-loader.component.html',
  styleUrls: ['./fluent-ad-loader.component.scss']
})
export class FluentAdLoaderComponent extends BaseDirective implements OnInit, AfterViewInit {
  @Input() domain;
  @Input() user:User;
  @Input() commerceToken:CommerceToken;
  @Input() uxHelper: UxHelper;
  @ViewChild('fluentContainer') public fluentContainerDiv: ElementRef;
  @ViewChild('fluentAd') public fluentAdDiv: ElementRef;
  @ViewChild('fluentAdTab') public fluentAdTabDiv: ElementRef;
  @ViewChild('iframefluentAd') public iframefluentAd: ElementRef;

  isFluentAdEnabled;
  flowContainerScriptLoaded: boolean;
  //boxWidth: 
  scriptUrl = 'https://api.tnapplications.com/assets/f431c48b-e0da-48f2-8b9a-3175b5fa507c/flow-container.js';
  fc: any;

  email: string = 'minsoub@gmail.com';
  zip: string = '51200';
  
  previousConstrain: {width: string, height: string};

  constructor(serviceHelperService: ServiceHelperService,
    activatedRoute: ActivatedRoute,private renderer: Renderer2,private ngZone: NgZone) {
      super(serviceHelperService, activatedRoute);
     }

  ngOnInit(): void {    
      this.isFluentAdEnabled = true;
      this.email = this.user?.email;
      this.zip = this.commerceToken?.billingAddress?.zip;
  }

  ngAfterViewInit() {
    this.setSizeFluentAd();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.setSizeFluentAd();
  }

  private setSizeFluentAd() {
    try {
      const screenWidth = window.innerWidth;

      // const constrains = {
      //   "380": {
      //     "width": "100%",
      //     "height": "300px"
      //   },
      //   "580": {
      //     "width": "50%",
      //     "height": "500px"
      //   }
      // };

      const constrains = this.uxHelper.getUxcomp('fluentAd.constrains');

      if (!constrains) {
        return;
      }

      const selectConstrain = () => {
        let selected;
        const keys = Object.keys(constrains);

        for (let i = 0; i < keys.length; i += 1) {
          const width = parseInt(keys[i]);

          if (screenWidth <= width) {
            selected = constrains[keys[i]];
            break;
          }

          if (i === keys.length - 1 && !selected) {
            selected = constrains[keys[i]];
          }
        }

        return selected;
      };

      const constrain = selectConstrain();

      if (!constrain || !constrain?.width || !constrain?.height) {
        return;
      }

      if (this.previousConstrain?.width === constrain?.width && this.previousConstrain?.height === constrain?.height) {
        return;
      }

      this.previousConstrain = constrain;

      this.fluentAdDiv.nativeElement.style.width = constrain.width;
      this.fluentAdDiv.nativeElement.style.height = constrain.height;
      this.fluentAdTabDiv.nativeElement.style.width = constrain.width;
      if (constrain?.align) {
        this.fluentContainerDiv.nativeElement.style.alignItems = constrain.align;
      } else {
        this.fluentContainerDiv.nativeElement.style.alignItems = 'center';
      }
    } catch (e) {
      LogUtils.error('FluentAdLoaderComponent.setSizeFluentAd Error', e);
    }
  }

  private loadScript(url: string): void {

    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    script.referrerPolicy = 'no-referrer-when-downgrade';
    script.id='fluentAd';
    script.onload = () => {
      const fc = new FlowContainer({
        params: {
            email: '${this.email}',
            zip: '${this.zip}'
        }
      });
      fc.show();

      window.addEventListener('flowContainerClosed', function() {
            // perform any desired actions when the FlowContainer modal is closed
      }); 
       
    };
    script.onerror = (error) => {
      LogUtils.error(error);
    };
    (<HTMLInputElement>document.getElementById("iframefluentAd")).appendChild(script);
  }

  onLoad(iframe) {
    const iframeDoc = iframe.contentDocument || iframe.contentWindow;
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    this.renderer.setProperty(script, 'textContent', 
      `const fc = new FlowContainer({
                        params: {
                            email: '${this.email}',
                            zip: '${this.zip}'
                        }
                  });
                  fc.show();

                  window.addEventListener('flowContainerClosed', function() {
                        // perform any desired actions when the FlowContainer modal is closed
                  });

                  function init(){
                    var doc = document.getElementById('fluentAd');                  
                    if(doc.offsetHeight == 0){                    
                    } else {                 
                      pageHeight = doc.offsetHeight;                    
                      parent.document.getElementById("iframefluentAd").height = pageHeight+40+"px";                    
                    }                    
                  }                    
                  window.onload = function(){                    
                    init();                    
                  }
    `);
    iframeDoc.querySelector("#AdView").appendChild(script);
  }
}
@NgModule({
  declarations: [FluentAdLoaderComponent],
  imports: [CommonModule],
  exports: [FluentAdLoaderComponent],
})
export class FluentAdLoaderComponentModule {}