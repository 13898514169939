import { Component, OnInit, Input, NgModule } from "@angular/core";
import { User } from "../../../../../common/models/user/user";
import { CommerceContent } from '../../../../../common/models/commerce/commerceContent';
import { SpyIdHelper } from "../../../../../clientCommon/helper/spyIdHelper";
import { CommonModule } from "@angular/common";
import moment from "moment";
import 'moment-timezone';
import { clientPaths } from "../../../../../../../common/helpers/pathHelpers";
import { SearchedPerson } from "@common/custom/models/peopleSearch/searchedPerson";
import { customClientPaths } from "@common/custom/customPathHelpers";
import { UxTemplateModule } from 'app/components/uxTemplate.component';


@Component({
    selector: "app-member-new-benefits",
    templateUrl: "./newMemberBenefits.component.html",
    styleUrls: ["./newMemberBenefits.component.scss"],
    standalone: false
})
export class MemberBenefitsNewComponent implements OnInit {
    @Input() base: any;
    //@Input() isPdfPrintView = false;
    @Input() user: any;
    @Input() uxHelper: any;
    @Input() showRemoveInfoBenefit: boolean = false;
    @Input() idProtectionEnabled: boolean = false;
    @Input() left: boolean = false;
    @Input() newSpacingEnabled: boolean = false;

    customClientPaths = customClientPaths;

    constructor() {}

    ngOnInit(): void {
      //this.base.init();
      //this.user = this.base.authService.getUser();
    }
    idProtectionhandleClick() {
        if (this.idProtectionEnabled) {    
          let url = '/'+clientPaths.spyDashboardComponent+'/'+this.user?._id

          location.href=url;
          //this.router.navigateByUrl(url);
        }
    }
}

@NgModule({
    declarations: [MemberBenefitsNewComponent],
    imports: [CommonModule,UxTemplateModule,],
    exports: [MemberBenefitsNewComponent],
  })
  export class MemberBenefitsNewComponentModule {}