import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-icon-selection-reverse-phone',
    templateUrl: './icon-text-reverse-phone.component.html',
    styleUrls: ['./icon-text-reverse-phone.component.css']
})

export class IconTextComponentReversePhone implements OnInit {
    @Input() icon = 'phone';
    @Input() label = 'email';
    @Input() active = false;
    constructor() { }

    ngOnInit() { }
}
