import {Component, Input} from "@angular/core";
import {ServiceHelperService} from "../../../../clientCommon/services/serviceHelper.service";
import {ActivatedRoute} from "@angular/router";
import {UxHelper} from "../../../../clientCommon/helper/uxHelper";
import {SalesHeaderComponent} from "../../../sales/components/salesHeader.component";

@Component({
  selector: 'ux-2-0-sales-header',
  templateUrl: 'salesHeader.component.html',
  styleUrls: ['salesHeader.component.scss'],
})
export class Ux2Dot0SalesHeaderComponent extends SalesHeaderComponent {
  @Input() uxHelper: UxHelper;
  @Input() options: any = {};

  constructor(serviceHelperService: ServiceHelperService,
              activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
  }

  ngOnInit() {
    return super.ngOnInit();
  }

}
