import { Component, Input, NgModule, OnInit } from "@angular/core";
import { ViewportScroller } from "@angular/common";
import { UxComp } from "@ux5.2/types";

@Component({
  selector: "ux-5-2-records-review",
  templateUrl: "./records-review.component.html",
  styleUrls: ["./records-review.component.scss"],
})
export class RecordsReviewComponent implements OnInit {
  @Input() userName: string = null;
  @Input() title: UxComp;
  @Input() subtitle: UxComp;
  @Input() imageUrls: UxComp;
  @Input() content: UxComp;

  constructor(private viewportScroller: ViewportScroller) {}

  ngOnInit(): void {
    this.viewportScroller.scrollToPosition([0, 0]);
  }
}
