import { Component, NgModule, OnInit, signal, WritableSignal, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseDirective } from '../../../../clientCommon/directives/BaseDirective';
import { ServiceHelperService } from '../../../../clientCommon/services/serviceHelper.service';
import { AddressField, AddressUtils } from '../../../../common/utils/addressUtils';
import { phoneUtils } from '../../../../common/utils/phoneUtils';
import { stringUtils } from '../../../../common/utils/stringUtils';
import { peopleSearchProductKeys } from '../../../../common/custom/peopleSearch/peopleSearchProductKeys';
import { customClientPaths } from '../../../../common/custom/customPathHelpers';
import { redirectHelper } from '../../../../clientCommon/helper/redirectHelper';
import { environment } from '../../../../environments/environment';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

const searchTypes = {
  none: 'none',
  nameSearch: 'nameSearch',
  phoneSearch: 'phoneSearch',
} as const;

type SearchTypeParam = typeof searchTypes[keyof typeof searchTypes];

class CustomProduct {
    public static ACTION = {
      redirect: 'redirect',
    };
  
    productType: string;
    name: string;
    action: string;
    value: string;
    forms: {
      [type: string]: any;
    }[] = [];
  
    public static CODE = {
      requestState: 'requestState',
    };
}

@Component({
    selector: 'app-member-keep-search',
    templateUrl: 'memberKeepSearch.component.html',
    styleUrls: ['./memberKeepSearch.component.scss'],
  })
  export class MemberKeepSearchComponent  extends BaseDirective implements OnInit {
    @Input() base: any;

    phoneUtils = phoneUtils;
    addressField: AddressField = AddressUtils.getAddressField('US');
    customerNumber = '';
    loaderEyeTimeout: number = environment.miniDetailsLoaderEyeTimeout; //2000; // loader animated Eye Timeout
  
    stateList = this.addressField;
    ageList = [];
    model: any = {
      fname: '',
      mname: '',
      lname: '',
      city: '',
      state: 'all',
      // age: "",
      phone: '',
      email: '',
      // relativeFName: "",
      // relativeLName: ""
    };

    error = {
      fname: false,
      lname: false,
      phone: false,
    };

    relativesList = [];
  
    allStateObj = [{ code: 'all', name: 'All States' }];
    addressFieldWithAllStateOption;
    customProducts: CustomProduct[] = [];
    selectedCustomProductValues: any = {};
    productType: string;
    selectedPhotos = [];
    swiperIndex = 0;
    showAllRelatives = false;    
    stringUtils = stringUtils;

    searchType: WritableSignal<SearchTypeParam> = signal(searchTypes.none);

    searchTypes = searchTypes;

    constructor(serviceHelperService: ServiceHelperService, private router: Router, activatedRoute: ActivatedRoute) {
        super(serviceHelperService, activatedRoute);
    }

    ngOnInit() {
        //this.customerNumber = this.uxHelper.getValue('comp.brand.customer.phone');
        //this.showAllRelatives = this.isPdfPrintView;
        // creatting state list with all state option
        this.addressFieldWithAllStateOption = [
          ...this.allStateObj,
          ...this.addressField.getAdministrativeAreaOptionsArray(),
        ];
        if (!this.model.state) {
          this.model.state = 'all';
        }
        // creating ageList for dropdown
        let g = 18;
        while (g > 17 && g < 60) {
          this.ageList.push(g);
          g++;
        }
    }

    findOptionByKey(options, key) {
        let chosenOption;
        if (options) {
          options.some((option) => {
            if (option.key === key) {
              chosenOption = option;
              return true;
            }
          });
        }
    
        return chosenOption;
    }

    findCustomProduct(productType) {
        let foundCustomProduct: CustomProduct = null;
        this.customProducts.some((customProduct) => {
          if (customProduct.productType === productType) {
            foundCustomProduct = customProduct;
            return true;
          }
        });
    
        if (foundCustomProduct) {
          if (!this.selectedCustomProductValues[productType]) {
            this.selectedCustomProductValues[productType] = {};
          }
    
          if (foundCustomProduct.forms) {
            foundCustomProduct.forms.forEach((form) => {
              if (form.name && form.default && this.selectedCustomProductValues[productType][form.name] === undefined) {
                let value = form.default;
                if (value === CustomProduct.CODE.requestState) {
                  value = this.uxComposite.requestState;
                }
                this.selectedCustomProductValues[productType][form.name] = value;
                let option = this.findOptionByKey(form.options, value);
                if (option) {
                  foundCustomProduct.value = option.url;
                }
              }
            });
          }
        }
    
        return foundCustomProduct;
    }    

    onSearchAgain() {
      if (!this.validate()) {
        return;
      }

      // copied from memberPeopleSearch.component.ts
      this.productType = peopleSearchProductKeys.nameSearch;
      let url = '/';
      if (this.searchType() === searchTypes.phoneSearch) {
        url += customClientPaths.memberPhoneSearchLoader;
      } else {
        url += customClientPaths.memberNameSearchLoader;
      }

      this.serviceHelperService.peopleSearchService.clear();
      let customProduct = this.findCustomProduct(this.productType);
      if (customProduct) {
        if (customProduct.action === CustomProduct.ACTION.redirect) {
          url = customProduct.value;
        }
      }
      if (this.model?.phone) {
        this.model.phone = this.model?.phone.replace(/[()\s-]/g, '');
      }

      this.serviceHelperService.userInputService.userInput = {
        ...this.model,
      };

      this.serviceHelperService.userInputService.storeUserInput();

      this.serviceHelperService.trackingService.report({
        page: this?.base?.page,
        type: this?.base?.pageType,
        mode: this?.base?.pageCategory,
        action: 'reSearch',
      });

      return redirectHelper.redirect(this.serviceHelperService, url);
    }

    formatPhone(event) {
        event.target.value = this.model.phone = this.phoneUtils.formatPhoneNumber(event.target.value);
    }    

    onChangeInput(field) {
      if (this.isEmptyModel()) {
        this.searchType.set(searchTypes.none);
      } else if (field === 'phone') {
        this.searchType.set(searchTypes.phoneSearch);
      } else {
        this.searchType.set(searchTypes.nameSearch);
      }

      this.validate();
    }

    private validate(): boolean {
      switch (this.searchType()) {
        case 'none':
          this.error.fname = false;
          this.error.lname = false;
          this.error.phone = false;
          return false;
        case 'nameSearch':
          this.error.fname = !this.model.fname;
          this.error.lname = !this.model.lname;
          this.error.phone = false;
          return this.isValidNameSearch();
        case 'phoneSearch':
          const validPhone = this.isValidPhoneSearch();
          this.error.fname = false;
          this.error.lname = false;
          this.error.phone = !validPhone;
          return validPhone;
      }
    }

    private isEmptyModel(): boolean {
      return !this.model.fname && !this.model.mname && !this.model.lname && !this.model.city && !this.model.phone;
    }

    private isValidNameSearch(): boolean {
      return this.model.fname && this.model.lname;
    }

    private isValidPhoneSearch(): boolean {
      return this.phoneUtils.validDigits(this.model.phone);
    }
  }

  @NgModule({
    declarations: [MemberKeepSearchComponent],
    imports: [CommonModule, FormsModule, NgSelectModule],
    exports: [MemberKeepSearchComponent],
  })
  export class MemberKeepSearchComponentModule {}