import {Component, OnDestroy, OnInit} from "@angular/core";
import {LandingPeopleSearchSales} from "../../landingPeopleSearchSales";
import {BaseComponent} from "../../../../../clientCommon/components/BaseComponent";
import {ServiceHelperService} from "../../../../../clientCommon/services/serviceHelper.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  templateUrl: 'landing.component.html'
})

/**
 * LandingPeopleSearchSales render the respective theme/layout component based on current theme
 * @extends LandingPeopleSearchSales which further extends LandingPeopleSearch to have all the required properties i.e delay, addressField, counter, counterInterval...
 */
export class LandingPhoneSearchSalesComponent extends LandingPeopleSearchSales implements OnInit, OnDestroy {
  /**
 *
 * @param serviceHelperService will provide access to different required services such as authentication service, uxc service etc.
 * @param activatedRoute provides access to information about a route associated with a component that is loaded in an outlet.
 *
 */
  constructor(serviceHelperService: ServiceHelperService,
              activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
    this.pageCategory = BaseComponent.PAGE_CATOGORY.phoneSearch;
  }

  ngOnInit() {
    return this.onInit().then(() => {
      this.userInput.state = undefined;
      this.initDone = true;
    });
  }

  ngOnDestroy() {
    return super.onDestroy();
  }
}
