import { Component, OnInit, Input, Output, EventEmitter, NgModule } from "@angular/core";
import { ViewportScroller } from "@angular/common";
import { UxHelper } from "@clientCommon/helper/uxHelper";
import { UxComp } from "@ux5.2/types";

@Component({
  selector: "ux-5-2-tile-list",
  templateUrl: "./tile-list.component.html",
  styleUrls: ["./tile-list.component.scss"],
})
export class TileListComponent implements OnInit {
  @Input() NumberOfSecondsToWait: number = 5;
  @Input() uxHelper: UxHelper;
  @Input() title: UxComp;
  @Input() listItems: string[] = [];
  @Input() listItemsPaths: UxComp;
  @Output() clickEvent = new EventEmitter();
  listCardsSelected = Array(this.listItems.length).fill(false);
  

  constructor(private viewportScroller: ViewportScroller) {}

  ngOnInit(): void { 
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  clickEventOnButton() {
    this.clickEvent.emit();
  }

  cardSelected(i: number) {
    this.listCardsSelected[i] = !this.listCardsSelected[i];
  }
  selectAllCards() {
    this.listCardsSelected = Array(this.listItems.length).fill(true);
  }
}
