import {
    Component,
    OnDestroy,
    OnInit,
    Input,
    ComponentFactoryResolver,
    NgModule
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceHelperService } from '@clientCommon/services/serviceHelper.service';
import { LandingAffilateFormComponent } from '@sales/affiliate/affiliateForm.component';
import { UxHelper } from '@clientCommon/helper/uxHelper';
import { utmParamValueType } from '@sales/peopleSearch/nameSearch/landing/landing.component';
import { CommonModule } from '@angular/common';
import { UxTemplateModule } from 'app/components/uxTemplate.component';
// import { FooterComponentModule, HeaderComponent, HeaderComponentModule } from '@ux5.2/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LandingAffilateComponent } from "@sales/affiliate/affiliate.componet";
// import { ContentCardComponentModule } from '@ux5.2/components/landing';

@Component({
    selector: 'ux-5-2-affiliate-form',
    templateUrl: './affiliateForm.component.html',
    styleUrls: ['./affiliateForm.component.css']
})
export class Ux5Dot2AffiliateFormComponent extends LandingAffilateFormComponent implements OnInit, OnDestroy {
  @Input() base: LandingAffilateFormComponent;
  @Input() uxHelper: UxHelper;
  @Input() pageType: string;
  @Input() pageCategory: string;
  @Input() page: string;
  @Input() utmParamValue: utmParamValueType = null;
  //@Input() utmParamValue: utmParamValueType = null;
  constructor(serviceHelperService: ServiceHelperService,
    activatedRoute: ActivatedRoute,
    cfr: ComponentFactoryResolver) {
      super(serviceHelperService, activatedRoute, cfr);
  }

  init() {
    return super.baseInit().then(() => {});
  }

  ngOnInit() {
    return super.ngOnInit().then(() => {
      this.init();
    });
  }

  ngOnChanges() {
    return this.init();
  }
}

// @NgModule({
//   declarations: [Ux5Dot2AffiliateFormComponent],
//   imports: [
//     CommonModule,
//     FormsModule,
//     ReactiveFormsModule,

//     UxTemplateModule,

//     HeaderComponentModule,
//     FooterComponentModule,
//     ContentCardComponentModule,
//   ],
// })
// export class Ux5Dot2AffiliateFormComponentModule {}