import {Component, Input, ViewChild} from "@angular/core";
import {ServiceHelperService} from "../../../../clientCommon/services/serviceHelper.service";
import {ActivatedRoute} from "@angular/router";
import {UxHelper} from "../../../../clientCommon/helper/uxHelper";
import {SalesHeaderComponent} from "../../../sales/components/salesHeader.component";
import {customClientPaths} from "../../../../common/custom/customPathHelpers";

@Component({
  selector: 'ux-4-0-sales-header',
  templateUrl: 'salesHeader.component.html',
  styleUrls: ['salesHeader.component.scss'],
})
export class Ux4Dot0SalesHeaderComponent extends SalesHeaderComponent {
  @Input() uxHelper: UxHelper;
  @Input() options: any = {};
  @ViewChild('navbar') navbar;
  customClientPaths = customClientPaths;
  isNavVisible = false;

  constructor(serviceHelperService: ServiceHelperService,
              activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
  }

  ngOnInit() {
    return super.ngOnInit();
  }

  onToggleNav() {
    if (this.navbar.nativeElement.hasAttribute("data-nav-menu-open")) {
      this.navbar.nativeElement.removeAttribute("data-nav-menu-open");
    } else {
      this.navbar.nativeElement.setAttribute("data-nav-menu-open", "");
    }
  }
}
