import {timeUtils} from "../../common/utils/timeUtils";
import {LogUtils} from "../../common/utils/logUtils";

export class FakeProgressor {
  // required properties for progressor working
  public max = 100;
  public name;
  public type;
  private intervalId;
  private startTimestamp;
  private endTimestamp;
  private duration;
  private weights = [1];
  public progress = 0;
  public dramaticProgress = 0;
  private interval = 200;
  private pause = false;

  private promiseFunc: { fulfill, reject };

  /**
   * 
   * @param duration of the progess provided at the time of setup
   */
  constructor(duration?: number) {
    if (duration) {
      this.setDuration(duration);
    }
  }

  /**
   * tells if progressor is running based on intervalId is set or not
   * @returns true if intervalId is set
   */
  isRunning(): boolean {
    return !!this.intervalId;
  }

  /**
   * @returns true by checking the progress done either by unassigned duaration or current progress greater than max progress
   */
  isDone(): boolean {
    this.setProgressByCalculation();
    return this.max <= this.progress || (!this.duration);
  }

  /**
   * 
   * @param flag boolean flag to pause/resume loader progress
   */
  setPause(flag: boolean) {
    this.pause = flag;
  }

  /**
   * 
   * @returns a progress duration number
   */
  getDuration(): number {
    return this.duration;
  }

  /**
   * 
   * @param duration a number to set the progressor duration
   */
  setDuration(duration: number) {
    this.duration = duration;
  }

  // this set the max progress and fulfill to clear interval
  setProgressMax() {
    this.progress = this.max;
    this.fulfill();
  }

  /**
   * this set the startTimeStamp for loader
   * @param timestamp miliseconds since the loader start
   */
  setStartTimestamp(timestamp) {
    this.startTimestamp = timestamp;
  }

  /**
   * 
   * @returns timestamp in miliseconds since the loader start
   */
  getStartTimestamp() {
    return this.startTimestamp;
  }

  // this clear the interval & resolved the promiseFunc for fulfilment on progress end
  fulfill() {
    this.clearInterval();
    if (this.promiseFunc) {
      this.promiseFunc.fulfill(this.endTimestamp - this.startTimestamp);
    }
  }

  // this clear the interval & reject the promiseFunc for fulfilment on some error
  reject(e) {
    this.clearInterval();
    if (this.promiseFunc) {
      this.promiseFunc.reject(e);
    }
  }

  start(): Promise<any> {
    if (this.intervalId) {
      LogUtils.error("Can't start", this.intervalId);
      return Promise.reject(["Interval ID", this.intervalId]);
    } else {
      if (!this.startTimestamp) {
        this.startTimestamp = (new Date()).getTime();
      }
      return new Promise<any>((fulfill, reject) => {
        try {
          this.promiseFunc = {fulfill: fulfill, reject: reject};
          if (this.duration > 0) {
            this.intervalId = setInterval(() => {
              try {
                if (this.isDone()) {
                  this.progress = this.max;
                  this.dramaticProgress = this.max;
                  this.endTimestamp = (new Date()).getTime();
                  this.fulfill();
                }
              } catch (e) {
                LogUtils.error("setInterval error", e);
              }
            }, this.interval);
          } else if (!this.duration) {
            // null or zero duration will does immediate fulfill
            this.fulfill();
          }
        } catch (e) {
          LogUtils.error(e);
          this.reject(e);
        }

      });
    }
  }

  /**
   * 
   * @returns number, calculated progress of the loader 
   */
  private calculateProgress(): number {
    if (this.duration < 0 || !this.startTimestamp) {
      return 0;
    } else if (this.duration === 0) {
      return this.max;
    } else {
      let progressTime = timeUtils.getTimestamp() - this.startTimestamp;
      return progressTime * this.max / this.duration;
    }
  }

  /**
   * this the original & dramatic progress, if the loader is not on pause
   */
  private setProgressByCalculation() {
    if (!this.pause) {
      this.progress = this.calculateProgress();
      this.dramaticProgress = this.convertToDramaticProgress(this.progress);
      if (this.progress >= this.max) {
        this.progress = this.max;
        this.dramaticProgress = this.max;
      }
    }
  }

  // this unset the intervalId on progressor end or error
  clearInterval() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  progressTime(): number {
    if (this.startTimestamp) {
      return (new Date()).getTime() - this.startTimestamp;
    } else {
      return 0;
    }
  }

  /**
   * this generate the random weight for each step to be added to the progress
   * @param steps number from which we start generate the weights 
  */
  setRandomWeights(steps = 4) {
    var count = 0;
    var weights = [];
    while (steps > 0) {
      steps--;
      count++;
      let weight = Math.random() + 0.3; // adding constant not to progress too slow
      weights.push(weight * count * 2);
    }

    this.setWeights(weights);
  }

  /**
    * this set the weights for each step to be added to the progress
   * @param weights a number array
   */
  setWeights(weights: number[]) {
    var finalWeights = [];
    var totalWeight = 0;

    weights.forEach((weight) => {
      totalWeight += weight;
    });

    weights.forEach((weight) => {
      finalWeights.push(weight / totalWeight);
    });

    this.weights = finalWeights;
  }

  /**
   * This add some weight to the orignal progrss calculated for the user to show different previews
   * @param progress orignal progress calculated
   * @returns number, a progress with added weight according to each step & ratio
   */
  convertToDramaticProgress(progress: number) {
    var ratio = 100 / this.weights.length;
    var step = 1;
    var dramaticProgress = 0;

    this.weights.some((weight) => {
      if ((step * ratio) > progress) {
        dramaticProgress += 100 * weight * (progress % ratio) / ratio;
        return true;
      } else {
        dramaticProgress += 100 * weight;
      }
      step++;
    });

    return dramaticProgress;
  }

  // this clear all the progressor properties
  clear() {
    this.clearInterval();
    this.duration = null;
    this.startTimestamp = null;
    this.endTimestamp = null;
    this.progress = 0;
    this.dramaticProgress = 0;
    this.weights = [1];
    this.promiseFunc = null;
    this.name = null;
    this.type = null;
  }
}
