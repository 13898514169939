import {Ux3Dot1LandingNameSearchComponent} from "./landing.component";
import {Ux3Dot1LoaderNameSearchComponent} from "./loader.component";
import {Ux3Dot1SearchResultNameSearchComponent} from "./searchResult.component";
import {Ux3Dot1SignupPIIComponent} from "./signup-pii/signup-pii.component";
import {Ux3Dot1IconTextComponent} from "./signup-pii/icon-text.component";
import {Ux3Dot1SignupNameSearchComponent} from "./signup.component";
// import {Ux3Dot0ReportReviewNameSearchComponent} from "./reportReview.component";

export const NAME_SEARCH_PEOPLE_SEARCH_UX_3_1_THEME_DECLARATIONS = [
  Ux3Dot1LandingNameSearchComponent,
  Ux3Dot1LoaderNameSearchComponent,
  Ux3Dot1SearchResultNameSearchComponent,
  Ux3Dot1SignupPIIComponent,
  Ux3Dot1IconTextComponent,
//   Ux3Dot0ReportReviewNameSearchComponent,
   Ux3Dot1SignupNameSearchComponent,
];