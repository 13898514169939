import { CommerceOrder } from "../models/commerce/commerceOrder";
import { UserInput } from "../models/user/userInput";
import { UxComposite } from "../models/ux/uxComposite";
import { LogUtils } from "./logUtils";
import { validateUtil } from "./validateUtil";

export enum RemoveInfoAlertType {
  NoOfferRuleKey = 'NoOfferRuleKey',
  NotFoundOffers = 'NotFoundOffers',
}

export namespace RemoveInfoAlertType {
  export function toLongMessage(type: RemoveInfoAlertType) {
    switch (type) {
      case RemoveInfoAlertType.NoOfferRuleKey:
        return 'Failed to get offer rule key from user\'s UX settings. Please check member.remove-info.payment.offerKey.rules settings.';
      case RemoveInfoAlertType.NotFoundOffers:
        return 'Could not find offer with Ids of offerKey set in member.remove-info.payment.offers.rules.';
      default:
        LogUtils.warn('RemoveInfoAlertType.toLongMessage : No Message');
        return 'No message.';
    }
  }
}

export type RemoveInfoRequestParam = Pick<
  UserInput, 
  'fname' | 'mname' | 'lname' | 
  'phone' | 'street' | 'city' | 
  'state' | 'zip' | 'age' |
  'extId'
> & { 
  commerceContentId: string,
  updateMode?: boolean,
};

export namespace RemoveInfoRequestParam {
  export function isValid(param: RemoveInfoRequestParam): boolean {
    if (!param.fname) {
      return false;
    }

    if (!param.lname) {
      return false;
    }

    if (!param.phone || !validateUtil.isValidPhone(param.phone)) {
      return false;
    }

    if (!param.city) {
      return false;
    }

    if (!param.zip || !validateUtil.isValidZip(param.zip)) {
      return false;
    }

    if (!param.age || !validateUtil.isValidAge(param.age)) {
      return false;
    }

    return true;
  }
}

export class RemoveInfoUtils {
  // New Member Interstitial Active Flag
  static readonly enableInterstitialCompKey: string = 'comp.member.remove-info.interstitial.enable';

  static isEnableInterstitial(uxComposite: UxComposite): boolean {
    return uxComposite.get(RemoveInfoUtils.enableInterstitialCompKey) === true;
  }

  static checkPaidByOrders(orders: CommerceOrder[]) {
    return !!(orders?.length && orders.find((e) => e.isActive()));
  }
}