import {
    AfterViewChecked,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
    Input,
    ViewContainerRef,
    ComponentFactoryResolver,
    AfterViewInit
  } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '@clientCommon/components/BaseComponent';
import { ServiceHelperService } from '@clientCommon/services/serviceHelper.service';
import {redirectHelper} from '../../../clientCommon/helper/redirectHelper';
import { utmParamValueType } from '@sales/peopleSearch/nameSearch/landing/landing.component';
import { customClientPaths } from '@common/custom/customPathHelpers';

 
  
  @Component({
    templateUrl: './affiliate.component.html',
  })
  export class LandingAffilateComponent extends BaseComponent implements OnInit, OnDestroy {
    @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;
  
    /**
     *
     * @param serviceHelperService will provide access to different required services such as authentication service, uxc service etc.
     * @param activatedRoute provides access to information about a route associated with a component that is loaded in an outlet.
     * @param pageCategory getting from BaseComponent to be provided as input to the child(theme template) component.
     *
     */
    constructor(
      serviceHelperService: ServiceHelperService,
      activatedRoute: ActivatedRoute,
      private _cfr: ComponentFactoryResolver
    ) {
      super(serviceHelperService, activatedRoute);
      this.pageType = BaseComponent.PAGE_TYPE.general;
      this.pageCategory = BaseComponent.PAGE_CATOGORY.general;
      this.page = BaseComponent.PAGE.affilliate;
    }
  
    ngOnInit() {
      return this.baseInit().then(() => {
        // if (this.theme === this.themes.ux_5_2) {
        //     this._loadUx5_2Page();
        // }
        this.initDone = true;
      });
    }
  
    ngOnDestroy() {
      return super.onDestroy();
    }
    
    @HostListener('window:scroll', ['$event'])
    onScroll($event) {
      super.onScroll($event);
    }

    login() {
      let url = customClientPaths.affiliateLandingForm;
      redirectHelper.redirect(this.serviceHelperService, url);
    }
  
    // private async _loadUx5_2Page() {
    //   this.container?.clear?.();
    //   const { Ux5Dot2AffiliateComponent } = await import('@ux5.2/affiliate/landing');
    //   const componentFactory = this._cfr.resolveComponentFactory(Ux5Dot2AffiliateComponent);
    //   const component = this.container?.createComponent?.(componentFactory);
    //   if (component) {
    //     component.instance.base = this;
    //     component.instance.uxHelper = this.uxHelper;
    //   }
    // }
  }
  