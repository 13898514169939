import {Component, ComponentFactoryResolver, HostListener, OnDestroy, OnInit, ViewChild, ViewContainerRef} from "@angular/core";
import {SearchResultPeopleSearchSales} from "../../searchResultPeopleSearchSales";
import {BaseComponent} from "../../../../../clientCommon/components/BaseComponent";
import {ServiceHelperService} from "../../../../../clientCommon/services/serviceHelper.service";
import {ActivatedRoute} from "@angular/router";
import { HeadlessHelper } from "../../../../helper/headlessHelper";

@Component({
  templateUrl: './searchResult.component.html',
})
/**
 * SearchResultNameSearchSalesComponent render the respective theme/layout component based on current theme
 * @extends SearchResultPeopleSearchSales which further extends SearchResultPeopleSearch to get all required properties
 */
export class SearchResultNameSearchSalesComponent extends SearchResultPeopleSearchSales implements OnInit, OnDestroy {
  @ViewChild('container', {read: ViewContainerRef}) container: ViewContainerRef;
/**
 *
 * @param serviceHelperService will provide access to different required services such as authentication service, uxc service etc.
 * @param activatedRoute provides access to information about a route associated with a component that is loaded in an outlet.
 * @param pageCategory getting from BaseComponent to be provided as input to the child(theme template) component.
 *
 */
  constructor(
      serviceHelperService: ServiceHelperService,
      activatedRoute: ActivatedRoute, 
      private _cfr: ComponentFactoryResolver,
  ) {
    super(serviceHelperService, activatedRoute);
    this.pageCategory = BaseComponent.PAGE_CATOGORY.nameSearch;
  }

  //TODO: for test (JMS)
  enableHeadless: boolean = false;

  ngOnInit() {
    return this.onInit().then(() => {
      this.initDone = true;

      if (HeadlessHelper.isEnabled(this.uxComposite)) {
        this.enableHeadless = true;
      } 
      // else if (this.theme === this.themes.ux_5_2) {
      //   this._loadUx5_2SearchResultPage();
      // }
    });
  }

  ngOnDestroy() {
    return super.onDestroy();
  }

  @HostListener('window:scroll', ['$event'])
  onScroll($event) {
    super.onScroll($event);
  }

  // private async _loadUx5_2SearchResultPage() {
  //   this.container.clear();
  //   const { Ux5Dot2SearchResultNameSearchComponent } = await import('@ux5.2/peopleSearch/nameSearch/search-result');
  //   const componentFactory = this._cfr.resolveComponentFactory(Ux5Dot2SearchResultNameSearchComponent);
  //   const component = this.container.createComponent(componentFactory);
  //   component.instance.base = this;
  //   component.instance.uxHelper = this.uxHelper;
  // }
}
