import {LogUtils} from "../utils/logUtils";
import {isArray, isString} from "util";
import {collectionClassHelper} from "../decorators/database/collectionClass";
import {objectUtils} from "../utils/objectUtils";

export class DatabaseWrapperActions {
    static CRUD = {
        create: "create",
        read: "read",
        readOne: "readOne",
        update: "update",
        updateDoc: "updateDoc",
        delete: "delete",
    };
}
