import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, NgModule } from '@angular/core';
import { ServiceHelperService } from '@clientCommon/services/serviceHelper.service';

@Component({
    selector: 'app-modal-save-report',
    templateUrl: 'saveReport.component.html',
    styleUrls: ['saveReport.component.scss'],
    standalone: false
})
export class SaveReportComponent implements OnInit {
  @Input() downloadPath: string;
  @Input() commerceContent: any;

  constructor(public serviceHelperService: ServiceHelperService) {}

  ngOnInit() {}

  goNext() {}

  trackDownload() {
    this.serviceHelperService.trackingService.report({
      provider: this.commerceContent.productKey,
      type: 'detail',
      action: 'download',
    });

    // email download check
    if (this.commerceContent?.content[0]?.contentInfo?.searchContent[0]?.contentInfo?.email) {
      this.serviceHelperService.trackingService.report({
        provider: this.commerceContent.productKey,
        type: 'emailSearch',
        action: 'download',
      });
    }
  }
}

@NgModule({
  declarations: [SaveReportComponent],
  imports: [CommonModule],
  exports: [SaveReportComponent],
})
export class SaveReportComponentModule {}
