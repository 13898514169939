import {Progressor} from "../../common/models/progressor";
import {Injectable} from "@angular/core";
import {PageService} from "../../clientCommon/services/page.service";
import {Router} from "@angular/router";
import {UxcService} from "./uxc.service";
import {FakeProgressor} from "../helper/fakeProgressor";
import {LogUtils} from "../../common/utils/logUtils";
import {serviceCollection} from "./service.collection";
import {redirectHelper} from "../helper/redirectHelper";
import {CrudService} from "./crud.service";
import {timeUtils} from "../../common/utils/timeUtils";
import {collectionClassHelper} from "../../common/decorators/database/collectionClass";

declare var window: any;

@Injectable()
export class ProgressorService {
  timeDiff: number = 0;
  progressor: Progressor;
  fakeProgressors: { current: FakeProgressor, timeout: FakeProgressor, nested: any };
  nestedProgresses: { [key: string]: { name: string, delay: number, timeout: number }[] } = {};
  totalDuration = 0;
  finishedDuration = 0;
  totalMax = 100;
  nestedTypes = ['use', 'question'];

  maxExecutionCount = 100;
  currentExecutionCount = 0;
  nestedDone = false;

  constructor(private uxcService: UxcService,
              private crudService: CrudService,
              private pageService: PageService,
              private router: Router) {
    this.init();
  }
/**
 * sets the currentExecutionCOunt to 0
 * clears the current and timout value from fakeProgressors if fakeprogressors already exist
 * changes the nestedDone Boolean to false
 * clears the value for all the types in nestedType Array
 * assigns new fakeprogressor to fakeprogressor if no fakeProgressor exists
 * adds new fakeprogressor type to every fakeprogressor nested type in nestedtype Array
 * creates a new windowspace and binds stepmax value to this windowspace
 */
  init() {
    this.currentExecutionCount = 0;
    if (this.fakeProgressors) {
      this.clearCurrentProgressors();
    }
    this.fakeProgressors = {
      current: new FakeProgressor(),
      timeout: new FakeProgressor(),
      nested: {},
    };
    this.nestedTypes.forEach((type) => {
      this.fakeProgressors.nested[type] = new FakeProgressor();
    });

    {
      // For testing purpose
      window.my = window.my || {};
      window.my.namespace = window.my.namespace || {};
      window.my.namespace.setStepMax = this.setStepMax.bind(this);
    }
  }
/**
 * 
 * @param progressor progressor value that needs to be assigned
 * gets the timedifference from uxCOmposite
 * sets the current progressor value to the value in param progressor 
 * calculates the durations and assigns the value 0 to currentExecutionCount
 */
  setProgressor(progressor: Progressor) {
    this.timeDiff = this.uxcService.uxComposite.getTimeDiff();
    this.progressor = progressor;
    this.calculateDurations();
    this.currentExecutionCount = 0;
  }
/**
 * calculates and assigns the total and finished duration to total and finished duration if progressor exists
 */
  calculateDurations() {
    if (this.progressor) {
      this.totalDuration = this.progressor.calculateTotalDuration();
      this.finishedDuration = this.progressor.caluclateFinishedDuration();
    }
  }
/**
 * clears the current and timout value from fakeProgressors
 * changes the nestedDone Boolean to false
 * clears the value for all the types in nestedType Array
 */
  clearCurrentProgressors() {
    this.fakeProgressors.current.clear();
    this.fakeProgressors.timeout.clear();
    this.clearNestedProgressors();
  }
/**
 * changes the nestedDone Boolean to false
 * clears the value for all the types in nestedType Array
 */
  clearNestedProgressors() {
    this.nestedDone = false;
    this.nestedTypes.forEach((type) => {
      if (this.fakeProgressors.nested[type]) {
        this.fakeProgressors.nested[type].clear();
      }
    });
  }
/**
 * gets the currentprogress and assigns it to progress if progressor exists
 * sets the current progress to start value if no progress exists
 * returns progress
 */
  getCurrentProgress() {
    if (this.progressor) {
      let progress = this.progressor.getCurrentProgress();
      if (!progress) {
        progress = this.progressor.progresses[0];
      }

      return progress;
    }
  }
/**
 * sets the progress to current progress if progressor exists
 * gets the pagetypesbyuxkey from pageservice and sets it to pagetypes
 * gets the pageurl from pageservice and sets it to url
 * checks the url format by a regexexpression and assigns it to regex
 * returns false if url and the regex expression do not match else returns true
 * 
 */
  isSameProgressPage() {
    if (this.progressor) {
      let progress = this.getCurrentProgress();
      if (progress) {
        let pageTypes = this.pageService.getPageTypesByUxKey(progress.name);
        if (pageTypes) {
          let url = this.pageService.getPageUrl(pageTypes.pageType, pageTypes.pageCategory, pageTypes.page);
          let regex = new RegExp("^\/?" + url + "$");
          if (url && !regex.test(window.location.pathname)) {
            return false;
          }
        }
      }
    }
    return true;
  }
/**
 * sets current progress to progress
 * get pagetypes by uxkey from pageservice and assigns it to pageTypes
 * gets page url from pageservice and assigns it to url
 * redirects to the same progress page if the page is not the progress page
 * 
 */
  goToCurrentProgressPage() {
    if (this.isSameProgressPage() === false) {
      let progress = this.getCurrentProgress();
      let pageTypes = this.pageService.getPageTypesByUxKey(progress.name);
      let url = this.pageService.getPageUrl(pageTypes.pageType, pageTypes.pageCategory, pageTypes.page);
      return redirectHelper.redirect(serviceCollection.serviceHelperService, url);
    }
  }
/**
 * 
 * @param timestamp the current timestamp for the progressor
 * subtracts timediff from the timestamp to convert it into server timestamp format
 */
  convertToServerTimestamp(timestamp) {
    return timestamp - this.timeDiff;
  }
/**
 * 
 * @param timestamp the current timestamp for the progressor
 * adds timediff to timestamp to convert it into clientTimestamp format
 */
  convertToClientTimestamp(timestamp) {
    return timestamp + this.timeDiff;
  }
  /**
   * 
   * @param action the cyrrent action under execution by the progressor
   * @param name the current name of the progressor being executed
   * @param timestamp the current timestamp 
   * returns the key action name and timestamp after converting it into the serverTimestamp format
   */
  createProgressAction(action, name, timestamp) {
    return {key: Progressor.INPUT_KEY.progress, action: action, name: name, timestamp: this.convertToServerTimestamp(timestamp)};
  }
/**
 * 
 * @param action the cyrrent action under execution by the progressor
 * @param name the current name of the progressor being executed
 * @param timestamp the current timestamp 
 * takes the current progressaction and assigns it to the input
 * pushes the input to the progressorinput array 
 * logs the input content
 * gets collection name from the collectionclassHelper and assigns it to colelctionName
 * pushes the collectionname progressorid and input in the input for crudservice
 */
  pushProgressAction(action, name, timestamp) {
    let input = this.createProgressAction(action, name, timestamp);
    this.progressor.inputs.push(input);
    LogUtils.debug("input", input);

    let collectionName = collectionClassHelper.getCollectionName(Progressor);
    return this.crudService.pushInput(collectionName, this.progressor._id, input).then(() => {
    }).catch((e) => {
      LogUtils.error(e);
    });
  }
/**
 * 
 * @param timestamp the current timestamp for the progressor under execution
 * sets the current progress to progressor current progress if progressor exists otherwise sets currentprogress to progressor start progress(0)
 * gets the progressor input by passing progressor action and currenprogressname and assigns it into the input
 * if no input exists pushes the new progressaction and assigns this new progressaction to the input
 * if input already exsits sets the input timestamp to fakeprogressor time stamp after converting it into clienttimestamp format
 */
  setCurrentProgress(timestamp: number) {
    if (this.progressor) {
      let currentProgress = this.progressor.getCurrentProgress();
      if (!currentProgress) {
        currentProgress = this.progressor.progresses[0];
      }

      if (currentProgress) {
        let input = this.progressor.getProgressInput(Progressor.ACTION.started, currentProgress.name);
        if (!input) {
          this.pushProgressAction(Progressor.ACTION.started, currentProgress.name, timestamp);
          input = this.createProgressAction(Progressor.ACTION.started, currentProgress.name, timestamp);
        } else {
          // Adjust timestamp due to mismatch of client/server time diff
          this.fakeProgressors.current.setStartTimestamp(this.convertToClientTimestamp(input.timestamp));
        }

        // Setting current progressor
        this.fakeProgressors.current.setDuration(currentProgress.duration);
        this.fakeProgressors.current.setWeights(currentProgress.weights);
        this.fakeProgressors.current.name = currentProgress.name;

        // Setting timeOut progressor
        this.fakeProgressors.timeout.clear();
        this.fakeProgressors.timeout.setDuration(currentProgress.timeout);
        this.fakeProgressors.timeout.name = `${currentProgress.name}.timeout`;
        this.calculateDurations();
      }
    }
  }
/**
 * gets current progress and sets it to progress and returns tempClientdone as false if progress exists
 * returns false if progress exists in the progressor
 * returns true if no progressor is found meaning all progressors have finished working 
 */
  hasFinishedProgressors() {
    if (this.progressor) {
      let progress = this.getCurrentProgress();
      if (progress) {
        return !!progress.tempClientDone;
      }
      return false;
    } else {
      return true;
    }
  }
/**
 * 
 * @param timestamp the current timestamp for the progressor under execution
 * clears the content from currentprogressors
 * gets the current progress from progressor and sets it to current progress if progressor exists
 * sets the current progress to progressor starting progress if no currentprogress is found 
 * if currentprogress exists and the page is progresspage sets the progressor progress input to input
 * if no input is found pushes a new progress action into the progressor 
 */
  endCurrentProgress(timestamp: number) {
    this.clearCurrentProgressors();
    if (this.progressor) {
      let currentProgress = this.progressor.getCurrentProgress();
      if (!currentProgress) {
        currentProgress = this.progressor.progresses[0];
      }

      if (currentProgress) {
        if (this.isSameProgressPage()) {
          let input = this.progressor.getProgressInput(Progressor.ACTION.ended, currentProgress.name);
          if (!input) {
            this.pushProgressAction(Progressor.ACTION.ended, currentProgress.name, timestamp);
          }
        } else {
        }
      }
    }
  }
/**
* calculates the current protion of the progressor by getting the current duration multiplying it with totalmax(100) and than dividing it with totalDUration
*/
  calculateCurrentPortion() {
    return this.fakeProgressors.current.getDuration() * this.totalMax / this.totalDuration;
  }
/**
 * sets the duration to finishedduration(0) if the currentfakeprogressor and totalduation are not 0
 * calculates the duration by multilpying currentdramaticprogress and currentduration and than dividing it by 100(fakeprogressor max value)
 * returns duation after multiplying it with totalmax and than dividing it with totalduration of current fakegprogressor
 */
  getTotalDurationDramaticProgress() {
    if (this.fakeProgressors.current.max !== 0 && this.totalDuration !== 0) {
      let duration = this.finishedDuration;
      if (!this.fakeProgressors.current.isDone()) {
        duration += this.fakeProgressors.current.dramaticProgress * this.fakeProgressors.current.getDuration() / this.fakeProgressors.current.max;
      }
      return (duration) * this.totalMax / this.totalDuration;
    } else {
      return 0;
    }
  }
/**
 * gets the uxComposite from uxcService and then assigns the response to uxCOmposite after resolving the promise
 * for each nestedtype creates two new params nestedItems (assigns it to an empty array) and nestedTypeKeys
 * traverses the keys of fake progressor current name and its nestedtype in uxcomposite
 * assigns the traversekeys to nestedTypeKeys
 * if nestedtypeKeys exist assigns the keys to nested array
 * sorts the keys in nested array if any key exists
 * for each key pushes name(progresscurrentname.type.item) delay and timeout (from uxcomposite) to nestedItems array
 * adds the nestedItems to the nestedType of nestedProgress
 */
  setNestedTypes(): Promise<any> {
    return this.uxcService.getUxComposite().then((uxComposite) => {
      this.nestedTypes.forEach((nestedType) => {
        let nestedItems = [];
        let nestedTypeKeys = uxComposite.traverseKeys(`${this.fakeProgressors.current.name}.${nestedType}`);
        if (nestedTypeKeys) {
          let nested = Object.keys(nestedTypeKeys);
          if (nested) {
            nested.sort();
            nested.forEach((item) => {
              let name = `${this.fakeProgressors.current.name}.${nestedType}.${item}`;
              let delay = uxComposite.get(`${name}.delay`);
              let timeout = uxComposite.get(`${name}.timeout`);
              nestedItems.push({name: name, delay: delay, timeout: timeout});
            });
          }
        }
        this.nestedProgresses[nestedType] = nestedItems;
      });
    });
  }
/**
 * clears the nested Progressors
 * after resolving setNestedTypes promise assings promises to a new array
 * assings the nestedfakeprogressor type to fakeProgressor
 * logs the information for the Nest with its type and current fakeprogressor value
 * pushes the nestedtype by reducing its promise and the value to promises array
 * logs the delay  with current progressor if promise is resolved
 * adds the delay to the fakeprogressor if any delay exists and starts the fakeprogressor
 * logs and adds timeout to the fakeprogressor if any timeout exists in the item after adding delay
 * starts the fakeprogressor in return
 * returns the type after clearing the fakeprogressor and catches any error
 * logs the currentname and type of fakeprogressor with Nest Done status
 * after resolving everypromise finishes any timeout remaining in fakeprogressor
 * logs every nest done value with NestsDone status and current progressor name
 * returns the nestsDone as true 
 */
  runNestedTypes() {
    this.clearNestedProgressors();
    return this.setNestedTypes().then(() => {
      let promises = [];
      this.nestedTypes.forEach((type) => {
        let fakeProgressor = this.fakeProgressors.nested[type];
        LogUtils.debug("Nest", type, fakeProgressor);
        if (fakeProgressor) {
          promises.push(this.nestedProgresses[type].reduce((promise, item) => {
            return promise.then(() => {
              LogUtils.debug("Delay", this.fakeProgressors.current.name, type, item.delay);
              if (item.delay) {
                // Delay
                fakeProgressor.clear();
                fakeProgressor.setDuration(item.delay);
                fakeProgressor.name = item.name;
                fakeProgressor.type = "delay";

                return fakeProgressor.start();
              }
            }).then((e) => {
              LogUtils.debug("Timeout", this.fakeProgressors.current.name, type, item.timeout, !!item.timeout);
              if (item.timeout) {
                // Delay
                fakeProgressor.clear();
                fakeProgressor.setDuration(item.timeout);
                fakeProgressor.name = item.name;
                fakeProgressor.type = "timeout";
                return fakeProgressor.start();
              }
            }).then(() => {
              fakeProgressor.clear();
              return type;
            }).catch((e) => {
              LogUtils.error(e);
            }).then(() => {
              LogUtils.debug("Nest done", this.fakeProgressors.current.name, type);
            });
          }, Promise.resolve()));
        }
      });
      return Promise.all(promises).then(() => {
        // if main progressor is in timeout mode. finish timeout
        if (!this.fakeProgressors.timeout.isDone()) {
          this.fakeProgressors.timeout.setProgressMax();
        }
      }).catch((e) => {
        return Promise.reject(e);
      });
    }).catch((e) => {
      LogUtils.error(e);
    }).then(() => {
      LogUtils.debug("Nests done", this.fakeProgressors.current.name);
      this.nestedDone = true;
    });
  }
/**
 * increases the currentExecution count by 1
 * returns and resolves the promise if currentexecutioncount is less than the maxExecutioncount
 * logs the current name and duration of fakeProgressor with Duration status
 * calls the runNestedTypes Function
 * returns the current start of fakeprogressor
 * assigns the resulting value to duration and losgs current timeout and currentduration of fakeprogressor with Timeout status
 * returns the starting value of fakeprogressor timeout if nestedDone is still false
 * returns timout name and timout duration with Timeout Bypasses status if nestsDone is true
 * ends the currentProgress with current timestamp in return
 * after ending currentprogress sets new currentprogress if its the same progresspage and has no finished progressors
 * executes the progress in return
 * catches any error thrown and logs this error
 * returns the currentExecution count with Maxprogressor execution reached status if currentExecution count is greater than maxExecutionCount
 * rejects the Promise in return
 */
  executeProgress() {

    this.currentExecutionCount++;
    if (this.currentExecutionCount < this.maxExecutionCount) {
      return Promise.resolve().then(() => {
        LogUtils.debug("Duration", this.fakeProgressors.current.name, this.fakeProgressors.current.getDuration());
        this.runNestedTypes();
        return this.fakeProgressors.current.start();
      }).then((duration) => {
        if (!this.nestedDone) {
          LogUtils.debug("Timeout", this.fakeProgressors.timeout.name, this.fakeProgressors.timeout.getDuration());
          return this.fakeProgressors.timeout.start();
        } else {
          LogUtils.debug("Timeout Bypassed", this.fakeProgressors.timeout.name, this.fakeProgressors.timeout.getDuration());
        }
      }).then(() => {
        return this.endCurrentProgress(timeUtils.getTimestamp());
      }).then(() => {
        if (this.isSameProgressPage() && !this.hasFinishedProgressors()) {
          this.setCurrentProgress(new Date().getTime());
          return this.executeProgress();
        }
      }).catch((e) => {
        LogUtils.error(e);
      })
    } else {
      LogUtils.error("Max progressor execution count reached", this.currentExecutionCount);
      return Promise.reject(false);
    }
  }
  /**
   * 
   * @param pageType the type of the current page returned from pageService
   * @param pageCategory current page category returned from pageservice
   * @param page the current page returned from pageservice
   * removes the Progressinput from the comp containing current pageType,pagecategory and page
   * gets the progressor collection name from collectionClassHelper and assigns it to collectionName
   * returns the updated input with collection name progressor id and progressorinput using crudService
   * catches and logs any errors that are thrown after updating the input using crudservice
   */
  removeProgressorEvents(pageType, pageCategory, page) {
    this.progressor.removeProgressInput(`comp.${pageType}.${pageCategory}.${page}`);

    let collectionName = collectionClassHelper.getCollectionName(Progressor);
    return this.crudService.updateInput(collectionName, this.progressor._id, this.progressor.inputs).then(() => {
    }).catch((e) => {
      LogUtils.error(e);
    });
  }
/**
 * sets the fakeprogressors current value to max(100)
 */
  setStepMax() {
    this.fakeProgressors.current.setProgressMax();
  }

}
