import {LegacyLandingPhoneSearchComponent} from "./landing.component";
import {LegacyLoaderPhoneSearchComponent} from "./loader.component";
import {LegacySearchResultPhoneSearchComponent} from "./searchResult.component";
import {LegacySignupPhoneSearchComponent} from "./signup.component";
import {LegacyReportReviewPhoneSearchComponent} from "./reportReview.component";

export const PHONE_SEARCH_PEOPLE_SEARCH_LEGACY_THEME_DECLARATIONS = [
  LegacyLandingPhoneSearchComponent,
  LegacyLoaderPhoneSearchComponent,
  LegacySearchResultPhoneSearchComponent,
  LegacyReportReviewPhoneSearchComponent,
  LegacySignupPhoneSearchComponent,
];
