/* tslint:disable:object-literal-shorthand */
import {Injectable} from '@angular/core';
import {JsonService} from './json.service';
import {ModelBase} from '../../common/models/modelBase';
import {Router} from '@angular/router';
import {ResponseEvent} from '../../common/event/responseEvent';
import {LogUtils} from '../../common/utils/logUtils';
import {DatabaseWrapperActions} from '../../common/wrapper/databaseWrapperActions';
import {serverPaths} from '../../common/helpers/pathHelpers';

@Injectable()
export class CrudService {
  public static URL = {
    default: '/api/helper/crud',
    admin: '/api/manage/admin/crud',
  };

  /**
   *
   * @param jsonService to access localStorage for managing token etc.
   * @param router to access the routes
   */
  constructor(private jsonService: JsonService,
              private router: Router) {
  }

  /**
   * This FN is to perform the db crud operation proving action, collectionName, query & data
   * @param param have all the data needed to perform required crud operation
   */
  public crud(param: {
    action: string,
    collectionName: string,
    selectQuery?,
    setQuery?,
    docs?: any[],
    url: string,
  }): Promise<any> {
    return new Promise<any>((fulfill, reject) => {
      try {
        // api url for performing crud operation
        const url = `${param.url}/${param.action}/${param.collectionName}`;

        // Call to the CRUD API
        this.jsonService.json(url, param).then((responseEvent: ResponseEvent) => {
          if (DatabaseWrapperActions.CRUD.read === param.action) {
            fulfill(responseEvent.getDocs());
          } else {
            fulfill(responseEvent);
          }
        }).catch((error) => {
          if (error.status === 401) {
            this.router.navigateByUrl("/account");
          }
          reject(error);
        });
      } catch (e) {
        LogUtils.error(e);
        reject(e);
      }
    });
  }

  /**
   * This FN is used to create the DB collection
   * @param url API url for creating the collection
   * @param doc have all the required model data required to create the collection
   * @returns the promise from the crud function response
   */
  public create(url, doc: ModelBase<any>): Promise<any> {
    let param = {
      action: DatabaseWrapperActions.CRUD.create,
      collectionName: doc.getCollectionName(),
      docs: [doc],
      url: url,
    };

    return this.crud(param);
  }

    /**
   * This FN is used read the data from the DB collection
   * @param url API url for reading the data from the collection
   * @param doc have the required properties/data to read from db collection
   * @returns the promise from the crud function response
   */
  public read(url, collectionName: string, selectQuery, options?): Promise<any> {
    let param = {
      action: DatabaseWrapperActions.CRUD.read,
      collectionName: collectionName,
      selectQuery: selectQuery,
      options: options,
      url: url,
    };

    return this.crud(param);
  }

    /**
   * This FN is used updarte the data in the DB collection
   * @param url API url for updating the doc of the DB collection
   * @param doc have the required properties/data to update the collection docs
   * @returns the promise from the crud function response
   */
  public updateDoc(url, objs: any[]): Promise<any> {
    let docs = [];
    objs.forEach((obj: ModelBase<any>) => {
      let doc = obj.getSaveSet();
      doc._id = obj._id;
      docs.push(doc);
    });
    let param = {
      action: DatabaseWrapperActions.CRUD.updateDoc,
      collectionName: objs[0].getCollectionName(),
      docs: docs,
      url: url,
    };

    return this.crud(param);
  }

  /**
   * This FN is used read the single doc only by ID from the DB collection
   * @param collectionName from which the taregeted doc is needed
   * @param targetId id of the certain collection whose document is needed
   * @returns the promise from the crud function response
   */
  public readOne(collectionName: string, targetId, targetName?): Promise<any> {
    const searchOption = {
      collectionName,
      targetId,
      targetName,
    }
    Object.keys(searchOption).forEach(key => {
      if (!searchOption[key]) delete searchOption[key];
    });
    return this.jsonService.request(serverPaths.crudRead, searchOption).then((responseEvent: ResponseEvent) => {
      let docs = responseEvent.getDocs();
      if(docs) {
        return docs[0];
      }
    }).catch((e) => {
      LogUtils.error(e);
      return Promise.reject(e);
    });
  }

  /**
   * This Fn is to push update the collection with id
   * @param collectionName for which data needed to be updated
   * @param id of the collection document whose data needed to be updated
   * @param data needed to be updated
   * @returns
   */
  public pushInput(collectionName, id, data): Promise<any> {
    return this.jsonService.request(serverPaths.crudPushInput, {
      collectionName: collectionName,
      targetId: id,
      data: data,
    }).then((responseEvent: ResponseEvent) => {
      return responseEvent.getDocs();
    }).catch((e) => {
      LogUtils.error(e);
      return Promise.reject(e);
    });
  }

    /**
   * This Fn is to update the collection with id
   * @param collectionName for which data needed to be updated
   * @param id of the collection document whose data needed to be updated
   * @param data needed to be updated
   * @returns
   */
  public updateInput(collectionName, id, data): Promise<any> {
    return this.jsonService.request(serverPaths.crudUpdateInput, {
      collectionName: collectionName,
      targetId: id,
      data: data,
    }).then((responseEvent: ResponseEvent) => {
      return responseEvent.getDocs();
    }).catch((e) => {
      LogUtils.error(e);
      return Promise.reject(e);
    });
  }
}
