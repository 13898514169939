import {
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  EventEmitter,
  Input,
  NgModule,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceHelperService } from '../../../../../../clientCommon/services/serviceHelper.service';
import { PersonDetail } from '../../../personDetail';
import { ToastrService } from '../../../../../service/toastr.service';
import { landingPeopleSearchMemberUtils } from '../../../../peopleSearch/landingPeopleSearchMemberUtils';
import { BaseComponent } from '../../../../../../clientCommon/components/BaseComponent';
import { peopleSearchProductKeys } from '../../../../../../common/custom/peopleSearch/peopleSearchProductKeys';
import { AuthenticationService, NoteService } from '../../../../../../clientCommon/services';
import { CommonModule } from '@angular/common';
import { MiniReportHeaderModule } from '@member/peopleSearch/components/mini/header/header.component';
import { MiniButtonComponentModule } from '@member/peopleSearch/components/mini/button/button.component';
import { MemberUpsellGlobalComponentModule } from '@member/components/upsells/global/upsell-global.component';
import { MiniReportNotesComponentModule } from '@member/peopleSearch/components/mini/notes/notes.component';
import { MiniReportFooterModule } from '@member/peopleSearch/components/mini/footer/footer.component';
import { AdComponentModule } from 'app/components/ad/ad.component';
import { MiniReportAdProtectionComponentModule } from '@member/peopleSearch/components/mini/ad/ad-protection.component';
import { MiniFamilyBaseComponent } from './miniFamilyBase.component';
import { FactFindersComponentModule } from '../factFinders/factFinders.component';

declare var $: any;

@Component({
    selector: 'app-mini-family',
    templateUrl: 'miniFamily.component.html',
    styleUrls: ['miniFamily.component.scss'],
    standalone: false
})
export class MiniFamilyComponent extends MiniFamilyBaseComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  
  constructor(
    public serviceHelperService: ServiceHelperService,
    public changeDetectorRef: ChangeDetectorRef,
    activatedRoute: ActivatedRoute,
    protected router: Router,
    public renderer: Renderer2,
    public toastr: ToastrService,
    public noteService: NoteService,
    public authService: AuthenticationService
  ) {
    super(serviceHelperService, changeDetectorRef, activatedRoute, router, renderer, toastr, noteService, authService);
  }
  ngOnInit() {
    return super.ngOnInit();
  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
  }
  ngOnDestroy() {
    return super.ngOnDestroy();
  }
  ngOnChanges(changes: SimpleChanges): void {}
}

@NgModule({
  declarations: [MiniFamilyComponent],
  imports: [
    CommonModule,
    FactFindersComponentModule,
    MiniReportHeaderModule,
    MiniButtonComponentModule,
    MemberUpsellGlobalComponentModule,
    MiniReportNotesComponentModule,
    MiniReportFooterModule,
    AdComponentModule,
    MiniReportAdProtectionComponentModule,
  ],
  exports: [MiniFamilyComponent],
})
export class MiniFamilyComponentModule {}
