import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ServiceHelperService } from "../../../../clientCommon/services/serviceHelper.service";
import { ActivatedRoute } from "@angular/router";
import { PersonDetail } from "../personDetail";
import { CriminalRecordSearchedPerson } from "../../../../common/custom/models/peopleSearch/searchedPerson";
import { ToastrService } from "../../../service/toastr.service";
import {AuthenticationService, NoteService} from "../../../../clientCommon/services";

@Component({
    selector: "app-member-people-search-criminal",
    templateUrl: "criminal.component.html",
    standalone: false
})
export class CriminalComponent
  extends PersonDetail
  implements OnInit, OnDestroy, OnChanges {
  @Input() criminalRecord: any;
  @Input() bottomLine: boolean;
  @Input() isIDI: boolean;

  constructor(
    public serviceHelperService: ServiceHelperService,
    public changeDetectorRef: ChangeDetectorRef,
    activatedRoute: ActivatedRoute,
    public toastr: ToastrService,
    public authService: AuthenticationService,
    public noteService: NoteService
  ) {
    super(serviceHelperService, activatedRoute, toastr, noteService, authService);
  }

  ngOnInit() {}

  ngOnDestroy() {
    return super.onDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  init() {
    return super.init();
  }

  convertedCriminalPhotoUrl(photoUrl) {
    return this.customServerPaths.contentPeopleSearchCriminalPhoto + "?url=" + photoUrl;
  }
}
