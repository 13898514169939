// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sup-payment-loader {
  padding: 88px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 991px) {
  .sup-payment-loader {
    padding: 80px 36px;
  }
}
@media (max-width: 576px) {
  .sup-payment-loader {
    padding: 40px 36px;
  }
}
.sup-payment-loader__heading {
  font-weight: 600;
  font-size: 36px;
  line-height: 1.2;
  margin: 0;
  margin-bottom: 60px;
}
@media (max-width: 576px) {
  .sup-payment-loader__heading {
    margin-bottom: 40px;
    font-size: 24px;
  }
}
.sup-payment-loader__spinner img {
  animation: spin infinite 3s linear;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/paymentLoader/loader.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;AACE;EANF;IAOI,kBAAA;EAEF;AACF;AAAE;EAVF;IAWI,kBAAA;EAGF;AACF;AADE;EACE,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,SAAA;EACA,mBAAA;AAGJ;AADI;EAPF;IAQI,mBAAA;IACA,eAAA;EAIJ;AACF;AAAI;EACE,kCAAA;AAEN;AAEE;EACE;IACE,uBAAA;EAAJ;EAEE;IACE,yBAAA;EAAJ;AACF","sourcesContent":[".sup-payment-loader {\n  padding: 88px 36px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  @media (max-width: 991px) {\n    padding: 80px 36px;\n  }\n\n  @media (max-width: 576px) {\n    padding: 40px 36px;\n  }\n\n  &__heading {\n    font-weight: 600;\n    font-size: 36px;\n    line-height: 1.2;\n    margin: 0;\n    margin-bottom: 60px;\n\n    @media (max-width: 576px) {\n      margin-bottom: 40px;\n      font-size: 24px;\n    }\n  }\n\n  &__spinner {\n    img {\n      animation: spin infinite 3s linear;\n    }\n  }\n\n  @keyframes spin {\n    0% {\n      transform:rotate(0deg);\n    }\n    100% {\n      transform:rotate(360deg);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
