import {Component, Input, OnChanges, OnInit} from "@angular/core";
import { parameterdUtils } from "../../../../../clientCommon/utils/parameterUtils";
import {UxHelper} from "../../../../../clientCommon/helper/uxHelper";
import {LandingPeopleSearch} from "../../../../peopleSearch/landingPeopleSearch";



@Component({
  selector: 'legacy-landing-phone-search',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LegacyLandingPhoneSearchComponent implements OnInit, OnChanges {
  @Input() base: LandingPeopleSearch;

  @Input() uxHelper: UxHelper;
  @Input() pageType: string;
  @Input() pageCategory: string;
  @Input() page: string;

  features = []
  aboutItems = []
  whyItems = [];
  heroimageurl="" 

  itemList = [];

  constructor() {
  }

  init() {

  }

  ngOnInit() {
    //Adding params to enable skip in landing
    let params = parameterdUtils.getAllParams();



    if(params.skip==="true"){


      this.base.userInput.phone=params.ui_phone


      this.base.userInput.phone = this.base.phoneUtils.formatPhoneNumber(this.base.userInput.phone);
      this.base.update();
      this.base.submit()




    }
    this.itemList = this.uxHelper.uxComposite.getUxcomp('comp.sales.phone-search.landing.why.items');
    this.features = this.uxHelper.uxComposite.getUxcomp('comp.sales.phone-search.landing.feature.items');

    return this.init();

  }

  ngOnChanges() {
    return this.init();
  }

  getDigit(number: number, place: number) {
    return Math.floor(number / Math.pow(10, place - 1)) % 10;
  }

}
