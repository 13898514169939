import { AfterViewInit, Directive, ElementRef, Input, NgModule } from '@angular/core';
import { UxComp } from '../types';

@Directive({
    selector: '[setUxcompKeyid]',
    standalone: false
})
export class SetUxCompKeyIdDirective implements AfterViewInit{

  @Input('setUxcompKeyid') uxComp: UxComp;

  constructor(private element: ElementRef) {
  }

  ngAfterViewInit(): void {
    try {
      this.element.nativeElement.setAttribute('data-key', this.uxComp?.key);
      this.element.nativeElement.setAttribute('data-id', this.uxComp?.id);
    }
    catch(e) {
      /* element not in DOM */
    }
  }
}

@NgModule({
  declarations: [SetUxCompKeyIdDirective],
  exports: [SetUxCompKeyIdDirective],
})
export class SetUxCompKeyIdDirectiveModule {}
