import { CourtComponent } from './court.component';
import { ValueComponent } from './value.component';
import { Businessomponent } from './business.component';
import { CriminalComponent } from './criminal.component';
import { PropertyMapComponentModule } from './map.component';
import { SexOffenderComponentModule } from './sexOffender.component';
import {  MemberBenefitsComponentModule } from './benefits/memberBenefits.component';
import { MemberBenefitsNewComponentModule } from './benefits/newMemberBenefits.component';

export const COMPONENT_PEOPLE_SEARCH_MEMBER_DECLARATIONS = [
  ValueComponent,
  Businessomponent,
  CourtComponent,
  CriminalComponent,
];

export const COMPONENT_PEOPLE_SEARCH_MEMBER_IMPORTS = [
  PropertyMapComponentModule,
  SexOffenderComponentModule,
  MemberBenefitsComponentModule,
  MemberBenefitsNewComponentModule,
];
