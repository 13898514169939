import { CUSTOM_ELEMENTS_SCHEMA, Injectable, NgModule } from "@angular/core";
import { DatePipe } from "@angular/common";
import { register } from 'swiper/element/bundle';
import { provideHttpClient, withInterceptors } from "@angular/common/http";

import { AppComponent } from "./app.component";
import { routing } from "./app.routing";
import {
  AlertService,
  AuthenticationService,
  CrudService,
  JsonService,
  UxcService,
  CensusDataService,
  ClientCaptchaService,
  ReferralService
} from "../clientCommon/services";
import { StorageService } from "../clientCommon/services/storage.service";
import { UserInputService } from "../clientCommon/services/userInput.service";
import { UserInfoService } from "../clientCommon/services/userInfo.service";
import { CommerceService } from "../clientCommon/services/commerce.service";
import { SpinnerService } from "../clientCommon/services/spinner.service";
import { EmailService } from "../clientCommon/services/email.service";
import { ReferService } from "../clientCommon/services/refer.service";
import { PageService } from "../clientCommon/services/page.service";
import { PixelService } from "../clientCommon/services/pixel.service";
import { NoteService } from "../clientCommon/services/note.service";
import { ParameterService } from "../clientCommon/services/parameter.service";
import { TrackingService } from "../clientCommon/services/tracking.service";
import { ProgressorService } from "../clientCommon/services/ProgressorService";
import { CookieService } from "../clientCommon/services/cookie.service";
import { ServiceHelperService } from "../clientCommon/services/serviceHelper.service";
import { GUARD_DECLARATIONS } from "./guards";
import { CustomServiceHelperService } from "../clientCommon/custom/services/customServiceHelper.service";
import { LimitStorageService } from "../clientCommon/services/limitStorage.service";
import {
  CUSTOM_DECLARATIONS,
  CUSTOM_IMPORTS,
  CUSTOM_PROVIDERS,
} from "./custom/custom.module";
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
} from "@angular/platform-browser";
import { InputTrackingService } from "../clientCommon/services/InputTracking.service";
import { clientCaptchaInterceptor } from '../clientCommon/interceptors';

// notification module
import { MiniReversePhoneComponent } from './member/peopleSearch/nameSearch/detail/miniReversePhone/miniReversePhone.component';
import { ModalTriggerService } from "../clientCommon/services/modal-trigger.service";
import { ToastrService } from "./service/toastr.service";
import { TransformPipe } from "../clientCommon/pipes/transform.pipe";
import { SharedModule } from "./shared.module";
import { LoaderEyeComponentModule } from "@member/peopleSearch/nameSearch/detail/loader-eye/loader-eye.component";
import { AdditionalResourcesComponentModule } from "@member/peopleSearch/nameSearch/detail/additional-resources/additional-resources.component";
import { CourtDetailsComponentModule } from "@member/peopleSearch/nameSearch/detail/courtDetails/courtDetails.component";
import { CourtTypePipeModule } from "@member/peopleSearch/nameSearch/detail/courtType.pipe";
import { RedirectWarningComponentModule } from "@member/peopleSearch/nameSearch/detail/redirect-warning";
import { CommerceOfferService } from "@clientCommon/services/commerceOffer.service";
import { ScriptLoaderService } from "@clientCommon/services/scriptLoader.service";
import { RemoveInfoService } from "@clientCommon/services/removeInfo.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

register();

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  public buildHammer(element: HTMLElement): any {
    return new (window as any).Hammer(element, { touchAction: "pan-y" });
  }
}

export const appModuleDefiner = {
  declarations: [
    AppComponent,
//    SwiperDirective,
    ...CUSTOM_DECLARATIONS,
    MiniReversePhoneComponent,
    TransformPipe,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    
    LoaderEyeComponentModule,
    AdditionalResourcesComponentModule,
    CourtDetailsComponentModule,
    CourtTypePipeModule,
    RedirectWarningComponentModule,

    routing,
    ...CUSTOM_IMPORTS,
  ],
  providers: [
    provideHttpClient(
      withInterceptors([clientCaptchaInterceptor]),
    ),
    DatePipe,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    CrudService,
    JsonService,
    UxcService,
    StorageService,
    UserInputService,
    UserInfoService,
    InputTrackingService,
    CommerceService,
    CommerceOfferService,
    AuthenticationService,
    AlertService,
    SpinnerService,
    EmailService,
    ReferService,
    ReferralService,
    ParameterService,
    PixelService,
    ClientCaptchaService,
    LimitStorageService,
    PageService,
    TrackingService,
    ProgressorService,
    CookieService,
    CustomServiceHelperService,
    ServiceHelperService,
    ModalTriggerService,
    NoteService,
    CensusDataService,
    ToastrService,
    ScriptLoaderService,
    RemoveInfoService,
    ScriptLoaderService,
    ...GUARD_DECLARATIONS,
    ...CUSTOM_PROVIDERS,
  ],
  bootstrap: [AppComponent],
//  schemas: [CUSTOM_ELEMENTS_SCHEMA] 
}

@NgModule({
  declarations: appModuleDefiner.declarations,
  imports: appModuleDefiner.imports,
  providers: appModuleDefiner.providers,
  bootstrap: appModuleDefiner.bootstrap,
})
export class AppModule {}
