import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {User} from "../../common/models/user/user";
import {AuthenticationService} from "../../clientCommon/services/authentication.service";
import {AlertService} from "../../clientCommon/services/alert.service";

@Injectable()
export class CustomerAuthGuard  {

  constructor(private router: Router,
              private authenticationService: AuthenticationService,
              private alertSerivce: AlertService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    var user = this.authenticationService.getUser();

    if (user && user.isValid([User.ROLES.customer])) {
      return true;
    }

    return false;
  }
}
