import {ModelBase} from "../modelBase";
import {CollectionClass} from "../../decorators/database/collectionClass";
import {TransientField} from "../../decorators/database/transientField";
import {ForeignObjectField} from "../../decorators/database/foreignObjectField";
import {ReferenceField} from "../../decorators/database/referenceField";
import {ForeignKeyField} from "../../decorators/database/foreignKeyField";
import {CommercePayment} from "./commercePayment";
import {CommerceOffer, CommerceOfferDetail} from "./commerceOffer";
import {LogUtils} from "../../utils/logUtils";
import {IndexField} from "../../decorators/database/indexField";
import {User} from "../user/user";
import {ModelRevision} from "../modelRevision";
import {CompositeClassField} from "../../decorators/database/compositeClassField";
import {CommercePrice} from "./commercePrice";

@CollectionClass({name: "commerceTransactions", revisionEnabled: false, draftEnabled: false})
export class CommerceTransaction extends ModelBase<CommerceTransaction> {
  public static TYPE = {};

  @IndexField() @ForeignKeyField(User) payeeId: string;
  @IndexField() @ForeignKeyField(User) payerId: string;

  @IndexField() @ForeignKeyField(CommerceOffer) commerceOfferIds: string[] = [];
  @TransientField @ForeignObjectField("commerceOfferIds") commerceOffers: CommerceOffer[];
  @ForeignKeyField(ModelRevision) commerceOfferRevisionIds: string[] = [];

  @IndexField() @ReferenceField(CommercePayment) @ForeignKeyField(CommercePayment) commercePaymentIds: string[] = [];
  @TransientField @ForeignObjectField("commercePaymentIds") commercePayments: CommercePayment[];

  @CompositeClassField(CommerceOfferDetail) commerceOfferDetails: CommerceOfferDetail[] = [];

  @IndexField() dueTimestamp: number;
  @IndexField() paymentTimestamp: number;
  sequence: number;
  retry: number;

  price: { code: string, amount: number, options };
  note: string;

  constructor(doc?, draftFlag?: boolean) {
    super(doc, draftFlag);
    this.init(doc, this.draftFlag);
  }

  getCommerceOfferDetail(commerceOfferId: string): CommerceOfferDetail {
    let result;

    this.commerceOfferDetails.some((commerceOfferDetail) => {
      if (commerceOfferDetail.commerceOfferId === commerceOfferId) {
        result = commerceOfferDetail;
        return true;
      }
    });

    return result;
  }

  getPrice(commerceOfferId: string) {
    let commerceOfferDetail = this.getCommerceOfferDetail(commerceOfferId);

    if (commerceOfferDetail) {
      return commerceOfferDetail.price;
    }
  }

  addPrice(commerceOfferId: string, price: { code: string, amount: number }) {
    let commerceOfferDetail = this.getCommerceOfferDetail(commerceOfferId);
    if (commerceOfferDetail.price.code === price.code) {
      commerceOfferDetail.price.amount += price.amount;
    } else {
      throw Error("Wrong code");
    }
  }

  getTotalPrice() {
    let code;
    let totalAmount = 0;
    let options = {};

    this.commerceOfferDetails.forEach((commerceOfferDetail) => {
      if (!code) {
        code = commerceOfferDetail.price.code;
      }
      if (code != commerceOfferDetail.price.code) {
        LogUtils.error(this.commerceOfferIds);
        throw Error("Code does not match");
      }
      totalAmount += commerceOfferDetail.price.amount;
      CommercePrice.mergePriceOptions(commerceOfferDetail.price.options, options);
    });

    return {code: code, amount: totalAmount, options: options};
  }

  getSecuredDoc(): CommerceTransaction {
    let obj: CommerceTransaction = super.getSecuredDoc();

    obj.commerceOfferIds = this.commerceOfferIds;
    obj.commercePaymentIds = this.commercePaymentIds;
    obj.commerceOfferDetails = this.commerceOfferDetails;
    obj.dueTimestamp = this.dueTimestamp;
    obj.paymentTimestamp = this.paymentTimestamp;
    obj.sequence = this.sequence;
    obj.retry = this.retry;
    obj.price = this.price;
    obj.note = this.note;

    return obj;
  }

  getOrderDescription(): string {
    let description = "";
    this.commerceOffers.forEach((commerceOffer) => {
      if (description.length > 0) {
        description += ", ";

      }
      description += commerceOffer.description;
    });
    return description;
  }
}
