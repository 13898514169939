import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, ViewChild, NgModule } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SalesHeaderComponent } from 'app/sales/components/salesHeader.component';
import { UxHelper } from 'clientCommon/helper/uxHelper';
import { ServiceHelperService } from 'clientCommon/services/serviceHelper.service';
import { customClientPaths } from 'common/custom/customPathHelpers';

@Component({
  selector: 'ux-5-2-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends SalesHeaderComponent implements OnInit {
  @Input() uxHelper: UxHelper;
  @ViewChild('navbar') navbar;
  customClientPaths = customClientPaths;
  isNavVisible = false;
  

  constructor(serviceHelperService: ServiceHelperService,
              activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
  }

  ngOnInit() {
    return super.ngOnInit();
  }

  onToggleNav() {
    if (this.navbar.nativeElement.hasAttribute("data-nav-menu-open")) {
      this.navbar.nativeElement.removeAttribute("data-nav-menu-open");
    } else {
      this.navbar.nativeElement.setAttribute("data-nav-menu-open", "");
    }
  }
}

// @NgModule({
//   declarations: [HeaderComponent],
//   imports: [CommonModule],
//   exports: [HeaderComponent],
// })
// export class HeaderComponentModule {}
