// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 736px;
  width: 100%;
  height: 122px;
  background: #f2f2ff;
  border: 1px solid #4444cf;
}
@media (max-width: 450px) {
  .container p {
    text-align: center;
    font-size: 16px;
  }
}
@media (max-width: 752px) {
  .container {
    justify-content: center;
    flex-direction: column;
    height: 132px;
  }
}

.image-section {
  width: 25%;
}
@media (max-width: 752px) {
  .image-section {
    width: 80px;
    height: 70px;
  }
}

.content-section {
  width: 50%;
  max-width: 255px;
  height: 29px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
}
@media (max-width: 752px) {
  .content-section {
    width: 100%;
    justify-content: center;
  }
}

.price-section {
  display: flex;
  justify-content: flex-end;
  width: 40%;
  height: 29px;
  left: 693px;
  top: 364px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}
@media (max-width: 752px) {
  .price-section {
    width: 100%;
    justify-content: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/themes/2234200bd3cc36ba97274ecbdc6c6a47/components/sup/casx-alert/casx-alert.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;AACF;AACI;EADF;IAEI,kBAAA;IACA,eAAA;EAEJ;AACF;AACE;EAhBF;IAiBI,uBAAA;IACA,sBAAA;IACA,aAAA;EAEF;AACF;;AAAA;EACE,UAAA;AAGF;AAFE;EAFF;IAGI,WAAA;IACA,YAAA;EAKF;AACF;;AAFA;EACE,UAAA;EACA,gBAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAKF;AAHE;EAVF;IAWI,WAAA;IACA,uBAAA;EAMF;AACF;;AAHA;EACE,aAAA;EACA,yBAAA;EACA,UAAA;EACA,YAAA;EACA,WAAA;EACA,UAAA;EAEA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EAEA,cAAA;AAIF;AAFE;EAhBF;IAiBI,WAAA;IACA,uBAAA;EAKF;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  max-width: 736px;\n  width: 100%;\n  height: 122px;\n  background: #f2f2ff;\n  border: 1px solid #4444cf;\n  p {\n    @media (max-width: 450px) {\n      text-align: center;\n      font-size: 16px;\n    }\n  }\n\n  @media (max-width: 752px) {\n    justify-content: center;\n    flex-direction: column;\n    height: 132px;\n  }\n}\n.image-section {\n  width: 25%;\n  @media (max-width: 752px) {\n    width: 80px;\n    height: 70px;\n  }\n}\n\n.content-section {\n  width: 50%;\n  max-width: 255px;\n  height: 29px;\n  font-family: 'Lato';\n  font-style: normal;\n  font-weight: 600;\n  font-size: 24px;\n  line-height: 29px;\n\n  @media (max-width: 752px) {\n    width: 100%;\n    justify-content: center;\n  }\n}\n\n.price-section {\n  display: flex;\n  justify-content: flex-end;\n  width: 40%;\n  height: 29px;\n  left: 693px;\n  top: 364px;\n\n  font-family: 'Lato';\n  font-style: normal;\n  font-weight: 900;\n  font-size: 24px;\n  line-height: 29px;\n\n  color: #000000;\n\n  @media (max-width: 752px) {\n    width: 100%;\n    justify-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
