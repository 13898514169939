// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-box {
  min-height: 400px;
}



.use {
  position: relative;
  min-height: 40px;
  padding-bottom: 10px;

}

.narrow{
  padding-top: 30px;
}

.name{
  padding-top: 20px;
  padding-bottom: 10px;
}

.city{
  padding-right: 12px;
}
@media only screen and (max-width: 768px){
  .submit{
    width: 90%;
  }
  #city-bottom{
    width: 206px;
    display: inline;
  }
  .name{
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .narrow{
    padding-top: 0px;
  }
}

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    display: none;
  }
`, "",{"version":3,"sources":["webpack://./src/app/peopleSearch/templates/nameSearch/default.1.2/loader.component.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;;;AAIA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,oBAAoB;;AAEtB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,mBAAmB;AACrB;AACA;EACE;IACE,UAAU;EACZ;EACA;IACE,YAAY;IACZ,eAAe;EACjB;EACA;IACE,gBAAgB;IAChB,mBAAmB;EACrB;EACA;IACE,gBAAgB;EAClB;AACF;;EAEE;;IAEE,aAAa;EACf","sourcesContent":[".content-box {\n  min-height: 400px;\n}\n\n\n\n.use {\n  position: relative;\n  min-height: 40px;\n  padding-bottom: 10px;\n\n}\n\n.narrow{\n  padding-top: 30px;\n}\n\n.name{\n  padding-top: 20px;\n  padding-bottom: 10px;\n}\n\n.city{\n  padding-right: 12px;\n}\n@media only screen and (max-width: 768px){\n  .submit{\n    width: 90%;\n  }\n  #city-bottom{\n    width: 206px;\n    display: inline;\n  }\n  .name{\n    padding-top: 0px;\n    padding-bottom: 0px;\n  }\n  .narrow{\n    padding-top: 0px;\n  }\n}\n\n  input::-webkit-outer-spin-button,\n  input::-webkit-inner-spin-button {\n    display: none;\n  }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
