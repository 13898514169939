// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.magnifier {
  height: 220px;
  width: 220px;
  background-repeat: no-repeat;
  background-position-x: 18px;
  background-position-y: 18px;
  background-image: url("/assets/ux5/progress-indicator/loader-faces-color.gif");
}
@media (max-width: 991px) {
  .magnifier {
    height: 180px;
    width: 180px;
    background-position-x: 10px;
    background-position-y: 10px;
    background-size: 100px;
    transform: scale(0.72);
  }
}
@media (max-width: 768px) {
  .magnifier {
    height: 64px;
    width: 64px;
    background-position-x: 5px;
    background-position-y: 5px;
    background-repeat: no-repeat;
    background-size: 35px;
    transform: scale(0.72);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/themes/2234200bd3cc36ba97274ecbdc6c6a47/components/loader/magnifier/magnifier.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,YAAA;EACA,4BAAA;EACA,2BAAA;EACA,2BAAA;EACA,8EAAA;AACJ;AACI;EARJ;IASQ,aAAA;IACA,YAAA;IACA,2BAAA;IACA,2BAAA;IACA,sBAAA;IACA,sBAAA;EAEN;AACF;AAAI;EAjBJ;IAkBQ,YAAA;IACA,WAAA;IACA,0BAAA;IACA,0BAAA;IACA,4BAAA;IACA,qBAAA;IACA,sBAAA;EAGN;AACF","sourcesContent":[".magnifier {\n    height: 220px;\n    width: 220px;\n    background-repeat: no-repeat;\n    background-position-x: 18px;\n    background-position-y: 18px;\n    background-image: url(\"/assets/ux5/progress-indicator/loader-faces-color.gif\");\n\n    @media (max-width: 991px) {\n        height: 180px;\n        width: 180px;\n        background-position-x: 10px;\n        background-position-y: 10px;\n        background-size: 100px;\n        transform: scale(0.72);\n    }\n\n    @media (max-width: 768px) {\n        height: 64px;\n        width: 64px;\n        background-position-x: 5px;\n        background-position-y: 5px;\n        background-repeat: no-repeat;\n        background-size: 35px;\n        transform: scale(0.72);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
