import { PeopleSearchService } from "../../clientCommon/custom/services/peopleSearch.service";
import { SALES_DECLARATIONS } from "../sales";
import { MEMBER_DECLARATIONS, MEMBER_PAGE_MODULES } from "../member";
import { MAIL_DECLARATIONS } from "../mail";
import { MISC_DECLARATIONS } from "../misc";
import { PEOPLE_SEARCH_TEMPLATES_DECLARATIONS } from "../peopleSearch/templates";

export const CUSTOM_DECLARATIONS = [
  ...SALES_DECLARATIONS,
  ...MEMBER_DECLARATIONS,
  ...MAIL_DECLARATIONS,
  ...MISC_DECLARATIONS,
  ...PEOPLE_SEARCH_TEMPLATES_DECLARATIONS,
];

export const CUSTOM_IMPORTS = [ ...MEMBER_PAGE_MODULES, ];
export const CUSTOM_PROVIDERS = [
  PeopleSearchService,
];
