export class DocumentReferenceUtils {

    static shouldLog() {
        return !true;
    }

    static getIndex(references: any[], collection: string, id: string): number {

        var index = -1;

        if (references) {
            references.some((reference, i) => {
                if (reference.id == id && reference.collection == collection) {
                    index = i;
                    return true;
                }
            });
        }
        return index;
    }

    static hasReference(references: any[], collection: string, id: string): boolean {
        return this.getIndex(references, collection, id) > -1;
    }

    static addReference(references: any[], collection: string, id: string): void {
        if (!this.hasReference(references, collection, id)) {
            references.push({id: id, collection: collection});
        }
    }

    static removeReference(references: any[], collection: string, id: string): void {
        var index = this.getIndex(references, collection, id);
        if (index > -1) {
            var result = references.splice(index, 1);
        }
    }

    static getLength(references: any[]): number {
        if (references) {
            return references.length;
        }

        return 0;
    }
}
