import {Component, OnDestroy, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {UxComposite} from "../../common/models/ux/uxComposite";
import {UxcService} from "../../clientCommon/services/uxc.service";
import {LogUtils} from "../../common/utils/logUtils";
import {JsonService} from "../../clientCommon/services/json.service";
import {UxHelper} from "../../clientCommon/helper/uxHelper";

@Component({
  templateUrl: './mpi3dsPares.component.html'
})

export class Mpi3dsParesComponent implements OnInit, OnDestroy {
  uxHelper: UxHelper = new UxHelper();
  uxComposite: UxComposite;
  pageType = "sales";
  pageCategory = "mpi";
  page = "3dsPares";

  constructor(private router: Router,
              private jsonService: JsonService,
              private uxcService: UxcService) {
    this.uxHelper.prefix = `${this.pageType}.${this.pageCategory}.${this.page}`;
    this.uxHelper.pageType = this.pageType;
    this.uxHelper.pageCategory = this.pageCategory;
    this.uxHelper.page = this.page;
    this.uxcService.getUxComposite().then((uxComposite: UxComposite) => {
      this.uxHelper.uxComposite = uxComposite;
      this.uxComposite = uxComposite;
    }).catch((e) => {
      LogUtils.error(e);
    });
  }

  ngOnInit() {
    let params = new URLSearchParams(window.location.search);
  }

  ngOnDestroy() {
  }

}
