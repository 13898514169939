import { Component, OnInit, Input, NgModule } from '@angular/core';
import { UxHelper } from '../../../../../../../src/clientCommon/helper/uxHelper';
import { CommonModule } from '@angular/common';
import { MemberUpsellPremiumBadgeComponentModule } from '@member/components/upsells/premium-badge/premium-badge.component';
import { MiniHistoryAccordionComponentModule } from '../history-accordion/accordion.component';

@Component({
    selector: 'app-mini-property',
    templateUrl: './property.component.html',
    styleUrls: ['./property.component.scss'],
    standalone: false
})
export class MiniPropertyComponent implements OnInit {
  @Input() property: any;
  @Input() isPdfPrintView: boolean;
  @Input() index: number;
  @Input() upsellEnabled: boolean;
  @Input () uxHelper:UxHelper

  expanded = false;

  constructor() { }

  ngOnInit(): void {
  }
}

@NgModule({
  declarations: [MiniPropertyComponent],
  imports: [CommonModule, MemberUpsellPremiumBadgeComponentModule, MiniHistoryAccordionComponentModule],
  exports: [MiniPropertyComponent],
})
export class MiniPropertyComponentModule {}
