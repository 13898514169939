import { Component, OnInit, Input, Output, EventEmitter, NgModule } from '@angular/core';
import { ActivatedRoute, Router, ActivationEnd } from '@angular/router';
import { customMemberMiniSteps, customClientPaths } from '../../../../../../common/custom/customPathHelpers';
import { environment } from '../../../../../../environments/environment';
import { CommonModule } from '@angular/common';
import { ReportActionComponentModule } from '@member/peopleSearch/nameSearch/detail/reportAction/reportAction.component';
import { DirectionButtonComponentModule } from '@member/peopleSearch/nameSearch/detail/directionButton/directionButton.component';
import { NextReportComponentModule } from '@member/peopleSearch/nameSearch/detail/nextReport/nextReport.component';

@Component({
    selector: 'app-mini-report-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: false
})
export class MiniReportFooter implements OnInit {
  @Input() base: any;
  @Input() isFirst: boolean = false;
  @Input() isLast: boolean = false;
  @Input() person: any;
  @Output() showEyeLoader = new EventEmitter<void>();
  @Input() isPdfPrintView = false;
  @Input() showButton = true;
  @Input() shareLink = true;
  @Input() disableMonitoring = false;

  customMemberMiniSteps = customMemberMiniSteps;
  customClientPaths = customClientPaths;
  contentId: string;
  currentStep: string = customMemberMiniSteps.linear;
  loaderEyeTimeout: number = environment.miniDetailsLoaderEyeTimeout; //2000; // loader animated Eye Timeout

  constructor(public activatedRoute: ActivatedRoute, private router: Router) {
    activatedRoute.params.subscribe((val) => {
      this.contentId = val.id;
    });
    router.events.subscribe((event) => {
      if (event instanceof ActivationEnd) {
        this.currentStep = event.snapshot.url.reverse()[0]?.path;
        window.scrollBy(0, 0);
      }
    });
  }

  ngOnInit(): void {
    window.scrollBy(0, 0);
    this.currentStep = this.router.url.split('/').reverse()[0];
  }

  handlePrevious() {
    if (this.currentStep === this.customMemberMiniSteps.linear) {
      return;
    } else if (this.currentStep === this.customMemberMiniSteps.socialMedia) {
      this.navigateTo(this.customMemberMiniSteps.linear);
    } else if (this.currentStep === this.customMemberMiniSteps.family) {
      this.navigateTo(this.customMemberMiniSteps.socialMedia);
    } else if (this.currentStep === this.customMemberMiniSteps.lifeEvents) {
      this.navigateTo(this.customMemberMiniSteps.family);
    } else if (this.currentStep === this.customMemberMiniSteps.properties) {
      this.navigateTo(this.customMemberMiniSteps.lifeEvents);
    } else if (this.currentStep === this.customMemberMiniSteps.careerFinancial) {
      this.navigateTo(this.customMemberMiniSteps.properties);
    } else if (this.currentStep === this.customMemberMiniSteps.criminalRecords) {
      this.navigateTo(this.customMemberMiniSteps.careerFinancial);
    } else if (this.currentStep === this.customMemberMiniSteps.miscellaneous) {
      this.navigateTo(this.customMemberMiniSteps.criminalRecords);
    }
  }

  handleNext() {
    if (this.currentStep === this.customMemberMiniSteps.linear) {
      this.navigateTo(this.customMemberMiniSteps.socialMedia);
    } else if (this.currentStep === this.customMemberMiniSteps.socialMedia) {
      this.navigateTo(this.customMemberMiniSteps.family);
    } else if (this.currentStep === this.customMemberMiniSteps.family) {
      this.navigateTo(this.customMemberMiniSteps.lifeEvents);
    } else if (this.currentStep === this.customMemberMiniSteps.lifeEvents) {
      this.navigateTo(this.customMemberMiniSteps.properties);
    } else if (this.currentStep === this.customMemberMiniSteps.properties) {
      this.navigateTo(this.customMemberMiniSteps.careerFinancial);
    } else if (this.currentStep === this.customMemberMiniSteps.careerFinancial) {
      this.navigateTo(this.customMemberMiniSteps.criminalRecords);
    } else if (this.currentStep === this.customMemberMiniSteps.criminalRecords) {
      this.navigateTo(this.customMemberMiniSteps.miscellaneous);
    } else if (this.currentStep === this.customMemberMiniSteps.miscellaneous) {
      return;
    }
  }

  navigateTo(page: string) {
    this.showEyeLoader.emit();
    setTimeout(() => {
      this.router.navigateByUrl(this.customClientPaths.memberNameSearchDetail + '/' + this.contentId + '/' + page);
    }, this.loaderEyeTimeout);
  }
}

@NgModule({
  declarations: [MiniReportFooter],
  imports: [CommonModule, ReportActionComponentModule, DirectionButtonComponentModule, NextReportComponentModule],
  exports: [MiniReportFooter],
})
export class MiniReportFooterModule {}
