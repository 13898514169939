import {environment} from "../../environments/environment";

class UrlUtils {
  absoluteUrlRegex = new RegExp('^(?:[a-z]+:)?//', 'i');
  /**
   * 
   * @param url the current url of the window
   * @returns the current url after testing the validity of its format
   */
  isAbsoluteUrl(url): boolean {
    return this.absoluteUrlRegex.test(url)
  }
/**
 * 
 * @param domain the current domain of the page
 * gets the second level domain and sets it to result
 * sets the second level domain to the default site environment and assings it to result 
 * @returns the result having the domain
 */
  get2ndLevelDomain(domain) {
    let result = this.doGet2ndLevelDomain(domain);

    if (!result) {
      result = this.doGet2ndLevelDomain((<any>environment).defaultSite);
    }

    return result;
  }
/**
 * 
 * @param domain the current domain of the page
 * splits the domain based on dot if domain exists
 * @returns splitted domain
 */
  private doGet2ndLevelDomain(domain) {
    if (domain) {
      let splitted = domain.split(".");
      if (splitted && splitted.length > 1) {
        return splitted[splitted.length - 2];
      }
    }
  }
}

export var urlUtils = new UrlUtils();

