import { Component, Input } from '@angular/core';
import { Benefits as benefitsContent } from './data';
import { UxComp } from '@ux5.2/types';

type BenefitsType = {
  label: string;
  icon: string;
};

@Component({
  selector: 'ux-5-2-icon-label-card',
  templateUrl: './icon-label-card.component.html',
  styleUrls: ['./icon-label-card.component.scss'],
})
export class IconLabelCardComponent {
  @Input() benefits: Array<BenefitsType> = benefitsContent;
  @Input() title: UxComp;
}

