
export class CustomModelLoader {

    public load() {
        // Force Importing
    }
}

export var customModelLoader = new CustomModelLoader();

