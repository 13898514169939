import { Injectable } from "@angular/core";
import { Observable, Observer } from "rxjs";

@Injectable()
export class ScriptLoaderService {
  private scripts: ScriptModel[] = [];

  public loadSrc(src: string): Observable<ScriptModel> {
    return new Observable<ScriptModel>((observer: Observer<ScriptModel>) => {
      const existingScript = this.scripts.find(s => s.src === src);

      // Complete if already loaded
      if (existingScript && existingScript.loaded) {
        observer.next(existingScript);
        observer.complete();
      } else {
        // Add the script
        let script = new ScriptModel();
        script.src = src;
        script.loaded = false;
        this.scripts = [...this.scripts, script];

        // Load the script
        let scriptElement = document.createElement("script");
        scriptElement.type = "text/javascript";
        scriptElement.src = script.src;

        scriptElement.onload = () => {
          script.loaded = true;
          observer.next(script);
          observer.complete();
        };

        scriptElement.onerror = (error: any) => {
          observer.error("Couldn't load script " + script.src);
        };

        document.getElementsByTagName('body')[0].appendChild(scriptElement);
      }
    });
  }
}

export class ScriptModel {
  src: string;
  loaded: boolean;
}
