import {Component, OnDestroy, OnInit, Input, EventEmitter, Output, OnChanges, NgModule} from "@angular/core";

import { LoaderPeopleSearch } from "../../../../../../src/app/peopleSearch/loaderPeopleSearch";
import { ServiceHelperService } from "../../../../../clientCommon/services/serviceHelper.service";
import { SearchedPerson } from "../../../../../common/custom/models/peopleSearch/searchedPerson";
import { CommonModule } from "@angular/common";
import { LoaderUpsellComponentModule } from "./loader/loader.component";
import { MemberUpsellPaymentModalModule } from "@member/components/paymentModal/payment-modal.component";

declare const $: any;

@Component({
    selector: 'member-upsell-global',
    templateUrl: './upsell-global.component.html',
    styleUrls: ['./upsell-global.component.scss'],
    standalone: false
})
export class MemberUpsellGlobalComponent implements OnInit, OnDestroy, OnChanges {
  @Input() base:LoaderPeopleSearch
  @Input() person: SearchedPerson;
  @Input() count: number = 0;
  @Input() uxHelper: any;
  @Input() noData: boolean = false;
  @Input() commerceContentId: string;
  @Output() onSuccess = new EventEmitter<void>();
  @Input() showAliases = false;
  @Input() isPdfPrintView : boolean = false;
  @Input() upsellEnabled: boolean = true;
  @Input() section = '';
  @Input() global = false;

bonusflag=false;
enhancedflag=false;
  visibleAkas = false;
  loader=false;
  constructor(
    private serviceHelperService: ServiceHelperService
  ) {}

  availableCounts = 0;

  ngOnInit() {
    const upsellnameflag=this.uxHelper.uxComposite.get("comp.billing.enhanced.name.enabled")
    this.bonusflag=upsellnameflag.bonus;
    this.enhancedflag=upsellnameflag.enhanced;
    this.visibleAkas = this.person?.tempClient?.unlocked?.linear;
  }

  ngOnDestroy() {

  }

  ngOnChanges() {
    this.visibleAkas = this.person?.tempClient?.unlocked?.linear;
  }

  onReload() {
    this.onSuccess.emit();
  }

  bonusloader(){
    this.loader=true;
  }
  checkVisible() {

    if (!this.upsellEnabled && !this.showAliases) {
      return false;
    }

    if (!this.person?.tempClient?.unlocked?.linear) {
      return true;
    }

    if (this.showAliases) {
      return true;
    }

    return false;
  }

  report() {
    this.serviceHelperService.trackingService.report({
      section: this.section,
      action: 'click',
      type: 'upsell'
    });
    this.serviceHelperService.trackingService.report({
      type: 'upsell',
      action: 'upsell_payment',
      benefit: true,
    });
  }
}

@NgModule({
  declarations: [MemberUpsellGlobalComponent],
  imports: [CommonModule, LoaderUpsellComponentModule, MemberUpsellPaymentModalModule],
  exports: [MemberUpsellGlobalComponent],
})
export class MemberUpsellGlobalComponentModule {}
