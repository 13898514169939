import {Component, Input, OnChanges, OnInit} from "@angular/core";
import {UxHelper} from "../../../../../clientCommon/helper/uxHelper";
import {SearchResultPeopleSearch} from "../../../searchResultPeopleSearch";
import {UxComposite} from "../../../../../common/models/ux/uxComposite";

@Component({
    selector: 'default-searchResult-phone-search',
    templateUrl: './searchResult.component.html',
    styleUrls: ['./searchResult.component.scss'],
    standalone: false
})
export class DefaultSearchResultPhoneSearchComponent implements OnInit, OnChanges {
  @Input() base: SearchResultPeopleSearch;

  @Input() uxHelper: UxHelper;
  @Input() pageType: string;
  @Input() pageCategory: string;
  @Input() page: string;

  constructor() {
  }

  init() {
    if (this.base.candidateSearchObject) {
      this.base.submit(this.base.candidateSearchObject);
    }
  }

  ngOnInit() {
    return this.init();
  }

  ngOnChanges() {
    return this.init();
  }
}
