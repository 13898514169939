import { Component, Input, NgModule, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { UxComp, Format } from '@ux5.2/types';

@Component({
  selector: 'ux-5-2-popular-uses',
  templateUrl: './popular-uses.component.html',
  styleUrls: ['./popular-uses.component.scss']
})
export class PopularUsesComponent implements OnInit {
  @Input() title: UxComp;
  @Input() content: UxComp;

  format = Format;

  constructor(private viewportScroller: ViewportScroller) { }

  ngOnInit(): void {
    this.viewportScroller.scrollToPosition([0, 0]);
  }
}