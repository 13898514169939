import {Default1Dot3LandingNameSearchComponent} from "./landing.component";
import {Default1Dot3LoaderNameSearchComponent} from "./loader.component";
import {Default1Dot3SearchResultNameSearchComponent} from "./searchResult.component";
import {Default1Dot3ReportReviewNameSearchComponent} from "./reportReview.component";
import {Default1Dot3SignupNameSearchComponent} from "./signup.component";

export const DEFAULT_1_3_NAME_SEARCH_TEMPLATES_DECLARATIONS = [
  Default1Dot3LandingNameSearchComponent,
  Default1Dot3LoaderNameSearchComponent,
  Default1Dot3SearchResultNameSearchComponent,
  Default1Dot3ReportReviewNameSearchComponent,
  Default1Dot3SignupNameSearchComponent,
];
