import { Component, Input, NgModule, OnInit } from '@angular/core';
import { UxComp, Format } from '@ux5.2/types';

@Component({
  selector: 'ux-5-2-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.scss'],
})
export class MonitoringComponent implements OnInit {
  format = Format;

  @Input() userName: string = null;
  @Input() title: UxComp;
  @Input() imageUrls: UxComp;

  constructor() {}

  ngOnInit(): void {}
}