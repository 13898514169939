import {LogUtils} from "./logUtils";

export var TemplateEngine = function (html, options, depth?: number) {
  if (!depth) {
    depth = 1;
  }

  if (depth > 100) {
    throw new Error("Max depth occured. Depth:" + depth);
  }

  let re = /[$]{(.+?)}/g;
  let reExp = /(^( )?(try|catch|if|for|else|switch|case|break|{|}))(.*)?/g;
  let raw = "RAW:";
  let unsafe = "UNSAFE:";
  let code = 'var r=[];\n';
  let cursor = 0;
  let match;
  let count = 0;
  options.timestamp = new Date().getTime();

  let add: any = function (line, js) {
    if (js) {
      if (line.match(reExp)) {
        code += line + '\n';
      } else {
        count++;
        if (line.startsWith("comp.")) {
          code += ('try{r.push(options["' + line + '"])}catch(e){};\n');
        } else if (line.startsWith(raw)) {
          code += ('try{' + line.substr(raw.length) + '}catch(e){};\n');
        } else if (line.startsWith(unsafe)) {
          code += (line.substr(unsafe.length) + ';\n');
        } else {
          code += ('try{r.push(options.' + line + ')}catch(e){};\n');
        }
      }
    } else {
      if (line !== '') {
        code += 'r.push("' + line.replace(/"/g, '\\"') + '");\n';
      } else {
        code += '';
      }
    }

    return add;
  };
  while (match = re.exec(html)) {
    add(html.slice(cursor, match.index))(match[1], true);
    cursor = match.index + match[0].length;
  }
  add(html.substr(cursor, html.length - cursor));
  code += 'return r.join("");';
  let rendered = "";
  try {
    rendered = new Function('options', code.replace(/[\r\t\n]/g, ''))(options);
  } catch (e) {
    LogUtils.error(e);
  }
  return !count ? rendered : TemplateEngine(rendered, options, depth + 1);
};
// http://krasimirtsonev.com/blog/article/Javascript-template-engine-in-just-20-line

