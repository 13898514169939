import { POPUP_COMPONENT_DECLARATIONS } from './popup';
import { ImageRotator } from './imageRotator.component';
import { RawTemplate } from './rawTemplate.component';
import { UxTemplateModule } from './uxTemplate.component';
import { AD_COMPONENT_DECLARATIONS, AD_COMPONENT_MODULES } from './ad';
import { PoliciesModalComponentModule } from './policiesModal.component';
import { SignupPaymentLoader } from './paymentLoader/loader.component';
import { ThinMatchWelcomeModalComponentModule } from './thin-match-welcome-modal';
import { DevMessageComponentModule } from './devMessage.component';
import { ButtonsModule } from './buttons/buttons.module';
//import { FluentAdLoaderComponentModule } from './ad/fluent-ad-loader.component';

export const COMPONENT_DECLARATIONS = [
  ImageRotator,
  RawTemplate,
  SignupPaymentLoader,
  ...POPUP_COMPONENT_DECLARATIONS,
  ...AD_COMPONENT_DECLARATIONS,
];

export const COMPONENT_MODULES = [
  ...AD_COMPONENT_MODULES,
  UxTemplateModule,
  PoliciesModalComponentModule,
  ThinMatchWelcomeModalComponentModule,
  DevMessageComponentModule,
  ButtonsModule,
];
