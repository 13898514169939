// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button {
  background-color: white;
}

#loader .loader-modal {
  position: fixed;
}

.reSearch.cityState .submit {
  margin: -10px 0 10px;
}

@media (max-width: 768px) {
  .reSearch.cityState .submit {
    margin: 0 0 10px;
  }
}
.reSearch.moreThanAge button.answer {
  margin: 0 10px;
}

.btn-research {
  position: static;
  right: 40px;
  bottom: 0px;
  display: inline-block;
  margin-top: 32px;
  margin-right: auto;
  margin-left: auto;
  padding: 10px 40px;
  border-radius: 25px;
  background-color: #e89f10;
  box-shadow: 3px 3px 4px 2px rgba(0, 0, 0, 0.25);
  color: #fff;
  font-size: 20px;
  line-height: 23px;
  font-weight: 900;
}

.btn-link {
  color: #4851c0;
  background: transparent;
}

.submit {
  background-color: #76b509;
}`, "",{"version":3,"sources":["webpack://./src/app/themes/ux.2.0/peopleSearch/nameSearch/loader.component.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,oBAAA;AACF;;AAEA;EACE;IACE,gBAAA;EACF;AACF;AAEA;EACE,cAAA;AAAF;;AAGA;EACE,gBAAA;EACA,WAAA;EACA,WAAA;EACA,qBAAA;EACA,gBAAA;EACA,kBAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;EACA,yBAAA;EACA,+CAAA;EACA,WAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;AAAF;;AAEA;EACE,cAAA;EACA,uBAAA;AACF;;AACA;EACE,yBAAA;AAEF","sourcesContent":["button {\n  background-color: white;\n}\n\n#loader .loader-modal {\n  position: fixed;\n}\n\n.reSearch.cityState .submit {\n  margin: -10px 0 10px;\n}\n\n@media (max-width: 768px) {\n  .reSearch.cityState .submit {\n    margin: 0 0 10px;\n  }\n}\n\n.reSearch.moreThanAge button.answer {\n  margin: 0 10px;\n}\n\n.btn-research {\n  position: static;\n  right: 40px;\n  bottom: 0px;\n  display: inline-block;\n  margin-top: 32px;\n  margin-right: auto;\n  margin-left: auto;\n  padding: 10px 40px;\n  border-radius: 25px;\n  background-color: #e89f10;\n  box-shadow: 3px 3px 4px 2px rgba(0, 0, 0, 0.25);\n  color: #fff;\n  font-size: 20px;\n  line-height: 23px;\n  font-weight: 900;\n}\n.btn-link{\n  color: #4851c0;\n  background: transparent;\n}\n.submit{\n  background-color: #76b509;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
