import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, NgModule, OnChanges, SimpleChanges } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { UxHelper } from '@clientCommon/helper/uxHelper';
import { ServiceHelperService } from '@clientCommon/services/serviceHelper.service';
import { customClientPaths } from '@common/custom/customPathHelpers';
import { LoaderPeopleSearch, SearchResultPeopleSearch } from '@peopeSearch';
// import { BasicProgressBarComponentModule, FooterComponentModule, HeaderComponentModule, PaperComponentModule } from '@ux5.2/common';
// import { ImageTitleComponentModule } from '@ux5.2/common/image-title';
// import {
//   AnimatedSearchingComponentModule,
//   ConfirmationComponentModule,
//   FollowUpQuestionComponentModule,
//   ProgressIndicatorComponentModule,
//   TileListComponentModule,
// } from '@ux5.2/components/loader';
import { SetUxCompKeyIdDirectiveModule } from '@ux5.2/directives';
import { PaperFormat, UxComp } from '@ux5.2/types';
import { UxTemplateModule } from 'app/components/uxTemplate.component';

interface Step {
  component: string;
  title: string;
  data?: any;
}

@Component({
  selector: 'ux-5-2-loader-name-search',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class Ux5Dot2LoaderNameSearchComponent implements OnInit, OnChanges {
  @Input() base: LoaderPeopleSearch;
  @Input() uxHelper: UxHelper;

  time: number = 20;
  progress: number = 0;
  stepCount: number = 0;
  timeCount: number = 0;
  
  srpBase: SearchResultPeopleSearch;
  format = PaperFormat;
  
  thinMatchEnabled = false;
  currentProgress = false;

  questionType: 'v1' | 'v2';

  title: UxComp;
  warning: UxComp;
  tileListTitle: UxComp;
  tileListItems;
  tileListItemsPaths: UxComp;
  narrowResultTitle: UxComp;
  confirmationTitle: UxComp;
  animatedSearchingTitle: UxComp;
  animatedSearchingStarContent: UxComp;
  animatedSearchingListTitle: UxComp;
  animatedSearchingList: UxComp;

  steps: Step[] = [
    {
      component: 'progress-indicator',
      title: 'Searching Millions of records for ',
    },
    {
      component: 'follow-up-question',
      title: null,
      data: { cityQuestion: true },
    },
    { component: 'tile-list', title: null },
    {
      component: 'follow-up-question',
      title: null,
      data: { cityQuestion: false },
    },
    { component: 'confirmation', title: null },
    {
      component: 'animated-searching',
      title: 'Searching Millions of records for ',
    },
  ];

  currentStep: Step = this.steps[0];

  constructor(private serviceHelperService: ServiceHelperService, private router: Router) {}

  ngOnInit(): void {
    this.loadUxcomps();

    if (this.questionType === 'v1') {
      this.loadV1Component();
    }

    return this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    return this.init();
  }

  init() {
    if(this.base.reSearchFlag) {
      this.base.reSearch(this.base.reSearchQuestion?.value?.yes);
    }
  }

  getUxcompValue(compName: string): UxComp {
    const compNameIndex = Object.keys(this.uxHelper?.uxComposite?.ids).indexOf(compName);
    const id = Object.values(this.uxHelper?.uxComposite?.ids)[compNameIndex] as string;
    return {
      id,
      key: compName,
      value: this.uxHelper?.uxComposite?.getUxcomp(compName),
    };
  }

  loadUxcomps() {
    let loaderCompPrefix = 'comp.sales.name-search.loader';

    this.title = this.getUxcompValue(`${loaderCompPrefix}.headline`);
    this.warning = this.getUxcompValue(`${loaderCompPrefix}.h2.text`);
    this.narrowResultTitle = this.getUxcompValue(`${loaderCompPrefix}.instructions.text`);
    this.tileListTitle = this.getUxcompValue(`${loaderCompPrefix}.interests.title`);
    this.tileListItems = this.getUxcompValue(`${loaderCompPrefix}.interests.items`);
    this.tileListItemsPaths = this.getUxcompValue(`${loaderCompPrefix}.interests.items-paths`);
    this.confirmationTitle = this.getUxcompValue(`${loaderCompPrefix}.confirmation.title`);
    this.animatedSearchingTitle = this.getUxcompValue(`${loaderCompPrefix}.headline`);
    this.animatedSearchingStarContent = this.getUxcompValue(`${loaderCompPrefix}.testimonials.title`);
    this.animatedSearchingListTitle = this.getUxcompValue(`${loaderCompPrefix}.progressor.title`);
    this.animatedSearchingList = this.getUxcompValue(`${loaderCompPrefix}.progressor.list`);

    this.setQuestionType(this.getUxcompValue(`${loaderCompPrefix}.type`));
  }

  loadV1Component() {
    if (this.stepCount == 0) {
      return setTimeout(() => {
        this.stepCount++;
        this.currentStep = this.steps[this.stepCount];
        this.base.fakeProgressors.current.setPause(true);
      }, (this.time * 1000) / 2);
    }

    this.stepCount++;
    this.thinMatchEnabled = this.serviceHelperService?.storageService?.getSession('thinMatchEnabled');
    if (this.stepCount == 5 && this.thinMatchEnabled) {
      this.base.fakeProgressors.current.setPause(false);
      this.serviceHelperService?.storageService?.setSession('stepSkipping', true);
      return this.router.navigateByUrl(customClientPaths.salesNameSearchReportReview);
    }
    if (this.stepCount < 5) {
      return (this.currentStep = this.steps[this.stepCount]);
    }

    this.currentStep = this.steps[this.stepCount];

    const interval = setInterval(() => {
      this.timeCount++;
      this.progress = (100 / this.time) * this.timeCount;

      if (this.progress >= 100) {
        this.base.fakeProgressors.current.setPause(false);
        clearInterval(interval);
      }
    }, ((this.time / 2) * 1000) / 10);
  }

  private setQuestionType(type: UxComp) {
    this.questionType = type?.value === 'v1' ? 'v1' : 'v2';
  }
}

// @NgModule({
//   declarations: [Ux5Dot2LoaderNameSearchComponent],
//   imports: [
//     CommonModule,
//     FormsModule,
//     ReactiveFormsModule,

//     UxTemplateModule,
//     SetUxCompKeyIdDirectiveModule,

//     HeaderComponentModule,
//     FooterComponentModule,
//     BasicProgressBarComponentModule,
//     ImageTitleComponentModule,
//     ProgressIndicatorComponentModule,
//     PaperComponentModule,

//     FollowUpQuestionComponentModule,
//     TileListComponentModule,
//     ConfirmationComponentModule,
//     AnimatedSearchingComponentModule,
//   ],
// })
// export class LoaderNameSearchComponentModule {}
