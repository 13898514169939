import { COMPLETING_RESULTS_REPORT_REVIEW_COMPONENTS_UX_5_2_THEME_DECLARATIONS } from './completing-results';
import { CONTACT_INFORMATION_REPORT_REVIEW_COMPONENTS_UX_5_2_THEME_DECLARATIONS } from './contact-information';
import { MEDIA_SCAN_REPORT_REVIEW_COMPONENTS_UX_5_2_THEME_DECLARATIONS } from './media-scan';
import { MONITORING_REPORT_REVIEW_COMPONENTS_UX_5_2_THEME_DECLARATIONS } from './monitoring';
import { POPULAR_USES_REPORT_REVIEW_COMPONENTS_UX_5_2_THEME_DECLARATIONS } from './popular-uses';
import { RECORDS_REVIEW_REPORT_REVIEW_COMPONENTS_UX_5_2_THEME_DECLARATIONS } from './records-review';

export const REPORT_REVIEW_COMPONENTS_UX_5_2_THEME_DECLARATIONS = [
    ...COMPLETING_RESULTS_REPORT_REVIEW_COMPONENTS_UX_5_2_THEME_DECLARATIONS,
    ...CONTACT_INFORMATION_REPORT_REVIEW_COMPONENTS_UX_5_2_THEME_DECLARATIONS,
    ...MEDIA_SCAN_REPORT_REVIEW_COMPONENTS_UX_5_2_THEME_DECLARATIONS,
    ...MONITORING_REPORT_REVIEW_COMPONENTS_UX_5_2_THEME_DECLARATIONS,
    ...POPULAR_USES_REPORT_REVIEW_COMPONENTS_UX_5_2_THEME_DECLARATIONS,
    ...RECORDS_REVIEW_REPORT_REVIEW_COMPONENTS_UX_5_2_THEME_DECLARATIONS,
];
