import {
    AfterViewChecked,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
    Input,
    ViewContainerRef,
    ComponentFactoryResolver,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '@clientCommon/components/BaseComponent';
import { ServiceHelperService } from '@clientCommon/services/serviceHelper.service';
import {redirectHelper} from '../../../clientCommon/helper/redirectHelper';
import { utmParamValueType } from '@sales/peopleSearch/nameSearch/landing/landing.component';
import { AddressField, AddressUtils } from '@common/utils/addressUtils';
import { emailUtils } from '@common/utils/emailUtils';
import { phoneUtils } from '@common/utils/phoneUtils';
import { LogUtils } from '@common/utils/logUtils';
import { RequestEvent } from '@common/event/requestEvent';
import { ResponseEvent } from '@common/event/responseEvent';
import { serverPaths } from '@common/helpers/pathHelpers';
  
@Component({
    templateUrl: './affiliateForm.component.html'
})
export class LandingAffilateFormComponent extends BaseComponent implements OnInit, OnDestroy {
    @ViewChild('firstName')
    private _firstNameInputElement: ElementRef;
    @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;

    addressField: AddressField = AddressUtils.getAddressField('US');
    allStateObj = [{ code: 'all', name: 'All States' }];
    addressFieldWithAllStateOption;
    addressFieldWithEmptyStateOption;

    fullName = "";
    companyName = "";
    streetAddress = "";
    city = "";
    state = "";
    zipcode = "";
    email;
    telNumber;
    message = "";

    feedBackFlag = false;
    emailFlag = false;
    sentFlag = false;  // TODO: for popup test = true
    fail = false;
    failedMessage = "";


   
  
    constructor(
      serviceHelperService: ServiceHelperService,
      activatedRoute: ActivatedRoute,
      private _cfr: ComponentFactoryResolver
    ) {
      super(serviceHelperService, activatedRoute);
      this.pageType = BaseComponent.PAGE_TYPE.general;
      this.pageCategory = BaseComponent.PAGE_CATOGORY.general;
      this.page = BaseComponent.PAGE.affilliate;
    }
  
    ngOnInit() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      //const utm_lp = urlParams.get('utm_lp');
      //this.utmParamValue = headerContent[utm_lp];
      return this.baseInit().then(() => {
        // if (this.theme === this.themes.ux_5_2) {
        //     this._loadUx5_2Page();
        // }

        this.addressFieldWithAllStateOption = [
            ...this.allStateObj,
            ...this.addressField.getAdministrativeAreaOptionsArray(),
        ];
        this.addressFieldWithEmptyStateOption = [
            ...[{ code: '', name: 'Select a State' }],
            ...this.addressField.getAdministrativeAreaOptionsArray(),
        ];

        this.initDone = true;
      });
    }
  
    ngOnDestroy() {
      return super.onDestroy();
    }
        
    @HostListener('window:scroll', ['$event'])
    onScroll($event) {
      super.onScroll($event);
    }

    isCheck(event) {
        var inp = String.fromCharCode(event.keyCode);
        if (/[a-zA-Z0-9._-]/.test(inp)) {
          return true;
        } else {
          event.preventDefault();
          return false;
        }
    }
    formatPhoneNumber() {
        this.telNumber = phoneUtils.formatPhoneNumber(this.telNumber);
    }
    // Check email typo
    mailCheck() {
        this.serviceHelperService.emailService.checkEmailAddress(this.email).then((email)=> {
          this.email = email.data;
        })
    }

    isValid() {
        return Promise.resolve()
          .then(async () => { // general validation for email, firstName, lastName and other fields
            const validated = await emailUtils.validateEmail(this.email);
            if (!validated) {
              this.emailFlag = false;
              return Promise.reject(false);
            }
            if (validated) {
              this.emailFlag = true;
            }
            if (!this.message) {
              return Promise.reject(false);
            }
    
            if (
              !(
                this.fullName ||
                this.companyName ||
                this.streetAddress ||
                this.city ||
                this.state ||
                this.zipcode || 
                this.telNumber
              )
            )
            {
              return Promise.reject(false);
            }
            if(!this.telNumber){
              return Promise.reject(false)
            }
          })
          .then((e) => { // validate email through mail check API
            return this.serviceHelperService.emailService
              .checkEmailAddress(this.email)
              .catch((ex) => {
                if (!ex) {
                  LogUtils.debug(ex);
    
                } else {
                  LogUtils.error(ex);
                }
                this.emailFlag = false;
                // return Promise.reject(false);
              });
          })
          .then(() => { // return true if above validation promise resolve with true
            this.emailFlag = true;
            return true;
          })
          .catch((e) => { // return false if above validation promise reject with false
            this.fail = true;
            if (e.responseCode === 500) {
              if (
                e.reason === ResponseEvent.REASON.authenticationRecaptchaTokenError
              ) {
                this.failedMessage = e.reason;
              } else {
                this.failedMessage = e.reason + this.uxComposite.brandName;
              }
            } else {
              this.failedMessage = e.reason;
            }
            if (e === false) {
              LogUtils.debug(e);
    
            } else {
              LogUtils.error(e);
    
            }
            return false;
          });
      }
    
      validationMessage = (): string => {
        const _email = this.email;
        const validated = emailUtils.validateEmail(_email);
        if (!this.fullName) {
          return "Enter Full Name";
        }
        if (!this.companyName) {
            return "Enter Company Name";
        }
        if (!this.streetAddress) {
            return "Enter Street Address";
        }
        if (!this.city) {
            return "Enter City";
        }
        if (!this.state) {
            return "Select state";
        }
        if (!this.zipcode) {
            return "Enter Zip Code";
        }
        if (!_email) {
          return "Email Cannot Be Empty";
        }
        if (!validated) {
          this.emailFlag = false;
          return "Email is not in valid Format";
        }
        if (!this?.telNumber||this.telNumber === undefined) {
          return "Phone Number is empty or in invalid Format";
        }
        if (!this.message) {
          return "Message Cannot be Empty";
        }
    
        return '';
    }    
    generateRequest = ():RequestEvent => {
        const requestEvent = new RequestEvent();
        requestEvent.param = {
          body: `Message: ${this.message.replace(/\n/g, "<br/>")}`,
          fromEmail: this.email,
          fullName: this.fullName,
          companyName: this.companyName,
          streetAddress: this.streetAddress,
          city: this.city,
          state: this.state,
          zipcode: this.zipcode,
          telephone: this.telNumber,
        };
        return requestEvent;
    }
    
    submitData() {
        const requestEvent = this.generateRequest();
        this.serviceHelperService.spinnerService.spin();
        return this.serviceHelperService.jsonService
        .json(serverPaths.affiliateEmail, requestEvent)
        .then(() => {
            this.serviceHelperService.spinnerService.unspin();
            this.sentFlag = true;
            return true
        }).catch((responseEvent: ResponseEvent) => {
            LogUtils.error(responseEvent.rawResponse);
            this.serviceHelperService.spinnerService.unspin();
            this.sentFlag = false;
            if (responseEvent?.reason) {
              this.failedMessage = responseEvent?.reason;
            }else {
              this.failedMessage = 'Server Error';
            }
            this.fail = true;
            return false;
        });
    }
    send() {
        return Promise.resolve()
        .then(() => {
          this.fail = false;
          return this.isValid();
        })
        .then((flag) => {
          if (flag) {
            return this.submitData();
          }
        })
        .then(() => {
          const msg = this.validationMessage();
          if (msg) {
            this.failedMessage = msg;
            this.feedBackFlag = true;
          }
        });
    }

    closePopup() {
        this.sentFlag = false;
    }
  
    // private async _loadUx5_2Page() {
    //   this.container?.clear?.();
    //   const { Ux5Dot2AffiliateFormComponent } = await import('@ux5.2/affiliate/form');
    //   const componentFactory = this._cfr.resolveComponentFactory(Ux5Dot2AffiliateFormComponent);
    //   const component = this.container?.createComponent?.(componentFactory);
    //   if (component) {
    //     component.instance.base = this;
    //     component.instance.uxHelper = this.uxHelper;
    //   }
    // }
  }
  