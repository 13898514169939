// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-direction {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #276FCF;
  border-radius: 6px;
  font-size: 12px;
  line-height: 24px;
  font-weight: 600;
  cursor: pointer;
  width: 96px;
  height: 24px;
  margin: 0 8px;
}
.btn-direction svg:first-child {
  margin-right: 8px;
}
.btn-direction svg:last-child {
  margin-left: 8px;
}`, "",{"version":3,"sources":["webpack://./src/app/member/peopleSearch/nameSearch/detail/directionButton/directionButton.component.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,mBAAA;EACA,uBAAA;EACA,yBAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;AACF;AAEI;EACE,iBAAA;AAAN;AAEI;EACE,gBAAA;AAAN","sourcesContent":[".btn-direction {\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  border: 1px solid #276FCF;\n  border-radius: 6px;\n  font-size: 12px;\n  line-height: 24px;\n  font-weight: 600;\n  cursor: pointer;\n  width: 96px;\n  height: 24px;\n  margin: 0 8px;\n\n  svg {\n    &:first-child {\n      margin-right: 8px;\n    }\n    &:last-child {\n      margin-left: 8px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
