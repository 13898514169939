import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter, NgModule } from '@angular/core';

@Component({
    selector: 'app-mini-history-accordion',
    templateUrl: './accordion.component.html',
    styleUrls: ['./accordion.component.scss'],
    standalone: false
})
export class MiniHistoryAccordionComponent implements OnInit {
  @Input() historyData: any;
  @Input() isPdfPrintView: any;
  @Input() index: number;

  constructor() { }

  ngOnInit(): void {
  }
}

@NgModule({
  declarations: [MiniHistoryAccordionComponent],
  imports: [CommonModule],
  exports: [MiniHistoryAccordionComponent],
})
export class MiniHistoryAccordionComponentModule {}
