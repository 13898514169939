import { LANDING_PEOPLE_SEARCH_UX_5_2_THEME_DECLARATIONS } from "./nameSearch/landing";
import { LOADER_PEOPLE_SEARCH_UX_5_2_THEME_DECLARATIONS } from "./nameSearch/loader";
import { REPORT_REVIEW_PEOPLE_SEARCH_UX_5_2_THEME_DECLARATIONS } from "./nameSearch/report-review";
import { SEARCH_RESULT_PEOPLE_SEARCH_UX_5_2_THEME_DECLARATIONS } from "./nameSearch/search-result";
import { SINGUP_PEOPLE_SEARCH_UX_5_2_THEME_DECLARATIONS } from "./nameSearch/signup";


export const PEOPLE_SEARCH_UX_5_2_THEME_DECLARATIONS = [
    ...LANDING_PEOPLE_SEARCH_UX_5_2_THEME_DECLARATIONS,
    ...LOADER_PEOPLE_SEARCH_UX_5_2_THEME_DECLARATIONS,
    ...REPORT_REVIEW_PEOPLE_SEARCH_UX_5_2_THEME_DECLARATIONS,
    ...SEARCH_RESULT_PEOPLE_SEARCH_UX_5_2_THEME_DECLARATIONS,
    ...SINGUP_PEOPLE_SEARCH_UX_5_2_THEME_DECLARATIONS,
  ];
  