import {PropertyDecoratorHelper} from "../helpers/propertyDecoratorHelper";

export var noCyclicFieldHelper = new PropertyDecoratorHelper();

/**
 * This decoration checks circular reference
 */
export function NoCyclicField(target, key: string): void {
    noCyclicFieldHelper.addProperties(target, key);
}
