import {LogUtils} from "../../common/utils/logUtils";

class ParameterdUtils {
  /**
   * sets the oGetVars to any object
   * gets and filters the params from the current url
   * catches and logs any error thrown from the window
   * @returns the Ogetvars containing the divided params
   */
  getAllParams() {
    let oGetVars: any = {};
    if (window.location.search.length > 1) {
      try {
        for (var aItKey, nKeyId = 0, aCouples = window.location.search.substr(1).split("&"); nKeyId < aCouples.length; nKeyId++) {
          aItKey = aCouples[nKeyId].split("=");
          oGetVars[decodeURIComponent(aItKey[0])] = aItKey.length > 1 ? decodeURIComponent(aItKey[1]) : "";
        }
      } catch (e) {
        LogUtils.error(e, window.location, window.location.href, window.location.search);
        window.location.search = "";
      }
    }

    return oGetVars;
  }
}

export var parameterdUtils = new ParameterdUtils();
