import { Injectable } from "@angular/core";
import { JsonService } from "./json.service";
import { RequestEvent } from "../../common/event/requestEvent";
import { ResponseEvent } from "../../common/event/responseEvent";
import { LogUtils } from "../../common/utils/logUtils";
import { serverPaths } from "../../common/helpers/pathHelpers";

@Injectable()
export class NoteService {
  /**
   *
   * @param jsonService to access localStorage for managing token etc.
   */
  constructor(private jsonService: JsonService) {}

  /**
   * This will call an API to create a note added by the user on some candidate report
   * @param note a note content to be added
   * @param privateFlag boolean flag set based on if the note author set the note as private or public
   * @param userId an id of the user who is addding the note
   * @param brandId an id of brand on which the note is being added
   * @param referenceId candidate commerce content id on which the note is being added
   * @returns resolved promise with the response for content data or rejected promise with error data
   */
  public createNote(note: string, privateFlag: boolean, externalId: string, userId: string, brandId?: string, referenceId?: string): Promise<any> {
    let requestEvent = new RequestEvent();
    requestEvent.param = {
      privateFlag,
      note,
      externalId,
      userId,
      brandId,
      referenceId
    };
    return this.jsonService
      .json(serverPaths.noteCreate, requestEvent)
      .then((responseEvent: ResponseEvent) => {
        return true;
      })
      .catch((responseEvent: ResponseEvent) => {
        LogUtils.error(responseEvent.rawResponse);
        return Promise.reject(false);
      });
  }

    /**
   * This will get all the notes calling the notes API, optionally it also take external Id to get specific
   * notes based either externalId(which can be customer id or note id)
   * @returns resolved promise with the response for content data or rejected promise with error data
   */
  public findNote(externalId: string): Promise<any> {
    let requestEvent = new RequestEvent();
    requestEvent.param = {
      externalId
    };
    return this.jsonService
      .json(serverPaths.noteFindAll, requestEvent)
      .then((responseEvent: ResponseEvent) => {
        return responseEvent.getDocs();
      })
      .catch((responseEvent: ResponseEvent) => {
        LogUtils.error(responseEvent.rawResponse);
        return Promise.reject(false);
      });
  }

  /**
   * This will delete the note based on note id calling delete note API
   * @param id of the note needed to be deleted
   * @returns resolved promise with the response for content data or rejected promise with error data
   */
  public deleteNote(id: string): Promise<any> {
    let requestEvent = new RequestEvent();
    requestEvent.param = {
      noteId: id
    }
    return this.jsonService
      .json(serverPaths.noteDelete, requestEvent)
      .then((responseEvent: ResponseEvent) => {
        return responseEvent.getDocs();
      })
      .catch((responseEvent: ResponseEvent) => {
        LogUtils.error(responseEvent.rawResponse);
        return Promise.reject(false);
      });
  }

  public feedbackNote(params): Promise<any> {
    let requestEvent = new RequestEvent();
    requestEvent.param = params;
    return this.jsonService
      .json(serverPaths.feedbackNote, requestEvent)
      .then((responseEvent: ResponseEvent) => {
        return true;
      })
      .catch((responseEvent: ResponseEvent) => {
        LogUtils.error(responseEvent.rawResponse);
        return Promise.reject(false);
      });
  }

  public findFeedbackNote(userId: string, brandId: string): Promise<any> {
    let requestEvent = new RequestEvent();
    requestEvent.param = {
      userId, brandId
    };
    return this.jsonService
      .json(serverPaths.findFeedbackNote, requestEvent)
      .then((responseEvent: ResponseEvent) => {
        return responseEvent.getDocs();
      })
      .catch((responseEvent: ResponseEvent) => {
        LogUtils.error(responseEvent.rawResponse);
        return Promise.reject(false);
      });
  }
}
