import {
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  EventEmitter,
  Input,
  HostListener,
  NgModule,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ServiceHelperService } from '../../../../../../clientCommon/services/serviceHelper.service';
import { PersonDetail } from '../../../personDetail';
import { ToastrService } from '../../../../../service/toastr.service';
import { BaseComponent } from '../../../../../../clientCommon/components/BaseComponent';
import { peopleSearchProductKeys } from '../../../../../../common/custom/peopleSearch/peopleSearchProductKeys';
import { SearchedPerson } from '../../../../../../common/custom/models/peopleSearch/searchedPerson';
import { AuthenticationService, NoteService } from '../../../../../../clientCommon/services';
import { User } from '../../../../../../common/models/user/user';
import { SpyIdHelper } from '../../../../../../clientCommon/helper/spyIdHelper';
import { CommonModule } from '@angular/common';
import { MiniReportHeaderModule } from '@member/peopleSearch/components/mini/header/header.component';
import { MemberUpsellGlobalComponentModule } from '@member/components/upsells/global/upsell-global.component';
import { MiniReportFooterModule } from '@member/peopleSearch/components/mini/footer/footer.component';
import { TutorialModalComponentModule } from '../tutorialModal/tutorialModal.component';
import { MiniReportDwbBreachesComponentModule } from '@member/peopleSearch/components/mini/dwb-breaches/dwb-breaches.component';
import { MiniReportShareReportComponentModule } from '@member/peopleSearch/components/mini/share-report/share-report.component';
import { MiniReportNotesComponentModule } from '@member/peopleSearch/components/mini/notes/notes.component';
import { AdComponentModule } from 'app/components/ad/ad.component';
import { MiniReportAdProtectionComponentModule } from '@member/peopleSearch/components/mini/ad/ad-protection.component';
import { UxTemplateModule } from 'app/components/uxTemplate.component';
import { MiniLinearBaseComponent } from './miniLinearBase.component';
import { FactFindersComponentModule } from '../factFinders/factFinders.component';

declare var $: any;

@Component({
  selector: 'app-mini-linear',
  templateUrl: 'miniLinear.component.html',
  styleUrls: ['miniLinear.component.scss'],
  standalone: false
})
export class MiniLinearComponent extends MiniLinearBaseComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

  constructor(
    public serviceHelperService: ServiceHelperService,
    public changeDetectorRef: ChangeDetectorRef,
    activatedRoute: ActivatedRoute,
    public renderer: Renderer2,
    protected router: Router,
    public toastr: ToastrService,
    public noteService: NoteService,
    public authService: AuthenticationService
  ) {
    super(serviceHelperService, changeDetectorRef, activatedRoute, renderer, router, toastr, noteService, authService);
  }

  ngOnInit() {
    return super.ngOnInit();
  }

  ngOnDestroy() {
    return super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }
}

@NgModule({
  declarations: [MiniLinearComponent],
  imports: [
    CommonModule,
    RouterModule,
    FactFindersComponentModule,
    MiniReportHeaderModule,
    MemberUpsellGlobalComponentModule,
    MiniReportFooterModule,
    TutorialModalComponentModule,
    MiniReportDwbBreachesComponentModule,
    MiniReportShareReportComponentModule,
    MiniReportNotesComponentModule,
    AdComponentModule,
    MiniReportAdProtectionComponentModule,
    UxTemplateModule, 
  ],
  exports: [MiniLinearComponent],
})
export class MiniLinearComponentModule {}
