import {
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  EventEmitter,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ServiceHelperService } from "../../../../../../clientCommon/services/serviceHelper.service";
import { PersonDetail } from "../../../personDetail";
import {ToastrService} from "../../../../../service/toastr.service";
import {AuthenticationService, NoteService} from "../../../../../../clientCommon/services";

declare var $: any;

@Component({
    selector: "app-mini-reverse-phone",
    templateUrl: "miniReversePhone.component.html",
    styleUrls: ["miniReversePhone.component.scss"],
    standalone: false
})


export class MiniReversePhoneComponent
  extends PersonDetail
  implements OnInit, OnDestroy {
  href = "";
  showAllEmail = false;

  @Output() goPrev = new EventEmitter<any>();
  @Output() goNext = new EventEmitter<any>();

  constructor(
    public serviceHelperService: ServiceHelperService,
    public changeDetectorRef: ChangeDetectorRef,
    activatedRoute: ActivatedRoute,
    private router: Router,
    public toastr: ToastrService,
    public noteService: NoteService,
    public authService: AuthenticationService
  ) {
    super(serviceHelperService, activatedRoute, toastr, noteService, authService);
  }

  ngOnInit() {

    this.href = this.router.url;
    return this.init().then(() => {
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy() {
    return super.onDestroy();
  }


  getEmails() {
    return this.person.emailAddresses.map((item) => item.address);
  }

  handlePrevious() {
    window.scrollTo(0, 0);
    this.goPrev.emit();
  }

  handleNext() {
    window.scrollTo(0, 0);
    this.goNext.emit();
  }

}
