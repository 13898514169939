import {BaseComponent} from "../../../clientCommon/components/BaseComponent";
import {CommerceOrder} from "../../../common/models/commerce/commerceOrder";
import {ServiceHelperService} from "../../../clientCommon/services/serviceHelper.service";
import {ActivatedRoute} from "@angular/router";
import {ProcessPeopleSearch} from "../../peopleSearch/processPeopleSearch";
import {LogUtils} from "../../../common/utils/logUtils";
import {redirectHelper} from "../../../clientCommon/helper/redirectHelper";
import {CommerceContent} from "../../../common/models/commerce/commerceContent";
import {commerceUtils} from "../../../common/utils/commerceUtils";
import {customClientPaths} from "../../../common/custom/customPathHelpers";

export class ProcessPeopleSearchMember extends ProcessPeopleSearch {

  hasAccess;
  contentProductId: string;
  commerceOrders: CommerceOrder[] = [];

  constructor(serviceHelperService: ServiceHelperService,
              activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
    this.pageType = BaseComponent.PAGE_TYPE.member;
  }

  onInit() {
    return super.onInit().then(() => {
      return this.checkOrder();
    }).then(() => {
      if (this.hasAccess) {
        return this.submit();
      } else {
        LogUtils.error("no access");
        return this.redirectOnError();
      }
    }).catch((e) => {
      LogUtils.error(e);
      return this.redirectOnError();
    });
  }

  onDestroy() {
    return super.onDestroy();
  }

  checkOrder(): Promise<any> {
    return this.getCommerceOrdersByPageCategory().then((commerceOrders: CommerceOrder[]) => {
      LogUtils.debug("checkOrder", commerceOrders);
      this.commerceOrders = commerceOrders;
      if (commerceUtils.canProcessSomeCommerceOrders(commerceOrders,this.getProductKeyByPageCategory())) {
        this.hasAccess = true;
      } else {
        return Promise.reject(false);
      }
    }).catch((e) => {
      LogUtils.error(e, "has no order for this page");
    });
  }

  submit() {
    if (this.selectedPerson && this.selectedPerson.extId) {
      let content = this.serviceHelperService.peopleSearchService.getContentInfoBySelectedObject(this.selectedPerson, this.getProductKeyByPageCategory(), this.commerceContent ? this.commerceContent.content : null);
      return this.createCommerceContent(content).then((result) => {
        if (result && result[1] instanceof CommerceContent) {
          return this.finishDuration(result[1]);
        } else {
          LogUtils.error(false);
        }
      });
    } else {
      LogUtils.error(false);
    }

    return this.redirectOnError(); // Error case
  }

  createCommerceContent(content): Promise<any> {
    let contentInfos = [{
      productId: this.contentProductId,
      productKey: this.getProductKeyByPageCategory(),
      data: {userInput: this.userInput, content: content},
    }];

    this.serviceHelperService.peopleSearchService.clear();
    this.executeProgress();
    return this.serviceHelperService.peopleSearchService.createContent(this.commerceOrders[0]._id, contentInfos);
  }

  redirectOnError() {
    return redirectHelper.redirect(this.serviceHelperService, '/' + customClientPaths.memberNameSearchLanding);
  }

}
