import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';

@Component({
  selector: 'ux-5-2-icon-label',
  templateUrl: './icon-label.component.html',
  styleUrls: ['./icon-label.component.scss']
})
export class IconLabelComponent {
  @Input() icon: string = null;
  @Input() label: string = null;
}

// @NgModule({
//   declarations: [IconLabelComponent],
//   imports: [CommonModule],
//   exports: [IconLabelComponent],
// })
// export class IconLabelComponentModule {}
