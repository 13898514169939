export var customClientPaths = {
  // todo: add this path in proper category
  memberNameSearchRedirectWarning: 'member/nameSearch/redirect-warning',

  // PeopleSearch Member
  memberPeopleSearchUpdate: 'member/peopleSearch/update',

  // NameSearch Sales
  salesNameSearchLanding: 'name/landing',
  salesNameSearchLoader: 'name/loader',
  salesNameSearchReportReview: 'name/report-review',
  salesNameSearchSearchResult: 'name/search-result',
  salesNameSearchSignup: 'name/signup',

  // NameSearch Member
  memberNameSearchLanding: 'member/nameSearch/landing',
  memberNameSearchLoader: 'member/nameSearch/loader',
  memberVinLookup: 'member/nameSearch/vinLookup',
  memberNameSearchReportReview: 'member/nameSearch/reportReview',
  memberNameSearchSearchResult: 'member/nameSearch/searchResult',
  memberNameSearchProcess: 'member/nameSearch/process',
  memberNameSearchDetail: 'member/nameSearch/detail',
  memberInmateState: "member/inmate/state",

  // Affiliate Page
  affiliateLanding: 'become-an-affiliate',
  affiliateLandingForm: 'become-an-affiliate-form',

  // PhoneSearch Sales
  salesPhoneSearchLanding: 'phone/landing',
  salesPhoneSearchLoader: 'phone/loader',
  salesPhoneSearchReportReview: 'phone/report-review',
  salesPhoneSearchSearchResult: 'phone/search-result',
  salesPhoneSearchSignup: 'phone/signup',

  // PhoneSearch Member
  memberPhoneSearchLanding: 'member/phoneSearch/landing',
  memberPhoneSearchLoader: 'member/phoneSearch/loader',
  memberPhoneSearchReportReview: 'member/phoneSearch/report-review',
  memberPhoneSearchSearchResult: 'member/phoneSearch/search-result',
  memberPhoneSearchProcess: 'member/phoneSearch/process',
  memberPhoneSearchDetail: 'member/phoneSearch/detail',

  // NameSearch remove my info Member
  memberRemoveInfoPayment: 'member/remove-info/payment',
  memberRemoveInfoLanding: 'member/remove-info/landing',
  memberRemoveInfoLoader: 'member/remove-info/loader',
  memberRemoveInfoSearchResult: 'member/remove-info/search-result',
  memberRemoveInfoSignup: 'member/remove-info/signup',
  memberRemoveInfoDashboard: 'member/remove-info/dashboard',

  // NameSearch OptOut Sales
  salesNameSearchOptOutLanding: 'optOut/name/landing',
  salesNameSearchOptOutLoader: 'optOut/name/loader',
  salesNameSearchOptOutSearchResult: 'optOut/name/search-result',
  salesNameSearchOptOutInfoInput: 'optOut/name/info-input',

  // Review Site
  salesReviewPeopleSearchList: 'review/peopleSearch/list',
  salesReviewPeopleSearchDetail: 'review/peopleSearch/detail',

  // Property Search
  property: 'property',

  // Referral Link
  referral: 'referral',

  // share link
  shareLink: 'share/mini-report',

  // Life Events
  lifeEvents: 'member/life-events'
};

export var contentPeopleSearchPaths = {
  peopleSearch: "peopleSearch",
  subscribe: "subscribe",
  criminalPhoto: "criminalPhoto",
};

export var contentSmsSenderPaths = {
  subscribe: "subscribe",
  unsubscribe: "unsubscribe"
}

export var contentRemoveInfoPaths = {
  segment: "segment",
  request: "request",
  find: "find",
  alert: "alert",
};

// Router Path
export var customServerRouterPaths = {
  contentPeopleSearch: "api/content/peopleSearch",
  progressor: "api/helper/progressor",
  smsSender: "api/content/smsSender",
  removeInfo: "api/content/removeInfo",
};

export var customServerPaths = {
  //ContentPeopleSearchPaths
  contentPeopleSearchPeopleSearch: customServerRouterPaths.contentPeopleSearch + "/" + contentPeopleSearchPaths.peopleSearch,
  contentPeopleSearchSubscribe: customServerRouterPaths.contentPeopleSearch + "/" + contentPeopleSearchPaths.subscribe,
  contentPeopleSearchCriminalPhoto: customServerRouterPaths.contentPeopleSearch + "/" + contentPeopleSearchPaths.criminalPhoto,
  
  contentSmsSenderSubscribe: customServerRouterPaths.smsSender + "/" + contentSmsSenderPaths.subscribe,
  contentSmsSenderUnSubscribe: customServerRouterPaths.smsSender + "/" + contentSmsSenderPaths.unsubscribe,

  contentRemoveInfoSegment: customServerRouterPaths.removeInfo + '/' + contentRemoveInfoPaths.segment,
  contentRemoveInfoRequest: customServerRouterPaths.removeInfo + '/' + contentRemoveInfoPaths.request,
  contentRemoveInfoFind: customServerRouterPaths.removeInfo + '/' + contentRemoveInfoPaths.find,
  contentRemoveInfoAlert: customServerRouterPaths.removeInfo + '/' + contentRemoveInfoPaths.alert,
};

export var customMemberMiniSteps = {
  linear: 'linear',
  socialMedia: 'socialMedia',
  family: 'family',
  lifeEvents: 'lifeEvents',
  properties: 'properties',
  careerFinancial: 'careerFinancial',
  criminalRecords: 'criminalRecords',
  miscellaneous: 'miscellaneous',
};
 
export const customDoubleServePaths = {
  wi: {
    "/commerce/action/load/offer": "/ds/wi/commerce/action/load/offer_page",
    "/commerce/action/preSaleTrackingPixel": "/ds/wi/commerce/action/pre_Sale_Tracking_Pixel",
    "/content/peopleSearch/peopleSearch": "/ds/wi/content/peopleSearch/people_Search",
    "/content/smsSender/subscribe": "/ds/wi/content/smsSender/subscribe_page",
    "/helper/crud/push/input": "/ds/wi/helper/crud/pushadd/input",
    "/helper/recaptcha/verify": "/ds/wi/helper/recaptcha/verify_page",
    "/helper/tracking/pixelFrame": "/ds/wi/helper/trk/p_F",
    "/helper/tracking/rec_null": "/ds/wi/helper/trk/r_n",
    "/helper/tracking/rec": "/ds/wi/helper/trk/r",
    "/mail/add": "/ds/wi/mail/addpush",
    "/mail/check": "/ds/wi/mail/checkcorrect",
    "/uxc/find/composite": "/ds/wi/uxc/find/uxcomposite",
  },
  ims: {
    "/commerce/action/load/offer": "/ts/commerce/action/load/offer_page",
    "/commerce/action/preSaleTrackingPixel": "/ts/commerce/action/pre_Sale_Tracking_Pixel",
    "/content/peopleSearch/peopleSearch": "/ts/content/peopleSearch/people_Search",
    "/content/smsSender/subscribe": "/ts/content/smsSender/subscribe_page",
    "/helper/crud/push/input": "/ts/helper/crud/pushadd/input",
    "/helper/recaptcha/verify": "/ts/helper/recaptcha/verify_page",
    "/helper/tracking/pixelFrame": "/ts/helper/trk/p_F",
    "/helper/tracking/rec_null": "/ts/helper/trk/r_n",
    "/helper/tracking/rec": "/ts/helper/trk/r",
    "/mail/add": "/ts/mail/addpush",
    "/mail/check": "/ts/mail/checkcorrect",
    "/uxc/find/composite": "/ts/uxc/find/uxcomposite",
  },
};
