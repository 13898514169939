import {BaseComponent} from "../../clientCommon/components/BaseComponent";
import {ServiceHelperService} from "../../clientCommon/services/serviceHelper.service";
import {ActivatedRoute} from "@angular/router";
import {CommerceContent} from "../../common/models/commerce/commerceContent";
import {LogUtils} from "../../common/utils/logUtils";
import {redirectHelper} from "../../clientCommon/helper/redirectHelper";
import {customClientPaths} from "../../common/custom/customPathHelpers";
import {clientPaths} from "../../common/helpers/pathHelpers";

export class UpdatePeopleSearch extends BaseComponent {

  commerceContentId;
  commerceContent: CommerceContent;

  constructor(serviceHelperService: ServiceHelperService, activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
    this.page = BaseComponent.PAGE.detail;
  }

  onInit(): Promise<any> {
    return this.baseInit().then(() => {
      this.activatedRoute.params.subscribe((params) => {
        let id = params.id;
        if (id) {
          this.commerceContentId = id;
          return this.init();
        }
      });
    });
  }

  init() {
    this.serviceHelperService.spinnerService.spin();
    this.serviceHelperService.commerceService.processUpdateContent(this.commerceContentId).then((commerceContent: CommerceContent) => {
      this.commerceContent = commerceContent;
      LogUtils.debug("UpdatePeopleSearch", commerceContent);
      redirectHelper.redirect(this.serviceHelperService, '/' + customClientPaths.memberNameSearchDetail + "/" + this.commerceContentId);
    }).catch((e) => {
      redirectHelper.redirect(this.serviceHelperService, '/' + clientPaths.memberAccountOrders);
      LogUtils.error(e);
    }).then(() => {
      this.serviceHelperService.spinnerService.unspin();
    });
  }

  onDestroy() {
    return super.onDestroy();
  }
}
