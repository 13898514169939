export class CommerceAddress {
  public static TYPE = {
    billing: "billing",
    shipping: "shipping",
  };

  bogusFields: {
    title?: boolean,
    firstName?: boolean,
    lastName?: boolean,
    middleName?: boolean,
    company?: boolean,
    street1?: boolean,
    street2?: boolean,
    street3?: boolean,
    city?: boolean,
    zip?: boolean,
    state?: boolean,
    country?: boolean,
    phone?: boolean,
    email?: boolean,
    addressType?: boolean,
    residential?: boolean,
  } = {};

  title: string;
  firstName: string;
  lastName: string;
  middleName: string;
  company: string;
  street1: string;
  street2: string;
  street3: string;
  city: string;
  zip: string;
  state: string;
  country: string;
  phone: string;
  email: string;
  addressType: string;
  residential: boolean
}

