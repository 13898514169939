import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'ux-5-2-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent {
  @Input() value: string | number = null;
  @Input() defaultValue: string | number = 0;
  @Input() options: Array<any> = [];
  @Input() valueKey: string;
  @Input() displayKey: string;
  @Input() disableDefaultOption = true;
  @Input() showDefaultOption = true;
  @Output() valueChange = new EventEmitter();
  @Output() focus = new EventEmitter();
  @Output() blur = new EventEmitter();

  notify() {
    this.valueChange.emit({
      target: {
        value: this.value,
      }
    });
  }
}

// @NgModule({
//   declarations: [SelectComponent],
//   imports: [CommonModule, FormsModule],
//   exports: [SelectComponent],
// })
// export class SelectComponentModule {}