import { Subject, BehaviorSubject} from 'rxjs';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class ActionRecommenderService {

  baseTemplateValue = "";
  baseTemplate = new Subject();
  canSave= new BehaviorSubject(false);
  customerType= new BehaviorSubject('I');
  validEmail= new BehaviorSubject(false);
  
  constructor() { }

  setCanSave(value){
 this.canSave.next(value);
  }

  getCanSave() {
    return this.canSave.asObservable();
  }

  setvalidEmail(value){
     this.validEmail.next(value);
      }
    
      getvalidEmail() {
        return this.validEmail.asObservable();
      }

  setCustomerType(value){
     this.customerType.next(value);
      }
    
      getCustomerType() {
        return this.customerType.asObservable();
      }

  setBaseTemplate(template) {
    this.baseTemplateValue = template;
    this.baseTemplate.next(template);
  }

  getBaseTemplate() {
    return this.baseTemplateValue;
  }
}
