import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class LimitStorageService {
    isSearchLimited = new BehaviorSubject(this.searchLimited);

    set searchLimited(value: string) {
        this.isSearchLimited.next(value);
        localStorage.setItem('isSearchLimited', value);
    }

    get searchLimited() {
        return localStorage.getItem('isSearchLimited') || 'false';
    }

}