import { peopleSearchProductKeys } from "../custom/peopleSearch/peopleSearchProductKeys";
import { CommerceOrder } from "../models/commerce/commerceOrder";
import { CommercePrice } from "../models/commerce/commercePrice";
import { CommerceStockDetail } from "../models/commerce/commerceStock";
import { UxComposite } from "../models/ux/uxComposite";
import { LogUtils } from "./logUtils";

type PeriodUnitKeys = keyof typeof CommercePrice.UNIT_CODE;

type PeriodUnitType = typeof CommercePrice.UNIT_CODE[PeriodUnitKeys];

export class FactFinderUtils {
  // 60 Min
  static readonly defaultLockTime: number = 1000 * 60 * 60;

  static readonly productContentType = peopleSearchProductKeys.factFinder;

  static readonly productInfix = 'factFinders';

  static readonly lockTimeCompKey: string = `comp.member.${FactFinderUtils.productInfix}.lockTime`;

  static readonly productCompKey: string = `comp.member.${FactFinderUtils.productInfix}.product.ids`;

  /**
   * Returns the time when the user's FF request changes to the locked state.
   * 
   * @param uxComposite 
   * @returns lockTime is Milliseconds
   */
  static getLockTimeFromUxComposite(uxComposite: UxComposite): number {
    if (!uxComposite) {
      return FactFinderUtils.defaultLockTime;
    }

    const lockTime = uxComposite.get(FactFinderUtils.lockTimeCompKey);

    if (!lockTime && lockTime !== 0) {
      return FactFinderUtils.defaultLockTime;
    }

    return lockTime <= 0 ? 0 : lockTime;
  }

  /**
   * Returns lockTime in minutes. If it is less than 1 minute, it returns 0.
   * 
   * @param uxComposite 
   * @returns lockTime is Minute
   */
  static getLockTimeMinuteFromUxComposite(uxComposite: UxComposite): number {
    const lockTime = FactFinderUtils.getLockTimeFromUxComposite(uxComposite);

    const minute = Math.floor(lockTime / 60000);

    return minute < 1 ? 0 : minute;
  }

  static async getConfigFromCommerceOrder(
      uxComposite: UxComposite,
      commerceOrders: CommerceOrder[],
  ): Promise<FactFinderConfig> {
    try {
      if (!uxComposite) {
        throw new Error('FactFinderUtils.getConfigFromCommerceOrder No UxComposite');
      }

      if (!commerceOrders?.length) {
        throw new Error('FactFinderUtils.getConfigFromCommerceOrder No commerceOrders');
      }

      const productId = uxComposite.get(FactFinderUtils.productCompKey)?.[0];

      if (!productId) {
        throw new Error('FactFinderUtils.getConfigFromCommerceOrder No productId in UxComposite');
      }

      let { 
        orderId, 
        stockDetail, 
        userCredit, 
      } = FactFinderUtils.findProduct(commerceOrders, productId);

      if (!stockDetail) {
        throw new Error('FactFinderUtils.getConfigFromUxComposite Not Found stockDetail');
      }

      return new FactFinderConfig({
        orderId: orderId,
        credit: stockDetail?.quantity,
        userCredit: userCredit,
        periodUnit: stockDetail?.period?.unit,
        periodQuentity: stockDetail?.period?.quantity,
      });
    } catch (e) {
      return new FactFinderConfig({
        orderId: '',
        credit: 1,
        userCredit: 0,
        periodUnit: 'd',
        periodQuentity: 30,
      });
    }
  }

  private static findProduct(
      commerceOrders: CommerceOrder[], 
      productId: string,
  ): { 
      orderId: string, 
      stockDetail: CommerceStockDetail,
      userCredit: number, 
  } | null {
    if (!commerceOrders?.length || !productId) {
      return null;
    }

    for (let i = 0, length = commerceOrders.length; i < length; i += 1) {
      const innerLength = commerceOrders[i]
        ?.commerceOfferDetail
        ?.commerceProductDetails
        ?.length;

      if (!innerLength) {
        continue;
      }

      for (let j = 0; j < innerLength; j += 1) {
        const product = commerceOrders[i].commerceOfferDetail.commerceProductDetails[j];

        if (product.commerceProductId === productId) {
          const { quantity, process } = commerceOrders[i].tempClientSecured
            ?.processes
            ?.[peopleSearchProductKeys.factFinder] ?? { quantity: 0, process: 0 };

          return {
            orderId: commerceOrders[i]._id,
            stockDetail: product.commerceStockDetails?.[0],
            userCredit: quantity - process,
          };
        }
      }
    }

    return null;
  }
}

export class FactFinderConfig {
  constructor(config: {
    orderId: string,
    credit: number,
    userCredit: number,
    periodUnit: PeriodUnitType,
    periodQuentity: number,
  }) {
    this.orderId = config?.orderId ?? '';
    this.credit = config?.credit ?? 1;
    this.userCredit = config?.userCredit ?? 0;
    this.periodUnit = config?.periodUnit ?? CommercePrice.UNIT_CODE.d;
    this.periodQuantity = config?.periodQuentity ?? this.getDefaultPeriodQuantity();
  }

  readonly orderId: string;

  readonly credit: number;

  readonly userCredit: number;

  readonly periodUnit: PeriodUnitType;

  readonly periodQuantity: number;

  isValid() {
    return !!this.orderId;
  }

  getPeriodUnitString() {
    let result;

    switch (this.periodUnit) {
      case 'd':
        result = 'day';
        break;
      case 'w':
        result = 'week';
        break;
      case 'm':
        result = 'month';
        break;
      case 'y':
        result = 'year';
        break;
      default:
        LogUtils.error(`FactFinderOffer.getPeriodUnitString Not implements periodUnit (${this.periodUnit})`);
        result = 'day';
        break;
    }

    return this.periodQuantity > 1 ? `${result}s` : result;
  }

  getMilliseconds(): number {
    const dayNum = 1000 * 60 * 60 * 24;

    let unitNum: number;

    switch (this.periodUnit) {
      case 'd':
        unitNum = dayNum;
        break;
      case 'w':
        unitNum = dayNum * 7;
        break;
      case 'm':
        unitNum = dayNum * 30;
        break;
      case 'y':
        unitNum = dayNum * 365;
        break;
      default:
        LogUtils.error(`FactFinderOffer.getMilliseconds Not implements periodUnit (${this.periodUnit})`);
        unitNum = dayNum;
        break;
    }

    return this.periodQuantity * unitNum;
  }

  copyWith(config: {
    credit?: number,
    userCredit?: number,
    periodUnit?: PeriodUnitType,
    periodQuentity?: number,
  }): FactFinderConfig {
    return new FactFinderConfig({
      orderId: this.orderId,
      credit: config?.credit ?? this.credit,
      userCredit: config?.userCredit ?? this.userCredit,
      periodUnit: config?.periodUnit ?? this.periodUnit,
      periodQuentity: config?.periodQuentity ?? this.periodQuantity,
    });
  }

  private getDefaultPeriodQuantity(): number {
    switch (this.periodUnit) {
      case 'd':
        return 30;
      case 'w':
        return 4;
      case 'm':
        return 1;
      case 'y':
        return 1;
      default:
        LogUtils.error(`FactFinderOffer.getDefaultPeriodQuantity Not implements periodUnit (${this.periodUnit})`);
        return 30;
    }
  }
}
