import {isArray} from "util";
import {ArrayUtils} from "./arrayUtils";

class ModelUtils {

  public getFieldRecursive(objects, fieldName) {
    let values = [];
    if (!isArray(objects)) {
      objects = [objects];
    }

    objects.forEach((object) => {
      if (object) {
        if (object.getValuesRecursive) {
          values.push(object.getValuesRecursive(fieldName));
        } else if (object[fieldName]) {
          values.push(object[fieldName]);
        }
      }
    });

    return ArrayUtils.flatten(values);
  }

}

export var modelUtils = new ModelUtils();

