import {isNumber} from "util";

class MapUtils {

  osmLong2tile(lon, zoom) {
    if(!isNumber(lon)) {
      lon = parseFloat(lon);
    }
    return (Math.floor((lon + 180) / 360 * Math.pow(2, zoom)));
  }

  osmLat2tile(lat, zoom) {
    if(!isNumber(lat)) {
      lat = parseFloat(lat);
    }
    return (Math.floor((1 - Math.log(Math.tan(lat * Math.PI / 180) + 1 / Math.cos(lat * Math.PI / 180)) / Math.PI) / 2 * Math.pow(2, zoom)));
  }


  osmTile2long(x, z) {
    return (x / Math.pow(2, z) * 360 - 180);
  }

  osmTile2lat(y, z) {
    var n = Math.PI - 2 * Math.PI * y / Math.pow(2, z);
    return (180 / Math.PI * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n))));
  }

  osmToUrl(latitude, longitude, zoom) {
    return `//a.tile.openstreetmap.org/${zoom}/${longitude}/${latitude}.png`;
  }
}

export const mapUtils = new MapUtils();
