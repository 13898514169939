import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { LandingNameSearchMemberComponent } from '../../member/peopleSearch/nameSearch/landing/landing.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-thin-match-welcome-modal',
  templateUrl: './thin-match-welcome-modal.component.html',
  styleUrls: ['./thin-match-welcome-modal.component.scss'],
})
export class ThinMatchWelcomeModalComponent implements OnInit {
  @Output() updateAccountEvent = new EventEmitter<string>();

  @Input() base: LandingNameSearchMemberComponent;
  constructor() {}

  ngOnInit(): void {
    document.getElementById('openThinMatchWelcomeModal').click();
  }

  trialExpireDate(): string {
    const monthNamesShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    try {
      const trialStartDate = new Date();
      // const trialStartDateValue =  this.base?.uxComposite?.values?.code?.db?.comp?.sales?.["name-search"]?.signup?.main?.[1]?.offer?.tempClientSecured?.billingDates?.[0]
      //      || this.base?.uxComposite?.values?.code?.db?.comp?.sales?.["phone-search"]?.signup?.main?.[1]?.offer?.tempClientSecured?.billingDates?.[0]
      //      || this.base?.uxComposite?.values?.code?.offers?.main?.[0].tempClientSecured.billingDates?.[0];
      // const trialStartDate = new Date(trialStartDateValue);
      trialStartDate.setDate(trialStartDate.getDate() + 14);
      // Dec. 16, 2022
      return `${
        monthNamesShort[trialStartDate.getMonth()]
      }. ${trialStartDate.getDate()}, ${trialStartDate.getFullYear()}`;
    } catch {
      const today = new Date();
      today.setDate(today.getDate() + 14);
      return `${monthNamesShort[today.getMonth()]}. ${today.getDate()}, ${today.getFullYear()}`;
    }
  }

  updateAccountInfo(): void {
    this.updateAccountEvent.next('updateAccount');
  }
}

@NgModule({
  declarations: [ThinMatchWelcomeModalComponent],
  imports: [CommonModule],
  exports: [ThinMatchWelcomeModalComponent],
})
export class ThinMatchWelcomeModalComponentModule {}
