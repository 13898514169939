import {
  ChangeDetectorRef,
  EventEmitter,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  NgModule,
  AfterViewInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from '../../../../service/toastr.service';
import { NoteService, AuthenticationService } from '../../../../../clientCommon/services';
import { ServiceHelperService } from '../../../../../clientCommon/services/serviceHelper.service';
import { PersonDetail } from '../../personDetail';
import { SearchedPerson } from '../../../../../common/custom/models/peopleSearch/searchedPerson';
import { Resource } from '../../../../../../src/common/models/resource';
import { LogUtils } from '../../../../../../src/common/utils/logUtils';
import { BaseComponent } from '../../../../../../src/clientCommon/components/BaseComponent';
import { peopleSearchProductKeys } from '../../../../../../src/common/custom/peopleSearch/peopleSearchProductKeys';
import { CensusDataService } from '../../../../../clientCommon/services/censusData.service';
import { AddressField, AddressUtils } from '../../../../../../src/common/utils/addressUtils';
import { phoneUtils } from '../../../../../../src/common/utils/phoneUtils';
import { environment } from '../../../../../../src/environments/environment';
import { CommerceContent } from '../../../../../../src/common/models/commerce/commerceContent';
import { SpyIdHelper } from '../../../../../clientCommon/helper/spyIdHelper';
import { CommonModule } from '@angular/common';
import { MemberUpsellPhoneComponentModule } from '@member/components/upsells/phone';
import { MiniButtonComponentModule, MiniCensusAccordionComponentModule, MiniPropertyComponentModule, MiniReportFooterModule } from '@member/peopleSearch/components/mini';
import { PropertyMapComponentModule, PropertyMapMarker } from '@member/peopleSearch/components/map.component';
import { FormsModule } from '@angular/forms';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ReportActionComponentModule } from '@member/peopleSearch/nameSearch/detail/reportAction/reportAction.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MemberDisclaimerComponentModule } from '@member/components/memberDisclaimer.component';
import { MemberFooterComponentModule } from '@member/components/memberFooter.component';
import  moment from "moment";
import { SexOffenderComponentModule } from '@member/peopleSearch/components/sexOffender.component';
import { MiniReportAdProtectionComponentModule } from '@member/peopleSearch/components/mini/ad/ad-protection.component';
import { clientPaths } from "../../../../../../src/common/helpers/pathHelpers";
import 'moment-timezone';
import { MemberKeepSearchComponentModule } from '@member/components/peopleSearch/memberKeepSearch.component';


// Bug on variable
// this.person?.tempClient?.selectedPhoneNumber;
// Its null whenever we open a saved report.
// Need to find another way to consistently keep track on the referenced phoneNumber.
declare var $: any;
class CustomProduct {
  public static ACTION = {
    redirect: 'redirect',
  };

  productType: string;
  name: string;
  action: string;
  value: string;
  forms: {
    [type: string]: any;
  }[] = [];
  objectKeys = Object.keys;

  public static CODE = {
    requestState: 'requestState',
  };
}
@Component({
  selector: 'app-mini-linear-phone-search-v2',
  templateUrl: './mini-linear-phone-search-v2.component.html',
  styleUrls: ['./mini-linear-phone-search-v2.component.scss'],
})
export class MiniLinearPhoneSearchV2Component extends PersonDetail implements OnInit, OnChanges, AfterViewInit {
  enhancedflag = false;
  bonusflag = false;

  private = false;
  invalidNote = false;
  noteContent = '';
  @Input() isPrivate: boolean = true;
  phoneUtils = phoneUtils;
  addressField: AddressField = AddressUtils.getAddressField('US');
  customerNumber = '';
  @Output() updateNotes = new EventEmitter<any>();

  stateList = this.addressField;
  ageList = [];

  relativesList = [];
  assosiatesList = [];

  customProducts: CustomProduct[] = [];
  selectedCustomProductValues: any = {};
  productType: string;
  selectedPhotos = [];
  @Input() commerceContent: CommerceContent;
  swiperIndex = 0;
  showAllRelatives = false;
  @Input() searchedPersons: SearchedPerson[] = [];
  @Output() showLoader = new EventEmitter<void>();
  searchPersonsFiltered: SearchedPerson[] = [];
  currentIndex: number = 0;
  @Input() isPdfPrintView: boolean = false;
  loaderEyeTimeout: number = environment.miniDetailsLoaderEyeTimeout;
  @Input() externalId: string = '';
  @Output() noteModified = new EventEmitter<any>();

  censusInfo: any = {};

  stateCensusInfo = {};

  phoneNumber: string;

  @ViewChild('scrollToTop', { static: true })
  scrollToTopBtnElement: ElementRef;
  lastZip;
  zoomLevel = 6;
  propertiesList: PropertyMapMarker[] = [];
  propertyArr: Array<any> = [];
  breaches = [];
  alerts = [];
  alertsView: { idx: string, id: string, is_view: boolean, img_src: string, alarm: any }[] = [];
  objectKeys = Object.keys;
  idProtectionEnabled: boolean = false;
  idOfferClaimed: boolean = false;
  spyIdHelper = new SpyIdHelper();
  formatDob;

  showAllProperties = false;

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    public serviceHelperService: ServiceHelperService,
    activatedRoute: ActivatedRoute,
    public toastr: ToastrService,
    public noteService: NoteService,
    public authService: AuthenticationService,
    public renderer: Renderer2,
    private censusService: CensusDataService,
    private router: Router
  ) {
    super(serviceHelperService, activatedRoute, toastr, noteService, authService);
  }

  cancelModal(index) {
    $('#modal-' + index).modal('hide');
  }

  ngOnInit() {
    this.phoneNumber = this.searchedPersons[this.searchedPersons.length - 1]?.tempClient?.selectedPhoneNumber;
    
    const upsellphoneflag = this.uxHelper.uxComposite.get('comp.billing.enhanced.phone.enabled');
    
    this.bonusflag = upsellphoneflag.bonus;
    
    this.enhancedflag = upsellphoneflag.enhanced;
    
    if (this.person.tempClient?.upsellEnabled) {
      this.upsellEnabled = true;
    }
    
    this.serviceHelperService.trackingService.report({
      page: BaseComponent.PAGE.detail,
      pageType: BaseComponent.PAGE_TYPE.member,
      type: peopleSearchProductKeys.phoneSearch,
      section: 'linear',
      action: 'view',
    });
    
    this.serviceHelperService.trackingService.report({
      section: 'phone',
      action: 'view',
      type: 'report',
    });
    
    // this.onReload.emit(); // Dev#1471 unnecessary call?

    this.initialize();

    return this.init();
  }

  ngAfterViewInit() {
    // Register scroll to top event listener.
    const screenHeight = window.screen.availHeight;
    window.onscroll = () => {
      if (document.body.scrollTop > screenHeight || document.documentElement.scrollTop > screenHeight) {
        this.renderer.setStyle(this.scrollToTopBtnElement.nativeElement, 'display', 'flex');
      } else {
        this.renderer.setStyle(this.scrollToTopBtnElement.nativeElement, 'display', 'none');
      }
    };
  }

  // Initialize component.
  initialize() {
    this.currentIndex = parseInt(Object.keys(this.searchedPersons)[0]);
    this.showAllRelatives = this.isPdfPrintView;
    this.relativesList = [...this.searchedPersonByIndex(this.currentIndex).relatives];
    this.assosiatesList = [...this.searchedPersonByIndex(this.currentIndex).associates];

    if (this.getzip(this.currentIndex)) {
      this.lastZip = this.getzip(this.currentIndex);
    }
  }

  PROVIDER_TYPE = {
    dwbEmailMonitoringGetFullResults: 'DwbEmailMonitoringGetFullResults',
    dwbEmailMonitoringGetAlerts: 'DwbEmailMonitoringGetAlerts',
  };

  showAllBreaches = false;

  toggleShowAllBreaches() {
    this.showAllBreaches = !this.showAllBreaches;
  }

  getDwbBreaches() {
    const spyDwbCommerceContent: CommerceContent = this.commerceContent?.tempClient?.spyDwbCommerceContent;
    const raws = spyDwbCommerceContent?.raws ?? [];
    this.breaches = [];
    for (const raw of raws) {
      if (raw.meta.type == this.PROVIDER_TYPE.dwbEmailMonitoringGetFullResults) {
        if (raw?.data?.contentInfo?.body?.result?.length > 0) {
          this.breaches = [...this.breaches, ...raw?.data?.contentInfo?.body?.result];
        }
      }
    }

    const alertRaws = raws.find(
      (raw) => raw.meta.type == this.PROVIDER_TYPE.dwbEmailMonitoringGetAlerts
    );

    //this.alerts = alertRaws?.data?.alertsData || [];
    if (alertRaws) {
      for (const alert of alertRaws?.data?.alertsData) {
        let data = { 
          id: spyDwbCommerceContent._id,
          alert_year: alert.year_of_breach,
          alert_date_of_alert: alert?.alert_created_date,
          alert_email: alert.breached_email,
          alert_breach: alert.breach,
          alert_type: alert.breached_data_classes, // 'email',
          // alert_subject: alert.breached_email,
          // alert_date: Math.round(new Date(alert.alert_created_date).getTime()/1000),
          // alert_status: alert.alert_status,
          alert_text: alert.breach_description,
          alert_id: alert.alert_access_key
        };
        this.alerts.push(data);
      }
    } 

    //this.breaches = [...this.alerts, ...this.breaches];

    
    this.alertsView = [];
    for(const alert of this.alerts) {
      this.alertsView.push(
          {
            idx: alert.alert_id, id:spyDwbCommerceContent?._id, is_view: false, img_src: '/assets/spyandid/bottom_arrow.svg', alarm: alert
          }
      );      
    };  

    for(const alert of this.breaches) {
      let breachAlart = {
        id: spyDwbCommerceContent._id,
        alert_year: alert.year_of_breach,
        alert_date_of_alert: alert?.alert_created_date,
        alert_email: alert.breached_email,
        alert_breach: alert.breach,
        alert_type: alert.breached_data_classes, // 'email',
        // alert_subject: alert.breached_email,
        // alert_date: Math.round(new Date(alert.alert_created_date).getTime()/1000),
        // alert_status: alert.alert_status,
        alert_text: alert.breach_description,
        alert_id: alert.alert_access_key
      };
      this.alertsView.push(
          {
            idx: alert.breach_id, id: spyDwbCommerceContent._id, is_view: false, img_src: '/assets/spyandid/bottom_arrow.svg', alarm: breachAlart
          }
      );      
    }; 

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.person?.firstChange === false) {
      this.init().then(() => {
        this.serviceHelperService.trackingService.report({
          page: BaseComponent.PAGE.detail,
          pageType: BaseComponent.PAGE_TYPE.member,
          type: peopleSearchProductKeys.nameSearch,
          section: 'properties',
          action: 'view',
        });
      });
    }
  }

  init() {
    this.findCensusData();

    this.showAllProperties = this.isPdfPrintView;

    const owned = this.searchedPersonByIndex(this.currentIndex).properties.sort((a, b) => {
      return <any>new Date(b.filingDate) - <any>new Date(a.filingDate);
    });    
    const rented = this.searchedPersonByIndex(this.currentIndex)
      .addresses.sort((a, b) => {
        return <any>new Date(b.firstReported) - <any>new Date(a.firstReported);
      })
      .filter((property) => {
        return !owned.find((op) => op.address.fullAddress?.toLowerCase() === property.fullAddress?.toLowerCase());
      });

    this.propertiesList = [
      ...this.searchedPersonByIndex(this.currentIndex).properties.map((property) => property.address),
      ...this.searchedPersonByIndex(this.currentIndex).addresses,
    ]
      .filter((address) => address?.['latitude'] && address?.['longitude'])
      .map((address, index) => {
        return {
          id: index,
          latitude: address.latitude,
          longitude: address.longitude,
        };
      });

    this.propertyArr = [...owned.map((p) => ({ ...p, ownershipStatus: 'C' })), ...rented];

    this.propertyArr.sort((a, b) => {
      const parseDate = (range: string | null) => {
        if (!range) return null; // Handle null dateRange
        const toDate = range.split('-')[1]; // Extract 'to' date
        return toDate ? new Date(toDate) : null; // Convert to Date object
      };  
      const dateA = parseDate(a.dateRange);
      const dateB = parseDate(b.dateRange);
    
      if (dateA === null && dateB === null) return 0; // Both are null
      if (dateA === null) return 1; // Null dates come last
      if (dateB === null) return -1;
    
      const dateComparison = dateB.getTime() - dateA.getTime();

      // If dates are the same, sort by fullAddress alphabetically
      if (dateComparison === 0) {
        return a.fullAddress.localeCompare(b.fullAddress);
      }
      return dateComparison;
    });

    if (this.getzip(this.currentIndex)) {
      this.lastZip = this.getzip(this.currentIndex);
    }

    // Tooltip init
    setTimeout(() => {
      $('.tooltip-container').tooltip({
        html: true,
        trigger: 'click',
      });
    }, 0);
    return super
      .init()
      .then(() => {
        this.customerNumber = this.uxHelper.getValue('comp.brand.customer.phone');

        const commerceStorage = this.serviceHelperService?.commerceService?.getStorage();
        if (this.spyIdHelper.isIdProtectionEnabled(commerceStorage)) {
          this.getDwbBreaches();
        }
        const idProtectionFrontEnabled = this.uxHelper.getValue("comp.member.idProtection.enable") === 'true'? true: false;
        const idProtectionBackendEnabled = this.uxHelper.getValue("comp.member.idProtection.registration.enable") === 'true'? true: false;
        if (idProtectionBackendEnabled && idProtectionFrontEnabled) {
          this.idProtectionEnabled = true;
        }

        if (commerceStorage?.reportClaimedContentId) {
          this.idOfferClaimed = true;
        }
      }).catch((e) => {
        LogUtils.error(e);
      });
  }
  
  handleMove() {
    var url = '/'+clientPaths.memberAccountProfile;
    //var url = '/'+clientPaths.spyDashboardComponent + '/' + this.spyDwbCommerceContentId;
    this.router.navigateByUrl(url);
  }

  handleMoveMySearch() {
    var url = '/'+clientPaths.content;
    this.router.navigateByUrl(url);
  }

  alertDetailDisplay(idx) {
    if (this.alertsView[idx].is_view == true) {
      this.alertsView[idx].is_view = false;
      this.alertsView[idx].img_src = '/assets/spyandid/forward_arrow.svg';
    } else {
      this.alertsView[idx].is_view = true;
      this.alertsView[idx].img_src = '/assets/spyandid/bottom_arrow.svg';
    }
  }


  formatTimestamp(timestamp) {
    if (!timestamp) {
      timestamp = 0;
    }
    return moment.unix( Number(timestamp)).tz('America/New_York').format('MM/DD/YYYY h:mm a');
  }

  formatYear(timestamp) {
    if (!timestamp) {
      timestamp = 0;
    }
    return moment.unix( Number(timestamp)).tz('America/New_York').format('YYYY');
  }
  formatDate(timestamp) {
    if (!timestamp) {
      return 'N/A';
    }else if (timestamp.length == 4) return '-';
    else  {
      let formattedDate = moment(timestamp).format('MM/DD/YYYY');
      return formattedDate;
    }
    
  }
  // displayStyle = "none";

  // openPopup() {
  //   this.displayStyle = "block";
  // }
  // closePopup() {
  //   this.displayStyle = "none";
  // }
  // Get selected phone number
  searchedPhoneNumber(person: SearchedPerson) {
    let phone = null;
    if (person.tempClient.selectedPhoneNumber) {
      phone = person.tempClient.selectedPhoneNumber;
    }else {
      phone = this.phoneNumber;
    }

    // phone format
    return this.formatPhoneNumber(phone);
  }
  formatPhoneNumber(phoneNumber) {
    if (phoneNumber.length !== 10) {
        return phoneNumber;
    }
    const formattedNumber = `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
    return formattedNumber;
  }

  hasPremiumFields(searchedPerson: SearchedPerson) {
    if (!searchedPerson.tempClient.upsellEnabled) {
      return false;
    }
    return Object.keys(searchedPerson.tempClient.availableItems).length > 0;
  }
  openPhotoModal(photos) {
    this.selectedPhotos = photos;
    this.swiperIndex = 0;
    if (this.selectedPhotos?.length < 1) {
      return;
    }
    this.serviceHelperService.trackingService.report({
      page: BaseComponent.PAGE.detail,
      pageType: BaseComponent.PAGE_TYPE.member,
      type: peopleSearchProductKeys.nameSearch,
      section: 'associates',
      action: 'view_more_images',
    });
    $('#personPhotoModal').modal({
      backdrop: 'static',
    });
  }
  handleLoader() {
    this.showLoader.emit();
  }

  selectedPhoneNumber(person: SearchedPerson) {
    let phone = null;
    if (person.tempClient.selectedPhoneNumber) {
      phone = person.tempClient.selectedPhoneNumber;
    }else {
      phone = this.phoneNumber;
    }
    // phone format
    return this.formatPhoneNumber(phone);
  }

  selectedLineType(person: SearchedPerson) {
    if (person?.phones[0]?.type) {
      person.tempClient.type = person?.phones[0]?.type;
      return person?.tempClient?.type;
    } else {
      return '';
    }
  }

  selectedCareer(person: SearchedPerson) {
    if (person?.phones[0]?.carrier) {
      person.tempClient.carrier = person?.phones[0]?.carrier;
      return person?.tempClient?.carrier;
    } else {
      return '';
    }
  }

  selectedPhoneConnected(person: SearchedPerson) {
    if (person?.phones[0]?.connected) {
      person.tempClient.connected = person?.phones[0]?.connected;
      return person?.tempClient?.connected;
    } else {
      return false;
    }
  }

  moveStep(step) {
    if (step < 0) {
      if (this.swiperIndex === 0) {
        this.swiperIndex = this.selectedPhotos.length - 1;
      } else {
        this.swiperIndex--;
      }
    } else {
      if (this.swiperIndex === this.selectedPhotos.length - 1) {
        this.swiperIndex = 0;
      } else {
        this.swiperIndex++;
      }
    }
  }
  checkNote() {
    if (this.noteContent.length) {
      this.invalidNote = false;
    } else {
      this.invalidNote = true;
    }
  }

  addNote() {
    $('#add-note-modal').modal('hide');
    return Promise.resolve(true).then(() => {
      const userId = this.serviceHelperService.authenticationService.getUserId();
      return this.noteService
        .createNote(this.noteContent, this.private, this.searchedPersonByIndex(this.currentIndex)?.extId, userId)
        .then(() => {
          this.handleReload();
          this.noteContent = '';
          this.isPrivate = false;
          this.serviceHelperService.trackingService.report({
            page: BaseComponent.PAGE.detail,
            pageType: BaseComponent.PAGE_TYPE.member,
            type: 'notes',
            action: 'submit',
            private: this.private,
          });
          if (this.private) {
            $('#success-private-note-modal').modal('show');
          } else {
            $('#success-public-note-modal').modal('show');
          }
        })
        .catch((err) => {
          LogUtils.error(err);
        });
    });
  }

  reload() {
    window.location.reload();
  }
  getNoOfKeysInObject(obj) {
    let x = 0;
    x = Object.keys(obj).length;
    return x;
  }
  async getCensusData(arr: any[], func) {
    let result;
    for (const address of arr) {
      result = await func(address);
      if (result) return result;
    }
    return null;
  }
  personFullName(person: SearchedPerson) {
    return `${person?.fName} ${person?.lName}`;
  }

  // Get possible aliases array
  possibleAliases(person: SearchedPerson) {
    let sliceAmount = 0;
    if (person.akas?.length < 2) sliceAmount = person.akas?.length;
    else sliceAmount = 2;
    const values = person.akas?.map((val) => `${val.fName} ${val.lName}`.trim()).slice(0, sliceAmount);
    if (values.length == 1) return values.pop();
    else return values.join(', ');
  }

  // Get possible associates array
  possibleAssociates(person: SearchedPerson) {
    let sliceAmount = 0;
    if (person.associates?.length < 2) sliceAmount = person.associates?.length;
    else sliceAmount = 2;
    const values = person.associates?.map((val) => `${val.fName} ${val.lName}`.trim()).slice(0, sliceAmount);
    if (values.length == 1) return values.pop();
    else return values.join(', ');
  }

  // Get possible relation array
  possibleRelatives(person: SearchedPerson) {
    let sliceAmount = 0;
    if (person.relatives?.length < 2) sliceAmount = person.relatives?.length;
    else sliceAmount = 2;
    const values = person.relatives?.map((val) => `${val.fName} ${val.lName}`.trim()).slice(0, sliceAmount);
    if (values.length == 1) return values.pop();
    else return values.join(', ');
  }
  handlePrevious() {
    window.scrollTo(0, 0);
    this.showLoader.emit();
    setTimeout(() => {
      this.router.navigate([`member/nameSearch/detail/${this.commerceContent._id}/criminalRecords`]);
    }, this.loaderEyeTimeout);
  }

  onContactUs() {
    window.scrollTo(0, 0);
    // this.goTo.emit();
    this.router.navigate(['member/mini-contact-us']);
  }
  // Get possible locations array
  possibleLocations(person: SearchedPerson) {
    let sliceAmount = 0;
    if (person.tempClient?.cityStates?.length < 2) sliceAmount = person.relatives?.length;
    else sliceAmount = 2;
    const values = person.tempClient?.cityStates?.slice(0, sliceAmount);
    if (values.length == 1) return values.pop();
    else return values.join(', ');
  }

  // Get searched person by index
  searchedPersonByIndex(index: number) {
    return this.searchedPersons[index];
  }
  getzip(index: number) {
    return this.searchedPersons[index]?.addresses[0]?.zip || this.searchedPersons[index]?.tempClient?.address?.zip;
  }
  handleReload() {
    this.onReload.emit();

    setTimeout(() => {
      this.initialize();
    }, 2000);
  }

  // Get search persons other than the currently selected one.
  searchedPersonsWithoutCurrent() {
    this.searchPersonsFiltered = JSON.parse(JSON.stringify(this.searchedPersons));
    return this.searchPersonsFiltered.splice(this.currentIndex, 1);
  }

  // Set currently selected searched person
  setCurrentIndex(val: SearchedPerson) {
    this.currentIndex = this.searchedPersons?.indexOf(val);
  }

  // Check if the household object is empty
  isHouseHoldEmpty(val: SearchedPerson) {
    return Object.keys(val.household).length === 0;
  }

  // Check if the household object is empty
  isFinanceEmpty(val: SearchedPerson) {
    return Object.keys(val.finance).length === 0;
  }

  // When the user clicks on the button, scroll to the top of the document
  scrollToTopHandler() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
  viewDetailedReport() {
    const person = this.searchedPersonByIndex(this.currentIndex);

    this.serviceHelperService.userInputService.userInput = {
      fname: person?.fName,
      lname: person?.lName,
      state: person?.addresses?.[0]?.state,
      extId: person?.extId,
    };
    this.serviceHelperService.userInputService.storeUserInput();
    this.router.navigateByUrl(this.customClientPaths.memberNameSearchLanding + '?skip=true&keepUserInput=1');
  }

  findCensusData() {
    const uxcId = this.uxHelper.uxComposite.uxConfigId;

    const uxlId = this.uxHelper.uxComposite.uxLayoutId;
    // last param isRevPhone [true] indicating endpoint is called from phone search
    this.censusService
      .findCensusCommunityData(null, null, this.lastZip, this.commerceContent._id, uxcId, uxlId, true)
      .then((censusData: any) => {
        if (censusData.premium) {
          this.censusInfo = censusData;
          return '';
        }

        this.censusInfo.premium = false;
        if (!censusData?.value) {
          // moved logging in CensusDataService.findCensusCommunityData
          return;
        }
        Object.keys(censusData?.value).forEach((key) => {
          if (censusData?.value[key] === 'NULL') {
            this.censusInfo[key] = null;
          } else {
            this.censusInfo[key] = censusData?.value[key];
          }
        });
        if (this.censusInfo['STATE']) {
          return this.censusInfo['STATE'];
        }
        return '';
      })
      .then((stateCode) => {
        if (!stateCode) {
          return;
        }
        // last param isRevPhone [true] indicating endpoint is called from phone search
        this.censusService
          .findCensusCommunityData(undefined, stateCode, undefined, this.commerceContent._id, uxcId, uxlId, true)
          .then((censusData: Resource) => {
            if (!censusData?.value) {
              // moved logging in CensusDataService.findCensusCommunityData
              return;
            }
            Object.keys(censusData.value).forEach((key) => {
              if (censusData.value[key] === 'NULL') {
                this.stateCensusInfo[key] = null;
              } else {
                this.stateCensusInfo[key] = censusData.value[key];
              }
            });
          });
      })
      .catch((err) => {
        LogUtils.debug(err);
      });
  }

  titleCase(str) {
    return str
      .split('_')
      .map((w) => w[0].toUpperCase() + w.substring(1).toLowerCase())
      .join(' ');
  }
}

@NgModule({
  declarations: [MiniLinearPhoneSearchV2Component],
  imports: [
    CommonModule,
    FormsModule,

    UiSwitchModule,
    NgSelectModule,

    MemberUpsellPhoneComponentModule,
    MiniCensusAccordionComponentModule,
    PropertyMapComponentModule,
    MiniPropertyComponentModule,
    SexOffenderComponentModule,
    MiniButtonComponentModule,
    ReportActionComponentModule,
    MemberDisclaimerComponentModule,
    MemberFooterComponentModule,
    MemberKeepSearchComponentModule,
    MiniReportAdProtectionComponentModule,
  ],
  exports: [MiniLinearPhoneSearchV2Component],
})
export class MiniLinearPhoneSearchV2ComponentModule { }
