import { Component, Input, NgModule } from '@angular/core';

@Component({
  selector: 'ux-5-2-loading-spinner',
  template: `
    <div class="background">
      <div class="loader-container">
        <img src="assets/common/svg/spinner.svg" alt="spin" />
      </div>
    </div>
  `,
  styles: [
    `
      .background {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9999;
      }

      .loader-container {
        background-color: transparent;
        box-shadow: none;

        img {
          animation: spin infinite 3s linear;
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    `,
  ],
})
export class Ux5Dot2LoadingSpinnerComponent {
  @Input() title: string;
  @Input() description: string;
  @Input() classes: string[];
}

// @NgModule({
//   declarations: [Ux5Dot2LoadingSpinnerComponent],
//   exports: [Ux5Dot2LoadingSpinnerComponent],
// })
// export class LoadingSpinnerModule {}
