import {ApplicationRef, Injectable} from "@angular/core";
import {UxComposite} from "../../common/models/ux/uxComposite";
import {PixelService} from "./pixel.service";
import {TrackingService} from "./tracking.service";
import {BaseComponent} from "../components/BaseComponent";
import {objectUtils} from "../../common/utils/objectUtils";
import customPageHelper from "../custom/customPageHelper";
import {LogUtils} from "../../common/utils/logUtils";

@Injectable()
export class PageService {
  constructor(private pixelService: PixelService,
              private applicationRef: ApplicationRef,
              private trackingService: TrackingService) {
  }

  /**
   * This will be called on page load for the page pixel fire, tracking & page tile of the doc
   * @param pageComponent context of the base component of the page loaded
   * @returns resolved promise with the response for content data or rejected promise with error data
   */
  onPageLoad(pageComponent: BaseComponent): Promise<any> {
    this.setUxComposite(pageComponent, pageComponent.uxComposite);
    this.pixelService.fire(pageComponent.uxHelper.getUxcompKey("pixel"), pageComponent.pixelExtra);
    this.trackingService.trackPage();
    this.setPageTitle(pageComponent.uxHelper.getUxcomp("title"));
    return Promise.resolve();
  }

 /**
  * @param pageComponent context of the base component of the page loaded
  * @param uxComposite of a page component
  */
  setUxComposite(pageComponent, uxComposite: UxComposite) {
    LogUtils.debug("UXC", uxComposite, pageComponent);
    pageComponent.uxComposite = uxComposite;
    pageComponent.uxHelper.uxComposite = uxComposite;
    pageComponent.uxHelper.prefix = `${pageComponent.pageType}.${pageComponent.pageCategory}.${pageComponent.page}`;
    pageComponent.uxHelper.pageType = pageComponent.pageType;
    pageComponent.uxHelper.pageCategory = pageComponent.pageCategory;
    pageComponent.uxHelper.page = pageComponent.page;
  }

  /**
   *
   * @param key against which page info will be returned
   * @returns the page info based on key
   */
  getPageTypesByUxKey(key) {
    if (key) {
      let split = key.split(".");
      if (split.length > 3) {
        let pageType = split[1];
        let pageCategory = split[2];
        let page = split[3];

        if (BaseComponent.PAGE_TYPE[objectUtils.getKeyByValue(BaseComponent.PAGE_TYPE, pageType)] &&
          BaseComponent.PAGE_CATOGORY[objectUtils.getKeyByValue(BaseComponent.PAGE_CATOGORY, pageCategory)] &&
          BaseComponent.PAGE[objectUtils.getKeyByValue(BaseComponent.PAGE, page)]) {
          return {
            pageType: pageType,
            pageCategory: pageCategory,
            page: page,
          }
        }
      }
    }
  }

  /**
   *
   * @param pageType getting from BaseComponent to be provided as input to the child(theme template) component.
   * @param pageCategory getting from BaseComponent to be provided as input to the child(theme template) component.
   * @param page getting from BaseComponent to be provided as input to the child(theme template) component.
   * @returns page url based on page info like pageType and category...
   */
  getPageUrl(pageType, pageCategory, page) {
    return customPageHelper.getPageUrl(pageType, pageCategory, page);
  }

  /**
   * This set the current HTML document title
   * @param title to be set it got from pageComponent uxHelper
   */
  setPageTitle(title) {
    if (title) {
      document.title = title;
    } else {
      let hostname = this.getHostname();
      if (hostname) {
        document.title = hostname.charAt(0).toUpperCase() + hostname.slice(1);
      }
    }
  }

  /**
   *
   * @returns the brand name form the window location URL
   */
  getHostname() {
    var domain = window.location.hostname;
    if (domain) {
      var fragments = domain.split(".");
      let hostname = fragments[fragments.length - 2];
      return hostname;
    }
  }

  // this manually run's the change detection using applicationRef
  render() {
    this.applicationRef.tick();
  }

}
