import {Component, ComponentFactoryResolver, Input, OnChanges, OnInit} from "@angular/core";
import {UxHelper} from "../../../../clientCommon/helper/uxHelper";
import {LandingPeopleSearch} from "../../../peopleSearch/landingPeopleSearch";
import {UxComposite} from "../../../../common/models/ux/uxComposite";
import { utmParamValueType } from "@sales/peopleSearch/nameSearch/landing/landing.component";
import { LandingAffilateFormComponent } from "@sales/affiliate/affiliateForm.component";
import { ServiceHelperService } from "@clientCommon/services/serviceHelper.service";
import { ActivatedRoute } from "@angular/router";
import { LandingAffilateComponent } from "@sales/affiliate/affiliate.componet";

@Component({
  selector: 'ux-3-0-affiliate',
  templateUrl: './affiliate.component.html',
  styleUrls: ['./affiliate.component.css'],
})
export class Ux3Dot0AffiliateComponent extends LandingAffilateComponent implements OnInit, OnChanges {
  //@Input() base: LandingAffilateFormComponent;
  @Input() uxHelper: UxHelper;
  @Input() pageType: string;
  @Input() pageCategory: string;
  @Input() page: string;
  //@Input() utmParamValue: utmParamValueType = null;
  constructor(serviceHelperService: ServiceHelperService,
    activatedRoute: ActivatedRoute,
    cfr: ComponentFactoryResolver) {
      super(serviceHelperService, activatedRoute, cfr);
  }

  init() {
    return super.baseInit().then(() => {});
  }

  ngOnInit() {
    return super.ngOnInit().then(() => {
      this.init();
    });
  }

  ngOnChanges() {
    return this.init();
  }
}
