import { phoneUtils } from "./phoneUtils";

function isValidAge(age: any): boolean {
  if (!age) {
    return false;
  }

  if (!(typeof age === 'string' || typeof age === 'number')) {
    return false;
  }

  return !!age.toString().match('^[1-9][0-9]{0,2}$');
}

function isValidZip(zip: any): boolean {
  if (!zip || typeof zip !== 'string') {
    return false;
  }

  return !!zip.match('^[0-9]{5}$');
}

function isValidPhone(phone: any): boolean {
  if (!phone || typeof phone !== 'string') {
    return false;
  }

  return phoneUtils.validDigits(phone);
}

export const validateUtil = {
  isValidAge,
  isValidZip,
  isValidPhone,
};