import {BaseComponent} from "../../clientCommon/components/BaseComponent";
import {LogUtils} from "../../common/utils/logUtils";
import {CommerceContent} from "../../common/models/commerce/commerceContent";
import {ServiceHelperService} from "../../clientCommon/services/serviceHelper.service";
import {timeUtils} from "../../common/utils/timeUtils";
import {ActivatedRoute} from "@angular/router";
import {progressorHelper} from "../../common/helpers/progressorHelper";
import {FakeProgressor} from "../../clientCommon/helper/fakeProgressor";
import {isArray} from "util";
import {PeopleSearchContentHelper, peopleSearchContentHelper} from "../../common/custom/peopleSearch/peopleSearchHelper";
import {AddressField, AddressUtils} from "../../common/utils/addressUtils";
import { thinMatchType } from "../../common/custom/models/peopleSearch/searchedPerson";
import {collectionClassHelper} from "../../common/decorators/database/collectionClass";
import { ResponseEvent } from "@common/event/responseEvent";

export class LoaderPeopleSearch extends BaseComponent {
  count = 0;
  reSearchFlag = false;
  hasAccess;
  startTimestamp;
  searchPromise: { promise: Promise<any>, fulfill, reject };

  pageNo = 1;
  perPage = 100;


  commerceContent: CommerceContent;
  questionTypes = PeopleSearchContentHelper.QUESTION_TYPE;
  questions;
  questionTypeCurrent;
  questionTypeSubmitted;
  skip = null;
  addressField: AddressField = AddressUtils.getAddressField("US");

  reSearchs = [];
  reSearchQuestion;
  currentSearchError;

  fakeProgressors: any;
  isThinMatchEnabled = false;
  ignoreThinMatch = false;
  isThinMatchCase = false;

  // Fake Question Occurances
  cityFakeQuestion = 0
  ageFakeQuestion = 0
  relativesFakeQuestion = 0;

  postPreloaderTransferUxcompKey = "transfer.preloader.post";
  postLoaderTransferUxcompKey = "transfer.loader.post";

  // User Input
  userInputFirstName: string;
  userInputLastName: string;
  nameFromLS;
  lpCounty: string = '';

  // Thin Match Cases Flags for each case
  thinMatchCaseEnabled = {
    NoResults: false,
    TooManyResults: false,
    Geographic: false, 
    GeographicNoResults: false,
    DataProviderDown: false
  };

  constructor(public serviceHelperService: ServiceHelperService, activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
    this.page = BaseComponent.PAGE.loader;
  }

  disableThinMatch() {
    this.isThinMatchEnabled = false;
    this.ignoreThinMatch = true;
    this.isThinMatchCase = false;
  }

  getDefaultDuration() {
    let key = `comp.${this.pageType}.${this.pageCategory}.${BaseComponent.PAGE.loader}`;
    let progresses = progressorHelper.getProgresses(this.uxComposite, [key]);
    let duration;
    let stepSkipping  = this.serviceHelperService?.storageService.getSession('stepSkipping');
    if (stepSkipping) {
      duration = 0;
    }else {
      duration = 50 * 1000; // Default
    }
    /* This should not uncomment. if duration less than result comes back, loader end tracking will be ignored
    if (progresses[0]) {
      duration = progresses[0].duration;
    }
    */
    return duration;
  }

  getUrlParam(param) {
    let result = decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search) || [null, ''])[1].replace(/\+/g, '%20')) || null;
    if (result !== null) {
      // capitalize first letter
      return result.charAt(0).toUpperCase() + result.slice(1);
    }
    return null;
  }

  onInit(): Promise<any> {
    this.startTimestamp = timeUtils.getTimestamp();
    this.fakeProgressors = this.serviceHelperService.progressorService.fakeProgressors;
    this.setSearchPromise();
    return this.baseInit().then(() => {    
      this.thinMatchCaseEnabled = this.uxHelper?.getValue("comp.sales.name-search.thinMatchCases.enabled");
      this.nameFromLS = this.serviceHelperService.storageService.get("inputTracking"); 
      this.userInputFirstName = this.userInput?.fname || this.nameFromLS.search?.firstName || this.getUrlParam('ui_fname') || '';
      this.userInputLastName = this.userInput?.lname || this.nameFromLS.search?.lastName || this.getUrlParam('ui_lname') || '';

      // Progressor
      this.fakeProgressors.current.setDuration(this.getDefaultDuration());
      if (this.isSkipLoader()) {
        let weights = Array.from(Array(20).keys()).map(n => n * n * n * n);
        this.fakeProgressors.current.setWeights(weights.reverse());
      }
      this.executeProgress().then(() => {
        // do nothing.
      }).catch((e) => {
        LogUtils.error(e);
      });
    });
  }

  onDestroy() {
    this.serviceHelperService.progressorService.clearCurrentProgressors();
    return super.onDestroy();
  }


  setSearchPromise() {
    this.searchPromise = <any>{};
    this.searchPromise.promise = new Promise<any>((fulfill, reject) => {
      this.searchPromise.fulfill = fulfill;
      this.searchPromise.reject = reject;
    });
  }

  next() {
    if (this.stillInThisPage()) {
      if (this.serviceHelperService.trackingService.shouldTransfer(this.uxHelper.uxComposite, this.getPostLoaderTransferUxcompKey())) {
        return this.serviceHelperService.trackingService.transferDomain(this.uxHelper.uxComposite, this.getPostLoaderTransferUxcompKey());
      } else {
        let options = {replaceUrl: true};
        return this.goNextPage(null, options);
      }
    }
  }

  isSkipLoader() {
    if (this.skip === null) {
      let ruleProgressorKeySkip = this.uxHelper.getUxcomp("rule.progressor.skip");

      if (this.userInput.searchObjectId) {
        this.skip = true;
      } else if (!(ruleProgressorKeySkip && isArray(ruleProgressorKeySkip[BaseComponent.PAGE.loader]) && ruleProgressorKeySkip[BaseComponent.PAGE.loader].indexOf(this.userInput.from) !== -1)) {        
        this.skip = false;
      } else {
        this.skip = true;
      }
    }
    return this.skip;
  }

  submitFakeQuestion(){
    this.ageFakeQuestion++;
    this.cityFakeQuestion++;
  }

  submitFakeQuestionType(type) {
    this.cityFakeQuestion++;

    this.questionTypeSubmitted = type;
    this.questionTypeCurrent = type;    
    if (this.ageFakeQuestion === 1) this.ageFakeQuestion++;
    setTimeout(() => {
      this.questionTypeSubmitted = 'none';
      this.ageFakeQuestion++;
    }, 5000);
  }
  getPostPreloaderTransferUxcompKey() {
    return this.uxHelper.getUxcompKey(this.postPreloaderTransferUxcompKey);
  }

  getPostLoaderTransferUxcompKey() {
    return this.uxHelper.getUxcompKey(this.postLoaderTransferUxcompKey);
  }

  search(): Promise<any> {
    this.count++;
    let uxcKeys = [];
    let _thinMatchType = null;
    return Promise.resolve().then(() => {
      this.checkThinMatchEnabled()
      if (this.serviceHelperService.peopleSearchService.hasCommerceContent()) {
        return this.serviceHelperService.peopleSearchService.getCommerceContent(this.uxHelper.uxComposite);
      } else {
        let productKey = this.getProductKeyByPageCategory();
        let keyPrefix = `comp.${this.pageType}.${this.pageCategory}.`;

        if (!this.isSkipLoader()) {
          uxcKeys.push(keyPrefix + BaseComponent.PAGE.loader);
        }
        uxcKeys.push(keyPrefix + BaseComponent.PAGE.searchResult);
        uxcKeys.push(keyPrefix + BaseComponent.PAGE.reportReview);
        LogUtils.debug(productKey, this.userInput, this.pageNo, this.perPage, uxcKeys);
        return this.serviceHelperService.peopleSearchService.peopleSearch(productKey, this.userInput, this.pageNo, this.perPage, uxcKeys)
      }
    })
    .catch((e) => {
      if (!this.isThinMatchEnabled || !this.thinMatchCaseEnabled?.TooManyResults){
        throw e;
      }

      const error = peopleSearchContentHelper.getError(e);
      _thinMatchType = (error === PeopleSearchContentHelper.ERROR.tooManyResult) 
        ? thinMatchType.TooManyResults
        : null;

      // When search fails (tooManyResults, etc.) Dev#1624
      //  - Existing logic: Create commerceContent by performing a dummy search with XZOIQLRJXKQJSCPKTDRF(fname, lname).
      //    (If commerceContent does not exist, an error occurs in the next step and a dummy CommerceContent is created and processed.)
      //  - Changed logic: Do not perform a dummy search
      //    (The logic that requires commerceContent in the next step handles exceptions.)
      if (e instanceof ResponseEvent) {
        let commerceContent = e.getSingleDoc();
        this.serviceHelperService.peopleSearchService.setCommerceContent(commerceContent);
        peopleSearchContentHelper.processResult(this.uxHelper.uxComposite, commerceContent);
        return commerceContent;
      }

      return null;
    })
    .then((commerceContent) => {
      LogUtils.debug('loaderPeopleSearch', this.userInput, commerceContent);

      // Phase 1. General logic
      this.uxComposite.setCode("contentInfo", {
        fname: this.userInput.fname,
        lname: this.userInput.lname,
        state: this.userInput.state,
        phone: this.userInput.phone
      });

      if (!commerceContent) {
        return;
      }

      // Phase 2. CommerceContent logic
      commerceContent.content.contentInfo = this.userInput

      if (this.commerceContent) {
        Object.keys(commerceContent.content).forEach((key) => {
          this.commerceContent.content[key] = this.commerceContent.content[key].concat(commerceContent.content[key]);
        });
      } else {
        Object.keys(commerceContent.content).forEach((key) => {
          this.commerceContent = commerceContent;
        });
      }

      this.checkThinMatchFlow(_thinMatchType);

      this.updateLpCounty();
    })
    .then(() => {
      if (this.serviceHelperService.trackingService.shouldTransfer(this.uxHelper.uxComposite, this.getPostPreloaderTransferUxcompKey())) {
        return this.serviceHelperService.trackingService.transferDomain(this.uxHelper.uxComposite, this.getPostPreloaderTransferUxcompKey());
      } else if (this.stillInThisPage() && this.commerceContent?.progressors?.length) {
        this.serviceHelperService.progressorService.setProgressor(this.commerceContent.progressors[0]);

        if (this.serviceHelperService.progressorService.isSameProgressPage()) {
          // if (!this.serviceHelperService.progressorService.goToCurrentProgressPage()) {
          this.serviceHelperService.progressorService.setCurrentProgress(this.startTimestamp);
          this.serviceHelperService.progressorService.runNestedTypes();
        } else {
          return this.next();
        }
      }
      LogUtils.debug("Loader", this.commerceContent);
      this.searchPromise.fulfill();
      return this.searchPromise.promise;
    })
    .catch((e) => {
      return this.processResearch(e);
    })
  }

  checkThinMatchEnabled() {
    const uxcEnabled = this.uxHelper?.getValue("comp.sales.name-search.thinMatch.enabled");
    const isLoggedIn = this.serviceHelperService.authenticationService.isLoggedIn();
    // Only enable thin match is the user isn't logged in, the UXC is enabled and we have not ignored thin match
    this.isThinMatchEnabled = (uxcEnabled && !this.ignoreThinMatch && !isLoggedIn) ? true : false;
    return this.isThinMatchEnabled;
  }

  checkThinMatchFlow(type?:thinMatchType) {
    if (!this.isThinMatchEnabled || this.ignoreThinMatch) {
      return;
    }
    this.userInputFirstName = this.userInput?.fname || this.nameFromLS.search?.firstName;
    this.userInputLastName = this.userInput?.lname || this.nameFromLS.search?.lastName;
    this.uxHelper.uxComposite.setCode('searchedPerson', this.userInput?.fname + ' ' + this.userInput?.lname);
    this.uxHelper.uxComposite.setCode('searchedPersonFname', this.userInput?.fname);
    this.isThinMatchCase = false;

    if (this.checkThinMatchGeographic() && this.thinMatchCaseEnabled.Geographic && !this.thinMatchCaseEnabled.GeographicNoResults) { // when type is geolocation and geo no result isn't enabled
      this.isThinMatchCase = true;
    } else if (type && this.thinMatchCaseEnabled[type]) { // when type is too many result
      this.serviceHelperService.storageService.setSession("thinMatchType", type);
      this.isThinMatchCase = true;
    } else {
      this.isThinMatchCase = this.checkThinMatchNoResults();
    }

    if (this.isThinMatchCase) {
      this.serviceHelperService.storageService.setSession("thinMatchEnabled", true);
      this.uxHelper.uxComposite.setCode('thinMatchEnabled', true);
      this.cityFakeQuestion++;

      if (this.commerceContent?._id) {
        return this.serviceHelperService.crudService.pushInput(
          collectionClassHelper.getCollectionName( CommerceContent ),
          this.commerceContent._id,
          "thinMatch"
        ).then(() => {}).catch((e) => {
            LogUtils.error(e);
          });
      }
    }
  }

  checkThinMatchStateSelected() {
    const selectedState = this.userInput?.state || "";
    const geoLocationStates = this.uxHelper?.getValue("comp.sales.name-search.thinMatch.geoLocations");
    return geoLocationStates.includes(selectedState);
  }

  checkThinMatchGeographic() {
    if(this.checkThinMatchStateSelected()) {
      this.serviceHelperService.storageService.setSession("thinMatchType", thinMatchType.Geographic);
      return true;
    }
    return false;
  }

  checkThinMatchNoResults() {
    const matchFound = this.commerceContent?.getMainRaw()?.tempClient?.processed?.person?.length > 0 ? true : false;

    if (!matchFound && (this.thinMatchCaseEnabled.NoResults || this.thinMatchCaseEnabled.GeographicNoResults)) {
      if(!this.commerceContent?.getMainRaw()?.tempClient?.processed?.person?.length) {
        if (this.checkThinMatchStateSelected() && this.thinMatchCaseEnabled.GeographicNoResults) {
          this.serviceHelperService.storageService.setSession("thinMatchType", thinMatchType.GeographicNoResults);
        } else {
          this.serviceHelperService.storageService.setSession("thinMatchType", thinMatchType.NoResults);
        }
      }
      return true;
    }
    return false;
  }

  validateAge($event) {
    if(/^\d+$/.test($event.target.value) && $event.target.value > 0 && $event.target.value < 120) {
      this.userInput.age = $event.target.value
      return;
    }
    this.userInput.age = null
  }

  executeProgress() {
    return this.serviceHelperService.progressorService.executeProgress().then(() => {
      return this.searchPromise.promise;
    }).then(() => {
      return this.next();
    });
  }

  submitAnswer(fakeProgressor: FakeProgressor, question, answer) {
    let input = peopleSearchContentHelper.shapeAnswer(this.uxComposite, question, answer);
    this.commerceContent.inputs.push(input);
    this.processCommerceContent();
    fakeProgressor.setProgressMax();
    this.questionTypeSubmitted = question.type;
    return this.serviceHelperService.peopleSearchService.submitAnswer(input);
  }

  processCommerceContent() {
    peopleSearchContentHelper.processResult(this.uxHelper.uxComposite, this.commerceContent);
    this.questions = this.commerceContent.tempClient.questions;
  }

  hasQuestionTypeInput(type): boolean {
    return peopleSearchContentHelper.hasQuestionTypeInput(this.commerceContent, type);
  }

  prepareQuestion(question) {
    let rendered = "";
    if (question) {
      let shaped = peopleSearchContentHelper.shapeQuestion(this.uxComposite, question);
      if (question.type === PeopleSearchContentHelper.QUESTION_TYPE.cityState || question.type === PeopleSearchContentHelper.QUESTION_TYPE.relative) {
        shaped.forEach((choice, i) => {
          if (i !== 0) {
            rendered += ' &bull; ';
          }
          rendered += `${choice.key}`;
        });

        this.uxComposite.setCode("temp." + question.type, rendered);
      } else if (question.type === PeopleSearchContentHelper.QUESTION_TYPE.moreThanAge) {
        if (question.choices[0]) {
          this.uxComposite.setCode("temp." + question.type, shaped);
        }
      }
    }
  }

  getQuestion(type) {
    this.questionTypeCurrent = type;
    let question;

    if (this.commerceContent && this.commerceContent.tempClient && this.commerceContent.tempClient.questions) {
      this.commerceContent.tempClient.questions.some((candidateQuestion) => {
        if (candidateQuestion.type === type) {
          if (!this.hasQuestionTypeInput(candidateQuestion.type)) {
            if (candidateQuestion.choices.length) {
              question = candidateQuestion;
            }
          }
        }
        if (question) {
          return true;
        }
      });
      this.prepareQuestion(question);
    }

    return question;
  }

  processResearch(e) {
    const error = peopleSearchContentHelper.getError(e);
    this.currentSearchError = error;
    if (this.count < 5 && error === PeopleSearchContentHelper.ERROR.tooManyResult) {
      this.prepareResearch(error);
      if(this.isThinMatchEnabled && this.thinMatchCaseEnabled.TooManyResults) {
        this.serviceHelperService.storageService.setSession("thinMatchType", thinMatchType.TooManyResults);
      }
    } else {
      if(this.isThinMatchEnabled && this.thinMatchCaseEnabled.DataProviderDown) {
        this.serviceHelperService.storageService.setSession("thinMatchType", thinMatchType.DataProviderDown);
      }
      this.searchPromise.reject(e);
    }
    return this.searchPromise.promise;
  }

  prepareResearch(error) {
    LogUtils.debug(error);
    this.reSearchFlag = true;
    this.fakeProgressors.current.setPause(true);
    this.reSearchQuestion = this.getReSearchQuestion(error);
    if (this.reSearchQuestion && this.reSearchQuestion.type) {
      this.reSearchs.push(this.reSearchQuestion.type);
    }
    if (!this.userInput.city) {
      this.userInput.city = '';
    }
    LogUtils.debug(error, this.reSearchQuestion, this.reSearchs);
    if (!this.reSearchQuestion) {
      return this.next();
    }
  }

  postPrepareResearch(param) {
    this.reSearchFlag = false;
    this.fakeProgressors.current.setPause(false);
    LogUtils.debug(param, this.userInput);
  }

  getReSearchQuestion(error) {

    const config = this.uxHelper.getUxcomp("config");
    LogUtils.debug(this.uxHelper.getUxcompKey("config"), config);
    let reSearchQuestion = null;
    if (config && config.questions && config.questions.length) {
      config.questions.some((question) => {
        if (question.error === error && question.type && this.reSearchs.indexOf(question.type) === -1) {
          reSearchQuestion = question;
          return true;
        }
      });
    }

    if (reSearchQuestion) {
      if (reSearchQuestion.code) {
        Object.keys(reSearchQuestion.code).forEach((key) => {
          this.uxHelper.uxComposite.setCode(key, reSearchQuestion.code[key]);
        });

      }
    }

    return reSearchQuestion;
  }

  isValidCityState() {
    if (this.userInput.state === "all") {
      return false;
    }
    if (!this.userInput.city) {
      return false;
    }

    return true;
  }

  reSearch(param) {
    this.postPrepareResearch(param);

    if (param === null) {
      // Bypass question
      this.prepareResearch(this.currentSearchError);
    } else {
      if (param) {
        Object.keys(param).forEach((key) => {
          this.userInput[key] = param[key];
        });
      }
      return this.search();
    }
  }

  private updateLpCounty() {
    this.lpCounty = '';

    if (!this.isThinMatchCase) {
      return;
    }

    const county = this.uxComposite.get('code.lp_county');

    if (county) {
      this.lpCounty = county;
    }
  }
}
