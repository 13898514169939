import { 
  Component, 
  Input, 
  NgModule, 
  CUSTOM_ELEMENTS_SCHEMA,
  OnInit,
  AfterViewInit,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { customClientPaths } from '../../../../../../common/custom/customPathHelpers';
//import { SwiperOptions } from 'swiper/types/swiper-options';
//import { SwiperModule } from '../../../../../../clientCommon/directives/SwiperDirective';
//import { register } from 'swiper/element';
import Swiper from 'swiper';
import {Navigation, Pagination} from 'swiper/modules'

// Swiper의 모듈을 활성화
Swiper.use([Navigation]); // , Pagination]);
// .use([, Pagination]);

@Component({
  selector: 'app-additional-resources',
  templateUrl: './additional-resources.component.html',
  styleUrls: ['./additional-resources.component.scss'],
})
export class AdditionalResourcesComponent implements OnInit, AfterViewInit {
  @Input() summary: String = '';
  @Input() resourceArr: Array<any> = [];
  
  swiper: Swiper;
  redirectPage = customClientPaths.memberNameSearchRedirectWarning;
  
  // public config: SwiperOptions = {    
  //   //init: false,
  //   spaceBetween: 16,
  //   slidesPerView: 1,
  //   pagination: false,

  //   navigation: {
  //     enabled: true,
  //     nextEl: '.custom-button-next',
  //     prevEl: '.custom-button-prev',
  //   },

  //   breakpoints: {
  //     0: {
  //       slidesPerView: 1,
  //     },
  //     767: {
  //       slidesPerView: 3,
  //     },
  //   },
  // };
  thirdPartyAlertUrl: string = '';

  ngOnInit(): void {
    if (this.resourceArr.length < 3) {
      const resource = {
        imgSrc : 'assets/ux1/search_flow/coming-soon.svg',
        url: null
      };
      this.resourceArr.push(resource);
    }
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.swiper = new Swiper('.swiper-container', {
        modules: [],  // Navigation, Pagination],
        spaceBetween: 16,
        slidesPerView: 1,   
        slidesPerGroup: 1,     
        initialSlide: 0,
        pagination: true,
        loop: false,
  //      navigation: true,
    
        navigation: {
          enabled: true,
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
    
        breakpoints: {
          0: {
            slidesPerView: 1,
            slidesPerGroup: 1,
          },
          767: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
        },
      });
      this.swiper.update();

       // Prevent dynamic application of styles
      const prevButton = document.querySelector('.swiper-button-prev') as HTMLElement;
      prevButton.style.left = '16px'; 

    }, 10);    
  }
  
  goToLink() {
    window.open(this.thirdPartyAlertUrl, '_blank');
    this.thirdPartyAlertUrl = '';
  }

  saveLink(url) {
    window.open(`/${this.redirectPage}?thirdpartyURL=${url}`, '_blank');
  }
}

@NgModule({
  declarations: [AdditionalResourcesComponent],
  imports: [
    CommonModule, 
   // SwiperModule
  ],
  exports: [AdditionalResourcesComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class AdditionalResourcesComponentModule {}
