import { Component, Input, NgModule, OnInit } from '@angular/core';
import { BaseDirective } from '../../../clientCommon/directives/BaseDirective';
import { ServiceHelperService } from '../../../clientCommon/services/serviceHelper.service';
import { ActivatedRoute } from '@angular/router';
import { UxHelper } from '../../../clientCommon/helper/uxHelper';
import { CommonModule } from '@angular/common';
import { UxTemplateModule } from 'app/components/uxTemplate.component';

@Component({
    selector: 'app-member-disclaimer',
    templateUrl: 'memberDisclaimer.component.html',
    standalone: false
})
export class MemberDisclaimerComponent extends BaseDirective implements OnInit {
  @Input() uxHelper: UxHelper;

  constructor(serviceHelperService: ServiceHelperService, activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
  }

  ngOnInit() {
    return super.baseInit();
  }
}

@NgModule({
  declarations: [MemberDisclaimerComponent],
  imports: [CommonModule, UxTemplateModule],
  exports: [MemberDisclaimerComponent],
})
export class MemberDisclaimerComponentModule {}
