import { CommonModule } from "@angular/common";
import {
  ChangeDetectorRef,
  Component, HostListener, Input,
  NgModule,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { PropertyMapMarker, PropertyMapComponentModule } from "./map.component";
import { SexOffenderProfileComponent } from "./mini";
import { UxHelper } from "../../../../clientCommon/helper/uxHelper";
import { CommerceContent } from "../../../../common/models/commerce/commerceContent";
import { SearchedFamilyWatchdog } from "../../../../common/custom/models/peopleSearch/searchedFamilyWatchdog";
import { SearchedPerson } from "../../../../common/custom/models/peopleSearch/searchedPerson";

@Component({
    selector: 'app-member-people-search-sex-offender',
    styleUrls: ['./sexOffender.component.scss'],
    templateUrl: 'sexOffender.component.html',
    standalone: false
})
export class SexOffenderComponent implements OnInit, OnChanges {
  constructor(private cdRef: ChangeDetectorRef) { }

  @Input() uxHelper: UxHelper;

  @Input() person: SearchedPerson;

  @Input() commerceContent: CommerceContent;

  @Input() isPdfPrintView: boolean = false;

  @Input() titleEnable: boolean = true;

  hasSearchPosition = false;

  enable = false;
  isMobile: boolean = false;

  offenders: SearchedFamilyWatchdog[] = [];

  positions: PropertyMapMarker[] = [];

  center: PropertyMapMarker;

  totalOffenderLength: number = 0;

  moreOffenderLength: number = 0;

  perPage = 4;

  private readonly defaultDisplayRadius = 3;

  private readonly displayRadiusMargin = 0.2;

  readonly noOffenderMessage = 'Great news, there are no sex offenders around your area. We will notify if we find any';

  private shownOffenderLength = 0;

  private displayRadius = this.defaultDisplayRadius;

  @HostListener('window:resize')
  onResize() {
    this.checkIfMobile();
  }
  
  ngOnInit(): void {
    this.checkIfMobile();
    
    this.initUxComp();

    this.initOffenders();

    this.initPositions();

    this.initCenter();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initUxComp();

    this.initOffenders();

    this.initPositions();

    this.initCenter();
  }

  private checkIfMobile() {
    this.isMobile = window.innerWidth <= 576; 
  }

  showMoreOffenders(length?: number) {
    const offenders = this.getAllOffenders();

    const offendersToShow = offenders.slice(
      this.shownOffenderLength,
      this.shownOffenderLength + (length ?? this.perPage),
    );

    this.pushShowOffenders(offendersToShow);
  }

  onClickMarker(marker: PropertyMapMarker) {
    if (!marker) {
      return;
    }

    if (+marker.id >= this.offenders.length) {
      // this.showAllOffenders();

      this.cdRef.detectChanges();
    }

    const element = document.getElementById(`sex-offender-profile-${marker.id}`);

    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }

  private initUxComp() {
    const radius = this.uxHelper.uxComposite.get('comp.member.sexOffender.displayRadius');

    if (radius) {
      this.displayRadius = radius;
    }

    const perPage = this.uxHelper.uxComposite.get('comp.member.sexOffender.perPage');

    if (perPage) {
      this.perPage = perPage;
    }
  }

  /**
   * Set an offenders 
   * that is displayed only as much as the initOffenderLength value.
   */
  private initOffenders() {
    this.offenders = [];

    this.shownOffenderLength = 0;

    const offenders = this.getAllOffenders();

    this.totalOffenderLength = offenders.length;

    this.moreOffenderLength = 0;

    const initOffenders = this.isPdfPrintView 
      ? offenders 
      : offenders.slice(0, this.perPage);

    this.pushShowOffenders(initOffenders);
  }

  /**
   * All offenders are displayed as pins on the map.
   */
  private initPositions() {
    this.positions = [];

    const offenders = this.offenders;

    for (
      let i = 0, length = offenders.length;
      i < length;
      i += 1
    ) {
      const offender = offenders[i];

      const offenderModel = SearchedFamilyWatchdog.fromJson(offender);

      if (!offenderModel.position) {
        continue;
      }

      this.positions.push({
        id: i,
        ...offenderModel.position,
      });
    }
  }

  private initCenter() {
    const { latitude, longitude }: { latitude?: number, longitude?: number } = this.getCenter();

    if (latitude === undefined || longitude === undefined) {
      this.hasSearchPosition = false;

      return null;
    }

    this.hasSearchPosition = true;

    this.center = { id: 'center', latitude, longitude };
  }

  private pushShowOffenders(offenders: any[]) {
    if (!offenders?.length) {
      return;
    }

    let i = this.offenders.length;

    this.shownOffenderLength += offenders.length;

    for (let offender of offenders) {
      const offenderModel = SearchedFamilyWatchdog.fromJson(offender);

      this.offenders.push(offenderModel);

      if (offenderModel.position) {
        this.positions.push({
          id: i,
          ...offenderModel.position,
        });
      }

      i += 1;
    }

    this.positions = [...this.positions];

    this.moreOffenderLength = this.totalOffenderLength - this.shownOffenderLength;
  }

  private getAllOffenders(): any[] {
    let offenders = this.commerceContent
      ?.getSexOffenderRaw()
      ?.tempClient.processed
      ?.offenders;

    this.enable = !!offenders;

    if (!offenders?.length) {
      return [];
    }

    const center = this.getCenter();

    const withinDisplayRadiusWithCenter = (offender) => {
      return this.withinDisplayRadius(center, offender);
    };

    this.replaceNearestLocation(center, offenders);

    offenders = offenders.filter(withinDisplayRadiusWithCenter);

    this.sortByDistance(center, offenders);

    return [...offenders];
  }

  private getCenter() {
    const userInput = this.commerceContent
      ?.getSexOffenderRaw()
      ?.tempClient.processed
      ?.userInput;

    const coordinate = this.validCoordinatesOrNull(userInput?.mainCoordinate);

    if (coordinate) {
      return coordinate;
    }

    return {
      latitude: userInput?.latitude,
      longitude: userInput?.longitude,
    };
  }

  private withinDisplayRadius(center, offender): boolean {
    const distance = this.getDistance(center, offender);

    return distance <= this.displayRadius + this.displayRadiusMargin;
  }

  /**
   * If the offender's default latitude/longitude is greater than our search radius, 
   * we use the closest address in offender's otherAddresses.
   */
  private replaceNearestLocation(center, offenders): void {
    if (!offenders?.length) {
      return;
    }

    const length = offenders.length;

    for (let i = 0; i < length; i += 1) {
      const offender = offenders[i];

      const distance = this.getDistance(center, offender);

      // Allow some margin of error
      if (distance > this.displayRadius + this.displayRadiusMargin) {
        this.updateLocationToNearest(center, offender);
      }
    }
  }

  private updateLocationToNearest(center, offender) {
    const addresses = [
      {
        street1: offender?.street1,
        street2: offender?.street2,
        latitude: offender?.latitude,
        longitude: offender?.longitude,
      },
      ...offender?.otherAddresses,
    ];

    this.sortByDistance(center, addresses);

    offender['street1'] = addresses[0]?.street1;
    offender['street2'] = addresses[0]?.street2;
    offender['latitude'] = addresses[0]?.latitude;
    offender['longitude'] = addresses[0]?.longitude;
  }

  private sortByDistance(center, offenders): void {
    offenders.sort((a, b) => {
      const distanceA = this.getDistance(center, a);

      const distanceB = this.getDistance(center, b);

      return distanceA - distanceB;
    });
  }

  private getDistance(a, b) {
    const EARTH_RADIUS = 6371;

    const toRadian = Math.PI / 180;
    const lat1 = a.latitude * toRadian;
    const lat2 = b.latitude * toRadian;
    const lon1 = a.longitude * toRadian;
    const lon2 = b.longitude * toRadian;

    const deltaLat = lat2 - lat1;
    const deltaLon = lon2 - lon1;

    const p1 =
      Math.pow(Math.sin(deltaLat / 2), 2) +
      Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon / 2), 2);

    const p2 = 2 * Math.asin(Math.sqrt(p1));

    const distance = EARTH_RADIUS * p2;
  
    return distance * 0.621371;
  }

  private validCoordinatesOrNull(coordinate) {
    if (!!coordinate 
      && coordinate?.latitude !== null 
      && coordinate?.latitude !== undefined
      && coordinate?.longitude !== null 
      && coordinate?.longitude !== undefined
    ) {
      return coordinate;
    }

    return null;
  }
}

@NgModule({
  declarations: [
    SexOffenderComponent,
    SexOffenderProfileComponent,
  ],
  imports: [
    CommonModule,
    PropertyMapComponentModule,
  ],
  exports: [SexOffenderComponent],
})
export class SexOffenderComponentModule { }
