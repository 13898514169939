import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'marketing-promo',
  templateUrl: './marketing-promo.component.html',
  styleUrls: ['./marketing-promo.component.scss']
})
export class MarketingPromoComponent implements OnInit {

  listContents:string[]=[
    "Criminal/Arrest Records",
    "Marriage/Divorce Records",
    "Social Media Profiles",
    "Current/Past Addresses"
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
