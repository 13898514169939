import { Component, Input, Output, EventEmitter, NgModule } from '@angular/core';
import { UxHelper } from '@clientCommon/helper/uxHelper';
import { SignUpPeopleSearch } from '@sales/peopleSearch/signUpPeopleSearchSales';
import { MembershipType, UxComp } from '@ux5.2/types';

@Component({
  selector: 'ux-5-2-top-sup',
  templateUrl: './top-sup.component.html',
  styleUrls: ['./top-sup.component.scss'],
})
export class TopSupComponent {
  @Input() base: SignUpPeopleSearch;
  @Input() uxHelper: UxHelper;
  @Input() membership: string = 'one_month';
  @Input() membershipTitle: UxComp;
  @Input() iconLabelCardTitle: UxComp;
  @Input() faqCardTitle: UxComp;
  @Input() faqCardSubTitle: UxComp;
  @Input() faqHeader: UxComp;
  @Input() faqDescription: UxComp;
  @Input() faqSecondHeader: UxComp;
  @Input() faqCancelMembershipContent: UxComp;
  @Output() memberShipEvent = new EventEmitter<MembershipType>();

  getName(): string {
    if (this.base.isThinMatchEnable) return this.base.userInputFullName;
    if (!this.base?.selectedPerson?.fName) return 'John';
    return `${this.base?.selectedPerson?.fName} ${this.base.selectedPerson?.lName}`;
  }

  getFullName(): string {
    if (this.base.isThinMatchEnable) return this.base.userInputFullName;
    return `${this.base?.selectedPerson?.fName || 'John'} ${this.base.selectedPerson?.lName || 'Doe'}`;
  }

  see(membership: MembershipType): void {
    this.membership = membership;
    this.memberShipEvent.emit(membership);
  }
}
