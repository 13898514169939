import { Component, OnInit, Input, Output, EventEmitter, NgModule } from '@angular/core';
import { UxComposite } from '../../../../../../common/models/ux/uxComposite';
import { CommonModule } from '@angular/common';
import {
  MiniButtonComponent,
  MiniButtonComponentModule,
} from '@member/peopleSearch/components/mini/button/button.component';
import { AddNoteComponentModule } from './addNote/addNote.component';
import { SaveReportComponentModule } from './saveReport/saveReport.component';
import { GetAlertsComponentModule } from './getAlerts/getAlerts.component';
import { TutorialModalComponentModule } from '../tutorialModal/tutorialModal.component';
import { LogUtils } from '@common/utils/logUtils';
import { UxHelper } from '@clientCommon/helper/uxHelper';
import { PersonDetail } from '@member/peopleSearch/personDetail';
import { SearchedPerson } from '@common/custom/models/peopleSearch/searchedPerson';
import { ServiceHelperService } from '@clientCommon/services/serviceHelper.service';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { BaseDirective } from '@clientCommon/directives/BaseDirective';
import { UxTemplateModule } from 'app/components/uxTemplate.component';
import { BaseComponent } from '@clientCommon/components/BaseComponent';

declare var $: any;

@Component({
  selector: 'app-report-action',
  templateUrl: 'reportAction.component.html',
  styleUrls: ['reportAction.component.scss'],
})
export class ReportActionComponent extends BaseDirective implements OnInit {
  [x: string]: any;
  @Input() downloadPath: string;
  @Input() commerceContent: any;
  @Input() asFlexRow: boolean = false;
  @Input() uxComposite: UxComposite;
  @Input() uxHelper: UxHelper;
  @Input() extId: string;
  @Input() csrPhone: string;
  @Input() disableMonitoring = false;
  @Input() shareLink = false
  @Input() person: SearchedPerson;


  
  @Output() setMonitoring = new EventEmitter<any>();
  @Output() updateNotes = new EventEmitter<any>();

  shareLinkUrl = '';
  shareLinkEnable = false;

  constructor(serviceHelperService: ServiceHelperService, activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
  }

  ngOnInit() {
    const url = new URL(window.location.href);

    try {
      this.shareLinkEnable = this.uxHelper.uxComposite.get("comp.mini-report.share.enable");

      if (this.shareLinkEnable && this.shareLink) {

        this.serviceHelperService.referralService.shareCopyLink().then((result) => {
            let param = `${this.commerceContent._id}`; 
            let targetName = `${this.person?.fName} ${this.person?.lName}`; 
            let uxc = result?.data?.guestUxc;   // this.uxHelper.uxComposite.get('comp.email.campaign.guest.signup.uxc');

            const linkUrl = `${url.protocol}//${url.host}/share/mini-report/${param}?share=${targetName}&uxc=${uxc}`;
            const encodeUrl = encodeURIComponent(linkUrl);
            this.shareLinkUrl = `${url.protocol}//${url.host}/api/social/shareLink?linkUrl=${encodeUrl}`;
        });
      }
    }catch(e) {
      LogUtils.debug(e);
    }
  }

  addNote() {}

  handleSetMonitoring(val) {
    this.setMonitoring.emit(val);
  }

  handleUpdateNotes() {
    this.updateNotes.emit();
  }

  handleClick() {
    //event.stopPropagation(); // Prevent event from bubbling up
    this.refererCopy(this.shareLinkUrl);
    return false;
  }
  // Share referral link
  refererCopy(data) {
    this.copyToClipboard(data)
    .then(() => {
      alert('Copied to clipboard');
    })
    .catch(err => {
      alert('Failed to copy to clipboard');
      console.error('Clipboard copy error: ', err);
    });
  }

  copyToClipboard(text: string): Promise<void> {
    return navigator.clipboard.writeText(text)
      .then(() => {
        LogUtils.debug('The share link copied to clipboard2: ', text);
      })
      .catch(err => {
        LogUtils.error('Failed to copy text to clipboard: ', err);
        throw err; // Optional: propagate the error further
      });
  }
}

@NgModule({
  declarations: [ReportActionComponent],
  imports: [
    CommonModule,
    UxTemplateModule,
    MiniButtonComponentModule,
    AddNoteComponentModule,
    SaveReportComponentModule,
    GetAlertsComponentModule,
    TutorialModalComponentModule,
  ],
  exports: [ReportActionComponent],
})
export class ReportActionComponentModule {}
