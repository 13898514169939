import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UxHelper } from '../../../../clientCommon/helper/uxHelper';
import { SignUpPeopleSearch } from '../../../sales/peopleSearch/signUpPeopleSearchSales';

declare var $: any;

@Component({
    selector: 'app-signup-pii-reverse-phone',
    templateUrl: './signup-pii-reverse-phone.component.html',
    styleUrls: ['./signup-pii-reverse-phone.component.scss'],
    standalone: false
})
export class SignupPIIComponentReversePhone implements OnInit {

  REPORTING_STEPS = {
    'step1_in': '1_selection_in',
    'step1_out': '1_selection_out',
    'step2_in_email': '2_email_in',
    'step2_in_phone': '2_phone_in',
    'step2_out_email': '2_email_out',
    'step2_out_phone': '2_phone_out',
    'step3_in': '3_password_in',
    'step3_out': '3_password_out',
    'step4_in_email': '4_phone_in',
    'step4_out_email': '4_phone_out',
    'step4_in_phone': '4_email_in',
    'step4_out_phone': '4_email_out',
    'step5_in': '5_name_in',
    'step5_out': '5_name_out',
    'step6_in': '6_billing_in',
    'step6_out': '6_billing_out',
  }

  MODES = {
    'option1':1,
    'option2':2,
    'option3':3,
  }

  STEPS = {
    'step1':1,
    'step2':2,
    'step3':3,
    'step4':4,
    'step5':5,
    'step6':6,
  }

  mode: number;
  passwordPattern = "(?=.*?[a-z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?).{8,}$";
  stepOne: any = {isSubmitted: false};
  selectedStep = 1;
  selection = '';
  cityState = "";
  progresses = [0, 12, 25, 50, 75, 95];
  password = "";
  confirmPassword = "";
  passwordValidation = {
      message: "",
      cfrmMessage: "",
      isPasswordValid: false,
      isValid:false,
      isSubmitted: false,
      isMatching: true
    };
  isBothPasswordMatch = false;
  visiblePassword: boolean = false;
  @Input() base: SignUpPeopleSearch;
  @Input() uxHelper: UxHelper;
  @Input() pageType: string;
  @Input() pageCategory: string;
  @Input() page: string;
  @ViewChild('emailForm', {static: true}) emailForm:NgForm;


  constructor() {

  }

  init() {
    this.cityState = this.base.commerceContent?.raws[0]?.meta?.cityState;
    this.mode = this.base.uxHelper.getUxcomp('pii.mode'); // => pii.mode
    setTimeout(() => {
      this.addStepTracking(true);
    }, 1000);
    // setTimeout(function () {
    //   $('.carousel').carousel({
    //     interval: 6000,
    //     cycle: true
    //   });
    //   $('[data-toggle="popover"]').popover();
    // }, 2000);
  }

  ngOnInit() {
    this.base.getPillStepAsObservable().subscribe((step) => {
      if (step != -1) {
        this.selection = 'email';
        this.selectedStep = this.STEPS.step2;

        this.base.userInfo.email = '';
        this.base.validateEmailCustom('');
        this.base.setPillStep(-1);
      }
    });
    return this.init();
  }

  ngOnChanges() {
    return this.init();
  }

  onNextStep(step) {
    this.addStepTracking();
    if(this.selectedStep == 1 && this.selection == '') { return; }
    if(this.selectedStep == 3) {
      this.base.userInfo.password = this.password;
    }
    step++;
    this.selectedStep++;
  }

  isNoMatchEnabled():boolean {
    return this.base.uxComposite.getUxcomp('comp.reverse-phone.no-match.enabled');
  }

  get brandCustomerPhone():string {
    return this.base.uxComposite.getUxcomp('comp.brand.customer.phone');
  }

  get trialExpireDate():string {
    return this.base.uxComposite.values.code.offers.main[0].tempClientSecured.billingDatesString[1];
  }

  get monthlyCharges():number {
    return this.base.uxComposite.values.code.offers.main[0].tempClientSecured.prices[1][0].amount;
  }

  get timePeriod():string {
    return this.base.uxComposite.values.code.offers.main[0].tempClientSecured.prices[1][0].periodString;
  }

  checkPasswordValidation() {
    if(this.password == '') {
      this.passwordValidation.isValid = false;
      this.passwordValidation.message = 'Please enter a password';
      return;
    }

    const regex = new RegExp(/^(?=.*?[a-z])(?=.*?[0-9])(?=.*?).{8,}$/);
    let isOk = regex.test(this.password);

    if(!isOk) {
      this.passwordValidation.isValid = false;
      this.passwordValidation.isPasswordValid = false;
      this.passwordValidation.cfrmMessage = 'Please enter a valid password';
      return ;
    }

    this.passwordValidation.isPasswordValid = true;
    if(isOk && this.confirmPassword == '') {
      this.passwordValidation.isValid = false;
      this.passwordValidation.isMatching = false;
      this.passwordValidation.message = 'Please re enter a password';
      return ;
    }

    if(isOk && this.confirmPassword != this.password) {
      this.passwordValidation.isValid = false;
      this.passwordValidation.message = 'Please enter the same password twice';
      this.passwordValidation.isMatching = false;
      return;
    }



    this.passwordValidation.isValid = true;
    this.passwordValidation.isMatching = true;
    this.passwordValidation.message = "";
    return;
  }

  onPrevStep() {this.selectedStep--;}

  addStepTracking(trackOnlyIn = false) {
    const outStep = this.selectedStep;
    const inStep = trackOnlyIn ? this.selectedStep : this.selectedStep + 1;
    const selectedOutStep = outStep == 2 || outStep == 4
      ? this.REPORTING_STEPS[`step${outStep}_out_${this.selection}`]
      : this.REPORTING_STEPS[`step${outStep}_out`];

    const params = {
      location: window?.location?.pathname,
      type: 'pii'
    }

    if (inStep < 7) {
      this.base.trackPIISignupSteps({
        ...params, 
        step: inStep == 2 || inStep == 4
          ? this.REPORTING_STEPS[`step${inStep}_in_${this.selection}`]
          : this.REPORTING_STEPS[`step${inStep}_in`]
      });
    }

    if(!trackOnlyIn) {
      this.base.trackPIISignupSteps({
        ...params, 
        step: outStep == 2 || outStep == 4
          ? this.REPORTING_STEPS[`step${outStep}_out_${this.selection}`]
          : this.REPORTING_STEPS[`step${outStep}_out`]
      });
    }

    this.base.serviceHelperService.trackingService.privacyPolicyAgreeReportPii({
      uxHelper: this.uxHelper,
      REPORTING_STEPS: this.REPORTING_STEPS,
      piiStep: selectedOutStep,
      email: this.base.userInfo?.email,
      phone: this.base.userInfo?.phone,
      billingCompKeys: [
        this.uxHelper.getUxcompKey('checkbox.term'),
        this.uxHelper.getUxcompKey('billing.disclaimer'),
        this.uxHelper.getUxcompKey('submit.button.text'),
      ],
    });
  }
}
