import { Component, Input, NgModule } from '@angular/core';
import { Format } from '../../types';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ux-5-2-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss']
})
export class ListItemComponent {
  @Input() text: string = null;
  @Input() format: Format = null;
  @Input() image: string;
}

// @NgModule({
//   declarations: [ListItemComponent],
//   imports: [CommonModule],
//   exports: [ListItemComponent],
// })
// export class ListItemComponentModule {}
