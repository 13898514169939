import {CollectionClass} from "../decorators/database/collectionClass";
import {ModelBase} from "./modelBase";
import {IndexField} from "../decorators/database/indexField";
import {isObject} from "util";

@CollectionClass({name: "progressors", revisionEnabled: false, draftEnabled: false})
export class Progressor extends ModelBase<Progressor> {
  public static ACTION = {
    started: "started",
    ended: "ended",
  };

  public static INPUT_KEY = {
    progress: "progress",
  };

  startTimestamp: number;
  progresses: { name: string, duration: number, delay: number, timeout: number, weights: number[], data: any, tempClientDone?: boolean }[] = [];

  @IndexField() referenceId: string;
  referenceCollection: string;

  constructor(doc?, draftFlag?: boolean) {
    super(doc, draftFlag);
    this.init(doc, this.draftFlag);
  }

  getSecuredDoc(): Progressor {
    var obj: Progressor = super.getSecuredDoc();
    return obj;
  }

  getCurrentProgress() {
    let currentProgress;
    let currentIndex = 0;
    if (this.inputs && this.progresses) {

      this.progresses.forEach((progress) => {
        progress.tempClientDone = false;
      });

      this.inputs.forEach((input) => {
        if (isObject(input) &&
          input.key === Progressor.INPUT_KEY.progress &&
          (
            input.action === Progressor.ACTION.started ||
            input.action === Progressor.ACTION.ended)
        ) {
          this.progresses.some((progress, index) => {
            if (currentIndex <= index && progress.name === input.name) {
              if (input.action === Progressor.ACTION.ended) {
                if (!currentProgress) {
                  currentProgress = progress;
                }
                currentProgress.tempClientDone = true;
              }

              if (input.action === Progressor.ACTION.ended && this.progresses[index + 1]) {
                currentProgress = this.progresses[index + 1];
                currentIndex = index + 1;
              } else {
                currentProgress = progress;
                currentIndex = index;
              }
              return true;
            }
          });
        }
      });
    }

    return currentProgress;
  }

  calculateTotalDuration() {
    let duration = 0;
    this.progresses.forEach((progress) => {
      if (progress.duration > 0) {
        duration += progress.duration;
      }
    });

    return duration;
  }

  caluclateFinishedDuration() {
    let duration = 0;
    let currentProgress = this.getCurrentProgress();
    if (currentProgress) {
      this.progresses.some((progress) => {
        if (progress.name === currentProgress.name) {
          if (progress.tempClientDone) {
            duration += progress.duration;
          }

          return true;
        } else {
          if (progress.duration > 0) {
            duration += progress.duration;
          }
        }
      });
    }

    return duration;
  }

  getProgressInput(action, name) {
    let foundInput;
    this.inputs.some((input) => {
      if (isObject(input) &&
        input.key === Progressor.INPUT_KEY.progress &&
        input.action === action &&
        input.name === name) {
        foundInput = input;
        return true;
      }
    });
    return foundInput;
  }

  removeProgressInput(prefix) {
    this.inputs.forEach((input) => {
      if (isObject(input) &&
        input.key === Progressor.INPUT_KEY.progress && input.name &&
        input.name.startsWith(prefix)) {
        input.name = "x-" + input.name;
      }
    });
  }

}

