import {UxLayout} from "./uxLayout";
import {UxComponent} from "./uxComponent";
import {CollectionClass} from "../../decorators/database/collectionClass";
import {TemplateEngine} from "../../utils/templateEngine";
import {ModelBase} from "../modelBase";
import {LogUtils} from "../../utils/logUtils";
import {ModelRevision} from "../modelRevision";
import {objectUtils} from "../../utils/objectUtils";
import {isArray} from "util";
import {UxConfig} from "./uxConfig";

/**
 * Processed UXLayout
 */
@CollectionClass({name: "uxComposites"})
export class UxComposite {
  payeeId: string;
  uxConfigId: string;
  uxConfigName: string;
  uxConfigDesc: string;
  uxConfigStatus: string;
  uxLayoutId: string;
  uxLayoutName: string;
  uxLayoutDesc: string;
  uxLayoutStatus: string;
  brandId: string;
  brandName: string;
  brandDomain: string;
  serverType: string;

  timestamp: number;
  requestTimestamp: number;
  clientTimestamp: number;
  requestZip: string;
  requestState: string;
  requestCity: string;

  values: any = {code: {}};
  types: any = {comp: {}};
  keys: any = {comp: {}};
  serverOnlykeys = {code: [], comp: []};
  revisions: any = {};
  ids: any = {};
  device: string;
  ip: string;
  admin: boolean;
  postProcessed = false;

  constructor(doc?) {
    Object.assign(<any>this, doc);
  }

  getSecuredDoc(): UxComposite {
    let obj: UxComposite = objectUtils.clone(this);

    obj.uxConfigDesc = undefined;
    obj.uxLayoutDesc = undefined;

    if (!this.isAdmin()) {
      if (obj.serverOnlykeys) {
        if (isArray(obj.serverOnlykeys.comp)) {
          obj.serverOnlykeys.comp.forEach((key:string) => {
            obj.values[key] = undefined;
            obj.revisions[key] = undefined;
            obj.ids[key] = undefined;
            obj.types[key] = undefined;
          });
        }
      }

      try {
        for (let valuesKey in obj.values) {
          if (valuesKey.startsWith("comp.admin.") || valuesKey.startsWith("code.admin.")) {
            obj.values[valuesKey] = undefined;
            obj.revisions[valuesKey] = undefined;
            obj.ids[valuesKey] = undefined;
            obj.types[valuesKey] = undefined;
          }
        }
        obj.values.code.admin = undefined;
        obj.keys.comp.admin = undefined;
      } catch (e) {
        LogUtils.error("FIX_THIS!. UxComposite.getSecuredDoc", e);
      }

      obj.serverOnlykeys = undefined;
    } else {

    }
    return obj;
  }

  setTimestamps(manualTimestamp?: number) {
    this.timestamp = manualTimestamp 
      ? manualTimestamp
      : new Date().getTime();
  }

  public setUxcUxl(uxConfig: UxConfig, uxLayout: UxLayout) {
    this.uxConfigId = uxConfig._id;
    this.uxConfigName = uxConfig.name;
    this.uxConfigDesc = uxConfig.description;
    this.uxConfigStatus = uxConfig.status;


    this.uxLayoutId = uxLayout._id;
    this.uxLayoutName = uxLayout.name;
    this.uxLayoutDesc = uxLayout.description;
    this.uxLayoutStatus = uxLayout.status;

    this.setCode("uxcId", uxConfig._id);
    this.setCode("uxlId", uxLayout._id);
    this.setCode("uxcName", uxConfig.name);
    this.setCode("uxlName", uxLayout.name);
  }

  public pushUxcomp(uxcomp: UxComponent, force) {
    if (uxcomp.status === ModelBase.STATUS.active && (force || (!this.values["comp." + uxcomp.name]))) {
      this.values["comp." + uxcomp.name] = uxcomp.content;
      this.types["comp." + uxcomp.name] = uxcomp.type;
      this.revisions["comp." + uxcomp.name] = uxcomp.currentModelRevisionId;
      this.ids["comp." + uxcomp.name] = uxcomp._id;
      if (uxcomp.serverOnly) {
        this.serverOnlykeys.comp.push("comp." + uxcomp.name);
      }

      let keys = this.keys.comp;
      uxcomp.name.split('.').forEach((key) => {
        if (!keys[key]) {
          keys[key] = {};
        }

        keys = keys[key];
      });
      keys['$'] = true;
    }
  }

  public setCode(key, value) {
    let values = this.values.code;
    let keyArray = key.split('.');
    keyArray.forEach((key, index) => {
      if (!values[key]) {
        values[key] = {};
      }

      if ((index + 1) === keyArray.length) {
        if (value instanceof ModelBase || value instanceof ModelRevision) {
          values[key] = value.getClientDoc();
        } else {
          values[key] = value;
        }
      } else {
        values = values[key];
      }
    });
  }

  private traverseValues(key) {
    try {
      var values = this.values;
      key.split('.').some((key) => {
        if (values) {
          values = values[key];
        } else {
          values = null;
          return true;
        }
      });

      if (values && values['$value']) {
        return values['$value'];
      } else {
        return values;
      }
    } catch (e) {
      LogUtils.error(e, key);
      return "";
    }
  }

  public traverseKeys(key) {
    try {
      var keys = this.keys;
      key.split('.').some((key) => {
        if (keys) {
          keys = keys[key];
        } else {
          keys = null;
          return true;
        }
      });

      return keys;
    } catch (e) {
      LogUtils.error(e, key);
      return null;
    }
  }

  public isUxcomp(key): boolean {
    return key && key.startsWith("comp.");
  }

  public raw(key: string) {
    var value = "";
    if (this.isUxcomp(key)) {
      value = this.values[key];
    } else {
      return this.traverseValues(key);
    }

    return value;
  }

  public get(key: string): any {
    if (key) {
      if (this.isUxcomp(key)) {
        return this.getUxcomp(key);
      } else {
        return this.traverseValues(key);
      }
    }
  }

  public getUxcomp(key: string, throwFlag = false): any {
    

    let rendered = null;
    if (this.values[key]) {
      rendered = this.render(this.values[key]);
    }

    if (this.types[key] === UxComponent.TYPE.JSON) {
      try {
        rendered = JSON.parse(rendered);
      } catch (e) {
        LogUtils.error("Uxcomp Render Error", this.uxConfigId, this.uxLayoutId, this.ids[key], key, rendered, e);
        if (throwFlag) {
          e.message = `Uxcomp render error. UXC:${this.uxConfigId} UXL:${this.uxLayoutId} ${key} ID:${this.ids[key]}. ` + e.message + ":" + rendered;
          throw e;
        }
        rendered = null;
      }
    } else {
      if (rendered === null || rendered === undefined) {
        rendered = "";
      }
    }

    return rendered;
  }

  private render(content: string): string {
    //LogUtils.debug("uxComposite render => ", content);
    return TemplateEngine(content, this.values);
  }

  isProdServer(): boolean {
    return this.serverType === "prod";
  }

  isStgServer(): boolean {
    return this.serverType === "stg";
  }

  isDevServer(): boolean {
    return !(this.isProdServer() || this.isStgServer());
  }

  getTimeDiff(): number {
    return this.clientTimestamp - this.requestTimestamp;
  }

  isDevicePhone() {
    return this.device === 'phone';
  }

  setAdmin(flag: boolean) {
    this.admin = flag;
  }

  isAdmin() {
    return !!this.admin;
  }
}

