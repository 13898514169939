import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgModule, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceHelperService } from '../../../../../../clientCommon/services/serviceHelper.service';
import { PersonDetail } from '../../../personDetail';
import { ToastrService } from '../../../../../service/toastr.service';
import { BaseComponent } from '../../../../../../clientCommon/components/BaseComponent';
import { phoneUtils } from '../../../../../../common/utils/phoneUtils';
import { AddressField, AddressUtils } from '../../../../../../common/utils/addressUtils';
import { peopleSearchProductKeys } from '../../../../../../common/custom/peopleSearch/peopleSearchProductKeys';
import { AuthenticationService, NoteService } from '../../../../../../clientCommon/services';
import { environment } from '../../../../../../environments/environment';
import { CommonModule } from '@angular/common';
import { MemberUpsellPremiumBadgeComponentModule } from '@member/components/upsells/premium-badge';
import { MiniReportHeaderModule } from '@member/peopleSearch/components/mini/header/header.component';
import { MemberUpsellAssociatesComponentModule } from '@member/components/upsells/associates';
import { MiniButtonComponentModule } from '@member/peopleSearch/components/mini/button/button.component';
import { ReportActionComponentModule } from '../reportAction/reportAction.component';
import { AdComponentModule } from 'app/components/ad/ad.component';
import { MiniReportNotesComponentModule } from '@member/peopleSearch/components/mini/notes/notes.component';
import { MiniReportAdProtectionComponentModule } from '@member/peopleSearch/components/mini/ad/ad-protection.component';
import { FactFindersComponentModule } from '../factFinders/factFinders.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { UxTemplateModule } from 'app/components/uxTemplate.component';
import { DirectionButtonComponentModule } from '../directionButton/directionButton.component';
import { stringUtils } from '../../../../../../common/utils/stringUtils';
import { MemberKeepSearchComponentModule } from '@member/components/peopleSearch/memberKeepSearch.component';
import { MiniReportFooterModule } from '@member/peopleSearch/components/mini';
import { MiniMiscellaneousBaseComponent } from './miniMiscellaneousBase.component';

declare var $: any;

@Component({
    selector: 'app-mini-miscellaneous',
    templateUrl: 'miniMiscellaneous.component.html',
    styleUrls: ['miniMiscellaneous.component.scss'],
    standalone: false
})
export class MiniMiscellaneousComponent extends MiniMiscellaneousBaseComponent implements OnInit, OnDestroy, AfterViewInit {
 
  constructor(
    public serviceHelperService: ServiceHelperService,
    public changeDetectorRef: ChangeDetectorRef,
    activatedRoute: ActivatedRoute,
    protected router: Router,
    public renderer: Renderer2,
    public toastr: ToastrService,
    public authService: AuthenticationService,
    public noteService: NoteService
  ) {
    super(serviceHelperService, changeDetectorRef, activatedRoute, router, renderer, toastr, authService, noteService);
  }

  ngOnInit() {
    return super.ngOnInit();
  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
  }
  ngOnDestroy() {
    return super.ngOnDestroy();
  }
}

@NgModule({
  declarations: [MiniMiscellaneousComponent],
  imports: [
    CommonModule,
    FormsModule,

    NgSelectModule,

    MemberUpsellPremiumBadgeComponentModule,
    MemberUpsellAssociatesComponentModule,
    MemberKeepSearchComponentModule,
    MiniReportFooterModule,
    
    MiniReportHeaderModule,
    MiniButtonComponentModule,
    MiniReportNotesComponentModule,
    ReportActionComponentModule,
    AdComponentModule,
    FactFindersComponentModule,
    DirectionButtonComponentModule,
    MiniReportAdProtectionComponentModule,
    UxTemplateModule,
  ],
  exports: [MiniMiscellaneousComponent],
})
export class MiniMiscellaneousComponentModule {}
