import { Component, Input } from "@angular/core";
import { SearchedFamilyWatchdog } from "@common/custom/models/peopleSearch/searchedFamilyWatchdog";


@Component({
    selector: 'app-mini-sex-offender-profile',
    templateUrl: 'sex-offender-profile.component.html',
    standalone: false
})
export class SexOffenderProfileComponent {
  @Input() offender: SearchedFamilyWatchdog;
  @Input() index: number;
}