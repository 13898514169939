import {SearchedObject} from "./searchedObject";

const hairMap = {
  "--": "Unknown",
  "Auburn": "Auburn",
  "BAL": "Bald",
  "Bald": "Bald",
  "Bald or Unknown": "Bald or Unknown",
  "Bald/Unknown": "Bald or Unknown",
  "Black": "Black",
  "Black/Gray": "Black or Grey",
  "BLD": "Blonde",
  "BLK": "Black",
  "BLN": "Blonde",
  "Blond": "Blonde",
  "Blond or Strawberry": "Blonde or Strawberry",
  "BLOND/STRAWBERRY": "Blonde or Strawberry",
  "Blonde": "Blonde",
  "BLONDE (STRAWBERRY)": "Strawberry Blonde",
  "BLONDE OR STRAWBERRY": "Blonde or Strawberry",
  "BLU": "Blue",
  "Blue": "Blue",
  "BRO": "Brown",
  "Brown": "Brown",
  "Brown/Gray": "Brown or Grey",
  "COMPLETELY BALD": "Bald",
  "Gray": "Grey",
  "Gray or Partially Gray": "Grey or Partially Grey",
  "Gray, Partially Gray": "Grey or Partially Grey",
  "GRAY/PARTIALLY": "Grey or Partially Grey",
  "GRAY/PARTIALLY GRAY": "Grey or Partially Grey",
  "Green": "Green",
  "Grey": "Grey",
  "Grey or partially grey": "Grey or Partially Grey",
  "GRN": "Green",
  "GRY": "Gray",
  "Mixed": "Mixed",
  "Mulit-Colored": "Multi-Colored",
  "Multi-colored": "Multi-Colored",
  "No Selection": "No Selection",
  "ONG": "Orange",
  "Orange": "Orange",
  "Other": "Other",
  "Pepper": "Pepper",
  "Pink": "Pink",
  "Purple": "Purple",
  "Red": "Red",
  "Red or Auburn": "Red or Auburn",
  "RED/AUBURN": "Red or Auburn",
  "Sandy": "Sandy Blonde",
  "SDY": "Sandy Blonde",
  "Strawberry": "Strawberry Blonde",
  "Unk, Completely Bald": "Unknown",
  "Unknown": "Unknown",
  "UNKNOWN OR BALD": "Unknown",
  "Unknown or Completely Bal": "Unknown",
  "Unknown Or Completely Bald": "Unknown",
  "Unknown/bald": "Unknown",
  "Unknown2": "Unknown",
  "WHI": "White",
  "White": "White",
  "XXX": "Unknown",
};

const eyesMap = {
  "--": "Unkown",
  "Black": "Black",
  "BLK": "Black",
  "BLU": "Blue",
  "Blue": "Blue",
  "BRO": "Brown",
  "Brown": "Brown",
  "Gray": "Gray",
  "Green": "Green",
  "GREY": "Gray",
  "GRN": "Green",
  "GRY": "Gray",
  "HAZ": "Hazel",
  "Hazel": "Hazel",
  "MAR": "Maroon",
  "Maroon": "Maroon",
  "MUL": "Multi-color",
  "Multi": "Multi-color",
  "MULTI-COLOR": "Multi-color",
  "Multi-Colored": "Multi-color",
  "MULTICOL": "Multi-color",
  "Multicolored": "Multi-color",
  "No Selection": "Unknown",
  "Other": "Other",
  "Pink": "Pink",
  "Red": "Red",
  "Unknown": "Unknown",
  "XXX": "Unknown",
};

export class SearchedFamilyWatchdog extends SearchedObject<SearchedFamilyWatchdog> {
  offenderId: string;

  photo: string;

  name: string;

  firstName: string;

  middleName: string;

  lastName: string;

  suffix: string;

  dob: string;

  age: string;

  sex: string;

  race: string;

  hair: string;

  eye: string;

  height: string;

  weight: string;

  street1: string;

  street2: string;

  city: string;

  state: string;

  zipcode: string;

  country: string;

  matchType: string;

  latitude: string;

  longitude: string;

  convictionType: string;

  possibleNicknames: string[];

  otherAddresses: {
    street1: string,
    street2: string,
    latitude: string,
    longitude: string,
  }[];

  get convictionTypeName() {
    switch (this.convictionType) {
      case '1':
      case '2':
        return 'Other Offense';
      case '3':
        return 'Sexual Battery';
      case '4':
        return 'Rape';
      case '5':
        return 'Offense Against Children';
      default:
        return 'N/A';
    }
  }

  get position(): {latitude: number, longitude: number} | null {
    if (!this.latitude || !this.longitude) {
      return null;
    }

    return {latitude: +this.latitude, longitude: +this.longitude};
  }

  static fromJson(json: any): SearchedFamilyWatchdog {
    const result = new SearchedFamilyWatchdog();

    result.offenderId = json['offenderid'] ?? json['offenderId'];
    result.photo = json['photo'];
    result.name = json['name'];
    result.firstName = json['firstname'] ?? json['firstName'];
    result.middleName = json['middlename'] ?? json['middleName'];
    result.lastName = json['lastname'] ?? json['lastName'];
    result.suffix = json['suffix'];
    result.dob = json['dob'];
    result.age = json['age'];
    result.sex = json['sex'];
    result.race = json['race'];
    result.hair = SearchedFamilyWatchdog.parseHair(json['hair']);
    result.eye = SearchedFamilyWatchdog.parseEyes(json['eye']);
    result.height = json['height'];
    result.weight = json['weight'];
    result.street1 = json['street1'];
    result.street2 = json['street2'];
    result.city = json['city'];
    result.state = json['state'];
    result.zipcode = json['zipcode'];
    result.country = json['country'];
    result.matchType = json['matchtype'] ?? json['matchType'];
    result.latitude = json['latitude'];
    result.longitude = json['longitude'];
    result.convictionType = json['convictiontype'] ?? json['convictionType'];
    result.possibleNicknames = SearchedFamilyWatchdog.parsePossibleNicknames(json['possiblenicknames'] ?? json['possibleNicknames']);
    result.otherAddresses = json['otheraddresses'] ?? json['otherAddresses'];

    return result;
  }

  private static parsePossibleNicknames(possibleNicknames) {
    if (!possibleNicknames?.length) {
      return [];
    }

    return possibleNicknames.map(e => e?.firstname ?? e).filter(e => !!e);
  }

  private static parseHair(hair) {
    if (!hair) {
      return 'Unknown';
    }

    return hairMap[hair] ?? hair;
  }

  private static parseEyes(eyes) {
    if (!eyes) {
      return 'Unknown';
    }

    return eyesMap[eyes] ?? eyes;
  }
}