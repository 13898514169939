import { Component, Input, NgModule, OnInit } from '@angular/core';
import { UxHelper } from '@clientCommon/helper/uxHelper';
import { SpinnerService } from '@clientCommon/services/spinner.service';
import { SignUpPeopleSearch } from '@sales/peopleSearch/signUpPeopleSearchSales';
import { MembershipType } from '@ux5.2/types';
import { LogUtils } from '@common/utils/logUtils';

interface BillingPlan {
  offerKey: string;
  billingSaleRuleKey: string;
  billingBinRuleKey: string;
  billingRetryRuleKey: string;
  emailCampaignKey: string;
}

interface BillingPlans {
  main1x: BillingPlan[];
  main2x: BillingPlan[];
  // casx and single plan billing
  main: BillingPlan[];
}

@Component({
  selector: 'ux-5-2-payment-submit',
  templateUrl: './payment-submit.component.html',
  styleUrls: ['./payment-submit.component.scss'],
})
export class PaymentSubmitComponent implements OnInit {
  constructor(
    private spinnerService: SpinnerService, 
  ) {}

  @Input() uxHelper: UxHelper;
  @Input() base: SignUpPeopleSearch;
  @Input() toggleLoader: () => void;
  @Input() plan: string = 'one_month';
  @Input() disableCasX = () => {};

  subscriptionMap: { [key in MembershipType]: string } = {
    one_month: 'main1x',
    two_month: 'main2x',
    casx: 'main',
    free_trial: 'main',
  };

  defaultPlans: BillingPlans = {
    main1x: [
      {
        offerKey: 'comp.sales.name-search.signup.main.1.offer',
        billingSaleRuleKey: 'comp.billing.sale.rule.default',
        billingBinRuleKey: 'comp.sales.name-search.signup.billing.bin.rule',
        billingRetryRuleKey: 'comp.billing.retry.rule.best-days',
        emailCampaignKey: 'sales.name-search.signup',
      },
    ],
    main2x: [
      {
        offerKey: 'comp.sales.name-search.signup.main.2.offer',
        billingSaleRuleKey: 'comp.billing.sale.rule.default',
        billingBinRuleKey: 'comp.sales.name-search.signup.billing.bin.rule',
        billingRetryRuleKey: 'comp.billing.retry.rule.best-days',
        emailCampaignKey: 'sales.name-search.signup',
      },
    ],
    main: [
      {
        offerKey: 'comp.sales.name-search.signup.main.1.offer',
        billingSaleRuleKey: 'comp.billing.sale.rule.default',
        billingBinRuleKey: 'comp.sales.name-search.signup.billing.bin.rule',
        billingRetryRuleKey: 'comp.billing.retry.rule.best-days',
        emailCampaignKey: 'sales.name-search.signup',
      },
    ],
  };

  buttonDisabled: boolean = false;
  billingCodes: BillingPlans;

  get billingOptions() {
    const uxcContent = this.uxHelper.getUxcomp('comp.sales.name-search.signup.offers.rules');
    return uxcContent ? JSON.parse(uxcContent) : this.defaultPlans;
  }

  get getPlan(): string {
    return this.subscriptionMap[this.plan];
  }

  get getOffer(): string {
    return this.billingCodes[this.getPlan][0].offerKey;
  }

  ngOnInit(): void {  
    this.billingCodes = this.billingOptions;
  }

  getIsSuperBad(): boolean {
    if (this.base.creditCardInputHelper.fullName == 'superbad') return true;
    return false;
  }

  getIsUserInfoValid = (): boolean =>
    this.base?.userInfo?.fname?.length > 0 &&
    this.base?.userInfo?.lname?.length > 0 &&
    this.base?.userInfo?.email?.length > 0;

  getIsPaymentInfoValid = (): boolean =>
    this.base.creditCardInputHelper.isValidCCCvv() &&
    this.base.creditCardInputHelper.isValidCCNumber() &&
    this.base.creditCardInputHelper.isValidFullName();

  getAreDisclosuresChecked = (): boolean =>
    this.base.creditCardInputHelper.termsOfUseChecked &&
    this.base.creditCardInputHelper.notConsumerReportingAgencyChecked;

  getIsAllInfoValid = (): boolean => this.getIsPaymentInfoValid() && this.getIsUserInfoValid();

  onSubmitButtonPressed = () => {
    this.disableCasX();
    if (this.getIsSuperBad()) {
      this.base.failureFlag = true;
      return;
    }

    this.base.setOffer(this.getPlan).then(() => {
      this.base.feedBackFlags[this.base.steps.billing] = true;
      if (this.getIsAllInfoValid()) {
        this.spinnerService.spin();
        this.buttonDisabled = true;
        this.base
          .goNextStep(this.base.steps.billing)
          .catch((err) => {
            LogUtils.error(err);
          })
          .finally(() => {
            this.spinnerService.unspin();
            this.buttonDisabled = false;
          });
      }
    });
  };
}
