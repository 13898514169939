import {CollectionClass} from "../../decorators/database/collectionClass";
import {CompositeClassField} from "../../decorators/database/compositeClassField";
import {ForeignKeyField} from "../../decorators/database/foreignKeyField";
import {IndexField} from "../../decorators/database/indexField";
import {ServerField} from "../../decorators/database/serverField";
import {timeUtils} from "../../utils/timeUtils";
import {ModelBase} from "../modelBase";
import {User} from "../user/user";
import {CommerceAddress} from "./commerceAddress";
import {CommerceBillingRouting} from "./commerceBillingRouting";

@CollectionClass({name: "commerceTokens"})
export class CommerceToken extends ModelBase<CommerceToken> {

  public static SUB_STATUS = {
    none: "none",
    chargeback: "chargeback",
    superbad: "superbad",
  };

  @IndexField() @ServerField hash: string;
  @IndexField() declare subStatus: string;
  @IndexField() @ServerField externalId: string;
  @IndexField() last4: string;
  @IndexField() gateway: string;
  @IndexField() bin: string;
  @IndexField() @ForeignKeyField(User) payerId: string;
  length: number;
  expiration: { year: string, month: string };
  billingAddress: CommerceAddress = new CommerceAddress();
  ipAddress: string;

  @CompositeClassField(CommerceBillingRouting) commerceBillingRouting: CommerceBillingRouting;

  @ServerField cau: {
    id: string,
    status?: string,
    timestamp?: number;
    response?: string,
    update?: {
      cc: boolean,
      exp: boolean,
    },
    oldInfo?: {
      bin: string,
      last4: string,
      expiration: {
        year,
        month,
      }
    },
  };

  constructor(doc?, draftFlag?: boolean) {
    super(doc, draftFlag);
    this.init(doc, this.draftFlag);
  }

  getSecuredDoc(): CommerceToken {
    let obj: CommerceToken = super.getSecuredDoc();

    obj.bin = this.bin;
    obj.last4 = this.last4;
    obj.length = this.length;
    obj.expiration = this.expiration;

    return obj;
  }

  getExp4Digits() {
    if (this.expiration) {
      return timeUtils.getExp4Digits(this.expiration.year, this.expiration.month);
    }
  }

  getFirstName() {
    let name = "";
    if (this.billingAddress && this.billingAddress.firstName) {
      name = this.billingAddress.firstName;
    }

    return name;
  }

  getLastName() {
    let lastName = "";
    if (this.billingAddress && this.billingAddress.lastName) {
      lastName = this.billingAddress.lastName;
    }

    return lastName;
  }

  getFullName() {
    let fullName = "";
    if (this.billingAddress) {
      if (this.billingAddress.firstName) {
        fullName += this.billingAddress.firstName;
      }
      if (this.billingAddress.lastName) {
        fullName += " " + this.billingAddress.lastName;
      }
    }

    return fullName;
  }

  getHashableInfo() {
    let info = "";

    info += this.bin;
    info += this.last4;
    info += this.getLastName();
    return info;
  }

  isQuadZero() {
    return this.getExp4Digits() === "0000";
  }

  isExpired() {
    let date = new Date();

    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let current4Digit = timeUtils.getExp4Digits(year, month);
    let currentYearMonth = timeUtils.getYearMonthFromExp4digit(current4Digit);
    let expYearMonth = timeUtils.getYearMonthFromExp4digit(this.getExp4Digits()); // Doing this in case there are string value in expdate such as quadzero
    let expiredFlag = (expYearMonth.year * 100 + expYearMonth.month) < (currentYearMonth.year * 100 + currentYearMonth.month);

    return !this.isQuadZero() && expiredFlag;
  }
}
