import {Component, Input, OnChanges, OnInit} from "@angular/core";
import {UxHelper} from "../../../../../clientCommon/helper/uxHelper";
import {UxComposite} from "../../../../../common/models/ux/uxComposite";
import {SignUpPeopleSearch} from "../../../../sales/peopleSearch/signUpPeopleSearchSales";

declare var $: any;

@Component({
  selector: 'default-1-2-signup-name-search',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
})
export class Default1Dot2SignupNameSearchComponent implements OnInit, OnChanges {
  @Input() base: SignUpPeopleSearch;

  @Input() uxHelper: UxHelper;
  @Input() pageType: string;
  @Input() pageCategory: string;
  @Input() page: string;
  public personFullName = "";
  officePhone: string;

  constructor() {
  }

  init() {
    setTimeout(function () {
      $('.carousel').carousel({
        interval: 6000,
        cycle: true
      });
      $('[data-toggle="popover"]').popover();
    }, 2000);
    this.officePhone = this.uxHelper.uxComposite.get("comp.brand.customer.phone");
  }

  ngOnInit() {
    this.personFullName  = this.base.userInfo.fname + " " + this.base.userInfo.lname;
    
    return this.init();
  }

  ngOnChanges() {
    return this.init();
  }
}
