import { Component, Input, NgModule, OnChanges, OnInit } from "@angular/core";
import { UxComposite } from "../../common/models/ux/uxComposite";
import { UxcService } from "../../clientCommon/services/uxc.service";
import { LogUtils } from "../../common/utils/logUtils";
import { htmlUtils } from "../../clientCommon/utils/htmlUtils";
import { UxHelper } from "../../clientCommon/helper/uxHelper";

@Component({
  selector: "uxtemplate",
  template:
    '<div [id]="id" [class]="tagClass" [attr.data-key]="key" [attr.data-id]="uxHelper?.uxComposite?.ids[key]"></div>',
})

/**
 * @fileOverview UxTemplate Component will:
 *  - first load uxcomposite
 *  - get value(HTML Content) from uxcomposite based on the key it got as input from parent component
 *  - applyTemplate(): replace the inner html of current empty div by css id with html content from  uxcomposite
 */
export class UxTemplate implements OnInit, OnChanges {
  private static count = 0;
  id;
  html = "";

  @Input() uxHelper: UxHelper;
  @Input() key: string;
  @Input() tagClass = "";
  @Input() property;

  constructor(private uxcService: UxcService) {
    UxTemplate.count++;
    this.id = `ux-${UxTemplate.count}`;
  }

  ngOnInit() {
    // this make sure uxcomposite loaded
    this.uxcService
      .getUxComposite()
      .then((uxComposite: UxComposite) => {
        this.uxHelper.uxComposite = uxComposite;
        this.applyTemplate();
      })
      .catch((e) => {
        LogUtils.error(e);
      });
  }

  // get value(HTML Content) from uxcomposite based on the key we're getting as input from parent component
  getValue() {
    let value;
    let uxComposite;

    if (this.uxHelper && this.uxHelper.hasUxComposite()) {
      uxComposite = this.uxHelper.uxComposite;
    }

    // Loading
    if (uxComposite) {
      value = uxComposite.get(this.key);
      try {
        if (this.property) {
          this.property.split(".").forEach((key) => {
            value = value[key];
          });
        }
      } catch (e) {
        LogUtils.error(e);
      }
    }

    if (value === null || value === undefined) {
      value = "";
    }

    return value;
  }
  // replace the inner html of current empty div by css id with html content from uxcomposite
  applyTemplate() {
    if (this.key) {
      this.html = this.getValue();
      htmlUtils.replaceInnerHtmlById(this.id, this.html);
    }
  }

  ngOnChanges() {
    this.applyTemplate();
  }
}

@NgModule({
  declarations: [UxTemplate],
  exports: [UxTemplate],
})
export class UxTemplateModule {}
