//import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';

@Component({
  selector: 'ux-5-2-vector',
  templateUrl: './vector.component.html',
  styleUrls: ['./vector.component.scss']
})
export class VectorComponent {
  @Input() class: Array<string> = [];
  @Input() vectorName: string
}

// @NgModule({
//   declarations: [VectorComponent],
//   imports: [CommonModule],
//   exports: [VectorComponent],
// })
// export class VectorComponentModule {}
