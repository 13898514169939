import {CollectionClass} from "../../decorators/database/collectionClass";
import {ModelBase} from "../modelBase";
import {IndexField} from "../../decorators/database/indexField";

export class CommerceStockDetail {
  commerceStockId: string;
  commerceStockRevisionId?: string;
  quantity: number;
  period: {
    quantity: number,
    unit: "d" | "w" | "m" | "y",
  };
}

@CollectionClass({name: "commerceStocks"})
export class CommerceStock extends ModelBase<CommerceStock> {

  @IndexField() sku: string;
  @IndexField() name: string;
  description: string;

  digital: boolean = true;
  unlimited: boolean = true;

  stockCount: number;
  soldCount: number = 0;

  constructor(doc?, draftFlag?: boolean) {
    super(doc, draftFlag);
    this.init(doc, this.draftFlag);
  }

  hasEnoughStock(quantity: number): boolean {
    return this.stockCount >= quantity;
  }

  getSecuredDoc() {
    var obj: CommerceStock = super.getSecuredDoc();

    obj.digital = this.digital;
    obj.unlimited = this.unlimited;
    obj.name = this.name;
    obj.stockCount = this.stockCount;

    return obj;
  }
}

