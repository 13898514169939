import {IndexField} from "../../decorators/database/indexField";
import {ModelBase} from "../modelBase";
import {ForeignKeyField} from "../../decorators/database/foreignKeyField";
import {UxComponentCollection} from "./uxComponentCollection";
import {ReferenceField} from "../../decorators/database/referenceField";
import {ForeignObjectField} from "../../decorators/database/foreignObjectField";
import {LogUtils} from "../../utils/logUtils";
import {CollectionClass} from "../../decorators/database/collectionClass";
import {TransientField} from "../../decorators/database/transientField";

@CollectionClass({name: "uxLayouts"})
export class UxLayout extends ModelBase<UxLayout> {
    @IndexField()
    public name;
    public description;

    @ReferenceField(UxComponentCollection)
    @ForeignKeyField(UxComponentCollection)
    public uxComponentCollectionIds: string[] = [];


    @TransientField
    @ForeignObjectField("uxComponentCollectionIds")
    public uxComponentCollections: UxComponentCollection[];

    massId: string;

    constructor(doc?, draftFlag?: boolean) {
        super(doc, draftFlag);
        this.init(doc, this.draftFlag);
    }

    getUxCCById(id: string): UxComponentCollection {
        let obj = null;
        try {
            if (this.uxComponentCollections) {
                this.uxComponentCollections.some((obj2) => {
                    if (obj2._id === id) {
                        obj = obj2;
                        return true;
                    }
                });
            }
        } catch (e) {
            LogUtils.error(e, this);
        }

        return obj;
    }
}

