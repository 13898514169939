import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';

@Component({
  selector: 'member-upsell-household-features',
  templateUrl: './household-features.component.html',
})
export class MemberUpsellHouseholdFeaturesComponent {}

@NgModule({
  declarations: [MemberUpsellHouseholdFeaturesComponent],
  exports: [MemberUpsellHouseholdFeaturesComponent],
})
export class MemberUpsellHouseholdFeaturesComponentModule {}
