import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';

@Component({
  selector: 'ux-5-2-casx-alert',
  template: `
    <div class="container">
      <div class="image-section">
        <img class="shape" src="assets/ux5.1/alert/alert-img.png" />
      </div>
      <div class="content-section">
        <p>7 Day Trial Membership</p>
      </div>
      <div class="price-section">
        <p>$1.00*</p>
      </div>
    </div>
  `,
  styleUrls: ['./casx-alert.component.scss'],
})
export class CasxAlertComponent {}

// @NgModule({
//   declarations: [CasxAlertComponent],
//   imports: [CommonModule],
//   exports: [CasxAlertComponent],
// })
// export class CasxAlertComponentModule {}