import { Component, NgModule } from '@angular/core';

@Component({
  selector: 'member-upsell-neighbor-features',
  templateUrl: './neighbor-features.component.html',
})
export class MemberUpsellNeighborFeaturesComponent {}

@NgModule({
  declarations: [MemberUpsellNeighborFeaturesComponent],
  exports: [MemberUpsellNeighborFeaturesComponent],
})
export class MemberUpsellNeighborFeaturesComponentModule {}
