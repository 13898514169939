import { Component, Input, NgModule, OnInit } from '@angular/core';
import { UxHelper } from '@clientCommon/helper/uxHelper';
import { SignUpPeopleSearch } from '@sales/peopleSearch/signUpPeopleSearchSales';
import { MembershipType } from '@ux5.2/types';

export interface membershipType {
  days: string;
  price: string;
  billingDate?: string;
}

@Component({
  selector: 'ux-5-2-payment-disclosure',
  templateUrl: './payment-disclosure.component.html',
  styleUrls: ['./payment-disclosure.component.scss'],
})
export class PaymentDisclosureComponent implements OnInit {
  @Input() uxHelper: UxHelper;
  @Input() base: SignUpPeopleSearch;
  @Input() membership: MembershipType = 'one_month';
  @Input() isCasX: boolean = false;
  @Input() name: string = '';

  onClickTermsOfUse() {
    this.base.creditCardInputHelper.toggleTermsOfUse();
  }
  onClickNotConsumerReportingDisclosure() {
    this.base.creditCardInputHelper.toggleConsumerReportingDisclosure();
  }

  constructor() {}

  checkMembership(): membershipType {
    if (this.isCasX) return { days: '30', price: '39.99', billingDate: this.getDate(7) };
    if (this.membership === 'one_month') return { days: '30', price: '29.55', billingDate: this.getDate(30) };
    return { days: '60', price: '50.24', billingDate: this.getDate(60) };
  }

  getDate(days: number) {
    const today = new Date();
    today.setDate(today.getDate() + days);
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();
    return `${month}/${day}/${year}`;
  }

  ngOnInit(): void {
    if (this.isCasX) {
      this.onClickTermsOfUse();
      this.onClickNotConsumerReportingDisclosure();
    }
  }
}
