import {CollectionClass} from "../decorators/database/collectionClass";
import {IndexField} from "../decorators/database/indexField";
import {ModelBase} from "./modelBase";

@CollectionClass({name: "timesheet"})
export class TimeSheet extends ModelBase<TimeSheet> {
  public static TYPE = {
    clock_in: 'clock_in',
    clock_out: 'clock_out',
  };

  timestamp: number;
  note: string;
  @IndexField({unique: true}) public uniqueId: string;

  constructor(doc?, draftFlag?: boolean) {
    super(doc, draftFlag);
    this.init(doc, this.draftFlag);
  }

  createUniqueId(userId: string) {
    let id = "";
    id += "|" + userId;
    id += "|" + this.draft.timestamp;

    this.uniqueId = id;
  }

  getSecuredDoc(): TimeSheet {
    var obj: TimeSheet = super.getSecuredDoc();
    obj.note = this.note;
    obj.timestamp = this.timestamp;
    obj.type = this.type;
    obj.ownerId = this.ownerId;
    obj.draft = this;

    return obj;
  }
}

