import {CollectionClass} from "../decorators/database/collectionClass";
import {ModelBase} from "./modelBase";
import {IndexField} from "../decorators/database/indexField";

@CollectionClass({name: "cdpchargebacks"})
export class CdpChargeback extends ModelBase<CdpChargeback> {

  @IndexField() referenceId: string;
  caseId: string;
  referenceCollection: string;
  output: string;
  emailOutput: string;

  constructor(doc?, draftFlag?: boolean) {
    super(doc, draftFlag);
    this.init(doc, this.draftFlag);
  }

  getSecuredDoc(): CdpChargeback {
    var obj: CdpChargeback = super.getSecuredDoc();
    obj.output = this.output;
    obj.emailOutput = this.emailOutput;
    obj.referenceId = this.referenceId;
    obj.referenceCollection = this.referenceCollection;
    obj.caseId = this.caseId;
    obj.type = this.type;

    return obj;
  }

}
