import {Injectable} from "@angular/core";

@Injectable()
export class CookieService {

    constructor() {
    }

    /**
     *
     * @param name against which cookie data is stored
     * @returns data stored in cookie against the provided name
     */
    getCookie(name: string) {
        let ca: Array<string> = document.cookie.split(';');
        let caLen: number = ca.length;
        let cookieName = `${name}=`;
        let c: string;

        for (let i: number = 0; i < caLen; i += 1) {
            c = ca[i].replace(/^\s+/g, '');
            if (c.indexOf(cookieName) == 0) {
                return c.substring(cookieName.length, c.length);
            }
        }
        return '';
    }

    /**
     *
     * @param name against which cookie needed to be deleted
     */
    deleteCookie(name) {
        this.setCookie(name, '', -1);
    }

    /**
     *
     * @param name against which data needed to be stored in the cookie
     * @param value contain the data need to be stored in cookie
     * @param expireDays for the cookie
     * @param path sring path for the cookie
     */
    setCookie(name: string, value: string, expireDays: number, path: string = '/') {
        let d: Date = new Date();
        d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
        let expires: string = expireDays ? `expires=${d.toUTCString()}` : '';
        let cpath: string = path ? `; path=${path}` : '';
        let cookie = `${name}=${value}; ${expires}${cpath}`;
        document.cookie = cookie;
    }
}
