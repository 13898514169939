import {Component, Input, OnInit, ViewChild, ViewContainerRef} from "@angular/core";
import {ActivatedRoute} from "@angular/router";

import {BaseDirective} from "../../../clientCommon/directives/BaseDirective";
import {UxHelper} from "../../../clientCommon/helper/uxHelper";
import {ServiceHelperService} from "../../../clientCommon/services/serviceHelper.service";
import {BaseComponent} from "../../../clientCommon/components/BaseComponent";
import { CustomBaseComponent } from "../../../clientCommon/custom/customBaseComponent";

@Component({
    selector: 'app-sales-header',
    templateUrl: 'salesHeader.component.html',
    standalone: false
})
/**
 *  @fileOverview SalesHeaderComponent will first check the uxComposite and render the respective theme content based on current uxComp theme & it's brandtype
 *
 *  @extends BaseDirective to get the required custom properties methods and base directives
 *
 *  @property uxHelper is an input received from the parent view component, it will be used to get the theme and render html content according to-
 *  it and render different html content blocks via uxTemplate child component based on UxComp key
 *
 */
export class SalesHeaderComponent extends BaseDirective implements OnInit {
  @Input() uxHelper: UxHelper;
  @Input() options: any = {};
  isReversePhone = false;

  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;

/**
 *
 * @param serviceHelperService will provide access to different required services such as authentication service, uxc service etc.
 * @parma activatedRoute provides access to information about a route associated with a component that is loaded in an outlet
 *
 */
  constructor(serviceHelperService: ServiceHelperService,
              activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
  }

  ngOnInit() {
    this.isReversePhone = this.uxHelper?.pageCategory === CustomBaseComponent.PAGE_CATOGORY.phoneSearch;
    return super.baseInit().then(() => {
      return this.loadComponent();
    });
  }

/**
 *
 *  Checks to not show login button on the header for certian pages
 */
  shouldShowLogin() {
    let flag = true;
    if (this.uxHelper.pageType === BaseComponent.PAGE_TYPE.member ||
      this.uxHelper.page === BaseComponent.PAGE.loader ||
      this.uxHelper.page === BaseComponent.PAGE.searchResult ||
      this.uxHelper.page === BaseComponent.PAGE.reportReview ||
      this.uxHelper.page === BaseComponent.PAGE.signup ||
      this.uxHelper.page === BaseComponent.PAGE.addon ||
      this.uxHelper.page === BaseComponent.PAGE.confirmation ||
      this.uxHelper.page === BaseComponent.PAGE.cpcc ||
      (this.options && this.options.loginButton === false)
    ) {
      flag = false;
    }
    return flag;
  }

  /**
 *
 * to handle the click on Logo Image
 *
 * @param event is an event object to get event info of the clicked html elment
 */
  clickHeaderLogo($event) {
    if (this.options.headerTopLogoLink !== false) {
      return this.click($event);
    } else {
      return false;
    }
  }

  private async loadComponent() {
    if (!this.container) {
      return;
    }

    if (this.isReversePhone) {
      {
        const { DefaultSalesHeaderPhoneComponent } = await import('@themes/legacy/components/saleHeaderPhone.component');
        const component = this.container.createComponent(DefaultSalesHeaderPhoneComponent);
        component.instance.uxHelper = this.uxHelper;
        component.instance.options = this.options;
      }
      return;
    } else {
      switch (this.uxHelper.getTheme()) {
        case this.themes.ux_2_0:
          {
            const { Ux2Dot0SalesHeaderComponent } = await import('@themes/ux.2.0/components/salesHeader.component');
            const component = this.container.createComponent(Ux2Dot0SalesHeaderComponent);
            component.instance.uxHelper = this.uxHelper;
            component.instance.options = this.options;
          }
          return;
        case this.themes.ux_3_0:
          {
            const { Ux3Dot0SalesHeaderComponent } = await import('@themes/ux.3.0/components/salesHeader.component');
            const component = this.container.createComponent(Ux3Dot0SalesHeaderComponent);
            component.instance.uxHelper = this.uxHelper;
            component.instance.options = this.options;
          }
          return;
        case this.themes.ux_3_1:
          {
            const { Ux3Dot1SalesHeaderComponent } = await import('@themes/3842FF07E386F64F7AC9CD6AC71A737C/components/salesHeader.component');
            const component = this.container.createComponent(Ux3Dot1SalesHeaderComponent);
            component.instance.uxHelper = this.uxHelper;
            component.instance.options = this.options;
          }
          return;
        case this.themes.ux_4_0:
          {
            const { Ux4Dot0SalesHeaderComponent } = await import('@themes/ux.4.0/components/salesHeader.component');
            const component = this.container.createComponent(Ux4Dot0SalesHeaderComponent);
            component.instance.uxHelper = this.uxHelper;
            component.instance.options = this.options;
          }
          return;
        default:
          {
            const { DefaultSalesHeaderComponent } = await import('@themes/legacy/components/saleHeader.component');
            const component = this.container.createComponent(DefaultSalesHeaderComponent);
            component.instance.uxHelper = this.uxHelper;
            component.instance.options = this.options;
          }
          return;
      }
    }
  }
}
