import {Component, Input, OnInit} from "@angular/core";
import {ServiceHelperService} from "../../../../clientCommon/services/serviceHelper.service";
import {ActivatedRoute} from "@angular/router";
import {UxHelper} from "../../../../clientCommon/helper/uxHelper";
import {BaseDirective} from "../../../../clientCommon/directives/BaseDirective";

@Component({
  selector: 'ux-2-0-landingLightbox',
  templateUrl: 'landingLightbox.component.html',
})
export class Ux2DotLandingLightboxComponent extends BaseDirective implements OnInit {
  @Input() uxHelper: UxHelper;
  @Input() options: any = {};

  flag = true;

  constructor(serviceHelperService: ServiceHelperService,
              activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
  }

  ngOnInit() {
    return super.baseInit();
  }

  close() {
    this.flag = false;

  }

}
