import { AFFILIATE_UX_5_2_THEME_DECLARATIONS } from './affiliate';
import { CAREERS_UX_5_2_THEME_DECLARATIONS } from './careers';
import { COMMON_UX_5_2_THEME_DECLARATIONS } from './common';
import { COMPONENTS_UX_5_2_THEME_DECLARATIONS } from './components';
import { SetUxCompKeyIdDirectiveModule } from './directives';
import { PEOPLE_SEARCH_UX_5_2_THEME_DECLARATIONS } from './peopleSearch';

export const UX_5_2_THEME_DECLARATIONS = [
  // SetUxCompKeyIdDirectiveModule,
  ...AFFILIATE_UX_5_2_THEME_DECLARATIONS,
  ...CAREERS_UX_5_2_THEME_DECLARATIONS,
  ...AFFILIATE_UX_5_2_THEME_DECLARATIONS,
  ...COMMON_UX_5_2_THEME_DECLARATIONS,
  ...COMPONENTS_UX_5_2_THEME_DECLARATIONS,
  ...PEOPLE_SEARCH_UX_5_2_THEME_DECLARATIONS,
];


// export const THEMES_IMPORTS = [SetUxCompKeyIdDirectiveModule];