import {Component, Input, OnChanges, OnInit} from "@angular/core";
import {UxHelper} from "../../../../../clientCommon/helper/uxHelper";
import {LandingPeopleSearch} from "../../../landingPeopleSearch";

@Component({
  selector: 'default-landing-phone-search',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class DefaultLandingPhoneSearchComponent implements OnInit, OnChanges {
  @Input() base: LandingPeopleSearch;

  @Input() uxHelper: UxHelper;
  @Input() pageType: string;
  @Input() pageCategory: string;
  @Input() page: string;

  features = []
  aboutItems = []
  whyItems = [];

  constructor() {
  }

  init() {
  }

  ngOnInit() {
    return this.init();
  }

  ngOnChanges() {
    return this.init();
  }

  getDigit(number: number, place: number) {
    return Math.floor(number / Math.pow(10, place - 1)) % 10;
  }

}
