import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { UxHelper } from '../../../../../clientCommon/helper/uxHelper';
import { LoaderPeopleSearch } from '../../../loaderPeopleSearch';
import { ServiceHelperService } from '../../../../../clientCommon/services/serviceHelper.service';

@Component({
  selector: 'default-loader-phone-search',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class DefaultLoaderPhoneSearchComponent implements OnInit, OnChanges {
  @Input() base: LoaderPeopleSearch;

  @Input() uxHelper: UxHelper;
  @Input() pageType: string;
  @Input() pageCategory: string;
  @Input() page: string;

  constructor(private serviceHelperService: ServiceHelperService) {}

  init() {}

  ngOnInit() {
    return this.init();
  }

  ngOnChanges() {
    return this.init();
  }

  confirmFcra() {
    this.base.next();
  }
}
