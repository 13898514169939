// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.use p {
  font-size: 16px;
}

.btn-link {
  background: transparent;
  color: #4851c0;
}

.media768-inline-block {
  display: flex;
  justify-content: center;
  width: 25%;
  margin: 0 auto;
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/themes/ux.3.0/peopleSearch/nameSearch/loader.component.scss"],"names":[],"mappings":"AACE;EACE,eAAA;AAAJ;;AAGA;EACE,uBAAA;EACA,cAAA;AAAF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,UAAA;EACA,cAAA;EACA,mBAAA;AACF","sourcesContent":[".use {\n  p {\n    font-size: 16px;\n  }\n}\n.btn-link{\n  background: transparent;\n  color: #4851c0;\n}\n.media768-inline-block{\n  display: flex;\n  justify-content: center;\n  width: 25%;\n  margin: 0 auto;\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
