import {CollectionClass} from "../decorators/database/collectionClass";
import {ModelBase} from "./modelBase";
import {IndexField} from "../decorators/database/indexField";

@CollectionClass({name: "optOuts", revisionEnabled: true, draftEnabled: false})
export class OptOut extends ModelBase<OptOut> {

    @IndexField() targetId: string;
    provider: string;
    hash: string;
    email: string;
    fullName: string;
    address: string;
    phoneNumber: string;
    emailCampaignKey: string;

    @IndexField() referenceId: string;
    referenceCollection: string;

    constructor(doc?, draftFlag?: boolean) {
        super(doc, draftFlag);
        this.init(doc, this.draftFlag);
    }
}

