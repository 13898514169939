// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toast-wrapper {
  position: fixed;
  top: 16px;
  right: 16px;
  width: 200px;
  height: 100vh;
  z-index: 1000;
}
.toast-wrapper .toastr {
  border-radius: 6px;
  padding: 8px 16px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  color: white;
  margin-bottom: 16px;
  width: 200px;
  transition: all 0.2s;
  cursor: pointer;
}
.toast-wrapper .toastr.success {
  background-color: #81b34c;
}
.toast-wrapper .toastr.error {
  background-color: #ff4b55;
}
.toast-wrapper .toastr__title {
  margin: 0;
  margin-bottom: 0.5em;
  font-size: 18px;
}
.toast-wrapper .toastr__content {
  margin: 0;
  font-size: 16px;
}
.toast-wrapper .toastr:hover {
  box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.25);
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,aAAA;AACF;AACE;EACE,kBAAA;EACA,iBAAA;EACA,2CAAA;EACA,YAAA;EACA,mBAAA;EACA,YAAA;EACA,oBAAA;EACA,eAAA;AACJ;AACI;EACE,yBAAA;AACN;AAEI;EACE,yBAAA;AAAN;AAGI;EACE,SAAA;EACA,oBAAA;EACA,eAAA;AADN;AAII;EACE,SAAA;EACA,eAAA;AAFN;AAKI;EACE,2CAAA;AAHN","sourcesContent":[".toast-wrapper {\n  position: fixed;\n  top: 16px;\n  right: 16px;\n  width: 200px;\n  height: 100vh;\n  z-index: 1000;\n\n  .toastr {\n    border-radius: 6px;\n    padding: 8px 16px;\n    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);\n    color: white;\n    margin-bottom: 16px;\n    width: 200px;\n    transition: all 0.2s;\n    cursor: pointer;\n\n    &.success {\n      background-color: #81b34c;\n    }\n\n    &.error {\n      background-color: #ff4b55;\n    }\n\n    &__title {\n      margin: 0;\n      margin-bottom: 0.5em;\n      font-size: 18px;\n    }\n\n    &__content {\n      margin: 0;\n      font-size: 16px;\n    }\n\n    &:hover {\n      box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.25);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
