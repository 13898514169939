export var clientPaths = {
  landingIntermediate: "landingIntermediate",
  confirmation: "confirmation",
  confirmationIntermediate: "confirmationIntermediate",
  cpcc: "cpcc",
  addon: "addon",
  article: "article",
  page: "page",
  content: "content",
  terms: "terms",
  privacy: "privacy",
  contactUs: "contact",
  becomeAnAffiliate: "partner",
  savedReportsPage: "saved-reports",
  contactUsFull: 'contact-us',
  refund: 'refund',
  cpccLoading: "cpcc-loader",
  unsubscribe: "unsubscribe",
  suppressionList: "suppressionList",
  careers: "careers",
  optout: "optout",

  // Member
  member: "member",
  memberLogin: "member/login",
  memberLoginLink: "member/loginLink",
  memberForgotPassword: "member/forgotPassword",
  memberContents: "member/contents",
  memberContent: "member/content",
  memberContactUs: "member/mini-contact-us",
  memberHelp: "member/help",
  // Spy and ID Protection
  spyDashboardComponent: 'member/spy-dashboard',
  // Life Events Component
  LifeEventsComponent: 'member/life-events',
  
  // Member/account
  memberAccount: "member/account",
  memberAccountProfile: "member/account/profile",
  memberAccountPayment: "member/account/payment",
  memberAccountOrders: "member/account/orders",
  memberNewMyAccount: "member/account/new",
  memberPasswordUpdate: "member/account/password",

  // Misc
  mailUnsubscribe: "mail/unsubscribe",
  mailClick: "mail/click",
  mpi3dsPares: "mpi3ds/pares",
  optOutConfirmation: "optOut/confirmation",

  property: "property",
  propertyLanding: "property/landing", 
  propertySearchResult: "property/searchResult", 
  propertyDetail: "property/detail", 
};

export var adminPaths = {
  login: 'login',
  uxcFullMgmt: 'uxc/fullMgmt',
  uxcHistory: 'uxc/History',  
  uxcFullMgmtV1: 'uxc/v1/fullMgmt',
  productFullMgmt: 'product/fullMgmt',
  productFullMgmtV1: 'product/v1/fullMgmt',
  userMgmt: 'user/mgmt',
  userMgmtV1: 'user/v1/mgmt',
  contentMgmt: 'content/mgmt',
  csrCustomers: 'csr/customers',
  csrCustomer: 'csr/customer',
  commercePayment: 'csr/commercePayment',
  commerceOrder: 'csr/commerceOrder',
  mailSent: 'csr/mail/sent',
  tracking: 'csr/tracking/view',
  optOutView: 'csr/optOut/view',
  factFinders: 'csr/factFinders',
  permissions: 'admin/permissions',
  permissionsV1: 'admin/permissions/v1',
  emailMgmt: 'csr/email',
  uxHistoryMgmt: 'csr/uxHistory',
  csrCustomerV1: 'csr/1.0/customer',
  csrCustomerV2: 'csr/2.0/customer',
  trackingV1: 'csr/v1/tracking/view',
  optOutViewV1: 'csr/v1/optOut/view',
  mainSentV1: 'csr/v1/mail/sent',
  notes: 'notes',
  unsubscribe: 'csr/unsubscribe',
  timesheet: 'csr/timesheet',
  timesheets: 'csr/timesheets',
  scheduleHistory: 'csr/scheduleHistory',
};

export var manageCsrPaths = {
  findCustomers: "customers/find",
  updateCustomers: "customers/update",
  findCustomer: "commerce/customer/find",
  findPayment: "commerce/customer/payment/find",
  findOrder: "commerce/customer/order/find",
  chargebackCustomer: "commerce/customer/chargeback",
  chargebackSendEmail: "commerce/customer/chargebackEmail",
  commerceUpdate: "commerce/update",
  commerceAdjustment: "commerce/adjustment",
  commerceSale: "commerce/sale",
  mailSent: "mail/sent",
  mailUnsubscribe: "mail/unsubscribe",
  tracking: "tracking/view",
  saveNotes: "saveNotes",
  updateNote: "updateNote",
  historyNote: "historyNote",
  sendEmail: "sendEmail",
  clearPassword: "clearPassword",
  optOutView: "optOut/view",
  optOutOptOut: "optOut/optOut",
  orderCancel: "order/cancel",
  orderUncancel: "order/uncancel",
  getWhitelistHash: "get/whitelistHash",
  findCustomersSmart: "customers/findSmart",
  findPaymentSmart: "commerce/customer/payment/findSmart",
  findOrderSmart: "commerce/customer/order/findSmart",
  findCsr: "customers/findCsr",
  noteView: "note/view",
  createTimesheet: "timesheet/create",
  deleteTimesheet: "timesheet/delete",
  updateTimesheet: "timesheet/update",
  addAttachments: "create/attachment",
  getAllRequest: "find/ffrequest",
  deleteAttachments: "delete/attachment",
  downloadAttachments: "download/attachment/",
  getAllAttachments: "find/allattachments",
  updateFFRequest: "findone/factFindersRequest",
};

export var accountActionPaths = {
  updatePassword: "update/password",
  updateAccount: "update/account",
};

export var commerceActionPaths = {
  loadOffer: "load/offer",
  sale: "sale",
  isAvailableId: "isAvailableId",
  findContent: "findContent",
  downloadContent: "download/content",
  preSaleTrackingPixel: "preSaleTrackingPixel",
  checkRules: "checkRules",
};

export var commerceCustomerPaths = {
  findCustomer: "find",
  findOrder: "findOrder",
  findOrders: "findOrders",
  findUpsellOffers: "findUpsellOffers",
  hasOrder: "hasOrder",
  findContents: "find/contents",
  findContent: "find/content",
  findContentDwb: "find/contentDwb",
  findPastSearches: "find/pastSearches",
  findContentsByOrders: "find/contentsByOrders",
  createContent: "create/content",
  orderCancel: "order/cancel",
  orderUncancel: "order/uncancel",
  countContentUpdated: "count/content/updated",
  processUpdateContent: "process/update/content",
  declineIdProtectionOfferBanner: "idProtectionBanner/offer/decline",
  declineIdProtectionOfferPopup: "idProtectionPopup/offer/decline",
  markAlertsAsRead: "dwbAlerts/mark/unread",
  viewReport: "view",
  updateMeReportFlag: "update/meReport",
  updateRelationship: "update/relationship",
  updateEmailMonitoringForIdProtection: "update/emailMonitoringForIdProtection",
  updateEmailMonitoring: "update/emailMonitoring",
  monitorDwb: "update/monitorDwb",
  addFamilyProvisioning: "provisioning/family/add",
  deleteFamilyProvisioning: "provisioning/family/del",
  addLostWalletProvisioning: "provisioning/lostwallet/add",
  deleteLostWalletProvisioning: "provisioning/lostwallet/del",
  initScan: "monitoring/initScan",
  addFactFindersRequest: "create/factFindersRequest",
  updateFactFindersRequest: "findone/factFindersRequest",
  getFactFindersRequests: "find/factFindersRequests",
  requestToShowStatus: "requestshowstatus",
  requestWithdrawStatus: "withdrawstatus",
  factfinderUserById: "findone/factfinderUserById",
  downloadAttachmentById: "download/attachment",
  updateIdiCount: "update/idiCount",
};

export var commerceMpi3dsPaths = {
  verify: "verify",
  process: "process",
};

export var uxcPaths = {
  findComposite: "find/composite",
  prefetch: "prefetch",
  findUxCompData: "findUxCompData",
  findUxccData: "findUxccData",
  findUxlData: "findUxlData",
  findUxcData: "findUxcData"
};

export var mailPaths = {
  add: "add",
  check: "check",
  track: "track",
  trackGif: "track.gif",
  trackGet: "trackGet",
  referralSendEmail: "referralSendEmail",
};

export var loginLinkPaths = {
  login: "login",
  sendEmail: "send/email",
  verifyToken: "verifyToken",
};

export var referralLinkPaths = {
  sendEmail: "send/email",
  verifyReferralLink: "verifyReferralLink",
  referralCopyLink: "referralCopyLink"
}
export var shareLinkPaths = {
  findShareData: "find/shareData",
  shareCopyLink: "shareCopyLink"
}

export const captchaPaths = {
  verify: "verify",
  verifyAdmin: "noSession/verifyAdmin",
};
export var recaptchaPaths = {
  verify: "verify",
};

export var censusPaths = {
  find: "find",
};

export var mpi3dsecurePaths = {};
export var trackingPaths = {
  track: "rec",

  trackNull: "rec_null", // for null response tracking
  pixel: "pix.gif",
  trackPixel: "rec_pix.gif",
  frame: "frame", // For conversion pixel
  pixelFrame: "pixelFrame", // for 3rd party pixel
  reflectFrameA: "reflectFrameA", // for reflect POST data to localStorage. From
  reflectFrameB: "noSession/reflectFrameB", // for reflect POST data to localStorage. To
};

export var tagPaths = {
  saveTags: "saveTags",
  findAllTags: "findAllTags",
  findByTag: "findByTag",
  createTag: "createTag",
  updateTag: "updateTag",
  deleteTag: "deleteTag",
};

export var timeSheetPaths = {
  getLatest: "getLatest",
  getGlobalLatest: "getGlobalLatest",
  create: "create",
  getRecordsByUser: "getRecordsByUser",
  getWeeklyRecord: "getWeeklyRecord",
  readUser: "readUser",
  updateNote: "updateNote",
};

export var optOutPaths = {
  optOutRequest: "optOutRequest",
  optOutConfirm: "optOutConfirm",
};

export var progressorPaths = {
  update: "update",
};

export var gatewayHubPaths = {
  transact: "transact",
};

export var crudPaths = {
  read: "read",
  readHistory: "readHistory",
  pushInput: "push/input",
  updateInput: "update/input",
};

export var notePaths = {
  createNote: 'createNote',
  deleteNote: 'deleteNote',
  findNote: 'findNote',
  feedbackNote: 'feedbackNote',
  findFeedbackNote: 'findFeedbackNote',
};
export const attachmentPaths = {
  createAttachment: 'createAttachment',
  deleteAttachment: 'deleteAttachment',
  findAttachment: 'findAttachment',
};
export var unsubscribePaths = {
  search: 'search',
  unsubscribe: 'unsubscribe',
};

export var messagePaths = {
  supportEmail: "support/email",
  affiliateEmail: "affiliate/email",
};



export var bankIdentificationNumberPaths = {
  find: "find",
};

export var emailRecordPaths = {
  save: "save",
  findEmailsByStatus: "findEmailsByStatus",
  findEmailById: "findEmailById",
  findNonMemberCDP: "findNonMemberCDP",
  findEmailsByUpdater: "findEmailsByUpdater",
  findEmailsBySender: "findEmailsBySender",
  findEmails: "findEmails",
  updateEmailStatus: "updateEmailStatus",
  updateCDP: "updateCDP",
  assignCsr: "assignCsr",
  replyTo: "replyTo",
  move: "move",
  setOwner: "setOwner"
};

export const suppressionListPaths = {
  add: "add",
};

export const propertyPaths = {
  search: "",
  featuredSearch: "featuredSearch",
  detail: "detail",
  location: "location",
  register: "register",
};

// Router Path
export var serverRouterPaths = {
  accountAction: "api/account/action",
  commerceCustomer: "api/commerce/customer",
  commerceAction: "api/commerce/action",
  commerceMpi3ds: "api/commerce/mpi3ds",
  contentPeopleSearch: "api/content/peopleSearch",
  contentSmsSender: "api/content/smsSender",
  manageCsr: "api/manage/csr",
  manageUnsubscribe: "api/manage/unsubscribe",
  uxc: "api/uxc",
  mail: "api/mail",
  loginLink: "api/helper/loginLink",
  tracking: "api/helper/tracking",
  optOut: "api/helper/optOut",
  progressor: "api/helper/progressor",
  crud: "api/helper/crud",
  gatewayHub: "api/gatewayHub/interface",
  message: "api/helper/message",
  bankIdentificationNumber: "api/helper/bin",
  email: "api/manage/email",
  note: "api/helper/note",
  tag: "api/helper/tag",
  censusData: "api/helper/census",
  captcha: "api/helper/captcha",
  recaptcha: "api/helper/recaptcha",
  suppressionList: "api/helper/suppressionList",
  timesheet: "api/manage/timesheet",
  unsubscribe: "api/helper/unsubscribe",
  demographics: 'api/report/demographics/social',
  property: 'api/helper/property',
  referral: 'api/helper/referral',
  share: 'api/helper/share',
};

export var serverPaths = {
  //Account
  accountActionUpdatePassword:
    serverRouterPaths.accountAction + "/" + accountActionPaths.updatePassword,
  accountActionUpdateAccount:
    serverRouterPaths.accountAction + "/" + accountActionPaths.updateAccount,

  //manageCsrPaths
  manageCsrFindCustomers: serverRouterPaths.manageCsr + "/" + manageCsrPaths.findCustomers,
  manageCsrFindCsr: serverRouterPaths.manageCsr + "/" + manageCsrPaths.findCsr,
  manageCsrUpdateCustomer: serverRouterPaths.manageCsr + "/" + manageCsrPaths.updateCustomers,
  manageCsrFindCustomer: serverRouterPaths.manageCsr + "/" + manageCsrPaths.findCustomer,
  manageCsrCommerceUpdate: serverRouterPaths.manageCsr + "/" + manageCsrPaths.commerceUpdate,
  manageCsrCommerceAdjustment: serverRouterPaths.manageCsr + "/" + manageCsrPaths.commerceAdjustment,
  manageCsrCommerceSale: serverRouterPaths.manageCsr + "/" + manageCsrPaths.commerceSale,
  manageCsrMailSent: serverRouterPaths.manageCsr + "/" + manageCsrPaths.mailSent,
  manageCsrMailUnsubscribe: serverRouterPaths.manageCsr + "/" + manageCsrPaths.mailUnsubscribe,
  manageCsrTrackingView: serverRouterPaths.manageCsr + "/" + manageCsrPaths.tracking,
  manageCsrSaveNotes: serverRouterPaths.manageCsr + "/" + manageCsrPaths.saveNotes,
  manageCsrSendEmail: serverRouterPaths.manageCsr + "/" + manageCsrPaths.sendEmail,
  manageCsrUpdateNote: serverRouterPaths.manageCsr + "/" + manageCsrPaths.updateNote,
  manageCsrHistoryNote: serverRouterPaths.manageCsr + "/" + manageCsrPaths.historyNote,
  manageCsrClearPassword: serverRouterPaths.manageCsr + "/" + manageCsrPaths.clearPassword,
  manageCsrOptOutView: serverRouterPaths.manageCsr + "/" + manageCsrPaths.optOutView,
  manageCsrOptOutOptOut: serverRouterPaths.manageCsr + "/" + manageCsrPaths.optOutOptOut,
  manageCsrOrderCancel: serverRouterPaths.manageCsr + "/" + manageCsrPaths.orderCancel,
  manageCsrOrderUncancel: serverRouterPaths.manageCsr + "/" + manageCsrPaths.orderUncancel,
  manageCsrFindCustomersSmart: serverRouterPaths.manageCsr + "/" + manageCsrPaths.findCustomersSmart,
  manageCsrNotesView: serverRouterPaths.manageCsr + "/" + manageCsrPaths.noteView,
  manageCsrTimesheetCreate: serverRouterPaths.manageCsr + "/" + manageCsrPaths.createTimesheet,
  manageCsrTimesheetUpdate: serverRouterPaths.manageCsr + "/" + manageCsrPaths.updateTimesheet,
  manageCsrTimesheetDelete: serverRouterPaths.manageCsr + "/" + manageCsrPaths.deleteTimesheet,
  manageCsrAddAttachment:
    serverRouterPaths.manageCsr + "/" + manageCsrPaths.addAttachments,
  manageCsrDeleteAttachment:
    serverRouterPaths.manageCsr + "/" + manageCsrPaths.deleteAttachments,
  manageCsrDownloadAttachment:
    serverRouterPaths.manageCsr + "/" + manageCsrPaths.downloadAttachments,
  manageCsrGetAllFFRequest:
    serverRouterPaths.manageCsr + "/" + manageCsrPaths.getAllRequest,
  manageCsrUpdateFFRequest:
    serverRouterPaths.manageCsr + "/" + manageCsrPaths.updateFFRequest,
  manageCsrGetAllAttachments:
    serverRouterPaths.manageCsr + "/" + manageCsrPaths.getAllAttachments,

  manageCsrFindPayments: serverRouterPaths.manageCsr + "/" + manageCsrPaths.findPayment,
  manageCsrFindPaymentsSmart: serverRouterPaths.manageCsr + "/" + manageCsrPaths.findPaymentSmart,

  manageCsrFindOrders: serverRouterPaths.manageCsr + "/" + manageCsrPaths.findOrder,
  manageCsrFindOrdersSmart: serverRouterPaths.manageCsr + "/" + manageCsrPaths.findOrderSmart,

  //commerceActionPaths
  commerceActionLoadOffer:
    serverRouterPaths.commerceAction + "/" + commerceActionPaths.loadOffer,
  commerceActionSale:
    serverRouterPaths.commerceAction + "/" + commerceActionPaths.sale,
  commerceActionIsAvailableId:
    serverRouterPaths.commerceAction + "/" + commerceActionPaths.isAvailableId,
  commerceActionFindContent:
    serverRouterPaths.commerceAction + "/" + commerceActionPaths.findContent,
  commerceActionCheckRules: serverRouterPaths.commerceAction + "/" + commerceActionPaths.checkRules,

  commerceActionDownloadContent:
    serverRouterPaths.commerceAction +
    "/" +
    commerceActionPaths.downloadContent,

  //commerceCustomerPaths
  commerceCustomerFindCustomer:
    serverRouterPaths.commerceCustomer +
    "/" +
    commerceCustomerPaths.findCustomer,
  commerceCustomerFindOrder:
    serverRouterPaths.commerceCustomer + "/" + commerceCustomerPaths.findOrder,
  commerceCustomerFindOrders:
    serverRouterPaths.commerceCustomer + "/" + commerceCustomerPaths.findOrders,
  commerceCustomerFindUpsellOffers:
    serverRouterPaths.commerceCustomer +
    "/" +
    commerceCustomerPaths.findUpsellOffers,
  commerceCustomerHasOrder:
    serverRouterPaths.commerceCustomer + "/" + commerceCustomerPaths.hasOrder,
  commerceCustomerFindPastSearches:
    serverRouterPaths.commerceCustomer + "/" + commerceCustomerPaths.findPastSearches,
  commerceCustomerFindContents:
    serverRouterPaths.commerceCustomer +
    "/" +
    commerceCustomerPaths.findContents,
  commerceCustomerFindContent: serverRouterPaths.commerceCustomer + "/" + commerceCustomerPaths.findContent,
  commerceCustomerFindContentDwb: serverRouterPaths.commerceCustomer + "/" + commerceCustomerPaths.findContentDwb,
  commerceCustomerFindContentsByOrders:
    serverRouterPaths.commerceCustomer +
    "/" +
    commerceCustomerPaths.findContentsByOrders,
  commerceCustomerCreateContent:
    serverRouterPaths.commerceCustomer +
    "/" +
    commerceCustomerPaths.createContent,
  commerceCustomerOrderCancel:
    serverRouterPaths.commerceCustomer +
    "/" +
    commerceCustomerPaths.orderCancel,
  commerceCustomerOrderUncancel:
    serverRouterPaths.commerceCustomer +
    "/" +
    commerceCustomerPaths.orderUncancel,
  commerceCustomerCountContentUpdated:
    serverRouterPaths.commerceCustomer +
    "/" +
    commerceCustomerPaths.countContentUpdated,
  commerceCustomerProcessUpdateContent:
    serverRouterPaths.commerceCustomer +
    "/" +
    commerceCustomerPaths.processUpdateContent,
  commerceCustomerDeclineIdProtectionOfferBanner:
    serverRouterPaths.commerceCustomer +
    "/" +
    commerceCustomerPaths.declineIdProtectionOfferBanner,

  commerceCustomerDeclineIdProtectionOfferPopup:
    serverRouterPaths.commerceCustomer +
    "/" +
    commerceCustomerPaths.declineIdProtectionOfferPopup,

  commerceCustomerMarkAlertAsRead:
    serverRouterPaths.commerceCustomer +
    "/" +
    commerceCustomerPaths.markAlertsAsRead,
  commerceCustomerGetFFRequests:
    serverRouterPaths.commerceCustomer +
    "/" +
    commerceCustomerPaths.getFactFindersRequests,
  commerceCustomerViewReport:
    serverRouterPaths.commerceCustomer +
    "/" +
    commerceCustomerPaths.viewReport,
  commerceCustomerUpdateRelationship:
    serverRouterPaths.commerceCustomer +
    "/" +
    commerceCustomerPaths.updateRelationship,

  commerceCustomerUpdateIdiCount:
    serverRouterPaths.commerceCustomer + "/" + commerceCustomerPaths.updateIdiCount,

  //commerceMpi3dsPaths
  commerceMpi3dsVerify:
    serverRouterPaths.commerceMpi3ds + "/" + commerceMpi3dsPaths.verify,
  commerceMpi3dsProcess:
    serverRouterPaths.commerceMpi3ds + "/" + commerceMpi3dsPaths.process,
  commerceCustomerUpdateMeReportFlag:
    serverRouterPaths.commerceCustomer + "/" + commerceCustomerPaths.updateMeReportFlag,
  commerceCustomerUpdateEmailMonitoring:
    serverRouterPaths.commerceCustomer + "/" + commerceCustomerPaths.updateEmailMonitoring,
  commerceCustomerUpdateEmailMonitoringForIdProtection:
    serverRouterPaths.commerceCustomer + "/" + commerceCustomerPaths.updateEmailMonitoringForIdProtection,

  commerceCustomerMonitorDwb:
    serverRouterPaths.commerceCustomer + "/" + commerceCustomerPaths.monitorDwb,
  commerceCustomerAddFamilyProvisioning:
    serverRouterPaths.commerceCustomer + "/" + commerceCustomerPaths.addFamilyProvisioning,
  commerceCustomerDeleteFamilyProvisioning:
    serverRouterPaths.commerceCustomer + "/" + commerceCustomerPaths.deleteFamilyProvisioning,  
  commerceCustomerAddLostWalletProvisioning:
    serverRouterPaths.commerceCustomer + "/" + commerceCustomerPaths.addLostWalletProvisioning,
  commerceCustomerDeleteLostWalletProvisioning:
    serverRouterPaths.commerceCustomer + "/" + commerceCustomerPaths.deleteLostWalletProvisioning,    
  commerceCustomerAddFactFindersRequest:
    serverRouterPaths.commerceCustomer + "/" + commerceCustomerPaths.addFactFindersRequest,
  commerceCustomerGetFactFindersRequestToShowStatus:
    serverRouterPaths.commerceCustomer + "/" + commerceCustomerPaths.requestToShowStatus,
  commerceCustomerGetFactFindersWithdraw:
    serverRouterPaths.commerceCustomer + "/" + commerceCustomerPaths.requestWithdrawStatus,
  commerceCustomerFindFactFindersRequestUserById:
    serverRouterPaths.commerceCustomer + "/" + commerceCustomerPaths.factfinderUserById,
  commerceCustomerUpdateFactFindersRequest:
    serverRouterPaths.commerceCustomer + "/" + commerceCustomerPaths.updateFactFindersRequest,
  commerceCustomerdownladAttachment:
    serverRouterPaths.commerceCustomer + "/" + commerceCustomerPaths.downloadAttachmentById,

  //ContentPeopleSearchPaths

  //UXC
  uxcFindComposite: serverRouterPaths.uxc + "/" + uxcPaths.findComposite,
  uxCompData: serverRouterPaths.uxc + "/" + uxcPaths.findUxCompData,
  uxccData: serverRouterPaths.uxc + "/" + uxcPaths.findUxccData,
  uxlData: serverRouterPaths.uxc + "/" + uxcPaths.findUxlData,
  uxcData: serverRouterPaths.uxc + "/" + uxcPaths.findUxcData,

  //Mail
  mailAdd: serverRouterPaths.mail + "/" + mailPaths.add,
  mailCheck: serverRouterPaths.mail + "/" + mailPaths.check,
  mailTrack: serverRouterPaths.mail + "/" + mailPaths.track,
  mailTrackGif: serverRouterPaths.mail + "/" + mailPaths.trackGif,
  mailTrackGet: serverRouterPaths.mail + "/" + mailPaths.trackGet,

  referralSendEmail: serverRouterPaths.referral + "/" + referralLinkPaths.sendEmail,
  verifyReferralLink: serverRouterPaths.referral + "/" + referralLinkPaths.verifyReferralLink,
  referralCopyLink: serverRouterPaths.referral + "/" + referralLinkPaths.referralCopyLink,

  // Share link
  shareFindData: serverRouterPaths.share+"/"+shareLinkPaths.findShareData,
  shareCopyLink: serverRouterPaths.share + "/" + shareLinkPaths.shareCopyLink,

  preSaleTrackingPixel: serverRouterPaths.commerceAction + "/" + commerceActionPaths.preSaleTrackingPixel,
  loginLinkLogin: serverRouterPaths.loginLink + "/" + loginLinkPaths.login,
  loginLinkSendEmail:
    serverRouterPaths.loginLink + "/" + loginLinkPaths.sendEmail,
  trackingTrack: serverRouterPaths.tracking + "/" + trackingPaths.track,

  trackingTrackNull: serverRouterPaths.tracking + "/" + trackingPaths.trackNull,
  trackingTrackPixel:
    serverRouterPaths.tracking + "/" + trackingPaths.trackPixel,
  trackingFrame: serverRouterPaths.tracking + "/" + trackingPaths.frame,
  trackingPixelFrame:
    serverRouterPaths.tracking + "/" + trackingPaths.pixelFrame,
  optOutOptOutRequest:
    serverRouterPaths.optOut + "/" + optOutPaths.optOutRequest,
  optOutOptOutConfirm:
    serverRouterPaths.optOut + "/" + optOutPaths.optOutConfirm,
  progressorUpdate: serverRouterPaths.progressor + "/" + progressorPaths.update,
  crudRead: serverRouterPaths.crud + "/" + crudPaths.read,
  crudPushInput: serverRouterPaths.crud + "/" + crudPaths.pushInput,
  crudUpdateInput: serverRouterPaths.crud + "/" + crudPaths.updateInput,
  emailSave: serverRouterPaths.email + "/" + emailRecordPaths.save,
  emailReply: serverRouterPaths.email + "/" + emailRecordPaths.replyTo,
  emailsBySender: serverRouterPaths.email + "/" + emailRecordPaths.findEmailsBySender,
  emailsByStatus: serverRouterPaths.email + "/" + emailRecordPaths.findEmailsByStatus,
  emailById: serverRouterPaths.email + "/" + emailRecordPaths.findEmailById,
  nonMemberCDP: serverRouterPaths.email + "/" + emailRecordPaths.findNonMemberCDP,
  emailsByUpdater: serverRouterPaths.email + "/" + emailRecordPaths.findEmailsByUpdater,
  emailsAll: serverRouterPaths.email + "/" + emailRecordPaths.findEmails,
  emailsMoveTo: serverRouterPaths.email + "/" + emailRecordPaths.move,
  emailsUpdateStatus: serverRouterPaths.email + "/" + emailRecordPaths.updateEmailStatus,
  emailsAssignCsr: serverRouterPaths.email + "/" + emailRecordPaths.assignCsr,
  emailsUpdateCDP: serverRouterPaths.email + "/" + emailRecordPaths.updateCDP,
  emailsSetOwner: serverRouterPaths.email + "/" + emailRecordPaths.setOwner,
  gatewayHubTransact:
    serverRouterPaths.gatewayHub + "/" + gatewayHubPaths.transact,
  messageSupportEmail: serverRouterPaths.message + "/" + messagePaths.supportEmail,
  affiliateEmail: serverRouterPaths.message + "/" + messagePaths.affiliateEmail,
  bankIdentificationNumberFind:
    serverRouterPaths.bankIdentificationNumber +
    "/" +
    bankIdentificationNumberPaths.find,
  noteFindAll: serverRouterPaths.note + "/" + notePaths.findNote,
  noteCreate: serverRouterPaths.note + "/" + notePaths.createNote,
  noteDelete: serverRouterPaths.note + "/" + notePaths.deleteNote,
  feedbackNote: serverRouterPaths.note + "/" + notePaths.feedbackNote,
  findFeedbackNote: serverRouterPaths.note + "/" + notePaths.findFeedbackNote,

  tagSaveTags: serverRouterPaths.tag + "/" + tagPaths.saveTags,
  tagFindAllTags: serverRouterPaths.tag + "/" + tagPaths.findAllTags,
  tagFindByTag: serverRouterPaths.tag + "/" + tagPaths.findByTag,
  tagCreateTag: serverRouterPaths.tag + "/" + tagPaths.createTag,
  tagUpdateTag: serverRouterPaths.tag + "/" + tagPaths.updateTag,
  tagDeleteTag: serverRouterPaths.tag + "/" + tagPaths.deleteTag,

  timeSheetGetLatest: serverRouterPaths.timesheet + "/" + timeSheetPaths.getLatest,
  timeSheetGetGlobalLatest: serverRouterPaths.timesheet + "/" + timeSheetPaths.getGlobalLatest,
  timeSheetCreate: serverRouterPaths.timesheet + "/" + timeSheetPaths.create,
  timeSheetGetRecordsByUser: serverRouterPaths.timesheet + "/" + timeSheetPaths.getRecordsByUser,
  timeSheetGetWeeklyRecord: serverRouterPaths.timesheet + "/" + timeSheetPaths.getWeeklyRecord,
  timeSheetGetUsers: serverRouterPaths.timesheet + "/" + timeSheetPaths.readUser,
  timeSheetUpdateNote: serverRouterPaths.timesheet + "/" + timeSheetPaths.updateNote,

  censusLinkFind: serverRouterPaths.censusData + "/" + censusPaths.find,

  captchaVerify: serverRouterPaths.captcha + "/" + captchaPaths.verify,
  captchaVerifyAdmin: serverRouterPaths.captcha + "/" + captchaPaths.verifyAdmin,
  recaptchaVerify: serverRouterPaths.recaptcha + "/" + recaptchaPaths.verify,

  unsubscribeSearch: serverRouterPaths.manageUnsubscribe + "/" + unsubscribePaths.search,
  unsubscribeUnsubscribe: serverRouterPaths.manageUnsubscribe + "/" + unsubscribePaths.unsubscribe,

  suppressionListAdd: serverRouterPaths.suppressionList + "/" + suppressionListPaths.add,

  // property
  propertySearch: serverRouterPaths.property + "/" + propertyPaths.search,
  propertyFeaturedSearch: serverRouterPaths.property + "/" + propertyPaths.featuredSearch,
  propertyDetail: serverRouterPaths.property + "/" + propertyPaths.detail,
  propertyLocation: serverRouterPaths.property + "/" + propertyPaths.location,
  propertyRegister: serverRouterPaths.property + "/" + propertyPaths.register,
};
