import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Input, NgModule, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UxHelper } from '@clientCommon/helper/uxHelper';
import { SignUpPeopleSearch } from 'app/sales/peopleSearch/signUpPeopleSearchSales';

declare var $: any;

@Component({
  selector: 'ux-5-2-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent implements OnInit {
  @Input() base: SignUpPeopleSearch;
  @Input() uxHelper: UxHelper;
  @Input() freeTrial = false;

  @ViewChild('billingSignup') public billingSignup: ElementRef;
  @ViewChild('firstName') public firstNameInput: ElementRef<HTMLInputElement>;
  @ViewChild('lastName') public lastNameInput: ElementRef<HTMLInputElement>;

  cardType = '';

  reports = [];
  feedbacks = [];
  brandName = '';

  constructor(private dr: ChangeDetectorRef) {}

  init() {
    this.reports = this.uxHelper.getUxcomp('reports');
    this.feedbacks = this.uxHelper.getUxcomp('feedbacks');
    this.brandName = this.uxHelper.uxComposite.brandName;
  }

  ngOnInit() {
    return this.init();
  }

  ngOnChanges() {
    return this.init();
  }

  formatInput() {
    this.base.creditCardInputHelper.fullName = this.base.userInfo.fname + ' ' + this.base.userInfo.lname;
  }
  cred;

  onSubmitStep1() {
    this.base.goNextStep(this.base.steps.email);
  }

  formatString(value, key) {
    const replaced = this.base.formatString(value, key);
    if (key === 'fname') {
      this.firstNameInput.nativeElement.value = replaced;
    } else if (key === 'lname') {
      this.lastNameInput.nativeElement.value = replaced;
    }
    this.dr.detectChanges();
  }

  handleChangeCCNumber() {
    this.cardType = this.base.creditCardInputHelper.cardType;
  }

  isValidCCForm() {
    if (!this.base.feedBackFlags[this.base.steps.billing]) {
      return true;
    }
    if (
      this.base.creditCardInputHelper.isValidFullName() &&
      this.base.creditCardInputHelper.isValidCCNumber() &&
      this.base.creditCardInputHelper.isValidCCExp() &&
      this.base.creditCardInputHelper.isValidCCCvv() &&
      this.base.creditCardInputHelper.isValidZip()
    ) {
      return true;
    }
    return false;
  }
}
