import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, NgModule } from '@angular/core';

@Component({
  selector: 'app-tutorial-modal',
  templateUrl: 'tutorialModal.component.html',
  styleUrls: ['tutorialModal.component.scss'],
})
export class TutorialModalComponent implements OnInit {
  @Input() desc = '';

  constructor() {}

  ngOnInit() {}

  goNext() {}
}

@NgModule({
  declarations: [TutorialModalComponent],
  imports: [CommonModule],
  exports: [TutorialModalComponent],
})
export class TutorialModalComponentModule {}
