import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgSelectModule } from "@ng-select/ng-select";
import { TooltipModule } from "ng2-tooltip-directive";
import { UiSwitchModule } from "ngx-ui-switch";

import { SALES_COMPONENTS_DECLARATIONS } from "./sales/components";
import { COMPONENT_DECLARATIONS, COMPONENT_MODULES } from "./components";
import { ReversePipe } from "../clientCommon/pipes/reverse.pipe";
import { ToArrayPipe } from "../clientCommon/pipes/toArray.pipe";
import { ToDateObjectPipe } from "../clientCommon/pipes/toDateObject.pipe";
import { ToDateTimePipe } from "../clientCommon/pipes/toDateTime.pipe";
import { CREDIT_CARD_DECLARATIONS } from "./member/components/creditCard";
import { MARKETING_PROMO_DECLARATIONS } from "./member/components/marketing-promo";
import { CPCC_ENTERY_CREDIT_CARD_DECLARATIONS } from "./member/components/cpcc-entery-card";
import { SetUxCompKeyIdDirectiveModule } from "@themes/2234200bd3cc36ba97274ecbdc6c6a47/directives";
import { TruncatePipe } from "../clientCommon/pipes/truncate.pipe";

@NgModule({
    declarations: [
        ToDateTimePipe,
        ToDateObjectPipe,
        ToArrayPipe,
        ReversePipe,
        TruncatePipe,
        ...SALES_COMPONENTS_DECLARATIONS,
        ...COMPONENT_DECLARATIONS,
        ...CREDIT_CARD_DECLARATIONS,
        ...MARKETING_PROMO_DECLARATIONS,
        ...CPCC_ENTERY_CREDIT_CARD_DECLARATIONS,
    ],
    exports: [
        // imports
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        UiSwitchModule,
        NgSelectModule,
        TooltipModule,
        ...COMPONENT_MODULES,
        // declarations
        ToDateTimePipe,
        ToDateObjectPipe,
        ToArrayPipe,
        ReversePipe,
        TruncatePipe,
        SetUxCompKeyIdDirectiveModule,
        ...SALES_COMPONENTS_DECLARATIONS,
        ...COMPONENT_DECLARATIONS,
        ...CREDIT_CARD_DECLARATIONS,
        ...MARKETING_PROMO_DECLARATIONS,
        ...CPCC_ENTERY_CREDIT_CARD_DECLARATIONS,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        UiSwitchModule,
        NgSelectModule,
        TooltipModule,
        ...COMPONENT_MODULES,
        SetUxCompKeyIdDirectiveModule,
    ],
    providers: [provideHttpClient(withInterceptorsFromDi())]
})
export class SharedModule { }