import {UxComposite} from "../../common/models/ux/uxComposite";
import {isObject} from "util";
import {LogUtils} from "../../common/utils/logUtils";

export class UxHelper {
  // uxComposite  instance for current uX
  uxComposite: UxComposite;
  prefix = "";
  suffix = "";

  pageType = "baseType";
  pageCategory = "baseCategory";
  page = "basePage";

  constructor() {
  }

  /**
   *
   * @returns the prefix string to form the uxCompKey
   */
  private getPrefix() {
    if (this.prefix) {
      return `.${this.prefix}`;
    } else {
      return '';
    }
  }


  /**
   * @returns the suffix string to form the uxCompKey
  */
  private getSuffix() {
    if (this.suffix) {
      return `.${this.suffix}`;
    } else {
      return '';
    }
  }

  /**
   * @returns the uxComposite instacne if its not null/undefined
   */
  hasUxComposite(): boolean {
    return !!this.uxComposite;
  }

  /**
   *
   * @param key of a uxCompos
   * @returns full uxComp key along with the required prefix+suffix
   */
  getUxcompKey(key) {
    if (key.includes('member.idProtection')) {
    }
    return `comp${this.getPrefix()}.${key}${this.getSuffix()}`;
  }

  /**
   *
   * @param key to get the uxComp
   * @returns the uxcomp based on the Key
   */
  getUxcomp(key) {
    if (this.hasUxComposite()) {
      let fullKey = this.getUxcompKey(key);
      return this.uxComposite.get(fullKey);
    }
  }

/**
 *
 * @param key to get the uxComposite
 * @returns the uxcomposite based on full Key
 */
  getValue(key) {
    if (this.hasUxComposite()) {
      return this.uxComposite.get(key);
    }
  }

/**
 *
 * @param key to get the uxComposite
 * @returns the uxcomposite unrendered raw value based on Key
 */
  getRaw(key) {
    if (this.hasUxComposite()) {
      return this.uxComposite.raw(key);
    }
  }
  keysUxComp(key) {
    if (this.hasUxComposite()) {
      var fullKey = this.getUxcompKey(key);
      let keys = this.uxComposite.keys;
      fullKey.split('.').some((value) => {
        if (keys) {
          keys = keys[value];
        } else {
          return true;
        }
      });
      return keys;
    }
  }

  /**
   *
   * @returns the current UXL theme from the uxComposite
   */
  getTheme() {
    // if we have uxComposite , we set it from uxComp
    if (this.hasUxComposite()) {
      let keySuffix = "theme.name";
      let theme = this.getUxcomp(keySuffix);
      if (!theme) {
        theme = this.getValue('comp.' + keySuffix);
      }

      // set the current device in the theme object from the uxComposite device
      let device = this.uxComposite.device;
      if (isObject(theme)) {
        if (theme[device]) {
          theme = theme[device];
        } else {
          theme = theme.default;
        }
      }

      if (theme) {
        try {
          theme = theme.replace(/[.]/g, "_");
        } catch (e) {
          LogUtils.error("ThemeTranslationError", typeof theme, e);
        }
      }
      return theme;
    }
  }

  /**
   *
   * @returns the theme logo svf from the current theme uxComp
   */
  getLogo() {
    let logo = "logo.svg";
    if (this.hasUxComposite()) {
      const themeLogo = this.uxComposite.get("comp.theme.logo");
      if (themeLogo) {
        logo = themeLogo
      }
    }

    return logo;
  }
}
