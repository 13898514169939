import { Component, Input, NgModule, OnInit } from '@angular/core';

@Component({
  selector: 'ux-5-2-basic-progress-bar',
  templateUrl: './basic-progress-bar.component.html',
  styleUrls: ['./basic-progress-bar.component.scss']
})
export class BasicProgressBarComponent {
  @Input() progress: number = 80;
}

// @NgModule({
//   declarations: [BasicProgressBarComponent],
//   exports: [BasicProgressBarComponent],
// })
// export class BasicProgressBarComponentModule {}