export class CommerceBillingRouting {
  public static TYPE = {
    sale: "sale",
    saleRetryImmediate: "sale.retry.immediate",
    recurring: "recurring",
    adjustment: "adjustment",
  };
  sticky: string;
  ignoreSticky: boolean;
  processorId: string;
  processorSticky: string;
  gateway: string;
  temp: any;

  constructor(doc?) {
    if (doc) {
      Object.assign(<any>this, doc);
    }
  }

  clearTempValues() {
    delete this.temp;
  }
}
