import {
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  EventEmitter,
  Input,
  NgModule,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceHelperService } from '../../../../../../clientCommon/services/serviceHelper.service';
import { PersonDetail } from '../../../personDetail';
import { ToastrService } from '../../../../../service/toastr.service';
import { BaseComponent } from '../../../../../../clientCommon/components/BaseComponent';
import { peopleSearchProductKeys } from '../../../../../../common/custom/peopleSearch/peopleSearchProductKeys';
import { AuthenticationService, NoteService } from '../../../../../../clientCommon/services';
import { CommonModule } from '@angular/common';
import { MiniReportHeaderModule } from '@member/peopleSearch/components/mini/header/header.component';
import { MiniReportFooter, MiniReportFooterModule } from '@member/peopleSearch/components/mini/footer/footer.component';
import {
  CriminalReportNoDataComponent,
  CriminalReportNoDataComponentModule,
} from '@member/peopleSearch/components/mini/criminal-no-data/criminal-nodata.component';
import { MiniOffenseSummaryComponentModule } from '@member/peopleSearch/components/mini/offense-summary/offense.component';
import { MiniOffenseCardComponentModule } from '@member/peopleSearch/components/mini/offense-card/offense-card.component';
import {
  AdditionalResourcesComponent,
  AdditionalResourcesComponentModule,
} from '../additional-resources/additional-resources.component';
import { MemberUpsellGlobalComponentModule } from '@member/components/upsells/global/upsell-global.component';
import { AdComponentModule } from 'app/components/ad/ad.component';
import {
  MiniReportNotesComponent,
  MiniReportNotesComponentModule,
} from '@member/peopleSearch/components/mini/notes/notes.component';
import { MiniCriminalRecordsBaseComponent } from './miniCriminalRecordsBase.component';
import { FactFindersComponentModule } from '../factFinders/factFinders.component';

declare var $: any;

@Component({
    selector: 'app-mini-criminal-records',
    templateUrl: 'miniCriminalRecords.component.html',
    styleUrls: ['miniCriminalRecords.component.scss'],
    standalone: false
})
export class MiniCriminalRecordsComponent extends MiniCriminalRecordsBaseComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  
  constructor(
    public serviceHelperService: ServiceHelperService,
    public changeDetectorRef: ChangeDetectorRef,
    activatedRoute: ActivatedRoute,
    protected router: Router,
    public renderer: Renderer2,
    public toastr: ToastrService,
    public noteService: NoteService,
    public authService: AuthenticationService
  ) {
    super(serviceHelperService, changeDetectorRef, activatedRoute, router, renderer, toastr, noteService, authService);
  }

  ngOnInit() {
    return super.ngOnInit();    
  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
  }
  ngOnDestroy() {
    return super.ngOnDestroy();
  }
  ngOnChanges(changes: SimpleChanges): void {}
}

@NgModule({
  declarations: [MiniCriminalRecordsComponent],
  imports: [
    CommonModule,
    FactFindersComponentModule,
    MiniReportHeaderModule,
    MiniReportFooterModule,
    CriminalReportNoDataComponentModule,
    MiniOffenseSummaryComponentModule,
    MiniOffenseCardComponentModule,
    AdditionalResourcesComponentModule,
    MemberUpsellGlobalComponentModule,
    AdComponentModule,
    MiniReportNotesComponentModule,
  ],
  exports: [MiniCriminalRecordsComponent],
})
export class MiniCriminalRecordsComponentModule {}
