// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detail-row {
  padding: 5px 0;
}

.court-label, .court-value {
  padding-left: 0;
  margin: 4px 0;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/app/member/peopleSearch/nameSearch/detail/courtDetails/courtDetails.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ;;AACA;EACI,eAAA;EACA,aAAA;EACA,gBAAA;AAEJ","sourcesContent":[".detail-row{\n    padding: 5px 0;\n}\n.court-label, .court-value{\n    padding-left:0;\n    margin: 4px 0;        \n    font-weight: 500;\n}\n.court-value{\n    // font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
