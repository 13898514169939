/* tslint:disable:object-literal-shorthand */
import {Injectable} from '@angular/core';
import {JsonService} from './json.service';
import {ModelBase} from '../../common/models/modelBase';
import {Router} from '@angular/router';
import {ResponseEvent} from '../../common/event/responseEvent';
import {LogUtils} from '../../common/utils/logUtils';
import {DatabaseWrapperActions} from '../../common/wrapper/databaseWrapperActions';
import {serverPaths} from '../../common/helpers/pathHelpers';

@Injectable()
export class CensusDataService {
  /**
   *
   * @param jsonService to access localStorage for managing token etc.
   * @param router to access the routes
   */
  constructor(private jsonService: JsonService,
              private router: Router) {
  }

  /**
   * This Fn is to call census data API
   * @param city for which census data is required
   * @param state for which census data is required
   * @param zip for which census data is required
   * @param commerceContentId to be provided to census data API
   * @param uxcId ux config id to be provided to census data API
   * @param uxlId ux layout id to be provided to census data API
   * @param isRevPhone {boolean, default false} only true incase this function called from reverse phone
   */
  public findCensusCommunityData(city, state, zip, commerceContentId = '', uxcId = '', uxlId = '', isRevPhone = false): Promise<any> {
    let param: any = {
      city,
      state,
      zip,
      uxcId,
      uxlId,
      isRevPhone
    };
    if (commerceContentId) {
      param.commerceContentId = commerceContentId;
    }

    return this.jsonService.json(serverPaths.censusLinkFind, param).then((res: ResponseEvent) => {
      const doc = res.data;

      if (!doc || !doc?.value) {
        this.loggingHasNotValueInCensusData();
      }

      return doc;
    }).catch(err => {
      LogUtils.error(err);
      Promise.reject(false);
    });
  }

  private loggingHasNotValueInCensusData() {
    LogUtils.warn('value does not exist in the result of findCensusCommunityData.');
  }
}
