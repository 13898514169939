import {Component, Input, OnChanges, OnInit} from "@angular/core";
import {UxHelper} from "../../../../../clientCommon/helper/uxHelper";
import {ReportReviewPeopleSearch} from "../../../../peopleSearch/reportReviewPeopleSearch";

@Component({
  selector: 'ux-2-0-reportReview-name-search',
  templateUrl: './reportReview.component.html',
  styleUrls: ['./reportReview.component.scss'],
})
export class Ux2Dot0ReportReviewNameSearchComponent implements OnInit, OnChanges {
  @Input() base: ReportReviewPeopleSearch;
  @Input() uxHelper: UxHelper;

  constructor() {
  }

  init() {
  }

  ngOnInit() {
    return this.init();
  }

  ngOnChanges() {
    return this.init();
  }
}
