import {Particle1LandingNameSearchComponent} from "./landing.component";
import {Particle1LoaderNameSearchComponent} from "./loader.component";
import {Particle1SearchResultNameSearchComponent} from "./searchResult.component";
import {Particle1ReportReviewNameSearchComponent} from "./reportReview.component";
import {Particle1SignupNameSearchComponent} from "./signup.component";

export const PARTICLE_1_NAME_SEARCH_TEMPLATES_DECLARATIONS = [
  Particle1LandingNameSearchComponent,
  Particle1LoaderNameSearchComponent,
  Particle1SearchResultNameSearchComponent,
  Particle1ReportReviewNameSearchComponent,
  Particle1SignupNameSearchComponent,
];
