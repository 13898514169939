import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'courtType',
  pure: false,
})
export class CourtTypePipe implements PipeTransform {
  transform(items: any[], filter: Object): any {
    if (!items || !filter) {
      return items;
    }

    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return items.filter((item) => item.type == filter['type']);
  }
}

@NgModule({
    declarations: [CourtTypePipe],
    exports: [CourtTypePipe],
})
export class CourtTypePipeModule {}