// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  position: relative;
}

@media screen and (max-width: 479px) {
  img {
    width: 75% !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/themes/ux.2.0/components/salesHeader.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE;IACE,qBAAA;EACF;AACF","sourcesContent":[".header {\n  position: relative;\n}\n\n@media screen and (max-width: 479px) {\n  img {\n    width: 75% !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
