// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-box {
  min-height: 400px;
}

.use {
  position: relative;
  min-height: 40px;
  padding-bottom: 10px;

}
`, "",{"version":3,"sources":["webpack://./src/app/peopleSearch/templates/nameSearch/default.1.3/loader.component.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,oBAAoB;;AAEtB","sourcesContent":[".content-box {\n  min-height: 400px;\n}\n\n.use {\n  position: relative;\n  min-height: 40px;\n  padding-bottom: 10px;\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
