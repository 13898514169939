import {
  AfterViewInit,
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceHelperService } from '../../../../../../clientCommon/services/serviceHelper.service';
import { PersonDetail } from '../../../personDetail';
import { ToastrService } from '../../../../../service/toastr.service';
import { NoteService, AuthenticationService } from '../../../../../../clientCommon/services';
import { BaseComponent } from '../../../../../../clientCommon/components/BaseComponent';
import { peopleSearchProductKeys } from '../../../../../../common/custom/peopleSearch/peopleSearchProductKeys';
import { CommonModule } from '@angular/common';
import { SocialCardComponentModule } from '../miniSocialMedia/socialCard/socialCard.component';
import { MiniReportHeaderModule } from '@member/peopleSearch/components/mini/header/header.component';
import { AdditionalResourcesComponentModule } from '../additional-resources/additional-resources.component';
import { CourtDetailsComponentModule } from '../courtDetails/courtDetails.component';
import { MemberUpsellCourtComponentModule } from '@member/components/upsells/court/upsell-court.component';
import { CourtTypePipeModule } from '../courtType.pipe';
import { MemberUpsellGlobalComponentModule } from '@member/components/upsells/global/upsell-global.component';
import { MiniReportNotesComponentModule } from '@member/peopleSearch/components/mini/notes/notes.component';
import { MiniReportFooterModule } from '@member/peopleSearch/components/mini/footer/footer.component';
import { AdComponentModule } from 'app/components/ad/ad.component';
import { MemberUpsellPremiumBadgeComponentModule } from '@member/components/upsells/premium-badge/premium-badge.component';
import { SwiperModule } from '../../../../../../clientCommon/directives/SwiperDirective';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { MiniCareerFinancialBaseComponent } from './miniCareerFinancialBase.component';
import { FactFindersComponentModule } from '../factFinders/factFinders.component';

declare var $: any;

@Component({
    selector: 'app-mini-career-financial',
    templateUrl: 'miniCareerFinancial.component.html',
    styleUrls: ['miniCareerFinancial.component.scss'],
    standalone: false
})
export class MiniCareerFinancialComponent extends MiniCareerFinancialBaseComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  constructor(
    public serviceHelperService: ServiceHelperService,
    public changeDetectorRef: ChangeDetectorRef,
    activatedRoute: ActivatedRoute,
    protected router: Router,
    public renderer: Renderer2,
    public toastr: ToastrService,
    public noteService: NoteService,
    public authService: AuthenticationService
  ) {
    super(serviceHelperService, changeDetectorRef, activatedRoute, router, renderer, toastr, noteService, authService);
  }

  ngOnInit() {
    return super.ngOnInit();
  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
  }
  ngOnDestroy() {
    return super.ngOnDestroy();
  }
  ngOnChanges(changes: SimpleChanges): void {}
}

@NgModule({
  declarations: [MiniCareerFinancialComponent],
  imports: [
    CommonModule,
    FactFindersComponentModule,
    CourtTypePipeModule,
    SocialCardComponentModule,
    MiniReportHeaderModule,
    AdditionalResourcesComponentModule,
    CourtDetailsComponentModule,
    MemberUpsellCourtComponentModule,
    MemberUpsellGlobalComponentModule,
    MiniReportNotesComponentModule,
    MiniReportFooterModule,
    AdComponentModule,
    MemberUpsellPremiumBadgeComponentModule,
    SwiperModule,
  ],
  exports: [MiniCareerFinancialComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class MiniCareerFinancialComponentModule {}
