export class UserInput {
  // Life events input
  lifeEvents?: string;

  fname?: string;
  mname?: string;
  lname?: string;
  extId?: string;

  street?: string;
  city?: string;
  state?: string;
  zip?: string;

  phone?: string;
  searchObjectId?: any;

  isMe?: boolean;
  gender?: string;
  age?: number;

  email?: string;
  content?: any;
  questions?: any;
  raw?: boolean;

  from?;

  rfname?: string;
  rlname?: string;
  minAge?: number;
  maxAge?: number;


  constructor(doc?) {
    if (doc) {
      Object.assign(<any>this, doc);
    }
  }
}

