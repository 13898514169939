import {Component, Input, NgModule} from "@angular/core";

@Component({
  selector: 'ux-5-2-content-card',
  templateUrl: 'content-card.component.html',
  styleUrls: ['./content-card.component.scss'],
})
export class ContentCardComponent {
  @Input() imageUrl: string;
  @Input() title: string;
  @Input() options: string[];
}
