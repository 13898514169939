import { Component, Input, NgModule, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseDirective } from '../../../clientCommon/directives/BaseDirective';
import { ServiceHelperService } from '../../../clientCommon/services/serviceHelper.service';
import { UxHelper } from '../../../clientCommon/helper/uxHelper';
import { UxTemplateModule } from 'app/components/uxTemplate.component';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-member-footer',
    templateUrl: 'memberFooter.component.html',
    styleUrls: ['./memberFooter.component.scss'],
    standalone: false
})
export class MemberFooterComponent extends BaseDirective implements OnInit {
  @Input() uxHelper: UxHelper;
  @Input() showFooter: boolean = false;
  @Input() page: string = '';
  @Input() pageType: string = '';
  @Input() pageCategory: string = '';

  constructor(serviceHelperService: ServiceHelperService, activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
  }

  ngOnInit() {
    return super.baseInit();
  }
}

@NgModule({
  declarations: [MemberFooterComponent],
  imports: [CommonModule, UxTemplateModule],
  exports: [MemberFooterComponent],
})
export class MemberFooterComponentModule {}
