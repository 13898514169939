import {Component, EventEmitter, Input, Output, HostListener} from "@angular/core";
import { SearchedPerson } from "@common/custom/models/peopleSearch/searchedPerson";
import { SearchResultPeopleSearch } from "@peopeSearch/searchResultPeopleSearch";


@Component({
  selector: 'search-result-item',
  templateUrl: './searchResultItem.component.html',
  styleUrls: ['./searchResultItem.component.css'],
})
export class SearchResultItemComponent {
  @Input() base: SearchResultPeopleSearch;
  @Input() searchedPerson: SearchedPerson;
  @Output() onClick = new EventEmitter<SearchedPerson>();

  isMobile = window.innerWidth <= 534;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobile = event.target.innerWidth <= 534;
  }
  
  getName() {
    let name = this.searchedPerson.fName+' '+this.searchedPerson.mName+' '+this.searchedPerson.lName;
    return name;
  }
}
