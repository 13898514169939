import {redirectHelper} from "./redirectHelper";
import {ServiceHelperService} from "../services/serviceHelper.service";
import {LogUtils} from "../../common/utils/logUtils";
import {NavigationEnd} from "@angular/router";

class BehaviorHelper {
  sessionHistoryStorageKey = "sessionHistory";

  /**
   * @param serviceHelperService to access different services like auth service
   * @param $event is an event object to get event info of the clicked html elment
   * @param option have optional params for url, propagation etc.
   */
  click(serviceHelperService: ServiceHelperService, $event, option?: { type?: string, target?: string, url?: string, extra?, promise?, newWindow?, propagation?: boolean }) {
    let param: any = {
      path: location.pathname,
    };

    // set the params, if there are some params in option
    if (option) {
      if (option.type) {
        param.type = option.type;
      }

      if (option.url) {
        param.url = option.url;
      }

      if (option.target) {
        param.target = option.target;
      }

      if (option.extra) {
        param.extra = option.extra;
      }
    }

    if (!param.type) {
      param.type = "click";
    }

    if (!param.url) {
      let path = this.getFromEvent($event, "href", true);
      let prefix = window.location.protocol + "//" + window.location.host;
      if (path) {
        path = path.replace(prefix, ""); // removing protocol + hostname
        path = path.replace(/#.*/, ""); // removing hash
        if (path) {
          param.url = path;
        }
      }

      let hash = this.getFromEvent($event, "hash", true);
      if (hash) {
        param.hash = hash;
      }
    }

    param.ids = this.getFromEvent($event, "id", false);
    if (param.url === '/' && param.hash) {
      param.hashOnly = true;
    }

    if (param.url) {
      serviceHelperService.spinnerService.spin();
    }

    // scroll to hash if its hash only else open a new window
    let redirect = false;
    let url = param.url + (param.hash ? param.hash : "");
    if (param.url) {
      serviceHelperService.spinnerService.unspin();
      if (param.hashOnly) {
        this.goToHash(param.hash);
      } else if (option && option.newWindow) {
        window.open(url, '_blank');
      } else {
        redirect = true;
      }
    }

    return serviceHelperService.trackingService.report(param).catch((e) => {
      LogUtils.error(e);
    }).then(() => {
      if (redirect) {
        return redirectHelper.redirect(serviceHelperService, url);
      }
    });
  }

  /**
   * this will scroll the hash element parent container
   * @param hash toward which scroll is required
   */
  goToHash(hash) {
    try {
      document.getElementById(hash.substring(1)).scrollIntoView();
    } catch (e) {
      LogUtils.error(e, hash);
    }

  }

  /**
   *
   * @param $event to get its targeted element
   * @param property of target element i.e href, hash, id 
   * @param stopOnFound is a boolean
   * @returns path array having element properties
   */
  getFromEvent($event, property, stopOnFound) {
    let element = $event.target;

    var path = [];
    while (element) {
      try {
        if (element[property]) {
          path.push(element[property]);
          if (stopOnFound) {
            break;
          }
        }
        element = element.parentElement;
      } catch (e) {
      }
    }
    if (stopOnFound) {
      return path[0];
    } else {
      return path;
    }
  }

  /**
   * @param serviceHelperService to access different services like storage service service
   * @returns visited history stored in session storage with sessionHistoryStorageKey
   */
  getHistory(serviceHelperService: ServiceHelperService): any {
    let history = serviceHelperService.storageService.getSession(this.sessionHistoryStorageKey);

    if (!history) {
      history = {};
      this.setHistory(serviceHelperService, history)
    }

    return history;
  }

  /**
   * @functionOverview set the visited history in sessionStorage against the key of sessionHistoryStorageKey
   * @param serviceHelperService to access different services like storage service service
   * @param history visited history to be store in session storage with sessionHistoryStorageKey
   */
  setHistory(serviceHelperService: ServiceHelperService, history) {
    serviceHelperService.storageService.setSession(this.sessionHistoryStorageKey, history);
  }

  /**
   * @functionOverview set the visited history location in sessionStorage against the key sessionHistoryStorageKey
   * @param serviceHelperService to access different services like storage service service
   * @param location object key to be checked inside found history from session storage
   */
  setHistoryLocation(serviceHelperService: ServiceHelperService, location) {
    let history = serviceHelperService.storageService.getSession(this.sessionHistoryStorageKey);
    if (!history) {
      history = {};
    }
    if (!history?.location) {
      history.location = {};
    }
    if (!location) {
      location = 'invalid.location.specified';
    }
    history.location[location] = true;
    serviceHelperService.storageService.setSession(this.sessionHistoryStorageKey, history);
  }

  /**
   * @param serviceHelperService to access different services like storage service service
   * @param location object key to be checked inside found history from session storage
   * @returns true only if found history from session storage have location value found against the object key 'location' provided in param
   */
  hasHistoryLocation(serviceHelperService: ServiceHelperService, location) {
    let history = serviceHelperService.storageService.getSession(this.sessionHistoryStorageKey);
    if (!history) {
      history = {};
    }
    if (!history?.location) {
      history.location = {};
    }
    return !!history.location[location];
  }

  /**
   * @functionOverview this set the default history, if visited history stored in session storage with sessionHistoryStorageKey is not found
   * @param serviceHelperService to access different services like router events
   */
  startCheckingHistory(serviceHelperService: ServiceHelperService) {
    serviceHelperService.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        let history = this.getHistory(serviceHelperService);
        if (!history.landing) {
          history = {
            landing: window.location.href,
            pathname: window.location.pathname,
          };
          this.setHistory(serviceHelperService, history);
        }
      }
    });

    // On back button hit
    serviceHelperService.location.subscribe((x) => {
      if (x && x.pop && x.type === 'popstate') {
        serviceHelperService.trackingService.report({type: "pop"});

        let history = this.getHistory(serviceHelperService);
        if (!history.backCount) {
          history.backCount = 0;
        }
        history.backCount++;
        this.setHistory(serviceHelperService, history);
      }
    });

  }
}

export var behaviorHelper = new BehaviorHelper();
