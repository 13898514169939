import {ModelBase} from "../../../models/modelBase";

export class SearchedObject<OBJECT> extends ModelBase<OBJECT> {

  extId: string;
  providers: string[];
  productKey: string;
  raw;
  updateTimestamp: number;

  constructor(doc?, draftFlag?: boolean) {
    super(doc, draftFlag);
    this.init(doc, this.draftFlag);
  }
}
