import {LandingPhoneSearchSalesComponent} from "./landing/landing.component";
import {LoaderPhoneSearchSalesComponent} from "./loader/loader.component";
import {SearchResultPhoneSearchSalesComponent} from "./searchResult/searchResult.component";
import { ReportReviewPhoneSearchSalesComponent } from "./reportReview/reportReview.component";
import { SignupPhoneSearchSalesComponent } from "./signup/signup.component";

export const PHONE_SEARCH_SALES_DECLARATIONS = [
  LandingPhoneSearchSalesComponent,
  LoaderPhoneSearchSalesComponent,
  ReportReviewPhoneSearchSalesComponent,
  SearchResultPhoneSearchSalesComponent,
  SignupPhoneSearchSalesComponent,
];

