import {isFunction} from "util";
export class PropertyDecoratorHelper {
    private properties: any = {};
    private hasValue = false;
    private static classes = [];

    getId(target) {
        let index = PropertyDecoratorHelper.classes.indexOf(target);
        if (index === -1) {
            PropertyDecoratorHelper.classes.push(target);
            index = PropertyDecoratorHelper.classes.indexOf(target);
        }

        return index;
    }

    public addProperties(target: any, key: string, value?: any) {
        let index = this.getId(target);

        if (!this.properties[index]) {
            this.properties[index] = [];
        }

        if (value) {
            this.hasValue = true;
        }

        this.properties[index].push({key: key, value: value});
    }

    public getClassById(id) {
        return PropertyDecoratorHelper.classes[id];
    }

    public getIds(obj): number[] {
        let ids: number[] = [];
        if (isFunction(obj)) {
            obj = new obj;
        }
        PropertyDecoratorHelper.classes.forEach((target) => {
            if (target.isPrototypeOf(obj)) {
                ids.push(this.getId(target));
            }
        });

        return ids;
    }

    public getProperties(obj, shapeFunction?: Function) {
        let properties = [];
        let indexes = this.getIds(obj);

        indexes.forEach((id) => {
            let property = this.properties[id];
            if (property) {
                if (this.hasValue) {
                    if (shapeFunction) {
                        properties = properties.concat(shapeFunction(property));
                    } else {
                        properties = properties.concat(property);
                    }
                } else {
                    property.forEach((value) => {
                        if (shapeFunction) {
                            properties.push(shapeFunction(value.key));
                        } else {
                            properties.push(value.key);
                        }
                    });
                }
            }
        });

        return properties;
    }

    public getAllProperties() {
        return this.properties;
    }
}
