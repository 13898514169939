import { ExtraOptions, NoPreloading, RouterModule, Routes, UrlSegment } from '@angular/router';
import { LoaderNameSearchSalesComponent } from './sales/peopleSearch/nameSearch/loader/loader.component';
import { clientPaths } from '../common/helpers/pathHelpers';
import { AuthGuard } from './guards/auth.guard';
import { CustomerAuthGuard } from './guards/customer.auth.guard';
import { LandingNameSearchSalesComponent } from './sales/peopleSearch/nameSearch/landing/landing.component';
import { ReportReviewNameSearchSalesComponent } from './sales/peopleSearch/nameSearch/reportReview/reportReview.component';
import { SearchResultNameSearchSalesComponent } from './sales/peopleSearch/nameSearch/searchResult/searchResult.component';
import { SignupNameSearchSalesComponent } from './sales/peopleSearch/nameSearch/signup/signup.component';
import { UnsubscribeComponent } from './mail/unsubscribe.component';
import { ClickTrackingComponent } from './mail/clickTracking.component';
import { Mpi3dsParesComponent } from './misc/mpi3dsPares.component';
import { SignupPhoneSearchSalesComponent } from './sales/peopleSearch/phoneSearch/signup/signup.component';
import { LoaderPhoneSearchSalesComponent } from './sales/peopleSearch/phoneSearch/loader/loader.component';
import { ReportReviewPhoneSearchSalesComponent } from './sales/peopleSearch/phoneSearch/reportReview/reportReview.component';
import { SearchResultPhoneSearchSalesComponent } from './sales/peopleSearch/phoneSearch/searchResult/searchResult.component';
import { LandingPhoneSearchSalesComponent } from './sales/peopleSearch/phoneSearch/landing/landing.component';
import { LandingSalesComponent } from './sales/landingSales.component';
import { LandingIntermediateComponent } from './misc/landingIntermediate.component';
import { customClientPaths } from '../common/custom/customPathHelpers';
import { CustomPageComponent } from './misc/customPage.component';
import { customPathChanger } from '../clientCommon/utils/customPathChanger';
import { LandingAffilateComponent } from '@sales/affiliate/affiliate.componet';
import { CareersPage } from './themes/2234200bd3cc36ba97274ecbdc6c6a47/careers/shell-web/pages/careers/careers.page';
import { LandingAffilateFormComponent } from '@sales/affiliate/affiliateForm.component';
import { ReferralLinkComponent } from '@sales/referral.component';


import { ShareMiniReportLinkComponent } from '@sales/share.component';

customPathChanger.init();

const appRoutes: Routes = [
  /*************************************************************************************************
   * Sales
   *************************************************************************************************/

  { path: '', component: LandingSalesComponent },
  { path: clientPaths.landingIntermediate, component: LandingIntermediateComponent },

  // Name Search
  { path: customClientPaths.salesNameSearchLanding, component: LandingNameSearchSalesComponent },
  { path: customClientPaths.salesNameSearchLoader, component: LoaderNameSearchSalesComponent },
  { path: customClientPaths.salesNameSearchReportReview, component: ReportReviewNameSearchSalesComponent },
  { path: customClientPaths.salesNameSearchSearchResult, component: SearchResultNameSearchSalesComponent },
  { path: customClientPaths.salesNameSearchSignup, component: SignupNameSearchSalesComponent },

  // Phone Search
  { path: customClientPaths.salesPhoneSearchLanding, component: LandingPhoneSearchSalesComponent },
  { path: customClientPaths.salesPhoneSearchLoader, component: LoaderPhoneSearchSalesComponent },
  { path: customClientPaths.salesPhoneSearchReportReview, component: ReportReviewPhoneSearchSalesComponent },
  { path: customClientPaths.salesPhoneSearchSearchResult, component: SearchResultPhoneSearchSalesComponent },
  { path: customClientPaths.salesPhoneSearchSignup, component: SignupPhoneSearchSalesComponent },

  // Affilate page
  { path: customClientPaths.affiliateLanding, component: LandingAffilateComponent },
  { path: customClientPaths.affiliateLandingForm, component: LandingAffilateFormComponent },
  // Referral Link
  { path: customClientPaths.referral+ '/:target', component: ReferralLinkComponent },
  { path: clientPaths.careers, component: CareersPage },

  // Share minireport link
  { path: customClientPaths.shareLink+'/:target', component: ShareMiniReportLinkComponent },

  // Property Search
  {
    path: customClientPaths.property,
    loadChildren: () => import('./propertySearch/propertySearch.module').then(m => m.PropertySearchModule),
  },

  // Name Search OptOut
  { 
    path: customClientPaths.salesNameSearchOptOutLanding,
    loadChildren: () => import('./sales/peopleSearch/optOut/nameSearch/landing.component').then(m => m.LandingNameSearchOptOutSalesModule),
  },
  { 
    path: customClientPaths.salesNameSearchOptOutLoader,
    loadChildren: () => import('./sales/peopleSearch/optOut/nameSearch/loader.component').then(m => m.LoaderNameSearchOptOutSalesModule),
  },
  { 
    path: customClientPaths.salesNameSearchOptOutSearchResult,
    loadChildren: () => import('./sales/peopleSearch/optOut/nameSearch/searchResult/searchResult.component').then(m => m.SearchResultNameSearchOptOutSalesModule),
  },
  { 
    path: customClientPaths.salesNameSearchOptOutInfoInput,
    loadChildren: () => import('./sales/peopleSearch/optOut/nameSearch/infoInput.component').then(m => m.InfoInputNameSearchOptOutSalesModule),
  },
  { 
    path: clientPaths.optOutConfirmation,
    loadChildren: () => import('./sales/peopleSearch/optOut/nameSearch/confirmation.component').then(m => m.ConfirmationNameSearchOptOutSalesModule),
  },

  // CONFIRMATION_INTERMEDIATE, CPCC, CPSSI, CONFIRMATION
  { 
    matcher: (url: UrlSegment[]) => {
      // Defer to submodule. (consumed: [])
      if (url.length === 1 && url[0].path === clientPaths.cpccLoading) {
        return {consumed: []};
      }

      return url.length === 2 && [
        clientPaths.confirmationIntermediate,
        clientPaths.cpcc,
        clientPaths.addon,
        clientPaths.confirmation,
      ].includes(url[0].path) ? {consumed: []} : null;
    },
    loadChildren: () => import('./sales/confirmation').then(m => m.SUPPostProcessingModule),
  },

  // Review Site
  { 
    path: customClientPaths.salesReviewPeopleSearchList,
    loadChildren: () => import('./sales/review/peopleSearch/list.component').then(m => m.ListPeopleSearchReviewSalesModule),
  },
  {
    path: customClientPaths.salesReviewPeopleSearchDetail + '/:target',
    loadChildren: () => import('./sales/review/peopleSearch/detail.component').then(m => m.DetailPeopleSearchReviewSalesModule),
  },

  // MISC
  { 
    matcher: (url: UrlSegment[]) => {
      return url.length === 1 && [
        clientPaths.contactUs,
        clientPaths.becomeAnAffiliate,
        clientPaths.contactUsFull
      ].includes(url[0].path) ? {consumed: url} : null;
    },
    loadChildren: () => import('./sales/contactUs.component').then(m => m.ContactUsModule),
  },
  { 
    path: clientPaths.refund,
    loadChildren: () => import('./sales/refund.component').then(m => m.RefundModule),
  },
  { 
    path: clientPaths.terms, 
    loadChildren: () => import('./sales/terms.component').then(m => m.TermsModule),
  },
  { 
    path: clientPaths.privacy, 
    loadChildren: () => import('./sales/privacy.component').then(m => m.PrivacyModule),
  },
  { 
    path: clientPaths.article + '/:type/:category/:key',
    loadChildren: () => import('./sales/article.component').then(m => m.ArticleModule),
  },
  { 
    path: clientPaths.page + '/:key',
    loadChildren: () => import('./sales/page.component').then(m => m.PageModule),
  },
  { 
    path: clientPaths.content + '/:id/:key/:type/:productKey/:option',
    loadChildren: () => import('./sales/misc/content/content.component').then(m => m.ContentModule),
  },
  { 
    path: clientPaths.unsubscribe, 
    loadChildren: () => import('./sales/unsubscribe.component').then(m => m.SalesUnsubscribeModule),
  },
  { 
    path: clientPaths.suppressionList, 
    loadChildren: () => import('./mail/suppressionList.component').then(m => m.SuppressionListModule),
  },

  { 
    path: clientPaths.optout, 
    loadChildren: () => import('./sales/misc/optout/optout.module').then(m => m.OptoutModule),
  },

  // Saved Reports
  {
    path: clientPaths.savedReportsPage,
    loadChildren: async () => (await import('@member/components/mhpFlow')).SavedReportsPageModule,
    canActivate: [AuthGuard, CustomerAuthGuard],
  },

  /*************************************************************************************************
   * Member
   *************************************************************************************************/

  {
    path: clientPaths.member,
    loadChildren: async () => (await import('@member/member-feature-shell-web.module')).MemberFeatureShellWebModule,
  },

  //MISC
  { path: clientPaths.mailUnsubscribe, component: UnsubscribeComponent },
  { path: clientPaths.mailClick, component: ClickTrackingComponent },
  { path: clientPaths.mpi3dsPares, component: Mpi3dsParesComponent },

  // otherwise redirect to home
  { path: '**', component: CustomPageComponent },
];

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
  preloadingStrategy: NoPreloading, // Dev#1538 The default is NoPreloading, but explicitly
};

export const routing = RouterModule.forRoot(appRoutes, routerOptions);
