import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../../../../clientCommon/components/BaseComponent';
import { LoaderPeopleSearchMember } from '../loaderPeopleSearchMember';
import { ServiceHelperService } from '../../../../clientCommon/services/serviceHelper.service';
import { LogUtils } from '../../../../common/utils/logUtils';
import { phoneUtils } from '@common/utils/phoneUtils';

@Component({
  templateUrl: 'loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderPhoneSearchMemberComponent extends LoaderPeopleSearchMember implements OnInit, OnDestroy {
  constructor(serviceHelperService: ServiceHelperService, activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
    this.pageCategory = BaseComponent.PAGE_CATOGORY.phoneSearch;
  }

  searchedPhone;

  ngOnInit() {
    this.searchedPhone = phoneUtils.formatPhoneNumber(
      this?.commerceContent?.content?.[0]?.contentInfo?.phone 
        ?? this.serviceHelperService.userInputService.getUserInput()?.phone
        ?? ''
    );

    return this.onInit().catch((e) => {
      LogUtils.error(e);
      // return redirectHelper.redirect(this.serviceHelperService, '/' + this.clientPaths.member);
      return this.next();
    });
  }

  ngOnDestroy() {
    return super.onDestroy();
  }
}
