import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter, NgModule } from '@angular/core';

@Component({
  selector: 'app-social-card',
  templateUrl: 'socialCard.component.html',
  styleUrls: ['socialCard.component.scss'],
})
export class SocialCardComponent implements OnInit {
  @Input() name: string = '';
  @Input() social: any;
  @Input() career: any;
  @Input() color: string = '';
  @Input() imgSrc: string = '';
  @Input() cardIcon: string = '';
  @Input() cardLabel: string = '';
  @Input() signUpUrl: string = '';
  @Input() isPdfPrintView: boolean = false;
  @Output() onClick = new EventEmitter<any>();
  colorArr: Array<String> = ['#F8DA8A', '#62D6C3', '#5F95DC', '#B0A8E7', '#EEA98B', '#B6B7BC'];
  socialMediaSignUpUrls: Array<any> = [
    {
      service: 'twitter',
      url: 'https://twitter.com/i/flow/signup',
      loginURL: 'https://www.twitter.com/login',
    },
    {
      service: 'FB',
      url: 'https://www.facebook.com/',
      loginURL: 'https://www.facebook.com/login/',
    },
    {
      service: 'instagram',
      url: 'https://www.instagram.com/accounts/emailsignup/?hl=en',
      loginURL: 'https://www.instagram.com/accounts/login',
    },
    {
      service: 'linkedin',
      url: 'https://www.linkedin.com/signup/cold-join?trk=guest_homepage-basic_nav-header-join',
      loginURL: 'https://www.linkedin.com/login',
    },
  ];

  constructor() {}

  ngOnInit() {}

  isEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }

    return JSON.stringify(obj) === JSON.stringify({});
  }

  getUrl(service) {
    for (let i = 0; i < this.socialMediaSignUpUrls.length; i++) {
      if (this.socialMediaSignUpUrls[i].service == service) {
        window.open(this.socialMediaSignUpUrls[i].url, '_blank');
      }
    }
  }
  getLoginUrl(service) {
    for (let i = 0; i < this.socialMediaSignUpUrls.length; i++) {
      if (this.socialMediaSignUpUrls[i].service == service) {
        window.open(this.socialMediaSignUpUrls[i].loginURL, '_blank');
      }
    }
  }
}

@NgModule({
  declarations: [SocialCardComponent],
  imports: [CommonModule],
  exports: [SocialCardComponent],
})
export class SocialCardComponentModule {}
