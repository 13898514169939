import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, NgModule } from '@angular/core';
import { PaperFormat } from '@ux5.2/types';

@Component({
  selector: 'ux-5-2-paper',
  templateUrl: './paper.component.html',
  styleUrls: ['./paper.component.scss']
})
export class PaperComponent implements OnInit {
  @Input() format: PaperFormat = null;
  @Input() class: Array<string> = [];
  constructor() { }

  ngOnInit(): void {
    if (this.format && PaperFormat[this.format]) this.class.push(PaperFormat[this.format]);
  }
}

// @NgModule({
//   declarations: [PaperComponent],
//   imports: [CommonModule],
//   exports: [PaperComponent],
// })
// export class PaperComponentModule {}
