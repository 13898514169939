// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mini-footer {
  padding: 70px 0 40px;
}
@media (max-width: 990px) {
  .mini-footer {
    padding: 54px 0 32px;
  }
}
@media (max-width: 576px) {
  .mini-footer {
    padding: 40px 0 24px;
  }
}
@media (max-width: 374px) {
  .mini-footer {
    padding: 40px 0 24px;
  }
}
.mini-footer__grid {
  display: flex;
  flex-flow: row wrap;
  margin-left: -30px;
  margin-right: -30px;
}
@media (max-width: 374px) {
  .mini-footer__grid {
    margin-left: -25px;
    margin-right: -25px;
  }
}
.mini-footer__grid:before {
  display: block;
}
.mini-footer__grid--single {
  margin-bottom: 15px;
}
.mini-navigation {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 990px) {
  .mini-navigation {
    margin-top: 32px;
  }
}
@media (max-width: 576px) {
  .mini-navigation {
    margin-top: 24px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/member/peopleSearch/components/mini/footer/footer.component.scss"],"names":[],"mappings":"AACE;EACE,oBAAA;AAAJ;AAEI;EAHF;IAII,oBAAA;EACJ;AACF;AACI;EAPF;IAQI,oBAAA;EAEJ;AACF;AAAI;EAXF;IAYI,oBAAA;EAGJ;AACF;AADI;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;AAGN;AADM;EANF;IAOI,kBAAA;IACA,mBAAA;EAIN;AACF;AAFM;EACE,cAAA;AAIR;AADM;EACE,mBAAA;AAGR;AAEE;EACE,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAAJ;AAEI;EANF;IAOI,gBAAA;EACJ;AACF;AACI;EAVF;IAWI,gBAAA;EAEJ;AACF","sourcesContent":[".mini {\n  &-footer {\n    padding: 70px 0 40px;\n\n    @media (max-width: 990px) {\n      padding: 54px 0 32px;\n    }\n\n    @media (max-width: 576px) {\n      padding: 40px 0 24px;\n    }\n\n    @media (max-width: 374px) {\n      padding: 40px 0 24px;\n    }\n\n    &__grid {\n      display: flex;\n      flex-flow: row wrap;\n      margin-left: -30px;\n      margin-right: -30px;\n\n      @media (max-width: 374px) {\n        margin-left: -25px;\n        margin-right: -25px;\n      }\n\n      &:before {\n        display: block;\n      }\n\n      &--single {\n        margin-bottom: 15px;\n      }\n    }\n  }\n\n  &-navigation {\n    margin-top: 40px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    @media (max-width: 990px) {\n      margin-top: 32px;\n    }\n\n    @media (max-width: 576px) {\n      margin-top: 24px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
