import {Component, OnDestroy, OnInit} from "@angular/core";
import {BaseComponent} from "../../clientCommon/components/BaseComponent";
import {ServiceHelperService} from "../../clientCommon/services/serviceHelper.service";
import {ActivatedRoute} from "@angular/router";
import {LogUtils} from "../../common/utils/logUtils";
import {redirectHelper} from "../../clientCommon/helper/redirectHelper";

@Component({
    template: '<div></div>',
    standalone: false
})
export class LandingIntermediateComponent extends BaseComponent implements OnInit, OnDestroy {

  constructor(serviceHelperService: ServiceHelperService,
              activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
    this.pageType = BaseComponent.PAGE_TYPE.general;
    this.page = BaseComponent.PAGE.landingIntermediate;
    this.pageCategory = BaseComponent.PAGE_CATOGORY.general;
  }

  ngOnInit() {
    return super.baseInit().then(() => {
      let visited = this.hasHistory();
      this.setHistory();
      let url = "";
      let bounceUrl = this.uxHelper.getValue("comp.sales.general.landing.bounce.route");

      if ((!visited) || (!bounceUrl)) {
        url = this.uxHelper.getValue("comp.sales.general.landing.route");
        if (!url) {
          LogUtils.error("No url specified", this.uxComposite.uxConfigId, this.uxComposite.uxLayoutId);
        } else {
          if (window.location.search) {
            url += window.location.search;
          }
          redirectHelper.redirect(this.serviceHelperService, url);
        }
      } else {
        url = bounceUrl;
        let param = {type: "bounceRedirect", url: url};
        this.serviceHelperService.trackingService.report(param).catch((e) => {
          LogUtils.error(e);
        });
        this.serviceHelperService.spinnerService.spin();
        redirectHelper.redirect(this.serviceHelperService, url);
      }
    });
  }

  ngOnDestroy() {
    return super.onDestroy();
  }

}

