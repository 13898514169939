import {
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  EventEmitter,
  Input,
  HostListener,
  NgModule,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ServiceHelperService } from '../../../../../../clientCommon/services/serviceHelper.service';
import { PersonDetail } from '../../../personDetail';
import { ToastrService } from '../../../../../service/toastr.service';
import { BaseComponent } from '../../../../../../clientCommon/components/BaseComponent';
import { peopleSearchProductKeys } from '../../../../../../common/custom/peopleSearch/peopleSearchProductKeys';
import { SearchedPerson } from '../../../../../../common/custom/models/peopleSearch/searchedPerson';
import { AuthenticationService, NoteService } from '../../../../../../clientCommon/services';
import { User } from '../../../../../../common/models/user/user';
import { SpyIdHelper } from '../../../../../../clientCommon/helper/spyIdHelper';
import { CommonModule } from '@angular/common';
import { MiniReportHeaderModule } from '@member/peopleSearch/components/mini/header/header.component';
import { MemberUpsellGlobalComponentModule } from '@member/components/upsells/global/upsell-global.component';
import { MiniReportFooterModule } from '@member/peopleSearch/components/mini/footer/footer.component';
import { TutorialModalComponentModule } from '../tutorialModal/tutorialModal.component';
import { MiniReportDwbBreachesComponentModule } from '@member/peopleSearch/components/mini/dwb-breaches/dwb-breaches.component';
import { MiniReportShareReportComponentModule } from '@member/peopleSearch/components/mini/share-report/share-report.component';
import { MiniReportNotesComponentModule } from '@member/peopleSearch/components/mini/notes/notes.component';
import { AdComponentModule } from 'app/components/ad/ad.component';
import { MiniReportAdProtectionComponentModule } from '@member/peopleSearch/components/mini/ad/ad-protection.component';
import { UxTemplateModule } from 'app/components/uxTemplate.component';

declare var $: any;

@Component({
  selector: 'app-mini-linear',
  templateUrl: 'miniLinear.component.html',
})
export class MiniLinearComponent extends PersonDetail implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  href: string = '';
  showAllEmail: boolean = false;
  thisIsMe = null;
  user: User;
  alreadyClaimed = false;
  isEmailCheck = false;
  @Input() isPdfPrintView: boolean = false;
  @Output() showLoader = new EventEmitter<void>();
  processedPersons: SearchedPerson[] = [];
  commerceStorage;
  idProtectionEnabled = false;
  
  spyIdHelper = new SpyIdHelper();
  @ViewChild('scrollToTop', { static: true })
  scrollToTopBtnElement: ElementRef;

  
  constructor(
    public serviceHelperService: ServiceHelperService,
    public changeDetectorRef: ChangeDetectorRef,
    activatedRoute: ActivatedRoute,
    public renderer: Renderer2,
    private router: Router,
    public toastr: ToastrService,
    public noteService: NoteService,
    public authService: AuthenticationService
  ) {
    super(serviceHelperService, activatedRoute, toastr, noteService, authService);
  }

  ngOnInit() {
    this.showAllEmail = this.isPdfPrintView;
    this.href = this.router.url;
    window.scrollTo(0, 0);
    return this.linearInit().then(() => {
      this.serviceHelperService.trackingService.report({
        page: BaseComponent.PAGE.detail,
        pageType: BaseComponent.PAGE_TYPE.member,
        type: peopleSearchProductKeys.nameSearch,
        section: 'linear',
        action: 'view',
      });
      this.user = this.serviceHelperService.authenticationService.getUser();

      this.serviceHelperService.commerceService.getStorageAsObservable().subscribe((commerceStorage) => {

        if (this.spyIdHelper.isIdProtectionEnabled(commerceStorage)) {
          const idProtectionFrontEnabled = this.uxHelper.getValue("comp.member.idProtection.enable") === 'true'? true: false;
          const idProtectionBackendEnabled = this.uxHelper.getValue("comp.member.idProtection.registration.enable") === 'true'? true: false;
          if (idProtectionBackendEnabled && idProtectionFrontEnabled) {
            this.idProtectionEnabled = true;
          }
        }
        this.commerceStorage = commerceStorage;
        this.thisIsMe = commerceStorage?.reportClaimedContentId == this.commerceContent?._id ? true : false;
        this.alreadyClaimed = !this.thisIsMe && commerceStorage?.reportClaimedContentId;
      });

      this.isEmailCheck = this.getEmails().length > 0 ? true: false;
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy() {
    return super.onDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngAfterViewInit() {
    // Register scroll to top event listener.
    const screenHeight = window.screen.availHeight;
    window.onscroll = () => {
      if (document.body.scrollTop > screenHeight || document.documentElement.scrollTop > screenHeight) {
        this.renderer.setStyle(this.scrollToTopBtnElement.nativeElement, 'display', 'flex');
      } else {
        this.renderer.setStyle(this.scrollToTopBtnElement.nativeElement, 'display', 'none');
      }
    };
  }
  scrollToTopHandler() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
  linearInit() {
    // Tooltip init
    setTimeout(() => {
      $('.tooltip-container').tooltip({
        html: true,
        trigger: 'click',
      });
    }, 0);
    return super.init();
  }

  @HostListener('mousewheel', ['$event']) onMousewheel(event) {
    //this.router.navigate([]);
  }

  getEmails() {
    return this.person.emailAddresses.map((item) => item.address);
  }

  getFirstEmail() {
    return this.person.emailAddresses?.[0]?.address;
  }

  getNotes() {}

  switchShowAllEmail() {
    this.showAllEmail = !this.showAllEmail;
  }

  handleLoader() {
    this.showLoader.emit();
  }

  onMyReportChange() {
    this.serviceHelperService.spinnerService.spin();        
    this.idClaim();
    if (this.getEmails().length > 0) {
      //DWB Monitoring 
      if (this.commerceStorage?.idProtectionFeatures?.idDarkWebMonitoring?.enabled && this.idProtectionEnabled) {
        this.monitorDwb(!this.thisIsMe);
      }
    }
  }

  idClaim() {
    this.serviceHelperService.commerceService
      .updateMeReportFlag(
        this.commerceStorage?.idProtectionContentId,
        this.commerceContent._id,
        this.commerceContent.data?.spyDwbCommerceContentId,
        !this.thisIsMe,
        this.getEmails(),
        this.person?.fName,
        this.person?.lName
      )
      .then(() => {
        this.serviceHelperService.spinnerService.unspin();
        // tracking enrollment/un-enrollment
        if (!this.thisIsMe) {
          this.serviceHelperService.trackingService.report({
            page: BaseComponent.PAGE.detail,
            pageType: BaseComponent.PAGE_TYPE.member,
            type: 'IDClaimOnReport',
            action: 'checked',
            monitoredEmails: this.getEmails(),
          });
        } else {
          this.serviceHelperService.trackingService.report({
            page: BaseComponent.PAGE.detail,
            pageType: BaseComponent.PAGE_TYPE.member,
            type: 'IDClaimOnReport',
            action: 'unchecked',
          });
        }
      })
      .catch((e) => {
        this.serviceHelperService.spinnerService.unspin()
      });
  }

  monitorDwb(flag) {
    if (!this.commerceContent.data?.spyDwbCommerceContentId || this.getEmails().length < 1) {
      return;
    }
    const commerceStorage = this.serviceHelperService.commerceService.getStorage();
    this.serviceHelperService.spinnerService.spin();
    this.serviceHelperService.commerceService
      .monitorDwb(
        this.commerceContent.data?.spyDwbCommerceContentId,
        this.commerceContent._id,
        commerceStorage?.idProtectionContentId,
        flag,
        this.getEmails(),
        this.person?.fName,
        this.person?.lName
      )
      .then(() => {
        this.serviceHelperService.spinnerService.unspin();
        //tracking enrollment/un-enrollment
        if (flag) {
          this.serviceHelperService.trackingService.report({
            page: BaseComponent.PAGE.detail,
            pageType: BaseComponent.PAGE_TYPE.member,
            type: 'DWBEmailMonitoring',
            action: 'enrollment',
            monitoredEmails: this.getEmails(),
          });
        } else {
          this.serviceHelperService.trackingService.report({
            page: BaseComponent.PAGE.detail,
            pageType: BaseComponent.PAGE_TYPE.member,
            type: 'DWBEmailMonitoring',
            action: 'unenrollment',
          });
        }
      })
      .catch(() => this.serviceHelperService.spinnerService.unspin());
  }

  scrollTo(id: string) {
    const elem = document.getElementById(id);
    if (!elem) {
      return;
    }
    window.scrollTo(0, elem.offsetTop - 10);
  }
}

@NgModule({
  declarations: [MiniLinearComponent],
  imports: [
    CommonModule,
    RouterModule,
    
    MiniReportHeaderModule,
    MemberUpsellGlobalComponentModule,
    MiniReportFooterModule,
    TutorialModalComponentModule,
    MiniReportDwbBreachesComponentModule,
    MiniReportShareReportComponentModule,
    MiniReportNotesComponentModule,
    AdComponentModule,
    MiniReportAdProtectionComponentModule,
    UxTemplateModule, 
  ],
  exports: [MiniLinearComponent],
})
export class MiniLinearComponentModule {}
