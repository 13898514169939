// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.__selection_icon  p.__icon_text {
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    margin-bottom: 8px;
    margin-top: 15px;
}

div.__selection_icon.sq-active {
    background-color: #464FA7;
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/app/peopleSearch/components/signup-pii/icon-text.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB","sourcesContent":[".__selection_icon  p.__icon_text {\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 14px;\n    margin-bottom: 8px;\n    margin-top: 15px;\n}\n\ndiv.__selection_icon.sq-active {\n    background-color: #464FA7;\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
