import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';

@Component({
    selector: 'app-icon-selection',
    templateUrl: 'icon-text.component.html',
    styleUrls: ['./icon-text.component.css'],
    standalone: false
})

export class IconTextComponent implements OnInit {
    @Input() icon = 'phone';
    @Input() label = 'email';
    @Input() active = false;
    constructor() { }

    ngOnInit() { }
}

@NgModule({
  declarations: [IconTextComponent],
  imports: [CommonModule],
  exports: [IconTextComponent],
})
export class IconTextComponentModule {}