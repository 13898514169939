import {PropertyDecoratorHelper} from "../helpers/propertyDecoratorHelper";

export var referenceFieldHelper = new PropertyDecoratorHelper();

export function ReferenceField(value) {
    return function (target: any, key: string) {
        referenceFieldHelper.addProperties(target, key, value);
    };
}

