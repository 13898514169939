export class StorageAbstract {
  private readonly storage: string;
  private value: any;

  constructor(storage) {
    this.storage = storage;
  }

  getValue() {

    if (!this.value) {
      this.value = JSON.parse(localStorage.getItem(this.storage));
    }

    if (!this.value) {
      this.value = {};
      this.storeValue();
    }

    return this.value;
  }

  storeValue() {
    localStorage.setItem(this.storage, JSON.stringify(this.value));
  }

  clear() {
    Object.keys(this.value).forEach((key) => {
      this.value[key] = undefined;
    });

    localStorage.setItem(this.storage, JSON.stringify(this.value));
  }
}
