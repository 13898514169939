import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {BaseComponent} from "../../../../clientCommon/components/BaseComponent";
import {ReportReviewPeopleSearchMember} from "../reportReviewPeopleSearchMember";
import {ServiceHelperService} from "../../../../clientCommon/services/serviceHelper.service";

@Component({
    templateUrl: 'reportReview.component.html',
    standalone: false
})
export class ReportReviewPhoneSearchMemberComponent extends ReportReviewPeopleSearchMember implements OnInit, OnDestroy {

  constructor(serviceHelperService: ServiceHelperService,
              activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
    this.pageCategory = BaseComponent.PAGE_CATOGORY.phoneSearch;
  }

  ngOnInit() {
    return this.onInit().then(() => {
      this.initDone = true;
    });
  }

  ngOnDestroy() {
    return super.onDestroy();
  }
}
