import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-icon-selection',
    templateUrl: 'icon-text.component.html',
    styleUrls: ['./icon-text.component.css']
})

export class IconTextComponent implements OnInit {
    @Input() icon = 'phone';
    @Input() label = 'email';
    @Input() active = false;
    constructor() { }

    ngOnInit() { }
}