import { MiniReversePhoneComponent } from './detail/miniReversePhone/miniReversePhone.component';
import { PersonDetail } from '../personDetail';
import { MiniLinearComponentModule } from './detail/miniLinear/miniLinear.component';
import { MiniSocialMediaComponentModule } from './detail/miniSocialMedia/miniSocialMedia.component';
import { MiniFamilyComponentModule } from './detail/miniFamily/miniFamily.component';
import { MiniLifeEventsComponentModule } from './detail/miniLifeEvents/miniLifeEvents.component';
import { MiniPropertiesComponentModule } from './detail/miniProperties/miniProperties.component';
import { MiniCareerFinancialComponentModule } from './detail/miniCareerFinancial/miniCareerFinancial.component';
import { MiniCriminalRecordsComponentModule } from './detail/miniCriminalRecords/miniCriminalRecords.component';
import { MiniMiscellaneousComponentModule } from './detail/miniMiscellaneous/miniMiscellaneous.component';

export const NAME_SEARCH_MEMBER_DECLARATIONS = [
  PersonDetail,
  MiniReversePhoneComponent,
];

export const NAME_SEARCH_MEMBER_IMPORTS = [
  MiniLinearComponentModule,
  MiniSocialMediaComponentModule,
  MiniFamilyComponentModule,
  MiniLifeEventsComponentModule,
  MiniPropertiesComponentModule,
  MiniCareerFinancialComponentModule,
  MiniCriminalRecordsComponentModule,
  MiniMiscellaneousComponentModule,
];
