// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `li.searchedPerson h3 {
  color: #76b509;
  font-size: 28px;
}

.more {
  white-space: nowrap;
}
.search-view-report {
  background-image: none;
  padding: 10px 20px 10px 20px;
}
`, "",{"version":3,"sources":["webpack://./src/app/peopleSearch/templates/nameSearch/components/defaultSrpPerson.component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB;AACA;EACE,sBAAsB;EACtB,4BAA4B;AAC9B","sourcesContent":["li.searchedPerson h3 {\n  color: #76b509;\n  font-size: 28px;\n}\n\n.more {\n  white-space: nowrap;\n}\n.search-view-report {\n  background-image: none;\n  padding: 10px 20px 10px 20px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
