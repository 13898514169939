import { LogUtils } from "./logUtils";
import { isNumber } from "util";

export class CreditCardUtils {

  public static TYPE = {
    visa: "Visa",
    mastercard: "MasterCard",
    amex: "American Express",
    discover: "Discover",
    jcb: "JCB",
  };

  public static expMonths = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December"
  };

  detectRegex = {
    visa: /^4[0-9]{6,}$/,
    mastercard: /^(?:5[1-5][0-9]{5,}|222[1-9][0-9]{3,}|22[3-9][0-9]{4,}|2[3-6][0-9]{5,}|27[01][0-9]{4,}|2720[0-9]{3,})$/,
    amex: /^3[47][0-9]{5,}$/,
    discover: /^6(?:011|5[0-9]{2})[0-9]{3,}$/,
    jcb: /^(?:2131|1800|35[0-9]{3})[0-9]{3,}$/,
  };

  date: Date = new Date();

  testValues = [
    { key: "amex", value: 378282246310005 },
    { key: "amex", value: 371449635398431 },
    { key: "amex", value: 378734493671000 },
    { key: "etc", value: 5610591081018250 },
    { key: "dinersclub", value: 30569309025904 },
    { key: "dinersclub", value: 38520000023237 },
    { key: "discover", value: 6011111111111117 },
    { key: "discover", value: 6011000990139424 },
    { key: "jcb", value: 3530111333300000 },
    { key: "jcb", value: 3566002020360505 },
    { key: "mastercard", value: 5555555555554444 },
    { key: "mastercard", value: 5105105105105100 },
    { key: "visa", value: 4111111111111111 },
    { key: "visa", value: 4012888888881881 },
    { key: "visa", value: 4222222222222 },
    { key: "etc", value: 5019717010103742 },
    { key: "etc", value: 6331101999990016 },
  ];

  setDate(date: Date) {
    this.date = date;
  }

  getDate(): Date {
    return this.date;
  }

  getYearsArray(count: number) {
    var years = [];
    var currentYear = (this.getDate().getFullYear());

    do {
      years.push(currentYear);
      currentYear++;
    } while (years.length < count);


    return years;
  }

  findCardType(value) {
    if (value) {
      if (isNumber(value)) {
        value = value.toString();
      } else {
        value = value.replace(/[^\d]/g, "");
      }

      var cardType = null;
      Object.keys(this.detectRegex).some((key) => {
        if (value.match(this.detectRegex[key])) {
          cardType = key;
          return true;
        }
      });

      return cardType;
    }
  }

  getCardTypeKey(name) {
    var value = null;

    Object.keys(CreditCardUtils.TYPE).some((key) => {
      if (CreditCardUtils.TYPE[key] === name) {
        value = key;
        return true;
      }
    });

    return value;
  }

  getCardTypeName(key) {
    return CreditCardUtils.TYPE[key];
  }

  /**
   * @param ccNumber credit card number
   * @param ccExpMonth credit card expiry month
   * @param ccExpYear credut card expiry year
   * @param ccCvv credit card cvv
   * @returns true if all the credit card input fields are valid
   */
  isValid(ccNumber, ccExpMonth, ccExpYear, ccCvv): boolean {
    return !!(this.isValidCCNumber(ccNumber) && this.isValidCvv(ccNumber, ccCvv) && this.isValidExp(ccExpMonth, ccExpYear) && this.findCardType(ccNumber));
  }

  isValidCCNumber(value): boolean {
    if (value) {
      let cardType = this.findCardType(value);
      if (!cardType) {
        return false;
      }

      if (cardType === "visa" && value.length !== 16) {
        return false;
      }

      if (cardType === "mastercard" && value.length !== 16) {
        return false;
      }

      if (cardType === "amex" && value.length !== 15) {
        return false;
      }

      if (cardType === "discover" && value.length < 16) {
        return false;
      }

      if (value.length < 15) {
        return false;
      }

      if (isNumber(value)) {
        value = value.toString();
      }

      var len = value.length,
        mul = 0,
        prodArr = [[0, 1, 2, 3, 4, 5, 6, 7, 8, 9], [0, 2, 4, 6, 8, 1, 3, 5, 7, 9]],
        sum = 0;

      while (len--) {
        sum += prodArr[mul][parseInt(value.charAt(len), 10)];
        mul ^= 1;
      }

      return sum % 10 === 0 && sum > 0;
    } else {
      return false;
    }
  }

  isValidCvv(ccNumber, cvv): boolean {
    if (ccNumber && cvv) {
      if (isNumber((ccNumber))) {
        ccNumber = ccNumber.toString();
      }
      if (isNumber((cvv))) {
        cvv = cvv.toString();
      }
      if (this.findCardType(ccNumber) === "amex") {
        return !!cvv.match(/^[0-9]{4}$/);
      } else {
        return !!cvv.match(/^[0-9]{3}$/);
      }
    } else {
      return false;
    }
  }

  isValidExp(month, year): boolean {
    if (month && year) {
      if (!isNumber(month)) {
        month = parseInt(month, 10);
      }
      if (!isNumber(year)) {
        year = parseInt(year, 10);
      }
      var date = this.getDate();
      var currentYear = date.getFullYear();
      var currentMonth = date.getMonth() + 1;

      if (month < 1 || month > 12) {
        return false;
      }

      if (year > currentYear) {
        return true;
      } else if (year === currentYear && month >= currentMonth) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isTestValue(value): boolean {
    if (isNumber(value)) {
      value = value.toString();
    }

    let flag = false;
    this.testValues.some((testValue) => {
      if ((testValue.value.toString()) === value) {
        flag = true;
        return true;
      }
    });

    return flag;
  }

  unitTest(): boolean {
    let flag = true;
    let cardTypes = Object.keys(this.detectRegex);

    this.testValues.some((testValue) => {
      if (!this.isValidCCNumber(testValue.value)) {
        LogUtils.error("isValidCCNumber test fail", testValue);
        flag = false;
        return true;
      }
      if (!this.isTestValue(testValue.value)) {
        LogUtils.error("isTestValue test fail", testValue);
        flag = false;
        return true;
      }
      let cardType = this.findCardType(testValue.value);
      if (cardTypes.indexOf(cardType) >= 0) {
        if (cardType !== testValue.key) {
          LogUtils.error("findCardType test fail", this.findCardType(testValue.value.toString()), testValue);
          flag = false;
          return true;
        }
      }
    });

    return flag;
  }

  formatCCNumber(value, keepSpecialCharacter = false) {
    if (value) {
      if (keepSpecialCharacter === true) {
        value = value.replace(/[^0-9*]/g, "");
      } else {
        value = value.replace(/[^0-9]/g, "");
      }
      let cardType = creditCardUtils.findCardType(value);

      if (cardType === this.getCardTypeKey(CreditCardUtils.TYPE.amex)) {
        value = value.substr(0, 15).replace(/^([\d*]{4})([\d*]{6})?([\d*]{5})?/, "$1 $2 $3").replace(/ {2,}/g, " ").replace(/ $/, '');
      } else {
        // Default is VISA/Master
        value = value.substr(0, 16).replace(/^([\d*]{4})([\d*]{4})?([\d*]{4})?([\d*]{4})?/, "$1 $2 $3 $4").replace(/ {2,}/g, " ").replace(/ $/, '');
      }
    }

    return value;
  }

  formatObfuscatedCCNumberBybinLast4(bin, last4, length: number) {
    if (bin && last4 && length) {
      let ccNumber = "*".repeat(length - bin.length - last4.length);
      ccNumber = bin + ccNumber + last4;
      ccNumber = creditCardUtils.formatCCNumber(ccNumber, true);
      return ccNumber;
    }
  }

  formatObfuscatedCCNumber(ccNumber: String) {
    if (ccNumber && ccNumber.length) {
      let length = ccNumber.length;
      let bin = ccNumber.substr(0, 6);
      let last4 = ccNumber.substr(length - 4);
      return this.formatObfuscatedCCNumberBybinLast4(bin, last4, length);
    }
  }


  formatCvv(cardType, value) {
    if (value) {
      value = value.replace(/[^0-9]/g, "");
    }
    if (cardType && value) {
      if (cardType === this.getCardTypeKey(CreditCardUtils.TYPE.visa) || cardType === this.getCardTypeKey(CreditCardUtils.TYPE.mastercard)) {
        value = value.substr(0, 3);
      } else {
        // Default is amex
        value = value.substr(0, 4);
      }
    }

    return value;
  }

  formatExp4Digit(value) {
    if (value) {
      value = value.replace(/[^0-9]/g, "");
      if (parseInt(value[0]) > 1 && value.length === 1) {
        value = "0" + value;
      }
      if (parseInt(value[0]) === 1 && parseInt(value[1]) > 2) {
        value = "0" + value;
      }
      value = value.substr(0, 4)
        .replace(/^([\d]{2})([\d]{2})?/, "$1/$2")
        .replace(/[^0-9]$/, '');
    }

    return value;
  }

  formatZip(value) {
    if (value) {
      value = value.replace(/\D/g,'');
    }

    return value;
  }

  formatStreet(value) {
    if (value) {
      value = value.replace(/[^0-9a-zA-Z ]/g,'');
    }

    return value;
  }
}

export var creditCardUtils = new CreditCardUtils();
