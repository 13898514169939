import { Component, Input, NgModule, OnChanges, OnInit } from '@angular/core';
import { UxHelper } from '../../../clientCommon/helper/uxHelper';
import { BaseDirective } from '../../../clientCommon/directives/BaseDirective';
import { ServiceHelperService } from '../../../clientCommon/services/serviceHelper.service';
import { ActivatedRoute } from '@angular/router';
import { objectUtils } from '../../../common/utils/objectUtils';
import { CommonModule } from '@angular/common';
import { UxTemplateModule } from '../uxTemplate.component';

declare var $: any;

@Component({
  selector: 'ad1',
  templateUrl: './ad1.component.html',
  styleUrls: ['./ad1.component.scss'],
})
export class Ad1Component extends BaseDirective implements OnInit, OnChanges {
  @Input() uxHelper: UxHelper;
  @Input() uxcompKey;
  @Input() config;
  bodyKey;
  linkOption: any = {
    target: 'ad',
  };

  constructor(serviceHelperService: ServiceHelperService, activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
  }

  init() {
    return Promise.resolve()
      .then(() => {
        this.bodyKey = this.uxcompKey + '.body';

        this.linkOption.extra = objectUtils.clone(this.config);
        this.linkOption.extra.uxcomp = this.uxcompKey;
        if (this.config.target === '_blank') {
          this.linkOption.newWindow = true;
        }

        this.serviceHelperService.trackingService.report({
          target: 'ad',
          type: 'impression',
          extra: this.linkOption.extra,
        });
      })
      .then(() => {
        this.initDone = true;
      });
  }

  ngOnInit() {
    return this.baseInit().then(() => {
      return this.init();
    });
  }

  ngOnChanges() {
    return this.init();
  }

  submit($event) {
    return this.click($event, this.linkOption);
  }
}

@NgModule({
  declarations: [Ad1Component],
  imports: [CommonModule, UxTemplateModule],
  exports: [Ad1Component],
})
export class Ad1ComponentModule {}
