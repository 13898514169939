import { Component, Input, NgModule } from '@angular/core';
import { UxComp } from '@ux5.2/types';

@Component({
  selector: 'ux-5-2-side-cards',
  templateUrl: './side-cards.component.html',
  styleUrls: ['./side-cards.component.scss']
})
export class SideCardsComponent {
  @Input() forceHorizontal: boolean = false;
  @Input() title: UxComp;
  @Input() content: UxComp;
  @Input() imageUrls: UxComp;
}
