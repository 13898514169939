import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {BaseComponent} from "../../../../clientCommon/components/BaseComponent";
import {ServiceHelperService} from "../../../../clientCommon/services/serviceHelper.service";
import {ProcessPeopleSearchMember} from "../processPeopleSearchMember";
import { phoneUtils } from "@common/utils/phoneUtils";

@Component({
  templateUrl: 'process.component.html',
  styleUrls: ['./process.component.scss'],
})

export class ProcessPhoneSearchMemberComponent extends ProcessPeopleSearchMember implements OnInit, OnDestroy {

  constructor(serviceHelperService: ServiceHelperService,
              activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
    this.pageCategory = BaseComponent.PAGE_CATOGORY.phoneSearch;
  }

  searchedPhone;

  ngOnInit() {
    this.searchedPhone = phoneUtils.formatPhoneNumber(
      this?.commerceContent?.content?.[0]?.contentInfo?.phone 
        ?? this.serviceHelperService.userInputService.getUserInput()?.phone
        ?? ''
    );

    return this.onInit().then(() => {
      this.initDone = true;
    });
  }

  ngOnDestroy() {
    return super.onDestroy();
  }
}

