import { Component, OnInit, EventEmitter, Output, Input, NgModule } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { SearchedPerson } from '@common/custom/models/peopleSearch/searchedPerson';
import { BaseComponent } from '@clientCommon/components/BaseComponent';
import { ReportReviewPeopleSearch } from '@peopeSearch/reportReviewPeopleSearch';
import { UxComp, InputType } from '@ux5.2/types';
import { UxHelper } from '@clientCommon/helper/uxHelper';
import { PhoneUtils } from '@common/utils/phoneUtils';
import { ServiceHelperService } from '@clientCommon/services/serviceHelper.service';
import { CommerceContent } from '@common/models/commerce/commerceContent';
import { customServerPaths } from '@common/custom/customPathHelpers';
import { LogUtils } from '@common/utils/logUtils';
import { Format } from '@themes/2234200bd3cc36ba97274ecbdc6c6a47/common/button/button.component';

interface UserInformation {
  fname: string;
  firstName?: string;
  lname: string;
  lastName?: string;
  email: string;
  phone: string;
  age?: number;
  city?: string;
  state?: string;
  extId?: string;
  phones?: Phone[];
}

interface Phone {
  number: string;
}

interface UserInformationError {
  fName: boolean;
  lName: boolean;
  email: boolean;
  phone: boolean;
}

interface CombinedSearchedPerson extends SearchedPerson {
  search?: UserInformation;
  target?: UserInformation;
}

@Component({
  selector: 'ux-5-2-contact-information',
  templateUrl: './contact-information.component.html',
  styleUrls: ['./contact-information.component.scss'],
})
export class ContactInformationComponent extends BaseComponent implements OnInit {
  @Input() base: ReportReviewPeopleSearch;
  @Input() title: UxComp;
  @Input() uxHelper: UxHelper;
  @Output() saveResult = new EventEmitter();
  disclaimer: UxComp;

  format = Format;
  type = InputType;
  saveBtnClicked: boolean = false;
  phoneUtils = new PhoneUtils();

  validationPressed: boolean = false;

  userInformation: UserInformation = {
    fname: '',
    lname: '',
    email: '',
    phone: '',
  };
  userInformationError: UserInformationError = {
    fName: false,
    lName: false,
    email: false,
    phone: false,
  };

  leadDisclosureKey: string = 'comp.sales.name-search.report-review.contact-information.disclaimer';

  constructor(
    private viewportScroller: ViewportScroller,
    serviceHelperService: ServiceHelperService,
    activatedRoute: ActivatedRoute
  ) {
    super(serviceHelperService, activatedRoute);
  }

  ngOnInit(): void {
    this.viewportScroller.scrollToPosition([0, 0]);
    this.disclaimer = this.getUxcompValue('comp.sales.name-search.report-review.contact-information.disclaimer');
    this.initUserInfo();
  }

  isValidEmail() {
    return this.userInformation.email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)?.length > 0;
  }

  isValidFirstName() {
    return this.userInformation.fname.length > 0;
  }

  isValidLastName() {
    return this.userInformation.lname.length > 0;
  }

  formatPhone(event) {
    this.base.trackEvent(event);
    const phoneNumber = this.phoneUtils.formatPhoneNumber(event.target.value);
    if (this.userInformation.phone !== phoneNumber) {
      this.userInformation.phone = phoneNumber;
    }
  }

  isValidPhone() {
    const phoneLength = this.userInformation?.phone?.length || 0;
    // either a full phone number with formatting or no phone number
    return phoneLength === 0 || this.phoneUtils.validDigits(this.userInformation?.phone);
  }

  validate() {
    this.validationPressed = true;
    this.userInformationError.fName = !this.isValidFirstName();
    this.userInformationError.lName = !this.isValidLastName();
    this.userInformationError.email = !this.isValidEmail();
    this.userInformationError.phone = !this.isValidPhone();
    // if there are no errors set the saved button clicked to true
    this.saveBtnClicked = !(
      this.userInformationError.fName ||
      this.userInformationError.lName ||
      this.userInformationError.email ||
      this.userInformationError.phone
    );
  }

  getProductKey = async (): Promise<string> => {
    // With UX5.2 having multiple offers we don't know the product key at this point in the process
    return null;
  };

  getSelectedPerson = async (): Promise<CombinedSearchedPerson> => {
    /**
     * get commerce content based on uxComposite, set the selectedPerson by getting it based on commerceContent
     * set the progressor and its progress if found in commerceContent
     */
    await this.serviceHelperService.peopleSearchService
      .getCommerceContent(this.uxHelper.uxComposite)
      .then(async (commerceContent: CommerceContent) => {
        return await this.serviceHelperService.peopleSearchService.findSelectedPerson(commerceContent);
      })
      .catch((_e) => {});
    return localStorage.getItem('inputTracking') ? JSON.parse(localStorage.getItem('inputTracking')) : {};
  };

  // Create a contact in SlickText for remarketing
  remarketingSms = async () => {
    const selectedPerson: CombinedSearchedPerson = await this.getSelectedPerson();
    const uxCompPrefix = this.uxHelper.getUxcompKey('');

    const params = {
      phoneNumber: this.userInformation.phone,
      brandId: this?.uxHelper?.uxComposite?.brandId || '',
      userInfo: {
        lname: this.userInformation.lname,
        fname: this.userInformation.fname,
        productkey: await this.getProductKey(),
        customerId: this.base?.commerceContent?._id,
        phone: this.userInformation.phone,
        email: this.userInformation.email,
        optin: true,
        optinUrl: window.location.href,
      },
      targetInfo: {
        fname: selectedPerson?.fName || selectedPerson?.target?.firstName || selectedPerson?.search?.firstName,
        lname: selectedPerson?.lName || selectedPerson?.target?.lastName || selectedPerson?.search?.lastName,
        state: selectedPerson?.target?.state,
        extId: selectedPerson?.target?.extId,
        city: selectedPerson?.target?.city,
        age: selectedPerson?.target?.age,
        phone: selectedPerson?.target?.phones?.[0]?.number,
      },
      searchInfo: {
        fname: selectedPerson?.search?.firstName,
        lname: selectedPerson?.search?.lastName,
        city: selectedPerson?.search?.city,
        state: selectedPerson?.search?.state,
        phone: this?.userInput?.phone,
      },
      uxCompPrefix,
      leadDisclosureKey: this.leadDisclosureKey,
    };
    await this.serviceHelperService.jsonService
      .json(customServerPaths.contentSmsSenderSubscribe, { params })
      .then(() => {
        /*
      this.smsSubscribed = true;
      this.phoneNumberUsed.push(params.phoneNumber)
      */
      });
    return params;
  };

  addEmail() {
    if (this.base.userInfo.email) {
      let meta = {
        userInfo: this.base.userInfo,
      };
      let extraInfo: any = {};
      if (this.base.userInfo.optin) {
        extraInfo.optin = true;
        extraInfo.optinUrl = location.href;
      }

      this.serviceHelperService.emailService.addEmail(this.uxHelper.uxComposite.brandId, this.base.userInfo.email, "guest", meta, extraInfo).catch((e) => {
        LogUtils.error("email add failed");
      });
    }
  }

  async saveResults() {
    if (this.saveBtnClicked) return;
    this.validate();
    if (!Object.values(this.userInformationError).find((value) => value)) {
      if (this.userInformation.phone) {
        this.userInformation.phone = this.phoneUtils.formatPhoneNumber(this.userInformation.phone);
        await this.remarketingSms();
      }
      Object.entries(this.userInformation).map((value) => {
        this.base.userInfo[value[0]] = value[1];
      });
      this.addEmail();
      this.saveResult.emit(this.userInformation);

      const GET_THIN_MATCH_ENABLED: boolean =
        this.serviceHelperService?.storageService?.getSession('thinMatchEnabled') ?? false;
      const THIN_MATCH_TYPE: string = this.serviceHelperService?.storageService?.getSession('thinMatchType') ?? '';

      this.serviceHelperService.trackingService.report({
        page: BaseComponent.PAGE.reportReview,
        pageType: BaseComponent.PAGE_TYPE.sales,
        type: 'ux_5_2',
        firstName: this.userInformation.fname,
        lastName: this.userInformation.lname,
        email: this.userInformation.email,
        customerId: this.base?.commerceContent?._id,
        thinMatch: {
          enabled: GET_THIN_MATCH_ENABLED,
          thinMatchType: THIN_MATCH_TYPE,
        },
        optin: true,
        optinUrl: window.location.href,
      });
    }
  }

  getUxcompValue(compName: string): UxComp {
    const compNameIndex = Object.keys(this.uxHelper?.uxComposite?.ids).indexOf(compName);
    const id = Object.values(this.uxHelper?.uxComposite?.ids)[compNameIndex] as string;
    return {
      id,
      key: compName,
      value: this.uxHelper?.uxComposite?.getUxcomp(compName),
    };
  }

  formatInput(event) {
    let formatted = '';
    let value = event.target.value;
    if (value) {
      value = value.replace(/(?:(?:^[^0-9]*1*)|(?:[^0-9]))/g, '');
      let length = value.length;
      if (length > 0) {
        formatted = '(' + value.substring(0, 3);
      }
      if (length > 3) {
        formatted += ') ' + value.substring(3, 6);
      }
      if (length > 6) {
        formatted += '-' + value.substring(6, 10);
      }
    }
    this.userInformation.phone = formatted;
  }

  private initUserInfo() {
    const userInfo = this.serviceHelperService.userInfoService.getUserInfo();

    if (!userInfo) {
      return;
    }

    if (userInfo.fname) {
      this.userInformation.fname = userInfo.fname;
    }

    if (userInfo.lname) {
      this.userInformation.lname = userInfo.lname;
    }

    if (userInfo.email) {
      this.userInformation.email = userInfo.email;
    }

    if (userInfo.phone) {
      this.userInformation.phone = userInfo.phone;
    }
  }
}
