import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, NgModule } from '@angular/core';

@Component({
    selector: 'app-loader-eye',
    templateUrl: './loader-eye.component.html',
    styleUrls: ['./loader-eye.component.scss'],
    standalone: false
})
export class LoaderEyeComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

@NgModule({
  declarations: [LoaderEyeComponent],
  imports: [CommonModule],
  exports: [LoaderEyeComponent],
})
export class LoaderEyeComponentModule {}
