import { NAME_SEARCH_MEMBER_DECLARATIONS, NAME_SEARCH_MEMBER_IMPORTS } from './nameSearch/index';
import { PHONE_SEARCH_MEMBER_DECLARATIONS, PHONE_SEARCH_MEMBER_IMPORTS } from './phoneSearch/index';
import { COMPONENT_PEOPLE_SEARCH_MEMBER_DECLARATIONS } from './components';
import { COMPONENT_PEOPLE_SEARCH_MEMBER_IMPORTS } from './components';

export const PEOPLE_SEARCH_MEMBER_DECLARATIONS = [
  ...COMPONENT_PEOPLE_SEARCH_MEMBER_DECLARATIONS,
  ...NAME_SEARCH_MEMBER_DECLARATIONS,
  ...PHONE_SEARCH_MEMBER_DECLARATIONS,
];

export const PEOPLE_SEARCH_MEMBER_IMPORTS = [
  ...NAME_SEARCH_MEMBER_IMPORTS,
  ...COMPONENT_PEOPLE_SEARCH_MEMBER_IMPORTS,
  ...PHONE_SEARCH_MEMBER_IMPORTS,
];
