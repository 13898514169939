import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import { UxHelper } from "../../../../../../clientCommon/helper/uxHelper";
import { ServiceHelperService } from "../../../../../../clientCommon/services/serviceHelper.service";
import { SearchedPerson } from "../../../../../../common/custom/models/peopleSearch/searchedPerson";
import { SearchResultPeopleSearch } from "../../../../searchResultPeopleSearch";
import { HeadlessHelper } from "../../../../../helper/headlessHelper";
import { AddressUtils } from "../../../../../../common/utils/addressUtils";
import { LogUtils } from "../../../../../../common/utils/logUtils";

enum HeadlessRedirection {
  Signup = 'SUP',
  SearchResult = 'SRP',
}

@Component({
  selector: 'headless-searchResult-name-search',
  templateUrl: './searchResult.component.html',
  styleUrls: ['./searchResult.component.css'],
})
export class HeadlessSearchResultNameSearchComponent implements OnInit, OnChanges {
  @Input() base: SearchResultPeopleSearch;
  
  @Input() uxHelper: UxHelper;
  
  @Input() pageType: string;
  
  @Input() pageCategory: string;
  
  @Input() page: string;
  
  constructor(
    public serviceHelperService: ServiceHelperService,
    private ref: ElementRef,
  ) {}

  thinMatch: boolean;

  searchedPersons: SearchedPerson[] = [];

  searchedStateName: string;

  blurSearchedState: boolean = false;

  readonly uxCompKeys: {
    headline: string,
  } = {
    headline: '',
  };

  redirectorParams: {
    weid: string,
    loc: string,
  } = {
    weid: '',
    loc: '',
  };

  private listLimit: number = 10;

  private redirectUrl: string = 'https://www.familywatchdog.us/WeInformRedirector.asp';

  ngOnInit() {
    this.initialize();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const someChanges = Object.entries(changes).some(([_, value]) => {
      return !value.firstChange;
    });

    if (!someChanges) {
      return;
    }

    this.initialize();
  }

  handleViewReport(selected?: any, index?: number) {
    let redirect = this.uxHelper.getUxcomp('headless.redirect') as HeadlessRedirection;
    
    const contentInfo = this.base.commerceContent?.content?.[0]?.contentInfo;

    const params = {
      uxc: this.base.uxComposite.uxConfigId,
      ui_fname: contentInfo?.fname,
      ui_lname: contentInfo?.lname,
      ui_state: contentInfo?.state,
      skip: true,
      utm_refer_fwsize: this.ref.nativeElement.offsetWidth,
      utm_refer_fwpage: window.location.href,
      utm_refer: window.location?.ancestorOrigins?.[0],
    };

    if (this.thinMatch) {
      redirect = HeadlessRedirection.Signup;
    }

    if (redirect === HeadlessRedirection.Signup) {
      if (selected?.extId) {
        params['extId'] = selected?.extId;
      }
    }

    const purl = new URL('/name/landing', window.location.href);

    for (let param in params) {
      purl.searchParams.append(param, params[param]);
    }

    const weid = this.redirectorParams.weid;

    const loc = this.redirectorParams.loc;

    const encodedPurl = btoa(purl.href);

    const redirectUrl = `${this.redirectUrl}?weid=${weid}&loc=${loc}&purl=${encodedPurl}`;

    LogUtils.info('redirectUrl', redirectUrl);
    LogUtils.info('purl', purl.href);

    window.open(redirectUrl, '_blank');
  }

  private initialize() {
    const redirectUrl = this.uxHelper.uxComposite.getUxcomp(HeadlessHelper.redirectUrlCompKey);
    
    if (redirectUrl) {
      this.redirectUrl = redirectUrl;
    }

    const listLimit = this.uxHelper.getUxcomp('headless.listLimit');

    if (listLimit && typeof listLimit === 'number') {
      this.listLimit = listLimit;
    }

    const redirectorParams = HeadlessHelper.getRedirectorParams();

    if (redirectorParams.weid !== '' && redirectorParams.loc !== '') {
      this.redirectorParams.weid = redirectorParams.weid;
      this.redirectorParams.loc = redirectorParams.loc;
    }

    this.initThinMatch();

    this.initUxCompKeys();

    this.initSearchedPersons();

    this.initSearchedState(); 
  }

  private initThinMatch() {
    this.thinMatch = this.serviceHelperService.storageService.getSession("thinMatchEnabled");
  }

  private initUxCompKeys() {
    this.uxCompKeys.headline = this.thinMatch 
      ? this.uxHelper.getUxcompKey('headless.headline.thin') 
      : this.uxHelper.getUxcompKey('headless.headline');
  }

  private initSearchedPersons() {
    this.searchedPersons = this.thinMatch 
      ? [] 
      : this.base.commerceContent.getMainRaw()
        ?.tempClient
        ?.processed
        ?.person
        ?.slice(0, this.listLimit);
  }

  private initSearchedState() {
    const addressFields = AddressUtils.getAddressField('US');

    const state = this.base.commerceContent?.content?.[0]?.contentInfo?.state;

    if (state === 'all') {
      this.searchedStateName = 'Florida';
      this.blurSearchedState = true;
    } else {
      this.searchedStateName = addressFields.getAdministrativeAreaName(state);
      this.blurSearchedState = false;
    }
  }
}

/**
 * [uxcc.dev.1462]
 * sales.name-search.search-result.headless.headline : We found results for <Target Name>
 * sales.name-search.search-result.headless.headline.thin : View possible results for <Target Name> now
 * sales.name-search.search-result.headless.redirect : SUP, SRP
 * sales.name-search.headless.enabled : true
 * sales.name-search.loader.rule.progressor.skip : {"loader":["sales.name-search.search-result"]}
 * sales.name-search.headless.redirectUrl : https://www.familywatchdog.us/WeInformRedirector.asp
 * sales.name-search.thinMatch.enabled : true
 * sales.name-search.thinMatchCases.enabled : { "NoResults": true, "TooManyResults": true, "Geographic": true, "GeographicNoResults": true, "DataProviderDown": true }
 * 
 * [uxcc.dev.1563]
 * sales.name-search.search-result.headless.listLimit : 10
 */
