import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {LogUtils} from "../../common/utils/logUtils";
import {RequestEvent} from "../../common/event/requestEvent";
import {serverPaths} from "../../common/helpers/pathHelpers";
import {ResponseEvent} from "../../common/event/responseEvent";
import {redirectHelper} from "../../clientCommon/helper/redirectHelper";
import {ServiceHelperService} from "../../clientCommon/services/serviceHelper.service";
import {BaseComponent} from "../../clientCommon/components/BaseComponent";

@Component({
    template: '<div></div>',
    standalone: false
})

export class ClickTrackingComponent extends BaseComponent implements OnInit, OnDestroy {

  constructor(serviceHelperService: ServiceHelperService,
              activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
    this.pageType = BaseComponent.PAGE_TYPE.sales;
    this.pageCategory = BaseComponent.PAGE_CATOGORY.general;
    this.page = BaseComponent.PAGE.clickTracking;
  }

  ngOnInit() {
    return this.baseInit().then(() => {
      let params = new URLSearchParams(window.location.search);
      let id = params.get('id');
      let hash = params.get('hash');
      let action = params.get('action');
      let meta = params.get('meta');

      let url = "";
      try {
        url = decodeURIComponent(params.get('url'));
      } catch (e) {
        LogUtils.error(e);
      }

      let requestEvent = new RequestEvent();


      requestEvent.param = {
        id: id,
        hash: hash,
        action: action,
        meta: meta,
      };
      return this.serviceHelperService.jsonService.json(serverPaths.mailTrack, requestEvent).then((responseEvent: ResponseEvent) => {
        return true;
      }).catch((responseEvent: ResponseEvent) => {
        LogUtils.error(responseEvent.rawResponse);
        return Promise.reject(false);
      }).then(() => {
        redirectHelper.redirect(this.serviceHelperService, url);
      });
    }).catch((e) => {
      LogUtils.error(e);
    });
  }

  ngOnDestroy() {
    return super.onDestroy();
  }

}
