import {Default1Dot2LandingNameSearchComponent} from "./landing.component";
import {Default1Dot2LoaderNameSearchComponent} from "./loader.component";
import {Default1Dot2SearchResultNameSearchComponent} from "./searchResult.component";
import {Default1Dot2ReportReviewNameSearchComponent} from "./reportReview.component";
import {Default1Dot2SignupNameSearchComponent} from "./signup.component";

export const DEFAULT_1_2_NAME_SEARCH_TEMPLATES_DECLARATIONS = [
  Default1Dot2LandingNameSearchComponent,
  Default1Dot2LoaderNameSearchComponent,
  Default1Dot2SearchResultNameSearchComponent,
  Default1Dot2ReportReviewNameSearchComponent,
  Default1Dot2SignupNameSearchComponent,
];
