import { Component, Input, NgModule } from '@angular/core';
import { UxComp } from '@ux5.2/types';

@Component({
  selector: 'ux-5-2-icon-label-benefits-card',
  templateUrl: './icon-label-benefits-card.component.html',
  styleUrls: ['./icon-label-benefits-card.component.scss'],
})
export class IconLabelBenefitsCardComponent {
  @Input() title: UxComp;
  @Input() subTitle: UxComp;
  @Input() faqHeader: UxComp;
  @Input() description: UxComp;
  @Input() faqSecondHeader: UxComp;
  @Input() faqCancelMembershipContent: UxComp;
}
