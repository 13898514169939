import { Component, Input, NgModule, ViewEncapsulation } from '@angular/core';
import { UxHelper } from '@clientCommon/helper/uxHelper';

@Component({
  selector: 'wi-benefit-card',
  templateUrl: './benefit-card.component.html',
  styleUrls: ['./benefit-card.component.scss'],
  host: {
    class: 'wi-benefit-card',
  },
  encapsulation: ViewEncapsulation.None,
})
export class BenefitCardComponent {
  @Input() title: string;
  @Input() description: string;
  @Input() icon: string;

  @Input() uxHelper: UxHelper;
}
