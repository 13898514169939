// export * from './header';
// export * from './footer';
// export * from './input';
// export * from './cosmos';
// export * from './list-item';
// export * from './loading-spinner';
// export * from './icon-label';
// export * from './basic-progress-bar';
// export * from './image-title';
// export * from './vector';
// export * from './select';
// export * from './button';
// export * from './paper';
// export * from './card-grid';

import { BASIC_PROGRESS_BAR_COMMON_UX_5_2_THEME_DECLARATIONS } from "./basic-progress-bar";
import { BUTTON_COMMON_UX_5_2_THEME_DECLARATIONS } from "./button";
import { CARD_GRID_COMMON_UX_5_2_THEME_DECLARATIONS } from "./card-grid";
import { COSMOS_COMMON_UX_5_2_THEME_DECLARATIONS } from "./cosmos";
import { FOOTER_COMMON_UX_5_2_THEME_DECLARATIONS } from "./footer";
import { HEADER_COMMON_UX_5_2_THEME_DECLARATIONS } from "./header";
import { ICON_LABEL_COMMON_UX_5_2_THEME_DECLARATIONS } from "./icon-label";
import { IMAGE_TITLE_COMMON_UX_5_2_THEME_DECLARATIONS } from "./image-title";
import { INPUT_COMMON_UX_5_2_THEME_DECLARATIONS } from "./input";
import { LIST_ITEM_COMMON_UX_5_2_THEME_DECLARATIONS } from "./list-item";
import { LOADING_SPINNER_COMMON_UX_5_2_THEME_DECLARATIONS } from "./loading-spinner";
import { PAPER_COMMON_UX_5_2_THEME_DECLARATIONS } from "./paper";
import { SELECT_COMMON_UX_5_2_THEME_DECLARATIONS } from "./select";
import { VECTOR_COMMON_UX_5_2_THEME_DECLARATIONS } from "./vector";



export const COMMON_UX_5_2_THEME_DECLARATIONS = [
    ...BASIC_PROGRESS_BAR_COMMON_UX_5_2_THEME_DECLARATIONS,
    ...BUTTON_COMMON_UX_5_2_THEME_DECLARATIONS,
    ...CARD_GRID_COMMON_UX_5_2_THEME_DECLARATIONS,
    ...COSMOS_COMMON_UX_5_2_THEME_DECLARATIONS,
    ...FOOTER_COMMON_UX_5_2_THEME_DECLARATIONS,
    ...HEADER_COMMON_UX_5_2_THEME_DECLARATIONS,
    ...ICON_LABEL_COMMON_UX_5_2_THEME_DECLARATIONS,
    ...IMAGE_TITLE_COMMON_UX_5_2_THEME_DECLARATIONS,
    ...INPUT_COMMON_UX_5_2_THEME_DECLARATIONS,
    ...LIST_ITEM_COMMON_UX_5_2_THEME_DECLARATIONS,
    ...LOADING_SPINNER_COMMON_UX_5_2_THEME_DECLARATIONS,
    ...PAPER_COMMON_UX_5_2_THEME_DECLARATIONS,
    ...SELECT_COMMON_UX_5_2_THEME_DECLARATIONS,
    ...VECTOR_COMMON_UX_5_2_THEME_DECLARATIONS,

  ];
