import {
  AfterViewChecked,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  Input,
  ViewContainerRef,
  ComponentFactoryResolver,
} from '@angular/core';
import { LandingPeopleSearchSales } from '../../landingPeopleSearchSales';
import { BaseComponent } from '../../../../../clientCommon/components/BaseComponent';
import { ServiceHelperService } from '../../../../../clientCommon/services/serviceHelper.service';
import { ActivatedRoute } from '@angular/router';

export type utmParamValueType = {
  header1: string;
  header2: string;
};

export const headerContent = {
  background: {
    header1: 'Instant Background Checks',
    header2: 'Find Criminal Records, Bankruptcies, Social Media, Photos, Contact Info and More!',
  },
  publicrecords: {
    header1: 'Public Records Search',
    header2: 'The Most Trusted Source of Public Records. Search Billions of Records',
  },
  peoplesearch: {
    header1: 'Search for People Anywhere',
    header2: 'Search billions of national public records, social media, online photos, death records',
  },
  findpeople: {
    header1: 'Find Anybody in the USA',
    header2: 'Search billions of national public records, social media, online photos, death records',
  },
  crimrecords: {
    header1: 'Criminal Records Search',
    header2:
      'Search for arrests, felonies, misdemeanors, DUI, sex crimes. Instant results from the top, MOST TRUSTED source in the nation.',
  },
  crimbg: {
    header1: 'Criminal Background Checks',
    header2: 'Find Criminal Records, Bankruptcies, Social Media, Photos, Contact Info and More!',
  },
};

@Component({
  templateUrl: './landing.component.html',
})

/**
 * LandingNameSearchSalesComponent render the respective theme/layout component based on current theme
 * @extends LandingPeopleSearchSales which further extends LandingPeopleSearch to have all the required properties i.e delay, addressField, counter, counterInterval...
 */
export class LandingNameSearchSalesComponent
  extends LandingPeopleSearchSales
  implements OnInit, OnDestroy, AfterViewChecked
{
  @ViewChild('firstName')
  private _firstNameInputElement: ElementRef;
  @Input() utmParamValue: utmParamValueType = null;

  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;

  /**
   *
   * @param serviceHelperService will provide access to different required services such as authentication service, uxc service etc.
   * @param activatedRoute provides access to information about a route associated with a component that is loaded in an outlet.
   * @param pageCategory getting from BaseComponent to be provided as input to the child(theme template) component.
   *
   */
  constructor(
    serviceHelperService: ServiceHelperService,
    activatedRoute: ActivatedRoute,
    private _cfr: ComponentFactoryResolver
  ) {
    super(serviceHelperService, activatedRoute);
    this.pageCategory = BaseComponent.PAGE_CATOGORY.nameSearch;
  }

  ngOnInit() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const utm_lp = urlParams.get('utm_lp');
    this.utmParamValue = headerContent[utm_lp];
    return this.onInit().then(() => {
      // if (this.theme === this.themes.ux_5_2) {
      //   this._loadUx5_2LandingPage();
      // }
      this.initDone = true;
    });
  }

  ngOnDestroy() {
    return super.onDestroy();
  }
  
  // set input firstName from Base component everytime, there is a change detection on component and its children
  ngAfterViewChecked(): void {
    this.inputElements.firstName = this._firstNameInputElement;
  }
  
  @HostListener('window:scroll', ['$event'])
  onScroll($event) {
    super.onScroll($event);
  }

  // private async _loadUx5_2LandingPage() {
  //   this.container.clear();
  //   const { Ux5Dot2LandingNameSearchComponent } = await import('@ux5.2/peopleSearch/nameSearch/landing');
  //   const componentFactory = this._cfr.resolveComponentFactory(Ux5Dot2LandingNameSearchComponent);
  //   const component = this.container.createComponent(componentFactory);
  //   component.instance.utmParamValue = this.utmParamValue;
  //   component.instance.base = this;
  //   component.instance.uxHelper = this.uxHelper;
  // }
}
