import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { SignUpPeopleSearch } from '@sales/peopleSearch/signUpPeopleSearchSales';

@Component({
  selector: 'ux-5-2-profile-card',
  templateUrl: './profile-icon-card.component.html',
  styleUrls: ['./profile-icon-card.component.scss'],
})
export class ProfileCardComponent implements OnInit {
  @Input() name: string = null;
  @Input() base: SignUpPeopleSearch;
  
  location: string = null;
  age: string = null;
  isBlurred: boolean = false;

  ngOnInit(): void {
    this.location = this.getState();
    // if (this.base.isThinMatchEnabled && this.base.lpCounty) {
    //   this.location += ` ${this.base.lpCounty}`;
    // }
    this.age = this.getAge();
  }

  getState(): string {
    if (this.base?.isThinMatchEnable) {
      if (this.base?.userInputState === 'all') return 'Florida';
      return this.base?.userInputState;
    }
    return this.base?.selectedPerson?.cityState || 'Florida';
  }

  getAge(): string {
    const age: number = this.base?.selectedPerson?.age || 88;
    this.isBlurred = age === 88;
    return age.toString();
  }

  blurCss(): string {
    return this.isBlurred ? 'blurAgeLabel' : '';
  }
}

// @NgModule({
//   declarations: [ProfileCardComponent],
//   imports: [CommonModule],
//   exports: [ProfileCardComponent],
// })
// export class ProfileCardComponentModule {}
