// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 479px) {
  img {
    width: 75% !important;
  }
}
.bar-container {
  width: 28px;
  height: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.bar-container .bar {
  height: 4px;
  width: 100%;
  background-color: #ffb400;
}`, "",{"version":3,"sources":["webpack://./src/app/themes/ux.3.0/components/salesHeader.component.scss"],"names":[],"mappings":"AAAA;EACE;IACE,qBAAA;EACF;AACF;AAEA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;AAAF;AAEE;EACE,WAAA;EACA,WAAA;EACA,yBAAA;AAAJ","sourcesContent":["@media screen and (max-width: 479px) {\n  img {\n    width: 75% !important;\n  }\n}\n\n.bar-container {\n  width: 28px;\n  height: 24px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n\n  .bar {\n    height: 4px;\n    width: 100%;\n    background-color: #ffb400;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
