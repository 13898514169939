import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { UxHelper } from '@clientCommon/helper/uxHelper';
import { SignUpPeopleSearch } from '@sales/peopleSearch/signUpPeopleSearchSales';
import { MembershipType, UxComp } from '@ux5.2/types';
import { UxComposite } from '@common/models/ux/uxComposite';
import { CommerceOffer } from '@common/models/commerce/commerceOffer';
import { ServiceHelperService } from '@clientCommon/services/serviceHelper.service';
import { BaseComponent } from '@clientCommon/components/BaseComponent';

const BENEFITS_LIST = [
  "Check for Criminal Offenses",
  "Trace Their Past",
  "Discover SECRETS",
  "Find Out Marital Status",
  "Learn About Their Finances"
];
@Component({
  selector: 'free-trial-top-sup',
  templateUrl: './free-trial-top-sup.component.html',
  styleUrls: ['./free-trial-top-sup.component.scss'],
})
export class FreeTrialTopSupComponent implements OnInit {
  @Input() base: SignUpPeopleSearch;
  @Input() uxHelper: UxHelper;
  @Input() membershipTitle: UxComp;
  @Input() iconLabelCardTitle: UxComp;
  @Input() faqCardTitle: UxComp;
  @Input() faqCardSubTitle: UxComp;
  @Input() faqHeader: UxComp;
  @Input() faqDescription: UxComp;
  @Input() faqSecondHeader: UxComp;
  @Input() faqCancelMembershipContent: UxComp;

  @Output() membershipEvent = new EventEmitter<MembershipType>();


  prefix = 'comp.sales.name-search.signup';
  isBlurred = false;
  benefitsList: string[] = [];
  selected: MembershipType = 'free_trial';

  uxComposite: UxComposite;
  offer: CommerceOffer;

  constructor(private readonly _datePipe: DatePipe, private readonly _serviceHelperService: ServiceHelperService, ) {}

  ngOnInit(): void {
    this.uxComposite = this.base.uxHelper.uxComposite;
    this.benefitsList = this.uxComposite.getUxcomp(`${this.prefix}.bonus-benefits.list`) || BENEFITS_LIST;
    this._setOffer();
  }

  getName(): string {
    if (this.base.isThinMatchEnable) return this.base.userInputFullName;
    if (!this.base?.selectedPerson?.fName) return 'John';
    return `${this.base?.selectedPerson?.fName} ${this.base.selectedPerson?.lName}`;
  }

  getFullName(): string {
    if (this.base.isThinMatchEnable) return this.base.userInputFullName;
    return `${this.base?.selectedPerson?.fName || 'John'} ${this.base.selectedPerson?.lName || 'Doe'}`;
  }

  getState(): string {
    let state = '';

    if (this.base?.isThinMatchEnable) {
      if (this.base?.userInputState === 'all') {
        state = 'Florida';
      } else {
        state = this.base?.userInputState;
      }
    } else {
      state = this.base?.selectedPerson?.cityState || 'Florida';
    }

    //if (this.base.isThinMatchEnabled && this.base.lpCounty) {
    //  state += `              <b>County: </b> ${this.base.lpCounty}`;
    //}

    return state;
  }

  getAge(): string {
    const age: number = this.base?.selectedPerson?.age || 88;
    this.isBlurred = age === 88;
    return age.toString();
  }

  private _setOffer() {
    this.selected = 'free_trial';
    this._initOffer('free_trial');
    this._updateOffer('main');
  }

  private _updateOffer(offerName: string): void {
    sessionStorage.setItem('membership-plan', offerName);
    this.offer = this.base.structuredCommerceOffers[offerName][0];
    const priceDetails = this.offer?.tempClientSecured.prices?.[1]?.[0];
    this.uxComposite.setCode('offerPrice', priceDetails.amount);
    this.uxComposite.setCode('offerFrequency', priceDetails.periodString);
    this.uxComposite.setCode('billingDate', this.offer.tempClientSecured.billingDatesString[1]);
  }

  private _initOffer(value: MembershipType) {
    this.membershipEvent.emit(value);

    const GET_THIN_MATCH_ENABLED: boolean =
      this._serviceHelperService?.storageService?.getSession('thinMatchEnabled') ?? false;
    const THIN_MATCH_TYPE: string = this._serviceHelperService?.storageService?.getSession('thinMatchType') ?? '';

    this._serviceHelperService.trackingService.report({
      page: BaseComponent.PAGE.signup,
      pageType: BaseComponent.PAGE_TYPE.sales,
      type: 'ux_5_2',
      customerSelect: this.selected,
      thinMatch: {
        enabled: GET_THIN_MATCH_ENABLED,
        thinMatchType: THIN_MATCH_TYPE,
      },
      commerceContentId: this?.base?.commerceContent?._id || '',
    });
  }
}
