import {UxHelper} from "../helper/uxHelper";
import {UserInput} from "../../common/models/user/userInput";
import {UserInfo} from "../../common/models/user/userInfo";
import {objectUtils} from "../../common/utils/objectUtils";
import {LogUtils} from "../../common/utils/logUtils";
import {ActivatedRoute} from "@angular/router";
import {behaviorHelper} from "../helper/behaviorHelper";
import {viewUtils} from "../utils/viewUtils";
import {inputUtils} from "../utils/inputUtils";
import {CustomBaseComponent} from "../custom/customBaseComponent";
import {ServiceHelperService} from "../services/serviceHelper.service";

declare var $: any;

/**
 * @extends CustomBaseComponent to have static varibles & resuable functions needed for page, pageType & category
 */
export class BaseComponent extends CustomBaseComponent {
  /**
 * All properties definations that will be required by child components/pages such as theme to access the current theme and
 * uxHelper instance to access the uxComposite etc.
 */
  theme;
  pixelExtra = "";
  domainKey: string;
  uxHelper: UxHelper = new UxHelper();

  userInfo: UserInfo;
  hash: string;

  template = '';
  pathname = location.pathname;
  scrolled: { [id: number]: boolean } = {};

/**
 * @param serviceHelperService will provide access to different required services such as authentication service, uxc service etc.
 * @param activatedRoute provides access to information about a route associated with a component that is loaded in an outlet. *
 */
  constructor(public serviceHelperService: ServiceHelperService,
              public activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
  }

  onDestroy() {
    return super.onDestroy();
  }

  /**
   * This will be called by its parent component to initialize this component
   */
  protected baseInit(): Promise<any> {
    this.initBootstrapModal();
    return super.baseInit().then(() => {// set page component composite and title for page from uxComp
      return this.serviceHelperService.pageService.onPageLoad(this);
    }).then(() => {
      // Disable preboot-loading info
      let preBootElement = document.getElementById("preboot");
      if(preBootElement) {
        preBootElement.style.display = "none";
      }
      // setup the current template & theme from the uxComps
      this.template = this.uxHelper.getUxcomp('template');
      this.theme = this.uxHelper.getTheme();
      LogUtils.debug("theme", this.theme);
    }).catch((e) => {
      LogUtils.error(e);
      return Promise.reject(e);
    });
  }

  // set pixelExtra
  setExtraPixel(extra: string) {
    this.pixelExtra = extra;
  }

  /**
   * @returns object having page properties from CustomBaseComponent
   */
  getPageTypes() {
    return {
      pageType: this.pageType,
      pageCategory: this.pageCategory,
      page: this.page,
    }
  }

  /**
   * used by the child components to Navigate to next page based on provided page information
   * @param pageInfo have page information pageType, pageCategory...
   * @param options  optional options to be provided to the navigation url
   * @param param optional parameters to be attached with navigation url
   */
  goNextPage(pageInfo?: { pageType, pageCategory, page }, options?, param?) {
    return super.goNextPage(pageInfo, options, param);
  }

  /**
   * @param obj javascript object
   * @returns stringified raw json object
   */
  getRaw(obj) {
    let cloned = objectUtils.clone(obj);
    return JSON.stringify(cloned, null, 4);
  }

  /**
   * @returns the data from session storage saved with against the key name of 'pageType:pageCategory'
   */
  getSessionStorageByTypeAndCategory() {
    let key = `${this.pageType}:${this.pageCategory}`;


    let storage = this.serviceHelperService.storageService.getSession(key);
    if (!storage) {
      storage = {};
    }

    return storage;
  }

  /**
   * thi sets data in the session storage against the key name of 'pageType:pageCategory'
   * @param value content/value to be set in session storage
  */
  setSessionStorageByTypeAndCategory(value) {
    let key = `${this.pageType}:${this.pageCategory}`;
    let storage = this.serviceHelperService.storageService.setSession(key, value);
  }


  // this remove backdrop modal as well as modal class from the body on this component initialization
  initBootstrapModal() {
    try {
      $('.modal-backdrop').remove();
      $('body').removeClass('modal-open');
    } catch (e) {
      LogUtils.error(e);
    }
  }

  // this tells current path of application is still same as this component/page path
  protected stillInThisPage() {
    return this.pathname === location.pathname && !this.destroyed;
  }

  // check if there is history in session storage for visited location
  hasHistory() {
    return behaviorHelper.hasHistoryLocation(this.serviceHelperService, this.uxHelper.prefix);
  }

  // set the history in session storage for visited location
  setHistory() {
    behaviorHelper.setHistoryLocation(this.serviceHelperService, this.uxHelper.prefix);
  }

  /**
   * @param radix base number for grouping
   * @param number whose group-range is needed
   * @returns string with number range based on radix i.e for radix=10 & number=50, it will return 41-50
   */
  getRadixGroup(radix, number) {
    if (number > 0) {
      let group = Math.ceil(number / radix);
      return `${((group - 1) * radix) + 1}-${(group) * radix}`;
    } else {
      return "0";
    }
  }

  /**
   * This gets called from child component/page on the scroll event and track the event providing the scroll ratio
   * @param $event is an event object to get event info of the clicked html elment
   */
  onScroll($event) {
    let ratio = viewUtils.getScrollOffsetRatio();
    ratio = Math.floor(ratio * 10);

    if (!this.scrolled[ratio]) {
      this.scrolled[ratio] = true;
      this.serviceHelperService.trackingService.report({type: "scroll", value: ratio * 10});
    }
  }

  // check if enter key pressed while on some input element
  isEnterKeyDownEvent(event) {
    return inputUtils.isEnterEvent(event);
  }
}
