import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgModule,
  OnInit,
  ViewChild,
} from "@angular/core";
import { UxHelper } from "@clientCommon/helper/uxHelper";
import { SignUpPeopleSearch } from "@sales/peopleSearch/signUpPeopleSearchSales";

@Component({
  selector: "ux-5-2-payment-info",
  templateUrl: "./payment-info.component.html",
  styleUrls: ["./payment-info.component.scss"],
})
export class PaymentInfoComponent implements OnInit {
  @Input() base: SignUpPeopleSearch;

  @Input() uxHelper: UxHelper;

  @ViewChild("billingSignup") public billingSignup: ElementRef;
  @ViewChild("firstName") public firstNameInput: ElementRef<HTMLInputElement>;
  @ViewChild("lastName") public lastNameInput: ElementRef<HTMLInputElement>;

  cardType = "";

  reports = [];
  feedbacks = [];
  brandName = "";

  constructor(private dr: ChangeDetectorRef) {}

  init() {
    this.reports = this.uxHelper.getUxcomp("reports");
    this.feedbacks = this.uxHelper.getUxcomp("feedbacks");
    this.brandName = this.uxHelper.uxComposite.brandName;
  }

  ngOnInit() {
    return this.init();
  }

  ngOnChanges() {
    return this.init();
  }

  formatInput() {
    this.base.creditCardInputHelper.fullName =
      this.base.userInfo.fname + " " + this.base.userInfo.lname;
  }

  formatString(value, key) {
    const replaced = this.base.formatString(value, key);
    if (key === "fname") {
      this.firstNameInput.nativeElement.value = replaced;
    } else if (key === "lname") {
      this.lastNameInput.nativeElement.value = replaced;
    }
    this.dr.detectChanges();
  }

  handleChangeCCNumber() {
    this.cardType = this.base.creditCardInputHelper.cardType;
  }
}