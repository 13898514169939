import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, NgModule } from '@angular/core';

@Component({
    selector: 'app-criminal-report-nodata',
    templateUrl: './criminal-nodata.component.html',
    styleUrls: ['./criminal-nodata.component.scss'],
    standalone: false
})
export class CriminalReportNoDataComponent implements OnInit {

  @Input() keepVertical: boolean = false;
  @Input() personFName: string = "";
  @Input() personLName: string = "";

  constructor() { }

  ngOnInit(): void {
  }

}

@NgModule({
  declarations: [CriminalReportNoDataComponent],
  imports: [CommonModule],
  exports: [CriminalReportNoDataComponent],
})
export class CriminalReportNoDataComponentModule {}
