import {IndexField} from '../decorators/database/indexField';
import {CollectionClass, collectionClassHelper} from '../decorators/database/collectionClass';
import {TransientField, transientFieldHelper} from '../decorators/database/transientField';
import {referenceFieldHelper} from '../decorators/database/referenceField';
import {foreignKeyFieldHelper} from '../decorators/database/foreignKeyField';
import {foreignObjectFieldHelper} from '../decorators/database/foreignObjectField';
import {noCyclicFieldHelper} from '../decorators/database/noCyclicField';
import {ServerField} from '../decorators/database/serverField';

@CollectionClass({name: 'modelRevisions'})
export class ModelRevision {
  public static documentUtils;
  private currentDocumentUtils;

  _id?: string;

  @IndexField() createdTimestamp: number;
  @IndexField() referenceId: string;
  referenceCollection: string;
  reference: any;
  referenceForeignRevisions: any = {}; // {fieldName:{objectId:revisionId}}

  data: any = {};

  @ServerField @TransientField public tempServerOnly: any = {}; // temporary field only for server
  @TransientField public tempClient: any = {}; // temporary field for client
  @TransientField public tempClientSecured: any = {}; // temporary field for client
  @TransientField public originalRevisionId: any = {};

  constructor(doc?) {
    if (doc) {
      Object.assign(this as any, doc);
    }
  }

  getDocumentutils() {
    return ModelRevision.documentUtils;
  }

  setCurrentDocumentUtils(docuemntUtils) {
    this.currentDocumentUtils = docuemntUtils;
  }

  getCurrentDocumentUtils() {
    if (this.currentDocumentUtils) {
      return this.currentDocumentUtils;
    } else {
      return this.getDocumentutils();
    }
  }

  getTransientProperties(): string[] {
    return transientFieldHelper.getProperties(this);
  }

  getReferenceProperties(): { key: string, value: any }[] {
    return referenceFieldHelper.getProperties(this, collectionClassHelper.getCollectionNameByDecoratorFieldValue);
  }

  getForeignKeyProperties(): { key: string, value: any }[] {
    return foreignKeyFieldHelper.getProperties(this, collectionClassHelper.getCollectionNameByDecoratorFieldValue);
  }

  getForeignObjectProperties(): { key: string, value: any }[] {
    return foreignObjectFieldHelper.getProperties(this);
  }

  getNoCyclicProperties(): string[] {
    return noCyclicFieldHelper.getProperties(this);
  }

  loadForeignObject(options?): Promise<any> {
    if (!options) {
      options = {};
    }
    if (!options.objMeta) {
      options.objMeta = {};
    }
    options.objMeta.depth = this.getObjDepth() + 1;

    return this.getCurrentDocumentUtils().loadForeignObject(this, options);
  }

  getClientDoc(): ModelRevision {
    const obj = new ModelRevision();
    const reference = this.getInstance();

    obj._id = this._id;
    obj.referenceId = this.referenceId;
    obj.referenceCollection = this.referenceCollection;
    obj.reference = reference.getClientDoc();
    obj.tempClientSecured = this.tempClientSecured;
    obj.tempClient = this.tempClient;
    obj.originalRevisionId = this.originalRevisionId;
    return obj;
  }

  getSecuredDoc(): ModelRevision {
    const obj = new ModelRevision();
    const reference = this.getInstance();

    obj._id = this._id;
    obj.referenceId = this.referenceId;
    obj.referenceCollection = this.referenceCollection;
    obj.reference = reference.getSecuredDoc();
    obj.tempClientSecured = this.tempClientSecured;
    obj.originalRevisionId = this.originalRevisionId;
    return obj;
  }

  getInstance() {

    const prototype = collectionClassHelper.getClassByKeyValue('name', this.referenceCollection).target;
    const instance = new prototype.constructor(this.reference);

    instance.revisionFlag = true;
    instance.draft.revisionFlag = true;
    return instance;
  }

  getObjDepth(): number {
    if (!this.tempServerOnly.meta) {
      this.tempServerOnly.meta = {};
    }

    return this.tempServerOnly.meta.depth ? this.tempServerOnly.meta.depth : 0;
  }

  setObjDepth(depth: number) {
    if (!this.tempServerOnly.meta) {
      this.tempServerOnly.meta = {};
    }

    this.tempServerOnly.meta.depth = depth;
  }
}
