import {Component, Input} from "@angular/core";

@Component({
  selector: 'ux-4-0-stepper',
  templateUrl: 'stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class Ux4DotStepperComponent {
  @Input() step;
  @Input() substep;

  constructor() {}

}
