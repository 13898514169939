import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, NgModule } from '@angular/core';

@Component({
    selector: 'app-mini-offense-card',
    templateUrl: './offense-card.component.html',
    styleUrls: ['./offense-card.component.scss'],
    standalone: false
})
export class MiniOffenseCardComponent implements OnInit {
  @Input() item;
  @Input() isPdfPrintView;
  @Input() index;
  showMore = false;

  constructor() {}

  ngOnInit(): void {}
}

@NgModule({
  declarations: [MiniOffenseCardComponent],
  imports: [CommonModule],
  exports: [MiniOffenseCardComponent],
})
export class MiniOffenseCardComponentModule {}
