import {ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, ViewChild} from "@angular/core";
import {UxHelper} from "../../../../../clientCommon/helper/uxHelper";
import {SignUpPeopleSearch} from "../../../../sales/peopleSearch/signUpPeopleSearchSales";

declare var $: any;

@Component({
  selector: 'ux-4-0-signup-name-search',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class Ux4Dot0SignupNameSearchComponent implements OnInit, OnChanges {
  @Input() base: SignUpPeopleSearch;

  @Input() uxHelper: UxHelper;

  @ViewChild('billingSignup') public billingSignup: ElementRef;
  @ViewChild('firstName') public firstNameInput: ElementRef<HTMLInputElement>;
  @ViewChild('lastName') public lastNameInput: ElementRef<HTMLInputElement>;

  cardType = '';

  constructor(
    private dr: ChangeDetectorRef
  ) {
  }

  init() {
    setTimeout(function () {
      $('.carousel').carousel({
        interval: 6000,
        cycle: true
      });
      $('[data-toggle="popover"]').popover();
    }, 2000);
  }

  ngOnInit() {
    return this.init();
  }

  ngOnChanges() {
    return this.init();
  }

  formatInput() {
    this.base.creditCardInputHelper.fullName = this.base.userInfo.fname + ' ' + this.base.userInfo.lname;
  }

  onSubmitStep1() {
    this.base.goNextStep(this.base.steps.email);
  }

  formatString(value, key) {
    const replaced = this.base.formatString(value, key);
    if (key === 'fname') {
      this.firstNameInput.nativeElement.value = replaced;
    } else if (key === 'lname') {
      this.lastNameInput.nativeElement.value = replaced;
    }
    this.dr.detectChanges();
  }

  handleChangeCCNumber() {
    this.cardType = this.base.creditCardInputHelper.cardType;
  }
}
