import {Component, Input, OnChanges, OnInit} from "@angular/core";
import {UxHelper} from "../../../../../clientCommon/helper/uxHelper";
import {SignUpPeopleSearch} from "../../../../sales/peopleSearch/signUpPeopleSearchSales";

declare var $: any;

@Component({
  selector: 'ux-2-0-signup-name-search',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class Ux2Dot0SignupNameSearchComponent implements OnInit, OnChanges {
  @Input() base: SignUpPeopleSearch;

  @Input() uxHelper: UxHelper;

  officePhone: String;

  constructor() {
  }

  init() {
    setTimeout(function () {
      $('.carousel').carousel({
        interval: 6000,
        cycle: true
      });
      $('[data-toggle="popover"]').popover();
    }, 2000);

    this.officePhone = this.uxHelper.uxComposite.get("comp.brand.customer.phone");
  }

  ngOnInit() {
    return this.init();
  }

  ngOnChanges() {
    return this.init();
  }
}
