import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';

@Component({
  selector: 'ux-5-2-card-grid',
  templateUrl: './card-grid.component.html',
  styleUrls: ['./card-grid.component.scss'],
})
export class CardGridComponent {
  @Input() title = '';
}

// @NgModule({
//   declarations: [CardGridComponent],
//   imports: [CommonModule],
//   exports: [CardGridComponent],
// })
// export class CardGridComponentModule {}