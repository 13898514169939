import {CollectionClass} from "../decorators/database/collectionClass";
import {ModelBase} from "./modelBase";
import {IndexField} from "../decorators/database/indexField";

@CollectionClass({name: "notes"})
export class Note extends ModelBase<Note> {

  @IndexField() referenceId: string;
  referenceCollection: string;
  @IndexField() categories: string[] = [];
  note: string;
  privateFlag: boolean;
  to: string;
  from: string;
  subject: string;
  @IndexField() externalId: string;

  constructor(doc?, draftFlag?: boolean) {
    super(doc, draftFlag);
    this.init(doc, this.draftFlag);
  }

  getSecuredDoc(): Note {
    var obj: Note = super.getSecuredDoc();
    obj.note = this.note;
    obj.referenceId = this.referenceId;
    obj.referenceCollection = this.referenceCollection;
    obj.type = this.type;

    return obj;
  }

}

