import {Component, Input} from "@angular/core";

@Component({
  selector: 'sup-payment-loader',
  templateUrl: 'loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class SignupPaymentLoader {

  constructor() {}

}
