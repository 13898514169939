import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter, NgModule } from '@angular/core';

@Component({
    selector: 'app-mini-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    standalone: false
})
export class MiniButtonComponent implements OnInit {
  @Input() variant: string;
  @Input() icon: string;
  @Input() color: string;
  @Input() size: string;
  @Input() block: boolean;
  @Input() label: string;
  @Output() click = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  handleClick() {
    this.click.emit();
  }
}

@NgModule({
  declarations: [MiniButtonComponent],
  imports: [CommonModule],
  exports: [MiniButtonComponent],
})
export class MiniButtonComponentModule {}
