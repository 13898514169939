import {
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
  Input,
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  AfterViewInit,
  Renderer2,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceHelperService } from '../../../../../../clientCommon/services/serviceHelper.service';
import { PersonDetail } from '../../../personDetail';
import { ToastrService } from '../../../../../service/toastr.service';
import { BaseComponent } from '../../../../../../clientCommon/components/BaseComponent';
import { peopleSearchProductKeys } from '../../../../../../common/custom/peopleSearch/peopleSearchProductKeys';
import { AuthenticationService, NoteService } from '../../../../../../clientCommon/services';
import { CommonModule } from '@angular/common';
import { SocialCardComponentModule } from './socialCard/socialCard.component';
import { MemberUpsellPremiumBadgeComponentModule } from '@member/components/upsells/premium-badge/premium-badge.component';
import { MiniReportHeaderModule } from '@member/peopleSearch/components/mini/header/header.component';
import { MemberUpsellGlobalComponentModule } from '@member/components/upsells/global/upsell-global.component';
import { MemberUpsellHouseHoldComponentModule } from '@member/components/upsells/household/upsell-household.component';
import { MiniReportNotesComponentModule } from '@member/peopleSearch/components/mini/notes/notes.component';
import { AdComponentModule } from 'app/components/ad/ad.component';
import { MiniReportFooterModule } from '@member/peopleSearch/components/mini/footer/footer.component';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { SwiperModule } from '../../../../../../clientCommon/directives/SwiperDirective';
import { LogUtils } from '@common/utils/logUtils';
import { MiniSocialMediaBaseComponent } from './miniSocialMediaBase.component';
import { FactFindersComponentModule } from '../factFinders/factFinders.component';
declare var $: any;

@Component({
    selector: 'app-mini-social-media',
    templateUrl: 'miniSocialMedia.component.html',
    styleUrls: ['miniSocialMedia.component.scss'],
    standalone: false
})
export class MiniSocialMediaComponent extends MiniSocialMediaBaseComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {


  constructor(
    public serviceHelperService: ServiceHelperService,
    public changeDetectorRef: ChangeDetectorRef,
    activatedRoute: ActivatedRoute,
    public renderer: Renderer2,
    protected router: Router,
    public toastr: ToastrService,
    public noteService: NoteService,
    public authService: AuthenticationService
  ) {
    super(serviceHelperService, changeDetectorRef, activatedRoute, renderer, router, toastr, noteService, authService);
  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  ngOnInit() {
    return super.ngOnInit();
  }

  ngOnDestroy() {
    return super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  trackLink(type) {
    if (!type || typeof type !== 'string') {
      return;
    }

    this.serviceHelperService.trackingService.report({
      page: this.uxHelper.page,
      type: peopleSearchProductKeys.nameSearch,
      section: 'socialMedia',
      action: `socialCardClick${type}`,
    });
  }
}

@NgModule({
  declarations: [MiniSocialMediaComponent],
  imports: [
    CommonModule,
    SocialCardComponentModule,
    MiniReportHeaderModule,
    MemberUpsellPremiumBadgeComponentModule,
    MemberUpsellGlobalComponentModule,
    MemberUpsellHouseHoldComponentModule,
    MiniReportNotesComponentModule,
    AdComponentModule,
    MiniReportFooterModule,
    SwiperModule,
    FactFindersComponentModule,
  ],
  exports: [MiniSocialMediaComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class MiniSocialMediaComponentModule {}
