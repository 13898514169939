import {
    ChangeDetectorRef,
    Component,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    EventEmitter,
    Input,
    HostListener,
    NgModule,
  } from "@angular/core";
  import { ActivatedRoute, Router } from "@angular/router";
  import { ServiceHelperService } from "../../../../../clientCommon/services/serviceHelper.service";
  import { PersonDetail } from "../../personDetail";
  import { ToastrService } from "../../../../service/toastr.service";
import { DomSanitizer } from "@angular/platform-browser";
import { SearchedPerson } from "../../../../../common/custom/models/peopleSearch/searchedPerson";
import { NoteService, AuthenticationService } from "../../../../../clientCommon/services";

import Map from 'ol/Map';
import OSM from 'ol/source/OSM';
import TileLayer from 'ol/layer/Tile';
import {Vector as VectorLayer} from 'ol/layer';
import View from 'ol/View';
import {Vector} from 'ol/source';
import {Feature} from 'ol';
import {Style, Icon} from 'ol/style';
import {fromLonLat} from 'ol/proj';
import {FullScreen, defaults as defaultControls} from 'ol/control';
import Point from "ol/geom/Point";
import * as olProj from 'ol/proj';
import { BaseComponent } from "../../../../../clientCommon/components/BaseComponent";
import { peopleSearchProductKeys } from "../../../../../common/custom/peopleSearch/peopleSearchProductKeys";
import { CommonModule } from "@angular/common";
import { MemberUpsellPhoneComponentModule } from "@member/components/upsells/phone";

declare var $: any;

@Component({
    selector: "app-mini-linear-phone",
    templateUrl: "miniLinear.component.html",
    styleUrls: ["miniLinear.component.scss"],
    standalone: false
})
export class MiniLinearPhoneSearchComponent
  extends PersonDetail
  implements OnInit, OnDestroy, OnChanges {
  href: string = "";
  showAllEmail: boolean = false;
  thisIsMe: boolean = true;
  @Input() isPdfPrintView: boolean = false;
  @Input() searchedPersons: SearchedPerson[] = [];
  @Output() goNext = new EventEmitter<any>();
  @Output() onReload = new EventEmitter<void>();
  expanded: boolean[] = [];
  upsellEnabled = false;
  enhancedflag=false;
  bonusflag=false;

  constructor(
    public serviceHelperService: ServiceHelperService,
    public changeDetectorRef: ChangeDetectorRef,
    activatedRoute: ActivatedRoute,
    private router: Router,
    public toastr: ToastrService,
    private domSanitizer: DomSanitizer,
    public noteService: NoteService,
    public authService: AuthenticationService
  ) {
    super(serviceHelperService, activatedRoute, toastr, noteService, authService);
  }

  ngOnInit() {
    const upsellphoneflag=this.uxHelper.uxComposite.get("comp.billing.enhanced.phone.enabled")
    this.bonusflag=upsellphoneflag.bonus;
    this.enhancedflag=upsellphoneflag.enhanced;
    this.showAllEmail = this.isPdfPrintView;
    this.href = this.router.url;
    return this.init().then(() => {
      this.uxComposite.setCode('searchedPhone', this.person.tempClient?.selectedPhoneNumber);
      if (this.person.tempClient?.upsellEnabled) {
        this.upsellEnabled = true;
      }
      this.serviceHelperService.trackingService.report({
        page: BaseComponent.PAGE.detail,
        pageType: BaseComponent.PAGE_TYPE.member,
        type: peopleSearchProductKeys.phoneSearch,
        section: 'linear',
        action: 'view',
      });
      this.serviceHelperService.trackingService.report({
        section: 'phone',
        action: 'view',
        type: 'report'
      });
      this.initMap();
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy() {
    return super.onDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  init() {    
    return super.init();
  }

  initMap() {
    this.searchedPersons.forEach((person, personIdx) => {
      if (!this.person.addresses.length) {
        return;
      }

      const newmap = new Map({
        controls: defaultControls().extend([
          new FullScreen({
            source: 'fullscreen',
          }) ]),
        layers: [
          new TileLayer({
            source: new OSM(),
          }) ],
        target: `map${personIdx}`,
        view: new View({
          center: fromLonLat([this.person.addresses[0].longitude, this.person.addresses[0].latitude]),
          zoom: 10,
        }),
      });

      const iconFeatures: Feature[] = person.addresses.map((off, index) => {
        const feature = new Feature(new Point(olProj.fromLonLat([off.longitude, off.latitude])))
        feature.setId(index);
        return feature;
      });

      const iconStyle = new Style({
        image: new Icon({
          anchor: [0,5, 80],
          anchorXUnits: 'fraction',
          anchorYUnits: 'pixels',
          src: 'assets/ux1/images/icons/pin.svg',
        }),
      });

      iconFeatures.forEach((feature, idx) => {
        // feature.setStyle(new Style({
        //   fill: new Fill({
        //     color: '#acacac'
        //   }),
        //   text: new Text({
        //     text: `${idx+1}`,
        //     backgroundFill: new Fill({
        //       color: '#acacac'
        //     }),
        //     fill: new Fill({
        //       color: '#fff'
        //     }),
        //     scale: 3,
        //     textAlign: 'center',
        //   }),
        // }));
        feature.setStyle(iconStyle);
      });

      const markerSource = new Vector({
        features: iconFeatures
      });

      const iconLayer = new VectorLayer({
        source: markerSource
      });

      newmap.addLayer(iconLayer);
    })
  }

  @HostListener("mousewheel", ["$event"]) onMousewheel(event) {
    //this.router.navigate([]);
  }

  getAkas(person: SearchedPerson) {
    return person.akas?.map(aka => `${aka.fName} ${aka.mName} ${aka.lName}`.trim());
  }

  getEmails() {
    return this.person.emailAddresses.map((item) => item.address);
  }

  switchShowAllEmail() {
    this.showAllEmail = !this.showAllEmail;
  }

  handleNext() {
    window.scrollTo(0, 0);
    this.goNext.emit();
  }

  onThisIsMe() {
    this.thisIsMe = !this.thisIsMe;
  }

  getAllRelatedPerson(person: SearchedPerson) {
    const merged = [...person.relatives.map(p => ({...p, relationType: 'Relative'})), ...person.associates.map(p => ({...p, relationType: 'Associate'}))]
    this.expanded = new Array(merged.length).fill(false);
    return merged;
  }

  getClassName(index: number) {
    return index === 0 ? 'index first' : 'index';
  }

  getPhoneNumbers(person: any) {
    return person.phones.slice(0, person.tempClient.expanded ? person.phones.length : 4).map(phone => phone.number).join(", ");
  }

  toggleExpanded(person: any) {
    if (person.tempClient.expanded) {
      person.tempClient.expanded = false;
      return
    }
    else person.tempClient.expanded = true;
  }

  viewDetailedReport() {
    const person = this.searchedPersons[0];

    this.serviceHelperService.userInputService.userInput = {
      fname: person?.fName,
      lname: person?.lName,
      state: person?.addresses?.[0]?.state,
      extId: person?.extId,
    };
    this.serviceHelperService.userInputService.storeUserInput();
    this.router.navigateByUrl(this.customClientPaths.memberNameSearchLanding + '?skip=true&keepUserInput=1');
  }

  trackDownload() {
    this.serviceHelperService.trackingService.report({
      page: BaseComponent.PAGE.detail,
      pageType: BaseComponent.PAGE_TYPE.member,
      type: peopleSearchProductKeys.phoneSearch,
      action: 'download'
    });
  }

  hasPremiumFields(searchedPerson: SearchedPerson) {
    if (!searchedPerson.tempClient.upsellEnabled) {
      return false;
    }
    return Object.keys(searchedPerson.tempClient.availableItems).length > 0;
  }

  handleReload() {
    this.onReload.emit();
    setTimeout(() => {
      this.initMap();
    }, 2000)
  }
}

@NgModule({
  declarations: [MiniLinearPhoneSearchComponent],
  imports: [CommonModule, MemberUpsellPhoneComponentModule],
  exports: [MiniLinearPhoneSearchComponent],
})
export class MiniLinearPhoneSearchComponentModule {}
