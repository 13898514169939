import { Component, Input, OnInit, Output, EventEmitter, NgModule } from '@angular/core';
import { SearchedPerson } from '../../../../../../../src/common/custom/models/peopleSearch/searchedPerson';
import { UxHelper } from '../../../../../../../src/clientCommon/helper/uxHelper';
import { BaseDirective } from '../../../../../../../src/clientCommon/directives/BaseDirective';
import { ServiceHelperService } from '../../../../../../../src/clientCommon/services/serviceHelper.service';
import { ActivatedRoute } from '@angular/router';
import { User } from '../../../../../../../src/common/models/user/user';

import { timeUtils } from '../../../../../../../src/common/utils/timeUtils';

import { LogUtils } from '../../../../../../../src/common/utils/logUtils';

import { CommerceContent } from '../../../../../../../src/common/models/commerce/commerceContent';
import { CommonModule } from '@angular/common';

declare const $: any;

@Component({
    selector: 'app-bonusupsell',
    templateUrl: './bonusupsell.component.html',
    styleUrls: ['./bonusupsell.component.scss'],
    standalone: false
})
export class BonusupsellComponent extends BaseDirective implements OnInit {
  user: User;

  commerceContent: CommerceContent;

  timeUtils = timeUtils;
  loader = false;
  unlocked = true;
  selectedPerson: SearchedPerson;
  @Input() isPdfPrintView: boolean = false;
  @Input() partialKey: string = '';
  @Input() pageCategory = '';
  @Input() uxHelper: UxHelper;
  @Input() person: SearchedPerson;
  @Input() commerceContentId: string;

  @Output() onSuccess = new EventEmitter<void>();

  constructor(serviceHelperService: ServiceHelperService, activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
  }

  ngOnInit() {
    //Finding Content Based on CommerceContentId
    this.serviceHelperService.commerceService
      .findContent(this.commerceContentId)
      .then((commerceContent: CommerceContent) => {
        this.commerceContent = commerceContent;

        LogUtils.debug(commerceContent);
      })
      .catch(() => {
        this.serviceHelperService.alertService.error('Error');
      })
      .then(() => {});
  }
  init() {}

  ngOnDestroy() {
    return super.onDestroy();
  }

  upsell() {
    //Unlocking Bonus Data after the Loader is done Loading
    this.serviceHelperService.spinnerService.spin();
    return this.serviceHelperService.commerceService
      .unlockBonus({
        _id: this.commerceContentId,
        data: this.unlocked,
      })
      .then(() => {
        this.onSuccess.emit();
        this.serviceHelperService.spinnerService.unspin();
      })
      .catch(() => this.serviceHelperService.spinnerService.unspin());
  }
}

@NgModule({
  declarations: [BonusupsellComponent],
  imports: [CommonModule],
  exports: [BonusupsellComponent],
})
export class BonusUpsellComponentModule {}
