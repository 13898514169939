export class UserInfo {
  id?: string;
  uxcId?: string;
  uxlId?: string;
  prevUx: { timestamp: number, uxcId: string, uxlId: string }[] = [];
  queries?: any = {};

  password?: string;
  fname?: string;
  lname?: string;
  email?: string;
  extEmails: string[] = [];
  optin?: boolean;
  phone?: string;
  brandId?: string;
  zip?: string;
  connectionInfo?;

  constructor(doc?) {
    if (doc) {
      Object.assign(<any>this, doc);
    }
  }
}
