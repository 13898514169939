import {LogUtils} from "./logUtils";

class TimeUtils {
  public static months = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December"
  };

  getTimestamp(): number {
    return (new Date()).getTime();
  }

  getAge(timestamp) {
    if (timestamp > 0) {
      let current = new Date().getTime();
      let age = (current - timestamp) / 1000 / 86400 / 365;
      return Math.floor(age);
    }
  }

  formatStringDate(stringDate) {
    if (stringDate) {
      let date = stringDate.split("/");
      if (date.length === 3) {
        return `${TimeUtils.months[date[0]]} ${date[1]}, ${date[2]}`;
      }
    }
  }

  formatDate(date: Date) {
    if (date) {
      let month: any = date.getMonth() + 1;
      let day: any = date.getDate();
      let year = date.getFullYear();

      if (month < 10) {
        month = `0${month}`;
      }
      if (day < 10) {
        day = `0${day}`;
      }

      return `${month}/${day}/${year}`;
    }
  }

  timeout(ms) {
    return new Promise<any>((fulfill, reject) => {
      try {
        setTimeout(() => {
          fulfill(true);
        }, ms);
      } catch (e) {
        reject(e);
      }
    });
  }

  getTimestampFromExpDate(exp) {
    if (exp && exp.length === 4) {
      let expDate = new Date();
      let expYear = 2000 + parseInt(exp.substring(2, 4));
      let expMonth = parseInt(exp.substring(0, 2));
      expDate.setFullYear(expYear, expMonth - 1, 1);
      expDate.setHours(0, 0, 0);

      return expDate.getTime();
    } else {
      LogUtils.error("Wrong expDate", exp);
      return 0;
    }
  }

  getExp4Digits(expYear, expMonth) {
    if (expYear && expMonth) {
      let month = expMonth.toString();
      if (month.length < 2) {
        month = `0${month}`;
      }
      let year = (parseInt(expYear) % 100).toString();
      if (year.length < 2) {
        year = `0${year}`;
      }
      return `${month}${year}`;
    }
  }

  getCurrentYear() {
    return new Date().getFullYear();
  }

  setDay(date, dayOfWeek) {
    let dateOfMonth = date.getDate();
    dateOfMonth += dayOfWeek - date.getDay();
    date.setDate(dateOfMonth);
    let d = new Date(date);
  }

  getYearMonthFromExp4digit(exp4digit: string): { year: number, month: number } {
    return {
      year: parseInt(exp4digit.substring(2, 4)) + 2000,
      month: parseInt(exp4digit.substring(0, 2)),
    };
  }

  fromNow(time, tokenReplace = undefined) {

    switch (typeof time) {
      case 'number':
        break;
      case 'string':
        time = +new Date(time);
        break;
      case 'object':
        if (time.constructor === Date) time = time.getTime();
        break;
      default:
        time = +new Date();
    }
    let time_formats = [
      [60, 'seconds', 1], // 60
      [120, '1 minute ago', '1 minute from now'], // 60*2
      [3600, 'minutes', 60], // 60*60, 60
      [7200, '1 hour ago', '1 hour from now'], // 60*60*2
      [86400, 'hours', 3600], // 60*60*24, 60*60
      [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
      [604800, 'days', 86400], // 60*60*24*7, 60*60*24
      [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
      [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
      [4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
      [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
      [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
      [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
      [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
      [58060800000, 'centuries', 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
    ];
    let seconds = (+new Date() - time) / 1000,
      token = 'ago',
      list_choice = 1;

    if (seconds == 0) {
      return 'Just now'
    }
    if (seconds < 0) {
      seconds = Math.abs(seconds);
      token = 'from now';
      list_choice = 2;
    }
    if (tokenReplace !== undefined) {
      token = tokenReplace;
    }
    let i = 0,
      format;
    while (format = time_formats[i++])
      if (seconds < format[0]) {
        if (typeof format[2] == 'string')
          return format[list_choice];
        else
          return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
      }
    return time;
  }
}

export const timeUtils = new TimeUtils();
