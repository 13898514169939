import {BaseComponent} from "../../../clientCommon/components/BaseComponent";
import {LoaderPeopleSearch} from "../../peopleSearch/loaderPeopleSearch";
import {CommerceOrder} from "../../../common/models/commerce/commerceOrder";
import {LogUtils} from "../../../common/utils/logUtils";
import {ServiceHelperService} from "../../../clientCommon/services/serviceHelper.service";
import {ActivatedRoute} from "@angular/router";
import {redirectHelper} from "../../../clientCommon/helper/redirectHelper";
import {clientPaths} from "../../../common/helpers/pathHelpers";
import {commerceUtils} from "../../../common/utils/commerceUtils";

export class LoaderPeopleSearchMember extends LoaderPeopleSearch {

  commerceOrders: CommerceOrder[] = [];

  constructor(serviceHelperService: ServiceHelperService,
              activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
    this.pageType = BaseComponent.PAGE_TYPE.member;
  }

  onInit(): Promise<any> {
    return super.onInit().then(() => {
      return this.checkOrder();
    }).then(() => {
      this.initDone = true;
        return this.search()
    }).catch((e) => {
      LogUtils.error(e);
      return Promise.reject(e);
    });
  }

  checkOrder(): Promise<any> {
    return this.getCommerceOrdersByPageCategory().then((commerceOrders: CommerceOrder[]) => {
      LogUtils.debug("checkOrder", commerceOrders);
      this.commerceOrders = commerceOrders;
      if (commerceUtils.canProcessSomeCommerceOrders(commerceOrders, this.getProductKeyByPageCategory())) {
        this.hasAccess = true;
      } else {
        return Promise.reject(false);
      }
    }).catch((e) => {
      LogUtils.error(e, "has no order for this page");
    });
  }
}
