import { Component, Input, OnInit, Output, EventEmitter, NgModule } from '@angular/core';
import {  ViewportScroller } from '@angular/common';
import { UxComp, PaperFormat } from '@ux5.2/types';

@Component({
  selector: 'ux-5-2-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
  @Input() brandName:string = null;
  @Input() title: UxComp;
  @Output() clickEvent = new EventEmitter();

  format = PaperFormat;

  constructor(private viewportScroller: ViewportScroller) { }

  ngOnInit(): void {
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  clickEventOnButton() {
    this.clickEvent.emit('');
  }
}
