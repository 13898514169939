import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, NgModule } from '@angular/core';

@Component({
  selector: 'ux-5-2-image-title',
  templateUrl: './image-title.component.html',
  styleUrls: ['./image-title.component.scss']
})
export class ImageTitleComponent implements OnInit {

  @Input() text: string = null;
  @Input() isTooManyResults: boolean = false;
  @Input() userName:string = null;
  @Input() imageUrl: string = null;
  @Input() results: number = null;

  constructor() { }

  ngOnInit(): void {
  }

}

// @NgModule({
//   declarations: [ImageTitleComponent],
//   imports: [CommonModule],
//   exports: [ImageTitleComponent],
// })
// export class ImageTitleComponentModule {}
