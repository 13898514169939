import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'appTransform' })
export class TransformPipe implements PipeTransform {
  transform(items: any, searchText?: any) {
    if (searchText === undefined) {
      return items;
    }
    searchText = searchText.trim();
    if (searchText === "") {
      return items;
    }
    return items.filter((value: any) => {
      return value.name.toLowerCase().includes(searchText.toLowerCase());
    })
  }
}
