import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceHelperService } from '../../../../../../clientCommon/services/serviceHelper.service';
import { PersonDetail } from '../../../personDetail';
import { ToastrService } from '../../../../../service/toastr.service';
import { BaseComponent } from '../../../../../../clientCommon/components/BaseComponent';
import { phoneUtils } from '../../../../../../common/utils/phoneUtils';
import { AddressField, AddressUtils } from '../../../../../../common/utils/addressUtils';
import { peopleSearchProductKeys } from '../../../../../../common/custom/peopleSearch/peopleSearchProductKeys';
import { AuthenticationService, NoteService } from '../../../../../../clientCommon/services';
import { environment } from '../../../../../../environments/environment';
import { stringUtils } from '../../../../../../common/utils/stringUtils';

declare var $: any;

class CustomProduct {
  public static ACTION = {
    redirect: 'redirect',
  };

  productType: string;
  name: string;
  action: string;
  value: string;
  forms: {
    [type: string]: any;
  }[] = [];

  public static CODE = {
    requestState: 'requestState',
  };
}

@Component({
    template: '',
    standalone: false
})
export class MiniMiscellaneousBaseComponent extends PersonDetail {
  href = '';

  @Output() showLoader = new EventEmitter<void>();
  @Input() isPdfPrintView: boolean = false;

  @ViewChild('scrollToTop', { static: true })
  scrollToTopBtnElement: ElementRef;
  
  phoneUtils = phoneUtils;
  stringUtils = stringUtils;
  addressField: AddressField = AddressUtils.getAddressField('US');
  customerNumber = '';
  loaderEyeTimeout: number = environment.miniDetailsLoaderEyeTimeout; //2000; // loader animated Eye Timeout

  stateList = this.addressField;
  ageList = [];

  relativesList = [];

  customProducts: CustomProduct[] = [];
  selectedCustomProductValues: any = {};
  productType: string;
  selectedPhotos = [];
  swiperIndex = 0;
  showAllRelatives = false;

  constructor(
    public serviceHelperService: ServiceHelperService,
    public changeDetectorRef: ChangeDetectorRef,
    activatedRoute: ActivatedRoute,
    protected router: Router,
    public renderer: Renderer2,
    public toastr: ToastrService,
    public authService: AuthenticationService,
    public noteService: NoteService
  ) {
    super(serviceHelperService, activatedRoute, toastr, noteService, authService);
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.customerNumber = this.uxHelper.getValue('comp.brand.customer.phone');
    this.showAllRelatives = this.isPdfPrintView;

    // creating ageList for dropdown
    let g = 18;
    while (g > 17 && g < 60) {
      this.ageList.push(g);
      g++;
    }

    this.href = this.router.url;
    this.relativesList = [...this.person.associates];

    return this.init().then(() => {
      this.serviceHelperService.trackingService.report({
        page: BaseComponent.PAGE.detail,
        pageType: BaseComponent.PAGE_TYPE.member,
        type: peopleSearchProductKeys.nameSearch,
        section: 'misc',
        action: 'view',
      });
      
      this.serviceHelperService.trackingService.report({
        page: BaseComponent.PAGE.detail,
        pageType: BaseComponent.PAGE_TYPE.member,
        type: peopleSearchProductKeys.factFinder,
        action: 'factFinderViewed',
      });

      this.changeDetectorRef.detectChanges();
    });
  }
  ngAfterViewInit() {
    // Register scroll to top event listener.
    const screenHeight = window.screen.availHeight;
    window.onscroll = () => {
      if (document.body.scrollTop > screenHeight || document.documentElement.scrollTop > screenHeight) {
        this.renderer.setStyle(this.scrollToTopBtnElement.nativeElement, 'display', 'flex');
      } else {
        this.renderer.setStyle(this.scrollToTopBtnElement.nativeElement, 'display', 'none');
      }
    };
  }
  scrollToTopHandler() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
  ngOnDestroy() {
    return super.onDestroy();
  }

  handleLoader() {
    this.showLoader.emit();
  }
  
  init() {
    // Tooltip init
    setTimeout(() => {
      $('.tooltip-container').tooltip({
        html: true,
        trigger: 'click',
      });
    }, 0);
    return super.init().then(() => {
      this.customerNumber = this.uxHelper.getValue('comp.brand.customer.phone');
    });
  }

  handlePrevious() {
    window.scrollTo(0, 0);
    this.showLoader.emit();
    setTimeout(() => {
      this.router.navigate([`member/nameSearch/detail/${this.commerceContent._id}/criminalRecords`]);
    }, this.loaderEyeTimeout);
  }

  onContactUs() {
    window.scrollTo(0, 0);
    // this.goTo.emit();
    this.router.navigate(['member/mini-contact-us']);
  }

  cancelModal(index) {
    $('#modal-' + index).modal('hide');
  }

  openPhotoModal(photos) {
    this.selectedPhotos = photos;
    this.swiperIndex = 0;
    if (this.selectedPhotos?.length < 2) {
      return;
    }
    this.serviceHelperService.trackingService.report({
      page: BaseComponent.PAGE.detail,
      pageType: BaseComponent.PAGE_TYPE.member,
      type: peopleSearchProductKeys.nameSearch,
      section: 'associates',
      action: 'view_more_images',
    });
    $('#personPhotoModal').modal({
      backdrop: 'static',
    });
  }

  moveStep(step) {
    if (step < 0) {
      if (this.swiperIndex === 0) {
        this.swiperIndex = this.selectedPhotos.length - 1;
      } else {
        this.swiperIndex--;
      }
    } else {
      if (this.swiperIndex === this.selectedPhotos.length - 1) {
        this.swiperIndex = 0;
      } else {
        this.swiperIndex++;
      }
    }
  }

  switchShowAllRelative() {
    this.showAllRelatives = !this.showAllRelatives;
  }

}
