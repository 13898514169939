import { AfterViewInit, Component, Input, OnChanges, OnInit } from "@angular/core";
import { UxHelper } from "../../../../../clientCommon/helper/uxHelper";
import { SearchResultPeopleSearch } from "../../../searchResultPeopleSearch";
import { UxComposite } from "../../../../../common/models/ux/uxComposite";

@Component({
  selector: 'default-searchResult-name-search',
  templateUrl: './searchResult.component.html',
  styleUrls: ['./searchResult.component.scss'],
})
export class DefaultSearchResultNameSearchComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() base: SearchResultPeopleSearch;

  @Input() uxHelper: UxHelper;
  @Input() pageType: string;
  @Input() pageCategory: string;
  @Input() page: string;

  brandAdUnit = {
    "peoplesearcher": "peoplesearcher_sales_search_results_page_dynamic_mobile",
    "privaterecords": "privaterecords_salesresults_dynamic_mobile"
  }
  constructor() {
  }
  ngAfterViewInit(): void { }

  init() { }

  ngOnInit() {
    return this.init();
  }

  ngOnChanges() {
    return this.init();
  }

  get adsDisplayInterval() {
    return this.uxHelper.getUxcomp('ad.bottom.1.config')?.interval;
  }

  shouldDisplayAd(i: number) {
    if ((!this.adsDisplayInterval) || (i < this.adsDisplayInterval)) return false;
    return parseFloat((i % this.adsDisplayInterval).toString()) === 0
  }
}
