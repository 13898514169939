import {ModelBase} from "../modelBase";
import {CollectionClass} from "../../decorators/database/collectionClass";
import {TransientField} from "../../decorators/database/transientField";
import {ForeignObjectField} from "../../decorators/database/foreignObjectField";
import {ReferenceField} from "../../decorators/database/referenceField";
import {ForeignKeyField} from "../../decorators/database/foreignKeyField";
import {CommerceTransaction} from "./commerceTransaction";
import {CommerceOffer, CommerceOfferDetail} from "./commerceOffer";
import {IndexField} from "../../decorators/database/indexField";
import {User} from "../user/user";
import {ModelRevision} from "../modelRevision";
import {CompositeClassField} from "../../decorators/database/compositeClassField";

@CollectionClass({name: "commerceTransactionCollections", revisionEnabled: false, draftEnabled: false})
export class CommerceTransactionCollection extends ModelBase<CommerceTransactionCollection> {
    public static TYPE = {};

    @IndexField() @ForeignKeyField(User) payerId: string;
    @IndexField() sessionHash: string;
    @IndexField() hash: string;
    @IndexField() paymentTimestamp: number;

    @IndexField() @ReferenceField(CommerceTransaction) @ForeignKeyField(CommerceTransaction) commerceTransactionIds: string[] = [];
    @TransientField @ForeignObjectField("commerceTransactionIds") commerceTransactions: CommerceTransaction[];

    @IndexField() @ForeignKeyField(CommerceOffer) commerceOfferIds: string[] = [];
    @TransientField @ForeignObjectField("commerceOfferIds") commerceOffers: CommerceOffer[];
    @ForeignKeyField(ModelRevision) commerceOfferRevisionIds: string[] = [];

    @CompositeClassField(CommerceOfferDetail) commerceOfferDetails: CommerceOfferDetail[] = [];

    retryDepth: number;


    constructor(doc?, draftFlag?: boolean) {
        super(doc, draftFlag);
        this.init(doc, this.draftFlag);
    }

    getCommerceOfferDetail(commerceOfferId: string) {
        let result;

        this.commerceOfferDetails.some((commerceOfferDetail, i) => {
            if (commerceOfferDetail.commerceOfferId === commerceOfferId) {
                result = commerceOfferDetail;
                return true;
            }
        });

        return result;
    }

    getSecuredDoc(): CommerceTransactionCollection {
        var obj: CommerceTransactionCollection = super.getSecuredDoc();

        obj.paymentTimestamp = this.paymentTimestamp;
        obj.commerceTransactionIds = this.commerceTransactionIds;
        obj.commerceOfferIds = this.commerceOfferIds;
        obj.commerceOfferDetails = this.commerceOfferDetails;

        return obj;
    }
}
