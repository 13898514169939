import {objectUtils} from "./objectUtils";

export class AddressField {
  data: any;
  administrativeAreaOptionsArray: { code: string, name: string }[];

  constructor(data: any) {
    this.data = data;
  }

  getCountryISO(): string {
    return this.data.iso;
  }

  getCountryLabel(): string {
    return this.data.label;
  }

  getFromField(key: string): any {
    var data = null;
    this.data.fields.some((field) => {
      if (field[key]) {
        data = field[key];
      }
    });

    return data;
  }

  getFromLocality(key: string): any {
    var data = null;
    var locality = this.getFromField("locality");
    if (locality) {
      locality.some((field) => {
        if (field[key]) {
          data = field[key];
        }
      });
    }

    return data;
  }

  getLabel(field) {
    if (field) {
      return field.label;
    }
  }

  getThoroughFareLabel(): string {
    var field = this.getFromField("thoroughfare");
    return this.getLabel(field);
  }

  getPremiseLabel(): string {
    var field = this.getFromField("premise");
    return this.getLabel(field);
  }

  getLocalityLabel(): string {
    var field = this.getFromLocality("localityname");
    return this.getLabel(field);
  }

  hasLocality(): boolean {
    return !!this.getLocalityLabel();
  }

  getPostalCodeLabel(): string {
    var field = this.getFromLocality("postalcode");
    return this.getLabel(field);
  }

  hasPostalCode(): boolean {
    return !!this.getPostalCodeLabel();
  }

  getPostalCodeFormat(): string {
    var field = this.getFromLocality("postalcode");
    if (field) {
      return field.format;
    }
  }

  getPostalCodeSample(): string {
    var field = this.getFromLocality("postalcode");
    if (field) {
      return field.eg;
    }
  }

  getAdministrativeAreaLabel(): string {
    var field = this.getFromLocality("administrativearea");
    return this.getLabel(field);
  }

  getAdministrativeAreaOptions(): any[] {
    var field = this.getFromLocality("administrativearea");
    if (field) {
      return field.options;
    }
  }

  getAdministrativeAreaOptionsObject(): {} {
    var field = this.getFromLocality("administrativearea");

    if (field) {
      return field.options.reduce((acc, curr) => {
        const key = Object.keys(curr)[0];

        acc[key] = curr[key];

        return acc;
      }, {});
    }
  }

  hasAdministrativeAreaOptions(): boolean {
    var options = this.getAdministrativeAreaOptions();
    return options && options.length > 0;
  }

  getAdministrativeAreaOptionsArray(): { code: string, name: string }[] {
    if (!this.administrativeAreaOptionsArray && this.hasAdministrativeAreaOptions()) {
      var options = this.getAdministrativeAreaOptions();
      var optionsArray = [];
      options.forEach((administrativeArea) => {
        Object.keys(administrativeArea).forEach(function (key) {
          if (key) {
            optionsArray.push({code: key, name: administrativeArea[key]});
          }
        });
      });
      this.administrativeAreaOptionsArray = optionsArray;
    }
    return this.administrativeAreaOptionsArray;
  }

  addAdministrativeAreaOption(option) {
    let options = this.getAdministrativeAreaOptions();
    let optionString = JSON.stringify(option);
    let flag = false;
    options.some((o) => {
      let oString = JSON.stringify(o);
      if (optionString === oString) {
        flag = true;
        return true
      }
    });
    if (!flag) {
      let index = Object.keys(options).length;
      options[index] = option;
    }
  }

  sortAdministrativeAreaOptionsArray() {
    let options = this.getAdministrativeAreaOptionsArray();
    options.sort((a: any, b: any) => {
      let str1 = a.name;
      let str2 = b.name;
      return ((str1 === str2) ? 0 : ((str1 > str2) ? 1 : -1));
    });
  }

  getAdministrativeAreaName(administrativeAreaCode) {
    let name;
    if (administrativeAreaCode) {
      administrativeAreaCode = administrativeAreaCode.toUpperCase();
      this.getAdministrativeAreaOptionsArray().some((administrativeArea) => {
        if (administrativeArea.code === administrativeAreaCode) {
          name = administrativeArea.name;
          return true;
        }
      });
    }
    return name;
  }
}

export class AddressUtils {
  // https://github.com/tableau-mkt/addressfield.json
  static addressField = {
    "label": "Country",
    "options": [
      {
        "label": "United States",
        "iso": "US",
        "fields": [
          {
            "thoroughfare": {
              "label": "Address 1"
            }
          },
          {
            "premise": {
              "label": "Address 2"
            }
          },
          {
            "locality": [
              {
                "localityname": {
                  "label": "City"
                }
              },
              {
                "administrativearea": {
                  "label": "State",
                  "options": [
                    {
                      "": "--"
                    },
                    {
                      "AL": "Alabama"
                    },
                    {
                      "AK": "Alaska"
                    },
                    {
                      "AZ": "Arizona"
                    },
                    {
                      "AR": "Arkansas"
                    },
                    {
                      "CA": "California"
                    },
                    {
                      "CO": "Colorado"
                    },
                    {
                      "CT": "Connecticut"
                    },
                    {
                      "DE": "Delaware"
                    },
                    {
                      "DC": "District Of Columbia"
                    },
                    {
                      "FL": "Florida"
                    },
                    {
                      "GA": "Georgia"
                    },
                    {
                      "HI": "Hawaii"
                    },
                    {
                      "ID": "Idaho"
                    },
                    {
                      "IL": "Illinois"
                    },
                    {
                      "IN": "Indiana"
                    },
                    {
                      "IA": "Iowa"
                    },
                    {
                      "KS": "Kansas"
                    },
                    {
                      "KY": "Kentucky"
                    },
                    {
                      "LA": "Louisiana"
                    },
                    {
                      "ME": "Maine"
                    },
                    {
                      "MD": "Maryland"
                    },
                    {
                      "MA": "Massachusetts"
                    },
                    {
                      "MI": "Michigan"
                    },
                    {
                      "MN": "Minnesota"
                    },
                    {
                      "MS": "Mississippi"
                    },
                    {
                      "MO": "Missouri"
                    },
                    {
                      "MT": "Montana"
                    },
                    {
                      "NE": "Nebraska"
                    },
                    {
                      "NV": "Nevada"
                    },
                    {
                      "NH": "New Hampshire"
                    },
                    {
                      "NJ": "New Jersey"
                    },
                    {
                      "NM": "New Mexico"
                    },
                    {
                      "NY": "New York"
                    },
                    {
                      "NC": "North Carolina"
                    },
                    {
                      "ND": "North Dakota"
                    },
                    {
                      "OH": "Ohio"
                    },
                    {
                      "OK": "Oklahoma"
                    },
                    {
                      "OR": "Oregon"
                    },
                    {
                      "PA": "Pennsylvania"
                    },
                    {
                      "RI": "Rhode Island"
                    },
                    {
                      "SC": "South Carolina"
                    },
                    {
                      "SD": "South Dakota"
                    },
                    {
                      "TN": "Tennessee"
                    },
                    {
                      "TX": "Texas"
                    },
                    {
                      "UT": "Utah"
                    },
                    {
                      "VT": "Vermont"
                    },
                    {
                      "VA": "Virginia"
                    },
                    {
                      "WA": "Washington"
                    },
                    {
                      "WV": "West Virginia"
                    },
                    {
                      "WI": "Wisconsin"
                    },
                    {
                      "WY": "Wyoming"
                    },
                  ]
                }
              },
              {
                "postalcode": {
                  "label": "ZIP code",
                  "format": "^\\d{5}(?:[-\\s]\\d{4})?$",
                  "eg": "98103"
                }
              }
            ]
          }
        ]
      },
    ]
  };

  static getCountries(): { code: string, name: string }[] {
    return this.addressField.options.map((country) => {
      return {code: country.iso, name: country.label};
    });
  }

  static getAddressField(iso: string) {
    var info: AddressField = null;
    this.addressField.options.some((country) => {
      if (country.iso == iso) {
        info = new AddressField(objectUtils.clone(country));
        return true;
      }
    });

    return info;
  }
}

