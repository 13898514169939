import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, NgModule } from '@angular/core';

@Component({
  selector: 'app-mini-offense-card',
  templateUrl: './offense-card.component.html',
  styleUrls: ['./offense-card.component.scss'],
})
export class MiniOffenseCardComponent implements OnInit {
  @Input() item;
  @Input() isPdfPrintView;
  showMore = false;

  constructor() {}

  ngOnInit(): void {}
}

@NgModule({
  declarations: [MiniOffenseCardComponent],
  imports: [CommonModule],
  exports: [MiniOffenseCardComponent],
})
export class MiniOffenseCardComponentModule {}
