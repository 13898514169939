import { LoaderPhoneSearchMemberComponent } from './loader.component';
import { ReportReviewPhoneSearchMemberComponent } from './reportReview.component';
import { ProcessPhoneSearchMemberComponent } from './process.component';
import { MiniLinearPhoneSearchComponentModule } from './miniLinear/miniLinear.component';
import { MiniLinearPhoneSearchV2ComponentModule } from './mini-linear-phone-search-v2/mini-linear-phone-search-v2.component';

export const PHONE_SEARCH_MEMBER_DECLARATIONS = [
  LoaderPhoneSearchMemberComponent,
  ReportReviewPhoneSearchMemberComponent,
  ProcessPhoneSearchMemberComponent,
];

export const PHONE_SEARCH_MEMBER_IMPORTS = [
  MiniLinearPhoneSearchComponentModule,
  MiniLinearPhoneSearchV2ComponentModule,
];
