import {
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  EventEmitter,
  Input,
  NgModule,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceHelperService } from '../../../../../../clientCommon/services/serviceHelper.service';
import { PersonDetail } from '../../../personDetail';
import { ToastrService } from '../../../../../service/toastr.service';
import { BaseComponent } from '../../../../../../clientCommon/components/BaseComponent';
import { peopleSearchProductKeys } from '../../../../../../common/custom/peopleSearch/peopleSearchProductKeys';
import { AuthenticationService, NoteService } from '../../../../../../clientCommon/services';
import { CommonModule } from '@angular/common';
import { MiniReportHeaderModule } from '@member/peopleSearch/components/mini/header/header.component';
import { AdditionalResourcesComponentModule } from '../additional-resources/additional-resources.component';
import { MemberUpsellGlobalComponentModule } from '@member/components/upsells/global/upsell-global.component';
import { MiniReportNotesComponentModule } from '@member/peopleSearch/components/mini/notes/notes.component';
import { MiniReportFooterModule } from '@member/peopleSearch/components/mini/footer/footer.component';
import { AdComponentModule } from 'app/components/ad/ad.component';

declare var $: any;

@Component({
  selector: 'app-mini-life-events',
  templateUrl: 'miniLifeEvents.component.html',
  styleUrls: ['miniLifeEvents.component.scss'],
})
export class MiniLifeEventsComponent extends PersonDetail implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  href = '';
  showAllRelatives = false;
  @Input() isPdfPrintView: boolean = false;
  @Output() showLoader = new EventEmitter<void>();

  @ViewChild('scrollToTop', { static: true })
  scrollToTopBtnElement: ElementRef;
  
  additionalResources = {
    summary: `We have curated the very best resources to get even more Life Event
    Records, including marriages, divorces, death records, birth
    certificates. Select below for more details.`,
    arr: [
      {
        imgSrc: 'assets/ux1/search_flow/ic-diagram-ancestry.svg',
        url: 'https://www.ancestry.com/',
      },
      {
        imgSrc: 'assets/ux1/search_flow/ic-diagram-my-heritage.svg',
        url: 'https://www.myheritage.com/',
      },
    ],
  };
  ownedProperty = [];

  // eventFlow = {
  //   birthdate : { year: 'June 17th, 1970', address: "West Palm Beach, FL"},
  //   education:
  //     [
  //       {
  //         year: "1990-1993",
  //         address: 'University of Miami Higher Studies'
  //       },
  //       {
  //         year: "1993-1997",
  //         address: ' M.I.T.'
  //       },
  //     ]
  //   ,
  //   firstHome: { year: 'November 1998', address: "West Palm Beach, FL"},
  //   marriage: [],
  //   divorce: [],
  //   death: {address:"", year: ""},
  //   // death: { year: new Date(), address: "El'Cabro, TX"},
  // }
  constructor(
    public serviceHelperService: ServiceHelperService,
    public changeDetectorRef: ChangeDetectorRef,
    activatedRoute: ActivatedRoute,
    private router: Router,
    public renderer: Renderer2,
    public toastr: ToastrService,
    public noteService: NoteService,
    public authService: AuthenticationService
  ) {
    super(serviceHelperService, activatedRoute, toastr, noteService, authService);
  }

  ngOnInit() {
    this.href = this.router.url;

    this.ownedProperty = this.person.properties.sort((a, b) => {
      return <any>new Date(b.filingDate) - <any>new Date(a.filingDate);
    });

    return this.init().then(() => {
      this.serviceHelperService.trackingService.report({
        page: BaseComponent.PAGE.detail,
        pageType: BaseComponent.PAGE_TYPE.member,
        type: peopleSearchProductKeys.nameSearch,
        section: 'lifeEvents',
        action: 'view',
      });
      this.changeDetectorRef.detectChanges();
    });
  }
  ngAfterViewInit() {
    // Register scroll to top event listener.
    const screenHeight = window.screen.availHeight;
    window.onscroll = () => {
      if (document.body.scrollTop > screenHeight || document.documentElement.scrollTop > screenHeight) {
        this.renderer.setStyle(this.scrollToTopBtnElement.nativeElement, 'display', 'flex');
      } else {
        this.renderer.setStyle(this.scrollToTopBtnElement.nativeElement, 'display', 'none');
      }
    };
  }
  scrollToTopHandler() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
  ngOnDestroy() {
    return super.onDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  init() {
    // Tooltip init
    setTimeout(() => {
      $('.tooltip-container').tooltip({
        html: true,
        trigger: 'click',
      });
    }, 0);
    return super.init();
  }

  switchShowAllRelative() {
    this.showAllRelatives = !this.showAllRelatives;
  }

  handleLoader() {
    this.showLoader.emit();
  }
}

@NgModule({
  declarations: [MiniLifeEventsComponent],
  imports: [
    CommonModule,
    
    MiniReportHeaderModule,
    AdditionalResourcesComponentModule,
    MemberUpsellGlobalComponentModule,
    MiniReportNotesComponentModule,
    MiniReportFooterModule,
    AdComponentModule,
  ],
  exports: [MiniLifeEventsComponent],
})
export class MiniLifeEventsComponentModule {}
