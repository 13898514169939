import { Component, OnInit, Output, EventEmitter, Input, OnChanges, NgModule } from '@angular/core';
import { ServiceHelperService } from '../../../../../../../clientCommon/services/serviceHelper.service';
import { SpyIdHelper } from '../../../../../../../clientCommon/helper/spyIdHelper';
import { CommonModule } from '@angular/common';
import { UiSwitchModule } from 'ngx-ui-switch';

@Component({
  selector: 'app-modal-get-alerts',
  templateUrl: 'getAlerts.component.html',
  styleUrls: ['getAlerts.component.scss'],
})
export class GetAlertsComponent implements OnInit {
  @Input() commerceContent: any;
  @Output() setMonitoring = new EventEmitter<any>();
  dwbMonitotoringEnabled = false;
  monitoredPeopleSearchCommerceContentIds: string[];
  spyIdHelper = new SpyIdHelper();

  constructor(private serviceHelperService: ServiceHelperService) {}

  ngOnInit() {
    this.monitoringEnabled();
  }

  monitoringEnabled() {
    this.serviceHelperService.commerceService.getStorageAsObservable().subscribe((commerceStorage) => {
      const monitoredPeopleSearchCommerceContentIds = commerceStorage?.monitoredPeopleSearchCommerceContentIds || [];
      this.dwbMonitotoringEnabled =
        monitoredPeopleSearchCommerceContentIds.includes(this.commerceContent?._id) ||
        (this.commerceContent?.data?.subscribed ? true : false);
    });
  }

  goNext() {}

  handleSetMonitoring(val) {
    this.setMonitoring.emit(val);
  }
}

@NgModule({
  declarations: [GetAlertsComponent],
  imports: [CommonModule, UiSwitchModule],
  exports: [GetAlertsComponent],
})
export class GetAlertsComponentModule {}
