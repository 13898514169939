import {CollectionClass} from "../decorators/database/collectionClass";
import {ModelBase} from "./modelBase";
import {IndexField} from "../decorators/database/indexField";

@CollectionClass({name: 'contents', revisionEnabled: true, draftEnabled: true})
export class Content extends ModelBase<Content> {
  @IndexField() public name;
  public description;
  public content;
  options: any = {};

  constructor(doc?, draftFlag?: boolean) {
    super(doc, draftFlag);
    this.init(doc, this.draftFlag);
  }

  getSecuredDoc(): Content {
    let obj: Content = super.getSecuredDoc();
    obj.content = this.content;
    obj.options = this.options;

    return obj;
  }

}
