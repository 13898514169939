export class BankIdentificationNumber {
  public static CPD_TYPE = {
    credit: "credit",
    debit: "debit",
    prepaid: "prepaid",
    unknown: "unknown",
  };

  public bin: string;
  public brand: string;
  public type: string;
  public level: string;
  public country: string;
  public bank: string;
  public extra;

  constructor(doc?) {
    if (doc) {
      Object.assign(this as any, doc);
    }
  }

  public getCPD() {
    if (this.level && this.level.match(/GIFT|PREPAID.*/)) {
      return BankIdentificationNumber.CPD_TYPE.prepaid;
    } else if (this.type && this.type === "DEBIT") {
      return BankIdentificationNumber.CPD_TYPE.debit;
    } else if (this.type && this.type === "CREDIT") {
      return BankIdentificationNumber.CPD_TYPE.credit;
    } else {
      return BankIdentificationNumber.CPD_TYPE.unknown;
    }
  }
}
