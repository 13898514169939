import { BaseComponent } from "../../clientCommon/components/BaseComponent";
import { LogUtils } from "../../common/utils/logUtils";
import { ServiceHelperService } from "../../clientCommon/services/serviceHelper.service";
import { CommerceContent } from "../../common/models/commerce/commerceContent";
import { timeUtils } from "../../common/utils/timeUtils";
import { FakeProgressor } from "../../clientCommon/helper/fakeProgressor";
import { ActivatedRoute } from "@angular/router";
import { SearchedPerson } from "../../common/custom/models/peopleSearch/searchedPerson";
import { PeopleSearchContentHelper, peopleSearchContentHelper } from "../../common/custom/peopleSearch/peopleSearchHelper";


export class ReportReviewPeopleSearch extends BaseComponent {

  questionEnabled = false;
  testimonialsEnabled = false;

  commerceContent: CommerceContent;
  startTimestamp;
  selectedPerson: SearchedPerson;
  searchEmail: string;

  fakeProgressors: any;
  checkedRelatives: any = {};
  progressorDetails: any = {};

  loadings: Record<string, any[]> = {};

  constructor(serviceHelperService: ServiceHelperService, activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
    this.page = BaseComponent.PAGE.reportReview;
    this.fakeProgressors = this.serviceHelperService.progressorService.fakeProgressors;
  }

  onInit(): Promise<any> {
    this.startTimestamp = timeUtils.getTimestamp();
    this.fakeProgressors.current.setDuration(1800 * 1000); // Default 30m

    return this.baseInit().then(() => {
      this.checkThinMatchFlow();
      if (this.isSkip()) {
        this.serviceHelperService.spinnerService.spin();
        this.next();
        return Promise.reject(false);
      } else {

        this.questionEnabled = !!this.uxHelper.getUxcomp("questions.enabled");
        this.testimonialsEnabled = !!this.uxHelper.getUxcomp("testimonials.enabled");

        // Progressor
        this.executeProgress().then(() => {
          // do nothing.
        });
      }

      LogUtils.debug('reportReview', this.serviceHelperService.userInputService.getUserInput());
      if (this.serviceHelperService.userInputService.getUserInput() && this.serviceHelperService.userInputService.getUserInput().email) {
        this.searchEmail = this.serviceHelperService.userInputService.getUserInput().email;
      }

      return this.serviceHelperService.peopleSearchService.getCommerceContent(this.uxHelper.uxComposite, this.serviceHelperService.userInputService.getUserInput());
    }).then((commerceContent: CommerceContent) => {
      this.commerceContent = commerceContent;
      this.selectedPerson = this.serviceHelperService.peopleSearchService.findSelectedPerson(commerceContent);
      LogUtils.debug(this.selectedPerson);
    }).then(() => {
      if (this.stillInThisPage() && this.commerceContent.progressors && this.commerceContent.progressors.length) {
        this.serviceHelperService.progressorService.setProgressor(this.commerceContent.progressors[0]);
        if (!this.serviceHelperService.progressorService.goToCurrentProgressPage()) {
          this.serviceHelperService.progressorService.setCurrentProgress(this.startTimestamp);
          this.serviceHelperService.progressorService.runNestedTypes().then(() => {
          });
        }
      }
    }).catch((e) => {
      if (e !== false)
        LogUtils.error(e);
    });

  }

  onDestroy() {
    this.serviceHelperService.progressorService.clearCurrentProgressors();
    return super.onDestroy();
  }

  executeProgress() {
    return this.serviceHelperService.progressorService.executeProgress().then(() => {
      return this.next();
    });
  }

  isSkip() {
    return !!this.uxHelper.getUxcomp("skip");
  }

  submitAnswer(fakeProgressor: FakeProgressor, answer) {
    let detail = this.getProgerssorDetail(fakeProgressor);
    detail.feedback = true;
    let valid = true;
    let inputs = [];

    Object.keys(detail.checkbox).forEach((key) => {
      let value = !!detail.checkbox[key].value;
      if (detail.checkbox[key].required) {
        if (!value) {
          valid = false;
        }
      }
      inputs.push(peopleSearchContentHelper.shapeGeneralAnswer(fakeProgressor.name, "checkbox:" + detail.checkbox[key].index + ":" + value));
    });

    if (valid) {
      let promises = [];
      inputs.push(peopleSearchContentHelper.shapeGeneralAnswer(fakeProgressor.name, answer));
      inputs.forEach((input) => {
        this.commerceContent.inputs.push(input);
        promises.push(this.serviceHelperService.peopleSearchService.submitAnswer(input));
      });
      fakeProgressor.setProgressMax();

      return Promise.all(promises);
    }
  }

  submitRelatives(fakeProgressor: FakeProgressor) {
    let answerArray = Object.keys(this.checkedRelatives);
    let input = peopleSearchContentHelper.shapeGeneralAnswer(PeopleSearchContentHelper.QUESTION_SUB_TYPE.relativeInterested, answerArray.join("|"));
    this.commerceContent.inputs.push(input);
    return this.serviceHelperService.peopleSearchService.submitAnswer(input);
  }

  next() {
    if (this.stillInThisPage()) {
      let options = {replaceUrl: true};
      return this.goNextPage(null, options);
    }
  }

  getCurrentLoadings(): any[] {
    let key = this.fakeProgressors.current.name + ".loadings";
    if (this.loadings[key] === undefined) {
      let loadings = this.uxComposite.getUxcomp(key);
      if (loadings === undefined) {
        loadings = null;
      }
      this.loadings[key] = loadings;
    }
    return this.loadings[key];
  }

  shouldShowAfterProgress(length, index) {
    if (length > 0) {
      let step = this.fakeProgressors.current.max / length;
      return this.fakeProgressors.current.dramaticProgress >= step * index;
    } else {
      return true;
    }
  }

  getProgerssorDetail(fakeProgressor: FakeProgressor) {
    let key = fakeProgressor.name;
    if (this.progressorDetails[key] === undefined) {
      let detail: any = {
        key: key,
        feedback: false,
      };

      // Checkbox
      detail.checkbox = {};
      let checkboxKeys = this.uxComposite.traverseKeys(key + ".checkbox");
      if (checkboxKeys) {
        Object.keys(checkboxKeys).forEach((index) => {
          let currentKey = `${key}.checkbox.${index}`;
          detail.checkbox[index] = {
            index: index,
            key: currentKey,
            required: !!this.uxComposite.get(currentKey + ".required"),
            value: false,
          };
        });
      }

      // Button
      detail.button = {};
      let buttonKeys = this.uxComposite.traverseKeys(key + ".button");
      if (buttonKeys) {
        Object.keys(buttonKeys).forEach((index) => {
          detail.button[index] = {
            index: index,
            key: `${key}.button.${index}`,
          };
        });
      }
      // If no buttons specified, adding default key
      if (Object.keys(detail.button).length === 0) {
        detail.button["_default"] = {
          default: true,
        };
      }

      this.progressorDetails[key] = detail;
    }


    return this.progressorDetails[key];
  }

  // Check if its a thin match case and thin match uxc is enabled then redirect to signup
  checkThinMatchFlow() {
    const thinMatchEnabled = this.serviceHelperService.storageService.getSession("thinMatchEnabled");
    const thinMatchType = this.serviceHelperService.storageService.getSession("thinMatchType");
    if (thinMatchEnabled) {
      if (!this.serviceHelperService.authenticationService?.isLoggedIn()) {
        if (this.theme !== this.themes.ux_5_2) {
          this.next();
        }
      }
    }
  }

  keyValidate(event) {
    return event.charCode >= 48 && event.charCode <= 57;
  }

}
