import { Component, OnInit, Input, NgModule } from "@angular/core";
import { User } from "../../../../../../common/models/user/user";
import { CommerceContent } from '../../../../../../common/models/commerce/commerceContent';
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { clientPaths } from "../../../../../../../../common/helpers/pathHelpers";
import { SearchedPerson } from "@common/custom/models/peopleSearch/searchedPerson";
import { LogUtils } from '@common/utils/logUtils';
@Component({
  selector: "app-mini-share-report",
  templateUrl: "./share-report.component.html",
  styleUrls: ["./share-report.component.scss"],
})
export class MiniReportShareReportComponent implements OnInit {
  @Input() base: any;
  @Input() phone: string;

  user: User;
  objectKeys = Object.keys;
  showAllBreaches = false;
  idProtectionEnabled = false
  shareLinkUrl = '';

  constructor() {}

  ngOnInit(): void {
    this.base.init();
    this.user = this.base.authService.getUser();

    const url = new URL(window.location.href);

    let param = `${this.base.commerceContent._id}`; //fname=${this.base.person?.fName}&lname=${this.base.person?.lName}&city=${this.base.person?.city}&state=${this.base.person?.state}&phone=${this.phone}`;
    param = encodeURIComponent(param);
    this.shareLinkUrl = `${url.protocol}//${url.host}/share/mini-report/${param}`
  }

  toggleShowAllBreaches() {
    this.showAllBreaches = !this.showAllBreaches
  }

  titleCase(str) {
    return str.split('_')
    .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
    .join(' ');
  }

  handleClick() {
    this.refererCopy(this.shareLinkUrl);
  }
  // Share referral link
  refererCopy(data) {
    this.copyToClipboard(data)
    .then(() => {
      alert('Copied to clipboard');
    })
    .catch(err => {
      alert('Failed to copy to clipboard');
      console.error('Clipboard copy error: ', err);
    });
  }

  copyToClipboard(text: string): Promise<void> {
    return navigator.clipboard.writeText(text)
      .then(() => {
        LogUtils.debug('Text copied to clipboard: ', text);
      })
      .catch(err => {
        LogUtils.error('Failed to copy text to clipboard: ', err);
        throw err; // Optional: propagate the error further
      });
  }
}

@NgModule({
  declarations: [MiniReportShareReportComponent],
  imports: [CommonModule, FormsModule],
  exports: [MiniReportShareReportComponent],
})
export class MiniReportShareReportComponentModule {}


