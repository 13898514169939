import { Component, OnDestroy, OnInit, Input, EventEmitter, Output, NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { SearchedPerson } from '@common/custom/models/peopleSearch/searchedPerson';
import { ServiceHelperService } from '@clientCommon/services/serviceHelper.service';
import { MiniButtonComponentModule } from '@member/peopleSearch/components/mini';
import { customClientPaths } from '@common/custom/customPathHelpers';
import { BaseComponent } from '@clientCommon/components/BaseComponent';
import { peopleSearchProductKeys } from '@common/custom/peopleSearch';

declare const $: any;

@Component({
  selector: 'app-member-recommand',
  templateUrl: './recommand.component.html',
  styleUrls: ['./recommand.component.scss'],
})
export class MemberRecommandComponent implements OnInit, OnDestroy {
  @Input() uxHelper: any;
  @Input() persons: any[];
  @Input() isPdfPrintView: boolean = false;
  selectedPhotos = [];
  relativesList = [];
  swiperIndex = 0;
  showAllRelatives = false;
  
  constructor(private serviceHelperService: ServiceHelperService) {}

  ngOnInit() {
    this.showAllRelatives = this.isPdfPrintView;
    this.relativesList = this.persons;
  }

  ngOnDestroy() {}

  switchShowAllRelative() {
    this.showAllRelatives = !this.showAllRelatives;
  }
  
  searchPerson(person: SearchedPerson) {
    this.serviceHelperService.peopleSearchService.setSearchPersonToSearch(
      this.serviceHelperService,
      person
    );
    // open new tab
    window.open(
      "/" + customClientPaths.memberNameSearchLoader);
    return false;
  }  

  cancelModal(index) {
    $('#modal-' + index).modal('hide');
  }

  openPhotoModal(photos) {
    this.selectedPhotos = photos;
    this.swiperIndex = 0;
    if (this.selectedPhotos?.length < 2) {
      return;
    }
    $('#personPhotoModal').modal({
      backdrop: 'static',
    });
  }
  moveStep(step) {
    if (step < 0) {
      if (this.swiperIndex === 0) {
        this.swiperIndex = this.selectedPhotos.length - 1;
      } else {
        this.swiperIndex--;
      }
    } else {
      if (this.swiperIndex === this.selectedPhotos.length - 1) {
        this.swiperIndex = 0;
      } else {
        this.swiperIndex++;
      }
    }
  }
}

@NgModule({
  declarations: [MemberRecommandComponent],
  imports: [CommonModule, MiniButtonComponentModule],
  exports: [MemberRecommandComponent],
})
export class MemberRecommandComponentModule {}
