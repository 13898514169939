import {Component, Input} from "@angular/core";

@Component({
    selector: 'sup-payment-loader',
    templateUrl: 'loader.component.html',
    styleUrls: ['./loader.component.scss'],
    standalone: false
})
export class SignupPaymentLoader {

  constructor() {}

}
