import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, NgModule } from '@angular/core';

@Component({
    selector: 'app-mini-census-accordion',
    templateUrl: './accordion.component.html',
    styleUrls: ['../history-accordion/accordion.component.scss'],
    standalone: false
})
export class MiniCensusAccordionComponent implements OnInit {
  @Input() censusInfo: any;
  @Input() stateCensusInfo: any;
  @Input() isPdfPrintView: any;
  @Input() zipCode: any;

  constructor() { }

  ngOnInit(): void {
  }

  sum(...args) {
    let sum = 0;
    args.forEach(arg => sum += +this.censusInfo[arg]);
    return sum === 0 ? 'n/a' : sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  sumState(...args) {
    let sum = 0;
    args.forEach(arg => sum += +this.stateCensusInfo[arg]);
    return sum === 0 ? 'n/a' : sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  getPercentage(...values) {
    const total = values[values.length - 1];
    let sum = 0;
    values.slice(0, values.length - 1).forEach((k) => sum += +this.censusInfo[k]);
    if (!sum || !total) return 'n/a';
    return `${(+(sum / +this.censusInfo[total]) * 100).toFixed(2)}%`;
  }

  getPercentageState(value, total) {
    return (+(+this.stateCensusInfo[value] / +this.stateCensusInfo[total]) * 100).toFixed(2);
  }

  getAmount(value) {
    if (value) {
      return `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    }
    return 'n/a';
  }
}

@NgModule({
  declarations: [MiniCensusAccordionComponent],
  imports: [CommonModule],
  exports: [MiniCensusAccordionComponent],
})
export class MiniCensusAccordionComponentModule {}
