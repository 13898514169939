import {Component, Input, OnChanges, OnInit, ViewChild, ElementRef, inject, NgModule} from "@angular/core";
import {UxHelper} from "../../../../../../clientCommon/helper/uxHelper";
import {SearchResultPeopleSearch} from "../../../../../peopleSearch/searchResultPeopleSearch";
import { CommonModule } from "@angular/common";
// import { ButtonComponentModule, CosInputModule, FooterComponentModule, HeaderComponentModule, SelectComponentModule } from "@ux5.2/common";
import { UxTemplateModule } from "app/components/uxTemplate.component";
import { FormsModule } from "@angular/forms";

@Component({
  selector: 'ux-5-2-search-result-name-search',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss'],
})
export class Ux5Dot2SearchResultNameSearchComponent implements OnInit, OnChanges {
  @Input() base: SearchResultPeopleSearch;

  @Input() uxHelper: UxHelper;

  @ViewChild('firstName') public firstNameInput: ElementRef<HTMLInputElement>;
  @ViewChild('lastName') public lastNameInput: ElementRef<HTMLInputElement>;
  @ViewChild('middleName') public middleNameInput: ElementRef<HTMLInputElement>;
  @ViewChild('phone') public phoneInput: ElementRef<HTMLInputElement>;

  results = [];
  sortRelevanceResults = [];
  showMore = false;
  isNameAscending = false;
  isAgeAscending = false;

  pageNumber = 0;
  totalCount = 0;
  startIndex = 0;
  ageInput = 0;
  ageRanges = [
    {id: 1, label: '18 - 30 years'},
    {id: 2, label: '31 - 50 years'},
    {id: 3, label: '51 - 70 years'},
    {id: 4, label: '71+ years'},
  ];

  sortOptions = [
    {
      name: 'Relevance',
      code: 'relevance'
    },
    {
      name: 'Name',
      code: 'fName'
    },
    {
      name: 'Age',
      code: 'age'
    },
  ]

  init() {
    if (!this.base.commerceContent?.getMainRaw()?.tempClient?.processed?.person) return;
    this.totalCount = this.base.commerceContent.getMainRaw().tempClient.processed.person.length;
    if (+this.base.modifySearchInput.minAge === 18) {
      this.ageInput = 1;
    } else if (+this.base.modifySearchInput.minAge === 31) {
      this.ageInput = 2;
    } else if (+this.base.modifySearchInput.minAge === 51) {
      this.ageInput = 3;
    } else if (+this.base.modifySearchInput.minAge === 71) {
      this.ageInput = 4;
    }
  }

  ngOnInit() {
    this.results = this.base.commerceContent?.getMainRaw()?.tempClient?.processed?.person;
    if (this.results?.length > 0) this.sortRelevanceResults = [...this.results];
    if (this.results?.length > 10) {
      this.showMore = true;
    }
    return this.init();
  }

  ngOnChanges() {
    return this.init();
  }

  nextPage() {
    if (this.startIndex + this.base.searchResultLimit < this.totalCount) {
      this.startIndex += this.base.searchResultLimit;
      this.pageNumber ++;
    }
  }

  prevPage() {
    if (this.startIndex > 0) {
      this.startIndex -= this.base.searchResultLimit;
      this.pageNumber --;
    }
  }

  formatString(value, key) {
    const replaced = this.base.formatString(value, key);
    if (key === 'fname') {
      this.firstNameInput.nativeElement.value = replaced;
    } else if (key === 'lname') {
      this.lastNameInput.nativeElement.value = replaced;
    } else if (key === 'mname') {
      this.middleNameInput.nativeElement.value = replaced;
    }
  }

  formatPhone(value) {
    const replaced = this.base.formatPhone(value);
    this.phoneInput.nativeElement.value = replaced;
  }

  changeAgeRange(event) {
    const input = +event.target.value;
    if (+input === 1) {
      this.base.modifySearchInput.minAge = 18;
      this.base.modifySearchInput.maxAge = 30;
    } else if (+input === 2) {
      this.base.modifySearchInput.minAge = 31;
      this.base.modifySearchInput.maxAge = 50;
    } else if (+input === 3) {
      this.base.modifySearchInput.minAge = 51;
      this.base.modifySearchInput.maxAge = 70;
    } else if (+input === 4) {
      this.base.modifySearchInput.minAge = 71;
    }
  }

  changeState(event) {
    this.base.modifySearchInput.state = event.target.value;
  }

  sortByValue(sortBy: string) {
    let ascending: boolean;
    if (sortBy == 'relevance') {
      return this.results = [...this.sortRelevanceResults];
    }
    if (sortBy == 'fName') {
      ascending = this.isNameAscending;
      this.isNameAscending = !this.isNameAscending;
    } else {
      ascending = this.isAgeAscending;
      this.isAgeAscending = !this.isAgeAscending;
    }
    const clonedChildren = [...this.results];
    clonedChildren.sort((a, b): any => {
      if (ascending) {
        return b[sortBy] < a[sortBy] ? -1 : 1;
      } else {
        return b[sortBy] > a[sortBy] ? -1 : 1;
      }
    });
    this.results = [...clonedChildren];
  }
}
