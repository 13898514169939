import {Injectable} from "@angular/core";
import {UserInfo} from "../../common/models/user/userInfo";

@Injectable()
export class UserInfoService {
  STORAGE_NAME = 'userInfo';

  public userInfo: UserInfo;

  constructor() {
  }
  /**
   * getting the user info from local storage
   * if user info already exists returning the uxinfo in the original component
   */
  getUserInfo(): UserInfo {

    if (!this.userInfo) {
      this.userInfo = new UserInfo(JSON.parse(localStorage.getItem(this.STORAGE_NAME)));
    }

    return this.userInfo;
  }
  /**
   * storing the userinfo in the localstorage
   */
  storeUserInfo() {
    localStorage.setItem(this.STORAGE_NAME, JSON.stringify(this.userInfo));
  }

}
