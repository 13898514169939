import { Component, OnDestroy, OnInit, Input, EventEmitter, Output, NgModule } from '@angular/core';
import { ServiceHelperService } from '../../../../clientCommon/services/serviceHelper.service';
import { ActivatedRoute } from '@angular/router';
import { LogUtils } from '../../../../common/utils/logUtils';
import { UxHelper } from '../../../../clientCommon/helper/uxHelper';
import { User } from '../../../../common/models/user/user';
import { UserInfo } from '../../../../common/models/user/userInfo';
import { BaseDirective } from '../../../../clientCommon/directives/BaseDirective';
import { serverPaths } from '../../../../common/helpers/pathHelpers';
import { ResponseEvent } from '../../../../common/event/responseEvent';
import { CommerceOrder } from '../../../../common/models/commerce/commerceOrder';
import { CommerceOffer } from '../../../../common/models/commerce/commerceOffer';
import { ModelBase } from '../../../../common/models/modelBase';
import { CommerceToken } from '../../../../common/models/commerce/commerceToken';
import { CreditCardInputHelper } from '../../../../clientCommon/helper/creditCardInputHelper';
import { CommerceOfferRuleKeyDetail } from '../../../../common/models/commerce/commerceOrder';
import { objectUtils } from '../../../../common/utils/objectUtils';
import { commerceUtils } from '../../../../common/utils/commerceUtils';
import { timeUtils } from '../../../../common/utils/timeUtils';
import { SearchedPerson } from '../../../../common/custom/models/peopleSearch/searchedPerson';
import { CommonModule } from '@angular/common';
import { UxTemplateModule } from 'app/components/uxTemplate.component';
import { MemberUpsellAssociatesFeaturesComponentModule } from './features/associates-features.component';
import { MemberUpsellCourtFeaturesComponentModule } from './features/court-features.component';
import { MemberUpsellHouseholdFeaturesComponentModule } from './features/household-features.component';
import { MemberUpsellNeighborFeaturesComponentModule } from './features/neighbor-features.component';
import { MemberUpsellPropertiesFeaturesComponentModule } from './features/properties-features.component';
import { FormsModule } from '@angular/forms';
import { PoliciesModalComponentModule } from 'app/components/policiesModal.component';

declare const $: any;

@Component({
  selector: 'member-upsell-payment-modal-partial',
  templateUrl: './paymentModalPartial.component.html',
  styleUrls: ['./paymentModalPartial.component.scss'],
})
export class MemberUpsellPaymentModalPartial extends BaseDirective implements OnInit, OnDestroy {
  user: User;
  loaded = false;
  useExisting = false;
  commerceToken: CommerceToken;
  preName = '';
  preCard = '';
  expDate = '';
  processing = false;
  creditCardInputHelper = new CreditCardInputHelper();
  submitted = false;
  hash = '';
  step = 0;
  paymentResult = false;
  failed = 0;

  @Input() isPdfPrintView: boolean = false;
  @Input() partialKey = '';
  @Input() person: SearchedPerson;
  @Input() commerceContentId: string;
  @Output() onSuccess = new EventEmitter<void>();
  commerceOfferRulesKey = '';
  commerceOfferIdsKey = '';
  commerceOffers: CommerceOffer[] = [];

  brandName = '';
  commerceOfferIds: string[] = [];

  timeUtils = timeUtils;

  structuredCommerceOffers: {
    [type: string]: CommerceOffer[];
  } = {};
  newCommerceOrder: CommerceOrder;
  currentCommerceOrder: CommerceOrder;

  @Input() uxHelper: UxHelper;
  @Input() phoneReport: boolean = false;
  modalId = '';

  constructor(serviceHelperService: ServiceHelperService, activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
  }

  ngOnInit() {
    return this.baseInit()
      .then(() => {
        return this.init();
      })
      .then(() => {
        this.modalId = this.partialKey + 'UpsellPartialModal';
        if (this.partialKey === 'phone') {
          this.commerceOfferIdsKey = `comp.billing.enhanced.phone.global.offerIds`;
          this.commerceOfferRulesKey = `comp.billing.enhanced.phone.offersRules`;
        } else {
          this.commerceOfferIdsKey = `comp.billing.enhanced.name.${this.partialKey}.offerIds`;
          this.commerceOfferRulesKey = `comp.billing.enhanced.name.offersRules`;
        }
        const uxcomp = this.uxComposite.getUxcomp(this.commerceOfferIdsKey);
        if (!Array.isArray(uxcomp)) {
          try {
            this.commerceOfferIds = JSON.parse(uxcomp);
          } catch {
            this.commerceOfferIds = [];
          }
        } else {
          this.commerceOfferIds = uxcomp;
        }
        if (this.commerceOfferIds?.length) {
          this.serviceHelperService.commerceService
            .findOffers(this.commerceOfferIds)
            .then((commerceOffers) => {
              this.commerceOffers = commerceOffers;
              if (commerceOffers.length) {
                this.uxComposite.setCode('pricing', this.commerceOffers[0].commercePrice.prices[0].amount.toFixed(2));
                this.uxComposite.setCode('fName', this.person.fName);
              }
              this.initModal();
            })
            .catch((err) => {
              LogUtils.error('Finding offer error', err);
            });
        }
      });
  }

  init() {
    this.brandName = this.uxComposite.getUxcomp('comp.brand.name');
    this.creditCardInputHelper.setDummyAddress(this.uxComposite);

    return Promise.resolve().then(() => {
      this.user = this.serviceHelperService.authenticationService.getUser();
      this.uxComposite.setCode('fname', this.user.firstName);
      return this.serviceHelperService.commerceService
        .request(serverPaths.commerceCustomerFindCustomer, {
          userId: this.user._id,
        })
        .then((responseEvent: ResponseEvent) => {
          let docs = responseEvent.getDocs();
          docs.forEach((obj: ModelBase<any>) => {
            if (obj instanceof User) {
              this.user = obj;
            } else if (obj instanceof CommerceToken) {
              this.commerceToken = obj;
            } else if (obj instanceof CommerceOrder) {
              this.currentCommerceOrder = obj;
            }
          });
          if (this.commerceToken) {
            this.preName = `${this.commerceToken?.billingAddress.firstName} ${this.commerceToken?.billingAddress.lastName}`;
            this.expDate = `${this.commerceToken?.expiration.month}/${this.commerceToken?.expiration.year}`;
            let ccNumber = '';
            const bin = this.commerceToken.bin;
            const last4 = this.commerceToken.last4;
            const length = this.commerceToken.length - last4.length - bin.length;
            if (length) {
              ccNumber = bin;
              ccNumber += new Array(length).fill('*').join('');
              ccNumber += last4;
            }
            this.preCard = ccNumber;
          } else {
            this.preName = 'Pre-Populated Full Name on Card';
            this.expDate = 'Exp. 02/2023';
            this.preCard = 'Credit Card Number';
          }
        })
        .catch((err) => {
          LogUtils.error(err);
        });
    });
  }

  ngOnDestroy() {
    this.uxHelper.uxComposite.setCode('pricing', '');
    this.uxHelper.uxComposite.setCode('fName', '');
    return super.onDestroy();
  }

  isValidPaymentInfo() {
    return (
      this.creditCardInputHelper.isValidFullName() &&
      this.creditCardInputHelper.isValidCCNumber() &&
      this.creditCardInputHelper.isValidCCExp() &&
      this.creditCardInputHelper.isValidZip()
    );
  }

  getNewCommerceOrder(responseEvent: ResponseEvent) {
    let docs = responseEvent.getDocs();
    docs.forEach((obj: ModelBase<any>) => {
      if (obj instanceof CommerceOrder) {
        this.newCommerceOrder = obj;
        this.newCommerceOrder.tempClient.dueDate = this.timeUtils.formatDate(new Date());
      }
    });
  }

  upsell() {
    Promise.resolve(true)
      .then(() => {
        if (!this.commerceOffers?.length) {
          return Promise.reject('No valid commerce offers');
        }
        this.hash = this.serviceHelperService.commerceService.createHash();
        const commerceOffer = this.commerceOffers[0];
        if (this.useExisting) {
          this.processing = true;
          let offerRuleKeyDetails: CommerceOfferRuleKeyDetail[] = [];

          let offerRules = this.uxComposite.getUxcomp(this.commerceOfferRulesKey);

          if (offerRules && objectUtils.isObject(offerRules)) {
            Object.keys(offerRules).forEach((key) => {
              offerRuleKeyDetails.push({ key: key, commerceOfferIds: [commerceOffer._id] });
            });
          }

          if (commerceOffer) {
            let options: any = {};
            if (this.commerceContentId) {
              options.commerceContentId = this.commerceContentId;
            }
            if (this.partialKey) {
              options.upsellKey = 'global';
            }
            return this.serviceHelperService.commerceService
              .nonOptionSaleByUser(
                this.hash,
                this.user,
                this.uxComposite,
                this.commerceOfferRulesKey,
                offerRuleKeyDetails,
                options
              )
              .catch((e) => {
                this.step = 0;
                this.uxComposite.setCode('last4', this.commerceToken.last4);
                this.initCard();
                this.paymentResult = false;
                this.useExisting = false;
                this.processing = false;
                this.failed++;
                LogUtils.error(e);
                return Promise.reject(e);
              })
              .then((responseEvent: ResponseEvent) => {
                this.getNewCommerceOrder(responseEvent);
                this.step++;
                this.paymentResult = true;
                this.processing = false;
                return;
              });
          }
          return Promise.reject('No CommerceOffer found.');
        } else {
          this.submitted = true;
          if (!this.isValidPaymentInfo()) {
            return Promise.reject('Invalid Payment Info');
          }
          this.processing = true;
          this.creditCardInputHelper.billingAddress.email = this.user.email;
          const [fname, lname] = this.creditCardInputHelper.fullName.split(' ');
          this.creditCardInputHelper.billingAddress.firstName = fname;
          this.creditCardInputHelper.billingAddress.lastName = lname;

          let offerRules = this.uxComposite.getUxcomp(this.commerceOfferRulesKey);
          let offerRuleKeyDetails: CommerceOfferRuleKeyDetail[] = [];
          let offerRuleKey = this.commerceOfferRulesKey;

          Object.keys(offerRules).forEach((key) => {
            offerRuleKeyDetails.push({ key: key });
          });
          let userInfo = new UserInfo();
          userInfo.id = this.user._id;
          let requestParam: any = {
            hash: this.hash,
            userInfo: userInfo,
            commerceOfferIds: [],
            commerceOfferDetails: [],
            refer: this.serviceHelperService.referService.getRefer(),
            uxcId: this.uxComposite.uxConfigId,
            uxlId: this.uxComposite.uxLayoutId,
            ccNumber: this.creditCardInputHelper.ccNumber,
            ccExpMonth: this.creditCardInputHelper.ccExpMonth,
            ccExpYear: this.creditCardInputHelper.ccExpYear,
            ccCvv: this.creditCardInputHelper.ccCvv,
            billingAddress: this.creditCardInputHelper.billingAddress,
            disablePreviousTokens: true,
            declineIfSameActiveToken: true,
            offerRuleKey: offerRuleKey,
            offerRuleKeyDetails: offerRuleKeyDetails,
          };
          if (this.commerceContentId) {
            requestParam.commerceContentId = this.commerceContentId;
          }
          if (this.partialKey) {
            if (this.partialKey === 'phone') {
              requestParam.upsellKey = this.partialKey;
            } else {
              requestParam.upsellKey = 'global';
            }
          }
          return this.serviceHelperService.commerceService
            .processNonOptionSale(requestParam)
            .then((responseEvent) => {
              this.getNewCommerceOrder(responseEvent);
              this.step = this.step + 1;
              this.paymentResult = true;
              this.processing = false;
              return;
            })
            .catch((e) => {
              this.uxComposite.setCode('last4', this.creditCardInputHelper.ccNumber.slice(12));
              this.initCard();
              this.step = 0;
              this.useExisting = false;
              this.processing = false;
              this.failed++;
              if (commerceUtils.isNotAcceptableRequestError(e)) {
                LogUtils.debug(e);
              } else {
                LogUtils.error(e);
              }
              return Promise.reject(e);
            });
        }
      })
      .then(() => {
        this.serviceHelperService.trackingService.report({
          action: 'submit',
          type: 'upsell',
          status: 'success',
        });
        return;
      })
      .catch((err) => {
        LogUtils.error(err);
      })
      .then(() => {
        this.serviceHelperService.trackingService.report({
          action: 'submit',
          type: 'upsell',
        });
      });
  }

  changePaymentMethod() {
    this.useExisting = false;
    this.serviceHelperService.trackingService.report({
      type: 'upsell',
      action: 'new_card',
    });
  }

  initCard() {
    this.step = 0;
    this.useExisting = false;
    this.submitted = false;
    this.creditCardInputHelper.init();
    this.creditCardInputHelper.setDummyAddress(this.uxComposite);
    this.creditCardInputHelper.clearCC();
  }

  initModal() {
    this.step = 0;
    this.useExisting = true;
    this.processing = false;
    this.failed = 0;
    this.creditCardInputHelper.clearCC();
    this.submitted = false;
  }

  close() {
    this.onSuccess.emit();
    $(`#${this.modalId}`).modal('hide');
  }

  getCurrentDate() {
    return this.timeUtils.formatDate(new Date());
  }
}

@NgModule({
  declarations: [MemberUpsellPaymentModalPartial],
  imports: [
    CommonModule,
    FormsModule,

    UxTemplateModule,

    MemberUpsellAssociatesFeaturesComponentModule,
    MemberUpsellCourtFeaturesComponentModule,
    MemberUpsellHouseholdFeaturesComponentModule,
    MemberUpsellNeighborFeaturesComponentModule,
    MemberUpsellPropertiesFeaturesComponentModule,

    PoliciesModalComponentModule,
  ],
  exports: [MemberUpsellPaymentModalPartial],
})
export class MemberUpsellPaymentModalPartialModule {}
