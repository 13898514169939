import {Component, Input, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";

import {ServiceHelperService} from "../../../clientCommon/services/serviceHelper.service";
import {BaseDirective} from "../../../clientCommon/directives/BaseDirective";
import {UxHelper} from "../../../clientCommon/helper/uxHelper";
import { CustomBaseComponent } from "../../../clientCommon/custom/customBaseComponent";

@Component({
  selector: 'app-sales-footer',
  templateUrl: 'salesFooter.component.html',
  styleUrls: ['salesFooter.component.css'],
})
/**
 *  @fileOverview SalesFooterComponent will first check the uxComposite and render the respective theme content based on current uxComp theme & it's brandtype
 *
 *  @extends BaseDirective to get the required custom properties methods and base directives
 *
 *  @property uxHelper is an input received from the parent view component, it will be used to get the theme and render html content according to-
 *  it and render different html content blocks via uxTemplate child component based on UxComp key
 *
 */
export class SalesFooterComponent extends BaseDirective implements OnInit {
  @Input() uxHelper: UxHelper;
  isReversePhone = false;

  /**
 *
 * @param serviceHelperService will provide access to different required services such as authentication service, uxc service etc.
 * @parma activatedRoute provides access to information about a route associated with a component that is loaded in an outlet
 *
 */
  constructor(serviceHelperService: ServiceHelperService, activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
  }

  ngOnInit() {
    this.isReversePhone = this.uxHelper?.pageCategory === CustomBaseComponent.PAGE_CATOGORY.phoneSearch;
    return super.baseInit();
  }
}
