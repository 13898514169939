import {Ux2Dot0LandingNameSearchComponent} from "./landing.component";
import {Ux2Dot0LoaderNameSearchComponent} from "./loader.component";
import {Ux2Dot0SearchResultNameSearchComponent} from "./searchResult.component";
import {Ux2Dot0SignupNameSearchComponent} from "./signup.component";
import {Ux2Dot0ReportReviewNameSearchComponent} from "./reportReview.component";

export const NAME_SEARCH_PEOPLE_SEARCH_UX_2_0_THEME_DECLARATIONS = [
  Ux2Dot0LandingNameSearchComponent,
  Ux2Dot0LoaderNameSearchComponent,
  Ux2Dot0SearchResultNameSearchComponent,
  Ux2Dot0ReportReviewNameSearchComponent,
  Ux2Dot0SignupNameSearchComponent,
];
