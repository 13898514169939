import {
    ChangeDetectorRef,
    Component,
    Output,
    SimpleChanges,
    EventEmitter,
    Input,
    HostListener,
    ViewChild,
    ElementRef,
    Renderer2,
  } from '@angular/core';
  import { ActivatedRoute, Router } from '@angular/router';
  import { ServiceHelperService } from '../../../../../../clientCommon/services/serviceHelper.service';
  import { PersonDetail } from '../../../personDetail';
  import { ToastrService } from '../../../../../service/toastr.service';
  import { BaseComponent } from '../../../../../../clientCommon/components/BaseComponent';
  import { peopleSearchProductKeys } from '../../../../../../common/custom/peopleSearch/peopleSearchProductKeys';
  import { SearchedPerson } from '../../../../../../common/custom/models/peopleSearch/searchedPerson';
  import { AuthenticationService, NoteService } from '../../../../../../clientCommon/services';
  import { User } from '../../../../../../common/models/user/user';
  import { SpyIdHelper } from '../../../../../../clientCommon/helper/spyIdHelper';
  
  declare var $: any;
  
  @Component({
    template: '',
    standalone: false
})
  export class MiniLinearBaseComponent extends PersonDetail {
    href: string = '';
    showAllEmail: boolean = false;
    thisIsMe = null;
    user: User;
    alreadyClaimed = false;
    isEmailCheck = false;
    @Input() isPdfPrintView: boolean = false;
    @Output() showLoader = new EventEmitter<void>();
    processedPersons: SearchedPerson[] = [];
    commerceStorage;
    idProtectionEnabled = false;
    
    spyIdHelper = new SpyIdHelper();
    @ViewChild('scrollToTop', { static: true })
    scrollToTopBtnElement: ElementRef;
  
    
    constructor(
      public serviceHelperService: ServiceHelperService,
      public changeDetectorRef: ChangeDetectorRef,
      activatedRoute: ActivatedRoute,
      public renderer: Renderer2,
      protected router: Router,
      public toastr: ToastrService,
      public noteService: NoteService,
      public authService: AuthenticationService
    ) {
      super(serviceHelperService, activatedRoute, toastr, noteService, authService);
    }
  
    ngOnInit() {
      this.showAllEmail = this.isPdfPrintView;
      this.href = this.router.url;
      window.scrollTo(0, 0);
      return this.linearInit().then(() => {
        this.serviceHelperService.trackingService.report({
          page: BaseComponent.PAGE.detail,
          pageType: BaseComponent.PAGE_TYPE.member,
          type: peopleSearchProductKeys.nameSearch,
          section: 'linear',
          action: 'view',
        });
        this.user = this.serviceHelperService.authenticationService.getUser();
  
        this.serviceHelperService.commerceService.getStorageAsObservable().subscribe((commerceStorage) => {
  
          if (this.spyIdHelper.isIdProtectionEnabled(commerceStorage)) {
            const idProtectionFrontEnabled = this.uxHelper.getValue("comp.member.idProtection.enable") === 'true'? true: false;
            const idProtectionBackendEnabled = this.uxHelper.getValue("comp.member.idProtection.registration.enable") === 'true'? true: false;
            if (idProtectionBackendEnabled && idProtectionFrontEnabled) {
              this.idProtectionEnabled = true;
            }
          }
          this.commerceStorage = commerceStorage;
          this.thisIsMe = commerceStorage?.reportClaimedContentId == this.commerceContent?._id ? true : false;
          this.alreadyClaimed = !this.thisIsMe && commerceStorage?.reportClaimedContentId;
        });
  
        this.isEmailCheck = this.getEmails().length > 0 ? true: false;
        this.changeDetectorRef.detectChanges();
      });
    }
  
    ngOnDestroy() {
      return super.onDestroy();
    }
  
    ngOnChanges(changes: SimpleChanges): void {}
  
    ngAfterViewInit() {
      // Register scroll to top event listener.
      const screenHeight = window.screen.availHeight;
      window.onscroll = () => {
        if (document.body.scrollTop > screenHeight || document.documentElement.scrollTop > screenHeight) {
          this.renderer.setStyle(this.scrollToTopBtnElement.nativeElement, 'display', 'flex');
        } else {
          this.renderer.setStyle(this.scrollToTopBtnElement.nativeElement, 'display', 'none');
        }
      };
    }
    scrollToTopHandler() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
    linearInit() {
      // Tooltip init
      setTimeout(() => {
        $('.tooltip-container').tooltip({
          html: true,
          trigger: 'click',
        });
      }, 0);
      return super.init();
    }
  
    @HostListener('mousewheel', ['$event']) onMousewheel(event) {
      //this.router.navigate([]);
    }
  
    getEmails() {
      return this.person.emailAddresses.map((item) => item.address);
    }
  
    getFirstEmail() {
      return this.person.emailAddresses?.[0]?.address;
    }
  
    getNotes() {}
  
    switchShowAllEmail() {
      this.showAllEmail = !this.showAllEmail;
    }
  
    handleLoader() {
      this.showLoader.emit();
    }
  
    onMyReportChange() {
      this.serviceHelperService.spinnerService.spin();        
      this.idClaim();
      if (this.getEmails().length > 0) {
        //DWB Monitoring 
        if (this.commerceStorage?.idProtectionFeatures?.idDarkWebMonitoring?.enabled && this.idProtectionEnabled) {
          this.monitorDwb(!this.thisIsMe);
        }
      }
    }
  
    idClaim() {
      this.serviceHelperService.commerceService
        .updateMeReportFlag(
          this.commerceStorage?.idProtectionContentId,
          this.commerceContent._id,
          this.commerceContent.data?.spyDwbCommerceContentId,
          !this.thisIsMe,
          this.getEmails(),
          this.person?.fName,
          this.person?.lName
        )
        .then(() => {
          this.serviceHelperService.spinnerService.unspin();
          // tracking enrollment/un-enrollment
          if (!this.thisIsMe) {
            this.serviceHelperService.trackingService.report({
              page: BaseComponent.PAGE.detail,
              pageType: BaseComponent.PAGE_TYPE.member,
              type: 'IDClaimOnReport',
              action: 'checked',
              monitoredEmails: this.getEmails(),
            });
          } else {
            this.serviceHelperService.trackingService.report({
              page: BaseComponent.PAGE.detail,
              pageType: BaseComponent.PAGE_TYPE.member,
              type: 'IDClaimOnReport',
              action: 'unchecked',
            });
          }
        })
        .catch((e) => {
          this.serviceHelperService.spinnerService.unspin()
        });
    }
  
    monitorDwb(flag) {
      if (!this.commerceContent.data?.spyDwbCommerceContentId || this.getEmails().length < 1) {
        return;
      }
      const commerceStorage = this.serviceHelperService.commerceService.getStorage();
      this.serviceHelperService.spinnerService.spin();
      this.serviceHelperService.commerceService
        .monitorDwb(
          this.commerceContent.data?.spyDwbCommerceContentId,
          this.commerceContent._id,
          commerceStorage?.idProtectionContentId,
          flag,
          this.getEmails(),
          this.person?.fName,
          this.person?.lName
        )
        .then(() => {
          this.serviceHelperService.spinnerService.unspin();
          //tracking enrollment/un-enrollment
          if (flag) {
            this.serviceHelperService.trackingService.report({
              page: BaseComponent.PAGE.detail,
              pageType: BaseComponent.PAGE_TYPE.member,
              type: 'DWBEmailMonitoring',
              action: 'enrollment',
              monitoredEmails: this.getEmails(),
            });
          } else {
            this.serviceHelperService.trackingService.report({
              page: BaseComponent.PAGE.detail,
              pageType: BaseComponent.PAGE_TYPE.member,
              type: 'DWBEmailMonitoring',
              action: 'unenrollment',
            });
          }
        })
        .catch(() => this.serviceHelperService.spinnerService.unspin());
    }
  
    scrollTo(id: string) {
      const elem = document.getElementById(id);
      if (!elem) {
        return;
      }
      window.scrollTo(0, 0); // elem.offsetTop - 10);
    }   
  }


  