import {PropertyDecoratorHelper} from "../helpers/propertyDecoratorHelper";

export var manualFieldHelper = new PropertyDecoratorHelper();

/**
 * Field will not be updated automatically on save
 */
export function ManualField(target, key: string): void {
    manualFieldHelper.addProperties(target, key);
}
