// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  background-color: rgba(0, 18, 80, 0.65);
  font-size: 14px;
  line-height: 18px;
}
.header img {
  height: 60px;
}
.header__content {
  display: flex;
  align-items: center;
  height: 60px;
}
.header__content--nav {
  margin-left: auto;
}
.header__content--action {
  border: none;
  background: transparent;
  outline: 0;
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  border: 1px solid white;
  border-radius: 10px;
  height: 34px;
  padding: 7px 23px;
  transition: all 0.2s;
}
.header__content--action:hover {
  background-color: white;
  color: rgba(0, 18, 80, 0.65);
}`, "",{"version":3,"sources":["webpack://./src/app/themes/ux.4.0/components/salesHeader.component.scss"],"names":[],"mappings":"AAoBA;EACE,uCAAA;EACA,eAAA;EACA,iBAAA;AAnBF;AAqBE;EACE,YAAA;AAnBJ;AAsBE;EACE,aAAA;EACA,mBAAA;EACA,YAAA;AApBJ;AAsBI;EACE,iBAAA;AApBN;AAuBI;EArCF,YAAA;EACA,uBAAA;EACA,UAAA;EACA,YAmCiD;EAlCjD,gBAAA;EACA,yBAAA;EACA,qBAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,iBAAA;EACA,oBAAA;AAiBF;AAfE;EACE,uBAwB+C;EAvB/C,4BAuBwB;AAN5B","sourcesContent":["@mixin ux4-btn-link($background-color, $color) {\n  border: none;\n  background: transparent;\n  outline: 0;\n  color: $color;\n  font-weight: 700;\n  text-transform: uppercase;\n  text-decoration: none;\n  border: 1px solid white;\n  border-radius: 10px;\n  height: 34px;\n  padding: 7px 23px;\n  transition: all 0.2s;\n\n  &:hover {\n    background-color: $color;\n    color: $background-color;\n  }\n}\n\n.header {\n  background-color: rgba(0, 18, 80, 0.65);\n  font-size: 14px;\n  line-height: 18px;\n\n  img {\n    height: 60px;\n  }\n\n  &__content {\n    display: flex;\n    align-items: center;\n    height: 60px;\n\n    &--nav {\n      margin-left: auto;\n    }\n\n    &--action {\n      @include ux4-btn-link(rgba(0, 18, 80, 0.65), white);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
