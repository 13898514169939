import { LandingNameSearchSalesComponent } from './landing/landing.component';
import { LoaderNameSearchSalesComponent } from './loader/loader.component';
import { ReportReviewNameSearchSalesComponent } from './reportReview/reportReview.component';
import { SearchResultNameSearchSalesComponent } from './searchResult/searchResult.component';
import { SignupNameSearchSalesComponent } from './signup/signup.component';

export const NAME_SEARCH_SALES_DECLARATIONS = [
  LandingNameSearchSalesComponent,
  LoaderNameSearchSalesComponent,
  ReportReviewNameSearchSalesComponent,
  SearchResultNameSearchSalesComponent,
  SignupNameSearchSalesComponent,
];
