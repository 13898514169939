import { Component, Input, NgModule } from '@angular/core';
import { UxHelper } from '@clientCommon/helper/uxHelper';
import { ServiceHelperService } from '@clientCommon/services/serviceHelper.service';
import { ReportReviewPeopleSearch } from '@peopeSearch/reportReviewPeopleSearch';
import { ReportReviewResults, Step, UxComp } from '@ux5.2/types';
import { STEPS } from './config';

type UxCompKeys =
  | 'title'
  | 'popularUsesTitle'
  | 'popularUsesContent'
  | 'mediaScanTitle'
  | 'mediaScanDescription'
  | 'mediaScanImageUrl'
  | 'recordsReviewTitle'
  | 'recordsReviewSubTitle'
  | 'recordsReviewImageUrls'
  | 'recordsReviewContent'
  | 'contentInformationTitle'
  | 'monitoringTitle'
  | 'monitoringImageUrls'
  | 'disclaimer'
  | 'warning'
  | 'piiLocation';

type ReportReviewCompState = {
  [key in UxCompKeys]?: UxComp;
};

@Component({
  selector: 'ux-5-2-report-review-name-search',
  templateUrl: './report-review.component.html',
  styleUrls: ['./report-review.component.scss'],
})
export class Ux5Dot2ReportReviewNameSearchComponent {
  @Input() base: ReportReviewPeopleSearch;
  @Input() uxHelper: UxHelper;

  state: ReportReviewCompState = {};

  userName: string;
  inputUserName: { firstName: string; lastName: string };
  time: number = 26;
  progress: number = 0;
  stepCount: number = 0;
  thinMatchEnabled = this.serviceHelperService.storageService.getSession('thinMatchEnabled');
  completionResultsData: ReportReviewResults[] = [];

  steps = STEPS;

  currentStep: Step = this.steps[0];

  constructor(private serviceHelperService: ServiceHelperService) {}

  ngOnInit(): void {
    this.groupOfUxComps();
    this.getUxcompCompletionResult();
    this.loadComponent();
    this.inputUserName = this.serviceHelperService?.storageService?.get('inputTracking').search;
    this.userName =
      (this.base?.selectedPerson?.fName || this.inputUserName.firstName) +
      ' ' +
      (this.base?.selectedPerson?.lName || this.inputUserName.lastName);
  }

  getUxcompValue(compName: string): UxComp {
    const compNameIndex = Object.keys(this.uxHelper?.uxComposite?.ids).indexOf(compName);
    const id = Object.values(this.uxHelper?.uxComposite?.ids)[compNameIndex] as string;
    return {
      id,
      key: compName,
      value: this.uxHelper?.uxComposite?.getUxcomp(compName),
    };
  }

  groupOfUxComps(): void {
    const prefix = 'comp.sales.name-search.report-review';
    this.state = {
      title: this.getUxcompValue(`${prefix}.headline`),
      disclaimer: this.getUxcompValue(`${prefix}.short.privacy.disclaimer.text`),
      popularUsesTitle: this.getUxcompValue(`${prefix}.popular-uses.title`),
      popularUsesContent: this.getUxcompValue(`${prefix}.popular-uses.content`),
      mediaScanTitle: this.getUxcompValue(`${prefix}.media-scan.title`),
      mediaScanDescription: this.getUxcompValue(`${prefix}.media-scan.description`),
      mediaScanImageUrl: this.getUxcompValue(`${prefix}.media-scan.imageUrl`),
      recordsReviewTitle: this.getUxcompValue(`${prefix}.records-review.title`),
      recordsReviewSubTitle: this.getUxcompValue(`${prefix}.records-review.subtitle`),
      recordsReviewImageUrls: this.getUxcompValue(`${prefix}.records-review.imageUrls`),
      recordsReviewContent: this.getUxcompValue(`${prefix}.records-review.content`),
      contentInformationTitle: this.getUxcompValue(`${prefix}.contact-information.title`),
      monitoringTitle: this.getUxcompValue(`${prefix}.monitoring.title`),
      monitoringImageUrls: this.getUxcompValue(`${prefix}.monitoring.imageUrls`),
      warning: this.getUxcompValue(`comp.sales.name-search.loader.h2.text`),
      piiLocation: this.getUxcompValue('comp.sales.name-search.signup.pii.location'),
    };
  }

  getUxcompCompletionResult() {
    this.completionResultsData = this.uxHelper?.uxComposite?.getUxcomp(
      'comp.sales.name-search.report-review.completion-result.data'
    );
    let completionResultStep = 0;
    this.steps = this.steps.map((step) => {
      if (step.component == 'completing-results') {
        step.data = this.completionResultsData[completionResultStep];
        completionResultStep++;
      }
      return step;
    });
  }

  loadComponent() {
    if (!this.thinMatchEnabled) {
      for (let i = 0; i < this.steps.length; i++) {
        if (this.steps[i].title === 'Searching Millions of Records for ') {
          this.steps[i].title = 'Saving Results for ';
        }
      }
    } else {
      this.goNext();
      return;
    }

    if (this.currentStep.component == 'contact-information') {
      this.currentStep = this.steps[4];
    }

    this.stepCount++;

    const interval = setInterval(() => {
      if (this.progress >= 100) {
        this.goNext(interval);
      }

      this.stepCount++;

      this.progress = (100 / this.time) * this.stepCount;

      this.currentStep = this.steps.find((value) => {
        return this.progress <= value.time;
      });

      if (this.currentStep?.component == 'contact-information') {
        if (this.state.piiLocation?.value === 'sup') {
          this.currentStep = this.steps[4];
        } else {
          this.base.fakeProgressors.current.setPause(true);

          clearInterval(interval);
        }
      }
    }, (this.time * 1000) / this.steps.length);
  }

  getPersonName() {
    return this.uxHelper.uxComposite.values?.code?.selectedPerson?.fullName;
  }

  goNext(interval?: any) {
    this.base.fakeProgressors.current.setPause(false);
    
    if (interval) {
      clearInterval(interval);
    }
    
    this.base.next();
  }
}

