import { Component, NgModule, OnInit } from '@angular/core';
import { BaseComponent } from '@clientCommon/components/BaseComponent';
import { ServiceHelperService } from '@clientCommon/services/serviceHelper.service';
import { ActivatedRoute } from '@angular/router';
import { ICONS } from '../../constants';

type Benefit = {
  title: string;
  description: string;
  icon: string;
};

@Component({
  selector: 'wi-careers',
  templateUrl: './careers.page.html',
  styleUrls: ['./careers.page.scss'],
})
export class CareersPage extends BaseComponent implements OnInit {
  openingsUrl = 'https://indeed.com/cmp/We-Inform-LLC';
  prefix = 'comp.weinform.general.careers';

  benefits: Benefit[] = [];
  ourValues: string[];

  constructor(serviceHelperService: ServiceHelperService, activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
    this.pageType = 'weinform';
    this.pageCategory = 'general';
    this.page = 'careers';
  }

  ngOnInit(): void {
    window.scrollBy(0, 0);
    this.baseInit().then(() => {
      this.ourValues = JSON.parse(this.uxHelper.getRaw(`${this.prefix}.our-values.list`) || '[]');
      this.benefits = (<Benefit[]>JSON.parse(this.uxHelper.getRaw(`${this.prefix}.benefits.list`) || '[]')).map(
        (value, index) => ({ ...value, icon: ICONS[index] })
      );
    });
  }
}
