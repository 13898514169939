import {DefaultLandingPhoneSearchComponent} from "./landing.component";
import {DefaultLoaderPhoneSearchComponent} from "./loader.component";
import {DefaultSearchResultPhoneSearchComponent} from "./searchResult.component";
import {DefaultReportReviewPhoneSearchComponent} from "./reportReview.component";
import {DefaultSignupPhoneSearchComponent} from "./signup.component";

export const DEFAULT_PHONE_SEARCH_TEMPLATES_DECLARATIONS = [
  DefaultLandingPhoneSearchComponent,
  DefaultLoaderPhoneSearchComponent,
  DefaultSearchResultPhoneSearchComponent,
  DefaultReportReviewPhoneSearchComponent,
  DefaultSignupPhoneSearchComponent,
];
