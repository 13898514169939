export enum Format {
  Bullet = 'Bullet',
  NoBullet = 'NoBullet',
  NoWrap = 'NoWrap',
}

export enum PaperFormat {
  Flat = 'Flat',
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
}