import { Component, HostListener, NgZone, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Location } from "@angular/common";
import { filter } from 'rxjs/operators';

import { LogUtils } from "../common/utils/logUtils";
import { UxcService } from "../clientCommon/services/uxc.service";
import { UxComposite } from "../common/models/ux/uxComposite";
import { SpinnerService } from "../clientCommon/services/spinner.service";
import { ToastrService } from "./service/toastr.service";
import { clientPaths } from "../common/helpers/pathHelpers";
import { ReferService } from "../clientCommon/services/refer.service";
import { ParameterService } from "../clientCommon/services/parameter.service";
import { TrackingService } from "../clientCommon/services/tracking.service";
import { ServiceHelperService } from "../clientCommon/services/serviceHelper.service";
import { behaviorHelper } from "../clientCommon/helper/behaviorHelper";
import { urlUtils } from "../clientCommon/utils/urlUtils";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit, OnDestroy {
  site;
  clientPaths = clientPaths;
  domain;
  uxComposite: UxComposite;

  constructor(public serviceHelperService: ServiceHelperService,
    public spinnerService: SpinnerService,
    private referService: ReferService,
    private parameterService: ParameterService,
    private trackingService: TrackingService,
    private ngZone: NgZone,
    private router: Router,
    private location: Location,
    private uxcService: UxcService,
    public toastrService: ToastrService,
    private renderer: Renderer2) {
      this.site = urlUtils.get2ndLevelDomain(window.location.hostname);
      this.referService.processRefer();
      this.parameterService.processParameter();
      this.loadUxc();
      behaviorHelper.startCheckingHistory(this.serviceHelperService);    
  }

  ngOnInit() {
    this.bindPubFunctions();
    this.serviceHelperService.spinnerService.unspin();
    let param = {
      type: "postBoot",
      refer: this.referService.getRefer(),
    };
    this.trackingService.report(param);
  }


  ngOnDestroy() {}

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event) {
    this.trackingService.report({ type: "appUnload" });
  }

  loadUxc() {
    let params = new URLSearchParams(window.location.search);
    let uxc = params.get('uxc');

    this.uxcService.getLoadOrFindUxComposite({ type: "", uxcId: uxc }).then((uxComposite: UxComposite) => {
      uxComposite.setCode("refer", this.referService.getRefer());
      uxComposite.setCode("referString", this.referService.getReferString());
    }).catch((e) => {
      LogUtils.error(e);
    });
  }

  bindPubFunctions() {
    let w: any = window;
    w.pubFunc = w.pubFunc || {};
    w.pubFunc.app = w.pubFunc.app || {};
    w.pubFunc.app.click = this.pubClick.bind(this);
  }

  pubClick($event, options?) {
    this.ngZone.run(() => {
      behaviorHelper.click(this.serviceHelperService, $event, options).catch((e) => {
        LogUtils.error(e);
      });
      return false;
    });
    return false;
  }

}
