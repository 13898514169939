import {Component, Input, OnChanges, OnInit, ViewChild,
  ElementRef} from "@angular/core";
import {UxHelper} from "../../../../../clientCommon/helper/uxHelper";
import {LandingPeopleSearch} from "../../../../peopleSearch/landingPeopleSearch";

type utmParamValueType = {
  header1: string;
  header2: string;
}

@Component({
  selector: 'ux-3-1-landing-name-search',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class Ux3Dot1LandingNameSearchComponent { // implements OnInit, OnChanges {
  @Input() base: LandingPeopleSearch;

  @Input() uxHelper: UxHelper;
  @Input() pageType: string;
  @Input() pageCategory: string;
  @Input() page: string;
  @Input() utmParamValue: utmParamValueType;

  @ViewChild('firstName') public firstNameInput: ElementRef<HTMLInputElement>;
  @ViewChild('lastName') public lastNameInput: ElementRef<HTMLInputElement>;

  searchOption = 'searchAdvanced';
  preFullName = '';
  errorFlag = 0;

  constructor() {
  }

  init() {
  }

  ngOnInit() {
    return this.init();
  }

  ngOnChanges() {
    return this.init();
  }

  setSearchOption(value) {
    this.searchOption = value;
    window.scrollTo(0, 0);
  }

  submit() {
    this.errorFlag = 0;
    if (this.searchOption === 'searchAdvanced') {
      if (!this.base.userInput.fname) this.errorFlag = 5;
      if (this.base.userInput.fname.length === 0) this.errorFlag = 1;
      else if (!(/^[A-Z a-z]+$/.test(this.base.userInput.fname) && /^[A-Z a-z]+$/.test(this.base.userInput.lname))) this.errorFlag = 2;
      else if (this.base.userInput.fname.length < 3 && this.base.userInput.lname.length < 3) this.errorFlag = 3;
      else if (!this.base.userInput.lname) this.errorFlag = 4;

      //if (this.errorFlag === 0)
        this.base.submit();
    } else {
      const names = this.preFullName.trim().split(' ');
      if (this.preFullName.trim().length === 0) {
        this.errorFlag = 1;
      } else if (names.length > 1 && names.length <= 3) {
        if (names.length === 2) {
          this.base.userInput.fname = names[0];
          this.base.userInput.lname = names[1];
          this.base.userInput.mname = '';
        } else {
          this.base.userInput.fname = names[0];
          this.base.userInput.mname = names[1];
          this.base.userInput.lname = names[2];
        }
        if (!(/^[A-Z a-z]+$/.test(this.base.userInput.fname) && /^[A-Z a-z]+$/.test(this.base.userInput.lname))) this.errorFlag = 2;
        else if (this.base.userInput.fname.length < 3 && this.base.userInput.lname.length < 3) this.errorFlag = 3;

        //if (this.errorFlag === 0)
          this.base.submit();
      } else if (names.length === 0) this.errorFlag = 1;
      else if (names.length === 1) this.errorFlag = 4;

    }
  }

  formatString(value, key) {
    const replaced = this.base.formatString(value, key);
    if (key === 'fname') {
      this.firstNameInput.nativeElement.value = replaced;
    } else if (key === 'lname') {
      this.lastNameInput.nativeElement.value = replaced;
    }
  }
}
