import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UxHelper } from '../../../../clientCommon/helper/uxHelper';
import { SignUpPeopleSearch } from '../../../sales/peopleSearch/signUpPeopleSearchSales';
import { stringUtils } from '../../../../../../common/utils/stringUtils';
declare var $: any;

@Component({
  selector: 'app-signup-pii',
  templateUrl: './signup-pii.component.html',
  styleUrls: ['./signup-pii.component.scss']
})
export class SignupPIIComponent implements OnInit {

  REPORTING_STEPS = {
    'step1_in': '1_selection_in',
    'step1_out': '1_selection_out',
    'step2_in_email': '2_email_in',
    'step2_in_phone': '2_phone_in',
    'step2_out_email': '2_email_out',
    'step2_out_phone': '2_phone_out',
    'step3_in': '3_password_in',
    'step3_out': '3_password_out',
    'step4_in_email': '4_phone_in',
    'step4_out_email': '4_phone_out',
    'step4_in_phone': '4_email_in',
    'step4_out_phone': '4_email_out',
    'step5_in': '5_name_in',
    'step5_out': '5_name_out',
    'step6_in': '6_billing_in',
    'step6_out': '6_billing_out',
  }

  MODES = {
    'option1': 1,
    'option2': 2,
    'option3': 3,
  }

  STEPS = {
    'step1': 1,
    'step2': 2,
    'step3': 3,
    'step4': 4,
    'step5': 5,
    'step6': 6,
  }

  loadData = [
    {
      loaded: false,
      title: 'Current/Past Addresses'
    },
    {
      loaded: false,
      title: 'Court Records'
    },
    {
      loaded: false,
      title: 'Sex Offender Status'
    },    
    {
      loaded: false,
      title: 'Mugshots'
    },
    {
      loaded: false,
      title: 'Current/Past Addresses'
    },
    {
      loaded: false,
      title: 'Phone Numbers'
    },
    {
      loaded: false,
      title: 'Email Addresses'
    },
    {
      loaded: false,
      title: 'Social Media Profiles'
    },
    {
      loaded: false,
      title: 'Online Photos'
    },
    {
      loaded: false,
      title: 'Marriage/Divorce Records'
    },
    {
      loaded: false,
      title: 'Job History'
    },
    {
      loaded: false,
      title: 'Rentals/Owned Properties'
    },
  ];
  loadProgresses = [];


  mode = this.MODES.option3; // default option
  passwordPattern = "(?=.*?[a-z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?).{8,}$";
  stepOne: any = { isSubmitted: false };
  selectedStep = 1;
  selection = '';
  progresses = [0, 12, 25, 50, 75, 95];
  password = "";
  confirmPassword = "";
  passwordValidation = {
    message: "",
    cfrmMessage: "",
    isPasswordValid: false,
    isValid: false,
    isSubmitted: false,
    isMatching: true
  };
  isBothPasswordMatch = false;
  isPhoneRequired = false;
  loading = false;
  loadIndex = 0;
  loopIndex = 0;
  visiblePassword: boolean = false;
  stringUtils = stringUtils;
  officePhone: string;

  @Input() base: SignUpPeopleSearch;
  @Input() uxHelper: UxHelper;
  @Input() pageType: string;
  @Input() pageCategory: string;
  @Input() page: string;
  @ViewChild('emailForm', { static: true }) emailForm: NgForm;


  constructor() {
    let interval  = setInterval(() => {     
      this.setLoaded();     
      setTimeout(() => {
        this.loadProgressesSet();
      }, 1500); 
      if (this.loadIndex == 12) {
        clearTimeout(interval);
      }
    }, 1000);
  }

  init() {
    this.mode = this.uxHelper.getUxcomp('pii.mode'); // => pii.mode
    this.isPhoneRequired = this.uxHelper.getUxcomp('phone.required');
    setTimeout(() => {
      this.addStepTracking(null, true);
    }, 1000);
    this.officePhone = this.uxHelper.uxComposite.get("comp.brand.customer.phone");
  }

  setLoaded() {    
    try {
      this.loadProgresses.push(this.loadData[this.loadIndex]);   
      this.loadIndex++; 
      // if (!this.loading) {
      //   this.loading = true;
      // }
    }catch (e) {}
  }

  loadProgressesSet() {
    try {
      this.loadProgresses[this.loopIndex].loaded = true;     
      this.loopIndex++; 
      // if (!this.loading) {
      //   this.loading = true;
      // }
    }catch (e) {}
  }

  ngOnInit() {
    this.base.getPillStepAsObservable().subscribe((step) => {
      if (step != -1) {
        this.selection = 'email';
        this.selectedStep = this.STEPS.step2;

        this.base.userInfo.email = '';
        this.base.validateEmailCustom('');
        this.base.setPillStep(-1);
      }
    });

    return this.init();

  }

  ngOnChanges() {
    return this.init();
  }

  onNextStep(step) {
    if (this.selectedStep == 1 && this.selection == '') { return; }
    if (this.selectedStep == 3) {
      this.base.userInfo.password = this.password;
    }
    step++;
    this.selectedStep++;
  }

  checkPasswordValidation() {
    if (this.password == '') {
      this.passwordValidation.isValid = false;
      this.passwordValidation.message = 'Please enter a password';
      return;
    }

    const regex = new RegExp(/^(?=.*?[a-z])(?=.*?[0-9])(?=.*?).{8,}$/);
    let isOk = regex.test(this.password);

    if (!isOk) {
      this.passwordValidation.isValid = false;
      this.passwordValidation.isPasswordValid = false;
      this.passwordValidation.cfrmMessage = 'Please enter a valid password';
      return;
    }

    this.passwordValidation.isPasswordValid = true;
    if (isOk && this.confirmPassword == '') {
      this.passwordValidation.isValid = false;
      this.passwordValidation.isMatching = false;
      this.passwordValidation.message = 'Please re enter a password';
      return;
    }

    if (isOk && this.confirmPassword != this.password) {
      this.passwordValidation.isValid = false;
      this.passwordValidation.message = 'Please enter the same password twice';
      this.passwordValidation.isMatching = false;
      return;
    }



    this.passwordValidation.isValid = true;
    this.passwordValidation.isMatching = true;
    this.passwordValidation.message = "";
    return;
  }

  onPrevStep() { this.selectedStep--; }

  addStepTracking($event, trackOnlyIn = false) {
    const outStep = this.selectedStep;
    const inStep = trackOnlyIn ? this.selectedStep : this.selectedStep + 1;

    const params = {
      location: window?.location?.pathname,
      type: 'pii',
    }

    if (inStep < 7) {
      this.base.trackPIISignupSteps({ 
        ...params, 
        step: inStep == 2 || inStep == 4
          ? this.REPORTING_STEPS[`step${inStep}_in_${this.selection}`]
          : this.REPORTING_STEPS[`step${inStep}_in`]
      })
    }

    if (!trackOnlyIn) {
      this.base.trackPIISignupSteps({ 
        ...params, 
        step: outStep == 2 || outStep == 4
          ? this.REPORTING_STEPS[`step${outStep}_out_${this.selection}`]
          : this.REPORTING_STEPS[`step${outStep}_out`]
      });  
    }
  }
  // filterInput(event) {
  //   const inputElement = event.target as HTMLInputElement;
  //   inputElement.value = inputElement.value.replace(/[^a-zA-Z0-9_\-\s]/g, '');
  //   event.target.value = inputElement.value;
  // }

}
