import {Component, Input, OnChanges, OnInit} from "@angular/core";
import {UxHelper} from "../../../clientCommon/helper/uxHelper";
import {BaseDirective} from "../../../clientCommon/directives/BaseDirective";
import {ServiceHelperService} from "../../../clientCommon/services/serviceHelper.service";
import {ActivatedRoute} from "@angular/router";

declare var $: any;

@Component({
  selector: 'popup1',
  templateUrl: './popup1.component.html',
  styleUrls: ['./popup1.component.css'],
})
export class Popup1Component extends BaseDirective implements OnInit, OnChanges {
  @Input() uxHelper: UxHelper;
  @Input() id;
  @Input() show;
  @Input() backdrop;
  @Input() keyboard;
  @Input() uxcompPrefix;

  constructor(serviceHelperService: ServiceHelperService,
              activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
  }

  init() {
    if (this.show) {
      setTimeout(() => {
        $("#" + this.id).modal();
      }, 0);
    }
  }


  ngOnInit() {
    return this.init();
  }

  ngOnChanges() {
    return this.init();
  }

  close() {
    $("#" + this.id).modal("hide");
  }

  submit($event) {
    this.click($event);
    this.close();


  }
}
