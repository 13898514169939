import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { UxHelper } from "../../../../../clientCommon/helper/uxHelper";
import { LoaderPeopleSearch } from "../../../../peopleSearch/loaderPeopleSearch";
import { ServiceHelperService } from "../../../../../clientCommon/services/serviceHelper.service";
import { parameterdUtils } from "../../../../../clientCommon/utils/parameterUtils";
import { Router } from "@angular/router";
import { LogUtils } from "../../../../../common/utils/logUtils";

@Component({
  selector: 'legacy-loader-phone-search',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LegacyLoaderPhoneSearchComponent implements OnInit, OnChanges {

  @Input() base: LoaderPeopleSearch;

  @Input() uxHelper: UxHelper;
  @Input() pageType: string;
  @Input() pageCategory: string;
  @Input() page: string;

  constructor(
    private serviceHelperService: ServiceHelperService,
    private router: Router
  ) {
  }

  init() {}

  ngOnInit() {
    //Adding params to enable skip in loader

    let params = parameterdUtils.getAllParams();


    if (params.skip === "true") {
      this.base.next();
    }

    return this.init();
  }

  //Getting person count for data/Nodata Case
  get getPersonCount() {
    return this.base.commerceContent?.getMainRaw().tempClient.processed.person[0]
  }

  ngOnChanges() {
    return this.init();
  }

  // Rev Phone No match flow check for split test
  isNoMatchEnabled():boolean {
    return this.base.uxComposite.getUxcomp('comp.reverse-phone.no-match.enabled');
  }

  //Routing to landing if nodata button is clicked
  routetolp() {
    if(this.getPersonCount) {
      return true
    }
    if(this.isNoMatchEnabled()) {
      this.router.navigate([this.base.customClientPaths.salesPhoneSearchSignup])
    } else {
      this.router.navigate([this.base.customClientPaths.salesPhoneSearchLanding])
    }
  }

  confirmFcra() {
    this.base.next();
  }

  submitEmail(f) {
    if (!f.form.valid) {
      return;
    }
    this.base.fakeProgressors.current.duration = 0
    let email = this.base.userInfo.email;
    this.serviceHelperService.emailService.checkEmailAddress(email).then((email)=> {
      let correctedEmail = email.data;
      return correctedEmail
    }).then((correctedEmail)=>{
      let extraInfo: any = {};
      extraInfo.optin = true;
      extraInfo.optinUrl = location.href;

      const param = {
        step: 'reverse-phone-loader-email',
        type: "submit",
        optin: extraInfo.optin, optinUrl: location.href,
      };

      this.serviceHelperService.trackingService.report(param)
      .catch((e) => {
        LogUtils.error(e);
      });
      this.serviceHelperService.emailService.addEmail(this.base.uxComposite.brandId, correctedEmail, "guest", {}, extraInfo)
    }).catch((e) => {
      LogUtils.error("email add failed");
    })
  }
}
