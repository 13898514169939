import { LANDING_COMPONENTS_UX_5_2_THEME_DECLARATIONS } from './landing';
import { LOADER_COMPONENTS_UX_5_2_THEME_DECLARATIONS } from './loader';
import { REPORT_REVIEW_COMPONENTS_UX_5_2_THEME_DECLARATIONS } from './report-review';
import { SUP_COMPONENTS_UX_5_2_THEME_DECLARATIONS } from './sup';

export const COMPONENTS_UX_5_2_THEME_DECLARATIONS = [
    ...LANDING_COMPONENTS_UX_5_2_THEME_DECLARATIONS,
    ...LOADER_COMPONENTS_UX_5_2_THEME_DECLARATIONS,
    ...REPORT_REVIEW_COMPONENTS_UX_5_2_THEME_DECLARATIONS,
    ...SUP_COMPONENTS_UX_5_2_THEME_DECLARATIONS,    
];