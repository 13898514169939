import {Ux3Dot0LandingNameSearchComponent} from "./landing.component";
import {Ux3Dot0LoaderNameSearchComponent} from "./loader.component";
import {Ux3Dot0SearchResultNameSearchComponent} from "./searchResult.component";
import {Ux3Dot0SignupNameSearchComponent} from "./signup.component";
import {Ux3Dot0ReportReviewNameSearchComponent} from "./reportReview.component";

export const NAME_SEARCH_PEOPLE_SEARCH_UX_3_0_THEME_DECLARATIONS = [
  Ux3Dot0LandingNameSearchComponent,
  Ux3Dot0LoaderNameSearchComponent,
  Ux3Dot0SearchResultNameSearchComponent,
  Ux3Dot0ReportReviewNameSearchComponent,
  Ux3Dot0SignupNameSearchComponent,
];
