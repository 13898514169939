class RadixUtils {

  // Adds two arrays for the given base (10 or 16), returning the result.
  // This turns out to be the only "primitive" operation we need.
  add(x, y, base) {
    var z = [];
    var n = Math.max(x.length, y.length);
    var carry = 0;
    var i = 0;
    while (i < n || carry) {
      var xi = i < x.length ? x[i] : 0;
      var yi = i < y.length ? y[i] : 0;
      var zi = carry + xi + yi;
      z.push(zi % base);
      carry = Math.floor(zi / base);
      i++;
    }
    return z;
  }

  // Returns a*x, where x is an array of decimal digits and a is an ordinary
  // JavaScript number. base is the number base of the array x.
  multiplyByNumber(num, x, base) {
    if (num < 0) return null;
    if (num == 0) return [];

    var result = [];
    var power = x;
    while (true) {
      if (num & 1) {
        result = this.add(result, power, base);
      }
      num = num >> 1;
      if (num === 0) break;
      power = this.add(power, power, base);
    }

    return result;
  }

  parseToDigitsArray(str, base) {
    var digits = str.split('');
    var ary = [];
    for (var i = digits.length - 1; i >= 0; i--) {
      var n = parseInt(digits[i], base);
      if (isNaN(n)) return null;
      ary.push(n);
    }
    return ary;
  }

  convertBase(str, fromBase, toBase) {
    var digits = this.parseToDigitsArray(str, fromBase);
    if (digits === null) return null;

    var outArray = [];
    var power = [1];
    for (var i = 0; i < digits.length; i++) {
      // invariant: at this point, fromBase^i = power
      if (digits[i]) {
        outArray = this.add(outArray, this.multiplyByNumber(digits[i], power, toBase), toBase);
      }
      power = this.multiplyByNumber(fromBase, power, toBase);
    }

    var out = '';
    for (var i = outArray.length - 1; i >= 0; i--) {
      if(toBase>36) {
        out += this.toBase(outArray[i],toBase);
      } else {
        out += outArray[i].toString(toBase);
      }
    }
    return out;
  }

  toBase(decimal, base) {
    var symbols =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
    var conversion = "";

    if (base > symbols.length || base <= 1) {
      return false;
    }

    while (decimal >= 1) {
      conversion = symbols[(decimal - (base * Math.floor(decimal / base)))] +
        conversion;
      decimal = Math.floor(decimal / base);
    }

    return (base < 11) ? parseInt(conversion) : conversion;
  }

  decToHex(decStr) {
    var hex = this.convertBase(decStr, 10, 16);
    return hex ? '0x' + hex : null;
  }

  hexToDec(hexStr) {
    if (hexStr.substring(0, 2) === '0x') hexStr = hexStr.substring(2);
    hexStr = hexStr.toLowerCase();
    return this.convertBase(hexStr, 16, 10);
  }

  hexTo36(hexStr) {
    if (hexStr.substring(0, 2) === '0x') hexStr = hexStr.substring(2);
    hexStr = hexStr.toLowerCase();
    return this.convertBase(hexStr, 16, 36);
  }

  hexTo62(hexStr) {
    if (hexStr.substring(0, 2) === '0x') hexStr = hexStr.substring(2);
    hexStr = hexStr.toLowerCase();
    return this.convertBase(hexStr, 16, 62);
  }


  pad(source, width, pad) {
    pad = pad || '0';
    source = source + '';
    return source.length >= width ? source : new Array(width - source.length + 1).join(pad) + source;
  }
}

export var radixUtils = new RadixUtils();
