import {LogUtils} from "../../utils/logUtils";
import {objectUtils} from "../../utils/objectUtils";
import {PropertyDecoratorHelper} from "../helpers/propertyDecoratorHelper";
import {collectionClassHelper} from "./collectionClass";

var indexFieldHelper = new PropertyDecoratorHelper();

/**
 * Ensure adding index. This decorator must be used with CollectionClass decorator
 */
export function IndexField(value?: {
  unique?: boolean,
  collation?: any,
  compound?: any,
  wildcard?: boolean,
}) {
  return function(target: any, key: string) {
    if (value?.compound && value?.wildcard) {
      LogUtils.warn('Compound and wildcard cannot be set together in the index. Overwritten by wildcard index.');
    }

    indexFieldHelper.addProperties(target, key, value);
  };
}

export function ensureIndexByIndexField(db) {

  let collectionClasses = collectionClassHelper.getAllClasses();
  let indexFields = indexFieldHelper.getAllProperties();
  let promises = [];
  Object.keys(collectionClasses).forEach((collectionClass) => {
    Object.keys(indexFields).forEach((indexField) => {
      let instance = Object.create(collectionClasses[collectionClass].target);
      let instanceofFlag = (
        (instance) instanceof (indexFieldHelper.getClassById(indexField).constructor)
      );
      if (instanceofFlag) {
        indexFields[indexField].forEach((index) => {
          const collectionName = collectionClassHelper.getCollectionName(collectionClasses[collectionClass].target);
          let key = index.key;
          let value = index.value;

          if (index?.value?.compound) {
            key = index.value.compound;
            value = objectUtils.clone(index.value);
            delete value.compound;
          }

          if (index?.value?.wildcard) {
            key = `${index.key}.$**`;
            value = objectUtils.clone(index.value);
            delete value.wildcard;
          }

          LogUtils.info("createIndex start", collectionName, key, value);
          promises.push(db.createIndex(collectionName, key, value).then((result) => {
            LogUtils.info("createIndex done", collectionName, key, value, result);
          }).catch((e) => {
            LogUtils.error("createIndex error", collectionName, key, value, e);
            return Promise.reject(e);
          }));
        });
      }
    });
  });

  return Promise.all(promises).then((values) => {
    LogUtils.info("Ensuring index done");
  }).catch((errors) => {
    LogUtils.error("Adding index error", errors);
    return Promise.reject(errors);
  });
}
