import {DefaultLandingNameSearchComponent} from "./landing.component";
import {DefaultLoaderNameSearchComponent} from "./loader.component";
import {DefaultSearchResultNameSearchComponent} from "./searchResult.component";
import {DefaultReportReviewNameSearchComponent} from "./reportReview.component";
import {DefaultSignupNameSearchComponent} from "./signup.component";

export const DEFAULT_NAME_SEARCH_TEMPLATES_DECLARATIONS = [
  DefaultLandingNameSearchComponent,
  DefaultLoaderNameSearchComponent,
  DefaultSearchResultNameSearchComponent,
  DefaultReportReviewNameSearchComponent,
  DefaultSignupNameSearchComponent,
];
