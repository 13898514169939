// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  padding: 20px;
  height: auto;
  width: auto;
  display: block;

  > div.headless-container {
    margin: 0;
    padding: 0;
  
    > h1 {
      font-size: 30px;
      font-weight: 900;
      line-height: 35.16px;
      margin-top: 0;
  
      @media (max-width: 760px) {
        font-size: 24px;
        font-weight: 900;
        line-height: 24px;
      }
    }
  
    > div.progress {
      margin-bottom: 0;
    }
  }
}`, "",{"version":3,"sources":["webpack://./src/app/peopleSearch/templates/nameSearch/headless/loader/loader.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,WAAW;EACX,cAAc;;EAEd;IACE,SAAS;IACT,UAAU;;IAEV;MACE,eAAe;MACf,gBAAgB;MAChB,oBAAoB;MACpB,aAAa;;MAEb;QACE,eAAe;QACf,gBAAgB;QAChB,iBAAiB;MACnB;IACF;;IAEA;MACE,gBAAgB;IAClB;EACF;AACF","sourcesContent":[":host {\n  padding: 20px;\n  height: auto;\n  width: auto;\n  display: block;\n\n  > div.headless-container {\n    margin: 0;\n    padding: 0;\n  \n    > h1 {\n      font-size: 30px;\n      font-weight: 900;\n      line-height: 35.16px;\n      margin-top: 0;\n  \n      @media (max-width: 760px) {\n        font-size: 24px;\n        font-weight: 900;\n        line-height: 24px;\n      }\n    }\n  \n    > div.progress {\n      margin-bottom: 0;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
