import { Component, Input, OnInit, Output, EventEmitter, NgModule } from "@angular/core";
import { ActivatedRoute, RouterModule } from "@angular/router";

import { CustomBaseComponent } from "../../../../../clientCommon/custom/customBaseComponent";
import { PoliciesModalComponentModule } from "../../../../components/policiesModal.component";
import { BaseDirective } from "../../../../../clientCommon/directives/BaseDirective";
import { UxHelper } from "../../../../../clientCommon/helper/uxHelper";
import { ServiceHelperService } from "../../../../../clientCommon/services/serviceHelper.service";

@Component({
  selector: "ux-5-2-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent extends BaseDirective implements OnInit {
  @Input() uxHelper: UxHelper;
  @Output() loginButtonCallback = new EventEmitter();
  
  brandName: string;
  isReversePhone = false;
  /**
   *
   * @param serviceHelperService will provide access to different required services such as authentication service, uxc service etc.
   * @parma activatedRoute provides access to information about a route associated with a component that is loaded in an outlet
   *
   */
  constructor(
    serviceHelperService: ServiceHelperService,
    activatedRoute: ActivatedRoute
  ) {
    super(serviceHelperService, activatedRoute);
  }

  ngOnInit() {
    this.isReversePhone =
      this.uxHelper?.pageCategory === CustomBaseComponent.PAGE_CATOGORY.phoneSearch;
    this.brandName = this.uxHelper.uxComposite?.brandName;
    return super.baseInit();
  }

  test() {
    window.scrollTo(0, 0);
    this.loginButtonCallback.emit();
  }
}

// @NgModule({
//   declarations: [FooterComponent],
//   imports: [RouterModule, PoliciesModalComponentModule],
//   exports: [FooterComponent],
// })
// export class FooterComponentModule {}
