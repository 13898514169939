import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, NgModule } from '@angular/core';

@Component({
  selector: 'app-mini-offense-summary',
  templateUrl: './offense.component.html',
  styleUrls: ['./offense.component.scss'],
})
export class MiniOffenseSummaryComponent implements OnInit {
  @Input() criminalOffenseList: any;

  constructor() {}

  ngOnInit(): void {}
}

@NgModule({
  declarations: [MiniOffenseSummaryComponent],
  imports: [CommonModule],
  exports: [MiniOffenseSummaryComponent],
})
export class MiniOffenseSummaryComponentModule {}
