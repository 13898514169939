import { Component, Input, NgModule } from '@angular/core';
import { UxComp } from '@ux5.2/types';

@Component({
  selector: 'app-seal-cards',
  templateUrl: './sealCard.component.html',
  styleUrls: ['./sealCard.component.scss']
})
export class SealCardComponent {
  @Input() forceHorizontal: boolean = false;
  @Input() officePhone: String;
}
