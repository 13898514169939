import {Component, OnInit} from "@angular/core";

@Component({
  selector: 'app-sales-closure',
  templateUrl: 'salesClosure.component.html'
})
export class SalesClosureComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
