// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltiop-modal {
  background-color: white;
}
.tooltiop-modal .content {
  border: 2px solid #464FA7;
  border-radius: 4px;
}
.tooltiop-modal .content .header {
  background: #464FA7;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tooltiop-modal .content .body {
  padding: 16px 40px;
}
.tooltiop-modal .content .body p:first-child {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #525252;
  margin-bottom: 30px;
}

#tutorial-modal ::ng-deep .tooltip {
  top: 2309.97px;
  left: 266.844px;
  min-width: 300px;
  padding: 0;
  border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/app/member/peopleSearch/nameSearch/detail/tutorialModal/tutorialModal.component.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;AACF;AACE;EACE,yBAAA;EACA,kBAAA;AACJ;AACI;EACE,mBAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACN;AACI;EACE,kBAAA;AACN;AACM;EACE,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,mBAAA;AACR;;AAeA;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,UAAA;EACA,kBAAA;AAZF","sourcesContent":[".tooltiop-modal {\n  background-color: white;\n\n  .content {\n    border: 2px solid #464FA7;\n    border-radius: 4px;\n\n    .header {\n      background: #464FA7;\n      padding: 20px;\n      display: flex;\n      align-items: center;\n      justify-content: center;\n    }\n    .body {\n      padding: 16px 40px;\n\n      p:first-child {\n        font-style: normal;\n        font-weight: normal;\n        font-size: 18px;\n        line-height: 21px;\n        color: #525252;\n        margin-bottom: 30px;\n      }\n      // p:last-child {\n      //   font-weight: normal;\n      //   font-size: 18px;\n      //   line-height: 21px;\n      //   text-decoration-line: underline;\n      //   color: #81B34C;\n      //   margin-bottom: 0;\n      //   cursor: pointer;\n      //   text-align: center;\n      // }\n    }\n  }\n}\n\n#tutorial-modal ::ng-deep .tooltip {\n  top: 2309.97px;\n  left: 266.844px;\n  min-width: 300px;\n  padding: 0;\n  border-radius: 4px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
