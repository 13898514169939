import {Component, Input, OnChanges, OnInit} from "@angular/core";
import {UxComposite} from "../../common/models/ux/uxComposite";
import {UxcService} from "../../clientCommon/services/uxc.service";
import {LogUtils} from "../../common/utils/logUtils";
import {htmlUtils} from "../../clientCommon/utils/htmlUtils";

@Component({
    selector: 'rawtemplate',
    template: '<div [id]="id" [class]="tagClass"></div>',
    standalone: false
})
export class RawTemplate implements OnInit, OnChanges {
  private static count = 0;
  id;
  @Input() html: string;
  @Input() tagClass = "";

  uxComposite: UxComposite;

  constructor(private uxcService: UxcService) {
    RawTemplate.count++;
    this.id = `raw-${RawTemplate.count}`;
  }

  ngOnInit() {
    this.uxcService.getUxComposite().then((uxComposite: UxComposite) => {
      this.uxComposite = uxComposite;
      this.applyTemplate();
    }).catch((e) => {
      LogUtils.error(e);
    });
  }

  applyTemplate() {
    if (this.html) {
      htmlUtils.replaceInnerHtmlById(this.id, this.html);
    }
  }

  ngOnChanges() {
    this.applyTemplate();
  }
}
