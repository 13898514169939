import { Injectable } from '@angular/core';
import { isString } from "util";
import moment from 'moment';

import { thinMatchType } from "../../common/custom/models/peopleSearch/searchedPerson";
import { ResponseEvent } from "../../common/event/responseEvent";
import { serverPaths } from "../../common/helpers/pathHelpers";
import { CommerceOffer } from "../../common/models/commerce/commerceOffer";
import { CommerceOfferRuleKey } from "../../common/models/commerce/commerceOrder";
import { SequenceOptions } from "../../common/models/commerce/commercePrice";
import { UxComposite } from "../../common/models/ux/uxComposite";
import { LogUtils } from "../../common/utils/logUtils";
import { StorageService } from './storage.service';
import { CommerceService } from './commerce.service';
import { CustomBaseComponent } from '../custom/customBaseComponent';
import { UxHelper } from '../helper/uxHelper';

@Injectable()
export class CommerceOfferService {
  readonly defaultRulesKey: string = 'offers.rules';

  structuredCommerceOfferIds: {
    [type: string]: string[]
  } = {};

  structuredCommerceOffers: {
    [type: string]: CommerceOffer[]
  } = {};

  private currentMainOfferKey: string = "main";

  private loadedRulesKey: string | null = null;

  get mainOfferKey() {
    return this.currentMainOfferKey;
  }

  constructor(
    private storageService: StorageService,
    private commerceService: CommerceService,
  ) {}

  makeSignupOfferKey(uxHelper: UxHelper) {
    return this.toSignupKey({
      uxcompKey: uxHelper.getUxcompKey(this.defaultRulesKey),
      page: uxHelper.page,
    });
  }

  toSignupKey(config: { uxcompKey: string, page: string }) {
    return config.uxcompKey.replace(
      `.${config.page}.`,
      `.${CustomBaseComponent.PAGE.signup}.`,
    );
  }

  getThinMatchObject() {
    const thinMatch = {
      enabled: false,
      thinMatchType: null
    };

    const thinMatchEnabled = this.storageService.getSession("thinMatchEnabled");

    if (thinMatchEnabled) {
      thinMatch.enabled = true;
      thinMatch.thinMatchType = this.storageService.getSession("thinMatchType");
    }

    return thinMatch;
  }

  getSequenceOptions(): SequenceOptions {
    const thinMatch = this.getThinMatchObject();
    const sequenceOptions: SequenceOptions = {
      thinMatch: thinMatch.enabled,
      thinMatchNoResults: thinMatch.thinMatchType === thinMatchType.NoResults,
    };
    return sequenceOptions;
  }

  setMainOfferKey(uxComposite: UxComposite, offerKey: string) {
    this.currentMainOfferKey = offerKey;

    return this.initOffers(uxComposite);
  }

  /**
   * This function set the all the commerceOffer variables commerceOfferIds, structuredCommerceOrder
   * It will:
   *  -> get offer rules from uxComps and iterate on all its keys and then
   *  -> for each offer rule key get its commerceOfferIds which will be used to call the commerceService to get the commerce offer against them
   *  -> push all the commerce offers in [structruedCommerceOffers] array, if they found structuredCommerceOfferIds we extracted from rule keys
   *  -> get productId of a commerceOffer depends on if the product key is for nameSearch or phoneSearch
   *  -> get content productId from commerceOffer depends on if the product key is for nameSearch or phoneSearch and set this.contentProductId
   *  -> Finally, set uxComposite's codes for offers,selected person & offer total amount
   */
  initOffers(uxComposite: UxComposite, config?: { offerRulesKey?: string }) {
    const effectiveRulesKey = config?.offerRulesKey ?? this.defaultRulesKey;
    let commerceOfferIds = [];
    let keys: string[] = [];

    // for each offferRule key, get the commerceOfferIds
    return Promise.resolve().then(() => {
      let offerRules = this.getOfferRules(uxComposite, effectiveRulesKey);
      if (offerRules) {
        Object.keys(offerRules).forEach((key) => {
          if (offerRules[key] && offerRules[key].length) {
            keys.push(key);
            this.structuredCommerceOfferIds[key] = [];
            this.structuredCommerceOffers[key] = [];

            offerRules[key].forEach((offerRule: CommerceOfferRuleKey) => {
              if (offerRule.offerKey) {
                let ids = this.getValue(uxComposite, offerRule.offerKey);
                if (ids) {
                  if (isString(ids)) {
                    ids = [ids];
                  } else if (ids.length) {
                  }
                  this.structuredCommerceOfferIds[key] = this.structuredCommerceOfferIds[key].concat(ids);
                  commerceOfferIds = commerceOfferIds.concat(ids);
                }
              }
            });
          }
        });
        const offer = this.structuredCommerceOffers[this.mainOfferKey]?.[0];
        if (offer) {
          const priceDetails = offer.tempClientSecured.prices?.[1]?.[0];
          uxComposite.setCode('offerPrice', priceDetails?.amount);
          uxComposite.setCode('offerFrequency', priceDetails?.periodString);
          uxComposite.setCode('billingDate', offer.tempClientSecured?.billingDatesString?.[1]);
        }
      }

      // Call the commerceService to get the commerce offer against the commerceOfferIds
      const sequenceOptions = this.getSequenceOptions();
      return this.commerceService.request(serverPaths.commerceActionLoadOffer, { ids: commerceOfferIds, sequenceOptions });
    }).then((responseEvent: ResponseEvent) => {
      keys.forEach((key) => {
        this.structuredCommerceOffers[key] = [];
      });

      /**
       * push all the commerce offers found structruedCommerceOffer array
       * if they found in structuredCommerceOfferIds which we extracted from rule keys
       */
      let commerceOffers: CommerceOffer[] = responseEvent.getDocs();
      commerceOffers.forEach((commerceOffer) => {
        const { billingDates } = commerceOffer.tempClientSecured;
        commerceOffer = {
          ...commerceOffer,
          tempClientSecured: {
            ...commerceOffer.tempClientSecured,
            billingDateTimesString: billingDates.map((date) => {
              return moment(date).format('h:mma on MMMM d, y');
            }),
          }
        } as CommerceOffer;

        keys.some((key) => {
          if (!!commerceOffer?._id && this.structuredCommerceOfferIds[key].indexOf(commerceOffer._id) !== -1) {
            this.structuredCommerceOffers[key].push(commerceOffer);
            return true;
          }
        });
      })
    }).then(() => {
      uxComposite.setCode("offers", this.structuredCommerceOffers);

      let offerAmountsTotal = 0;
      if (this.structuredCommerceOffers) {
        Object.keys(this.structuredCommerceOffers).forEach((key) => {
          this.structuredCommerceOffers[key].forEach((commerceOffer) => {
            const sequenceOptions = this.getSequenceOptions();
            let amount = commerceOffer.commercePrice.getTotalPrice(0, 0, sequenceOptions).amount;
            offerAmountsTotal += amount;
          });
          uxComposite.setCode('offerAmountsTotal', offerAmountsTotal);
        });
      }
    }).then(() => {
      this.loadedRulesKey = effectiveRulesKey;
    }).catch((e) => {
      this.loadedRulesKey = null;
      LogUtils.error(e);
    });
  }

  private isAlreadyLoaded(rulesKey: string): boolean {
    return !!this.loadedRulesKey && this.loadedRulesKey === rulesKey;
  }

  private getOfferRules(uxComposite: UxComposite, offerRulesKey: string) {
    return this.getValue(uxComposite, offerRulesKey);
  }

  private getValue(uxComposite: UxComposite, key?: string) {
    if (!key) {
      return null;
    }

    try {
      return uxComposite.get(key);
    } catch (e) {
      return null;
    }
  }
}
