import * as util from 'util';
import { fireAndForgetUtils } from './fireAndForgetUtils';

declare var f5e99a00: any;

export class LogUtils {
  public static TYPE = {
    prod: "prod",
    dev: "dev",
    test: "test",
  };

  public static LOG_TYPE = {
    consumable: "___CONSUMABLE___",
    consumed: "___CONSUMED___",
  };

  private static log = console;
  public static type = LogUtils.TYPE.prod;
  private static expanded = false;

  public static isBrowser() {
    try {
      return f5e99a00 !== undefined;
    } catch (e) {
      return false;
    }
  }

  public static setExpanded(flag) {
    LogUtils.expanded = flag;
  }

  public static setDev() {
    if (!LogUtils.isTest()) { // can't override if this is test
      LogUtils.type = LogUtils.TYPE.dev;
    }
  }

  public static isDev() {
    return LogUtils.type === LogUtils.TYPE.dev;
  }

  public static setTest() {
    LogUtils.type = LogUtils.TYPE.test;
  }

  public static isTest() {
    return LogUtils.type === LogUtils.TYPE.test;
  }

  public static setLog(log) {
    LogUtils.log = log;
  }

  public static null(...args) {
    // does nothing
  }

  public static shapeLog(obj) {
    if (LogUtils.expanded) {
      return util.inspect(obj, {
        colors: false,
        depth: 40,
        maxArrayLength: null,
        // maxStringLength: null,
      });
    } else {
      return obj;
    }
  }

  public static test(...args) {
    let obj = args.length > 1 ? args : args[0];
    LogUtils.log.info(LogUtils.getCallerInfo(), LogUtils.shapeLog(obj));
  }

  public static debug(...args) {
    if (LogUtils.isDev()) {
      let obj = args.length > 1 ? args : args[0];
      LogUtils.log.info(LogUtils.getCallerInfo(), LogUtils.shapeLog(obj));
    }
  }

  public static info(...args) {
    if (!LogUtils.isTest()) {
      let obj = args;
      const method = LogUtils.log.info.bind(LogUtils.log);
      fireAndForgetUtils.method(method, LogUtils.getCallerInfo(), LogUtils.shapeLog(obj));
    }
  }

  public static warn(...args) {
    if (!LogUtils.isTest()) {
      let obj = args;
      const method = LogUtils.log.warn.bind(LogUtils.warn);
      fireAndForgetUtils.method(method, LogUtils.getCallerInfo(), LogUtils.shapeLog(obj));
    }
  }

  public static persist(...args) {
    let obj = {caller: LogUtils.getCallerInfo(), message: args};
    LogUtils.log.info(LogUtils.shapeLog(obj));
  }

  public static error(...args) {
    let obj = {caller: LogUtils.getCallerInfo(Infinity), message: args};
    const message = LogUtils.shapeLog(obj);
    LogUtils.log.error(message);
    if (LogUtils.isBrowser()) {
      const sanitizedMessage = LogUtils.sanitizeMessage(message);
      const length = LogUtils.objectSize(sanitizedMessage);
      if (length > 102400) {
        f5e99a00("large_errorlog", sanitizedMessage);
      } else {
        f5e99a00("errorlog", sanitizedMessage);
      }
    }
  }

  private static objectSize(obj) { //  not to have circular dependency
    if (obj) {
      return JSON.stringify(obj).length;
    } else {
      return 0;
    }
  }

  private static sanitizeMessage(message) {
    try {
      let sanitizedMessage = JSON.parse(JSON.stringify(message));
      if (sanitizedMessage && sanitizedMessage.message && message.message.length) {
        sanitizedMessage.message.forEach((obj) => {
          if (obj.docs) {
            obj.docs = null;
          }
        });
      } else {
        sanitizedMessage = message;
      }
      return sanitizedMessage;
    } catch (e) {
      return {typeof: (typeof message), error: e};
    }
  }

  public static errorBrief(e) {
    let error = null;
    if (e instanceof Error) {
      error = e;
    } else {
      error = "NotErrorObject:" + typeof e;
    }
    LogUtils.error(error);
  }

  public static smartLog(...args) {
    let error = false;
    args.some((arg) => {
      if (arg instanceof Error) {
        error = true;
        return true;
      }
    });

    if (error) {
      LogUtils.error(args);
    } else {
      LogUtils.info(args);
    }
  }

  public static getCallerInfo(lines?: number) {
    try {
      var error = new Error;
      var stacks = error.stack.split('\n');
      var messages = "";

      if (!lines) {
        lines = 1;
      } else if (lines > (stacks.length - 3)) {
        lines = stacks.length - 3;
      }

      for (let i = 0; i < lines; i++) {
        messages += stacks[i + 3];
        if (i === 0) {
          messages = messages.replace('    at ', '');
        }
        messages += "\n";
      }

      return messages;
    } catch (e) {
      LogUtils.log.error(LogUtils.shapeLog(e));
    }
  }

  public static shappeHttpResponse(res) {
    const shapedRes = {
      statusCode: res?.statusCode,
      body: res?.body,
      headers: res?.headers,
    };

    return shapedRes;
  }
}
