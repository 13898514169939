import {CollectionClass} from "../../decorators/database/collectionClass";
import {ModelBase} from "../modelBase";
import {IndexField} from "../../decorators/database/indexField";
import {TransientField} from "../../decorators/database/transientField";
import {ForeignObjectField} from "../../decorators/database/foreignObjectField";
import {ReferenceField} from "../../decorators/database/referenceField";
import {ForeignKeyField} from "../../decorators/database/foreignKeyField";
import {CommerceToken} from "./commerceToken";
import {User} from "../user/user";
import {CompositeClassField} from "../../decorators/database/compositeClassField";
import {CommerceBillingRouting} from "./commerceBillingRouting";

@CollectionClass({name: "commercePayments"})
export class CommercePayment extends ModelBase<CommercePayment> {
  public static TYPE = {
    sale: "sale",
    refund: "refund",
    void: "void",
    authorize: "authorize",
    capture: "capture",
    validate: "validate",
    verify: "verify",  // not used
    credit: "credit",
    dispute: "dispute",
    dummy: "dummy",
  };

  public static SUB_STATUS = {
    none: "none",
    refund: "refund",
    void: "void",
    chargeback: "chargeback",
  };

  public static SUB_TYPE = {
    retryQuadzero: "retryQuadzero",
    retryDropCvv: "retryDropCvv",
    retryDecline: "retryDecline",
    retryAutoDecline: "retryAutoDecline",
    passthruDecline: "passthruDecline",
    passthruDeclineValidate: "passthruDeclineValidate",
    passthruDeclineValidatePassthrued: "passthruDeclineValidatePassthrued",
    passthruPrepaid: "passthruPrepaid",
    passthruGateway: "passthruGateway",
    bypassGateway: "bypassGateway",
    authorizeVoid: "authorizeVoid",
  };

  price: { code: string, amount: number, options };
  sequence: number;
  retry: number;
  gateway: string;
  adjustment: { code: string, amount: number };
  subType;
  reason;

  @CompositeClassField(CommerceBillingRouting) commerceBillingRouting: CommerceBillingRouting;

  @IndexField() declare subStatus: string;
  @IndexField() @ReferenceField(CommerceToken) @ForeignKeyField(CommerceToken) commerceTokenId: string;
  @TransientField @ForeignObjectField("commerceTokenId") commerceToken: CommerceToken;
  @IndexField() authCode: string;

  @IndexField() gatewayTxId: string;
  @IndexField() parentGatewayTxId: string;
  @IndexField() paymentTimestamp: number;
  @IndexField() @ForeignKeyField(User) payerId: string;
  paymentInfo: any; // raw payment info
  additionalInfo: any[] = []; // Addtional info such webhook verification

  constructor(doc?, draftFlag?: boolean) {
    super(doc, draftFlag);
    this.init(doc, this.draftFlag);
  }

  getSecuredDoc(): CommercePayment {
    let obj: CommercePayment = super.getSecuredDoc();

    obj.commerceTokenId = this.commerceTokenId;
    obj.paymentTimestamp = this.paymentTimestamp;
    obj.sequence = this.sequence;
    obj.retry = this.retry;
    obj.price = this.price;

    return obj;
  }

  addAdjustment(adjustment: { code: string, amount: number }) {
    if (adjustment.code !== this.price.code) {
      throw new Error("Wrong price code");
    }

    if (!this.adjustment) {
      this.adjustment = {code: adjustment.code, amount: adjustment.amount};
    } else {
      this.adjustment.amount = Math.round((this.adjustment.amount + adjustment.amount) * 10000) / 10000;
    }
  }

  calculateRemainingValue() {
    let value = {code: this.price.code, amount: this.price.amount, options: null};

    if (this.adjustment) {
      value.amount += this.adjustment.amount;
    }

    value.amount = Math.round(value.amount * 10000) / 10000;

    return value;
  }

  isAdjustable(price?: { code: string, amount: number }): boolean {
    const finalAdjustment = {code: this.price.code, amount: this.price.amount};
    const currentValue = this.calculateRemainingValue();

    if(!(
      this.type === CommercePayment.TYPE.sale 
      || this.type === CommercePayment.TYPE.capture
      || this.type === CommercePayment.TYPE.authorize
    )) {
     return false;
    }

    if ((!this.isFulfilled()) || this.tempClient.delayRefund) {
      return false;
    }

    if ((this.adjustment && finalAdjustment.code !== this.adjustment.code) ||
      (price && finalAdjustment.code !== price.code)) {
      throw new Error("Wrong price code");
    }

    if (this.adjustment) {
      finalAdjustment.amount += this.adjustment.amount;
    }
    if (price) {
      finalAdjustment.amount += price.amount;
    }

    finalAdjustment.amount = Math.round(finalAdjustment.amount * 10000) / 10000;
    return this.isFulfilled() &&
      finalAdjustment.amount >= 0 && // Should be 0 or greater after adjustment
      currentValue.amount > 0 && // should have positive value for this payment to adjustment
      (price ? price.amount < 0 : true) && // adjustment should be negative
      this.subStatus !== CommercePayment.SUB_STATUS.void &&
      this.subStatus !== CommercePayment.SUB_STATUS.chargeback;
  }

  isVoidable(): boolean {
    let currentValue = this.calculateRemainingValue();

    return currentValue.amount > 0 &&
      this.subStatus !== CommercePayment.SUB_STATUS.void &&
      this.subStatus !== CommercePayment.SUB_STATUS.chargeback;
  }

  getProcessorId() {
    let merchantProcessorId;
    if (this.paymentInfo) {
      if (this.paymentInfo.merchantProcessorId) {
        merchantProcessorId = this.paymentInfo.merchantProcessorId;
      }
    }
    return merchantProcessorId;
  }
}
