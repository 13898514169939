import { BaseComponent } from "../../clientCommon/components/BaseComponent";
import { ServiceHelperService } from "../../clientCommon/services/serviceHelper.service";
import { ActivatedRoute } from "@angular/router";
import { CommerceContent } from "../../common/models/commerce/commerceContent";
import { LogUtils } from "../../common/utils/logUtils";
import { BehaviorSubject } from "rxjs";
import { peopleSearchContentHelper } from "../../common/custom/peopleSearch/peopleSearchHelper";
import { SearchedPerson } from "../../common/custom/models/peopleSearch/searchedPerson";

export class DetailPeopleSearch extends BaseComponent {
  commerceContentId;
  commerceContent: CommerceContent;
  personSubject = new BehaviorSubject<SearchedPerson>(null);
  searchedPersons: SearchedPerson[] = [];

  constructor(
    serviceHelperService: ServiceHelperService,
    activatedRoute: ActivatedRoute
  ) {
    super(serviceHelperService, activatedRoute);
    this.page = BaseComponent.PAGE.detail;
  }

  onInit(): Promise<any> {
    return super.baseInit().then(() => {
      this.activatedRoute.params.subscribe((params) => {
        let id = params.id;
        if (id) {
          this.commerceContentId = id;
          return this.init();
        }
      });
    });
  }

  init() {
    this.serviceHelperService.spinnerService.spin();
    this.serviceHelperService.commerceService
      .findContent(this.commerceContentId)
      .then((commerceContent: CommerceContent) => {
        peopleSearchContentHelper.processResult(
          this.uxComposite,
          commerceContent
        );
        this.commerceContent = commerceContent;
        LogUtils.debug(
          commerceContent,
          commerceContent.getMainRaw(),
          commerceContent.getMainRaw().tempClient.processed.person[0]
        );
        try {
          let personData = commerceContent.getMainRaw().tempClient.processed.person[0];

          this.searchedPersons = commerceContent.getMainRaw().tempClient.processed.person;

          this.personSubject.next(personData);
        } catch (e) {
          LogUtils.error(e);
        }
      })
      .catch((e) => {
        LogUtils.error(e);
      })
      .then(() => {
        this.serviceHelperService.spinnerService.unspin();
      });
  }

  onDestroy() {
    return super.onDestroy();
  }
}
