import {DocumentReferenceUtils} from '../utils/documentReferenceUtils';
import {Permission} from './permission';
import {TransientField, transientFieldHelper} from '../decorators/database/transientField';
import {ManualField, manualFieldHelper} from '../decorators/database/manualField';
import {IndexField} from '../decorators/database/indexField';
import {ServerField, serverFieldHelper} from '../decorators/database/serverField';
import {ForeignKeyField, foreignKeyFieldHelper} from '../decorators/database/foreignKeyField';
import {referenceFieldHelper} from '../decorators/database/referenceField';
import {foreignObjectFieldHelper} from '../decorators/database/foreignObjectField';
import {NoCyclicField, noCyclicFieldHelper} from '../decorators/database/noCyclicField';
import {collectionClassHelper} from '../decorators/database/collectionClass';
import {ModelRevision} from './modelRevision';
import {objectUtils} from '../utils/objectUtils';
import {isArray, isObject, isString} from 'util';
import {LogUtils} from '../utils/logUtils';
import {compositeClassFieldHelper} from '../decorators/database/compositeClassField';
import {radixUtils} from '../utils/radixUtils';
import {stringUtils} from '../utils/stringUtils';
import {ArrayUtils} from '../utils/arrayUtils';

export class ModelBase<BASE> {
  public static documentUtils;
  private currentDocumentUtils;

  public static STATUS = {
    draft: 'draft',
    inProgress: 'inProgress',
    active: 'active',
    inactive: 'inactive',
    ignored: 'ignored',
    failed: 'failed',
    removed: 'removed',
    banned: 'banned',
    locked: 'locked',
    flooded: 'flooded',
    fulfilled: 'fulfilled',
    rejected: 'rejected',
    error: 'error',
    requested: 'requested',
    done: 'done',
  };


  public static OWNER_TYPE = {
    sessionHash: 'sessionHash',
  };

  //////////////////////////////////////////////////////////////////////////
  // From here Flex General Properties
  @ManualField public _id?: string;

  @IndexField() public ownerId: string;
  public ownerType: string;
  @IndexField() @NoCyclicField public parentId: string;
  @IndexField() @ManualField public createdTimestamp: number;
  @IndexField() @ManualField public changedTimestamp: number;
  @IndexField() public status: string;
  public subStatus: string;
  @IndexField() public type: string;
  public data: any = {}; // any extra data

  @ManualField public draft: BASE;
  @ManualField public references: any[] = [];
  @ManualField public mainReferenceIds: string[] = [];
  @ServerField @ManualField public modelRevisionIds: string[] = [];  // Deprecated
  @ManualField @ForeignKeyField(ModelRevision) public currentModelRevisionId: string;
  @ManualField public originalRevisionId: string;
  public permissionIds: string[] = []; // Can't use @ForeignKeyField due to cyclic dependency
  @TransientField public permissions: Permission[] = []; // Can't use @ForeignObjectField due to cyclic dependendcy
  @IndexField() @ServerField @ManualField public index: string[] = [];
  @ServerField @TransientField public draftFlag: boolean;
  @ServerField @TransientField public revisionFlag: boolean;
  @ServerField @TransientField public tempServerOnly: any = {}; // temporary field only for server
  @TransientField public tempClient: any = {}; // temporary field for client
  @TransientField public tempClientSecured: any = {}; // temporary field for client
  @IndexField() public updaterId: string;
  @IndexField() public brandId: string;
  @ManualField inputs: any = [];
  public connectionInfo;
  public tagIds: string[] = [];

  constructor(doc?, draftFlag?: boolean) {
    if (draftFlag) {
      this.draftFlag = draftFlag;
    }
  }

  init(doc?, draftFlag?: boolean) {
    this.expandDoc(doc);
    if (!draftFlag) {
      const proto = Object.getPrototypeOf(this);
      this.draft = new proto.constructor(doc, true)
    }
  }

  getCollectionName(): string {
    return collectionClassHelper.getClass(this).value.name;
  }

  getDocumentutils() {
    return ModelRevision.documentUtils;
  }

  setCurrentDocumentUtils(docuemntUtils) {
    this.currentDocumentUtils = docuemntUtils;
  }

  getCurrentDocumentUtils() {
    if (this.currentDocumentUtils) {
      return this.currentDocumentUtils;
    } else {
      return this.getDocumentutils();
    }
  }

  expandDoc(doc?) {
    try {
      if (doc) {
        let currentRef: ModelBase<any>;
        if (!this.isDraft()) {
          currentRef = doc;
          Object.assign(this as any, doc);
        } else {
          currentRef = doc.draft;
          Object.assign(this as any, doc.draft);
        }

        // foreign Object
        const foreignKeys = foreignKeyFieldHelper.getProperties(this);
        const foreignObjectFields = this.getForeignObjectProperties();
        foreignObjectFields.forEach((foreignObject) => {
          foreignKeys.some((foreignKey) => {
            if (foreignKey.key === foreignObject.value) {
              if (!this.isDraft()) {
                if (doc[foreignObject.key]) {
                  let values;
                  if (isArray(doc[foreignObject.key])) {
                    values = [];
                    doc[foreignObject.key].forEach((value) => {
                      if (!(value instanceof foreignKey.value)) {
                        values.push(new foreignKey.value(value));
                      } else {
                        values.push(value);
                      }
                    });
                  } else {
                    if (!(doc[foreignObject.key] instanceof foreignKey.value)) {
                      values = new foreignKey.value(doc[foreignObject.key]);
                    } else {
                      values = doc[foreignObject.key];
                    }
                  }
                  this[foreignObject.key] = values;
                }
              } else {
                if (doc.draft && doc.draft[foreignObject.key]) {
                  let values;
                  if (isArray(doc.draft[foreignObject.key])) {
                    values = [];
                    doc.draft[foreignObject.key].forEach((value) => {
                      if (!(value instanceof foreignKey.value)) {
                        values.push(new foreignKey.value(value));
                      } else {
                        values.push(value);
                      }
                    });
                  } else {
                    if (!(doc.draft[foreignObject.key] instanceof foreignKey.value)) {
                      values = new foreignKey.value(doc.draft[foreignObject.key]);
                    } else {
                      values = doc.draft[foreignObject.key];
                    }
                  }
                  this[foreignObject.key] = values;
                }
              }
              return true;
            }
          });
        });

        // CompositeClass
        const compositeClassFields = compositeClassFieldHelper.getProperties(this);
        compositeClassFields.forEach((compositeClassField) => {
          if (!this.isDraft()) {
            if (doc[compositeClassField.key]) {
              let values;
              if (isArray(doc[compositeClassField.key])) {
                values = [];
                doc[compositeClassField.key].forEach((value) => {
                  values.push(new compositeClassField.value(value));
                });
              } else {
                values = new compositeClassField.value(doc[compositeClassField.key]);
              }
              this[compositeClassField.key] = values;
            }
          } else {
            if (doc.draft && doc.draft[compositeClassField.key]) {
              let values;
              if (isArray(doc.draft[compositeClassField.key])) {
                values = [];
                doc.draft[compositeClassField.key].forEach((value) => {
                  values.push(new compositeClassField.value(value));
                });
              } else {
                values = new compositeClassField.value(doc.draft[compositeClassField.key]);
              }
              this[compositeClassField.key] = values;
            }
          }
        });

        // Permission
        if (currentRef) {
          if (currentRef.permissions) {
            const permissions = [];
            currentRef.permissions.forEach((permission) => {
              const prototype = collectionClassHelper.getClassByKeyValue('name', 'permissions').target;
              const newObj = new prototype.constructor(permission);
              permissions.push(newObj);
            });
            this.permissions = permissions;
          }
        }
      }

      if (!this.tempServerOnly) {
        this.tempServerOnly = {};
      }

      if (!this.tempClient) {
        this.tempClient = {};
      }

      if (!this.tempClientSecured) {
        this.tempClientSecured = {};
      }
    } catch (e) {
      LogUtils.error(e);
      throw e;
    }

  }

  isDraftEnabled(): boolean {
    return collectionClassHelper.isEnabledDraftByCollectionName(this.getCollectionName());
  }

  getTransientProperties(): string[] {
    return transientFieldHelper.getProperties(this);
  }

  getReferenceProperties(): { key: string, value: any }[] {
    return referenceFieldHelper.getProperties(this, collectionClassHelper.getCollectionNameByDecoratorFieldValue);
  }

  getForeignKeyProperties(): { key: string, value: any }[] {
    return foreignKeyFieldHelper.getProperties(this, collectionClassHelper.getCollectionNameByDecoratorFieldValue);
  }

  getForeignObjectProperties(): { key: string, value: any }[] {
    return foreignObjectFieldHelper.getProperties(this);
  }

  getNoCyclicProperties(): string[] {
    return noCyclicFieldHelper.getProperties(this);
  }

  syncFromDraft() {
    if (this.throwErrorIfNotBase()) {
      this.getDocumentutils().syncFromDraft(this);
    }
  }

  syncFromDraftAndSaveAll(): Promise<any> {
    if (this.throwErrorIfNotBase()) {
      return this.getDocumentutils().syncFromDraftAndSaveAll(this);
    }
  }

  mutexedSyncFromDraftAndSaveAll(option?) {
    if (this.throwErrorIfNotBase()) {
      return this.getDocumentutils().mutexedSyncFromDraftAndSaveAll(this, option);
    }
  }

  saveAddIndex(index: string[]): Promise<any> {
    return this.getDocumentutils().saveAddIndex(this.getCollectionName(), this._id, index);
  }

  saveAddMainReferenceIds(ids: string[]): Promise<any> {
    return this.getDocumentutils().saveMainReferenceIds(1, this.getCollectionName(), this._id, ids);
  }

  saveRemoveMainReferenceIds(ids: string[]): Promise<any> {
    return this.getDocumentutils().saveMainReferenceIds(-1, this.getCollectionName(), this._id, ids);
  }

  saveAddReferences(references: ModelBase<any>[]): Promise<any> {
    return this.getDocumentutils().saveReferences(1, this.getCollectionName(), this._id, references);
  }

  saveRemoveReferences(references: ModelBase<any>[]): Promise<any> {
    return this.getDocumentutils().saveReferences(-1, this.getCollectionName(), this._id, references);
  }

  isRemoved() {
    return this.status === ModelBase.STATUS.removed;
  }

  isDraft(): boolean {
    return !!this.draftFlag;
  }

  isRevision(): boolean {
    return !!this.revisionFlag;
  }

  countReferences() {
    return DocumentReferenceUtils.getLength(this.references);
  }

  getDoc(withoutFields: string[]) {
    let keys = Object.keys(this);
    keys = keys.filter((value) => {
      return !(withoutFields.indexOf(value) >= 0);
    });

    const doc: any = {};
    keys.forEach((key) => {
      doc[key] = this[key];
    });

    if (this.draft instanceof ModelBase) {
      doc.draft = this.draft.getDoc(withoutFields);
    }

    return doc;
  }

  getSaveSet(): any {
    let withoutFields = [];
    withoutFields = withoutFields.concat(transientFieldHelper.getProperties(this));
    withoutFields = withoutFields.concat(manualFieldHelper.getProperties(this));

    return this.getDoc(withoutFields);
  }

  getClientDoc() {
    let withoutFields = [];
    withoutFields = withoutFields.concat(serverFieldHelper.getProperties(this));
    withoutFields = withoutFields.concat(foreignObjectFieldHelper.getProperties(this));
    const doc = this.getDoc(withoutFields);

    this.getForeignObjectProperties().forEach((property) => {
      if (this[property.key]) {
        if (isArray(this[property.key])) {
          doc[property.key] = [];
          this[property.key].forEach((obj) => {
            doc[property.key].push(obj.getClientDoc());
          });
        } else {
          doc[property.key] = this[property.key].getClientDoc();
        }
      }
    });

    return doc;
  }

  getSecuredDoc(): BASE {
    const doc = {
      _id: this._id,
      currentModelRevisionId: this.currentModelRevisionId,
      status: this.status,
      type: this.type,
      tempClientSecured: this.tempClientSecured,
    };
    this.getForeignObjectProperties().forEach((property) => {
      if (this[property.key]) {
        if (isArray(this[property.key])) {
          doc[property.key] = [];
          this[property.key].forEach((obj) => {
            doc[property.key].push(obj.getSecuredDoc());
          });
        } else {
          doc[property.key] = this[property.key].getSecuredDoc();
        }
      }
    });

    const obj = new (this.constructor as any)(doc);
    obj.draft = undefined;
    return obj;
  }


  isDiffDraft() {
    if (this.throwErrorIfNotBase()) {
      let flag = false;

      let withoutFields = [];
      withoutFields = withoutFields.concat(transientFieldHelper.getProperties(this));
      withoutFields = withoutFields.concat(manualFieldHelper.getProperties(this));

      Object.keys(this.draft).some((key) => {
        if (withoutFields.indexOf(key) === -1) {
          let orig = this[key];
          let draft = this.draft[key];

          if (isArray(this.draft[key]) || objectUtils.isObject(this.draft[key])) {
            orig = JSON.stringify(this[key]);
            draft = JSON.stringify(this.draft[key]);
          }

          if (orig !== draft) {
            flag = true;
            return true;
          }
        }
      });
      return flag;
    }
  }

  isActive() {
    return this.status === ModelBase.STATUS.active;
  }

  isInactive() {
    return this.status === ModelBase.STATUS.inactive;
  }

  isFulfilled() {
    return this.status === ModelBase.STATUS.fulfilled;
  }

  isInProgress() {
    return this.status === ModelBase.STATUS.inProgress;
  }

  loadForeignObject(options?): Promise<any> {
    if (!options) {
      options = {};
    }
    if (!options.objMeta) {
      options.objMeta = {};
    }
    options.objMeta.depth = this.getObjDepth() + 1;

    return this.getCurrentDocumentUtils().loadForeignObject(this, options);
  }

  throwErrorIfNotBase() {
    if (this.isDraft() || this.isRevision()) {
      const message = 'Should not be called from Draft/Revision';
      const error = new Error(message);
      LogUtils.error(error);
      throw error;
    } else {
      return true;
    }
  }

  isStatusIn(statuses: string[]) {
    if (isArray(statuses)) {
      return statuses.indexOf(this.status) > -1;
    }

    return false;
  }

  getNumericId() {
    if (this._id) {
      return radixUtils.hexToDec(this._id);
    }
  }

  get62Id() {
    if (this._id) {
      return radixUtils.hexTo62(this._id);
    }
  }

  getReverseNumericId() {
    if (this._id) {
      return stringUtils.reverse(radixUtils.hexToDec(this._id));
    }
  }

  toggle(key) {
    if (!isObject(this.tempClientSecured.toggle)) {
      this.tempClientSecured.toggle = {};
    }

    this.tempClientSecured.toggle[key] = !this.tempClientSecured.toggle[key];
  }

  getToggle(key) {
    if (!isObject(this.tempClientSecured.toggle)) {
      this.tempClientSecured.toggle = {};
    }

    return !!this.tempClientSecured.toggle[key];
  }

  getIdsFromReference(def) {
    const ids = [];
    if (!isString(def)) {
      def = collectionClassHelper.getCollectionName(def);
    }
    this.references.forEach((reference) => {
      if (reference.collectionName === def) {
        ids.push(reference.id);
      }
    });

    return ids;
  }

  getObjById(key, id): object {
    let target;
    if (this[key] && isArray(this[key])) {
      this[key].some((obj) => {
        if (obj._id === id) {
          target = obj;
          return true;
        }
      });
    }

    return target;
  }

  getObjDepth(): number {
    if (!this.tempServerOnly.meta) {
      this.tempServerOnly.meta = {};
    }

    return this.tempServerOnly.meta.depth ? this.tempServerOnly.meta.depth : 0;
  }

  setObjDepth(depth: number) {
    if (!this.tempServerOnly.meta) {
      this.tempServerOnly.meta = {};
    }

    this.tempServerOnly.meta.depth = depth;
  }

  lock(option?) {
    return this.getDocumentutils().lock(this, option);
  }

  unlock() {
    return this.getDocumentutils().unlock(this);
  }

  lockWithValidation(option?) {
    return this.getDocumentutils().lockWithValidation(this, option);
  }

  validateObjectToSave() {
    return this.getDocumentutils().validateObjectToSave(this);
  }

  getValuesRecursive(fieldName) {
    const values = [];
    if (this[fieldName]) {
      values.push(this[fieldName]);
    }

    const foreignObjectProperties = this.getForeignObjectProperties();
    if (foreignObjectProperties) {
      foreignObjectProperties.forEach((property) => {
        if (this[property.key]) {
          let foreignObjects = this[property.key];
          if (!isArray(foreignObjects)) {
            foreignObjects = [foreignObjects];
          }
          foreignObjects.forEach((foreignObject) => {
            if (foreignObject.getValuesRecursive) {
              values.push(foreignObject.getValuesRecursive(fieldName));
            } else if (foreignObject[fieldName]) {
              values.push(foreignObject[fieldName]);
            }
          });
        }
      });
    }

    return ArrayUtils.flatten(values);
  }

  mutexedProcessWithValidation(option, param: any, callback: Function,) {
    return this.getDocumentutils().mutexedProcessWithValidation(this, option, param, callback);
  }

  mutexedProcessWithoutValidation(option, param: any, callback: Function,) {
    return this.getDocumentutils().mutexedProcessWithoutValidation(this, option, param, callback);
  }
}
