import {CollectionClass} from '../decorators/database/collectionClass';
import {ModelBase} from './modelBase';
import { User } from './user/user';

@CollectionClass({name: 'permissions'})
export class Permission extends ModelBase<Permission> {

  public static TYPE = {
    site: 'site',
  };

  name: string;
  description: string;

  paths: { key: string, value?: any }[] = [];
  properties: { key: string, value?: any }[] = [];

  constructor(doc?, draftFlag?: boolean) {
    super(doc, draftFlag);
    this.init(doc, this.draftFlag);
  }

  getSecuredDoc(): Permission {
    const obj: Permission = super.getSecuredDoc();
    return obj;
  }

  hasPermission(key, target): boolean {
    // Whitelist
    let flag = null;
    if (this[key]) {
      this[key].some((source) => {
        const regex = new RegExp(source.key, 'i');        
        if (regex.test(target)) {
          flag = true;
          return true;
        }
      });
    }

    return !!flag;
  }

  hasPermissionPath(targetPath): boolean {
    return this.hasPermission('paths', targetPath);
  }
}

