import {
  ApplicationRef,
  Component,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
} from '@angular/core';
import { SignUpPeopleSearch } from '../../signUpPeopleSearchSales';
import { BaseComponent } from '../../../../../clientCommon/components/BaseComponent';
import { ServiceHelperService } from '../../../../../clientCommon/services/serviceHelper.service';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ThreeDSComponent } from '@sales/components/threeDS.component';
@Component({
  templateUrl: './signup.component.html',
})

/**
 * SignupNameSearchSalesComponent render the respective theme/layout component based on current theme
 * @extends SignUpPeopleSearch to get all required properties..
 */
export class SignupNameSearchSalesComponent extends SignUpPeopleSearch implements OnInit, OnDestroy {
  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;
  @ViewChild('threeDS') threeDSComponent: ThreeDSComponent;

  /**
   *
   * @param serviceHelperService will provide access to different required services such as authentication service, uxc service etc.
   * @param activatedRoute provides access to information about a route associated with a component that is loaded in an outlet.
   *
   */
  constructor(
    serviceHelperService: ServiceHelperService,
    ngZone: NgZone,
    applicationRef: ApplicationRef,
    activatedRoute: ActivatedRoute,
    datePipe: DatePipe,
    private _cfr: ComponentFactoryResolver
  ) {
    super(serviceHelperService, activatedRoute, datePipe);

    this.pageCategory = BaseComponent.PAGE_CATOGORY.nameSearch;
  }

  getChildObject() {
    return this;
  }

  ngOnInit() {
    return this.onInit().then(() => {
      this.initDone = true;
      // if (this.theme === this.themes.ux_5_2) {
      //   this._loadUx5_2SignupPage();
      // }
    });
  }

  ngOnDestroy() {
    return super.onDestroy();
  }

  get isEnabled() {
    return this.uxHelper.getUxcomp('pii.enabled');
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event) {
    return super.onBeforeUnload(event);
  }

  @HostListener('window:scroll', ['$event'])
  onScroll($event) {
    super.onScroll($event);
  }

  // private async _loadUx5_2SignupPage() {
  //   if (this.uxHelper.getUxcomp('pii.location') !== 'sup') {
  //     this.container.clear();
  //     const { Ux5Dot2SignupNameSearchComponent } = await import('@ux5.2/peopleSearch/nameSearch/signup');
  //     const componentFactory = this._cfr.resolveComponentFactory(Ux5Dot2SignupNameSearchComponent);
  //     const component = this.container.createComponent(componentFactory);
  //     component.instance.base = this;
  //     component.instance.uxHelper = this.uxHelper;
  //   }
  // }
}
