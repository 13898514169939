import {thinMatchType} from "../custom/models/peopleSearch/searchedPerson";
import {CommerceOffer, CommerceOfferDetail} from "../models/commerce/commerceOffer";
import {SequenceOptions} from "../models/commerce/commercePrice";
import {CommerceStockDetail} from "../models/commerce/commerceStock";
import {objectUtils} from "./objectUtils";
import {CommerceProductDetail} from "../models/commerce/commerceProduct";
import {CommerceOrder} from "../models/commerce/commerceOrder";
import {modelUtils} from "./modelUtils";
import {CommercePayment} from "../models/commerce/commercePayment";
import { peopleSearchProductKeys } from "../custom/peopleSearch";

class CommerceUtils {

  public getCommerceOfferDetails(
    commerceOffers: CommerceOffer[], 
    sequenceOptions: SequenceOptions, 
    override?: {sequence?: number},
  ): CommerceOfferDetail[] {
    let commerceOfferDetails = [];

    if (commerceOffers) {
      const sequence = override?.sequence ?? 0;

      commerceOffers.forEach((commerceOffer) => {
        let commerceProductDetails: CommerceProductDetail[] = [];

        commerceOffer.commerceProductDetails.forEach((commerceProductDetail) => {
          let commerceStockDetails: CommerceStockDetail[] = [];

          let commerceProduct = commerceOffer.getCommerceProduct(commerceProductDetail.commerceProductId);
          commerceProduct.commerceStockDetails.forEach((commerceStockDetail) => {
            let commerceStock = commerceProduct.getCommerceStock(commerceStockDetail.commerceStockId);
            let newCommerceStockDetail = objectUtils.clone(commerceStockDetail);
            newCommerceStockDetail.commerceStockRevisionId = commerceStock._id;
            commerceStockDetails.push(newCommerceStockDetail)
          });

          commerceProductDetails.push({
            commerceProductId: commerceProduct._id,
            commerceProductRevisionId: commerceProduct.currentModelRevisionId,
            commerceStockDetails: commerceStockDetails,
          });
        });

        commerceOfferDetails.push({
          commerceOfferId: commerceOffer._id,
          commerceOfferRevisionId: commerceOffer.currentModelRevisionId,
          commerceProductDetails: commerceProductDetails,
          price: commerceOffer.commercePrice.getTotalPrice(sequence, 0, sequenceOptions),
        });
      });
    }

    return commerceOfferDetails;
  }

  // limit check - if nameSearch of phoneSearch is, combined count check
  public canProcessCommerceOrder(commerceOrder: CommerceOrder, productKey) {
    if (productKey === peopleSearchProductKeys.peopleSearchProductKeys.nameSearch
        || productKey === peopleSearchProductKeys.peopleSearchProductKeys.phoneSearch) {
          let phoneSearchLimit = commerceOrder.tempClientSecured?.processes?.phoneSearch?.quantity ?? 0;
          let nameSearchLimit = commerceOrder.tempClientSecured?.processes?.nameSearch?.quantity ?? 0;
          let phoneUseSearchLimit = commerceOrder.tempClientSecured?.processes?.phoneSearch?.process ?? 0;
          let nameUseSearchLimit = commerceOrder.tempClientSecured?.processes?.nameSearch?.process ?? 0;

          if ((phoneSearchLimit + nameSearchLimit) <= (phoneUseSearchLimit+nameUseSearchLimit)) {
            return false;
          } else {
            return true;
          }
    } else {
      return !!commerceOrder.tempClientSecured.processes[productKey].canProcess;
    }
  }

  public canProcessSomeCommerceOrders(commerceOrders: CommerceOrder[], productKey) {
    let flag = false;
    commerceOrders.some((commerceOrder) => {
      if (this.canProcessCommerceOrder(commerceOrder, productKey)) {
        flag = true;
        return true;
      }
    });

    return flag;
  }

  public getUpdaterRecursive(objects) {
    return modelUtils.getFieldRecursive(objects, "updaterId");
  }

  isOkRequest(error) {
    return this.isResponseCode(error, 200);
  }


  isNotAcceptableRequestError(error) {
    return this.isResponseCode(error, 406);
  }

  isNotAuthorizedRequestError(error) {
    return this.isResponseCode(error, 401);
  }

  isResponseCode(error, code: number) {
    if (error && error.responseCode === code) {
      return true;
    } else {
      return false;
    }
  }

  createSequenceOptions(commerceOrder: CommerceOrder): SequenceOptions {
    const sequenceOptions: SequenceOptions = {
      thinMatch: commerceOrder?.meta?.thinMatch?.enabled === true,
      thinMatchDataProviderDown: commerceOrder?.meta?.thinMatch?.thinMatchType === thinMatchType.DataProviderDown,
      thinMatchTooManyResults: commerceOrder?.meta?.thinMatch?.thinMatchType === thinMatchType.TooManyResults,
      thinMatchNoResults: commerceOrder?.meta?.thinMatch?.thinMatchType === thinMatchType.NoResults,
      thinMatchGeographic: commerceOrder?.meta?.thinMatch?.thinMatchType === thinMatchType.Geographic,
    };

    return sequenceOptions;
  }

  /*
  Redundant code of craeteSquenceOptions, to merge createSequenceOptions and createSequenceOptionsByThinMatchObject,
  we need to test/QA both functions to make sure both working after merging
  */
  createSequenceOptionsByThinMatchObject(thinMatch): SequenceOptions {
    const sequenceOptions: SequenceOptions = {
      thinMatch: thinMatch?.enabled === true,
      thinMatchDataProviderDown: thinMatch?.thinMatchType === thinMatchType.DataProviderDown,
      thinMatchTooManyResults: thinMatch?.thinMatchType === thinMatchType.TooManyResults,
      thinMatchNoResults: thinMatch?.thinMatchType === thinMatchType.NoResults,
      thinMatchGeographic: thinMatch?.thinMatchType === thinMatchType.Geographic,
    };

    return sequenceOptions;
  }

  getOverridenSequence(commerceOffer: CommerceOffer, commerceObject: CommercePayment|CommerceOrder): { s: number, r: number } {
    const result:{
      s:number,
      r:number,
    } = {
      s: null,
      r: null,
    };

    result.s = commerceObject.sequence;
    result.r = commerceObject.retry;

    if (commerceOffer?.options?.sequence?.eval) {
      if (Number.isFinite(commerceOffer?.options?.sequence?.eval?.s)) {
        result.s += commerceOffer?.options?.sequence?.eval?.s;
      }
    }
    return result;

  }
}

export var commerceUtils = new CommerceUtils();

