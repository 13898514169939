import {LogUtils} from "../utils/logUtils";
import {timeUtils} from "../utils/timeUtils";

export class RequestEvent {
  req;
  param: any = {};
  sessionHash: string;
  ipAddress: string;
  startTimestamp: number;

  constructor(req?) {
    if (req) {
      Object.assign(this, req.body);
    }
  }

  logTimestamp(message) {
    LogUtils.info("RequestTimestamp", this.ipAddress, this.sessionHash, this.startTimestamp, timeUtils.getTimestamp() - this.startTimestamp, message);
  }
}
