import {Component, ElementRef, Input, OnDestroy} from "@angular/core";

@Component({
    selector: 'image-rotator',
    template: '<img [src]="src" (load)="load()" [ngStyle]="style"/>',
    standalone: false
})
export class ImageRotator implements OnDestroy {
  @Input() src: string;
  @Input() column: number;

  interval;
  perWidth: number;
  style = {position: "relative", visibility: "hidden"};
  count = 0;
  circleSize = 0;

  constructor(private el: ElementRef) {
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  load() {
    this.perWidth = this.el.nativeElement.offsetWidth / this.column;
    this.circleSize = this.perWidth / 2;
    this.rotate();
    this.style.visibility = "visible";

    setInterval(() => {
      this.rotate();
    }, 100);
  }

  rotate() {
    let index = this.count % this.column;
    this.style['clip-path'] = `circle(${this.circleSize}px at ${this.perWidth * index + 50}px)`;
    this.style['left'] = `-${this.perWidth * index}px`;
    this.count++;
  }
}
