import { HttpEvent, HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { NgZone, inject } from "@angular/core";
import { Observable } from "rxjs";

import { AdminCaptchaService, ClientCaptchaService } from '../services/captcha.service';

export function clientCaptchaInterceptor(
  req: HttpRequest<unknown>, 
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  const ngZone = inject(NgZone);
  
  const captchaService = inject(ClientCaptchaService);

  return ngZone.runOutsideAngular(
    () => captchaService.nextWithCaptcha(req, next),
  );
}

export function adminCaptchaInterceptor(
  req: HttpRequest<unknown>, 
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  const ngZone = inject(NgZone);
  
  const captchaService = inject(AdminCaptchaService);

  return ngZone.runOutsideAngular(
    () => captchaService.nextWithCaptcha(req, next),
  );
}
