import {BaseComponent} from "../../clientCommon/components/BaseComponent";
import {ServiceHelperService} from "../../clientCommon/services/serviceHelper.service";
import {ActivatedRoute} from "@angular/router";
import {CommerceContent} from "../../common/models/commerce/commerceContent";
import {LogUtils} from "../../common/utils/logUtils";
import {timeUtils} from "../../common/utils/timeUtils";
import {redirectHelper} from "../../clientCommon/helper/redirectHelper";
import {SearchedPerson} from "../../common/custom/models/peopleSearch/searchedPerson";
import {peopleSearchProductKeys} from "../../common/custom/peopleSearch/peopleSearchProductKeys";
import {customClientPaths} from "../../common/custom/customPathHelpers";

export class ProcessPeopleSearch extends BaseComponent {

  commerceContent: CommerceContent;
  selectedPerson: SearchedPerson;

  startTimestamp;
  fakeProgressors: any;
  timeout = 2000;
  duration = 120 * 1000;

  constructor(serviceHelperService: ServiceHelperService, activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
    this.page = BaseComponent.PAGE.process;
    this.fakeProgressors = this.serviceHelperService.progressorService.fakeProgressors;
  }


  onInit(): Promise<any> {
    this.startTimestamp = timeUtils.getTimestamp();
    this.executeProgress();
    return this.baseInit().then(() => {
      return this.serviceHelperService.peopleSearchService.getCommerceContent(this.uxHelper.uxComposite).then((commerceContent: CommerceContent) => {
        this.commerceContent = commerceContent;
        this.selectedPerson = this.serviceHelperService.peopleSearchService.findSelectedPerson(this.commerceContent);
        LogUtils.debug("SelectedPerson", this.selectedPerson);
      }).catch((e) => {
        LogUtils.error(e);
      });
    });
  }

  onDestroy() {
    this.serviceHelperService.progressorService.clearCurrentProgressors();
    return super.onDestroy();
  }

  initProgressor() {
    this.fakeProgressors = this.serviceHelperService.progressorService.fakeProgressors;
    this.fakeProgressors.current.setDuration(this.duration);
    this.fakeProgressors.current.setStartTimestamp(this.startTimestamp);
    let weights = Array.from(Array(10).keys()).map(n => n * n * n);
    this.fakeProgressors.current.setWeights(weights.reverse());

  }

  executeProgress() {
    this.initProgressor();
    return this.serviceHelperService.progressorService.executeProgress().then(() => {
    });
  }

  getCurrentLoadings() {
    return;
  }

  finishDuration(commerceContent: CommerceContent) {
    let progressor = this.fakeProgressors.current;
    progressor.startTimestamp = timeUtils.getTimestamp() - progressor.duration + this.timeout;
    setTimeout(() => {
      // Delay for progress shows 100%
      if (this.stillInThisPage()) {
        if (commerceContent.productKey === peopleSearchProductKeys.nameSearch) {
          redirectHelper.redirect(this.serviceHelperService, '/' + customClientPaths.memberNameSearchDetail + "/" + commerceContent._id + "/linear");
        } else if (commerceContent.productKey === peopleSearchProductKeys.phoneSearch) {
          redirectHelper.redirect(this.serviceHelperService, '/' + customClientPaths.memberPhoneSearchDetail + "/" + commerceContent._id + "/linear");
        }
      }
    }, this.timeout);
  }


}
