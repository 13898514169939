import { areaCode } from './../../../../../../../server/server/staticClass/areaCode';
import {Component, Input, OnChanges, OnInit} from "@angular/core";
import {UxHelper} from "../../../../../clientCommon/helper/uxHelper";
import {SignUpPeopleSearch} from "../../../../sales/peopleSearch/signUpPeopleSearchSales";

declare var $: any;

@Component({
  selector: 'legacy-signup-phone-search',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class LegacySignupPhoneSearchComponent implements OnInit, OnChanges {
  @Input() base: SignUpPeopleSearch;

  @Input() uxHelper: UxHelper;
  @Input() pageType: string;
  @Input() pageCategory: string;
  @Input() page: string;

  revealItems = [];
  accessItems = [];
  allowedItems = [];
  feedbacks = [];
  feeds = [];
  cityState = ""
  constructor() {
  }

  init() {

    this.cityState = this.base.commerceContent?.raws[0]?.meta?.cityState;
    if (this.base.testimonials) {
      setTimeout(function () {
        $('.carousel').carousel({
          interval: 6000,
          cycle: true
        });
        $('[data-toggle="popover"]').popover();

      }, 2000);
    }
  }

  ngOnInit() {

    this.feeds = this.uxHelper.uxComposite.getUxcomp('comp.sales.phone-search.signup.feedbacks.testimonials');
    return this.init();
  }

  ngOnChanges() {
    return this.init();
  }

  // Rev Phone No match flow check for split test
  isNoMatchEnabled():boolean {
    return this.base.uxComposite.getUxcomp('comp.reverse-phone.no-match.enabled');
  }


  getStarFilling(score: number) {
    let count = score;
    if (score > 5) {
      count = 5;
    }
    return new Array(count).fill(0);
  }

  getStarEmpty(score: number) {
    let count = 5 - score;
    if (count < 0) {
      count = 0;
    }
    return new Array(count).fill(0);
  }
}
