import { Component, Input, NgModule, OnChanges, OnInit } from '@angular/core';
import { UxHelper } from '../../../clientCommon/helper/uxHelper';
import { BaseDirective } from '../../../clientCommon/directives/BaseDirective';
import { ServiceHelperService } from '../../../clientCommon/services/serviceHelper.service';
import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Ad0ComponentModule } from './ad0.component';
import { Ad1ComponentModule } from './ad1.component';

declare var $: any;

@Component({
    selector: 'ad',
    templateUrl: './ad.component.html',
    standalone: false
})
export class AdComponent extends BaseDirective implements OnInit, OnChanges {
  @Input() uxHelper: UxHelper;
  @Input() uxcompKey;
  config;

  constructor(serviceHelperService: ServiceHelperService, activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
  }

  init() {
    return Promise.resolve()
      .then(() => {
        this.config = this.getConfig();
      })
      .then(() => {
        this.initDone = true;
      });
  }

  ngOnInit() {
    return this.baseInit().then(() => {
      return this.init();
    });
  }

  ngOnChanges() {
    return this.init();
  }

  getConfig() {
    return this.uxHelper.uxComposite.getUxcomp(this.uxcompKey + '.config');
  }

  shouldDisplayAd() {
    return !!this.getConfig().active;
  }
}

@NgModule({
  declarations: [AdComponent],
  imports: [CommonModule, Ad0ComponentModule, Ad1ComponentModule],
  exports: [AdComponent],
})
export class AdComponentModule {}
