import {Component, Input, OnChanges, OnInit} from "@angular/core";
import {UxHelper} from "../../../../../clientCommon/helper/uxHelper";
import {UxComposite} from "../../../../../common/models/ux/uxComposite";
import {SignUpPeopleSearch} from "../../../../sales/peopleSearch/signUpPeopleSearchSales";

declare var $: any;

@Component({
    selector: 'default-signup-phone-search',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss'],
    standalone: false
})
export class DefaultSignupPhoneSearchComponent implements OnInit, OnChanges {
  @Input() base: SignUpPeopleSearch;

  @Input() uxHelper: UxHelper;
  @Input() pageType: string;
  @Input() pageCategory: string;
  @Input() page: string;

  revealItems = [];
  accessItems = [];
  allowedItems = [];
  feedbacks = [];

  constructor() {
  }

  init() {
    if (this.base.testimonials) {
      setTimeout(function () {
        $('.carousel').carousel({
          interval: 6000,
          cycle: true
        });
        $('[data-toggle="popover"]').popover();

      }, 2000);
    }
  }

  ngOnInit() {
    return this.init();
  }

  ngOnChanges() {
    return this.init();
  }

  getStarFilling(score: number) {
    let count = score;
    if (score > 5) {
      count = 5;
    }
    return new Array(count).fill(0);
  }

  getStarEmpty(score: number) {
    let count = 5 - score;
    if (count < 0) {
      count = 0;
    }
    return new Array(count).fill(0);
  }
}
