import {isObject} from "util";

export class ArrayUtils {
  static diff(a1: any[], a2: any[]): any[] {
    return this.shortList(a1, a2).concat(this.surplusList(a1, a2));
  }

  static surplusList(source: any[], target: any[]): any[] {
    return target.filter((x) => {
      return (source.indexOf(x) == -1);
    });
  }

  static shortList(source: any[], target: any[]): any[] {
    return this.surplusList(target, source);
  }

  static isDiff(a1: any[], a2: any[]): boolean {
    return this.diff(a1, a2).length > 0;
  }

  static orderChange(repo: any[], targetObject, destinationObject): any[] {
    var targetIndex = repo.indexOf(targetObject);
    var destinationIndex = repo.indexOf(destinationObject);

    var target = repo.splice(targetIndex, 1)[0];
    if (destinationIndex < 0) {
      // last case
      repo.push(target);
    } else {
      if (destinationIndex > targetIndex) {
        destinationIndex -= 1;
      }
      repo.splice(destinationIndex, 0, targetObject);
    }

    return repo;
  }

  static remove(references: any, target: any): void {
    var index = references.indexOf(target);
    if (index > -1) {
      references.splice(index, 1);
    }
  }

  static toArray(obj: Object): { key: string, value: any }[] {
    var arr = [];
    if (obj && isObject(obj)) {
      Object.keys(obj).forEach(function (key) {
        arr.push({key: key, value: obj[key]});
      });
    }

    return arr;
  }

  static removeEmpty(target: any[]) {
    var removeIndex;

    do {
      removeIndex = null;

      target.some((value, index) => {
        if (!value) {
          removeIndex = index;
          return true;
        }
      });

      if (removeIndex !== null) {
        target.splice(removeIndex, 1);
      }
    } while (removeIndex !== null);
  }

  static unique(target: any[]) {
    return Array.from(new Set(target));
  }

  static flatten(array) {
    return [].concat.apply([], array);
  }

  static chunkArray(array: any[], chunkSize: number) {
    const length = array?.length;

    if (!length || chunkSize <= 0) {
      return array;
    }

    const chunks = [];

    for (let i = 0; i < length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);

      chunks.push(chunk);
    }

    return chunks;
  }
}

