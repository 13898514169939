import { Component, Input, OnInit, Output, EventEmitter, NgModule } from '@angular/core';
import { BaseComponent } from '../../../../../../../clientCommon/components/BaseComponent';
import { UxComposite } from '../../../../../../../common/models/ux/uxComposite';
import { LogUtils } from '../../../../../../../common/utils/logUtils';
import { NoteService, UxcService } from '../../../../../../../clientCommon/services';
import { ServiceHelperService } from '../../../../../../../clientCommon/services/serviceHelper.service';
import { CommonModule } from '@angular/common';
import { UiSwitchModule } from 'ngx-ui-switch';
import { FormsModule } from '@angular/forms';

declare var $: any;

@Component({
    selector: 'app-modal-add-note',
    templateUrl: 'addNote.component.html',
    styleUrls: ['addNote.component.scss'],
    standalone: false
})
export class AddNoteComponent implements OnInit {
  @Input() isPrivate: boolean = true;
  @Input() externalId: string = '';
  @Input() csrPhone: string = '';
  @Input() uxComposite: UxComposite;
  @Input() referenceId: string = '';
  @Output() noteModified = new EventEmitter<any>();
  private = false;
  invalidNote = false;
  noteContent = '';

  constructor(
    private noteService: NoteService,
    private serviceHelperService: ServiceHelperService,
    private uxService: UxcService
  ) {}

  ngOnInit() {}

  goNext() {}

  checkNote() {
    if (this.noteContent.length) {
      this.invalidNote = false;
    } else {
      this.invalidNote = true;
    }
  }

  addNote() {
    let brandId = this.uxComposite.brandId;
    if (!this.noteContent && !brandId && !this.referenceId) {
      this.invalidNote = true;
      return;
    }
    $('#add-note-modal').modal('hide');
    return Promise.resolve(true).then(() => {
      const userId = this.serviceHelperService.authenticationService.getUserId();
      return this.noteService
        .createNote(this.noteContent, this.private, this.externalId, userId, brandId, this.referenceId)
        .then(() => {
          this.noteModified.emit();
          this.noteContent = '';
          this.isPrivate = false;
          this.serviceHelperService.trackingService.report({
            page: BaseComponent.PAGE.detail,
            pageType: BaseComponent.PAGE_TYPE.member,
            type: 'notes',
            action: 'submit',
            private: this.private,
          });
          if (this.private) {
            $('#success-private-note-modal').modal('show');
          } else {
            $('#success-public-note-modal').modal('show');
          }
        })
        .catch((err) => {
          LogUtils.error(err);
        });
    });
  }

  reload() {
    window.location.reload();
  }
}

@NgModule({
  declarations: [AddNoteComponent],
  imports: [CommonModule, FormsModule, UiSwitchModule],
  exports: [AddNoteComponent],
})
export class AddNoteComponentModule {}
