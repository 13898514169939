import { AfterContentInit, Component, ElementRef, Input, NgModule, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { AddressField, AddressUtils } from '@common/utils/addressUtils';
import { UxComp, PaperFormat } from '@ux5.2/types';

@Component({
  selector: 'ux-5-2-animated-searching',
  templateUrl: './animated-searching.component.html',
  styleUrls: ['./animated-searching.component.scss']
})
export class AnimatedSearchingComponent implements OnInit, AfterContentInit {
  @Input() userName: string = null;
  @Input() location: string = null;
  @Input() county: string = null;
  @Input() time: number = null;
  @Input() title: UxComp;
  @Input() starContent: UxComp;
  @Input() listTitle: UxComp;
  @Input() list: UxComp;
  @Input() starsClass: string[] = ['br10'];

  addressField: AddressField = AddressUtils.getAddressField("US");

  @ViewChildren('status') status: QueryList<ElementRef>;

  format = PaperFormat;

  constructor(private viewportScroller: ViewportScroller) { }

  ngOnInit(): void {
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  ngAfterContentInit(): void {
    this.checkStatus();
    this.location = this.addressField.getAdministrativeAreaOptionsArray().find(value => value.code == this.location)?.name;
  }

  checkStatus() {
    let elementList = [];

    const interval = setInterval(() => {
      let random;

      if (elementList.length > 12) {
        clearInterval(interval)
      }

      do {
        if (elementList.length > 12) {
          break;
        }
        random = Math.floor(Math.random() * 13);
      } while (elementList.includes(random));

      elementList.push(random)

      this.status.map((element: ElementRef<HTMLSpanElement>, index) => {
        if (random == index) {
          element.nativeElement.classList.replace('spin', 'checkmark')
        }
      })
    }, (this.time * 1000)/6)

  }
}
