// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-label {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 230px;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.icon {
  width: 1.25rem;
  height: 1.25rem;
}

.label {
  color: #000000;
  font-size: 1rem;
  line-height: 0.8625rem;
  font-weight: 400;
  text-align: center;
  margin-bottom: 0;
  overflow: hidden;
}

@media (max-width: 820px) {
  .icon-label {
    padding: 0.625rem;
  }
  .icon {
    width: 0.75rem;
    height: 0.75rem;
  }
  .label {
    font-size: 0.625rem;
  }
}
@media (max-width: 500px) {
  .icon-label {
    width: 140px;
    padding: 0.625rem;
  }
  .icon {
    width: 1.1rem;
    height: 1.1rem;
  }
  .label {
    font-size: 12px;
    overflow: visible;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/themes/2234200bd3cc36ba97274ecbdc6c6a47/common/icon-label/icon-label.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qBAAA;EACA,mBAAA;EACA,YAAA;EACA,mBAAA;EACA,sBAAA;AACF;;AACA;EACE,cAAA;EACA,eAAA;AAEF;;AACA;EACE,cAAA;EACA,eAAA;EACA,sBAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;EACA,gBAAA;AAEF;;AACA;EACE;IACE,iBAAA;EAEF;EAAA;IACE,cAAA;IACA,eAAA;EAEF;EAAA;IACE,mBAAA;EAEF;AACF;AACA;EACE;IACE,YAAA;IACA,iBAAA;EACF;EACA;IACE,aAAA;IACA,cAAA;EACF;EACA;IACE,eAAA;IACA,iBAAA;EACF;AACF","sourcesContent":[".icon-label {\n  display: flex;\n  justify-content: left;\n  align-items: center;\n  width: 230px;\n  padding-top: 0.8rem;\n  padding-bottom: 0.8rem;\n}\n.icon {\n  width: 1.25rem;\n  height: 1.25rem;\n}\n\n.label {\n  color: #000000;\n  font-size: 1rem;\n  line-height: 0.8625rem;\n  font-weight: 400;\n  text-align: center;\n  margin-bottom: 0;\n  overflow: hidden;\n}\n\n@media (max-width: 820px) {\n  .icon-label {\n    padding: 0.625rem;\n  }\n  .icon {\n    width: 0.75rem;\n    height: 0.75rem;\n  }\n  .label {\n    font-size: 0.625rem;\n  }\n}\n\n@media (max-width: 500px) {\n  .icon-label {\n    width: 140px;\n    padding: 0.625rem;\n  }\n  .icon {\n    width: 1.1rem;\n    height: 1.1rem;\n  }\n  .label {\n    font-size: 12px;\n    overflow: visible;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
