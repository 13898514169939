import {BaseComponent} from "../../clientCommon/components/BaseComponent";
import {AddressField, AddressUtils} from "../../common/utils/addressUtils";
import {ServiceHelperService} from "../../clientCommon/services/serviceHelper.service";
import {ActivatedRoute} from "@angular/router";
import {parameterdUtils} from "../../clientCommon/utils/parameterUtils";
import {LogUtils} from "../../common/utils/logUtils";
import {progressorHelper} from "../../common/helpers/progressorHelper";
import {timeUtils} from "../../common/utils/timeUtils";
import {objectUtils} from "../../common/utils/objectUtils";
import {CommerceContent} from "../../common/models/commerce/commerceContent";
import {phoneUtils} from "../../common/utils/phoneUtils";
import {peopleSearchProductKeys} from "../../common/custom/peopleSearch/peopleSearchProductKeys";
import {customClientPaths} from "../../common/custom/customPathHelpers";
import {SearchedPerson} from "../../common/custom/models/peopleSearch/searchedPerson";
import { HeadlessHelper } from "../helper/headlessHelper";
import { stringUtils } from "../../common/utils/stringUtils";
declare var $: any;

export class LandingPeopleSearch extends BaseComponent {

  addressField: AddressField = AddressUtils.getAddressField("US");
  showRefineModal = false;
  feedBackFlag = false;
  landingLBShown = "landingLBShown";
  progressorDone = "progressorDone";
  progressorDoneFlag = false;
  lightboxFlag = true;

  fakeProgressors: any;
  counter = 0;
  counterInterval = null;
  delay = 0;
  phoneUtils = phoneUtils;
  stringUtils = stringUtils;

  constructor(serviceHelperService: ServiceHelperService,
              activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
    this.page = BaseComponent.PAGE.landing;
  }

  onInit(): Promise<any> {
    this.addressField = new AddressField(objectUtils.clone(this.addressField.data));
    // this.addressField.addAdministrativeAreaOption({all: "Nationwide"});
    this.addressField.addAdministrativeAreaOption({all: "All States"});
    this.addressField.sortAdministrativeAreaOptionsArray();

    this.executeCounter();
    this.fakeProgressors = this.serviceHelperService.progressorService.fakeProgressors;
    return this.baseInit().then(() => {
      this.clearThinMatchOnNewSearch();
      this.preSaleTrackingPixel();

      if (!this.userInput.state) {
        if (this.uxComposite.requestState) {
          this.userInput.state = this.uxComposite.requestState;
        } else {
          this.userInput.state = "all";
        }
      }

      this.initProgressor();
      this.addModalTracking();
      this.setDelay();

      let params = parameterdUtils.getAllParams();
      let productkey=this.getProductKeyByPageCategory();

      this.updateHeadlessParams(params);
      this.updateUserInputFromParams(params);
      this.updateCountyFromParams(params);
      this.updateCallRemarketing(params);

      //Adding params check to differentiate between phone skip and name skip
      if (params && params.skip === "true" && this.isSkippable()&&productkey==peopleSearchProductKeys.nameSearch) {
        if (this.userInput.fname && this.userInput.lname) {
          if (this.userInput.extId && this.userInput.extId !== 'undefined' && this.userInput.extId !== 'null') {
            let person = new SearchedPerson();
            person.lName = this.userInput.lname;
            person.fName = this.userInput.fname;
            person.extId = this.userInput.extId;
            this.serviceHelperService.peopleSearchService.setSearchPersonToSearch(this.serviceHelperService, person, true);
            return this.next(false);
          } else {
            this.update();
            return this.next(true);
          }
        } else {
          const inputTrackingSearch = this.serviceHelperService.inputTrackingService.getValue()?.search;
          if (inputTrackingSearch) {
            Object.keys(inputTrackingSearch).forEach((key) => {
              this.userInput[key] = inputTrackingSearch[key];
            });
          }
          return this.next(false); // session transfer case.
        }
      }

      if (this.hasLandingLightBox() && (!this.serviceHelperService.storageService.getTemp(this.landingLBShown))) {
        setTimeout(() => {
          this.serviceHelperService.storageService.setTemp(this.landingLBShown, true);
          $('#landing-lightbox').modal('show');
          this.serviceHelperService.trackingService.report({type: "show", id: "landing-lightbox"});
        }, 0);
      } else {
        this.lightboxFlag = false;
        this.executeProgress();
      }
    });
  }

  clearThinMatchOnNewSearch() {
    this.serviceHelperService.storageService.removeSession('thinMatchEnabled');
    this.serviceHelperService.storageService.removeSession('thinMatchType');
    // sessionStorage.removeItem('thinMatchEnabled');
    // sessionStorage.removeItem('thinMatchType');
  }

  addModalTracking() {
    setTimeout(() => {
      $('#landing-lightbox').on('hidden.bs.modal', () => {
        this.executeProgress();
        this.lightboxFlag = false;
      });

      $('#sample-report').on('hidden.bs.modal', () => {
        this.serviceHelperService.trackingService.report({type: "close", id: "sample-report-lightbox"});
      });

    }, 0);
  }

  onDestroy() {
    this.serviceHelperService.progressorService.clearCurrentProgressors();
    if (this.counterInterval) {
      clearInterval(this.counterInterval);
      this.counterInterval = null;
    }
    return super.onDestroy();
  }

  setDelay() {
    let key = `comp.${this.pageType}.${this.pageCategory}.${BaseComponent.PAGE.landing}`;
    let progresses = progressorHelper.getProgresses(this.uxComposite, [key]);
    if (progresses[0] && progresses[0].duration) {
      // Delay disabled if we have progressor
    } else {
      let delay = this.uxHelper.getUxcomp("delay");
      if (delay) {
        this.delay = delay;
      }
    }
  }

  initProgressor() {
    let key = `comp.${this.pageType}.${this.pageCategory}.${BaseComponent.PAGE.landing}`;
    let progresses = progressorHelper.getProgresses(this.uxComposite, [key]);
    if (progresses[0]) {
      this.fakeProgressors.current.name = progresses[0].name;
      if (progresses[0].duration) {
        this.fakeProgressors.current.setDuration(progresses[0].duration);
        if (progresses[0].timeout) {
          this.fakeProgressors.timeout.setDuration(progresses[0].timeout);
        }
      } else {
        this.progressorDoneFlag = true;
      }
    } else {
      this.progressorDoneFlag = true;
    }
  }

  isSkippable() {
    const inputTrackingSearch = this.serviceHelperService.inputTrackingService.getValue()?.search;

    if (this.userInput.fname && this.userInput.lname) {
      return true;
    } else if (this.serviceHelperService.peopleSearchService.hasCommerceContent()) {
      return true;
    } else if (inputTrackingSearch?.firstName && inputTrackingSearch?.lastName) {
      return true;
    }

    return false;
  }


  update() {
    this.serviceHelperService.userInputService.storeUserInput();
    this.serviceHelperService.userInfoService.storeUserInfo();
    this.serviceHelperService.inputTrackingService.storeSearch(this.userInput);
  }

  submit() {
    this.feedBackFlag = true;
    if (this.isValid()) {
      if (this.userInput?.fname?.length > 0) {
        this.userInput.fname = this.userInput.fname.trim();
      }
      if (this.userInput?.lname?.length > 0) {
        this.userInput.lname = this.userInput.lname.trim();
      }
      return this.next(true);
    }
  }

  next(clear) {
    if (clear) {
      this.serviceHelperService.userInputService.storeUserInput();
      this.serviceHelperService.peopleSearchService.clear();
    }
    return this.goNextPage();
  }

  isValid() {
    return !!(
      (this.userInput.fname && this.userInput.lname) ||
      (this.phoneUtils.validDigits(this.userInput.phone))
    );
  }

  trackEvent($event) {
    let param: any = {
      validation: this.isValid(),
      validations: {
        fname: !!this.userInput.fname,
        lname: !!this.userInput.lname,
        state: !!this.userInput.state,
      },
      value: null,
    };
    super.trackEvent($event, param);
  }

  executeProgress() {
    if ((!this.serviceHelperService.storageService.getTemp(this.progressorDone))) {
      this.serviceHelperService.trackingService.report({type: "progressor", id: "landing", action: "start"});
      this.serviceHelperService.progressorService.executeProgress().then(() => {
      }).catch((e) => {
        LogUtils.error(e);
      }).then(() => {
        this.progressorDoneFlag = true;
        if (!this.uxComposite.isDevicePhone()) {
          this.autofocus();
        }
        this.serviceHelperService.storageService.setTemp(this.progressorDone, true);
        this.serviceHelperService.trackingService.report({type: "progressor", id: "landing", action: "end"});
      });
    } else {
      this.progressorDoneFlag = true;
      if (!this.uxComposite.isDevicePhone()) {
        this.autofocus();
      }
    }
  }

  autofocus() {
    setTimeout(() => {
      if (this.inputElements.firstName) {
        this.inputElements.firstName.nativeElement.focus();
      }
    }, 0);
  }

  hasLandingLightBox() {
    return !!this.uxHelper.getValue('comp.landing.lightbox.enabled');
  }

  executeCounter() {
    this.counter = this.calculateCounter();
    if (!this.counterInterval) {
      this.counterInterval = setInterval(() => {
        this.counter = this.calculateCounter();
      }, 2300);
    }

  }

  calculateCounter() {
    let timestamp = timeUtils.getTimestamp();
    if (this.uxComposite) {
      timestamp -= this.uxComposite.getTimeDiff();
    }

    let yearStart = new Date();
    yearStart.setMonth(0);
    yearStart.setDate(1);
    yearStart.setHours(0);
    yearStart.setMinutes(0);
    yearStart.setSeconds(0);
    yearStart.setMilliseconds(0);

    timestamp -= yearStart.getTime();

    return Math.round(timestamp / 40000 * 37);
  }

  getContentUrl(commerceContent: CommerceContent) {
    let url = "/";

    if (commerceContent.productKey === peopleSearchProductKeys.nameSearch) {
      url += customClientPaths.memberNameSearchDetail;
    } else if (commerceContent.productKey === peopleSearchProductKeys.phoneSearch) {
      url += customClientPaths.memberPhoneSearchDetail;
    }

    url += '/' + commerceContent._id;

    return url;
  }

  formatString(value, key) {
    // const replaced = value.replace(/[0-9]/g, '');
    const replaced = value;
    if (key === 'fname') {
      this.userInput.fname = replaced;
    } else if (key === 'lname') {
      this.userInput.lname = replaced;
    }
    return replaced;
  }

  formatPhoneNumber(event) {
    const value = event.target.value;
    const replaced = this.phoneUtils.formatPhoneNumber(value);
    this.userInput.phone = replaced;
    event.target.value = replaced;
  }

  preSaleTrackingPixel() {
    let requestParams: any = {
      refer: this.serviceHelperService.referService.getRefer()
    };
    this.serviceHelperService.trackingService.preSaleTrackingPixel(requestParams).then((result) => {
      LogUtils.debug("preSaleTrackingPixel result", result);

    }).catch((err) => {
      LogUtils.error("preSaleTrackingPixel error", err);
    });
  }

  private updateHeadlessParams(params) {
    if (!HeadlessHelper.inIframe() 
        || this.uxComposite.getUxcomp(HeadlessHelper.enabledCompKey) !== true) {
      return;
    }

    HeadlessHelper.storeRedirectorParams(params);
  }

  private updateUserInputFromParams(params) {
    if (HeadlessHelper.inIframe()
        || this.uxComposite.getUxcomp(HeadlessHelper.enabledCompKey) !== true) {
      return;
    }

    // Dev#1462 Added logic for accessing via iframe. 
    // (Not an action within an iframe) 
    // For skiping SRP and correcting SearchedPerson on signup page, 
    // it must be set as follows.

    this.updateUserInput(params);

    if (params?.utm_refer_fwpage?.search('name/search-result') !== -1) {
      this.userInput.from = 'sales.name-search.search-result';
    }

    this.update();
  }

  private updateCallRemarketing(params) {
    if (!params.callRemarketing) {
      return;
    }

    this.updateUserInput(params);

    if (params?.user_email) {
      // If it's a space, replace it with a + symbol.
      // If you move through ClickTrackingComponent (mail link click), 
      // the + symbol in the email is converted to a space when the url is decoded.
      this.serviceHelperService.userInfoService.userInfo.email = params?.user_email.replace(' ', '+');
    }

    if (params?.user_fname) {
      this.serviceHelperService.userInfoService.userInfo.fname = params?.user_fname;
    }

    if (params?.user_lname) {
      this.serviceHelperService.userInfoService.userInfo.lname = params?.user_lname;
    }

    if (params?.user_phone) {
      this.serviceHelperService.userInfoService.userInfo.phone = phoneUtils.formatPhoneNumber(params?.user_phone);
    }

    this.update();

    const productkey = this.getProductKeyByPageCategory();

    if (productkey === peopleSearchProductKeys.phoneSearch) {
      if (params?.phone) {
        return this.next(false);
      } else {
        window.location.replace(customClientPaths.salesPhoneSearchSignup);
      }
    } else {
      if (!params?.fname || !params?.lname) {
        window.location.replace(customClientPaths.salesNameSearchSignup);
      }
    }
  }

  private updateUserInput(params) {
    if (params?.fname) {
      this.userInput.fname = params.fname;
    }

    if (params?.lname) {
      this.userInput.lname = params.lname;
    }

    if (params?.state) {
      this.userInput.state = params.state;
    }

    if (params?.phone) {
      this.userInput.phone = params.phone;
    }

    if (params?.extId) {
      this.userInput.extId = params.extId;
    }
  }

  private updateCountyFromParams(params) {
    if (!params?.county) {
      this.uxComposite.setCode('lp_county', '');
    } else {
      this.uxComposite.setCode('lp_county', params.county);
    }
  }

  // filterInput(event) {
  //   const inputElement = event.target as HTMLInputElement;
  //   inputElement.value = inputElement.value.replace(/[^a-zA-Z0-9_\-\s]/g, '');
  //   event.target.value = inputElement.value;
  // }
}

