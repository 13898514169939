class ViewUtils {
/**
 * sets the html and body from current document to body
 * sets the current body and html height to height 
 * @returns the current height
 */
  getDocumentHeight() {
    let body = document.body, html = document.documentElement;

    let height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

    return height;
  }
/**
 * @returns the current window innerHeight 
 */
  getScreenHeight() {
    return window.innerHeight;
  }
/**
 * @returns the current pageVOffset from window
 */
  getScrollHeight() {
    return window.pageYOffset;
  }
/**
 * gets the scrolloffset documentheight and scrrenheight and sets it to local vars
 * @returns the current scrolloffset ratio
 */
  getScrollOffsetRatio() {
    let scrollOffset = this.getScrollHeight();
    let documentHeight = this.getDocumentHeight();
    let screenHeight = this.getScreenHeight();

    return scrollOffset / (documentHeight - screenHeight);
  }
}

export const viewUtils = new ViewUtils();
