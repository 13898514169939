import {collectionClassHelper} from "../decorators/database/collectionClass";
import {modelFactory} from "../factory/modelFactory";
import {User} from "../models/user/user";
import {permissionUtils} from "../utils/permissionUtils";
import {isFunction} from "util";
import {LogUtils} from "../utils/logUtils";
import {objectUtils} from "../utils/objectUtils";

export class ResponseEvent {
  static REASON = {
    activeMember: "activeMember",
    noValidPayment: "noValidPayment",
    paymentRejected: "paymentRejected",
    paymentRejectedSuperbad: "paymentRejectedSuperbad",
    paymentRejectedDupCC: "paymentRejectedDupCC",
    paymentRejectedInsufficientFunds: "paymentRejectedInsufficientFunds",


    notAuthorized: "Not authorized",
    notValidAccount: "Not valid account",
    userUpdatePasswordNotMatch: "Password does not match",

    authenticationUserNotFound: "This email address has not been registered with ",
    authenticationPasswordErorr: "Invalid password. Please enter the correct password",
    authenticationRecaptchaTokenError: "Invalid Recaptcha Token",

    timesheetUpdateInvalidation: "Timesheet timestamps data is invalid. Timessheet clock_in or clock_out are possibly overlaping with prev or next timesheet."
  };

  static STATUS = {
    success: "success",
    fail: "fail",
  };

  user: User;
  status: string;
  reason: string;
  reasonObj: any = {};
  docs: { collectionName: string, obj: object }[] = [];
  rawResponse;
  errorMessage;
  data: any = {};
  responseCode;

  constructor(doc?) {
    if (doc && doc.error && doc.error.status) {
      Object.assign(this, doc.error);
      this.errorMessage = doc;
      this.responseCode = this.getResponseCode();
    } else {
      Object.assign(this, doc);
    }
  }

  setUser(user: User) {
    this.user = user;
  }

  permissionedDocs() {

    if (this.user) {
      if (!this.user.hasRoles([User.ROLES.admin])) {
        if (this.docs) {
          this.docs.forEach((doc) => {
            permissionUtils.removeValues(this.user, doc.obj, doc.collectionName);
          });
        }
      }
    }

    return this.docs;
  }

  addObject(obj, secure?: boolean, removeDrafts?: boolean) {
    let collectionName = collectionClassHelper.getCollectionName(obj);

    let shapedObj;
    if (secure !== false && isFunction(obj.getSecuredDoc)) {
      shapedObj = obj.getSecuredDoc();
    } else if (isFunction(obj.getClientDoc)) {
      shapedObj = obj.getClientDoc();
    } else {
      shapedObj = obj;
    }

    if (removeDrafts) {
      this.removeDrafts(shapedObj, 10);
    }
    this.docs.push({collectionName: collectionName, obj: shapedObj});
  }

  addObjects(objs: any[], secure?: boolean, removeDrafts?: boolean) {
    if (objs && objs.length > 0) {
      objs.forEach((obj) => {
        this.addObject(obj, secure, removeDrafts);
      });
    }
  }

  removeDrafts(obj, limit: number) {
    if (limit > 0 && obj && objectUtils.isObject(obj)) {
      Object.keys(obj).forEach((key) => {
        if (key === "draft") {
          delete obj[key];
        } else if (objectUtils.isObject(obj[key])) {
          this.removeDrafts(obj[key], limit - 1);
        }
      });
    }
  }

  setSuccess() {
    this.status = ResponseEvent.STATUS.success;
  }

  setFail() {
    this.status = ResponseEvent.STATUS.fail;
  }

  isSuccess(): boolean {
    return ResponseEvent.STATUS.success === this.status;
  }

  getDocs() {
    let docs = [];
    this.docs.forEach((doc) => {
      docs.push(modelFactory.factory(doc.collectionName, doc.obj));
    });
    return docs;
  }

  getSingleDoc() {
    let docs = this.getDocs();
    if (docs) {
      return docs[0];
    }
  }

  getResponse() {
    try {
      return {
        status: this.status,
        reason: this.reason,
        reasonObj: this.reasonObj,
        docs: this.permissionedDocs(),
        rawResponse: this.rawResponse,
        data: this.data,
      };
    } catch (e) {
      LogUtils.error(e);
    }
  }

  setData(data: any) {
    this.data = {...data}
  }

  private getResponseCode() {
    if (this.errorMessage) {
      return this.errorMessage.status;
    }
  }
}
