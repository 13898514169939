export class PersonDetailComponentConfig {
  main = true;
  lhs = true;
  derivedPerson = true;
  headline = true;
  lightHeadline = false;
  viewReport = true;

  //////////////////////////
  // Bio Section
  bioSection = true;

  //////////////////////////
  // Social Network Section
  socialNetworkSection = true;
  socialPhotoSubSection = true;
  otherSocialNetworkSubSection = true;

  //////////////////////////
  // Personal Section
  personalSection = true;
  personalSubSection = true;
  phoneSubSection = true;
  educationsSubSection = true;
  workExperiencesSubSection = true;
  lastKnownAddressSubSection = true;
  akasSubSection = true;
  contactsSubSection = true;
  birthRecordsSubSection = true;
  deathRecordsSubSection = true;
  associatesSubSection = true;
  relativesSubSection = true;
  marriageRecordsSubSection = true;
  divorceRecordsSubSection = true;

  //////////////////////////
  // Consumer Profile Section
  consumerProfileSection = true;
  consumerProfileSubSection = true;
  householdSubSection = true;
  financeSubSection = true;

  //////////////////////////
  // Property Section
  propertySection = true;
  propertySubSection = true;
  addressSubSection = true;
  addressSubSectionNeighborhood = true;

  //////////////////////////
  // Eviction Section
  evictionSection = true;
  evictionSubSection = true;

  //////////////////////////
  // Foreclosure Section
  foreclosureSection = true;
  foreclosureSubSection = true;

  //////////////////////////
  // Criminal Section
  criminalSection = true;
  criminalRecordsSubSection = true;
  trafficViolationSubSection = true;

  //////////////////////////
  // Court Section
  courtSection = true;
  bankruptciesSubSection = true;
  liensSubSection = true;
  judgmentsSubSection = true;

  //////////////////////////
  // Business Section
  businessSection = true;
  businessSubSection = true;
  fbnSubSection = true;
  feinSubSection = true;
  domainSubSection = true;

  //////////////////////////
  // License Section
  licenseSection = true;
  professionalSubSection = true;

  //////////////////////////
  // Horoscope Section
  horoscopeSection = true;
  horoscopeSubSection = true;

  tooltip = {
    //////////////////////////
    // Personal Section
    bioSection: "",

    //////////////////////////
    // Social Network Section
    socialNetworkSection: "",
    socialPhotoSubSection: "",
    otherSocialNetworkSubSection: "",

    //////////////////////////
    // Personal Section
    personalSection: "",
    personalSubSection: "",
    phoneSubSection: "",
    educationsSubSection: "",
    workExperiencesSubSection: "",
    lastKnownAddressSubSection: "",
    akasSubSection: "",
    contactsSubSection: "",
    birthRecordsSubSection: "",
    deathRecordsSubSection: "",
    associatesSubSection: "",
    relativesSubSection: "",
    marriageRecordsSubSection: "",
    divorceRecordsSubSection: "",

    //////////////////////////
    // Consumer Profile Section
    consumerProfileSection: "",
    consumerProfileSubSection: "",
    householdSubSection: "",
    financeSubSection: "",

    //////////////////////////
    // Property Section
    propertySection: "",
    propertySubSection: "",
    addressSubSection: "",
    addressSubSectionNeighborhood: "",

    //////////////////////////
    // Eviction Section
    evictionSection: "",
    evictionSubSection: "",

    //////////////////////////
    // Foreclosure Section
    foreclosureSection: "",
    foreclosureSubSection: "",

    //////////////////////////
    // Criminal Section
    criminalSection: "",
    criminalRecordsSubSection: "",
    trafficViolationSubSection: "",

    //////////////////////////
    // Court Section
    courtSection: "",
    bankruptciesSubSection: "",
    liensSubSection: "",
    judgmentsSubSection: "",

    //////////////////////////
    // Business Section
    businessSection: "",
    businessSubSection: "",
    fbnSubSection: "",
    feinSubSection: "",
    domainSubSection: "",

    //////////////////////////
    // License Section
    licenseSection: "",
    professionalSubSection: "",

    //////////////////////////
    // Horoscope Section
    horoscopeSection: "",
    horoscopeSubSection: "",
  }
}

