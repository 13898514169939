import {urlUtils} from "../utils/urlUtils";
import {ServiceHelperService} from "../services/serviceHelper.service";
import {LogUtils} from "../../common/utils/logUtils";

class RedirectHelper {
  /**
   * This helper function will be used through out the app to redirect to certain url + it track the redirection event and  start spinner
   * @param serviceHelperService will provide access to different required services such as commerceService, uxc service etc.
   * @param url to be redirected toward
   * @param options options for navigation url
   */
  redirect(serviceHelperService: ServiceHelperService, url, options?): Promise<any> {
    // track rediection event
    LogUtils.debug("RedirectHelper.redirect", url);
    serviceHelperService.trackingService.trackEvent();
    if (urlUtils.isAbsoluteUrl(url)) {
      serviceHelperService.spinnerService.spin();
      window.location.href = url;
      return Promise.resolve();
    } else {
      return serviceHelperService.router.navigateByUrl(url, options).then((flag) => {
        // if there is a flag that current window is already scrolled, after the redirection to new route, it will scroll to page start
        if (flag) {
          window.scrollTo(0, 0);
        }
      });
    }
  }
}

export var redirectHelper = new RedirectHelper();
