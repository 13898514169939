import {Injectable} from "@angular/core";
import {PeopleSearchService} from "../services/peopleSearch.service";

@Injectable()
export class CustomServiceHelperService {
  // instantiate an object peopleSearchService class
  constructor(
    public peopleSearchService?: PeopleSearchService, 
  ) {}

  /**
   * assigning peopleSearchService instance
   * @param customServiceHelperService provided from ServiceHelperService
   */
  setCustomServices(customServiceHelperService: CustomServiceHelperService) {
    this.peopleSearchService = customServiceHelperService.peopleSearchService;
  }
}
