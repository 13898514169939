const iconBasePath = 'assets/careers-page/icons';

export const ICONS = [
  `${iconBasePath}/remote_work.svg`,
  `${iconBasePath}/insurance.svg`,
  `${iconBasePath}/retirement.svg`,
  `${iconBasePath}/sick_time.svg`,
  `${iconBasePath}/training.svg`,
  `${iconBasePath}/growth.svg`,
];
