import { Component, OnDestroy, OnInit, Input, EventEmitter, Output, NgModule } from '@angular/core';
import { ServiceHelperService } from '../../../../../clientCommon/services/serviceHelper.service';
import { SearchedPerson } from '../../../../../common/custom/models/peopleSearch/searchedPerson';
import { CommonModule } from '@angular/common';
import { MemberUpsellPaymentModalPartialModule } from '@member/components/paymentModalPartial/paymentModalPartial.component';
import { LoaderUpsellComponentModule } from '../global/loader/loader.component';

declare const $: any;

@Component({
    selector: 'member-upsell-census',
    templateUrl: './upsell-census.component.html',
    styleUrls: ['./upsell-census.component.scss'],
    standalone: false
})
export class MemberUpsellCensusComponent implements OnInit, OnDestroy {
  @Input() uxHelper: any;
  @Input() partialKey: string = '';
  @Input() person: SearchedPerson;
  @Input() censusData: any;
  @Input() commerceContentId: string;
  @Output() onSuccess = new EventEmitter<void>();
  @Input() isPdfPrintView: boolean = false;

  enhancedflag = false;
  bonusflag = true;
  loader = false;
  constructor(private serviceHelperService: ServiceHelperService) {}

  ngOnInit() {
    const upsellnameflag = this.uxHelper.uxComposite.get('comp.billing.enhanced.name.enabled');
    this.bonusflag = upsellnameflag.bonus;
    this.enhancedflag = upsellnameflag.enhanced;
  }
  bonusloader() {
    this.loader = true;
  }

  ngOnDestroy() {}

  onReload() {
    this.onSuccess.emit();
  }

  report() {
    this.serviceHelperService.trackingService.report({
      section: 'properties',
      action: 'click',
      type: 'upsell',
    });
    this.serviceHelperService.trackingService.report({
      type: 'upsell',
      action: 'upsell_payment',
      benefit: true,
    });
  }
}

@NgModule({
  declarations: [MemberUpsellCensusComponent],
  imports: [CommonModule, MemberUpsellPaymentModalPartialModule, LoaderUpsellComponentModule],
  exports: [MemberUpsellCensusComponent],
})
export class MemberUpsellCensusComponentModule {}
