import {Component, Input, OnChanges, OnInit} from "@angular/core";
import {SearchResultPeopleSearch} from "../../../searchResultPeopleSearch";
import {UxHelper} from "../../../../../clientCommon/helper/uxHelper";
import {UxComposite} from "../../../../../common/models/ux/uxComposite";

@Component({
  selector: 'particle-1-searchResult-name-search',
  templateUrl: './searchResult.component.html',
  styleUrls: ['./searchResult.component.css'],
})
export class Particle1SearchResultNameSearchComponent implements OnInit, OnChanges {
  @Input() base: SearchResultPeopleSearch;

  @Input() uxHelper: UxHelper;
  @Input() pageType: string;
  @Input() pageCategory: string;
  @Input() page: string;

  constructor() {
  }

  init() {
  }

  ngOnInit() {
    return this.init();
  }

  ngOnChanges() {
    return this.init();
  }

  get adsDisplayInterval() {
    return this.uxHelper.getUxcomp('ad.bottom.1.config')?.interval;
  }

  shouldDisplayAd(i: number) {
    if ((!this.adsDisplayInterval) || (i < this.adsDisplayInterval)) return false;
    return parseFloat((i % this.adsDisplayInterval).toString()) === 0
  }
}
