import { UxComposite } from "../../common/models/ux/uxComposite";
import { LogUtils } from "../../common/utils/logUtils";

export class HeadlessHelper {
  static readonly enabledCompKey = 'comp.sales.name-search.headless.enabled';
  
  static readonly redirectUrlCompKey = 'comp.sales.name-search.headless.redirectUrl';

  private static readonly redirectorParamsKey = 'headlessRedirectorParams';

  static isEnabled(uxComposite: UxComposite): boolean {
    return HeadlessHelper.inIframe() 
      && uxComposite.getUxcomp(HeadlessHelper.enabledCompKey) === true;
  }

  static inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  // https://gitlab.weinform.net/root/Dev/-/issues/1563
  //
  // When calling an iframe in FamilyWatchdog, 
  // fm_weids and fm_loc values ​​are included in the query string.
  // These values ​​must be assigned to the search results in searchResult 
  // and included again in the query string when calling redirectUrl.
  static storeRedirectorParams(params) {
    if (!params?.fm_weid?.toString()?.length || !params?.fm_loc?.toString()?.length) {
      HeadlessHelper.removeRedirectorParams();

      return;
    }

    try {
      const data = {
        weid: params.fm_weid,
        loc: params.fm_loc,
      };

      localStorage.setItem(HeadlessHelper.redirectorParamsKey, JSON.stringify(data));
    } catch (e) {
      LogUtils.error('HeadlessHelper.storeRedirectorParams Fail', e);

      HeadlessHelper.removeRedirectorParams();
    }
  }

  static getRedirectorParams() {
    const result: {
      weid: string,
      loc: string,
    } = {
      weid: '',
      loc: '',
    };

    try {
      const params = localStorage.getItem(HeadlessHelper.redirectorParamsKey);

      if (!params) {
        LogUtils.warn('HeadlessHelper.getRedirectorParams No Params', params);

        return result;
      }

      const parsed = JSON.parse(params);

      if (!parsed?.weid?.toString()?.length || !parsed?.loc?.toString()?.length) {
        LogUtils.warn('HeadlessHelper.getRedirectorParams Missing Params', parsed);

        return result;
      }
  
      result.weid = parsed.weid;
      result.loc = parsed.loc;
  
      return result;
    } catch (e) {
      LogUtils.error('HeadlessHelper.getRedirectorParams Fail', e);

      return result;
    }
  }

  static removeRedirectorParams() {
    localStorage.removeItem(HeadlessHelper.redirectorParamsKey);
  }
}
