import { ResponseEvent } from "../event/responseEvent";
import { CommerceOffer } from "../models/commerce/commerceOffer";
import { UxComposite } from "../models/ux/uxComposite";
import { LogUtils } from "../utils/logUtils";
import { objectUtils } from "../utils/objectUtils";
import { randomUtils } from "../utils/randomUtils";

export enum CaptchaType {
  RecaptchaV2 = 'recaptcha.v2',
  RecaptchaV3 = 'recaptcha.v3',
  TurnstileV0 = 'turnstile.v0',
  FakeCaptchaSuccessV1 = 'fakeCaptchaSuccess.v1',
  FakeCaptchaFailV1 = 'fakeCaptchaFail.v1',
  None = '',
}

export type CaptchaTokenAndType = {
  token: string,
  type: CaptchaType,
};

export type CaptchaTypeData = { type: CaptchaType, action: string };

export namespace CaptchaType {
  export function fromString(type: string): CaptchaType {
    switch (type) {
      case CaptchaType.RecaptchaV2:
        return CaptchaType.RecaptchaV2;
      case CaptchaType.RecaptchaV3:
        return CaptchaType.RecaptchaV3;
      case CaptchaType.TurnstileV0:
        return CaptchaType.TurnstileV0;
      case CaptchaType.FakeCaptchaSuccessV1:
        return CaptchaType.FakeCaptchaSuccessV1;
      case CaptchaType.FakeCaptchaFailV1:
        return CaptchaType.FakeCaptchaFailV1;
      default:
        return CaptchaType.None;
    }
  }

  export function fromNameAndVersion(name: string, version: string): CaptchaType {
    switch (`${name}.${version}`) {
      case CaptchaType.RecaptchaV2:
        return CaptchaType.RecaptchaV2;
      case CaptchaType.RecaptchaV3:
        return CaptchaType.RecaptchaV3;
      case CaptchaType.TurnstileV0:
        return CaptchaType.TurnstileV0;
      case CaptchaType.FakeCaptchaSuccessV1:
        return CaptchaType.FakeCaptchaSuccessV1;
      case CaptchaType.FakeCaptchaFailV1:
        return CaptchaType.FakeCaptchaFailV1;
      default:
        return CaptchaType.None;
    }
  }

  export function splitTypeVersion(type: CaptchaType): {
    type: string,
    version: string,
  } {
    switch (type) {
      case CaptchaType.RecaptchaV2:
        return { type: 'recaptcha', version: 'v2' };
      case CaptchaType.RecaptchaV3:
        return { type: 'recaptcha', version: 'v3' };
      case CaptchaType.TurnstileV0:
        return { type: 'turnstile', version: 'v0' };
      case CaptchaType.FakeCaptchaSuccessV1:
        return { type: 'fakeCaptchaSuccess', version: 'v1' };
      case CaptchaType.FakeCaptchaFailV1:
        return { type: 'fakeCaptchaFail', version: 'v1' };
      default:
        return { type: 'none', version: '' };
    }
  }
}

export class CaptchaCommonHelper {
  private static readonly ruleCompKey = 'comp.brand.captcha.rules';

  static readonly captchaGuardIdHeader = 'X-Captcha-Id';

  static readonly captchaGuardPage = 'captcha-guard-page';

  static getCaptchaErrorResponse(captchaTypeList: CaptchaTypeData[][]): ResponseEvent {
    const responseEvent = new ResponseEvent();

    responseEvent.setFail();

    responseEvent.reasonObj['failed_captcha'] = true;

    responseEvent.reasonObj['captcha_type_list'] = captchaTypeList;

    return responseEvent;
  }

  static getSiteKey(params: {
    type: CaptchaType,
    uxComposite: UxComposite,
  }) {
    switch (params.type) {
      case CaptchaType.RecaptchaV2:
        return params.uxComposite.getUxcomp('comp.recaptcha.key.v2');
      case CaptchaType.RecaptchaV3:
        return params.uxComposite.getUxcomp('comp.recaptcha.key.v3');
      case CaptchaType.TurnstileV0:
        return params.uxComposite.getUxcomp('comp.brand.captcha.turnstile.siteKey');
      default:
        return null;
    }
  }

  static getCaptchaTypeDataList(params: {
    path: string,
    body: unknown | null,
    hostname: string,
    userAgent: string,
    userRoles: string[],
    commerceOffer: CommerceOffer | null,
    uxComposite: UxComposite,
  }): CaptchaTypeData[][] {
    const result = [];

    if (!params?.uxComposite || !params?.path) {
      return result;
    }

    const userRoles = params.userRoles?.length ? params.userRoles : [''];

    const rules = objectUtils.findRulesFromConditionedRules(
      { 
        path: params.path,
        body: params.body,
        hostname: params.hostname,
        userAgent: params.userAgent,
        userRoles,
        device: params.uxComposite.device,
        commerceOffer: params.commerceOffer,
        ip: params.uxComposite.ip,
      },
      CaptchaCommonHelper.getRules(params.uxComposite),
    );

    for (const rule of rules) {
      if (!rule?.candidates?.length) {
        continue;
      }
  
      const chosen = randomUtils.chooseWeightedRandom(rule.candidates) ?? '';
      
      result.push(
        chosen
          .split('|')
          .map(CaptchaType.fromString)
          .filter((e: CaptchaType) => e !== CaptchaType.None)
          .map((e: CaptchaType) => {
            return { type: e, action: rule?.['_DESC_'] ?? 'NO_DESC_IN_RULE' };
          }),
      );
    }

    return result;
  }

  static serializeTokens(tokens: CaptchaTokenAndType[]): string {
    try {
      return tokens.map(token => {
        return `${btoa(token.type)}:${btoa(token.token)}`;
      }).join('|');
    } catch (e) {
      LogUtils.error('CaptchaCommonHelper.serializeTokens failed', e);

      return '';
    }
  }

  static deserializeTokens(tokens: string): CaptchaTokenAndType[] {
    try {
      return tokens.split('|').map(e => {
        const splitted = e.split(':');
  
        return {
          type: atob(splitted?.[0]),
          token: atob(splitted?.[1]),
        } as CaptchaTokenAndType;
      });
    } catch (e) {
      LogUtils.error('CaptchaCommonHelper.deserializeTokens failed', e);

      return [];
    }
  }

  /**
   * In the body parameters, 
   * check whether the offer is in a necessary context 
   * and return the offer id array if necessary.
   * 
   * @param body Json parameters
   */
  static getCommerceOfferIds(body, uxComposite: UxComposite): string[] {
    if (!body?.param) {
      return [];
    }

    // case 1
    // - (ADMIN) api/manage/csr/commerce/sale
    if (body?.param?.commerceOfferIds?.length) {
      return body.param.commerceOfferIds;
    }

    // case 2
    // - (CLIENT) api/commerce/action/sale
    if (body?.param?.offerRuleKey && body?.param?.offerRuleKeyDetails?.length) {
      const offerRule = uxComposite.get(body.param.offerRuleKey);

      const ids: string[] = [];

      for (const detail of body.param.offerRuleKeyDetails) {
        // addon case
        if (detail?.commerceOfferIds?.length) {
          ids.push(...detail.commerceOfferIds);
        // sale case
        } else if (detail?.key && offerRule[detail.key]?.length) {
          const mappedIds: string[] = offerRule[detail.key]
            .map((e) => {
              return uxComposite.get(e?.offerKey);
            })
            .filter((e) => !!e);

          if (mappedIds.length) {
            ids.push(...mappedIds);
          }
        }
      }

      return ids;
    }

    return [];
  }

  /**
   * The candidates for a rule must be fixed to operate on a single value. 
   * The same conditions must be selected on the client and server.
   * 
   * If there are the same conditions, 
   * Captchas will be called for the corresponding number.
   * 
   * Sample rules
    [
      {
        "priority": 1,
        "conditions": [
          {"path":"^api/content/peopleSearch/peopleSearch"}
        ],
        "candidates": [
          {"weight": 1, "id":"recaptcha.v2|recaptcha.v3|turnstile.v0"}
        ]
      }
    ]
  */
  private static getRules(uxComposite: UxComposite) {
    const rules = uxComposite.getUxcomp(CaptchaCommonHelper.ruleCompKey);

    return rules?.length ? rules : [];
  }
}
