// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.offer-footer-divider {
  height: 0px;
}

.container {
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 576px) {
  .container {
    margin-top: 20px;
  }
}

.sub-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #525252;
}

ul.tabs {
  width: 100%;
  margin: 0px;
  padding: 0px;
  list-style: none;
}

ul.tabs li {
  background: none;
  color: #222;
  display: inline-block;
  padding: 10px 15px;
  cursor: pointer;
}

ul.tabs li.current {
  background: #ededed;
  color: #222;
}

.tab-content {
  display: none;
  background: #ededed;
  padding: 5px 0px 10px 0px;
  width: 100%;
  height: 210px;
}
@media (max-width: 576px) {
  .tab-content {
    height: 250px;
  }
}
@media (max-width: 380px) {
  .tab-content {
    height: 250px;
  }
}

.tab-content.current {
  display: inherit;
}`, "",{"version":3,"sources":["webpack://./src/app/components/ad/fluent-ad-loader.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;;AAEA;EACI,WAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AACJ;AAAI;EANJ;IAOM,gBAAA;EAGJ;AACF;;AAAA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAGJ;;AAAE;EACE,WAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;AAGJ;;AADE;EACE,gBAAA;EACA,WAAA;EACA,qBAAA;EACA,kBAAA;EACA,eAAA;AAIJ;;AADE;EACE,mBAAA;EACA,WAAA;AAIJ;;AADE;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EAEA,WAAA;EACA,aAAA;AAGJ;AADI;EARF;IAUI,aAAA;EAGJ;AACF;AADI;EAbF;IAeI,aAAA;EAGJ;AACF;;AAAE;EACE,gBAAA;AAGJ","sourcesContent":[".offer-footer-divider {\n    height: 0px;\n}\n\n.container{\n    width: 100%;\n    margin-top: 10px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    @media (max-width: 576px) {\n      margin-top: 20px;\n    }\n}\n\n.sub-title {\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 21px;\n    color: #525252;\n}\n  \n  ul.tabs{\n    width: 100%;\n    margin: 0px;\n    padding: 0px;\n    list-style: none;\n  }\n  ul.tabs li{\n    background: none;\n    color: #222;\n    display: inline-block;\n    padding: 10px 15px;\n    cursor: pointer;\n  }\n  \n  ul.tabs li.current{\n    background: #ededed;\n    color: #222;\n  }\n  \n  .tab-content{\n    display: none;\n    background: #ededed;\n    padding: 5px 0px 10px 0px;\n\n    width: 100%;\n    height: 210px;\n\n    @media (max-width: 576px) {\n      //width: 370px;\n      height: 250px;\n    }\n\n    @media (max-width: 380px) {\n      //width: 290px;\n      height: 250px;\n    }    \n  }\n  \n  .tab-content.current{\n    display: inherit;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
