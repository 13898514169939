// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-btn {
  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.2509803922);
}`, "",{"version":3,"sources":["webpack://./src/app/components/buttons/icon-button/icon-button.component.scss"],"names":[],"mappings":"AAAA;EACI,uDAAA;AACJ","sourcesContent":[".custom-btn {\n    box-shadow: 2px 2px 2px 0px #00000040;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
