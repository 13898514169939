import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: 'toDateTime'
})
export class ToDateTimePipe implements PipeTransform {
    transform(timestamp: number): string {
        var date = new Date(timestamp);

        return date.toLocaleString();
    }
}