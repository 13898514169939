import {Injectable} from "@angular/core";

@Injectable()
export class SpinnerService {
    spinner = false;


    constructor() {
    }

    spin() {
        this.spinner = true;
    }

    unspin() {
        this.spinner = false;
    }

    isSpinning():boolean {
        return this.spinner;
    }


}
