import {ClassDecoratorHelper} from "../helpers/classDecoratorHelper";

export class CollectionClassHelper extends ClassDecoratorHelper {
  getCollectionNameByDecoratorFieldValue(value) {
    return value.map((info) => {
      return {key: info.key, value: collectionClassHelper.getCollectionName(info.value)};
    });
  }

  getCollectionName(target): string {
    let info = this.getClass(target);

    if (info && info.value) {
      return info.value.name;
    }
  }

getCollection(collectionName) {
    let info = collectionClassHelper.getClassByKeyValue("name", collectionName);
    if (info) {
      return info.target;
    }
  }


  isEnabledRevisionByCollectionName(collectionName): boolean {
    var info = collectionClassHelper.getClassByKeyValue("name", collectionName);
    if (info && info.value) {
      return info.value.revisionEnabled !== false;
    }
  }

  isEnabledDraftByCollectionName(collectionName): boolean {
    var info = collectionClassHelper.getClassByKeyValue("name", collectionName);
    if (info && info.value) {
      return info.value.draftEnabled !== false;
    }
  }
}

export var collectionClassHelper = new CollectionClassHelper();

export function CollectionClass(value) {
  return function (target: any) {
    collectionClassHelper.addClass(target, value);
  };
}

