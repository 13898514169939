import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, NgModule } from '@angular/core';

@Component({
    selector: 'app-mini-offense-summary',
    templateUrl: './offense.component.html',
    styleUrls: ['./offense.component.scss'],
    standalone: false
})
export class MiniOffenseSummaryComponent implements OnInit {
  @Input() criminalOffenseList: any;

  constructor() {}

  ngOnInit(): void {

    if (this.criminalOffenseList[0]?.hair) {
      this.criminalOffenseList[0].hair = this.processString(this.criminalOffenseList[0]?.hair);
    }
    if (this.criminalOffenseList[0]?.eyes) {
      this.criminalOffenseList[0].eyes = this.processString(this.criminalOffenseList[0]?.eyes);
    }
    if (this.criminalOffenseList[0]?.height) {
      this.criminalOffenseList[0].height = this.processString(this.criminalOffenseList[0]?.height);
    }
    if (this.criminalOffenseList[0]?.weight) {
      this.criminalOffenseList[0].weight = this.processString(this.criminalOffenseList[0]?.weight);
    }
  }

  processString(input) {
    if (!input) return null; // null or undefined 
    return input.replace(/\s+OR.*$/, '').trim(); // " OR " 
  }
}

@NgModule({
  declarations: [MiniOffenseSummaryComponent],
  imports: [CommonModule],
  exports: [MiniOffenseSummaryComponent],
})
export class MiniOffenseSummaryComponentModule {}
