import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    Renderer2,
    SimpleChanges,
    ViewChild,
  } from '@angular/core';
  import { ActivatedRoute, Router } from '@angular/router';
  import { ServiceHelperService } from '../../../../../../clientCommon/services/serviceHelper.service';
  import { PersonDetail } from '../../../personDetail';
  import { ToastrService } from '../../../../../service/toastr.service';
  import { NoteService, AuthenticationService } from '../../../../../../clientCommon/services';
  import { BaseComponent } from '../../../../../../clientCommon/components/BaseComponent';
  import { peopleSearchProductKeys } from '../../../../../../common/custom/peopleSearch/peopleSearchProductKeys';
  import { SwiperOptions } from 'swiper/types/swiper-options';
  
  declare var $: any;
  
  @Component({
    template: '',
    standalone: false
})
  export class MiniCareerFinancialBaseComponent extends PersonDetail {
    href = '';
  
    @Input() isPdfPrintView: boolean = false;
    @Output() showLoader = new EventEmitter<void>();
  
    @ViewChild('scrollToTop', { static: true })
    scrollToTopBtnElement: ElementRef;
    
    // mock data
    mockCareerDataList = [
      { period: '2010-2012', job: 'Glaxosmith' },
      { period: '2012-2016', job: 'Dabur' },
      { period: '2016-2018', job: 'Ayush' },
      { period: '', job: '' },
    ];
  
    foreclosuresList = [];
    bankruptciesList = [];
    liensList = [];
    judgementsList = [];
    additionalResource = {
      summary: `We have found the most up to date work and career information to be at this website. Click to find out more about {{ person?.fName }}'s work history.`,
      arr: [
        {
          imgSrc: 'assets/ux1/search_flow/ic-linkedin.svg',
          url: 'https://www.linkedin.com/',
        },
      ],
    };
    additionalResourceFinancial = {
      summary: `We’ve found additional sources that may indicate if any of {{ person?.fName }}'s
      properties are in currently in foreclosure. This could present an
      excellent buying opportunity!`,
      arr: [],
    };
    public sliderConfig: SwiperOptions = {
      slidesPerView: 1,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        600: {
          slidesPerView: 4,
        },
        475: {
          slidesPerView: 2,
        },
      },
    };
  
    constructor(
      public serviceHelperService: ServiceHelperService,
      public changeDetectorRef: ChangeDetectorRef,
      activatedRoute: ActivatedRoute,
      protected router: Router,
      public renderer: Renderer2,
      public toastr: ToastrService,
      public noteService: NoteService,
      public authService: AuthenticationService
    ) {
      super(serviceHelperService, activatedRoute, toastr, noteService, authService);
    }
  
    ngOnInit() {
      window.scrollTo(0, 0);
      this.href = this.router.url;
  
      return this.init().then(() => {
        this.changeDetectorRef.detectChanges();
        this.additionalResource.summary =
          `We have found the most up to date work and career information to be at this website. Click to find out more about ` +
          (this.person ? this.person.fName : '') +
          `'s work history.`;
        this.additionalResourceFinancial.summary =
          `We’ve found additional sources that may indicate if any of ` +
          (this.person ? this.person.fName : '') +
          `'s properties are in currently in foreclosure. This could present an excellent buying opportunity!`;
  
        this.serviceHelperService.trackingService.report({
          page: BaseComponent.PAGE.detail,
          pageType: BaseComponent.PAGE_TYPE.member,
          type: peopleSearchProductKeys.nameSearch,
          section: 'careerFinancial',
          action: 'view',
        });
      });
    }
    ngAfterViewInit() {
      // Register scroll to top event listener.
      const screenHeight = window.screen.availHeight;
      window.onscroll = () => {
        if (document.body.scrollTop > screenHeight || document.documentElement.scrollTop > screenHeight) {
          this.renderer.setStyle(this.scrollToTopBtnElement.nativeElement, 'display', 'flex');
        } else {
          this.renderer.setStyle(this.scrollToTopBtnElement.nativeElement, 'display', 'none');
        }
      };
    }
    scrollToTopHandler() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
    ngOnDestroy() {
      return super.onDestroy();
    }
  
    ngOnChanges(changes: SimpleChanges): void {}
  
    init() {
      // Tooltip init
      setTimeout(() => {
        $('.tooltip-container').tooltip({
          html: true,
          trigger: 'click',
        });
      }, 0);
      return super.init();
    }
  
    handleLoader() {
      this.showLoader.emit();
    }
  
    getCourtClassName() {
      let availableCounts = 0;
      if (
        this.person?.tempClient?.availableItems?.lien?.count ||
        this.person.courts.filter((court) => court.type === 'Lien').length
      ) {
        availableCounts++;
      }
      if (
        this.person?.tempClient?.availableItems?.judgment?.count ||
        this.person.courts.filter((court) => court.type === 'Judgment').length
      ) {
        availableCounts++;
      }
      switch (availableCounts) {
        case 1:
          return 'col-xs-12 col-sm-6 col-md-4';
        case 2:
          return 'col-xs-12 col-sm-6 col-md-3';
        default:
          return 'col-xs-12 col-sm-6 col-md-6';
      }
    }
  }

  