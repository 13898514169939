import { Component, Directive, NgModule, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'input[cos-input], text-area[cos-input], select[cos-select]',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  host: {
    class: 'cos-input',
  },
  encapsulation: ViewEncapsulation.None,
})
export class CosInputComponent {}

@Component({
  selector: 'cos-label',
  template: `<ng-content></ng-content>`,
  host: {
    class: 'cos-label'
  },
  encapsulation: ViewEncapsulation.None,
})
export class CosLabel {}

// @NgModule({
//   declarations: [CosInputComponent, CosLabel],
//   exports: [CosInputComponent, CosLabel],
// })
// export class CosInputModule {}
