import { NgModule } from '@angular/core';
import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { SwiperContainer } from 'swiper/element';
import {SwiperOptions} from 'swiper/types';

@Directive({
    selector: '[appSwiper]',
    standalone: false
})
export class SwiperDirective implements AfterViewInit {
  @Input() config?: SwiperOptions;
  swiperElement: HTMLElement;

  constructor(private el: ElementRef<SwiperContainer>) {   
    this.swiperElement = el.nativeElement;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      Object.assign(this.el.nativeElement, this.config);
      this.el.nativeElement.initialize();
    }, 0);
  }
}

@NgModule({
  declarations: [SwiperDirective],
  exports: [SwiperDirective],
})
export class SwiperModule { }