import { Component, OnInit, Input, NgModule } from "@angular/core";
import { User } from "../../../../../../common/models/user/user";
import { CommerceContent } from '../../../../../../common/models/commerce/commerceContent';
import { SpyIdHelper } from "../../../../../../clientCommon/helper/spyIdHelper";
import { CommonModule } from "@angular/common";
import moment from "moment";
import 'moment-timezone';
import { clientPaths } from "../../../../../../../../common/helpers/pathHelpers";
import { SearchedPerson } from "@common/custom/models/peopleSearch/searchedPerson";

@Component({
    selector: "app-mini-dwb-breaches",
    templateUrl: "./dwb-breaches.component.html",
    styleUrls: ["./dwb-breaches.component.scss"],
    standalone: false
})
export class MiniReportDwbBreacheswComponent implements OnInit {
  @Input() base: any;
  @Input() isPdfPrintView = false;
  // @Input() person = SearchedPerson;
  @Input() emails = []
  @Input() spyDwbCommerceContentId: string;

  user: User;
  objectKeys = Object.keys;
  showAllBreaches = false;
  idProtectionEnabled = false

  constructor() {}

  ngOnInit(): void {
    this.base.init();
    this.user = this.base.authService.getUser();
  }

  toggleShowAllBreaches() {
    this.showAllBreaches = !this.showAllBreaches
  }

  titleCase(str) {
    return str.split('_')
    .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
    .join(' ');
  }

  handleMove() {
    var url = '/'+clientPaths.spyDashboardComponent + '/' + this.spyDwbCommerceContentId;
    this.base.router.navigateByUrl(url);
  }

  handleMoveMySearch() {
    var url = '/'+clientPaths.content;
    this.base.router.navigateByUrl(url);
  }

  alertDetailDisplay(idx) {
    if (this.base.alertsView[idx].is_view == true) {
      this.base.alertsView[idx].is_view = false;
      this.base.alertsView[idx].img_src = '/assets/spyandid/forward_arrow.svg';
    } else {
      this.base.alertsView[idx].is_view = true;
      this.base.alertsView[idx].img_src = '/assets/spyandid/bottom_arrow.svg';
    }
  }


  formatTimestamp(timestamp) {
    if (!timestamp) {
      return timestamp;
    }
    if (timestamp.length == 4) return '-';

    return moment.unix( Number(timestamp)).tz('America/New_York').format('MM/DD/YYYY h:mm a');
  }

  formatYear(timestamp) {
    if (!timestamp) {
       timestamp = 0;
    }
    if (timestamp.length == 4) return timestamp;
    return moment.unix( Number(timestamp)).tz('America/New_York').format('YYYY');
  }
  formatDate(timestamp) {
    if (!timestamp) {
      return 'N/A';
    }else if (timestamp.length == 4) return '-';
    else  {
      let formattedDate = moment(timestamp).format('MM/DD/YYYY');
      return formattedDate;
    }
    
  }
}

@NgModule({
  declarations: [MiniReportDwbBreacheswComponent],
  imports: [CommonModule],
  exports: [MiniReportDwbBreacheswComponent],
})
export class MiniReportDwbBreachesComponentModule {}


