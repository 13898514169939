import { Component, NgModule } from '@angular/core';

@Component({
  selector: 'member-upsell-properties-features',
  templateUrl: './properties-features.component.html',
})
export class MemberUpsellPropertiesFeaturesComponent {}

@NgModule({
  declarations: [MemberUpsellPropertiesFeaturesComponent],
  exports: [MemberUpsellPropertiesFeaturesComponent],
})
export class MemberUpsellPropertiesFeaturesComponentModule {}
