import {CollectionClass} from "../../decorators/database/collectionClass";
import {ForeignKeyField} from "../../decorators/database/foreignKeyField";
import {ForeignObjectField} from "../../decorators/database/foreignObjectField";
import {IndexField} from "../../decorators/database/indexField";
import {ReferenceField} from "../../decorators/database/referenceField";
import {TransientField} from "../../decorators/database/transientField";
import {ModelBase} from "../modelBase";
import {CommerceOrder} from "./commerceOrder";
import {CommerceTransactionCollection} from "./commerceTransactionCollection";

export const commerceScheduleIndex = {
  phone: 'ph:',
};

@CollectionClass({name: "commerceSchedules"})
export class CommerceSchedule extends ModelBase<CommerceSchedule> {
  public static TYPE = {
    commerceOrder: "commerceOrder",
    general: "general",
  };

  public static SUB_STATUS = {
    resume: "resume",
    resumeHasActiveSchedule: "resumeHasActiveSchedule",
  }

  @IndexField() category: string;

  @IndexField() @ReferenceField(CommerceOrder) @ForeignKeyField(CommerceOrder) commerceOrderId: string;
  @TransientField @ForeignObjectField("commerceOrderId") commerceOrder: CommerceOrder;

  @IndexField() @ForeignKeyField(CommerceTransactionCollection) commerceTransactionCollectionId: string;
  @TransientField @ForeignObjectField("commerceTransactionCollectionId") commerceTransactionCollection: CommerceTransactionCollection;

  @IndexField() targetId: string;
  targetCollectionName: string;

  @IndexField({compound: {dueTimestamp: 1, status: 1}}) @IndexField() dueTimestamp: number;
  @IndexField() originalDueTimestamp: number;
  @IndexField() actionTimestamp: number;

  scheduleData: {
    // commerceOrder type
    amount?: number,
    code?: string,
    tokenExpiration?: {
      sequence: number,
      year: string,
      month: string,
    },
    options: {},
  } | any = {};

  constructor(doc?, draftFlag?: boolean) {
    super(doc, draftFlag);
    this.init(doc, this.draftFlag);
  }

  getSecuredDoc(): CommerceSchedule {
    let obj: CommerceSchedule = super.getSecuredDoc();

    obj.commerceOrderId = this.commerceOrderId;
    obj.dueTimestamp = this.dueTimestamp;
    obj.scheduleData = this.scheduleData;
    obj.tempClient = this.tempClient;

    return obj;
  }

  isExpire() {
    return this.type === CommerceSchedule.TYPE.commerceOrder && this.scheduleData.amount === -1;
  }
}

