import {isNumber} from "util";
import {LogUtils} from "../../utils/logUtils";
import {evalUtils} from "../../utils/evalUtil";

export interface SequenceOptions {
  thinMatch?: boolean;
  thinMatchDataProviderDown?: boolean;
  thinMatchTooManyResults?: boolean;
  thinMatchNoResults?: boolean;
  thinMatchGeographic?: boolean;
}

export class CommercePrice {
  public static UNIT = {
    d: "Day",
    w: "Week",
    m: "Month",
    y: "Year",
  };

  public static UNIT_CODE = {
    d: "d",
    w: "w",
    m: "m",
    y: "y",
  } as const;


  public static PRICE_OPTION = {
    bypassOnActiveTokenExists: "bypassOnActiveTokenExists",
  };

  prices: {
    amount: number,
    code: string,
    sequence: string,
    period?: {
      quantity: number,
      unit: "d" | "w" | "m" | "y",
    },
    since?: "payment" | "due" | number,
    options?: { [key: string]: any },
  }[] = [];

  constructor(doc?) {
    if (doc) {
      Object.assign(this, doc);
    }
  }

  addStandardTrial() {
    this.prices.push({
      amount: null,
      code: "USD",
      sequence: "s == 0",
      period: {
        quantity: 7,
        unit: "d",
      },
      since: "payment",
      options: {},
    });
  }


  addStandardRecurring() {
    this.prices.push({
      amount: null,
      code: "USD",
      sequence: "s > 0",
      period: {
        quantity: 1, unit: "m",
      },
      since: "payment",
      options: {},
    });
  }

  isValid(): boolean {
    let flag = true;

    if (!this.prices || this.prices.length === 0) {
      flag = false;
    }

    if (flag) {
      this.prices.some((price) => {

        if (!isNumber(price.amount) || price.amount === null) {
          flag = false;
        }

        if (!isNumber(price.period.quantity) || price.period.quantity === null || !price.period.unit) {
          flag = false;
        }

        if (!price.since) {
          flag = false;
        }

        if (!flag) {
          return true;
        }
      });
    }

    return flag;
  }

  getPrices(sequence: number, retry: number, sequenceOptions): {
    amount: number,
    code: string,
    sequence: string,
    period: {
      quantity: number,
      unit: "d" | "w" | "m" | "y",
    },
    since: "payment" | "due" | number,
  }[] {
    let currentPrices = [];
    if (isNumber(sequence) && isNumber(retry)) {
      this.prices.forEach((price) => {
        if (evalUtils.sequenceMatch(sequence, retry, price.sequence, sequenceOptions)) {
          currentPrices.push(price);
        }
      });
    } else {
      LogUtils.error(sequence, retry);
    }

    return currentPrices;
  }

  hasPrices(sequence: number, retry: number, sequenceOptions): boolean {
    return this.getPrices(sequence, retry, sequenceOptions).length > 0;
  }

  getTotalPrice( sequence: number, retry: number, sequenceOptions) {
    let totalAmount = 0;
    let code;
    let currentPrices = this.getPrices(sequence, retry, sequenceOptions);
    if (currentPrices.length > 0) {
      currentPrices.forEach((price) => {
        if (!code) {
          code = price.code;
        }

        if (code != price.code) {
          LogUtils.error(currentPrices);
          throw Error("Code does not match");
        }

        totalAmount += price.amount;
      });
    } else {
      totalAmount = -1;
    }

    let options = this.getPriceOptions(sequence, retry, sequenceOptions);

    return {code: code, amount: totalAmount, options: options};
  }

  getPriceOptions(sequence: number, retry: number, sequenceOptions: SequenceOptions) {
    let options = {};
    if (isNumber(sequence) && isNumber(retry)) {
      this.prices.forEach((price) => {
        if (evalUtils.sequenceMatch(sequence, retry, price.sequence, sequenceOptions)) {
          CommercePrice.mergePriceOptions(price.options, options);
        }
      });
    } else {
      LogUtils.error(sequence, retry);
    }

    return options
  }

  public static mergePriceOptions(source, target) {
    if (target) {
      if (source && source[CommercePrice.PRICE_OPTION.bypassOnActiveTokenExists] && target[CommercePrice.PRICE_OPTION.bypassOnActiveTokenExists] !== false) {
        target[CommercePrice.PRICE_OPTION.bypassOnActiveTokenExists] = true;
      } else {
        target[CommercePrice.PRICE_OPTION.bypassOnActiveTokenExists] = false;
      }
    }
  }


  isZeroAmount() {
    var flag = true;

    this.prices.some((price) => {
      if (price.amount != 0) {
        flag = false;
        return true;
      }
    });

    return flag;
  }
}

