// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.landing-search-submit {
  white-space: nowrap;
}

#landing ::ng-deep .stay-informed-paragraph {
  font-size: 18px !important;
  line-height: 24px !important;
}

.testimonials {
  margin-top: 80px;
  margin-bottom: 80px;
}`, "",{"version":3,"sources":["webpack://./src/app/themes/ux.3.0/peopleSearch/nameSearch/landing.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAEA;EACE,0BAAA;EACA,4BAAA;AACF;;AAEA;EACE,gBAAA;EACA,mBAAA;AACF","sourcesContent":[".landing-search-submit {\n  white-space: nowrap;\n}\n\n#landing ::ng-deep .stay-informed-paragraph {\n  font-size: 18px !important;\n  line-height: 24px !important;\n}\n\n.testimonials {\n  margin-top: 80px;\n  margin-bottom: 80px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
