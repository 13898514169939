// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mini-offense-summary {
  background-color: white;
  border: 1px solid #acacac;
}
.mini-offense-summary__head {
  width: 230px;
  margin: 0 auto;
  padding: 25px 15px;
}
.mini-offense-summary__head img {
  display: block;
  max-width: 100%;
}
.mini-offense-summary__content {
  color: #525252;
  padding: 15px;
  font-size: 16px;
  line-height: 18px;
}
.mini-offense-summary__content dt {
  margin-bottom: 0.25em;
}
.mini-offense-summary__content dd {
  margin-bottom: 0.5em;
}
@media (max-width: 990px) {
  .mini-offense-summary__content {
    font-size: 12px;
    line-height: 14px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/member/peopleSearch/components/mini/offense-summary/offense.component.scss"],"names":[],"mappings":"AAGA;EACE,uBAAA;EACA,yBAAA;AAFF;AAIE;EACE,YAAA;EACA,cAAA;EACA,kBAAA;AAFJ;AAII;EACE,cAAA;EACA,eAAA;AAFN;AAME;EACE,cAlBG;EAmBH,aAAA;EACA,eAAA;EACA,iBAAA;AAJJ;AAMI;EACE,qBAAA;AAJN;AAOI;EACE,oBAAA;AALN;AAQI;EAdF;IAeI,eAAA;IACA,iBAAA;EALJ;AACF","sourcesContent":["$light-gray: #acacac;\n$body: #525252;\n\n.mini-offense-summary {\n  background-color: white;\n  border: 1px solid $light-gray;\n\n  &__head {\n    width: 230px;\n    margin: 0 auto;\n    padding: 25px 15px;\n\n    img {\n      display: block;\n      max-width: 100%;\n    }\n  }\n\n  &__content {\n    color: $body;\n    padding: 15px;\n    font-size: 16px;\n    line-height: 18px;\n\n    dt {\n      margin-bottom: 0.25em;\n    }\n\n    dd {\n      margin-bottom: 0.5em;\n    }\n\n    @media (max-width: 990px) {\n      font-size: 12px;\n      line-height: 14px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
