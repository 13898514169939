import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ServiceHelperService } from "../../../../clientCommon/services/serviceHelper.service";
import { ActivatedRoute } from "@angular/router";
import { LogUtils } from "../../../../common/utils/logUtils";
import { CreditCardInputHelper } from "../../../../clientCommon/helper/creditCardInputHelper";
import { UxHelper } from "../../../../clientCommon/helper/uxHelper";
import { CommerceOrder } from "../../../../common/models/commerce/commerceOrder";
import { User } from "../../../../common/models/user/user";
import { ModelBase } from "../../../../common/models/modelBase";
import { BaseDirective } from "../../../../clientCommon/directives/BaseDirective";
import { CommerceToken } from "../../../../common/models/commerce/commerceToken";
import { creditCardUtils } from "../../../../common/utils/creditCardUtils";
import { ResponseEvent } from "../../../../common/event/responseEvent";


export class CreditCardComponentEvent {
  public static ACTION = {
    update: "update",
    decline: "decline",
  };

  status: string;
  action: string;
  responseEvent: ResponseEvent;

}

export class CreditCardComponentOptions {

  prevTokenInfo = false;
  validateSubmitButton = true;

  prepop?: {
    firstName: boolean | string,
    lastName: boolean | string,
  } = {
      firstName: false,
      lastName: false,
    };
}


@Component({
  selector: 'cpcc-entery-card',
  templateUrl: './cpcc-entery-card.component.html',
  styleUrls: ['./cpcc-entery-card.component.scss']
})
export class CpccEnteryCardComponent extends BaseDirective implements OnInit, OnDestroy {
  @Input() uxHelper: UxHelper;
  @Input() commerceOrderId;

  @Input() uxcompKeyButtonSubmit;
  @Input() uxcompKeyButtonDecline;
  @Input() uxcompKeyUnderCc;
  @Input() uxcompKeyUnderExp;
  @Input() uxcompKeyUnderZip;
  @Input() uxcompKeyFooterInner;

  @Input() commerceOrder: CommerceOrder;
  @Input() prevCommerceToken: CommerceToken;
  @Input() options: CreditCardComponentOptions;

  @Output() event = new EventEmitter<CreditCardComponentEvent>();
  @Output() loading = new EventEmitter<boolean>();

  creditCardInputHelper = new CreditCardInputHelper();
  hash;
  user: User;
  email;
  flags = {
    newPaymentFlag: false,
    updated: false,
    fail: false,
    error: false,

  };
  prevValues = {
    ccNumber: "",
    exp: "",
  }
  lastObfuscatedCCNumber = "";
  lastExp = "";
  lastZip = "";
  brand = "";
  userFullName = "";
  processing = false;

  constructor(serviceHelperService: ServiceHelperService,
    activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
  }

  ngOnInit() {
    return this.baseInit().then(() => {
      if (!this.options) {
        this.options = new CreditCardComponentOptions();
      }

      this.brand = this.uxComposite.brandName;
      this.user = this.serviceHelperService.authenticationService.getUser();
      if ((!this.commerceOrder) && this.commerceOrderId) {
        return this.serviceHelperService.commerceService.findCommerceOrder(this.commerceOrderId);
      } else {
        return null;
      }
    }).then((commerceOrders) => {
      if (commerceOrders) {
        this.commerceOrder = commerceOrders[0];
      }
      if (this.commerceOrder) {
        this.email = this.commerceOrder.commerceToken.billingAddress.email;
        this.userFullName = [this.commerceOrder.commerceToken.billingAddress.firstName, this.commerceOrder.commerceToken.billingAddress.lastName].join(" ");
      }
      if (this.prevCommerceToken) {
        this.prevValues.ccNumber = creditCardUtils.formatObfuscatedCCNumberBybinLast4(this.prevCommerceToken.bin, this.prevCommerceToken.last4, this.prevCommerceToken.length);
        if (!this.prevCommerceToken.isQuadZero()) {
          this.prevValues.exp = creditCardUtils.formatExp4Digit(this.prevCommerceToken.getExp4Digits());
        }
      }
    }).then(() => {
      return this.init();
    }).then(() => {
      LogUtils.debug(this.options, this.commerceOrder, this.prevCommerceToken, this.email, this.user);
      this.initDone = true;
    }).catch((e) => {
      LogUtils.error("CreditCardComponent.error", e);
    });
  }

  init() {
    return Promise.resolve().then(() => {
      this.hash = this.serviceHelperService.commerceService.createHash();
      this.creditCardInputHelper.setDummyAddress(this.uxHelper.uxComposite);
      this.creditCardInputHelper.clearCC();
      if (this.options.prepop) {

        if (this.options.prepop.firstName === true) {
          if (this.prevCommerceToken && this.prevCommerceToken.billingAddress) {
            this.creditCardInputHelper.billingAddress.firstName = this.prevCommerceToken.billingAddress.firstName;
          }
        } else if (this.options.prepop.firstName) {
          this.creditCardInputHelper.billingAddress.firstName = this.options.prepop.firstName;
        }

        if (this.options.prepop.lastName === true) {
          if (this.prevCommerceToken && this.prevCommerceToken.billingAddress) {
            this.creditCardInputHelper.billingAddress.lastName = this.prevCommerceToken.billingAddress.lastName;
          }
        } else if (this.options.prepop.lastName) {
          this.creditCardInputHelper.billingAddress.lastName = this.options.prepop.lastName;
        }

      }
    }).catch((e) => {
      LogUtils.error("CreditCardComponent.error", e);
    });
  }

  initError() {
    this.flags.error = false;

  }

  ngOnDestroy() {
    return super.onDestroy();
  }

  isValidPaymentInfo() {
    return this.creditCardInputHelper.isValidFirstName() &&
      this.creditCardInputHelper.isValidLastName() &&
      this.creditCardInputHelper.isValidCCNumber() &&
      this.creditCardInputHelper.isValidCCExp() &&
      this.creditCardInputHelper.isValidZip();
  }

  public getOfferRulesUxcompKey() {
    return "comp.billing.cpcc.update.offers.rules";
  }

  submit() {
    this.initError();
    this.flags.newPaymentFlag = true;

    let event = new CreditCardComponentEvent();
    event.action = CreditCardComponentEvent.ACTION.update;
    event.status = ModelBase.STATUS.inProgress;

    return Promise.resolve().then(() => {
      if (!this.isValidPaymentInfo()) {
        event.status = ModelBase.STATUS.error;
        return Promise.reject(event);
      }
    }).then(() => {
      this.processing = true;
      this.loading.emit(true);
      this.lastObfuscatedCCNumber = '';
      this.lastExp = this.creditCardInputHelper.ccExp4Digits;
      this.lastZip = this.creditCardInputHelper.billingAddress.zip;
      this.creditCardInputHelper.billingAddress.email = this.email;
      const offerRuleUxcompKey = this.getOfferRulesUxcompKey();
      return this.serviceHelperService.commerceService.updateCreditCard(this.hash, this.user, this.uxComposite, this.creditCardInputHelper, null, offerRuleUxcompKey).catch((e) => {
        if (e instanceof ResponseEvent) {
          event.responseEvent = e;
        }
        event.status = ModelBase.STATUS.rejected;
        return Promise.reject(event);
      });
    }).then((responseEvent: ResponseEvent) => {
      event.status = ModelBase.STATUS.fulfilled;
      event.responseEvent = responseEvent;
    }).then(() => {
      return this.init();
    }).catch((e) => {
      LogUtils.debug("submit failed", e, event);
      if (event.status === ModelBase.STATUS.error) {
        this.flags.error = true;
      } else {
        this.flags.fail = true;
      }
    }).then(() => {
      LogUtils.debug("submit result", event);
      this.processing = false;
      this.loading.emit(false);
      if (event.status === ModelBase.STATUS.error) {
        // Do nothing
      } else {
        this.event.emit(event);
      }
    });
  }

  decline() {
    let event = new CreditCardComponentEvent();
    event.action = CreditCardComponentEvent.ACTION.decline;
    this.event.emit(event);
  }

  handleSplitFullName(value) {
    const [first, second] = value.split(" ");
    this.creditCardInputHelper.billingAddress.firstName = first || '';
    this.creditCardInputHelper.billingAddress.lastName = second || '';
  }

}
