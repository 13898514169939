import {Component, Input, OnInit} from "@angular/core";
import {BaseDirective} from "../../../clientCommon/directives/BaseDirective";
import {ServiceHelperService} from "../../../clientCommon/services/serviceHelper.service";
import {ActivatedRoute} from "@angular/router";
import {UxHelper} from "../../../clientCommon/helper/uxHelper";

@Component({
  selector: 'app-sales-disclaimer',
  templateUrl: 'salesDisclaimer.component.html',
  styleUrls: ['salesDisclaimer.component.css'],
})
/**
 *  @fileOverview SalesDisclaimerComponent will render its html content via uxTemplate child component based on UxComp key
 *
 *  @extends BaseDirective to get the required custom properties methods and base directives
 *
 *  @property uxHelper is an input received from the parent view component to render html content using 'uxTemplate' component based on uxCompkey
 */
export class SalesDisclaimerComponent extends BaseDirective implements OnInit {
  @Input() uxHelper: UxHelper;

  /**
 *
 * @param serviceHelperService will provide access to different required services such as authentication service, uxc service etc.
 * @parma activatedRoute provides access to information about a route associated with a component that is loaded in an outlet
 *
 */
  constructor(serviceHelperService: ServiceHelperService, activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
  }

  ngOnInit() {
    return super.baseInit();
  }

}
