import { Component, NgModule, OnInit } from "@angular/core";
import { BaseComponent } from "@clientCommon/components/BaseComponent";
import { ServiceHelperService } from "@clientCommon/services/serviceHelper.service";
import { ActivatedRoute} from "@angular/router"
import { redirectHelper } from "@clientCommon/helper/redirectHelper";
import { clientPaths } from "@common/helpers/pathHelpers";
import { CommonModule } from "@angular/common";
import { LogUtils } from "@common/utils/logUtils";

@Component({
    templateUrl: '../../clientCommon/templates/generic.html',
    standalone: false
})
export class ReferralLinkComponent extends BaseComponent implements OnInit {
    message: string;
    defaultUrl = '/name/landing';

    constructor(serviceHelperService: ServiceHelperService,
        activatedRoute: ActivatedRoute) {
        super(serviceHelperService, activatedRoute);
        this.pageType = BaseComponent.PAGE_TYPE.general;
        this.pageCategory = "general";
        this.page = "referralLink";
    }

    ngOnInit() {
        LogUtils.debug('referral link called...');
        return this.baseInit().then(() => {
            return this.activatedRoute.params.subscribe((params) => {
                LogUtils.debug(params);
                let hash = params.target;
                
                if (hash) {
                    const rawQueryParams = this.activatedRoute.snapshot.queryParams;
                    LogUtils.debug(rawQueryParams);

                    let myParam = rawQueryParams['code'];
                    let uxc = rawQueryParams['uxc'];
                    if (myParam) {
                        const code = myParam.replace(/\s/g, '+');
                        LogUtils.debug(hash, code, uxc);
                        this.referralCheck(hash, code, uxc);
                    }else {
                        redirectHelper.redirect(this.serviceHelperService, this.defaultUrl);
                    }

                    // this.activatedRoute.queryParams.subscribe(ps => {
                    //     LogUtils.debug(ps);
                    //     const myParam = ps['code'];
                    //     const code = decodeURIComponent(myParam.replace(/\+/g, '%2B'));
                    //     this.referralCheck(hash, code);
                    // });                    
                }else {
                    redirectHelper.redirect(this.serviceHelperService, this.defaultUrl);
                }
            });
        });
    }

    getUrlParam(param) {
        let result = decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search) || [null, ''])[1].replace(/\+/g, '%20')) || null;

        LogUtils.debug(location);
        if (result !== null) {
          // capitalize first letter
          return result.charAt(0).toUpperCase() + result.slice(1);
        }
        return null;
    }

    referralCheck(hash, myParam, uxc) {
        this.serviceHelperService.spinnerService.spin();
        // storage save : referral={ hash: hash, email: email }
        if (myParam) {
            const referral = { hash: hash, email: myParam };

            this.serviceHelperService.storageService.setSession('referral', referral);

            // track report
            let param = {
                page: BaseComponent.PAGE.referral,
                pageType: BaseComponent.PAGE_TYPE.general,
                type: 'referral',
                action: 'sent'  // sent, click, joined
            };
            this.serviceHelperService.trackingService.report(param);  

            const url = this.defaultUrl+'?uxc='+uxc;
            redirectHelper.redirect(this.serviceHelperService, url); // this.defaultUrl);
        }else {
            redirectHelper.redirect(this.serviceHelperService, this.defaultUrl);
        }
        this.serviceHelperService.spinnerService.unspin();

        // this.serviceHelperService.referralService.referralLinkCustomer(hash, email).then((user) => {            
        //   this.message = "Referral Link Success";
        //   redirectHelper.redirect(this.serviceHelperService, this.defaultUrl);
        // }).catch((e) => {
        //   if (e === false) {
        //     this.message = "Referral Link Fail";
        //   } else {
        //     this.message = "Referral Link Error";
        //   }
        //   redirectHelper.redirect(this.serviceHelperService, this.defaultUrl);
    
        // }).then(() => {
        //   this.serviceHelperService.spinnerService.unspin();
        // });
    }

}
// @NgModule({
//     declarations: [ReferralLinkComponent],
//     imports: [CommonModule],
//     exports: [ReferralLinkComponent],
// })
// export class ReferralLinkComponentModule {}