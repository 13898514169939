import {AfterViewInit, Component, HostListener, Input, OnChanges, OnInit} from "@angular/core";
import {UxHelper} from "../../../../../clientCommon/helper/uxHelper";
import {LandingPeopleSearch} from "../../../landingPeopleSearch";
import {DomSanitizer} from "@angular/platform-browser";

declare var $: any;

type utmParamValueType = {
  header1: string;
  header2: string;
}

@Component({
  selector: 'particle-1-landing-name-search',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css'],
})
export class Particle1LandingNameSearchComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() base: LandingPeopleSearch;

  @Input() uxHelper: UxHelper;
  @Input() pageType: string;
  @Input() pageCategory: string;
  @Input() page: string;
  @Input() utmParamValue: utmParamValueType = null;

  sample = false;
  counter = false;

  focusFlagCity;
  firstNameFocusedFlag = false;
  stateChangedFlag = false;
  inputField: string;
  videoTag;

  constructor(private domSanitizer: DomSanitizer) {
  }

  init() {}

  ngOnInit() {
    return this.init();
  }

  ngOnChanges() {
    return this.init();
  }

  // Used on page load to render DOM element
  ngAfterViewInit() {
    if (this.base.userInput.fname?.length > 0) {
      this.firstNameplaceHolderOnLoad();
    }
    if (this.base.userInput.lname?.length > 0) {
      this.lastNameplaceHolderOnLoad();
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    if (document.body.scrollTop > 300 || document.documentElement.scrollTop > 300) {
      this.sample = true;
    } else {
      this.sample = false;
    }
  }

  scrollToTop() {
    if (this.sample) {
      $('html, body').animate({scrollTop: 0});
    }
  }

  getDigit(number: number, place: number) {
    return Math.floor(number / Math.pow(10, place - 1)) % 10;
  }

  // On blur eventlistener
  showPlaceHolder(name: string) {
    this.inputField = name
    let placeHolderText = document.getElementById('hide-placeholder-' + this.inputField);
    // Moves up placeholder when there's data in input field
    if (this.base.userInput.fname?.length > 0 || this.base.userInput.lname?.length > 0) {
      placeHolderText.style.position = "absolute";
      placeHolderText.style.top = "0";
      placeHolderText.style.left = "5px";
    }
    // Moves down placeholder when there's no data in input field
    if (!this.base.userInput.fname && name === 'fname') {
      placeHolderText.style.position = "absolute";
      placeHolderText.style.top = "14px";
      placeHolderText.style.left = "15px";
    } else if (!this.base.userInput.lname && name === 'lname') {
      placeHolderText.style.position = "absolute";
      placeHolderText.style.top = "14px";
      placeHolderText.style.left = "15px";
    }
  }

  // Move up placeholder when there's data in input field on focus and keydown
  moveUpPlaceHolder(name: string) {
    this.inputField = name
    let placeHolderText = document.getElementById('hide-placeholder-' + this.inputField);
    placeHolderText.style.position = "absolute";
    placeHolderText.style.top = "0";
    placeHolderText.style.left = "5px";
  }

  // First name placeholder moves up on load if it is already filled
  firstNameplaceHolderOnLoad() {
    let placeHolderText = document.getElementById('hide-placeholder-fname');
    placeHolderText.style.position = "absolute";
    placeHolderText.style.top = "0";
    placeHolderText.style.left = "5px";
  }

  // Last name placeholder moves up on load if it is already filled
  lastNameplaceHolderOnLoad() {
    let placeHolderText = document.getElementById('hide-placeholder-lname');
    placeHolderText.style.position = "absolute";
    placeHolderText.style.top = "0";
    placeHolderText.style.left = "5px";
  }

  getVideoTag() {
    if (!this.videoTag) {
      this.videoTag = this.domSanitizer.bypassSecurityTrustHtml(
        `<video id="bgvid" poster="/assets/common/pr1/img/pbg.jpg" autoplay muted loop playsinline> <source src="/assets/${this.base.domainKey}/videos/bg35.mp4" type="video/mp4">No HTML5 supported.</source> </video>`
      );
    }


      return this.videoTag;
    }
  }

