// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#signup .signup-hero-left {
  width: auto;
}

::ng-deep #signup a {
  color: gray;
}
@media (max-width: 476px) {
  ::ng-deep #signup .email-row {
    flex-direction: column;
  }
}
::ng-deep .user-hidden-blury {
  color: #525252;
  -webkit-user-select: none;
          user-select: none;
  filter: blur(4px);
}`, "",{"version":3,"sources":["webpack://./src/app/themes/ux.3.0/peopleSearch/nameSearch/signup.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAIE;EACE,WAAA;AADJ;AAKI;EADF;IAEI,sBAAA;EAFJ;AACF;AAIE;EACE,cAAA;EACA,yBAAA;UAAA,iBAAA;EACA,iBAAA;AAFJ","sourcesContent":["#signup .signup-hero-left {\n  width: auto;\n}\n\n\n::ng-deep {\n  #signup a {\n    color: gray;\n  }\n\n  #signup .email-row {\n    @media (max-width: 476px) {\n      flex-direction: column;\n    }\n  }\n  .user-hidden-blury {\n    color: #525252;\n    user-select: none;\n    filter: blur(4px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
