import {
    Component,
    OnDestroy,
    OnInit,
    Input,
    ComponentFactoryResolver,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceHelperService } from '@clientCommon/services/serviceHelper.service';
import { LandingAffilateFormComponent } from '@sales/affiliate/affiliateForm.component';
import { UxHelper } from '@clientCommon/helper/uxHelper';
  
@Component({
    selector: 'default-1-2-affiliate-form',
    templateUrl: './affiliateForm.component.html',
    styleUrls: ['./affiliateForm.component.css']
})
export class LegacyAffilateFormComponent extends LandingAffilateFormComponent implements OnInit, OnDestroy {
  @Input() uxHelper: UxHelper;
  @Input() pageType: string;
  @Input() pageCategory: string;
  @Input() page: string;
  //@Input() utmParamValue: utmParamValueType = null;
  constructor(serviceHelperService: ServiceHelperService,
    activatedRoute: ActivatedRoute,
    cfr: ComponentFactoryResolver) {
      super(serviceHelperService, activatedRoute, cfr);
  }

  init() {
    return super.baseInit().then(() => {});
  }

  ngOnInit() {
    return super.ngOnInit().then(() => {
      this.init();
    });
  }

  ngOnChanges() {
    return this.init();
  }
}
  