import {Component, Input, OnInit} from "@angular/core";
import {UxComposite} from "../../../common/models/ux/uxComposite";
import {UxHelper} from "../../../clientCommon/helper/uxHelper";
import {ServiceHelperService} from "../../../clientCommon/services/serviceHelper.service";
import {ActivatedRoute} from "@angular/router";

declare var $: any;

@Component({
  selector: 'credit-card-lightbox',
  templateUrl: 'creditCardLightbox.component.html'
})

/**
 * @fileOverview CreditCardLightboxComponent have modal, it gets called as child component from SignupComponent
 *  - @property base is an input received from the signup component contain all methods and properties needed for this modal execution
 *  - @property uxHelper is an input received from the signup component to render different dynamic html blocks via 'uxTemplate' component
 *    based on UxcompKey
 */
export class CreditCardLightboxComponent implements OnInit {

  @Input() uxComposite: UxComposite;
  @Input() uxHelper: UxHelper;
  @Input() base;

  /**
 *
 * @param serviceHelperService will provide access to different required services such as authentication service, uxc service etc.
 * @parma activatedRoute provides access to information about a route associated with a component that is loaded in an outlet
 *
 */
  constructor(private serviceHelperService: ServiceHelperService,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.addModalTracking();
  }


  // on modal close
  close() {
    // reset all the inputs defined in init of client/src/clientCommon/helper/creditCardInputHelper.ts
    this.base.switchToOldCreditCardInputHelper();
    this.serviceHelperService.trackingService.report({type: "close", id: "cc-lb", trackingKey: this.base.reasonUxcompKey});
  }


  addModalTracking() {
    // Does nothing for now
    // setTimeout(() => {
    //   $('#cc-lb').on('hidden.bs.modal', () => {
    //   });
    // }, 0);

  }

}
