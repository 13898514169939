import {Component, OnDestroy, OnInit} from "@angular/core";
import {SearchResultPeopleSearchSales} from "../../searchResultPeopleSearchSales";
import {BaseComponent} from "../../../../../clientCommon/components/BaseComponent";
import {ServiceHelperService} from "../../../../../clientCommon/services/serviceHelper.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  templateUrl: 'searchResult.component.html'
})
/**
 * SearchResultPhoneSearchSalesComponent render the respective theme/layout component based on current theme
 * @extends SearchResultPeopleSearchSales which further extends SearchResultPeopleSearch to get all required properties
 */
export class SearchResultPhoneSearchSalesComponent extends SearchResultPeopleSearchSales implements OnInit, OnDestroy {

  /**
  *
  * @param serviceHelperService will provide access to different required services such as authentication service, uxc service etc.
  * @param activatedRoute provides access to information about a route associated with a component that is loaded in an outlet.
  */
  constructor(serviceHelperService: ServiceHelperService,
              activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
    this.pageCategory = BaseComponent.PAGE_CATOGORY.phoneSearch;
  }

  ngOnInit() {
    return this.onInit().then(() => {
      this.initDone = true;
    });
  }

  ngOnDestroy() {
    return super.onDestroy();
  }
}
