import {PEOPLE_SEARCH_UX_3_0_THEME_DECLARATIONS} from "./peopleSearch";
import {COMPONENTS_UX_3_0_THEME_DECLARATIONS} from "./components";
import {LOGIN_UX_3_0_THEME_DECLARATIONS} from "./login";
import { AFFILIATE_UX_3_0_THEME_DECLARATIONS } from "./affiliate";

export const UX_3_0_THEME_DECLARATIONS = [
  ...COMPONENTS_UX_3_0_THEME_DECLARATIONS,
  ...PEOPLE_SEARCH_UX_3_0_THEME_DECLARATIONS,
  ...LOGIN_UX_3_0_THEME_DECLARATIONS,
  ...AFFILIATE_UX_3_0_THEME_DECLARATIONS,
];
