export enum UserRemoveInfoSegment {
  // The user already clicked on Remove My Info CTA and utilized the feature
  A = 'A',
  
  // The user already saw the LI(Login Interstitial) and clicked on the No thanks link
  B = 'B',
  
  // The user already saw the LI and clicked on the Navbar link Continue to member experience
  C = 'C',
  
  // The user does not fall into either segments A or B or C
  D = 'D',
}

export namespace UserRemoveInfoSegment {
  export function isValid(segment: string): boolean {
    return segment in UserRemoveInfoSegment;
  }
}

export type UserRemoveInfo = {
  segment: UserRemoveInfoSegment,
};
