import {Component, OnDestroy, OnInit} from "@angular/core";
import {LoaderPeopleSearchSales} from "../../loaderPeopleSearchSales";
import {BaseComponent} from "../../../../../clientCommon/components/BaseComponent";
import {ServiceHelperService} from "../../../../../clientCommon/services/serviceHelper.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  templateUrl: 'loader.component.html'
})
/**
 * LoaderPhoneSearchSalesComponent render the respective theme/layout component based on current theme
 * @extends LoaderPeopleSearchSales which further extends LoaderPeopleSearch to have all the required properties i.e commerceContent, count...
 */
export class LoaderPhoneSearchSalesComponent extends LoaderPeopleSearchSales implements OnInit, OnDestroy {

  /**
  *
  * @param serviceHelperService will provide access to different required services such as authentication service, uxc service etc.
  * @param activatedRoute provides access to information about a route associated with a component that is loaded in an outlet.
  *
  */
  constructor(serviceHelperService: ServiceHelperService,
              activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
    this.pageCategory = BaseComponent.PAGE_CATOGORY.phoneSearch;
  }

  ngOnInit() {
    return this.onInit();
  }

  ngOnDestroy() {
    return super.onDestroy();
  }
}
