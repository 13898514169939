import {Component, Input, OnChanges, OnInit} from "@angular/core";
import {UxHelper} from "../../../../../../clientCommon/helper/uxHelper";
import {LoaderPeopleSearch} from "../../../../loaderPeopleSearch";

@Component({
  selector: 'headless-loader-name-search',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css'],
})
export class HeadlessLoaderNameSearchComponent implements OnInit, OnChanges {
  @Input() base: LoaderPeopleSearch;

  @Input() uxHelper: UxHelper;
  @Input() pageType: string;
  @Input() pageCategory: string;
  @Input() page: string;

  init() {
    if(this.base.reSearchFlag) {
      this.base.reSearch(this.base.reSearchQuestion.value?.yes);
    }

    this.base.serviceHelperService.spinnerService.spin();

    this.base.searchPromise.promise.then(() => {
      this.base.serviceHelperService.spinnerService.unspin();

      this.base.fakeProgressors.current.setProgressMax();
    });
  }

  ngOnInit() {
    return this.init();
  }

  ngOnChanges() {
    return this.init();
  }
}
