import { LogUtils } from "../../../utils/logUtils";
import {SearchedObject} from "./searchedObject";

export class BusinessSearchedPerson {
  status: string;
  sourceType: string;
  businessName: string;
  filingNumber: string;
  filingDate: string;
  filingState: string;
  incorpDate: string;
  url: string;
  names: {
    fName: string;
    mName: string;
    lName: string;
    title: string;
  }[];
  addresses: {
    street1: string;
    street2: string;
    city: string;
    state: string;
    zip: string;
    type: string;
    sourceType: string;
    latitude: number;
    longitude: number;
  }[];
}

export class DivorceSearchedPerson {
  fName: string;
  mName: string;
  lName: string;
  prefix: string;
  suffix: string;
  age: number;
  spouseFName: string;
  spouseMName: string;
  spouseLName: string;
  spousePrefix: string;
  spouseSuffix: string;
  spouseAge: number;
  date: string;
  county: string;
  state: string;
  extId: string;
  address?: string;
  year?: string;
}

export interface NameSlim {
  fullName: string;
  fName: string;
  mName: string;
  lName: string;
}

export interface Debtor {
  fullName: string;
  fName?: string;
  mName?: string;
  lName?: string;
  indicator: string;
  ssnTid: string;
  tid: string;
  address: string;
}

export interface NameAddresses {
  fullName: string,
  fullAddress: string,
}

export enum Relationship {
  coWorker = "Co-Worker",
  neighbor = "Neighbor",
  adressRecordMatched = "Address Record Matched",
  friend = "Friend",
  landlord = "Landlord",
  tenant = "Tenant",
  other = "Other",
  spouse = "Spouse",
  parent = "Parent",
  sibling = "Sibling",
  child = "Child",
  otherRelative = "Other Relative",
  inLaw = "In-Law",
}

export enum thinMatchType {
  DataProviderDown = "DataProviderDown",
  TooManyResults = "TooManyResults",
  NoResults = "NoResults",
  Geographic = "Geographic",
  GeographicNoResults = "GeographicNoResults",
}

export class CourtSearchedPerson {
  nullId: boolean;
  id: string;
  type: string;
  caseNumber: string;
  caseStatus: string;
  documentType: string;
  taxId: string;
  assets: string;
  judgmentAmount: string;
  courtId: string;
  court: string;
  courtPhone: string;
  courtDistrict: string;
  courtDivision: string;
  courtNumber: string;
  courtName: string;
  chapter: string;
  judge: string;
  judgeInitials: string;
  lastDateToFilePoc: string;
  proSeIndicator: string;
  caseStatusDate: string;
  screen: string;
  filingOffice: string;
  recordType: string;
  reportDate: string;
  schedule341MeetingDate: string;
  filingState: string;
  filingDate: string;
  filingType: string;
  filingTypeDesc: string;
  originalLienDate: string;
  dischargedDate: string;
  bankruptcyDate: string;
  convertedDate: string;
  converted: boolean;
  dateCollected: string;
  voluntaryFlag: string;

  // debtorInformation: string;

  debtorA: Debtor;
  debtorB: Debtor;
  debtorStreet1: string = "";
  debtorCity: string = "";
  debtorState: string = "";
  debtorZip: string = "";
  debtors: NameAddresses[] = [];
  debtorAddress: string = "";

  // meetingInformation: string;
  meetingAddress: string = "";
  meetingDate: string = "";
  meetingPhone: string = "";

  // creditorInformation: string;
  creditorFullName: string;
  creditorFName: string = "";
  creditorMName: string = "";
  creditorLName: string = "";
  lienIssuerName: string;
  creditorType: string;
  //otherNames: string;
  creditors: NameAddresses[] = [];

  // attorneyInformation: string;
  lawFirm: string;
  plaintiffLawFirm: string;
  attorneyA: string;
  attorneyB: string;
  attorneyPhone: string;
  county: string;
  attorneyAddress: string;
  attorneyName: string;
  attorneyLawFirm: string;

  // trusteeInformation: string;
  trusteeName: string = "";
  trusteePhoneEmail: string = "";
  trusteeAddress: string = "";
  trusteePhone: string = "";

  // etc
  courtAddress: string;

  //judgmentInformation: string
  ljdInfo: {
    fipsCode: string;
    caseCounty: string;
    caseState: string;
    creditor: string;
    amount: number;
    interestRate: number;
    backSupportAmount: number;
    installmentJudgment: string;
    assignee: string;
    federalTax: {
      area: string;
      city: string;
      state: string;
    }
  }[] = [];

  ljdRecord: {
    deedCategory: string;
    caseDescription: string;
    documentLocation: DocumentLocation;
    originalDocumentLocation: DocumentLocation;
    recordingDate: string;
    date: string;
    damarType: string;
    origRecordingDate: string;
    taxPeriodMax: string;
    taxPeriodMin: string;
    refileExtendLastDate: string;
    abstractIssueDate: string;
    stayOrderedDate: string;
    documentFilingDate: string;
    recordingDocumentYear: string;
    origDocumentDate: string;
  }[] = [];

  courtCaseNumbers: string[];
  taxCertificationNumbers: string[];
  attorneies: string[];
  businesses: string[];
  lienTypes: string[];
  issuingAgency: string[];

  defendant: {
    names: NameSlim[];
    taxPayerId: string[];
    address: string[];
    foreignAddress: string[];
  }[] = [];

  plaintiff: {
    names: NameSlim[];
    taxPayerId: string[];
    address: string[];
    foreignAddress: string[];
  }[] = [];

  debtor: {
    names: {
      fName: string;
      mName: string;
      lName: string;
    }[];
    taxPayerId: string[];
    address: string[];
    foreignAddress: string[];
  }[] = [];

  hoaAddress: string[] = [];

  foreclosure: {
    eid: string,
    meta: {
      firstSeen: number,
      lastSeen: number,
      count: number,
    },
    details: {
      recordType: string,
      documentDate: string,
      documentNumber: string,
      documentType: string,
      originalLoanDate: string,
      trustee: string,
      trusteeAddress: string,
      titleCompanyName: string,
      delinquentDate: string,
      defaultPrincipalBalance: number,
      beneficiary: string,
      beneficiaryAddress: string,
      auctionDate: string,
      auctionTime: string,
      auctionAddress: string,
      defaultAmount: number,
      trusteeSaleDate: string,
      trusteeSaleAddress: string,
      address: string,
    }[],
    trustors: {
      name: string,
    }[],
  };

  static fromForeclosures(foreclosure): CourtSearchedPerson {
    const result = new CourtSearchedPerson();

    result.foreclosure = {
      eid: foreclosure?.eid,
      meta: {
        // sources: foreclosure?.meta?.sources,
        // fieldSources: foreclosure?.meta?.fieldSources,
        firstSeen: foreclosure?.meta?.firstSeen,
        lastSeen: foreclosure?.meta?.lastSeen,
        count: foreclosure?.meta?.count,
      },
      details: foreclosure?.detail?.length > 0 ? foreclosure.detail.map((detail) => {
        return {
          recordType: detail?.recordType,
          documentDate: detail?.documentDate?.data,
          documentNumber: detail?.documentNumber,
          documentType: detail?.documentType,
          originalLoanDate: detail?.originalLoanDate?.data,
          trustee: detail?.trustee?.data,
          trusteeAddress: detail?.trusteeAddress?.complete,
          titleCompanyName: detail?.titleCompanyName,
          delinquentDate: detail?.delinquentDate?.data,
          defaultPrincipalBalance: detail?.defaultPrincipalBalance,
          beneficiary: detail?.beneficiary?.data,
          beneficiaryAddress: detail?.beneficiaryAddress?.complete,
          auctionDate: detail?.auctionDate?.data,
          auctionTime: detail?.auctionTime,
          auctionAddress: detail?.auctionAddress?.complete,
          defaultAmount: detail?.defaultAmount,
          trusteeSaleDate: detail?.trusteeSaleDate?.data,
          trusteeSaleAddress: detail?.trusteeSaleAddress?.complete,
          address: [
            detail?.address?.complete,
            detail?.address?.county,
            detail?.address?.city,
            detail?.address?.state,
            detail?.address?.zip,
          ].filter(e => !!e).join(' '),
        };
      }) : [],
      trustors: foreclosure?.trustor?.length > 0 ? foreclosure.trustor.map((trustor) => {
        if (!trustor?.name?.data) {
          return;
        }

        return {
          name: trustor?.name?.data,
        };
      }).filter(e => !!e) : [],
    };
    
    return result;
  }
};

export class PropertySearchedPerson {
  address: {
    fullAddress: string,
    latitude: number,
    longitude: number,
    zip: string,
  };
  mailingAddresses: string[] = [];
  apn: string;
  purchasePrice: string;
  assessedValue: string;
  filingDate: string;
  legalDescription: string;
  subdivision: string;
  owners: string[];
  bedroom: number;
  bathroom: number;
  distress: string;
  yearBuilt: string;
  buildingSqft: number;
  lotSqft: number;
  ownershipStatus: string;
  useCode: string;
  propertyDescription: string;
  parcelNumber: string;
  county: string;
  state: string;
  zip: string;
  zip4: string;
  exemption: number;
  sellers: string[];
  taxYear: string;
  marketValue: number;
  landValue: number;
  improvementValue: number;
  totalTax: number;
  assessmentDate: string;
  assessorYear: string;
  firstSeen: number;
  lastSeen: number;

  history: {
    detail: {
      receiptDate: string;
      transferDate: string;
      salesPrice: number;
      transferType: string;
      deedType: string;
      quitclaimFlag: string;
      armsLengthFlag: string;
      docNumber: string;
      docNumberFormat: string;
      distress: string;
    };

    loan: {
      docNumber: string;
      estimatedInterestRate: number;
      loanValue: number;
      loanType: string;
      lenderCode: string;
      lenderLastName: string;
      lenderFirstName: string;
      lenderType: string;
      interestRateType: string;
      lenderCreditLine: string;
    }[];

    buyer: string[];
    seller: string[];
    isCurrentOwner: boolean;
  }[];
}

export interface DateLocation {
  data: string;
  location: string;
  county: string;
  state: string;
  date: string;
}

export interface AddressSlim {
  street1?: string,
  street2?: string,
  city: string,
  state: string,
  zip: string,
  fullAddress: string,
  latitude: number,
  longitude: number,
}

export class AddressFull {
  firstReported: string;
  fullAddress: string;
  street1?: string;
  street2?: string;
  city: string;
  state: string;
  zip: string;
  latitude: number;
  longitude: number;
  neighbors?: SearchedPerson[];
  streetNumber?: string;
  street?: string;
  streetSuffix?: string;
  aptName?: string;
  aptNum?: string;
  zip4?: string;
  dwellingType?: string;
  postdir?: string;
  predir?: string;
  dateRange?: string;
  county?: string;
  ownershipStatus: string;
}

export interface DocumentLocation {
  fullNumber: string;
  docNumber: string;
  bookNumber: string;
  pageNumber: string;
}

export class CriminalRecordSearchedPerson {
  nullId: boolean;
  id: string;
  category: string;
  caseType: string;
  // linkToViewAllDetails: string;
  court: string;
  courtCounty: string;
  source: string;
  caseNumber: string;

  // subjectIdentification
  fName: string;
  mName: string;
  lName: string;
  fullName: string;
  suffix: string;
  prefix: string;
  gender: string;
  akaFlag: string;
  akas: string[] = [];
  dob: string;
  age: string;
  addresses: string [] = [];

  // description
  race: string;
  skinTone: string;
  sex: string;
  height: string;
  weight: string;
  hair: string;
  eyes: string;
  scarMarks: string;
  ethnicity: string;
  bodyBuild: string;
  militaryService: string;
  citizenship: string;
  statusRemarks: string;
  images: string[] = [];

  // offenseInformation
  offenseCode: string;
  offenseDate: string;
  offenseDescription: string;
  chargesFileDate: string;
  convictionDate: string;
  convictionPlace: string;
  disposition: string;
  dispositionDate: string;
  sentenced: string;
  fine: string;
  plea: string;
  probationDate: string;
  county: string;
  state: string;
  birthState: string;

  driverLicense: {
    expYear: string;
    number: string;
    state: string;
  };

  sexOffender: {
    id: string;
    databaseId: string;
    registerDate: string;
    riskLevel: string;
    classification: string;
  };

  bodyMark: {
    item: string;
    description: string;
  }[] = [];

  vehicle: string[];

  offense: {
    caseNumber: string;
    chargesFiledDate: string;
    date: string;
    code: string;
    location: string;
    description: string;
    statute: string;
    class: string;
    degree: string;
    level: string;
    ncicCode: string;
    counts: string;
    plea: string;
    conviction: {};
    warrant: DateLocation;
    supervision: DateLocation;
    commitment: DateLocation;
    disposition: DateLocation;
    amendedDisposition: DateLocation;
    arrest: DateLocation;
    sentence: string;
    probation: string;
    suspended: string;
    court: string;
    courtCosts: string;
    caseType: string;
    fines: string;
    countyOrJurisdiction: string;
    releaseDate: string;
    fbiNumber: string;
    bookingNumber: string;
    dlNumber: string;
    amendedCharge: string;
    amendedCaseType: string;
    status: string;
    statusRemarks: string;
    restitution: string;
    comments: string;
    victimAge: string;
    victimGender: string;
    photoName: string;
    category: string;
    sourceState: string;
    sourceName: string;
  }[] = [];
}

export class SearchedPerson extends SearchedObject<SearchedPerson> {
  originalOrder: number;
  dob: any;
  dateOfDeath: string;
  age: number;
  bio: string;

  // Name
  fName: string;
  mName: string;
  lName: string;
  fullName: string;
  searchData: string;
  //email: string;  // new add
  prefix: string;
  suffix: string;
  photos: any[] = [];
  gender: string;
  dead: boolean;
  subType: string;

  akas: {
    fName: string;
    mName: string;
    lName: string;
    prefix: string;
    suffix: string;
  }[] = [];

  addresses: AddressFull[] = [];
  cityState: string;

  reversePhoneInfo: any;
  reversePhoneAddresses: {
    street1?: string,
    street2?: string,
    city: string,
    state: string,
    zip: string,
  }[] = [];

  // Phones
  phones: {
    number: string,
    carrier?: string,
    type?: string,
    connected?: boolean,
    fake?: boolean,
    highFrequency?: boolean,
    business?: boolean,
  }[] = [];
  associates: SearchedPerson[] = [];
  relatives: SearchedPerson[] = [];
  relationship: Relationship;

  qualifications: string[] = [];
  qualified: boolean;
  qualifiedScore: number;

  emailAddresses: {
    address: string,
    meta: any
  }[] = [];

  ipAddresses: {
    ipAddress: string;
    firstSeen: number;
    lastSeen: number;
  }[] =[];

  educations: {
    degree: string,
    major: string,
    school: string,
    start,
    end,
  }[] = [];

  workExperiences: {
    jobTitle: string,
    company: string,
    companyDetail: string,
    companyUrl: string,
    companyLocation?: string,
    start: Date,
    end: Date,
    current: boolean,
  }[] = [];

  properties: PropertySearchedPerson[] = [];

  criminalRecords: CriminalRecordSearchedPerson[] = [];
  sexOffenderFlag: boolean;

  consumerProfile: {
    gender?,
    maritalStatus?,
    netWorth?,
    numberOfChildren?,
    occupation?,
    occupationGroup?,
  } = {};

  household: {
    adults?: number,
    children?: number,
    dwellingType?: number,
    yearsInHome?: number,
    livingStatus?: number,
    pets?: string,
    elderlyParent?: number,
  } = {};

  finance: {
    estHomeLoanToValue?,
    estHouseholdIncome?,
    estDiscretionaryIncomeOfTotal?,
    estDebt?,
    estCash?,
    netWorth?,
    bankCards?,
    retailCards?,
    activeLineOfCredit?,
  } = {};

  businesses: BusinessSearchedPerson[] = [];

  feins: {
    fein: string,
    businessType: string,
    legalName: string,
    tradeName: string,
    dba: string,
    fbn: string,
    fka: string,
    businessSpecialty: string,
    url: string,
    cbsa: string,
    cbsaName: string,
    dma: string,
    dmaName: string,
    naics: string,
    naicsDescription: string,
    companyStartYearMonth: string,
    sicCode: string,
    sicDescription: string,
  }[] = [];

  licenses: {
    category: string,
    type: string,
    status: string,
    issueDate: string,
    expirationDate: string,
    licenseNumber: string,
    licenseState: string,
    addresses: {
      street1: string,
      street2: string,
      city: string,
      state: string,
      zip: string,
      type: string,
      sourceType: string,
      latitude: number,
      longitude: number,
      fullAddress: string,
    }[],
    names: {
      fName: string,
      mName: string,
      lName: string,
      gender: string,
      prefix: string,
      suffix: string,
      extId: string,
    }[],
  }[] = [];

  marriages: {
    fName: string,
    mName: string,
    lName: string,
    prefix: string,
    suffix: string,
    age: number,

    spouseFName: string,
    spouseMName: string,
    spouseLName: string,
    spousePrefix: string,
    spouseSuffix: string,
    spouseAge: number,
    date: string,
    county: string,
    state: string,
    extId: string,
    year: string,
    address: string,
  }[] = [];

  divorces: DivorceSearchedPerson[] = [];

  foreclosures: {
    address: AddressSlim,
    parcelNumber: string,
    recordingDate: string,
    documentNumber: string,
    companyName: string,
    county: string,
    state: string,
    trustOrBusiness: string,
    municipalName: string,
    trusteeSaleNumber: string,
    trusteePhone: string,
    loanInfo: {
      originalLoanDocumentNumber: string,
      originalLoanAmount: string,
      originalLoanDate: string,
      principalBalance: string,
      defaultAmount: string,
      delinquentDate: string,
      delinquentAmount: string,
    },
    debtors: {
      fName: string,
      mName: string,
      lName: string,
      prefix: string,
      suffix: string,
      gender: string,
      extId: string,
    }[],
    beneficiaries: {},
    trustees: {}[],
    auctionSale: {
      auctionBidAmount: string,
      noticeOfSaleTime: string,
      noticeOfSaleDate: string,
      noticeOfSaleAddress: string,
      defaultNoticeOfSaleTime: string,
      defaultNoticeOfAuctionDate: string,
    },
  }[] = [];

  courts: CourtSearchedPerson[] = [];

  domains: {
    address: AddressSlim,
    fName: string,
    mName: string,
    lName: string,
    domain: string,
    creationDate: string,
    contactType: string,
    emails: string[],
    phones: string[],
  }[] = [];

  evictions: {
    caseNumber: string,
    fileType: string,
    courtId: string,
    filingState: string,
    liabilityAmount: string,
    dateReported: string,
    dateFiled: string,
    lastUpdated: string,
    unlawfulDetainer: string,
    plaintiffs: {
      ownerFlag: string,
      fName: string,
      mName: string,
      lName: string,
      extId: string,
    }[],
    defendants: {
      businessFlag: string,
      fName: string,
      mName: string,
      lName: string,
    }[],
    addresses: AddressSlim,
  }[] = [];

  profiles: {
    service: string,
    url: string,
    photos: string[],
  }[] = [];

  indicators: {
    isPropertyOwner?: boolean,
    hasBankruptcy?: boolean,
    hasLien?: boolean,
    hasJudgment?: boolean,
    hasVehicle?: boolean,
    isCriminal?: boolean,
    hasEmail?: boolean,
    hasForeclosure?: boolean,
    hasEmployment?: boolean,
    hasAssociatedBusiness?: boolean,
    hasAircraft?: boolean,
    hasProfessionalLicense?: boolean,
    hasPhone?: boolean,
    hasMobilePhone?: boolean,
    hasResidentialPhone?: boolean,
    hasIP?: boolean,
    hasAddress?: boolean,
    hasRelatives?: boolean,
    // add
    propertyCount?: string,
    bankruptcyCount?: string,
    lienCount?: string,
    judgmentCount?: string,
    criminalCount?: string,
    emailCount?: string,
    foreclosureCount?: string,
    employmentCount?: string,
    associatedBusinessCount?: string,
    aircraftCount?: string,
    professionalLicenseCount?: string,
    phoneCount?: string,
    mobilePhoneCount?: string,
    residentialPhoneCount?: string,
    ipCount?: string,
    addressCount?: string,
    relativeCount?: string,
  } = {};

  constructor(doc?, draftFlag?: boolean) {
    super(doc, draftFlag);
    this.init(doc, this.draftFlag);
  }

  getSecuredDoc(): SearchedPerson {
    var obj: SearchedPerson = super.getSecuredDoc();

    return obj;
  }

  getCityStates(): string[] {
    let cityStates = [];
    if (this.addresses) {
      this.addresses.forEach((address) => {
        let cityState = this.formatCityState(address.city, address.state);
        if (cityStates.indexOf(cityState) === -1 && !!address.city && !!address.state) {
          cityStates.push(cityState);
        }
      });
    }

    return cityStates;
  }

  hasCityStates(cityStates): boolean {
    let flag = false;
    if (this.addresses) {
      this.addresses.some((address) => {
        let currentCityState = this.formatCityState(address.city, address.state);

        cityStates.some((cityState) => {
          if (currentCityState === cityState) {
            flag = true;
            return true;
          }
        });
        if (flag) {
          return true;
        }
      });
    }

    return flag;
  }

  formatCityState(city, state): string {
    return `${city}, ${state}`;
  }

  getRelatives(): string[] {
    let relatives = [];

    if (this.relatives) {
      this.relatives.forEach((relative) => {
        relatives.push(this.formatName(relative.fName, relative.mName, relative.lName));
      });
    }

    return relatives;
  }

  hasRelatives(fullnames): boolean {
    let flag = false;
    if (this.relatives) {
      this.relatives.some((relative) => {
        let currentName = this.formatName(relative.fName, relative.mName, relative.lName);
        fullnames.some((fullname) => {
          if (currentName === fullname) {
            flag = true;
            return true;
          }
        });
        if (flag) {
          return true;
        }
      });
    }

    return flag;
  }

  moreThanAge(age): boolean {
    let flag = false;
    if (this.age) {
      flag = this.age > age;
    }

    return flag;
  }

  formatName(firstName, middleName, lastName) {
    let fullname = firstName;

    if (middleName) {
      if (fullname) {
        fullname += " ";
      }
      fullname += middleName;
    }

    if (lastName) {
      if (fullname) {
        fullname += " ";
      }
      fullname += lastName;
    }

    return fullname;
  }

  addProfiles(profiles: any[]) {
    if (profiles && profiles.length) {
      profiles.forEach((profile) => {
        let exist = false;
        this.profiles.some((currentProfile) => {
          if (currentProfile.url === profile.url) {
            exist = true;
            return true;
          }
        });
        if (!exist) {
          this.profiles.push(profile);
        }
      });
    }
  }

  getUniquePhotos() {
    let photos: {
      service: string,
      profileUrl: string,
      url: string,
    }[] = [];
    if (this.profiles) {
      let uniqPhotos = [];
      this.profiles.forEach((profile) => {
        if (profile.photos) {
          profile.photos.forEach((photo) => {
            if (uniqPhotos.indexOf(photo) === -1) {
              uniqPhotos.push(photo);
              photos.push({
                service: profile.service,
                profileUrl: profile.url,
                url: photo,
              });
            }
          });
        }
      });
    }

    return photos;
  }

  static getMainCoordinate(person: any): {latitude?: number, longitude?: number} {
    try {
      if (person?.properties?.length) {
        const found = person.properties.find((e) => e?.address?.latitude && e?.address?.longitude);
  
        if (found) {
          return {
            latitude: found.address.latitude,
            longitude: found.address.longitude,
          };
        }
      }
  
      if (person?.addresses?.length) {
        const found = person.addresses.find((e) => e?.latitude && e?.longitude);
  
        if (found) {
          return {
            latitude: found.latitude,
            longitude: found.longitude,
          };
        }
      }
  
      return {};
    } catch (e) {
      LogUtils.warn('SearchedPerson.getMainCoordinates. Not Found Coordinates');

      return {};
    }
  }

  static getMainZip(person: any): string {
    if (person?.properties?.length) {
      const found = person.properties.find((e) => e?.address?.zip);

      if (found) {
        return found.address.zip;
      }
    }

    if (person?.addresses?.length) {
      const found = person.addresses.find((e) => e?.zip);

      if (found) {
        return found.zip;
      }
    }

    return null;
  }
}
