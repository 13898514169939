import { Component, NgModule, OnInit } from "@angular/core";
import { BaseComponent } from "@clientCommon/components/BaseComponent";
import { ServiceHelperService } from "@clientCommon/services/serviceHelper.service";
import { ActivatedRoute, Router, NavigationEnd} from "@angular/router"
import { Location } from "@angular/common";
import { filter } from 'rxjs/operators';

import { redirectHelper } from "@clientCommon/helper/redirectHelper";
import { clientPaths, serverPaths } from "@common/helpers/pathHelpers";
import { CommonModule } from "@angular/common";
import { LogUtils } from "@common/utils/logUtils";
import { UxComposite } from "@common/models/ux/uxComposite";
import { ResponseEvent } from "@common/event/responseEvent";
import { RequestEvent } from "@common/event/requestEvent";
import { CommerceContent } from "@common/models/commerce/commerceContent";
import { peopleSearchContentHelper } from "@common/custom/peopleSearch/peopleSearchHelper";
import { SearchedPerson } from "@common/custom/models/peopleSearch/searchedPerson";
import { urlUtils } from "@clientCommon/utils/urlUtils";
import { SearchResultPeopleSearch } from "@peopeSearch";
import {peopleSearchProductKeys} from "../../common/custom/peopleSearch/peopleSearchProductKeys";
import { UserInput } from "@common/models/user/userInput";

@Component({
    templateUrl: '../../clientCommon/templates/generic.html',
})
export class ShareMiniReportLinkComponent extends SearchResultPeopleSearch implements OnInit {
    message: string;
    defaultUrl = '/name/landing';
    site;
    clientPaths = clientPaths;
    domain;
    uxComposite: UxComposite;
    //commerceContent: CommerceContent;
    searchedPersons: SearchedPerson[] = [];
    userInput: UserInput;

    constructor(serviceHelperService: ServiceHelperService,
        private router: Router,
        activatedRoute: ActivatedRoute) {
        super(serviceHelperService, activatedRoute);
        this.pageType = BaseComponent.PAGE_TYPE.general;
        this.pageCategory = "general";
        this.page = "shareLink";

        this.site = urlUtils.get2ndLevelDomain(window.location.hostname);
    } 

    ngOnInit() {
        LogUtils.debug('share link called...');        
        return this.baseInit().then(() => {
            return this.activatedRoute.params.subscribe((params) => {
                LogUtils.debug(params);
                let hash = params.target;
                if (hash) {
                    const requestEvent = new RequestEvent();
      
                    requestEvent.param =  { hash: hash };

                    LogUtils.debug('commerceContent id : ', hash); 
                    this.serviceHelperService.jsonService.json("/" + serverPaths.shareFindData, requestEvent)
                    .then((responseEvent: ResponseEvent) => {
                        return responseEvent.getSingleDoc();
                    })
                    .then((commerceContent: CommerceContent) => {
                      peopleSearchContentHelper.processResult(
                        this.uxComposite,
                        commerceContent
                      );
                      this.commerceContent = commerceContent;

                      // track report
                      let param = {
                        page: BaseComponent.PAGE.shareLink,
                        pageType: BaseComponent.PAGE_TYPE.general,
                        type: 'shareLink',
                        action: 'click'  // click, joined
                      };
                      this.serviceHelperService.trackingService.report(param);  

                      const shareLink = { hash: hash };
                      this.serviceHelperService.storageService.setSession('shareLink', shareLink);

                      try {
                        let personData = commerceContent.getMainRaw().tempClient.processed.person[0];        
                        this.searchedPersons = commerceContent.getMainRaw().tempClient.processed.person;
                        
                        LogUtils.debug(this.searchedPersons);
                        this.loadCdn(personData);
                      } catch (e) {
                        LogUtils.error(e);
                      }
                    })
                    .catch((e) => {
                      LogUtils.error(e);
                    });                                                                                         
                }
            });
        });
    }

    loadCdn(personData){      
        this.serviceHelperService.spinnerService.spin();  
        this.domain = window.location.href.split("www.").pop().replace(".", "-").split('/').shift();    
        LogUtils.debug('loadCdn called', personData, this.domain);
        // this.router.events.pipe(
        //   filter(event => event instanceof NavigationEnd)
        // ).subscribe((event: NavigationEnd) => {
          try {
            LogUtils.debug(personData);
            let keyPrefix = `comp.${this.pageType}.${this.pageCategory}.`;
            let uxcKeys = [];

            uxcKeys.push(keyPrefix + BaseComponent.PAGE.searchResult);
            uxcKeys.push(keyPrefix + BaseComponent.PAGE.reportReview);

            this.userInput.extId = personData.extId;
            if (this.commerceContent.productKey === peopleSearchProductKeys.phoneSearch) { 
                this.userInput.phone = personData.phones[0].number;
            }else {
                this.userInput.fname = personData.fName;            
                this.userInput.lname = personData.lName;
                this.userInput.city = personData.cityState.split(',')[0];
                this.userInput.state = personData.cityState.split(',')[1];
            }
            //LogUtils.debug(productKey, this.userInput, this.pageNo, this.perPage, uxcKeys);
            this.serviceHelperService.peopleSearchService.peopleSearch(this.commerceContent.productKey, this.userInput, 1, 
                BaseComponent.PAGE.reportReview, uxcKeys).then((commerceContent => {
                    
                    peopleSearchContentHelper.processResult(
                        this.uxComposite,
                        commerceContent
                      );
                    this.commerceContent = commerceContent;
                    let detailPersonData = commerceContent.getMainRaw().tempClient.processed.person[0];        
                    this.searchedPersons = commerceContent.getMainRaw().tempClient.processed.person;

                    LogUtils.debug(commerceContent);
                    // Phase 1. General logic
                    
                    if (!commerceContent) {
                        return;
                    }
                    // // Phase 2. CommerceContent logic
                    // commerceContent.content.contentInfo = this.userInput
                    // if (this.commerceContent) {
                    //     Object.keys(commerceContent.content).forEach((key) => {
                    //     this.commerceContent.content[key] = this.commerceContent.content[key].concat(commerceContent.content[key]);
                    //     });
                    // } else {
                    //     Object.keys(commerceContent.content).forEach((key) => {
                    //     this.commerceContent = commerceContent;
                    //     });
                    // }
                    let pageCategory = BaseComponent.PAGE_CATOGORY.nameSearch;
                    if (this.commerceContent.productKey === peopleSearchProductKeys.phoneSearch) { 
                        pageCategory = BaseComponent.PAGE_CATOGORY.phoneSearch;
                        this.uxComposite.setCode("contentInfo", {
                            phone: this.userInput.phone
                        });
                    }else {
                        this.uxComposite.setCode("contentInfo", {
                            fname: this.userInput.fname,
                            lname: this.userInput.lname,
                            state: this.userInput.state,
                            phone: this.userInput.phone
                        });
                    }
                    this.shareSubmit(detailPersonData, pageCategory); // this.searchedPersons);
                }));

          }catch(e) {
            LogUtils.debug(e);
          }
        // });
        this.serviceHelperService.spinnerService.unspin();
      }

    getUrlParam(param) {
        let result = decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search) || [null, ''])[1].replace(/\+/g, '%20')) || null;

        LogUtils.debug(location);
        if (result !== null) {
          // capitalize first letter
          return result.charAt(0).toUpperCase() + result.slice(1);
        }
        return null;
    }
}
// @NgModule({
//     declarations: [ReferralLinkComponent],
//     imports: [CommonModule],
//     exports: [ReferralLinkComponent],
// })
// export class ReferralLinkComponentModule {}