import { CollectionClass } from "../../decorators/database/collectionClass";
import { ModelBase, ServerMeta } from "../modelBase";
import { IndexField } from "../../decorators/database/indexField";
import { CommerceOrder } from "./commerceOrder";
import { ForeignKeyField } from "../../decorators/database/foreignKeyField";
import { ReferenceField } from "../../decorators/database/referenceField";
import { Raw } from "../raw";
import { TransientField } from "../../decorators/database/transientField";
import { ForeignObjectField } from "../../decorators/database/foreignObjectField";
import { Progressor } from "../progressor";
import { timeUtils } from "../../utils/timeUtils";
import { peopleSearchProductKeys } from "../../custom/peopleSearch/peopleSearchProductKeys";
import { User } from "../user/user";
import { ServerField } from "../../decorators/database/serverField";
import { UxComposite } from "../ux/uxComposite";
import { LogUtils } from "../../utils/logUtils";


@CollectionClass({ name: "commerceContents", revisionEnabled: false, draftEnabled: false })
export class CommerceContent extends ModelBase<CommerceContent> {
  @IndexField() declare subStatus;
  @IndexField() subType;

  @IndexField() @ReferenceField(CommerceOrder) @ForeignKeyField(CommerceOrder) commerceOrderId: string;

  @IndexField() @ReferenceField(Raw) @ForeignKeyField(Raw) rawId: string;
  @TransientField @ForeignObjectField("rawId") raw: Raw;

  @IndexField() @ReferenceField(Raw) @ForeignKeyField(Raw) rawIds: string[] = [];
  @TransientField @ForeignObjectField("rawIds") raws: Raw[] = [];

  @ReferenceField(User) @ForeignKeyField(User) ownerId: string;
  @TransientField @ForeignObjectField("ownerId") owner: User;

  @IndexField() extHash: string;
  @IndexField() searchQueries: string[];

  @ServerField serverMeta: ServerMeta = {};

  public static RELATIONSHIP_CODE = {
    father: 'father',
    mother: 'mother',
    son: 'son',
    daughter: 'daughter',
    brother: 'brother',
    sister: 'sister',
    relative: 'relative',
    business: 'business',
    friend: 'friend',
    neighbor: 'neighbor',
    romantic: 'romantic',
    other: 'other'
  };

  public static SUB_STATUS = {
    cloned: "cloned",
  };

  content: any;
  providers: string[];
  productKey: string;
  teaser: boolean;
  process: number = 0; // count for CommerceOrder.processed
  lastViewedTimestamp: number;
  reportViews: number = 0;
  relationShip: string;

  @IndexField() @ReferenceField(Progressor) @ForeignKeyField(Progressor) progressorIds: string[];
  @TransientField @ForeignObjectField("progressorIds") progressors: Progressor[];

  reportOwnerId: string = '';

  constructor(doc?, draftFlag?: boolean) {
    super(doc, draftFlag);
    this.init(doc, this.draftFlag);
    this.rawToRaws(); // Need to remove after all transition
  }

  // Need to remove after all transition
  rawToRaws() {
    //Fix for rawId, raw properties to rawIds, raws[]
    if (this.rawId) {
      this.rawIds.push(this.rawId);
      this.rawId = undefined; // need to confirm, no uxcomp uses this
    }
    if (this.raw) {
      this.raws.push(this.raw);
      this.raw = undefined; // need to confirm, no uxcomp uses this
    }
  }

  getClientDoc(): CommerceContent {
    let obj: CommerceContent= super.getClientDoc();
    delete obj.subStatus;

    return obj;
  }
  getSecuredDoc(): CommerceContent {
    let obj: CommerceContent = super.getSecuredDoc();
    obj.teaser = this.teaser;
    obj.productKey = this.productKey;
    delete obj.subStatus;

    return obj;
  }

  isSubscribed() {
    return !!this.data.subscribed;
  }

  setSubscribe(flag) {
    this.data.subscribed = flag;
    this.data.subscribeTimestamp = timeUtils.getTimestamp();
  }

  hasUpdate() {
    return !!this.data.updatedTimestamp;
  }

  setUpdate(value) {
    this.data.updatedTimestamp = value;
  }

  setMainRawIndex(index) {
    this.tempClientSecured.mainRawIndex = index;
  }

  getMainRaw(): Raw {
    let index = this.tempClientSecured.mainRawIndex ? this.tempClientSecured.mainRawIndex : 0;
    if (this.raws && this.raws.length) {
      return this.raws[index];
    }

    return null;
  }

  getSexOffenderRaw(): Raw {
    if (!this.raws || !this.raws.length) {
      return null;
    }

    return this.raws.find(this.findRaw(peopleSearchProductKeys.sexOffender));
  }

  setServerMetaForExistingTeaser(
    params: {uxComposite: UxComposite}, 
    options: { draft: boolean } = { draft: true },
  ) {
    try {
      const filterString = CommerceContent.getSearchFilterString({ uxComposite: params.uxComposite });
  
      const obj = options.draft ? this.draft : this;
  
      obj.serverMeta['searchFilter'] = filterString;
    } catch (e) {
      LogUtils.error('CommerceContent.setServerMetaForExistingTeaser Error', e);
    }
  }

  private findRaw(productKey: string) {
    return (raw: Raw) => raw?.meta?.productKey === productKey;
  }

  static getSearchFilterString(params: { uxComposite: UxComposite }) {
    try {
      let searchFilter = params.uxComposite.get("comp.peopleSearch.filters");

      if (!searchFilter || !Array.isArray(searchFilter)) {
        searchFilter = [];
      }
  
      return JSON.stringify(searchFilter);
    } catch (e) {
      LogUtils.error('CommerceContent.getSearchFilterString Error', e);

      return null;
    }
  }
}

