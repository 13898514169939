import {CollectionClass} from "../../decorators/database/collectionClass";
import {ModelBase} from "../modelBase";
import {IndexField} from "../../decorators/database/indexField";
import {CommercePayment} from "./commercePayment";
import {ForeignKeyField} from "../../decorators/database/foreignKeyField";
import {TransientField} from "../../decorators/database/transientField";
import {ForeignObjectField} from "../../decorators/database/foreignObjectField";
import {CreditCardUtils, creditCardUtils} from "../../utils/creditCardUtils";

@CollectionClass({name: "commerce3ds", revisionEnabled: false, draftEnabled: false})
export class Commerce3ds extends ModelBase<Commerce3ds> {

  @IndexField() merchantId: string;
  @IndexField({unique: true}) xid: string;

  gracePeriod: number;
  hidden: boolean;

  bin: string;
  ccExpMonth: string;
  ccExpYear: string;
  ccLength: number;
  amount: string;
  currency: string;

  acsUrl: string;
  pareq: string;
  enrolled: string;
  verifyError: string;
  verifyTimestamp: number;

  pares: string;
  cavv: string;
  cavvAlgorithm: string;
  eci: string;
  authTimestamp: number;
  authError: string;

  verifyRaw: string;
  processRaw: string;

  @IndexField() @ForeignKeyField(CommercePayment) appliedCommercePaymentIds = [];
  @TransientField @ForeignObjectField("appliedCommercePaymentIds") appliedCommercePayments: CommercePayment[];

  constructor(doc?, draftFlag?: boolean) {
    super(doc, draftFlag);
    this.init(doc, this.draftFlag);
  }

  getSecuredDoc(): Commerce3ds {
    var obj: Commerce3ds = super.getSecuredDoc();

    return obj;
  }

  getFormattedAmount() {
    return this.amount;
  }

  isEnrolled(): boolean {
    return this.enrolled === "Y";
  }

  isAttempt(): boolean {
    var eciFlag = false;
    if (this.eci) {
      eciFlag = !!this.eci.match(/^(06|6|01|1)$/);
    }

    return this.enrolled === "N" || this.status === "N" || eciFlag;
  }

  isVerified(): boolean {
    return this.status === "Y";
  }

  isProtected(): boolean {
    return this.isAttempt() || this.isVerified();
  }
}

