import { Component, NgModule } from '@angular/core';

@Component({
    selector: 'member-upsell-associates-features',
    templateUrl: './associates-features.component.html',
    standalone: false
})
export class MemberUpsellAssociatesFeaturesComponent {}

@NgModule({
  declarations: [MemberUpsellAssociatesFeaturesComponent],
  exports: [MemberUpsellAssociatesFeaturesComponent],
})
export class MemberUpsellAssociatesFeaturesComponentModule {}
