import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';

@Component({
  selector: 'app-member-closure',
  templateUrl: 'memberClosure.component.html',
})
export class MemberClosureComponent {}

@NgModule({
  declarations: [MemberClosureComponent],
  imports: [CommonModule],
  exports: [MemberClosureComponent],
})
export class MemberClosureComponentModule {}
