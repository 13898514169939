import {LogUtils} from "./logUtils";

export class PhoneUtils {

  formatPhoneNumber(value) {
    let formatted = "";
    if (value) {
      value = value.replace(/(?:(?:^[^0-9]*1*)|(?:[^0-9]))/g, "");
      let length = value.length;
      if (length > 0) {
        formatted = "(" + value.substring(0, 3);
      }
      if (length > 3) {
        formatted += ") " + value.substring(3, 6);
      }
      if (length > 6) {
        formatted += "-" + value.substring(6, 10);
      }
    }
    return formatted;
  }

  validDigits(value) {
    return value && value.replace(/(?:(?:^[^0-9]*1*)|(?:[^0-9]))/g, "").length === 10;
  }

  getValidatedPhoneNumber(value: string) {
    if (value) {
      try {
        const digitOnly = value.replace(/[^\w]/gi, '');
        return digitOnly.slice(digitOnly.length - 10, digitOnly.length);
      } catch (e) {
        LogUtils.error(e);
      }
    }
    return value;
  }
}

export var phoneUtils = new PhoneUtils();
