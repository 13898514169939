import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {UxComposite} from "../../common/models/ux/uxComposite";
import {BaseComponent} from "../../clientCommon/components/BaseComponent";
import {ServiceHelperService} from "../../clientCommon/services/serviceHelper.service";
import {LogUtils} from "../../common/utils/logUtils";
import {collectionClassHelper} from "../../common/decorators/database/collectionClass";
import {Content} from "../../common/models/content";
import {UxHelper} from "../../clientCommon/helper/uxHelper";
import {redirectHelper} from "../../clientCommon/helper/redirectHelper";
import {customPathChanger} from "../../clientCommon/utils/customPathChanger";

@Component({
    templateUrl: 'customPage.component.html',
    standalone: false
})

export class CustomPageComponent extends BaseComponent implements OnInit, OnDestroy {
  uxHelper: UxHelper = new UxHelper();
  uxComposite: UxComposite;
  pageType = "sales";
  pageCategory = "general";
  page = "page";
  content: Content;

  constructor(serviceHelperService: ServiceHelperService,
              private router: Router,
              activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
    this.pageType = BaseComponent.PAGE_TYPE.sales;
    this.pageCategory = BaseComponent.PAGE_CATOGORY.general;
    this.page = BaseComponent.PAGE.page;
  }

  ngOnInit() {
    let path = this.getPath();
    this.page = path;

    return this.baseInit().then(() => {
      let config = this.getCustomConfig();
      LogUtils.debug(config);
      if (!config) {
        let newPath = customPathChanger.getNewPathFromOldPath(path);
        const query = this.router.url.split("?")[1];
        if(query) {
          newPath += "?" + query;
        }
        if (newPath) {
          return redirectHelper.redirect(this.serviceHelperService, newPath, { replaceUrl: true });
        } else {
          return redirectHelper.redirect(this.serviceHelperService, '');
        }
      } else {
        return this.init(config);
      }
    });
  }

  init(config) {
    return Promise.resolve().then(() => {
      if (config.contentId) {
        return this.readContent(config.contentId);
      }
    }).then((content) => {
      this.content = content;
    });

  }

  ngOnDestroy() {
    return super.onDestroy();
  }

  getPath() {
    let url = this.router.url.split("?");
    let path = url[0];
    if (path) {
      path = path.replace(/^\/|\/$/g, '');
    }
    return path;
  }

  getCustomConfig() {
    let config = this.uxHelper.getValue("comp.page.custom");
    let path = this.getPath();
    if (config) {
      return config[path];
    }
  }

  readContent(id) {
    return this.serviceHelperService.crudService.readOne(collectionClassHelper.getCollectionName(Content), id).then((content) => {
      LogUtils.debug(content);
      return content;
    }).catch((e) => {
      LogUtils.error(e);
    });
  }
}
