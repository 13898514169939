import {BaseComponent} from "../../../clientCommon/components/BaseComponent";
import {SearchResultPeopleSearch} from "../../peopleSearch/searchResultPeopleSearch";
import {ServiceHelperService} from "../../../clientCommon/services/serviceHelper.service";
import {ActivatedRoute} from "@angular/router";
import {redirectHelper} from "../../../clientCommon/helper/redirectHelper";

/**
 * SearchResultPeopleSearchSales will be further extended by searchResult.component.ts
 * @extends ReportReviewPeopleSearch to have all the required properties i.e commerceContent, personCount...
 */
export class SearchResultPeopleSearchSales extends SearchResultPeopleSearch {

/**
 *
 * @param serviceHelperService will provide access to different required services such as authentication service, uxc service etc.
 * @param activatedRoute provides access to information about a route associated with a component that is loaded in an outlet. *
 */
  constructor(serviceHelperService: ServiceHelperService,
              activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
    this.pageType = BaseComponent.PAGE_TYPE.sales;
  }

  onInit(): Promise<any> {
    return super.onInit().then(() => {
      return this.serviceHelperService.commerceOfferService.initOffers(this.uxComposite, {
        offerRulesKey: this.serviceHelperService.commerceOfferService.makeSignupOfferKey(this.uxHelper),
      });
    }).then(() => {
      return this.search();
    }).then(() => {
      // SRP does not display when we cannot find a person
      // if(this.getPersonCount() === 0) {
        // return redirectHelper.redirect(this.serviceHelperService, '/' );
      // }
    });
  }
}
