import { LoaderPeopleSearch } from "../../peopleSearch/loaderPeopleSearch";
import { BaseComponent } from "../../../clientCommon/components/BaseComponent";
import { ServiceHelperService } from "../../../clientCommon/services/serviceHelper.service";
import { ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";

/**
 * LoaderPeopleSearchSales will be further extended by loader.component.ts
 * @extends LoaderPeopleSearch to have all the required properties i.e commerceContent, count...
 */
export class LoaderPeopleSearchSales extends LoaderPeopleSearch {
  readonly init$ = new Subject<void>();

  /**
   *
   * @param serviceHelperService will provide access to different required services such as authentication service, uxc service etc.
   * @param activatedRoute provides access to information about a route associated with a component that is loaded in an outlet. *
   */
  constructor(
    serviceHelperService: ServiceHelperService,
    activatedRoute: ActivatedRoute,
  ) {
    super(serviceHelperService, activatedRoute);
    this.pageType = BaseComponent.PAGE_TYPE.sales;
  }

  onInit(): Promise<any> {
    this.hasAccess = true;
    return super.onInit().then(() => {
      return this.serviceHelperService.commerceOfferService.initOffers(this.uxComposite, {
        offerRulesKey: this.serviceHelperService.commerceOfferService.makeSignupOfferKey(this.uxHelper),
      });
    }).then(() => {
      this.initDone = true;
      this.init$.next();
      if (this.hasAccess) {
        return this.search();
      }
    });
  }
}
