import {isObject} from "util";
import {UxComposite} from "../models/ux/uxComposite";
import {Progressor} from "../models/progressor";
import {LogUtils} from "../utils/logUtils";

export class ProgressorHelper {

    createProgressor(uxComposite: UxComposite, keys) {
        return Promise.resolve().then(() => {
            if (uxComposite && keys) {
                let progressor = new Progressor();
                progressor.draft.progresses = this.getProgresses(uxComposite, keys);
                progressor.draft.brandId = uxComposite.brandId;
                return progressor.syncFromDraftAndSaveAll().then(() => {
                    return progressor;
                });
            } else {
                LogUtils.info("ProgressorHelper.createProgressor no uxComposite && keys :Reject(false)");
                return Promise.reject(false);
            }
        }).catch((e) => {
            LogUtils.error(uxComposite ? {uxc: uxComposite.uxConfigId, uxl: uxComposite.uxLayoutId} : "null", keys);
            return Promise.reject(e);
        })
    }

    getProgresses(uxComposite: UxComposite, keys: string[]) {
        try {
            let progresses = [];
            keys.forEach((key) => {
                let stepKeys = uxComposite.traverseKeys(`${key}.step`);
                if (stepKeys && isObject(stepKeys)) {
                    let steps = Object.keys(stepKeys);
                    steps.sort();
                    steps.forEach((step) => {
                        let sectionKeys = uxComposite.traverseKeys(`${key}.step.${step}.section`);
                        let sections = Object.keys(sectionKeys);
                        if (sections) {
                            sections.sort();
                            sections.forEach((section) => {
                                let duration = uxComposite.get(`${key}.step.${step}.section.${section}.duration`);
                                let timeout = uxComposite.get(`${key}.step.${step}.section.${section}.timeout`);
                                let progress = {
                                    name: `${key}.step.${step}.section.${section}`,
                                    duration: duration,
                                    timeout: timeout,
                                    weights: this.getRandomWeights(4),
                                };
                                progresses.push(progress);
                            });
                        }
                    });
                }
            });

            return progresses;
        } catch (e) {
            LogUtils.error(e);
        }
    }

    getRandomWeights(steps) {
        var weights = [];
        while (steps > 0) {
            let weight = Math.random() + 0.3; // adding constant not to progress too slow
            weight = weight * steps * 100;
            weight = Math.floor(weight) / 100;
            weights.push(weight);
            steps--;
        }

        return weights;
    }
}

export var progressorHelper = new ProgressorHelper();
