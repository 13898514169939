import { Component, OnInit, Input, Output, EventEmitter, NgModule } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { UxComp } from '@ux5.2/types';
import { LoaderPeopleSearch } from '@peopeSearch';

@Component({
  selector: 'ux-5-2-follow-up-question',
  templateUrl: './follow-up-question.component.html',
  styleUrls: ['./follow-up-question.component.scss'],
})
export class FollowUpQuestionComponent implements OnInit {
  @Input() userName: string = null;
  @Input() narrowResultTitle: UxComp;
  @Input() base: LoaderPeopleSearch;
  @Input() cityQuestion = true;
  @Output() clickEvent = new EventEmitter();

  searchText = '';
  title = '';

  ageList = [
    {
      name: 'Select Age',
      value: '',
    },
    {
      name: '18-30',
      code: '18-30',
    },
    {
      name: '31-50',
      code: '31-50',
    },
    {
      name: '51-70',
      code: '51-70',
    },
    {
      name: '70+',
      code: '70+',
    },
  ];

  constructor(private viewportScroller: ViewportScroller) {}

  ngOnInit() {
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  clickEventOnButton() {
    this.clickEvent.emit();
  }
}