import {Component, Input, NgModule, OnInit} from "@angular/core";
import {UxHelper} from "../../clientCommon/helper/uxHelper";
import { CommonModule } from "@angular/common";
import { UxTemplateModule } from "./uxTemplate.component";

declare const $: any;

@Component({
    selector: 'policies-modal',
    templateUrl: 'policiesModal.component.html',
    standalone: false
})
export class PoliciesModalComponent implements OnInit {

  @Input() uxHelper: UxHelper;

  constructor() {
  }

  ngOnInit() {
    $(document).on('hidden.bs.modal', '.modal', function () {
      $('.modal:visible').length && $(document.body).addClass('modal-open');
    });
  }
}

@NgModule({
  declarations: [PoliciesModalComponent],
  imports: [CommonModule, UxTemplateModule],
  exports: [PoliciesModalComponent],
})
export class PoliciesModalComponentModule {}
