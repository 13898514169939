import {Component, ElementRef, Input, OnChanges, OnInit, ViewChild} from "@angular/core";
import {UxHelper} from "../../../../../clientCommon/helper/uxHelper";
import {SignUpPeopleSearch} from "../../../../sales/peopleSearch/signUpPeopleSearchSales";

declare var $: any;

@Component({
  selector: 'ux-3-0-signup-name-search',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class Ux3Dot0SignupNameSearchComponent implements OnInit, OnChanges {
  @Input() base: SignUpPeopleSearch;

  @Input() uxHelper: UxHelper;

  @ViewChild('billingSignup') public billingSignup: ElementRef;

  constructor() {
  }

  init() {
    setTimeout(function () {
      $('.carousel').carousel({
        interval: 6000,
        cycle: true
      });
      $('[data-toggle="popover"]').popover();
    }, 2000);
  }

  ngOnInit() {
    return this.init();
  }

  ngOnChanges() {
    return this.init();
  }

  formatInput() {
    this.base.creditCardInputHelper.fullName = this.base.userInfo.fname + ' ' + this.base.userInfo.lname;
  }

  onSubmitStep1() {
    this.base.goNextStep(this.base.steps.email);
  }
}
