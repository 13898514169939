import { Component, OnInit, Input, NgModule } from "@angular/core";
import { User } from "../../../../../../common/models/user/user";
import { CommonModule } from "@angular/common";
import 'moment-timezone';
import { clientPaths } from "../../../../../../../../common/helpers/pathHelpers";
import { UxHelper } from "@clientCommon/helper/uxHelper";
import { BaseDirective } from "@clientCommon/directives/BaseDirective";
import { ServiceHelperService } from "@clientCommon/services/serviceHelper.service";
import { ActivatedRoute } from "@angular/router";
import { UxTemplateModule } from 'app/components/uxTemplate.component';

@Component({
    selector: "app-ad-protection",
    templateUrl: "./ad-protection.component.html",
    styleUrls: ["./ad-protection.component.scss"],
    standalone: false
})
export class MiniReportAdProtectionComponent extends BaseDirective implements OnInit {
  @Input() base: any;
  @Input() uxHelper: UxHelper;
  @Input() uxcompKey;
  @Input() config;


  user: User;
  idProtectionEnabled = false
  
  constructor(serviceHelperService: ServiceHelperService, activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
  }

  ngOnInit(): void {
    this.baseInit();
    this.user = this.base.authService.getUser();
    const idProtectionFrontEnabled = this.uxHelper.getValue("comp.member.idProtection.enable") === 'true'? true: false;
    const idProtectionBackendEnabled = this.uxHelper.getValue("comp.member.idProtection.registration.enable") === 'true'? true: false;
    if (idProtectionBackendEnabled && idProtectionFrontEnabled) {
      this.idProtectionEnabled = true;
    }
  }

  handleMove() {
    //'/'+clientPaths.spyDashboardComponent+'/'+user?._id
    var url = '/'+clientPaths.spyDashboardComponent+'/'+this.user?._id;
    this.base.router.navigateByUrl(url);
  }

  handleMoveMySearch() {
    var url = '/'+clientPaths.content;
    this.base.router.navigateByUrl(url);
  }
}

@NgModule({
  declarations: [MiniReportAdProtectionComponent],
  imports: [CommonModule, UxTemplateModule],
  exports: [MiniReportAdProtectionComponent],
})
export class MiniReportAdProtectionComponentModule {}

