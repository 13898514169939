import { Component, Input, OnInit } from "@angular/core";
import { ViewportScroller } from "@angular/common";
import { UxComp } from "@ux5.2/types";

@Component({
  selector: "ux-5-2-media-scan",
  templateUrl: "./media-scan.component.html",
  styleUrls: ["./media-scan.component.scss"],
})
export class MediaScanComponent implements OnInit {
  @Input() title: UxComp;
  @Input() description: UxComp;
  @Input() imageUrl: UxComp;

  constructor(private viewportScroller: ViewportScroller) {}

  ngOnInit(): void {
    this.viewportScroller.scrollToPosition([0, 0]);
  }
}

