import {Component, OnInit} from '@angular/core';
import {User} from "../../../common/models/user/user";
import {BaseComponent} from "../../../clientCommon/components/BaseComponent";
import {ServiceHelperService} from "../../../clientCommon/services/serviceHelper.service";
import {ActivatedRoute} from "@angular/router";
import {redirectHelper} from "../../../clientCommon/helper/redirectHelper";

@Component({
    templateUrl: 'password.component.html',
    standalone: false
})

export class PasswordComponent extends BaseComponent implements OnInit {
  user: User;
  passwordLessUpdate = false;
  passwordRegex = /^(?=\S*[a-zA-Z])(?=\S*\d)\S{8,}$/;

  currentPassword: string;
  newPassword: string;
  confirmPassword: string;

  constructor(serviceHelperService: ServiceHelperService,
              activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);

    this.pageType = BaseComponent.PAGE_TYPE.member;
    this.pageCategory = BaseComponent.PAGE_CATOGORY.general;
    this.page = BaseComponent.PAGE.password;

  }

  ngOnInit() {
    return this.baseInit().then(() => {
      return this.init();
    });
  }

  init() {
    this.user = this.serviceHelperService.authenticationService.getUser();
    if (
      (this.user.tempClient.loginLink && this.user.tempClient.loginLink.loginHash)
      || (this.user.tempClient.login && !this.user.tempClient.login.password)) {
      this.passwordLessUpdate = true;
    } else {
      // this.passwordLessUpdate = false;
      this.passwordLessUpdate = true; // For now, always passwordLess update
    }
  }


  isValid() {
    return this.newPassword === this.confirmPassword &&
      this.passwordRegex.test(this.newPassword) &&
      this.newPassword &&
      (!this.serviceHelperService.spinnerService.isSpinning()) &&
      (this.passwordLessUpdate || this.currentPassword);
  }

  update() {
    this.serviceHelperService.spinnerService.spin();
    this.serviceHelperService.authenticationService.updatePasswordCustomer(this.currentPassword, this.newPassword).then(() => {
      this.newPassword = "";
      this.confirmPassword = "";
      this.serviceHelperService.alertService.success("Password updated");
      redirectHelper.redirect(this.serviceHelperService, '/' + this.clientPaths.member);
    }).catch((e) => {
      this.serviceHelperService.alertService.error("Password update fail");
    }).then(() => {
      this.init();
      this.serviceHelperService.spinnerService.unspin();
    });
  }
}
