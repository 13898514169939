import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ServiceHelperService } from "../../../../clientCommon/services/serviceHelper.service";
import { ActivatedRoute } from "@angular/router";
import { PersonDetail } from "../personDetail";
import { CourtSearchedPerson } from "../../../../common/custom/models/peopleSearch/searchedPerson";
import { ToastrService } from "../../../service/toastr.service";
import {AuthenticationService, NoteService} from "../../../../clientCommon/services";

@Component({
    selector: "app-member-people-search-court",
    templateUrl: "court.component.html",
    standalone: false
})
export class CourtComponent
  extends PersonDetail
  implements OnInit, OnDestroy, OnChanges {
  @Input() court: any;
  @Input() isIDI: boolean;

  constructor(
    public serviceHelperService: ServiceHelperService,
    public changeDetectorRef: ChangeDetectorRef,
    activatedRoute: ActivatedRoute,
    public toastr: ToastrService,
    public authService: AuthenticationService,
    public noteService: NoteService
  ) {
    super(serviceHelperService, activatedRoute, toastr, noteService, authService);
  }

  ngOnInit() {}

  ngOnDestroy() {
    return super.onDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  init() {
    return super.init();
  }

  getDocumentLocation(record: any) {
    return `${record.documentLocation.bookNumber}${record.documentLocation.pageNumber ? ` - ${record.documentLocation.pageNumber}`:''}${record.documentLocation.docNumber ? ` - ${record.documentLocation.docNumber}`: '' }`;
  }

  findRecord(array: any[], subarrayName) {
    if (!array) return false;
    if (array.length === 0) return false;
    return array.find(arrayItem => arrayItem[subarrayName] ? arrayItem[subarrayName].length > 0 : false);
  }
}
