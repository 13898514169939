import { Component, Input, OnInit, Output ,EventEmitter, NgModule} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceHelperService } from '../../../../../../../src/clientCommon/services/serviceHelper.service';
import { LogUtils } from '../../../../../../../src/common/utils/logUtils';
import { LoaderPeopleSearchMember } from '../../../../../../../src/app/member/peopleSearch/loaderPeopleSearchMember';
import { UxHelper } from '../../../../../../../src/clientCommon/helper/uxHelper';
import { LoaderPeopleSearch } from '../../../../../../../src/app/peopleSearch/loaderPeopleSearch';
import { BaseComponent } from '../../../../../../../src/clientCommon/components/BaseComponent';
import { progressorHelper } from '../../../../../../../src/common/helpers/progressorHelper';
import { SearchedPerson } from '../../../../../../../src/common/custom/models/peopleSearch/searchedPerson';
import { CommonModule } from '@angular/common';
import { UxTemplateModule } from 'app/components/uxTemplate.component';
import { BonusUpsellComponentModule } from '../bonusupsell/bonusupsell.component';

@Component({
  selector: 'app-loader-upsell',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent extends LoaderPeopleSearch {
  @Input()uxHelper: UxHelper;
  @Input() person: SearchedPerson;
  @Input() commerceContentId: string;
  @Input() base: LoaderPeopleSearchMember;
  @Output() onSuccess = new EventEmitter<void>();
  loader=true;
  unlocked=false;

  constructor(serviceHelperService: ServiceHelperService,
    activatedRoute: ActivatedRoute) {
super(serviceHelperService, activatedRoute);
this.pageType = BaseComponent.PAGE_TYPE.member;
}
onInit(): Promise<any> {
  return super.onInit().then(() => {
    this.disableThinMatch();
  }).then(() => {
    this.initDone = true;
  }).catch((e) => {
    LogUtils.error(e);
    return Promise.reject(e);
  });
}
exit(){
this.loader=false;
window.location.reload()
}
onReload() {

this.unlocked=true;
}
reload(){
  this.fakeProgressors.current.pause=true;
}

  async ngOnInit() {
    try {
      // let key = `comp.${this.pageType}.${this.pageCategory}.${BaseComponent.PAGE.loader}`;
      // this.fakeProgressors.current.name = key;
      await this.onInit()

      this.initDone = true
    } catch(e) {
      LogUtils.error(e);
    }

  }


}

@NgModule({
  declarations: [LoaderComponent],
  imports: [CommonModule, UxTemplateModule, BonusUpsellComponentModule],
  exports: [LoaderComponent],
})
export class LoaderUpsellComponentModule {}
