import {Pipe, PipeTransform} from "@angular/core";
import {ArrayUtils} from "../../common/utils/arrayUtils";

@Pipe({
    name: 'toArray'
})
export class ToArrayPipe implements PipeTransform {
    transform(value: object): any[] {
        return ArrayUtils.toArray(value);
    }
}