import {Component, HostListener, Input, OnChanges, OnInit} from "@angular/core";
import {UxHelper} from "../../../../../clientCommon/helper/uxHelper";
import {SearchResultPeopleSearch} from "../../../../peopleSearch/searchResultPeopleSearch";

@Component({
  selector: 'ux-3-1-searchResult-name-search',
  templateUrl: './searchResult.component.html',
  styleUrls: ['./searchResult.component.scss'],
})
export class Ux3Dot1SearchResultNameSearchComponent implements OnInit, OnChanges {
  @Input() base: SearchResultPeopleSearch;

  @Input() uxHelper: UxHelper;

  pageNumber = 0;
  totalCount = 0;
  startIndex = 0;
  strButtonName = null;
  viewLimit = 4;

  constructor() {
  }

  init() {
    if (!this.base.commerceContent) return;
    if (!this.base.commerceContent.getMainRaw()) return;
    if (!this.base.commerceContent.getMainRaw().tempClient) return;
    if (!this.base.commerceContent.getMainRaw().tempClient.processed) return;
    if (!this.base.commerceContent.getMainRaw().tempClient.processed.person) return;
    this.totalCount = this.base.commerceContent.getMainRaw().tempClient.processed?.person?.length || 0;

    this.strButtonName = this.capitalizeSentence(this.uxHelper.getUxcomp("results.button.text"));

    this.onResize();
  }

  ngOnInit() {
    return this.init();
  }

  ngOnChanges() {
    return this.init();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    if (window.innerWidth <= 567) {
      this.viewLimit = 1;
    }else {
      this.viewLimit = 4;
    }
  }

  capitalizeSentence(sentence) {
    return sentence
      .split(" ")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  nextPage() {
    if (this.startIndex + this.base.searchResultLimit < this.totalCount) {
      this.startIndex += this.base.searchResultLimit;
      this.pageNumber ++;
    }
  }

  prevPage() {
    if (this.startIndex > 0) {
      this.startIndex -= this.base.searchResultLimit;
      this.pageNumber --;
    }
  }

  get adsDisplayInterval() {
    return this.uxHelper.getUxcomp('ad.bottom.1.config')?.interval;
  }

  shouldDisplayAd(i: number) {
    if ((!this.adsDisplayInterval) || (i < this.adsDisplayInterval)) return false;
    return parseFloat((i % this.adsDisplayInterval).toString()) === 0
  }
}
