import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {LogUtils} from "../../common/utils/logUtils";
import {RequestEvent} from "../../common/event/requestEvent";
import {ManagedMail} from "../../common/models/user/managedMail";
import {serverPaths} from "../../common/helpers/pathHelpers";
import {ResponseEvent} from "../../common/event/responseEvent";
import {BaseComponent} from "../../clientCommon/components/BaseComponent";
import {ServiceHelperService} from "../../clientCommon/services/serviceHelper.service";

@Component({
    templateUrl: './unsubscribe.component.html',
    standalone: false
})

export class UnsubscribeComponent extends BaseComponent implements OnInit, OnDestroy {
  unsubscribed = false;

  param;

  constructor(serviceHelperService: ServiceHelperService,
              activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
    this.pageType = BaseComponent.PAGE_TYPE.sales;
    this.pageCategory = BaseComponent.PAGE_CATOGORY.general;
    this.page = BaseComponent.PAGE.unsubscribe;
  }

  ngOnInit() {
    return this.baseInit().then(() => {
      let params = new URLSearchParams(window.location.search);
      let id = params.get('id');
      let hash = params.get('hash');
      // let action = params.get('action');

      this.param = {
        id: id,
        hash: hash,
      };
    });
  }

  ngOnDestroy() {
    return super.onDestroy();
  }

  isValid() {
    let flag = false;
    if (this.param && this.param.id && this.param.hash) {
      flag = true;
    }

    return flag;
  }

  unsubscribe() {
    if (this.isValid()) {
      let requestEvent = new RequestEvent();
      requestEvent.param = {
        id: this.param.id,
        hash: this.param.hash,
        action: ManagedMail.ACTIONS.unsubscribed,
      };
      return this.serviceHelperService.jsonService.json(serverPaths.mailTrack, requestEvent).then((responseEvent: ResponseEvent) => {
        this.unsubscribed = true;
        return true;
      }).catch((responseEvent: ResponseEvent) => {
        LogUtils.error(responseEvent.rawResponse);
        return Promise.reject(false);
      });
    }

  }
}
