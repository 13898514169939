import { BaseComponent } from "../../../clientCommon/components/BaseComponent";
import { LandingPeopleSearch } from "../../peopleSearch/landingPeopleSearch";
import { ServiceHelperService } from "../../../clientCommon/services/serviceHelper.service";
import { ActivatedRoute } from "@angular/router";

/**
 * LandingPeopleSearchSales will be further extended by landing.component.ts
 * @extends LandingPeopleSearch to have all the required properties i.e delay, addressField, counter, counterInterval...
 */
export class LandingPeopleSearchSales extends LandingPeopleSearch {

  /**
 *
 * @param serviceHelperService will provide access to different required services such as authentication service, uxc service etc.
 * @param activatedRoute provides access to information about a route associated with a component that is loaded in an outlet. *
 */
  constructor(
    serviceHelperService: ServiceHelperService,
    activatedRoute: ActivatedRoute,
  ) {
    super(serviceHelperService, activatedRoute);
    this.pageType = BaseComponent.PAGE_TYPE.sales;
  }

  onInit(): Promise<any> {
    return super.onInit().then(() => {
      return this.serviceHelperService.commerceOfferService.initOffers(this.uxComposite, {
        offerRulesKey: this.serviceHelperService.commerceOfferService.makeSignupOfferKey(this.uxHelper),
      });
    });
  }

  next(clear) {
    return super.next(clear);
  }
}

