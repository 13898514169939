class HtmlUtils {
// replace the inner html of empty div with provided html content based on css id
  replaceInnerHtmlById(id, html) {
    var element = document.getElementById(id);
    if(element) {
      element.innerHTML = html;
    }
  }
}

export var htmlUtils = new HtmlUtils();
