import {Component, Input, OnInit} from "@angular/core";
import {UxHelper} from "../../../clientCommon/helper/uxHelper";

@Component({
    selector: 'app-member-menu',
    templateUrl: 'memberMenu.component.html',
    standalone: false
})
export class MemberMenuComponent implements OnInit {
  @Input() uxHelper: UxHelper;

  constructor() {
  }

  ngOnInit() {
  }

}
