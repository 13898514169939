import {Component, OnDestroy, OnInit, Input, Output, EventEmitter, NgModule} from "@angular/core";
import {ServiceHelperService} from "../../../../clientCommon/services/serviceHelper.service";
import {ActivatedRoute} from "@angular/router";
import {LogUtils} from "../../../../common/utils/logUtils";
import {UxHelper} from "../../../../clientCommon/helper/uxHelper";
import {User} from "../../../../common/models/user/user";
import {UserInfo} from "../../../../common/models/user/userInfo";
import { BaseDirective } from '../../../../clientCommon/directives/BaseDirective';
import { serverPaths } from '../../../../common/helpers/pathHelpers';
import { ResponseEvent } from '../../../../common/event/responseEvent';
import { CommerceOffer } from '../../../../common/models/commerce/commerceOffer';
import { ModelBase } from '../../../../common/models/modelBase';
import { CommerceToken } from '../../../../common/models/commerce/commerceToken';
import { CreditCardInputHelper } from '../../../../clientCommon/helper/creditCardInputHelper';
import {CommerceOfferRuleKeyDetail} from "../../../../common/models/commerce/commerceOrder";
import {objectUtils} from "../../../../common/utils/objectUtils";
import { commerceUtils } from "../../../../common/utils/commerceUtils";
import { CustomBaseComponent } from '../../../../clientCommon/custom/customBaseComponent';
import {peopleSearchProductKeys} from "../../../../common/custom/peopleSearch/peopleSearchProductKeys";
import { SearchedPerson } from "../../../../common/custom/models/peopleSearch/searchedPerson";
import { CommerceOrder } from '../../../../common/models/commerce/commerceOrder';
import { timeUtils } from '../../../../common/utils/timeUtils';
import { CommonModule } from "@angular/common";
import { UxTemplateModule } from "app/components/uxTemplate.component";
import { PoliciesModalComponentModule } from "app/components/policiesModal.component";
import { FormsModule } from "@angular/forms";

declare const $: any;

@Component({
  selector: 'member-upsell-payment',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.scss'],
})
export class MemberUpsellPaymentModal extends BaseDirective implements OnInit, OnDestroy {
  user: User;
  flag = false;
  useExisting = true;
  commerceToken: CommerceToken;
  preName = '';
  preCard = '';
  expDate = '';
  processing = false;
  creditCardInputHelper = new CreditCardInputHelper();
  submitted = false;
  hash = '';
  step = 0;
  paymentResult = true;
  commerceOfferRulesKey = "";
  commerceOfferIdsKey = "";
  commerceOffers: CommerceOffer[] = [];
  brandName = '';
  structuredCommerceOffers: {
    [type: string]: CommerceOffer[]
  } = {};
  modalId = '';
  newCommerceOrder: CommerceOrder;
  last4 = '';
  timeUtils = timeUtils;

  @Input() isPdfPrintView : boolean = false;
  @Input() partialKey: string = '';
  @Input() pageCategory = '';
  @Input() uxHelper: UxHelper;
  @Input() person: SearchedPerson;
  @Input() commerceContentId: string;
  @Output() onSuccess = new EventEmitter<void>();

  constructor(
    serviceHelperService: ServiceHelperService,
    activatedRoute: ActivatedRoute,
  ) {
    super(serviceHelperService, activatedRoute);
  }

  ngOnInit() {
    return this.baseInit().then(() => {
      this.commerceOfferIdsKey = `comp.billing.enhanced.${this.partialKey}.global.offerIds`;
      this.commerceOfferRulesKey = `comp.billing.enhanced.${this.partialKey}.offersRules`;
      this.modalId = `${this.partialKey}-payment-modal`;
      return this.init();
    })
  }

  init() {
    this.brandName = this.uxComposite.getUxcomp('comp.brand.name');
    this.creditCardInputHelper.setDummyAddress(this.uxComposite);

    return Promise.resolve().then(() => {
      this.user = this.serviceHelperService.authenticationService.getUser();
      this.uxComposite.setCode('fname', this.user.firstName);
      return this.serviceHelperService.commerceService.request(serverPaths.commerceCustomerFindCustomer, {
        userId: this.user._id,
      }).then((responseEvent: ResponseEvent) => {
        let docs = responseEvent.getDocs();
        docs.forEach((obj: ModelBase<any>) => {
          if (obj instanceof User) {
            this.user = obj;
          } else if (obj instanceof CommerceToken) {
            this.commerceToken = obj;
          }
        });
        if (this.commerceToken?.billingAddress.firstName && this.commerceToken?.billingAddress.lastName) {
          this.preName = `${this.commerceToken?.billingAddress.firstName} ${this.commerceToken?.billingAddress.lastName}`;
        } else {
          this.preName = 'Pre-Populated Full Name on Card';
        }
        if (this.commerceToken?.expiration.month && this.commerceToken?.expiration.year) {
          this.expDate = `${this.commerceToken?.expiration.month}/${this.commerceToken?.expiration.year}`;
        } else {
          this.expDate = 'Exp. 02/2023';
        }
        let ccNumber = '';
        const bin = this.commerceToken?.bin;
        const last4 = this.commerceToken?.last4;
        const length = this.commerceToken?.length - last4?.length - bin?.length;
        if (length) {
          ccNumber = bin;
          ccNumber += new Array(length).fill('*').join('');
          ccNumber += last4;
        }
        this.preCard = ccNumber || 'Credit Card Number';
      }).catch((err) => {
        LogUtils.error(err);
      })
    }).then(() => {
      return this.findOffers().catch((err) => {
        LogUtils.error(err);
      });
    }).catch((e) => {
      LogUtils.error(e);
    });
  }

  ngOnDestroy() {
    return super.onDestroy();
  }

  isValidPaymentInfo() {
    return (
      this.creditCardInputHelper.isValidFullName() &&
      this.creditCardInputHelper.isValidCCNumber() &&
      this.creditCardInputHelper.isValidCCExp() &&
      this.creditCardInputHelper.isValidZip()
    );
  }

  upsell() {
    Promise.resolve(true).then(() => {
      if (!this.commerceOffers?.length) {
        return Promise.reject('No valid commerce offers');
      }
      this.hash = this.serviceHelperService.commerceService.createHash();
      const commerceOffer = this.commerceOffers[0];
      if (this.useExisting) {
        this.processing = true;
        let offerRuleKeyDetails: CommerceOfferRuleKeyDetail[] = [];

        let offerRules = this.uxComposite.getUxcomp(this.commerceOfferRulesKey);

        if (offerRules && objectUtils.isObject(offerRules)) {
          Object.keys(offerRules).forEach((key) => {
            offerRuleKeyDetails.push({key: key, commerceOfferIds: [commerceOffer._id]});
          });
        }

        if (commerceOffer) {
          let options:any = {};
          if (this.commerceContentId) {
            options.commerceContentId = this.commerceContentId;
            options.upsellKey = 'global';
          }
          return this.serviceHelperService.commerceService.nonOptionSaleByUser(this.hash, this.user,
            this.uxComposite,
            this.commerceOfferRulesKey,
            offerRuleKeyDetails,
            options)
          .catch((e) => {
            this.paymentResult = false;
            this.useExisting = false;
            this.processing = false;
            LogUtils.error(e);
            return Promise.reject(e);
          }).then((responseEvent: ResponseEvent) => {
            this.step = this.step + 1;
            this.paymentResult = true;
            this.processing = false;
            this.getNewCommerceOrder(responseEvent);
            return;
          });
        }
        return Promise.reject('No CommerceOffer found.');
      } else {
        this.submitted = true;
        if (!this.isValidPaymentInfo()) {
          return Promise.reject('Invalid Payment Info');
        }
        this.processing = true;
        this.creditCardInputHelper.billingAddress.email = this.user.email;
        const [fname, lname] = this.creditCardInputHelper.fullName.split(" ");
        this.creditCardInputHelper.billingAddress.firstName = fname;
        this.creditCardInputHelper.billingAddress.lastName = lname;

        let offerRules = this.uxComposite.getUxcomp(this.commerceOfferRulesKey);
        let offerRuleKeyDetails: CommerceOfferRuleKeyDetail[] = [];
        let offerRuleKey = this.commerceOfferRulesKey;

        Object.keys(offerRules).forEach((key) => {
          offerRuleKeyDetails.push({key: key});
        });
        let userInfo = new UserInfo();
        userInfo.id = this.user._id;
        let requestParam: any = {
          hash: this.hash,
          userInfo: userInfo,
          commerceOfferIds: [],
          commerceOfferDetails: [],
          refer: this.serviceHelperService.referService.getRefer(),
          uxcId: this.uxComposite.uxConfigId,
          uxlId: this.uxComposite.uxLayoutId,
          ccNumber: this.creditCardInputHelper.ccNumber,
          ccExpMonth: this.creditCardInputHelper.ccExpMonth,
          ccExpYear: this.creditCardInputHelper.ccExpYear,
          ccCvv: this.creditCardInputHelper.ccCvv,
          billingAddress: this.creditCardInputHelper.billingAddress,
          disablePreviousTokens: true,
          declineIfSameActiveToken: true,
          offerRuleKey: offerRuleKey,
          offerRuleKeyDetails: offerRuleKeyDetails,
        };
        if (this.commerceContentId) {
          requestParam.commerceContentId = this.commerceContentId;
          requestParam.upsellKey = 'global';
        }
        return this.serviceHelperService.commerceService
          .processNonOptionSale(requestParam)
          .then((result) => {
            this.step = this.step + 1;
            this.paymentResult = true;
            this.processing = false;
            this.getNewCommerceOrder(result);
            return;
          })
          .catch((e) => {
            this.paymentResult = false;
            this.useExisting = false;
            this.processing = false;
            this.last4 = this.creditCardInputHelper.ccNumber.slice(12, 16);
            if (commerceUtils.isNotAcceptableRequestError(e)) {
              LogUtils.debug(e);
            } else {
              LogUtils.error(e);
            }
            return Promise.reject(e);
          });
      }
    }).then(() => {
      this.serviceHelperService.trackingService.report({
        action: 'submit',
        type: 'upsell',
        status: 'success',
      });
      return;
    }).catch((err) => {
      LogUtils.error(err);
    }).then(() => {
      this.serviceHelperService.trackingService.report({
        action: 'submit',
        type: 'upsell',
      });
    })
  }

  changePaymentMethod() {
    this.useExisting = !this.useExisting;
  }

  public findOffers() {
    const commerceOfferIds = this.uxComposite.getUxcomp(this.commerceOfferIdsKey);

    return this.serviceHelperService.commerceService.findOffers(commerceOfferIds).then((commerceOffers) => {
      this.commerceOffers = commerceOffers;
      this.uxComposite.setCode('pricing', this.commerceOffers[0]?.commercePrice.prices[0]?.amount.toFixed(2))
    }).catch((e) => {
      LogUtils.error("AddonComponent:Finding offer error", e);
    });
  }

  getProductKeyByPageCategory() {
    if (this.pageCategory === CustomBaseComponent.PAGE_CATOGORY.nameSearch || this.pageCategory === CustomBaseComponent.PAGE_CATOGORY.nameSearchOptOut) {
      return peopleSearchProductKeys.nameSearch;
    } else if (this.pageCategory === CustomBaseComponent.PAGE_CATOGORY.phoneSearch) {
      return peopleSearchProductKeys.phoneSearch;
    }
  }

  close() {
    this.onSuccess.emit();
    $(`#${this.modalId}`).modal('hide');
  }

  initCard() {
    this.step=0;
    this.flag = false;
    this.useExisting=false;
    this.submitted = false;
    this.creditCardInputHelper.init();
    this.creditCardInputHelper.setDummyAddress(this.uxComposite);
    this.creditCardInputHelper.clearCC();
  }

  getPartialKey(key: string) {
    return this.uxHelper.getUxcompKey('member.upsell.' + this.partialKey + '.' + key);
  }

  getNewCommerceOrder(responseEvent: ResponseEvent) {
    let docs = responseEvent.getDocs();
    docs.forEach((obj: ModelBase<any>) => {
      if (obj instanceof CommerceOrder) {
        this.newCommerceOrder = obj;
        this.newCommerceOrder.tempClient.dueDate = this.timeUtils.formatDate(new Date());
      }
    });
  }

  newCard() {
    this.useExisting = false;
    this.serviceHelperService.trackingService.report({
      type: 'upsell',
      action: 'new_card'
    });
  }

  goToPayment() {
    this.serviceHelperService.trackingService.report({
      type: 'upsell',
      action: 'upsell_payment',
      benefit: false,
    });
    this.flag = true;
  }
}

@NgModule({
  declarations: [MemberUpsellPaymentModal],
  imports: [CommonModule, FormsModule, UxTemplateModule, PoliciesModalComponentModule],
  exports: [MemberUpsellPaymentModal],
})
export class MemberUpsellPaymentModalModule {}
