// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
      .background {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9999;
      }

      .loader-container {
        background-color: transparent;
        box-shadow: none;

        img {
          animation: spin infinite 3s linear;
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    `, "",{"version":3,"sources":["webpack://./src/app/themes/2234200bd3cc36ba97274ecbdc6c6a47/common/loading-spinner/loading-spinner.component.ts"],"names":[],"mappings":";MACM;QACE,eAAe;QACf,MAAM;QACN,OAAO;QACP,QAAQ;QACR,SAAS;QACT,oCAAoC;QACpC,aAAa;QACb,mBAAmB;QACnB,uBAAuB;QACvB,aAAa;MACf;;MAEA;QACE,6BAA6B;QAC7B,gBAAgB;;QAEhB;UACE,kCAAkC;QACpC;;QAEA;UACE;YACE,uBAAuB;UACzB;UACA;YACE,yBAAyB;UAC3B;QACF;MACF","sourcesContent":["\n      .background {\n        position: fixed;\n        top: 0;\n        left: 0;\n        right: 0;\n        bottom: 0;\n        background-color: rgba(0, 0, 0, 0.8);\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        z-index: 9999;\n      }\n\n      .loader-container {\n        background-color: transparent;\n        box-shadow: none;\n\n        img {\n          animation: spin infinite 3s linear;\n        }\n\n        @keyframes spin {\n          0% {\n            transform: rotate(0deg);\n          }\n          100% {\n            transform: rotate(360deg);\n          }\n        }\n      }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
