import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class ModalTriggerService {

    private subject = new Subject<any>();

    /** setModalId set the modal id like when we need to open modals i.e openSuccessModal, openErrorModal  */
    sendModalId(modalId: string) {
        this.subject.next(modalId);
    }

    /** clearModalId will clear the modal id previously set on some modal open  */
    clearModalId() {
        this.subject.next(undefined);
    }

    /** This will return the modal id previously set on some modal open  */
    getModalId() : Observable<any> {
        return this.subject.asObservable();
    }
}
