import {PropertyDecoratorHelper} from "../helpers/propertyDecoratorHelper";

export var serverFieldHelper = new PropertyDecoratorHelper();

/**
 * Field not disclosed on client
 */
export function ServerField(target, key: string): void {
    serverFieldHelper.addProperties(target, key);
}
