// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-seals {
    padding: 5px 20px 0;
    text-align: right;
    margin-bottom: -20px;
    @media (max-width: 991px) {
      text-align: center !important;
    }  
}`, "",{"version":3,"sources":["webpack://./src/app/peopleSearch/templates/nameSearch/default.1.2/landing.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,iBAAiB;IACjB,oBAAoB;IACpB;MACE,6BAA6B;IAC/B;AACJ","sourcesContent":[".new-seals {\n    padding: 5px 20px 0;\n    text-align: right;\n    margin-bottom: -20px;\n    @media (max-width: 991px) {\n      text-align: center !important;\n    }  \n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
