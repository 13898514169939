import {ApplicationRef, Component, HostListener, NgZone, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {SignUpPeopleSearch} from "../../signUpPeopleSearchSales";
import {BaseComponent} from "../../../../../clientCommon/components/BaseComponent";
import {ServiceHelperService} from "../../../../../clientCommon/services/serviceHelper.service";
import { DatePipe } from "@angular/common";

@Component({
  templateUrl: 'signup.component.html',
})
/**
 * SignupPhoneSearchSalesComponent render the respective theme/layout component based on current theme
 * @extends SignUpPeopleSearch to get all required properties..
 */
export class SignupPhoneSearchSalesComponent extends SignUpPeopleSearch implements OnInit, OnDestroy {

  /**
  *
  * @param serviceHelperService will provide access to different required services such as authentication service, uxc service etc.
  * @param activatedRoute provides access to information about a route associated with a component that is loaded in an outlet.
  */
  constructor(serviceHelperService: ServiceHelperService,
              ngZone: NgZone,
              applicationRef: ApplicationRef,
              activatedRoute: ActivatedRoute, datePipe: DatePipe) {
    super(serviceHelperService, activatedRoute, datePipe);

    this.pageCategory = BaseComponent.PAGE_CATOGORY.phoneSearch;
  }

  getChildObject() {
    return this;
  }

  ngOnInit() {
    return this.onInit().then(() => {
      this.initDone = true;
    });
  }

  ngOnDestroy() {
    return super.onDestroy();
  }

  get isEnabled() {
    return this.uxHelper.getUxcomp('pii.enabled')
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event) {
    return super.onBeforeUnload(event);
  }

  @HostListener('window:scroll', ['$event'])
  onScroll($event) {
    super.onScroll($event);
  }
}
