import { Component, HostListener, Input, OnInit, ViewChild, ElementRef, NgModule } from '@angular/core';
import { UxHelper } from '@clientCommon/helper/uxHelper';
import { UxcService } from '@clientCommon/services';
import { StorageService } from '@clientCommon/services/storage.service';
import { MembershipType, UxComp } from '@ux5.2/types';
import { SignUpPeopleSearch } from 'app/sales/peopleSearch/signUpPeopleSearchSales';
import { LogUtils } from '../../../../../../common/utils/logUtils';

declare var $: any;

@Component({
  selector: 'ux-5-2-signup-name-search',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class Ux5Dot2SignupNameSearchComponent implements OnInit {
  @Input() base: SignUpPeopleSearch;

  @Input() uxHelper: UxHelper;

  membership: MembershipType = 'one_month';

  @ViewChild('billingSignup') public billingSignup: ElementRef;
  @ViewChild('firstName') public firstNameInput: ElementRef<HTMLInputElement>;
  @ViewChild('lastName') public lastNameInput: ElementRef<HTMLInputElement>;

  casxBannerText: UxComp;
  casxTextDesktop: UxComp;
  casxTextMob: UxComp;
  topUpTitle: UxComp;
  membershipTitle: UxComp;
  iconLabelCardTitle: UxComp;
  faqCardTitle: UxComp;
  faqCardSubTitle: UxComp;
  faqHeader: UxComp;
  faqDescription: UxComp;
  faqSecondHeader: UxComp;
  faqCancelMembershipContent: UxComp;
  sideCardTitle: UxComp;
  sideCardContent: UxComp;
  sideCardImageUrls: UxComp;
  resultHeader: UxComp;
  resultCheckMarkContent: UxComp;
  casxDisclaimerTitle: UxComp;
  casxDisclaimerDescription: UxComp;
  casxDueTodayTitle: UxComp;
  casxBenefitContent: UxComp;
  shouldShowLoader: boolean = false;
  disclaimerContent: string;
  secondaryDisclaimerContent: string;
  searchedName = '';

  constructor(private storageService: StorageService, private uxcService: UxcService) {}
  cardType = '';

  reports = [];
  feedbacks = [];
  brandName = '';

  isCasX: boolean = false;
  showBanner: boolean = false;
  casXDisabled: boolean = false;
  casxDelay: number = 1000 * 60 * 20;

  disableCasX() {
    this.casXDisabled = true;
  }
  sessionStorageKey = 'casXState';
  sessionStorage: Storage = {} as Storage;
  casXStorageState = {
    showBanner: false,
    showPage: false,
  };

  get hasOneOffer(): boolean {
    return Object.keys(this.base?.structuredCommerceOfferIds).length === 1;
  }

  initializeStorage() {
    this.sessionStorage = this.storageService.getSession(this.sessionStorageKey);
    if (!this.sessionStorage) {
      this.storageService.setSession(this.sessionStorageKey, JSON.stringify(this.casXStorageState));
      this.sessionStorage = this.storageService.getSession(this.sessionStorageKey);
    }
  }

  saveCasXState(casXState: boolean) {
    this.sessionStorage.setItem(this.sessionStorageKey, casXState.toString());
  }

  getCasXSession() {
    return this.storageService.getSession(this.sessionStorageKey);
  }

  toggleLoader() {
    this.shouldShowLoader = !this.shouldShowLoader;
  }

  init() {
    this.reports = this.uxHelper.getUxcomp('reports');
    this.feedbacks = this.uxHelper.getUxcomp('feedbacks');
    this.brandName = this.uxHelper.uxComposite.brandName;
    this.searchedName =
      this.base?.userInputFullName || `${this.base?.selectedPerson?.fName} ${this.base?.selectedPerson.lName}`;
    this.initializeStorage();
    let sessionState = JSON.parse(this.getCasXSession());
    if (sessionState?.showPage === true) {
      this.enableCasx();
      const uxlId = this.uxHelper.getUxcomp('exit.modal.stay.cascade');
      this.uxcService.setUxlId(uxlId);
      this.showBanner = true;
    } else if (sessionState?.showBanner) {
      this.showBanner = true;
    }
    this.groupOfUxComps();
  }

  ngOnInit() {
    this.setCasxDelayFromUxComp();

    setTimeout(() => {
      if (!this.casXDisabled) {
        this.showBanner = true;
      }
    }, this.casxDelay);

    return this.init();
  }

  ngOnChanges() {
    return this.init();
  }

  getUxcompValue<ValueT = string>(compName: string): UxComp<ValueT> {
    const compNameIndex = Object.keys(this.uxHelper?.uxComposite?.ids)?.indexOf(compName) || '';
    const id = (Object.values(this.uxHelper?.uxComposite?.ids)?.[compNameIndex] || '') as string;
    return {
      id,
      key: compName,
      value: this.uxHelper?.uxComposite?.getUxcomp(compName) as ValueT,
    };
  }

  getTrialEndDate() {
    const today = new Date();
    today.setDate(today.getDate() + 7);
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();
    return `${month}/${day}/${year}`;
  }

  checkMembership() {
    if (this.isCasX) return { days: '30', price: '39.99' };
    if (this.membership === 'one_month') return { days: '30', price: '29.55 ' };
    if (this.membership === 'free_trial') return { days: '7', price: '1.00 ' };
    return { days: '60', price: '50.24 ' };
  }

  groupOfUxComps(): void {
    const prefix = 'comp.sales.name-search.sign-up';
    this.casxBannerText = this.getUxcompValue(`${prefix}.casx-banner.text`);
    this.casxTextDesktop = this.getUxcompValue(`${prefix}.casx-banner.text-desktop`);
    this.casxTextMob = this.getUxcompValue(`${prefix}.casx-banner.text-mobile`);
    this.topUpTitle = this.getUxcompValue(`${prefix}.casx-banner.text-mobile`);
    this.membershipTitle = this.getUxcompValue(`${prefix}.top-up.membership-card.title`);
    this.iconLabelCardTitle = this.getUxcompValue(`${prefix}.top-up.icon-label-card.title`);
    this.faqCardTitle = this.getUxcompValue(`${prefix}.top-up.faq-card.title`);
    this.faqCardSubTitle = this.getUxcompValue(`${prefix}.top-up.faq-card.subtitle`);
    this.faqHeader = this.getUxcompValue(`${prefix}.top-up.faq-card.faq-header`);
    this.faqDescription = this.getUxcompValue(`${prefix}.top-up.faq-card.faq-description`);
    this.faqSecondHeader = this.getUxcompValue(`${prefix}.top-up.faq-card.faq-second-header`);
    this.faqCancelMembershipContent = this.getUxcompValue(
      `${prefix}.top-up.faq-card.faq-cancel-membership-content`
    );
    this.sideCardTitle = this.getUxcompValue(`${prefix}.side-card.title`);
    this.sideCardContent = this.getUxcompValue(`${prefix}.side-card.content`);
    this.sideCardImageUrls = this.getUxcompValue(`${prefix}.side-card.imageUrls`);
    this.resultHeader = this.getUxcompValue(`${prefix}.results.sub-header`);
    this.resultCheckMarkContent = this.getUxcompValue(`${prefix}.results-check-mark-content`);
    this.casxDisclaimerTitle = this.getUxcompValue(`${prefix}.casx-disclaimer.title`);
    this.casxDisclaimerDescription = this.getUxcompValue(`${prefix}.casx-disclaimer.description`);
    this.casxDueTodayTitle = this.getUxcompValue(`${prefix}.casx-due-today.title`);
    this.casxBenefitContent = this.getUxcompValue(`${prefix}.casx-bottom-card.content`);
  }

  get isEnabled() {
    return this.uxHelper.getUxcomp('pii.enabled');
  }

  async enableCasx(setIsCasX = true) {
    if (this.casXDisabled) {
      return;
    }
    
    try {
      this.base.serviceHelperService.spinnerService.spin();
      const uxlId = this.uxHelper.getUxcomp('exit.modal.stay.cascade');
      const uxComposite = await this.base.serviceHelperService.uxcService.cascadeUxComposite(uxlId);
      this.base.updateUxComposite(uxComposite);
      await this.base.setOffer('main');
      this.casXStorageState.showPage = true;
      this.storageService.setSession(this.sessionStorageKey, JSON.stringify(this.casXStorageState));
      if (setIsCasX) {
        this.isCasX = true;
      }
      this.groupOfUxComps();
      this.base.serviceHelperService.spinnerService.unspin();
      this.membership = 'casx';
    } catch (error) {
      LogUtils.error('UX5.2 enableCasx', error);
    }
  }

  getMembership(membership: MembershipType): void {
    this.membership = membership;
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event) {
    this.showBanner = true;

    this.casXStorageState.showBanner = true;

    if (!this.uxHelper.hasUxComposite()) {
      return;
    }

    const text = this.uxHelper.getUxcomp('exit.modal.text');

    if (!text) {
      return;
    }

    event.returnValue = text;

    setTimeout(async () => {
      try {
        this.base.serviceHelperService.spinnerService.spin();
        
        await this.enableCasx(false);
        
        window.scrollTo(0, 0);
        
        await this.base.init();

        this.isCasX = true;
      } catch (e) {
        LogUtils.error('UX5.2 onBeforeUnload setTimeout', e);
      } finally {
        this.base.serviceHelperService.spinnerService.unspin();
      }
    }, 100);

    return text;
  }

  private setCasxDelayFromUxComp() {
    const delay = this.uxHelper.getUxcomp('casx.delay');

    if (!!delay) {
      this.casxDelay = delay;
    }
  }
}
