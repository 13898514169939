// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-nav-video-wrapper {position:absolute;top:0;height:55px;left:0;right:0;width:100%;overflow:hidden;box-shadow:0 0 5px #000}
::ng-deep #search-result .navbar {background:url(/assets/common/pr1/img/pbg.jpg) no-repeat center center;background-size:cover;box-shadow:0 0 5px #000;}
.content-wrapper {background:none;}
`, "",{"version":3,"sources":["webpack://./src/app/peopleSearch/templates/nameSearch/particle.1/searchResult.component.css"],"names":[],"mappings":"AAAA,2BAA2B,iBAAiB,CAAC,KAAK,CAAC,WAAW,CAAC,MAAM,CAAC,OAAO,CAAC,UAAU,CAAC,eAAe,CAAC,uBAAuB;AAChI,kCAAkC,sEAAsE,CAAC,qBAAqB,CAAC,uBAAuB,CAAC;AACvJ,kBAAkB,eAAe,CAAC","sourcesContent":[".header-nav-video-wrapper {position:absolute;top:0;height:55px;left:0;right:0;width:100%;overflow:hidden;box-shadow:0 0 5px #000}\n::ng-deep #search-result .navbar {background:url(/assets/common/pr1/img/pbg.jpg) no-repeat center center;background-size:cover;box-shadow:0 0 5px #000;}\n.content-wrapper {background:none;}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
