import { ServerField } from '../../decorators/database/serverField';
import { IndexField } from '../../decorators/database/indexField';
import { ModelBase } from '../modelBase';
import { CollectionClass } from '../../decorators/database/collectionClass';
import { emailUtils } from '../../utils/emailUtils';
import { CompositeClassField } from "../../decorators/database/compositeClassField";
import { CommerceBillingRouting } from "../commerce/commerceBillingRouting";
import { UserRemoveInfo, UserRemoveInfoSegment } from './userRemoveInfo';

export const userIndex = {
  firstName: 'fn:',
  lastName: 'ln:',
  email: 'em:',
  phone: 'ph:',
  cusId: 'cu:',
};

@CollectionClass({ name: 'users' })
export class User extends ModelBase<User> {
  public static ROLES = {
    admin: 'admin',
    contentManager: 'contentManager',
    csr: 'csr',
    customer: 'customer',
  };
  //Adding this field to disable topbanner
  topBanner: boolean;
  ffRequests: {};
  removeInfo: UserRemoveInfo = {
    segment: UserRemoveInfoSegment.D,
  };
  @IndexField({ unique: true }) public uniqueId: string; // Brand id + email. This is for unique-check
  @IndexField() public email: string;
  @IndexField() public extEmails: string[] = [];

  @ServerField public password: string;
  @ServerField public salt: string;

  @IndexField() public firstName: string;
  @IndexField() public lastName: string;
  @IndexField() public phone: string;
  @IndexField() public roles: string[] = [];

  @IndexField() uxcId: string;
  @IndexField() uxlId: string;
  prevUx: { timestamp: number, uxcId: string, uxlId: string }[] = [];

  @CompositeClassField(CommerceBillingRouting) commerceBillingRouting: CommerceBillingRouting;

  queries: any = {};

  constructor(doc?, draftFlag?: boolean) {
    super(doc, draftFlag);
    this.init(doc, this.draftFlag);
  }

  public hasRoles(roles?: string[]): boolean {
    let flag = true;
    if (roles) {
      roles.some((role) => {
        if (this.roles.indexOf(role) === -1) {
          flag = false;
          return true;
        }
      });
    } else {
      flag = false;
    }
    return flag;
  }

  public isValid(roles?: string[]): boolean {
    let flag = true;
    if (flag && this.status !== ModelBase.STATUS.active) {
      flag = false;
    }

    if (flag && roles) {
      flag = this.hasRoles(roles);
    }

    return flag;
  }

  public isValidToCreate(): boolean {
    return emailUtils.validateEmail(this.email) &&
      this.email &&
      this.brandId &&
      this.firstName &&
      this.lastName &&
      this.status &&
      this.roles.length > 0;
  }

  getSecuredDoc(): User {
    const obj: User = super.getSecuredDoc();

    obj.firstName = this.firstName;
    obj.lastName = this.lastName;
    obj.roles = this.roles;

    return obj;
  }

  createUniqueId() {
    return `${this.brandId}|${this.email}`;
  }

  pushNewUxIfNeeded() {
    if (this.throwErrorIfNotBase()) {
      if ((this.uxcId !== this.draft.uxcId) || (this.uxlId !== this.draft.uxlId)) {
        const timestamp = new Date().getTime();
        this.draft.prevUx.push({ timestamp, uxcId: this.draft.uxcId, uxlId: this.draft.uxlId });
        return true;
      }
    }

    return false;
  }

  getFullName() {
    return `${this.firstName ? this.firstName : ''} ${this.lastName ? this.lastName : ''}`;
  }
}

