// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 360px) {
  .ad-margin-fix {
    margin: -15px;
  }
}

@media screen and (max-width: 360px) {
  #search-result #loadMore a {
    width: auto;
    margin: 20px 0;
    padding: 15px 30px;
    font-size: 18px;
    font-weight: bold;
  }
}

.more-result-title {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 900;
  line-height: 35.16px;
  text-align: center;
  color: #727272;
  margin-top: 40px;
  margin-bottom: 20px;
}
@media screen and (max-width: 834px) {
  .more-result-title {
    font-size: 24px;
    line-height: 28.13px;
  }
}
@media screen and (max-width: 534px) {
  .more-result-title {
    font-size: 20px;
    line-height: 23.44px;
  }
}

.more-result-content {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: center;
  color: #525252;
  margin-bottom: 40px;
}
@media screen and (max-width: 534px) {
  .more-result-content {
    font-size: 14px;
    line-height: 16.41px;
  }
}

.more-result-content-color {
  font-weight: 600;
  color: #81B34C;
}`, "",{"version":3,"sources":["webpack://./src/app/peopleSearch/templates/nameSearch/default/searchResult.component.scss"],"names":[],"mappings":"AACI;EADJ;IAEQ,aAAA;EACN;AACF;;AAGI;EADJ;IAEQ,WAAA;IACA,cAAA;IACA,kBAAA;IACA,eAAA;IACA,iBAAA;EACN;AACF;;AAEA;EACI,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,oBAAA;EACA,kBAAA;EACA,cAAA;EACA,gBAAA;EACA,mBAAA;AACJ;AAAI;EATJ;IAUQ,eAAA;IACA,oBAAA;EAGN;AACF;AAFI;EAbJ;IAcQ,eAAA;IACA,oBAAA;EAKN;AACF;;AAHA;EACI,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,oBAAA;EACA,kBAAA;EACA,cAAA;EACA,mBAAA;AAMJ;AALI;EARJ;IASQ,eAAA;IACA,oBAAA;EAQN;AACF;;AANA;EACI,gBAAA;EACA,cAAA;AASJ","sourcesContent":[".ad-margin-fix {\n    @media screen and (max-width: 360px) {\n        margin: -15px;\n    }\n}\n\n#search-result #loadMore a {\n    @media screen and (max-width: 360px) {\n        width: auto;\n        margin: 20px 0;\n        padding: 15px 30px;\n        font-size: 18px;\n        font-weight: bold;\n    }\n}\n\n.more-result-title {\n    font-family: Roboto;\n    font-size: 30px;\n    font-weight: 900;\n    line-height: 35.16px;\n    text-align: center;    \n    color: #727272;\n    margin-top: 40px;\n    margin-bottom: 20px;\n    @media screen and (max-width: 834px) {\n        font-size: 24px;\n        line-height: 28.13px;\n    }\n    @media screen and (max-width: 534px) {\n        font-size: 20px;\n        line-height: 23.44px;\n    }\n}\n.more-result-content {\n    font-family: Roboto;\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 18.75px;\n    text-align: center;\n    color: #525252;    \n    margin-bottom: 40px;\n    @media screen and (max-width: 534px) {\n        font-size: 14px;\n        line-height: 16.41px;\n    }    \n}\n.more-result-content-color {\n    font-weight: 600;\n    color: #81B34C;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
