import {ModelBase} from '../models/modelBase';
import {isObject} from 'util';
import {collectionClassHelper} from '../decorators/database/collectionClass';

export class PermissionUtils {

  static transientOnlyFields = [];

  hasPermission(obj: ModelBase<any>, key, target): boolean {
    let flag = false;
    if (obj && obj.permissions) {
      obj.permissions.some((permission) => {
        const result = permission.hasPermission(key, target);
        if (result) {
          flag = true;
          return true;
        }
      });
    }

    return flag;
  }

  hasPermissionPath(obj: ModelBase<any>, target): boolean {
    return this.hasPermission(obj, 'paths', target);
  }

  removeValues(obj: ModelBase<any>, target: Object, key?) {
    if (obj && target && isObject(target)) {
      if (!key) {
        key = collectionClassHelper.getCollectionName(target);
        if (!key) {
          key = 'obj';
        }
      }

      Object.keys(target).forEach((targetKey) => {
        const nextKey = key + '.' + targetKey;
        if (isObject(target[targetKey])) {
          this.removeValues(obj, target[targetKey], nextKey);
        } else {
          if (!this.hasPermission(obj, 'properties', nextKey)) {
            delete target[targetKey];
          }
        }
      });
    }
  }
}

export let permissionUtils = new PermissionUtils();
