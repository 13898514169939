import {isArray} from "util";
import {evalUtils} from "./evalUtil";
import {LogUtils} from "./logUtils";

class ObjectUtils {

  isObject(value) {
    return value !== null && typeof value === 'object';
  }

  deleteEmptyProperties(obj) {
    if (this.isObject(obj)) {
      Object.keys(obj).forEach((key) => {
        if (!obj[key]) {
          delete obj[key];
        }
      });
    }
  }

  deleteEmptyPropertiesExtended(obj) {
    if (this.isObject(obj)) {
      Object.keys(obj).forEach((key) => {
        if (!obj[key] ||
          (isArray(obj[key]) && (obj[key]).length === 0) ||
          (this.isObject(obj[key]) && Object.keys(obj[key]).length === 0)
        ) {
          delete obj[key];
        }
      });
    }
  }

  clone(obj) {
    try {
      return JSON.parse(JSON.stringify(obj));
    } catch (e) {
      LogUtils.error(e);
    }
  }

  isEmpty(obj) {
    return (!obj) || Object.keys(obj).length === 0;
  }

  valuePairStringToObj(string, delimiter, assingKey): any {
    let obj = {};
    if (string) {
      string.split(delimiter).forEach((data) => {
        let exploded = data.split(assingKey);
        obj[exploded[0]] = exploded[1];
      });
    }

    return obj;
  }

  getKeyByValue(object, value) {
    let foundKey;
    if (this.isObject(object)) {
      Object.keys(object).some((key) => {
        if (object[key] === value) {
          foundKey = key;
          return true;
        }
      });
    }

    return foundKey;
  }

  // applies obj1's value if obj2's value is undefined. recursive
  merge(obj1, obj2, remainDepth = 100) {
    Object.keys(obj1).forEach((key) => {

      if (obj2[key] === undefined) {
        obj2[key] = obj1[key];
      } else if (obj2[key] === null || !(objectUtils.isObject(obj2[key]))) {
        // do nothing.
      } else if (objectUtils.isObject(obj1[key])) {
        // Recursive
        if (remainDepth > 0) {
          objectUtils.merge(obj1[key], obj2[key], remainDepth - 1);
        }
      }
    });
  }

  matchObjectKeyLiteralValue(obj, key: string, value) {
    let flag = false;
    try {
      if (obj && key) {
        let keySplit = key.split(".");
        let target = obj;
        let index = 0;
        let length = keySplit.length;
        let regex: RegExp = null;
        let stringFlag = false;

        if ("string" === typeof value) {
          stringFlag = true;
        }

        if (stringFlag) {
          try {
            regex = new RegExp(value);
          } catch (e) {
            // consumes if not regex compatible
          }
        }

        while (index < length) {
          if (target) {
            target = target[keySplit[index]];
            let target2 = null;
            if (isArray(target2)) {
              target2 = target.join(',');
            }

            if (this.matchObjectKeyLiteralValueTest(index, length, target, value, regex, stringFlag) ||
              (target2 && this.matchObjectKeyLiteralValueTest(index, length, target2, value, regex, stringFlag))) {
              flag = true;
            }
          }

          if (flag) {
            break;
          }
          ++index;
        }
      }
    } catch (e) {
      LogUtils.error(e);
    }

    return flag;
  }

  public findRulesFromConditionedRules(
    options: any,
    rules: {
      priority: number,
      conditions: {
        [key: string]: any
      }
    }[],
  ) {
    const foundRules = [];

    if (rules && rules.length) {
      rules.sort((a, b) => {
        return a.priority - b.priority;
      });

      rules.forEach((rule) => {
        if ((!rule.conditions) || (!Object.keys(rule.conditions).length)) {
          foundRules.push(rule);
        } else {
          const conditions = Object.keys(rule.conditions);
          conditions.some((condition) => {
            let keys = Object.keys(rule.conditions[condition]);
            let flag = true;

            if (keys.length) {
              keys.some((key) => {
                if (!objectUtils.matchObjectKeyLiteralValue(options, key, rule.conditions[condition][key])) {
                  flag = false;
                  return true;
                }
              });
            }

            if (flag) {
              foundRules.push(rule);
              return true;
            }
          });
        }

      });
    }
    return foundRules;
  }

  public findRuleFromConditionedRules(
    options: any,
    rules: {
      priority: number,
      conditions: {
        [key: string]: any
      }
    }[],
  ) {
    const foundRules = this.findRulesFromConditionedRules(options, rules);
    return foundRules ? foundRules[0] : null;
  }

  setPropertiesIfEmpty(source, target) {
    if (this.isObject(source) && this.isObject(target)) {
      Object.keys(target).forEach((key) => {
        if (this.isEmpty(source[key])) {
          source[key] = target[key];
        }
      });
    }
  }

  isNullOrUndefined(value) {
    return value === null || value === undefined;
  }

  objectSize(obj) {
    if (obj) {
      return JSON.stringify(obj).length;
    } else {
      return 0;
    }
  }

  modifyProperty(obj, property, depth: number, func: (obj, key) => any) {
    if (depth > 0 && obj && objectUtils.isObject(obj)) {
      Object.keys(obj).forEach((key) => {
        if (key === property) {
          obj[key] = func(obj, key);
        } else if (objectUtils.isObject(obj[key])) {
          this.modifyProperty(obj[key], property, depth - 1, func);
        }
      });
    }
  }

  modifyAllProperties(obj, depth: number, func: (obj, key) => any) {
    if (depth > 0 && obj && objectUtils.isObject(obj)) {
      Object.keys(obj).forEach((key) => {
        func(obj, key);
        if (objectUtils.isObject(obj[key])) {
          this.modifyAllProperties(obj[key], depth - 1, func);
        }
      });
    }
  }


  private matchObjectKeyLiteralValueTest(index, length, target, value, regex, stringFlag) {
    let flag = false;

    if ((index + 1) < length) {
      // do nothing.
    } else if (target === value) {
      flag = true;
    } else if (regex && regex.test(target)) {
      flag = true;
    } else if (stringFlag && evalUtils.valueMatch(target, value, true)) {
      flag = true;
    }

    return flag;
  }

}

export var objectUtils = new ObjectUtils();
