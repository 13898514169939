import { IconTextComponentReversePhone } from "./signup-pii-reverse-phone/icon-text-reverse-phone.component";
import { SignupPIIComponentReversePhone } from "./signup-pii-reverse-phone/signup-pii-reverse-phone.component";
import { IconTextComponent } from "./signup-pii/icon-text.component";
import { SignupPIIComponent } from "./signup-pii/signup-pii.component";


export const PII_DECLARATIONS = [
    SignupPIIComponent,
    IconTextComponent,
    SignupPIIComponentReversePhone,
    IconTextComponentReversePhone
]
