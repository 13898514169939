import {DEFAULT_NAME_SEARCH_TEMPLATES_DECLARATIONS} from "./default";
import {PARTICLE_1_NAME_SEARCH_TEMPLATES_DECLARATIONS} from "./particle.1";
import {DEFAULT_1_2_NAME_SEARCH_TEMPLATES_DECLARATIONS} from "./default.1.2";
import {DEFAULT_1_3_NAME_SEARCH_TEMPLATES_DECLARATIONS} from "./default.1.3";
import {COMPONENTS_NAME_SEARCH_TEMPLATES_DECLARATIONS} from "./components";
import { HEADLESS_NAME_SEARCH_TEMPLATES_DECLARATIONS } from "./headless";

export const NAME_SEARCH_TEMPLATES_DECLARATIONS = [
  ...DEFAULT_NAME_SEARCH_TEMPLATES_DECLARATIONS,
  ...DEFAULT_1_2_NAME_SEARCH_TEMPLATES_DECLARATIONS,
  ...DEFAULT_1_3_NAME_SEARCH_TEMPLATES_DECLARATIONS,
  ...PARTICLE_1_NAME_SEARCH_TEMPLATES_DECLARATIONS,
  ...COMPONENTS_NAME_SEARCH_TEMPLATES_DECLARATIONS,
  ...HEADLESS_NAME_SEARCH_TEMPLATES_DECLARATIONS,
];
