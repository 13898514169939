import {isNumber} from "util";
import {LogUtils} from "./logUtils";

class EvalUtils {

  public sequenceMatch(sequence: number, retry: number, expr, options: any): boolean {
    let flag: boolean = null;
    try {
      options = options || {};
      if (isNumber(sequence) && isNumber(retry)) {
        var s = sequence; // For eval()
        var r = retry; // For eval()
        var o = options; // For eval()
        flag = !!eval(expr);
      } else {
        LogUtils.error("Invalid sequence value", expr, sequence, retry);
      }

    } catch (e) {
      LogUtils.error("EvalUtils.sequenceMatch error", expr, sequence, retry, e);
    }
    return flag;
  }

  public ageMatch(age: number, expr): boolean {
    let flag: boolean = null;
    if (isNumber(age)) {
      // Checking possible dangerous expr. only "a" is allowed
      if (expr.match(/(?:[A-Zb-z]|[a]{2,})/)) {
        LogUtils.error("Invalid sequence expr", expr, age);
      } else {
        var a = age; // For eval()
        flag = !!eval(expr);
      }
    } else {
      LogUtils.error("Invalid sequence value", expr, age);
    }

    return flag;
  }

  public valueMatch(value, expr, suppressError = false): boolean {
    let flag: boolean = null;

    try {
      // Checking possible dangerous expr. only "v" is allowed
      if (expr.match(/(?:^\^|[A-Za-uw-z]|[v]{2,})/)) {
        if (!suppressError) {
          LogUtils.error("Invalid sequence expr", expr, value);
        }
      } else {
        var v = value; // For eval()
        flag = !!eval(expr);
      }
    } catch (e) {
      LogUtils.error(e);
    }

    return flag;
  }

}

export var evalUtils = new EvalUtils();

