import {BaseComponent} from "../../../clientCommon/components/BaseComponent";
import {ReportReviewPeopleSearch} from "../../peopleSearch/reportReviewPeopleSearch";
import {ServiceHelperService} from "../../../clientCommon/services/serviceHelper.service";
import {ActivatedRoute} from "@angular/router";

export class ReportReviewPeopleSearchMember extends ReportReviewPeopleSearch {

  constructor(serviceHelperService: ServiceHelperService,
              activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
    this.pageType = BaseComponent.PAGE_TYPE.member;
  }
}
