import {
  Component,
  OnInit,
  Input,
  NgModule,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ChangeDetectionStrategy,
  NgZone,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import Map from 'ol/Map';
import View from 'ol/View';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import OSM from 'ol/source/OSM';
import Vector from 'ol/source/Vector';
import * as olProj from 'ol/proj';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import Feature, { FeatureLike } from 'ol/Feature';
import Point from 'ol/geom/Point';
import BaseLayer from 'ol/layer/Base';
import Collection from 'ol/Collection';
import LayerGroup from 'ol/layer/Group';
import { State } from 'ol/render';
import { Coordinate } from 'ol/coordinate';

export interface PropertyMapMarker {
  id: any;

  latitude: number;

  longitude: number;
}

@Component({
  selector: 'app-member-people-search-detail-map',
  templateUrl: './map.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PropertyMapComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() positions: PropertyMapMarker[];
  @Input() zoomLevel: number;
  @Input() center?: PropertyMapMarker;
  @Input() mapHeight: number = 400;
  @Output() onClick: EventEmitter<PropertyMapMarker> = new EventEmitter();
  @ViewChild('map', { read: ElementRef, static: false }) mapRef: ElementRef;

  private map: Map;
  private layers: BaseLayer[] | Collection<BaseLayer> | LayerGroup;
  private featureLayer;
  private view: View;

  private selectedIconStyle = new Style({
    image: new Icon({
      anchor: [0, 0],
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixels',
      src: 'assets/ux1/images/icons/pin_icon.svg',
    }),
  });

  constructor(private ngZone: NgZone) { }

  ngOnInit(): void {
    this.ngZone.runOutsideAngular(() => {
      this.initMap();
    });
  }

  ngAfterViewInit() {
    this.ngZone.runOutsideAngular(() => {
      this.map = new Map({
        target: this.mapRef.nativeElement,
        layers: this.layers,
        view: this.view,
      });

      this.map.on('singleclick', (event) => {
        const clickedFeature = this.map.forEachFeatureAtPixel(event.pixel, (feature, layer) => {
          return feature;
        });

        if (!clickedFeature) {
          return;
        }

        const marker = this.markerFromFeature(clickedFeature);

        if (!marker) {
          return;  
        }

        this.onClick.emit(marker);
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes?.positions && changes?.positions?.firstChange === false) {
      const source = this.featureLayer.getSource();

      source.refresh();

      source.addFeatures(this.getInitialFeatures());
    }
  }

  private initMap() {
    this.featureLayer = new VectorLayer({
      source: new Vector({
        features: this.getInitialFeatures(),
      }),
    });

    this.layers = [
      new TileLayer({
        source: new OSM()
      }),
      this.featureLayer,
    ];

    this.view = new View({
      center: this.getInitialCenter(),
      zoom: this.getInitialZoom(),
    });
  }

  private getInitialFeatures(): Feature[] {
    if (!this.positions.length) {
      return [];
    }

    if (this.center) {
      return [
        this.makePinFeature(this.center),
        ...this.positions.map(this.makeNumberFeature.bind(this)),
      ] as Feature[];
    }

    return [
      this.makePinFeature(this.positions[0]),
      ...this.positions.map(this.makeNumberFeature.bind(this)).slice(1),
    ] as Feature[];
  }

  private getInitialCenter() {
    let center: [number, number] = this.center
      ? [this.center.longitude, this.center.latitude]
      : this.positions.length
        ? [this.positions[0].longitude, this.positions[0].latitude]
        : [0, 0];

    return olProj.fromLonLat(center);
  }

  private getInitialZoom() {
    return this.positions.length ? this.zoomLevel : 4;
  }

  private makePinFeature(position: PropertyMapMarker): Feature {
    const feature = new Feature({
      geometry: new Point(olProj.fromLonLat([position.longitude, position.latitude])),
      type: 'pin',
      id: position.id,
      latitude: position.latitude,
      longitude: position.longitude,
    });

    feature.setStyle(this.selectedIconStyle);

    return feature;
  }

  private makeNumberFeature(
    position: PropertyMapMarker,
    index: number,
  ): Feature {
    const feature = new Feature({
      geometry: new Point(olProj.fromLonLat([position.longitude, position.latitude])),
      type: 'number',
      index: index,
      id: position.id,
      latitude: position.latitude,
      longitude: position.longitude,
    });
    
    feature.setStyle(this.makeNumberStyle((index + 1).toString()));

    return feature;
  }

  private makeNumberStyle(number: string) {
    return new Style({
      renderer: this.numberRenderer(number) as any,
    });
  }

  private markerFromFeature(feature: FeatureLike): PropertyMapMarker | null {
    if (!feature) {
      return null;
    }

    return {
      id: feature.get('id'), 
      latitude: feature.get('latitude'),
      longitude: feature.get('longitude'),
    };
  }

  private numberRenderer(number: string) {
    return (position: Coordinate | Coordinate[] | Coordinate[][], state: State) => {
      const x = position[0] as number;
      const y = position[1] as number;

      state.context.beginPath();

      state.context.arc(x, y, 20, 0, 2 * Math.PI, false);

      state.context.fillStyle = '#FF4B55';

      state.context.fill();

      state.context.fillStyle = '#FFFFFF';

      state.context.font = '900 16px Roboto';

      const measureNumber = state.context.measureText(number);

      const ideographicBaseline = measureNumber?.['ideographicBaseline'] ?? 0;

      state.context.fillText(
        number,
        x - (measureNumber.width / 2),
        y + (8 + (ideographicBaseline / 2)),
      );
    };
  }
}

@NgModule({
  declarations: [PropertyMapComponent],
  imports: [CommonModule],
  exports: [PropertyMapComponent],
})
export class PropertyMapComponentModule { }
