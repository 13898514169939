import {
    ChangeDetectorRef,
    Component,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    EventEmitter,
    Input,
    NgModule,
    AfterViewInit,
    Renderer2,
    ViewChild,
    ElementRef,
  } from '@angular/core';
  import { ActivatedRoute, Router } from '@angular/router';
  import { ServiceHelperService } from '../../../../../../clientCommon/services/serviceHelper.service';
  import { PersonDetail } from '../../../personDetail';
  import { ToastrService } from '../../../../../service/toastr.service';
  import { BaseComponent } from '../../../../../../clientCommon/components/BaseComponent';
  import { peopleSearchProductKeys } from '../../../../../../common/custom/peopleSearch/peopleSearchProductKeys';
  import { NoteService, AuthenticationService } from '../../../../../../clientCommon/services';
  import { Resource } from '../../../../../../common/models/resource';
  import { CensusDataService } from '../../../../../../clientCommon/services/censusData.service';
  import { LogUtils } from '../../../../../../common/utils/logUtils';
  import { CommonModule } from '@angular/common';
  import { MiniReportHeaderModule } from '@member/peopleSearch/components/mini/header/header.component';
  import { PropertyMapComponentModule, PropertyMapMarker } from '@member/peopleSearch/components/map.component';
  import { MiniPropertyComponentModule } from '@member/peopleSearch/components/mini/property/property.component';
  import { MemberUpsellOwnedPropertiesComponentModule } from '@member/components/upsells/owned-properties';
  import { MemberUpsellPremiumBadgeComponentModule } from '@member/components/upsells/premium-badge';
  import { MiniCensusAccordionComponentModule } from '@member/peopleSearch/components/mini/census-accordion/accordion.component';
  import { MemberUpsellCensusComponentModule } from '@member/components/upsells/census';
  import { AdditionalResourcesComponentModule } from '../additional-resources/additional-resources.component';
  import { MiniButtonComponentModule } from '@member/peopleSearch/components/mini/button/button.component';
  import { MiniReportNotesComponentModule } from '@member/peopleSearch/components/mini/notes/notes.component';
  import { AdComponentModule } from 'app/components/ad/ad.component';
  import { MiniReportFooterModule } from '@member/peopleSearch/components/mini/footer/footer.component';
  import { SexOffenderComponentModule } from '@member/peopleSearch/components/sexOffender.component';
  
  declare var $: any;
  
  @Component({
    template: '',
    standalone: false
})
  export class MiniPropertiesBaseComponent extends PersonDetail {
    @Input() isPdfPrintView = false;
    @Output() showLoader = new EventEmitter<void>();
    showAllRelatives = false;
    relativesList = [];
    selectedPhotos = [];
    swiperIndex = 0;
  
    @ViewChild('scrollToTop', { static: true })
    scrollToTopBtnElement: ElementRef;
  
    additionalResourcesAddress = {
      summary: `View the current market values for the properties at ${this.person ? this.person.fName : ''}
      current and past addresses.
      Search for homes to buy or rent in the neighborhood. Select below for more details.`,
      arr: [
        {
          imgSrc: 'assets/ux1/images/zillow.png',
          url: 'https://www.zillow.com/',
        },
        {
          imgSrc: 'assets/ux1/images/realtor_dot_com_logo_detail-1.png',
          url: 'https://www.realtor.com/',
        },
        {
          imgSrc: 'assets/ux1/images/Trulia.png',
          url: 'https://www.trulia.com/',
        },
        {
          imgSrc: 'assets/ux1/images/moving.jpeg',
          url: 'https://www.moving.com/',
        },
      ],
    };
    showAllProperties = false;
    zoomLevel = 6;
    lastZip;
    neighborCount = 0;
    censusInfo: any = {};
    stateCensusInfo = {};
    propertiesList: PropertyMapMarker[] = [];
    propertyArr: Array<any> = [];
  
    constructor(
      public serviceHelperService: ServiceHelperService,
      public changeDetectorRef: ChangeDetectorRef,
      activatedRoute: ActivatedRoute,
      protected router: Router,
      public renderer: Renderer2,
      public toastr: ToastrService,
      public noteService: NoteService,
      public authService: AuthenticationService,
      protected censusService: CensusDataService
    ) {
      super(serviceHelperService, activatedRoute, toastr, noteService, authService);
    }
  
    ngOnInit() {
      window.scrollTo(0, 0);
      this.showAllRelatives = this.isPdfPrintView;
  
      return this.init().then(() => {
        for (const neighbor of this.person.associates) {
          if (neighbor?.relationship === 'Neighbor') {
            this.relativesList.push(neighbor);
            this.neighborCount += 1;
          }
        }
        this.serviceHelperService.trackingService.report({
          page: BaseComponent.PAGE.detail,
          pageType: BaseComponent.PAGE_TYPE.member,
          type: peopleSearchProductKeys.nameSearch,
          section: 'properties',
          action: 'view',
        });
        this.changeDetectorRef.detectChanges();
        this.findCensusData();
      });
    }
    ngAfterViewInit() {
      // Register scroll to top event listener.
      const screenHeight = window.screen.availHeight;
      window.onscroll = () => {
        if (document.body.scrollTop > screenHeight || document.documentElement.scrollTop > screenHeight) {
          this.renderer.setStyle(this.scrollToTopBtnElement.nativeElement, 'display', 'flex');
        } else {
          this.renderer.setStyle(this.scrollToTopBtnElement.nativeElement, 'display', 'none');
        }
      };
    }
    scrollToTopHandler() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
    async getCensusData(arr: any[], func) {
      let result;
      for (const address of arr) {
        result = await func(address);
        if (result) {
          return result;
        }
      }
      return null;
    }
  
    ngOnDestroy() {
      return super.onDestroy();
    }
  
    ngOnChanges(changes: SimpleChanges): void {
      if (changes?.person?.firstChange === false) {
        this.init().then(() => {
          this.serviceHelperService.trackingService.report({
            page: BaseComponent.PAGE.detail,
            pageType: BaseComponent.PAGE_TYPE.member,
            type: peopleSearchProductKeys.nameSearch,
            section: 'properties',
            action: 'view',
          });
          this.changeDetectorRef.detectChanges();
          this.findCensusData();
        });
      }
    }
  
    init() {
      this.showAllProperties = this.isPdfPrintView;
  
  
      const owned = this.person.properties.sort((a, b) => {
        return <any>new Date(b.filingDate) - <any>new Date(a.filingDate);
      });
      const rented = this.person.addresses
        .sort((a, b) => {
          return <any>new Date(b.firstReported) - <any>new Date(a.firstReported);
        })
        .filter((property) => {
          return !owned.find((op) => op.address.fullAddress?.toLowerCase() === property.fullAddress?.toLowerCase());
        });
      this.lastZip = this.person.addresses[0].zip;
  
      this.propertiesList = [
        ...this.person.properties.map((property) => property.address),
        ...this.person.addresses,
      ]
        .filter((address) => address?.['latitude'] && address?.['longitude'])
        .map((address, index) => {
          return {
            id: index,
            latitude: address.latitude,
            longitude: address.longitude,
          };
        });
  
      this.propertyArr = [...owned.map((p) => ({ ...p, ownershipStatus: 'C' })), ...rented];
  
      this.propertyArr.sort((a, b) => {
        const parseDate = (range: string | null) => {
          if (!range) return null; // Handle null dateRange
          const toDate = range.split('-')[1]; // Extract 'to' date
          return toDate ? new Date(toDate) : null; // Convert to Date object
        };  
        const dateA = parseDate(a.dateRange);
        const dateB = parseDate(b.dateRange);
      
        if (dateA === null && dateB === null) return 0; // Both are null
        if (dateA === null) return 1; // Null dates come last
        if (dateB === null) return -1;
      
        const dateComparison = dateB.getTime() - dateA.getTime();
  
        // If dates are the same, sort by fullAddress alphabetically
        if (dateComparison === 0) {
          return a.fullAddress.localeCompare(b.fullAddress);
        }
        return dateComparison;
      });
      
      // Tooltip init
      setTimeout(() => {
        $('.tooltip-container').tooltip({
          html: true,
          trigger: 'click',
        });
      }, 0);
      return super.init();
    }
  
    handleLoader() {
      this.showLoader.emit();
    }
  
    findCensusData() {
      const uxcId = this.uxComposite.uxConfigId;
      const uxlId = this.uxComposite.uxLayoutId;
      this.censusService
        .findCensusCommunityData(null, null, this.lastZip, this.commerceContent._id, uxcId, uxlId)
        .then((censusData: any) => {
          if (censusData.premium) {
            this.censusInfo = censusData;
            return '';
          }
          this.censusInfo.premium = false;
          if (!censusData?.value) {
            // moved logging in CensusDataService.findCensusCommunityData
            return;
          }
          Object.keys(censusData.value).forEach((key) => {
            if (censusData.value[key] === 'NULL') {
              this.censusInfo[key] = null;
            } else {
              this.censusInfo[key] = censusData.value[key];
            }
          });
          if (this.censusInfo['STATE']) {
            return this.censusInfo['STATE'];
          }
          return '';
        })
        .then((stateCode) => {
          if (!stateCode) {
            return;
          }
          this.censusService
            .findCensusCommunityData(undefined, stateCode, this.commerceContent._id, undefined, uxcId, uxlId)
            .then((censusData: Resource) => {
              if (!censusData?.value) {
                // moved logging in CensusDataService.findCensusCommunityData
                return;
              }
              Object.keys(censusData.value).forEach((key) => {
                if (censusData.value[key] === 'NULL') {
                  this.stateCensusInfo[key] = null;
                } else {
                  this.stateCensusInfo[key] = censusData.value[key];
                }
              });
            });
        })
        .catch((err) => {
          LogUtils.debug(err);
        });
    }
  
    openPhotoModal(photos) {
      this.selectedPhotos = photos;
      this.swiperIndex = 0;
      if (this.selectedPhotos?.length < 2) {
        return;
      }
      this.serviceHelperService.trackingService.report({
        page: BaseComponent.PAGE.detail,
        pageType: BaseComponent.PAGE_TYPE.member,
        type: peopleSearchProductKeys.nameSearch,
        section: 'associates',
        action: 'view_more_images',
      });
      $('#personPhotoModal').modal({
        backdrop: 'static',
      });
    }
  
    moveStep(step) {
      if (step < 0) {
        if (this.swiperIndex === 0) {
          this.swiperIndex = this.selectedPhotos.length - 1;
        } else {
          this.swiperIndex--;
        }
      } else {
        if (this.swiperIndex === this.selectedPhotos.length - 1) {
          this.swiperIndex = 0;
        } else {
          this.swiperIndex++;
        }
      }
    }
  
    cancelModal(index) {
      $('#modal-' + index).modal('hide');
    }
  
    switchShowAllRelative() {
      this.showAllRelatives = !this.showAllRelatives;
    }
  }

  