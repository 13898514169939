import {PEOPLE_SEARCH_SALES_DECLARATIONS} from "./peopleSearch/index";
import {LandingSalesComponent} from "./landingSales.component";
import { AFFILIATE_DECLARATIONS } from "./affiliate/index";

// Component Path Declations for Sales Component
export const SALES_DECLARATIONS = [
  ...PEOPLE_SEARCH_SALES_DECLARATIONS,
  LandingSalesComponent,
  AFFILIATE_DECLARATIONS,
  // These are exported from SharedModule.
  // ...SALES_COMPONENTS_DECLARATIONS,
];

