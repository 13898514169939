import { ActivatedRoute } from "@angular/router";

import { BaseComponent } from "../../clientCommon/components/BaseComponent";
import { CommerceContent } from "../../common/models/commerce/commerceContent";
import { timeUtils } from "../../common/utils/timeUtils";
import { LogUtils } from "../../common/utils/logUtils";
import { ServiceHelperService } from "../../clientCommon/services/serviceHelper.service";
import { AddressField, AddressUtils } from "../../common/utils/addressUtils";
import { peopleSearchContentHelper, PeopleSearchContentHelper } from "../../common/custom/peopleSearch/peopleSearchHelper";
import { peopleSearchProductKeys } from "../../common/custom/peopleSearch/peopleSearchProductKeys";
import { phoneUtils } from "../../common/utils/phoneUtils";
import { CommerceOrder } from "../../common/models/commerce/commerceOrder";
import { redirectHelper } from "../../clientCommon/helper/redirectHelper";
import { clientPaths } from "../../common/helpers/pathHelpers";
import { customClientPaths } from "../../common/custom/customPathHelpers";
import { HeadlessHelper } from "../helper/headlessHelper";
import { stringUtils } from "../../common/utils/stringUtils";
import { thinMatchType as ThinMatchTypeEnum } from "../../common/custom/models/peopleSearch/searchedPerson";

export class SearchResultPeopleSearch extends BaseComponent {
  timeUtils = timeUtils;
  startTimestamp;
  hasAccess = false;
  commerceContent: CommerceContent = undefined;
  candidateSearchObject;

  testimonialsEnabled = false;
  exactNameVerifiedEnabled = false;
  bestMatchEnabled = false;

  date: Date = new Date();
  searchResultLimit = 10; // load more limit
  totalResultLimit = 50;
  isTotalResultLimit = false;
  isSrpNewEnabled = false;

  addressField: AddressField = AddressUtils.getAddressField("US");
  modifySearchInput: any = {};
  sortTypes = PeopleSearchContentHelper.SORT_TYPE;
  sortNames: any = {};
  sortName;

  retryDelay = 500;
  retryLimit = 10;
  personCount = 0;
  phoneUtils = phoneUtils;
  stringUtils = stringUtils;

  isReversePhone = false;
  brandId: string;

  isNameSearchAllowed = true;
  isPhoneSearchAllowed = true;
  isSearchLimited = false;

  constructor(
    serviceHelperService: ServiceHelperService,
    activatedRoute: ActivatedRoute
  ) {
    super(serviceHelperService, activatedRoute);
    this.page = BaseComponent.PAGE.searchResult;

    this.sortNames[PeopleSearchContentHelper.SORT_TYPE.qualification] =
      "Relevance";
    this.sortNames[PeopleSearchContentHelper.SORT_TYPE.age] = "Age";
    this.sortNames[PeopleSearchContentHelper.SORT_TYPE.name] = "Name";

    this.sortName =
      this.sortNames[PeopleSearchContentHelper.SORT_TYPE.qualification];
  }

  onInit(): Promise<any> {
    this.isReversePhone =
      this.uxHelper?.pageCategory === SearchResultPeopleSearch.PAGE_CATOGORY.phoneSearch;
    this.startTimestamp = timeUtils.getTimestamp();

    this.addressField.addAdministrativeAreaOption({ all: "Nationwide" });
    this.addressField.sortAdministrativeAreaOptionsArray();

    return this.baseInit().then(() => {
      this.checkThinMatchFlow();

      this.testimonialsEnabled = !!this.uxHelper.getUxcomp(
        "testimonials.enabled"
      );
      this.exactNameVerifiedEnabled = !!this.uxHelper.getUxcomp(
        "exact.name.verified.enabled"
      );
            
      this.bestMatchEnabled = !!this.uxHelper.getUxcomp("best.match.enabled");
      if (!this.modifySearchInput.state) {
        if (!this.userInput.state) {
          if (this.uxComposite.requestState) {
            this.modifySearchInput.state = this.uxComposite.requestState;
          } else {
            this.modifySearchInput.state = "all";
          }
        }
      }

      window.scrollTo(0, 0);
    });
  }

  onDestroy() {
    return super.onDestroy();
  }

  next() {
    if (this.stillInThisPage()) {
      return this.goNextPage();
    }
  }

  

  getAdNum(i) {
    return Math.ceil((i/4))
  }


  // Check if its a thin match case and thin match uxc is enabled then redirect to signup
  checkThinMatchFlow() {
    const thinMatchEnabled = this.serviceHelperService.storageService.getSession("thinMatchEnabled");

    if (thinMatchEnabled) {
      let thinMatchType = this.serviceHelperService.storageService.getSession("thinMatchType");
      
      if (!thinMatchType) {
        thinMatchType = ThinMatchTypeEnum.NoResults;
      }

      // tracking for checking if the user get the thin match experience & if yes then, which thin match scenrios is it
      this.serviceHelperService.trackingService.report({
        splits: {thinMatch: thinMatchType},
        type: "thinMatch",
        status: thinMatchEnabled,
        method: thinMatchType,
      });

      if (!this.serviceHelperService.authenticationService?.isLoggedIn()) {
        if (HeadlessHelper.isEnabled(this.uxComposite)) {
          // In the headless state, 
          // the view is output from the HeadlessSearchResultNameSearchComponent component 
          // without next or redirect even if it is a thinMatch.
        } else {
          if(this.theme === this.themes.ux_5_2) {
            window.location.replace(customClientPaths.salesNameSearchReportReview);
          }else {
            this.next();
          }
        }
      }
    } else {
      this.serviceHelperService.trackingService.report({
        splits: {thinMatch: "none"},
      });
    }
  }

  search(): Promise<any> {
    this.serviceHelperService.spinnerService.spin();
    return this.findCommerceContent()
      .then(() => {
        if (
          this.stillInThisPage() &&
          this.commerceContent &&
          this.commerceContent.progressors &&
          this.commerceContent.progressors.length
        ) {
          this.serviceHelperService.progressorService.setProgressor(
            this.commerceContent.progressors[0]
          );
          this.candidateSearchObject =
            peopleSearchContentHelper.chooseCandidateSearchObject(
              this.commerceContent
            );
          /* not allowing auto redirect in case customer re-choose
        if (!this.serviceHelperService.progressorService.goToCurrentProgressPage()) {
          this.serviceHelperService.progressorService.setCurrentProgress(this.startTimestamp);
        }
        */
        } else {
          return Promise.reject(false);
        }
      })
      .then(() => {
        let searchedObject =
          this.serviceHelperService.peopleSearchService.getSearchedObject();
        if (searchedObject && searchedObject.extId) {
          try {
            searchedObject =
              this.commerceContent.getMainRaw().tempClient.processed.person[0];
            this.serviceHelperService.peopleSearchService.setSearchedObject(
              null
            );
          } catch (e) {
            // consume
          }
          return this.submit(searchedObject);
        }
        this.personCount = this.getPersonCount();
        LogUtils.debug(
          this.personCount,
          this.commerceContent,
          this.commerceContent.getMainRaw()
        );
        if (this.commerceContent.getMainRaw()?.meta?.type === 'nameSearchTopTeaser') {
          this.isSrpNewEnabled = true;  // this.uxHelper.getValue("comp.SRP.flag.enable") === 'true' ? true: false;
          LogUtils.debug('srpNewEnable => ', this.isSrpNewEnabled, this.commerceContent.getMainRaw()?.meta?.type);          
        }
        
      })
      .catch((e) => {
        if (e !== false) {
          LogUtils.error(e, "Can't search commerceContent");
        }
      })
      .then(() => {
        this.serviceHelperService.spinnerService.unspin();
      });
  }

  findCommerceContent() {
    return this.serviceHelperService.peopleSearchService
      .getCommerceContent(this.uxHelper.uxComposite)
      .then((commerceContent: CommerceContent) => {
        this.uxComposite.setCode("commerceContent", commerceContent);
        this.commerceContent = commerceContent;

        
        peopleSearchContentHelper.processResult(
          this.uxComposite,
          this.commerceContent
        );
        this.initModifySearch();
      })
      .catch((e) => {
        LogUtils.debug(e, this.retryLimit);
        this.initModifySearchForNonCommerceContent();
        if (!this.destroyed) {
          return new Promise((fulfill, reject) => {
            this.retryLimit--;
            if (this.retryLimit > 0) {
              setTimeout(() => {
                this.findCommerceContent().then(() => {
                  fulfill(true);
                });
              }, this.retryDelay);
            } else {
              fulfill(true);
            }
          });
        }
      });
  }

  initModifySearch() {
    if (
      this.commerceContent &&
      this.commerceContent.content &&
      this.commerceContent.content[0] &&
      this.commerceContent.content[0].contentInfo
    ) {
      let contentInfo = this.commerceContent.content[0].contentInfo;

      this.modifySearchInput.fname = contentInfo.fname;
      this.modifySearchInput.mname = contentInfo.mname;
      this.modifySearchInput.lname = contentInfo.lname;
      this.modifySearchInput.city = contentInfo.city;
      this.modifySearchInput.state = contentInfo.state;
      this.modifySearchInput.minAge = contentInfo.minAge;
      this.modifySearchInput.maxAge = contentInfo.maxAge;
      this.modifySearchInput.email = contentInfo.email;
      if (!this.isReversePhone) {
        this.modifySearchInput.phone = contentInfo.phone;
      }
    }
  }

  initModifySearchForNonCommerceContent() {
    this.modifySearchInput.fname = this.userInput.fname;
    this.modifySearchInput.mname = this.userInput.mname;
    this.modifySearchInput.lname = this.userInput.lname;
    this.modifySearchInput.city = this.userInput.city;
    this.modifySearchInput.state = this.userInput.state;
    this.modifySearchInput.minAge = this.userInput.minAge;
    this.modifySearchInput.maxAge = this.userInput.maxAge;
    this.modifySearchInput.email = this.userInput.email;
    if (!this.isReversePhone) {
      this.modifySearchInput.phone = this.userInput.phone;
    }
  }

  isValidModifySearch() {
    return (
      this.modifySearchInput.fname &&
      this.modifySearchInput.lname &&
      this.modifySearchInput.state
    );
  }

  modifySearch(id) {
    this.userInput.fname = this.modifySearchInput.fname;
    this.userInput.mname = this.modifySearchInput.mname;
    this.userInput.lname = this.modifySearchInput.lname;
    this.userInput.city = this.modifySearchInput.city;
    this.userInput.state = this.modifySearchInput.state;
    this.userInput.from = this.uxHelper.prefix;
    this.userInput.minAge = this.modifySearchInput.minAge;
    this.userInput.maxAge = this.modifySearchInput.maxAge;
    this.userInput.email = this.modifySearchInput.email;
    this.userInput.phone = this.modifySearchInput.phone;
    this.serviceHelperService.peopleSearchService.clear();

    this.serviceHelperService.userInputService.storeUserInput();
    this.serviceHelperService.userInfoService.storeUserInfo();
    this.serviceHelperService.inputTrackingService.storeSearch(this.userInput);

    let param = { type: "submit", step: "modifySearch", id: id };

    this.serviceHelperService.trackingService.report(param).catch((e) => {
      LogUtils.error(e);
    });

    let pageInfo = {
      pageType: this.pageType,
      pageCategory: this.pageCategory,
      page: BaseComponent.PAGE.landing,
    };
    return this.goNextPage(pageInfo);
  }

  submitFirstResultIfPossible() {
    return Promise.resolve().then(() => {
      if (
        this.getProductKeyByPageCategory() ===
        peopleSearchProductKeys.phoneSearch
      ) {
        return this.submitFirstResult();
      }
    });
  }

  getPersonCount() {
    return this.commerceContent.getMainRaw().tempClient.processed.person.length;
  }

  submitFirstResult() {
    try {
      let firstResult = null;
      try {
        firstResult =
          this.commerceContent.getMainRaw().tempClient.processed.person[0];
      } catch (e) {
        // consume
      }
      if (firstResult) {
        return this.submit(firstResult);
      } else {
        return Promise.reject(false);
      }
    } catch (e) {
      LogUtils.error(e);
      return Promise.reject(e);
    }
  }

  checkOrder(): Promise<any> {
    return this.getCommerceOrdersByPageCategory()
      .then((commerceOrders: CommerceOrder[]) => {
        const [firstCommerceOrder] = commerceOrders;
        if (firstCommerceOrder) {
          this._setSearchAccess(firstCommerceOrder);
        }

        if (commerceOrders && commerceOrders.length > 0) {
          this.hasAccess = true;
        }
      })
      .catch(() => {
        LogUtils.error("has no order for this page");
      });
  }
  private _setSearchAccess(commerceOrder: CommerceOrder) {
    this.isNameSearchAllowed = commerceOrder.tempClientSecured?.processes?.nameSearch?.canProcess ?? true;
    this.isPhoneSearchAllowed = commerceOrder.tempClientSecured?.processes?.phoneSearch?.canProcess ?? true;

    if (!this.isNameSearchAllowed || !this.isPhoneSearchAllowed) {
      this.isSearchLimited = true;
    }
  }

  async submit(searchedResult) {
    if (this.serviceHelperService.authenticationService.isLoggedIn()) {
      await this.checkOrder();
      // If the user do not have access it will redirect to member/account/profile      
      if (!this.hasAccess) {
        // return redirectHelper.redirect(this.serviceHelperService, clientPaths.memberAccountProfile);
        return redirectHelper.redirect(
          this.serviceHelperService,
          clientPaths.memberAccountProfile
        );
      } 
      // search allowed check - name search or phone search
      // searchedResult.productKey => nameSearch, phoneSearch
      if (searchedResult.productKey === peopleSearchProductKeys.nameSearch 
            || searchedResult.productKey === peopleSearchProductKeys.phoneSearch) { 
          if (this.isSearchLimited) {
            this.serviceHelperService.limitStorageService.searchLimited = 'true';
            return redirectHelper.redirect(this.serviceHelperService, customClientPaths.memberNameSearchLanding);          
          }          
      }

    }

    this.serviceHelperService.spinnerService.spin();
    return Promise.resolve()
      .then(() => {
        this.serviceHelperService.inputTrackingService.storeTarget(
          searchedResult
        );
        return this.submitAnswer(searchedResult.extId, true);
      })
      .then(() => {
        return this.serviceHelperService.progressorService.endCurrentProgress(
          timeUtils.getTimestamp()
        );
      })
      .then(() => {
        this.serviceHelperService.spinnerService.unspin();
      })
      .then(() => {
        this.next();
      })
      .catch((e) => {
        LogUtils.error(e);
        return Promise.reject(e);
      });
  }


  async shareSubmit(searchedResult, pageCategory) {
    this.serviceHelperService.spinnerService.spin();
    return Promise.resolve()
      .then(() => {
        this.serviceHelperService.inputTrackingService.storeTarget(
          searchedResult
        );

        let input = peopleSearchContentHelper.shapePersonIdAnswer(searchedResult.extId, true);
        this.commerceContent.inputs.push(input);
        this.serviceHelperService.peopleSearchService.setCommerceContent(this.commerceContent);
        return this.serviceHelperService.peopleSearchService.submitAnswer(input);        
      })
      .then(() => {
        this.serviceHelperService.spinnerService.unspin();
      })
      .then(() => {
        // signup page
        this.pageType = BaseComponent.PAGE_TYPE.sales;
        this.pageCategory = pageCategory;  // name, phone
        this.page = BaseComponent.PAGE.reportReview;        
        this.next();
      })
      .catch((e) => {
        LogUtils.error(e);
        return Promise.reject(e);
      });
  }

  hasMore(): boolean {
    let flag = false;

    try {
      flag =
        this.commerceContent.getMainRaw().tempClient.processed?.person?.length >
        this.searchResultLimit;
    } catch (e) {
      // Do nothing
    }

    return flag;
  }

  loadMore() { 
    this.searchResultLimit += 10;

    if (this.searchResultLimit >= this.totalResultLimit) {
      // more button disable and notice title print
      this.isTotalResultLimit = true;
    }

    if (this.pageType !== BaseComponent.PAGE_TYPE.member) {
      return;
    }
    this.serviceHelperService.trackingService.report({
      page: BaseComponent.PAGE.searchResult,
      pageType: BaseComponent.PAGE_TYPE.member,
      action: "paginate",
    });
  }

  submitAnswer(personId, answer) {
    let input = peopleSearchContentHelper.shapePersonIdAnswer(personId, answer);
    this.commerceContent.inputs.push(input);
    this.serviceHelperService.progressorService.removeProgressorEvents(
      this.pageType,
      this.pageCategory,
      BaseComponent.PAGE.reportReview
    );
    return this.serviceHelperService.peopleSearchService.submitAnswer(input);
  }

  sortBy(type, ascending = true) {
    this.sortName = this.sortNames[type];

    if (type === 'Select') {
      type = 'qualification';
    }
    if (this.commerceContent) {
      peopleSearchContentHelper.sortBy(this.commerceContent, type, ascending);

      if (this.pageType !== BaseComponent.PAGE_TYPE.member) {
        return;
      }
      this.serviceHelperService.trackingService.report({
        page: BaseComponent.PAGE.searchResult,
        pageType: BaseComponent.PAGE_TYPE.member,
        action: "refine",
      });
    }
  }

  getSortTypeByName(name) {
    let type = null;
    Object.keys(this.sortNames).some((key) => {
      if (this.sortNames[key] === name) {
        type = key;
        return true;
      }
    });

    return type;
  }

  getSortTypeByDefaultName(name) {
    let type = 'Select';
    Object.keys(this.sortNames).some((key) => {
      if (this.sortNames[key] === name) {
        type = key;
        return true;
      }
    });

    return type;
  }

  formatString(value, key) {
    // const replaced = value.replace(/[0-9]/g, '');
    const replaced = value;
    if (key === "fname") {
      this.modifySearchInput.fname = replaced;
    } else if (key === "lname") {
      this.modifySearchInput.lname = replaced;
    } else if (key === "mname") {
      this.modifySearchInput.mname = replaced;
    }
    return replaced;
  }

  formatPhone(value) {
    let formatted = "";
    if (value) {
      value = value.replace(/(?:(?:^[^0-9]*1*)|(?:[^0-9]))/g, "");
      let length = value.length;
      if (length > 0) {
        formatted = "(" + value.substring(0, 3);
      }
      if (length > 3) {
        formatted += ") " + value.substring(3, 6);
      }
      if (length > 6) {
        formatted += "-" + value.substring(6, 10);
      }
    }
    this.modifySearchInput.phone = formatted;
    return formatted;
  }

  formatPhoneNumber(event) {
    const value = event.target.value;
    const replaced = this.phoneUtils.formatPhoneNumber(value);
    this.modifySearchInput.phone = replaced;
    event.target.value = replaced;
  }

  // filterInput(event) {
  //   const inputElement = event.target as HTMLInputElement;
  //   inputElement.value = inputElement.value.replace(/[^a-zA-Z0-9_\-\s]/g, '');
  //   event.target.value = inputElement.value;
  // }
}
