import {CommerceOrder} from "../../models/commerce/commerceOrder";

export class IdProtectionUtils {
  public static META_KEY = "IdProtection";

  public getMeta(commerceOrder: CommerceOrder) {
    if (commerceOrder?.meta) {
      return commerceOrder.meta[IdProtectionUtils.META_KEY];
    }
  }

  public hasValidProductKey(commerceOrders: CommerceOrder[], productKey) {
    let flag = false;
    if (commerceOrders?.length) {
      commerceOrders.some((commerceOrder) => {
        if (commerceOrder.isActive()) {
          const meta = this.getMeta(commerceOrder);
          let validCommerceProductKeys = meta?.validCommerceProductKeys;
          if (!validCommerceProductKeys && commerceOrder?.tempClientSecured && commerceOrder?.tempClientSecured[IdProtectionUtils.META_KEY]) {
            validCommerceProductKeys = commerceOrder?.tempClientSecured[IdProtectionUtils.META_KEY]?.validCommerceProductKeys;
          }
          if (validCommerceProductKeys?.length) {
            if (validCommerceProductKeys.includes(productKey)) {
              flag = true;
              return true;
            }
          }
        }
      });
    }
    return flag;
  }

}

export const idProtectionUtils = new IdProtectionUtils();
