import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgSelectModule } from "@ng-select/ng-select";
import { TooltipModule } from "ng2-tooltip-directive";
import { UiSwitchModule } from "ngx-ui-switch";

import { THEMES_DECLARATIONS } from "./themes";
import { SALES_COMPONENTS_DECLARATIONS } from "./sales/components";
import { COMPONENT_DECLARATIONS, COMPONENT_MODULES } from "./components";
import { ReversePipe } from "../clientCommon/pipes/reverse.pipe";
import { ToArrayPipe } from "../clientCommon/pipes/toArray.pipe";
import { ToDateObjectPipe } from "../clientCommon/pipes/toDateObject.pipe";
import { ToDateTimePipe } from "../clientCommon/pipes/toDateTime.pipe";
import { CREDIT_CARD_DECLARATIONS } from "./member/components/creditCard";
import { MARKETING_PROMO_DECLARATIONS } from "./member/components/marketing-promo";
import { CPCC_ENTERY_CREDIT_CARD_DECLARATIONS } from "./member/components/cpcc-entery-card";
import { SetUxCompKeyIdDirectiveModule } from "@themes/2234200bd3cc36ba97274ecbdc6c6a47/directives";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    UiSwitchModule,
    NgSelectModule,
    TooltipModule,
    ...COMPONENT_MODULES,
    SetUxCompKeyIdDirectiveModule,
  ],
  declarations: [
    ToDateTimePipe,
    ToDateObjectPipe,
    ToArrayPipe,
    ReversePipe,
    ...THEMES_DECLARATIONS,
    ...SALES_COMPONENTS_DECLARATIONS,
    ...COMPONENT_DECLARATIONS,
    ...CREDIT_CARD_DECLARATIONS,
    ...MARKETING_PROMO_DECLARATIONS,
    ...CPCC_ENTERY_CREDIT_CARD_DECLARATIONS,
  ],
  exports: [
    // imports
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    UiSwitchModule,
    NgSelectModule,
    TooltipModule,
    ...COMPONENT_MODULES,
    // declarations
    ToDateTimePipe,
    ToDateObjectPipe,
    ToArrayPipe,
    ReversePipe,
    SetUxCompKeyIdDirectiveModule,
    ...THEMES_DECLARATIONS,
    ...SALES_COMPONENTS_DECLARATIONS,
    ...COMPONENT_DECLARATIONS,
    ...CREDIT_CARD_DECLARATIONS,
    ...MARKETING_PROMO_DECLARATIONS,
    ...CPCC_ENTERY_CREDIT_CARD_DECLARATIONS,
  ],
})
export class SharedModule { }