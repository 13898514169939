// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#signup .signup-hero-left {
  width: auto;
}

::ng-deep #signup a {
  color: gray;
}
@media (max-width: 476px) {
  ::ng-deep #signup .email-row {
    flex-direction: column;
  }
}
::ng-deep .user-hidden-blury {
  color: #525252;
  -webkit-user-select: none;
          user-select: none;
  filter: blur(4px);
}

.div-center {
  display: flex;
  justify-content: center;
}

::ng-deep .theme-ux_3_1 .s1 {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  text-align: left;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
  color: #000;
}
@media (max-width: 620px) {
  ::ng-deep .theme-ux_3_1 .s1 {
    font-size: 24px;
    line-height: 36px;
  }
}
@media (max-width: 620px) {
  ::ng-deep .theme-ux_3_1 .signup-container {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 620px) {
  ::ng-deep .theme-ux_3_1 .signup-form-body {
    padding: 10px;
  }
}
@media (max-width: 576px) {
  ::ng-deep .theme-ux_3_1 .image-10 {
    margin-right: 20px;
  }
}

.div-btn {
  width: 100%;
  display: flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/app/themes/3842FF07E386F64F7AC9CD6AC71A737C/peopleSearch/nameSearch/signup.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAIE;EACE,WAAA;AADJ;AAKI;EADF;IAEI,sBAAA;EAFJ;AACF;AAIE;EACE,cAAA;EACA,yBAAA;UAAA,iBAAA;EACA,iBAAA;AAFJ;;AAMA;EACE,aAAA;EACA,uBAAA;AAHF;;AAME;EACE,oBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,kCAAA;EACA,sCAAA;UAAA,8BAAA;EACA,WAAA;AAHJ;AAII;EATF;IAUI,eAAA;IACA,iBAAA;EADJ;AACF;AAOI;EADF;IAEI,kBAAA;IACA,mBAAA;EAJJ;AACF;AAOI;EADF;IAEI,aAAA;EAJJ;AACF;AAOI;EADF;IAEI,kBAAA;EAJJ;AACF;;AAQA;EACE,WAAA;EACA,aAAA;EACA,uBAAA;AALF","sourcesContent":["#signup .signup-hero-left {\n  width: auto;\n}\n\n\n::ng-deep {\n  #signup a {\n    color: gray;\n  }\n\n  #signup .email-row {\n    @media (max-width: 476px) {\n      flex-direction: column;\n    }\n  }\n  .user-hidden-blury {\n    color: #525252;\n    user-select: none;\n    filter: blur(4px);\n  }\n}\n\n.div-center {\n  display: flex;\n  justify-content: center;\n}\n::ng-deep .theme-ux_3_1 {\n  .s1 {\n    font-family: Poppins;\n    font-size: 32px;\n    font-weight: 700;\n    line-height: 48px;\n    text-align: left;\n    text-underline-position: from-font;\n    text-decoration-skip-ink: none;\n    color: #000;  \n    @media (max-width: 620px) {\n      font-size: 24px;\n      line-height: 36px;\n    }\n  }\n  .s2 {\n    \n  }\n  .signup-container {\n    @media (max-width: 620px) {\n      padding-left: 10px;\n      padding-right: 10px;\n    }\n  }\n  .signup-form-body {\n    @media (max-width: 620px) {\n      padding: 10px;\n    }\n  }\n  .image-10 {\n    @media (max-width: 576px) {\n      margin-right: 20px;\n    }\n  }\n}\n\n.div-btn {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
