import {Component, ComponentFactoryResolver, Input, OnChanges, OnInit, NgModule} from "@angular/core";
import {UxHelper} from "../../../../../clientCommon/helper/uxHelper";
import {LandingPeopleSearch} from "../../../../peopleSearch/landingPeopleSearch";
import {UxComposite} from "../../../../../common/models/ux/uxComposite";
import { CommonModule } from '@angular/common';
import { UxTemplateModule } from 'app/components/uxTemplate.component';
// import { FooterComponentModule, HeaderComponent, HeaderComponentModule } from '@ux5.2/common';
import { LandingAffilateFormComponent } from "@sales/affiliate/affiliateForm.component";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServiceHelperService } from "@clientCommon/services/serviceHelper.service";
import { ActivatedRoute } from "@angular/router";
import { LandingAffilateComponent } from "@sales/affiliate/affiliate.componet";
// import { ContentCardComponentModule } from '@ux5.2/components/landing';

type utmParamValueType = {
  header1: string;
  header2: string;
};

@Component({
  selector: 'ux-5-2-affiliate',
  templateUrl: './affiliate.component.html',
  styleUrls: ['./affiliate.component.css'],
})
export class Ux5Dot2AffiliateComponent extends LandingAffilateComponent implements OnInit, OnChanges {
  @Input() base: LandingAffilateComponent;
  @Input() uxHelper: UxHelper;
  @Input() pageType: string;
  @Input() pageCategory: string;
  @Input() page: string;
  @Input() utmParamValue: utmParamValueType = null;
  constructor(serviceHelperService: ServiceHelperService,
    activatedRoute: ActivatedRoute,
    cfr: ComponentFactoryResolver) {
      super(serviceHelperService, activatedRoute, cfr);
  }

  init() {
    return super.baseInit().then(() => {});
  }

  ngOnInit() {
    return super.ngOnInit().then(() => {
      this.init();
    });
  }

  ngOnChanges() {
    return this.init();
  }
}

// @NgModule({
//   declarations: [Ux5Dot2AffiliateComponent],
//   imports: [
//     CommonModule,
//     FormsModule,
//     ReactiveFormsModule,

//     UxTemplateModule,

//     HeaderComponentModule,
//     FooterComponentModule,
//     ContentCardComponentModule,
//   ],
// })
// export class Ux5Dot2AffiliateComponentModule {}