import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgModule,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceHelperService } from '../../../../../../clientCommon/services/serviceHelper.service';
import { PersonDetail } from '../../../personDetail';
import { ToastrService } from '../../../../../service/toastr.service';
import { phoneUtils } from '../../../../../../common/utils/phoneUtils';
import { AddressField, AddressUtils } from '../../../../../../common/utils/addressUtils';
import { AuthenticationService, NoteService } from '../../../../../../clientCommon/services';
import { environment } from '../../../../../../environments/environment';
import { UxHelper } from '../../../../../../clientCommon/helper/uxHelper';
import { SearchedPerson } from '../../../../../../common/custom/models/peopleSearch/searchedPerson';
import { CommonModule, DatePipe } from '@angular/common';
import { BaseComponent } from '../../../../../../clientCommon/components/BaseComponent';
import { CommerceContent } from '../../../../../../common/models/commerce/commerceContent';
import { ModelBase } from '../../../../../../common/models/modelBase';
import { LogUtils } from '../../../../../../common/utils/logUtils';
import { FormsModule } from '@angular/forms';
import { FactFinderUtils, FactFinderConfig } from '../../../../../../common/utils/factFinderUtils';
import { peopleSearchProductKeys } from '../../../../../../common/custom/peopleSearch/peopleSearchProductKeys';
import { commerceUtils } from '../../../../../../common/utils/commerceUtils';
import { stringUtils } from '../../../../../../../../common/utils/stringUtils';

@Component({
  selector: 'app-fact-finders',
  templateUrl: 'factFinders.component.html',
  styleUrls: ['factFinders.component.scss'],
})
export class FactFindersComponent extends PersonDetail implements OnInit, OnDestroy {
  href = '';

  @Output() showLoader = new EventEmitter<void>();
  @Input() person: SearchedPerson;
  @Input() uxHelper: UxHelper;
  @Input() base: any;
  @ViewChild('submitBtn') submitBtn: ElementRef<HTMLElement>;

  phoneUtils = phoneUtils;
  addressField: AddressField = AddressUtils.getAddressField('US');
  customerNumber = '';
  loaderEyeTimeout: number = environment.miniDetailsLoaderEyeTimeout; //2000; // loader animated Eye Timeout
  stringUtils = stringUtils;  
  stateList = this.addressField;
  ageList = [];
  model = {
    fname: '',
    mname: '',
    lname: '',
    city: '',
    state: 'all',
    // age: "",
    phone: '',
    email: '',
    // relativeFName: "",
    // relativeLName: ""
  };
  relativesList = [];

  allStateObj = [{ code: 'all', name: 'All States' }];
  addressFieldWithAllStateOption;
  productType: string;
  selectedPhotos = [];
  swiperIndex = 0;
  showAllRelatives = false;
  selectRequestType = [
    'Point of Contact',
    'Social Media',
    'Family',
    'Life Events',
    'Properties',
    'Career & Financial',
    'Criminal',
    'Other',
  ];
  subRequestContent: any = {
    requestDate: '',
    targetReportPersonName: '',
    requestType: '',
    requestDetails: '',
    orderNumber: 0,
    reportCommerceContentId: '',
    withdrawRequestStatus: 'inActive',
    commerceOrderId: '',
  };
  ffReportStatuses: {
    id: string;
    status: string;
    reportId: string;
  }[];
  ffStatus: string = 'inactive';
  dateOfRequest: Date = new Date();
  pDate = new DatePipe('en-US');
  cDate = null;
  typeErrorMessage: boolean = false;
  detailErrorMessage: boolean = false;
  disabledBoxNoRequest: boolean = false;
  getRadioValue: string = '';
  brandName: string = '';
  targetReportIds: string[];
  conditionForFFRequestStatusCheck: boolean = false;
  hideFFRequestModel: boolean = true;
  showFFSubmitedModel: boolean = false;
  getCurrentUserReport = {
    requestDate: '',
    requestDetails: '',
    requestId: 0,
    requestType: '',
    targetReportId: '',
    targetReportPersonName: '',
    orderNumber: '',
    withdrawRequestStatus: '',
    _id: '',
  };
  showCurrentUserReport = {
    requestDate: '',
    requestDetails: '',
    requestId: 0,
    requestType: '',
    targetReportId: '',
    targetReportPersonName: '',
    orderNumber: '',
    withdrawRequestStatus: '',
    _id: '',
  };
  checkONWithdrawText: boolean = false;
  ffLockTimeMinute: number = 0;
  ffConfig: FactFinderConfig;
  ffCanAccess: boolean = false;
  ffSubmiting: boolean = false;

  constructor(
    public serviceHelperService: ServiceHelperService,
    public changeDetectorRef: ChangeDetectorRef,
    activatedRoute: ActivatedRoute,
    private router: Router,
    public toastr: ToastrService,
    public authService: AuthenticationService,
    public noteService: NoteService
  ) {
    super(serviceHelperService, activatedRoute, toastr, noteService, authService);
  }

  ngOnInit() {
    const user = this.authService.getUser();
    if (user?.ffRequests) {
      this.getAllFFRequestByCheckStatus(Object.keys(user?.ffRequests));
      this.checkRequestStatus();
    }
    this.onDateFormatSet();
    this.init();
  }

  ngOnDestroy() {
    return super.onDestroy();
  }

  init() {
    return super.init().then(() => {
      this.customerNumber = this.uxHelper.getValue('comp.brand.customer.phone');

      this.ffLockTimeMinute = FactFinderUtils.getLockTimeMinuteFromUxComposite(this.uxHelper.uxComposite);

      this.setFFConfig();
    });
  }

  get getBrandName(): string {
    return (this.brandName = this.base.uxHelper.getValue('comp.brand.name'));
  }

  handlePrevious() {
    window.scrollTo(0, 0);
    this.showLoader.emit();
    setTimeout(() => {
      this.router.navigate([`member/nameSearch/detail/${this.commerceContent._id}/criminalRecords`]);
    }, this.loaderEyeTimeout);
  }

  async onSubmitRequest() {
    try {
      this.ffSubmiting = true;

      if (!this.isValidate()) {
        this.ffSubmiting = false;
        return;
      }

      this.submitBtn.nativeElement.dataset.target = '#fact-finders-submitted-request-modal';
      this.submitBtn.nativeElement.dataset.dismiss = 'modal';
      const orderNumber = '0123456789121314153';
      this.subRequestContent.orderNumber = '01' + Math.floor(1000 + Math.random() * Number(orderNumber));
      this.subRequestContent.reportCommerceContentId = this.base.commerceContent._id;
      this.subRequestContent.targetReportPersonName = this.person.fName + ' ' + this.person.lName;
      this.subRequestContent.requestDate = this.cDate;
      this.subRequestContent.commerceOrderId = this.ffConfig.orderId;
      this.serviceHelperService.commerceService.addFactFindersRequest(this.subRequestContent).then((res) => {
        const commerceContent: CommerceContent = res;
        Object.assign(this.showCurrentUserReport, this.subRequestContent);

        if (commerceContent?.status === ModelBase.STATUS.requested) {
          this.hideFFRequestModel = false;
          this.showFFSubmitedModel = true;
        }

        this.ffStatus = ModelBase.STATUS.requested;
        this.ffConfig = this.ffConfig.copyWith({
          userCredit: this.ffConfig.userCredit - 1,
        });
        this.conditionForFFRequestStatusCheck = true;

        this.serviceHelperService.trackingService.report({
          page: BaseComponent.PAGE.detail,
          pageType: BaseComponent.PAGE_TYPE.member,
          type: peopleSearchProductKeys.factFinder,
          action: 'factFinderRequested',
          factFinderCommerceContentId: commerceContent._id,
          customerId: commerceContent.ownerId,
        });
        this.isEmpty();
      }).finally(() => {
        this.ffSubmiting = false;
      });
    } catch (error) {
      this.ffSubmiting = false;
      return error;
    }
  }

  isEmpty() {
    this.subRequestContent.requestDate = '';
    this.subRequestContent.requestType = '';
    this.subRequestContent.targetReportPersonName = '';
    this.subRequestContent.withdrawRequestStatus = 'inactive';
    this.subRequestContent.requestDetails = '';
    this.getRadioValue = '';
    this.submitBtn.nativeElement.dataset.target = undefined;
    this.submitBtn.nativeElement.dataset.dismiss = undefined;
  }

  getAllFFRequestByCheckStatus(ids) {
    const querySelector = { ids };
    
    this.getFFRequestshowStatus(querySelector).then(async (res: any) => {
      this.ffReportStatuses = res?.map((r) => ({
        id: r?._id,
        status: r?.status,
        reportId: r?.content?.reportCommerceContentId,
      })) ?? [];

      this.ffStatus = this.ffReportStatuses.find(
        (e) => e.reportId === this.base.commerceContent._id,
      )?.status || ModelBase.STATUS.inactive;
    });
  }

  getStatus() {
    this.conditionForFFRequestStatusCheck = false;
    this.targetReportIds = this.base.commerceContent._id;
    const querySelector = { compId: this.targetReportIds };
    this.serviceHelperService.commerceService.updateStatusForWithdrawRequest(querySelector).then((res: string) => {
      this.ffStatus = ModelBase.STATUS.inactive;
      this.ffConfig = this.ffConfig.copyWith({
        userCredit: this.ffConfig.userCredit + 1,
      });
      this.ffCanAccess = true;
      LogUtils.info('Updated successfully....', res);

      this.serviceHelperService.trackingService.report({
        page: BaseComponent.PAGE.detail,
        pageType: BaseComponent.PAGE_TYPE.member,
        type: peopleSearchProductKeys.factFinder,
        action: 'factFinderWithdrawRequest',
      });
    });
  }

  checkRequestStatus() {
    this.targetReportIds = [this.base.commerceContent._id];
    const querySelector = { ids: this.targetReportIds };
    this.getFFRequestshowStatus(querySelector).then((res: any) => {
      if (
        (res[res.length - 1]?.status === ModelBase.STATUS.inProgress 
        || res[res.length - 1]?.status === ModelBase.STATUS.done 
        || res[res.length - 1]?.status === ModelBase.STATUS.locked
        || res[res.length - 1]?.status === ModelBase.STATUS.requested)
      ) {
        this.getCurrentUserReport = Object.assign({}, res[res.length - 1].raws[0].data);

        let date = new Date(this.getCurrentUserReport.requestDate.slice(0, 20)); // some mock date
        let submitedDateInMilliseconds2 = date.getTime();
        const cuDate = new Date();
        let currentDateInMilliseconds = cuDate.getTime();
        let finalDate = currentDateInMilliseconds - submitedDateInMilliseconds2;
        let dateInMinute = this.ffConfig.getMilliseconds();

        if (finalDate < dateInMinute) {
          this.conditionForFFRequestStatusCheck = true;
          this.checkONWithdrawText = true;
        } else {
          this.conditionForFFRequestStatusCheck = true;
          this.checkONWithdrawText = false;
        }
      }
      LogUtils.info(res);
    });
  }

  isValidate() {
    let requestTypeLength = this.subRequestContent.requestType.length;
    let requestDetailsLength = this.subRequestContent.requestDetails.length;

    requestTypeLength == 0 ? (this.typeErrorMessage = true) : (this.typeErrorMessage = false);
    requestDetailsLength == 0 ? (this.detailErrorMessage = true) : (this.detailErrorMessage = false);

    if (requestTypeLength == 0 || requestDetailsLength == 0) {
      return false;
    }

    return true;
  }

  onDateFormatSet() {
    this.cDate = this.pDate.transform(Date.now(), 'dd-MM-yyyy HH:mm:ss');
  }

  trackingRequestModal() {
    this.serviceHelperService.trackingService.report({
      page: BaseComponent.PAGE.detail,
      pageType: BaseComponent.PAGE_TYPE.member,
      type: peopleSearchProductKeys.factFinder,
      action: 'factFinderViewRequestModal',
    });
  }

  private getFFRequestshowStatus(querySelector) {
    return this.serviceHelperService.commerceService.getFFRequestShowStatusById(querySelector);
  }

  private async setFFConfig(): Promise<void> {
    let orders;

    try {
      orders = await this.serviceHelperService.peopleSearchService.hasOrder(
        [FactFinderUtils.productInfix],
      );
    } catch (e) {
      orders = [];
    }

    this.ffConfig = await FactFinderUtils.getConfigFromCommerceOrder(
      this.uxHelper.uxComposite,
      orders,
    );

    this.ffCanAccess = this.ffConfig.isValid() && commerceUtils.canProcessSomeCommerceOrders(
      orders, 
      FactFinderUtils.productContentType,
    );
  }
}

@NgModule({
  declarations: [FactFindersComponent],
  imports: [CommonModule, FormsModule],
  exports: [FactFindersComponent],
})
export class FactFindersComponentModule {}
