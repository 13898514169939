import { Component, Input, NgModule, OnInit } from '@angular/core';
import { UxHelper } from '../../clientCommon/helper/uxHelper';
import { clientPaths } from '../../common/helpers/pathHelpers';
import { BaseComponent } from '../../clientCommon/components/BaseComponent';
import { customClientPaths } from '../../common/custom/customPathHelpers';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'dev-message',
    templateUrl: 'devMessage.component.html',
    standalone: false
})
export class DevMessageComponent implements OnInit {
  @Input() uxHelper: UxHelper;
  clientPaths = clientPaths;
  customClientPaths = customClientPaths;

  PAGE_TYPE = BaseComponent.PAGE_TYPE;
  PAGE_CATOGORY = BaseComponent.PAGE_CATOGORY;
  PAGE = BaseComponent.PAGE;

  constructor() {}

  ngOnInit() {}

  matchPrefix(pageType, pageCategory, page) {
    let regex = new RegExp(`${pageType}\.${pageCategory}\.${page}`);
    return regex.test(this.uxHelper.prefix);
  }
}

@NgModule({
  declarations: [DevMessageComponent],
  imports: [CommonModule],
  exports: [DevMessageComponent],
})
export class DevMessageComponentModule {}
