import {PropertyDecoratorHelper} from "../helpers/propertyDecoratorHelper";
import {collectionClassHelper} from "./collectionClass";
import {LogUtils} from "../../utils/logUtils";
export var compositeClassFieldHelper = new PropertyDecoratorHelper();

/**
 * Ensure adding index. This decorator must be used with CollectionClass decorator
 */
export function CompositeClassField(value) {
    return function (target: any, key: string) {
        compositeClassFieldHelper.addProperties(target, key, value);
    };
}

