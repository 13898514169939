import {isObject} from "util";

export class ClassDecoratorHelper {
    static id = 1;
    private classes: any = {};
    private static prototypes = [];

    public getId(target: any): number {
        let index = -1;
        let prototype = null;

        if (isObject(target)) {

            // check class instance case
            if (index === -1) {
                prototype = Object.getPrototypeOf(target);
                index = ClassDecoratorHelper.prototypes.indexOf(Object.getPrototypeOf(target));
            }

            // Property Decorator's target
            if (index === -1) {
                prototype = target;
                index = ClassDecoratorHelper.prototypes.indexOf(prototype);
            }

        } else if(typeof target == 'function') {
            // Class Decorator's target
            prototype = Object.getPrototypeOf(new target);
            index = ClassDecoratorHelper.prototypes.indexOf(prototype);
        }

        if (index === -1) {
            ClassDecoratorHelper.prototypes.push(prototype);
            index = ClassDecoratorHelper.prototypes.indexOf(prototype);
        }

        return index;
    }

    public addClass(target: any, value?: any) {
        let index = this.getId(target);
        let prototype = Object.getPrototypeOf(new target);
        this.classes[index] = {value: value, target: prototype, className: target.name};
    }

    public getClass(target) {
        let index = this.getId(target);
        if (index === -1 && isObject(target)) {
            let prototype = Object.getPrototypeOf(target);
            index = this.getId(prototype);
        }

        return this.classes[index];
    }

    public getCollectionName(target) {
        let classInfo = this.getClass(target);
        return classInfo.value.name;
    }

    public getClassByKeyValue(key, value) {
        var info = null;
        Object.keys(this.classes).some((id) => {
            if (this.classes[id].value[key] === value) {
                info = this.classes[id];
                return true;
            }
        });

        return info;
    }

    public getAllClasses() {
        return this.classes;
    }

}

