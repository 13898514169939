import {
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  EventEmitter,
  Input,
  NgModule,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceHelperService } from '../../../../../../clientCommon/services/serviceHelper.service';
import { PersonDetail } from '../../../personDetail';
import { ToastrService } from '../../../../../service/toastr.service';
import { landingPeopleSearchMemberUtils } from '../../../../peopleSearch/landingPeopleSearchMemberUtils';
import { BaseComponent } from '../../../../../../clientCommon/components/BaseComponent';
import { peopleSearchProductKeys } from '../../../../../../common/custom/peopleSearch/peopleSearchProductKeys';
import { AuthenticationService, NoteService } from '../../../../../../clientCommon/services';
import { CommonModule } from '@angular/common';
import { MiniReportHeaderModule } from '@member/peopleSearch/components/mini/header/header.component';
import { MiniButtonComponentModule } from '@member/peopleSearch/components/mini/button/button.component';
import { MemberUpsellGlobalComponentModule } from '@member/components/upsells/global/upsell-global.component';
import { MiniReportNotesComponentModule } from '@member/peopleSearch/components/mini/notes/notes.component';
import { MiniReportFooterModule } from '@member/peopleSearch/components/mini/footer/footer.component';
import { AdComponentModule } from 'app/components/ad/ad.component';
import { MiniReportAdProtectionComponentModule } from '@member/peopleSearch/components/mini/ad/ad-protection.component';

declare var $: any;

@Component({
  selector: 'app-mini-family',
  templateUrl: 'miniFamily.component.html',
  styleUrls: ['miniFamily.component.scss'],
})
export class MiniFamilyComponent extends PersonDetail implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  href = '';
  showAllRelatives = false;
  @Input() isPdfPrintView: boolean = false;
  @Output() showLoader = new EventEmitter<void>();
  searchUtils = landingPeopleSearchMemberUtils;
  selectedPhotos = [];
  swiperIndex = 0;
  @ViewChild('scrollToTop', { static: true })
  scrollToTopBtnElement: ElementRef;

  relativesList = [];
  mockRelativesList = [
    {
      fName: 'Jack',
      lName: 'Smith',
      relation: 'Father',
      gender: 'Male',
      age: '42',
      address: 'Pembroke Pines, FL',
      imgUrl: 'assets/ux1/search_flow/person-man.png',
    },
    {
      fName: 'Gwen',
      lName: 'Smith',
      relation: 'Mother',
      gender: 'Female',
      age: '36',
      address: 'Pembroke Pines, FL',
      imgUrl: 'assets/ux1/search_flow/person-woman.png',
    },
    {
      fName: 'Jill',
      lName: 'Smith',
      relation: 'Sister',
      gender: 'Female',
      age: '16',
      address: 'Pembroke Pines, FL',
      imgUrl: '',
    },
    {
      fName: 'Will',
      lName: 'Smith',
      relation: '',
      gender: 'Male',
      age: '31',
      address: 'Pembroke Pines, FL',
      imgUrl: '',
    },
    {
      fName: 'Jennifer',
      lName: "O'neil",
      relation: '',
      gender: 'Female',
      age: '53',
      address: 'Vintage Wines, CR',
      imgUrl: '',
    },
    {
      fName: 'Trisha',
      lName: 'Smith',
      relation: '',
      gender: 'Female',
      age: '41',
      address: 'New Heights, TX',
      imgUrl: '',
    },
    {
      fName: 'Matthew Perry',
      lName: 'Smith',
      relation: 'Husband',
      gender: 'Male',
      age: '36',
      address: 'New York, NY',
      imgUrl: '',
    },
  ];

  constructor(
    public serviceHelperService: ServiceHelperService,
    public changeDetectorRef: ChangeDetectorRef,
    activatedRoute: ActivatedRoute,
    private router: Router,
    public renderer: Renderer2,
    public toastr: ToastrService,
    public noteService: NoteService,
    public authService: AuthenticationService
  ) {
    super(serviceHelperService, activatedRoute, toastr, noteService, authService);
  }

  ngOnInit() {
    this.showAllRelatives = this.isPdfPrintView;
    this.href = this.router.url;
    this.relativesList = [...this.person.relatives];

    return this.init().then(() => {
      this.serviceHelperService.trackingService.report({
        page: BaseComponent.PAGE.detail,
        pageType: BaseComponent.PAGE_TYPE.member,
        type: peopleSearchProductKeys.nameSearch,
        section: 'family',
        action: 'view',
      });
      this.changeDetectorRef.detectChanges();
    });
  }
  ngAfterViewInit() {
    // Register scroll to top event listener.
    const screenHeight = window.screen.availHeight;
    window.onscroll = () => {
      if (document.body.scrollTop > screenHeight || document.documentElement.scrollTop > screenHeight) {
        this.renderer.setStyle(this.scrollToTopBtnElement.nativeElement, 'display', 'flex');
      } else {
        this.renderer.setStyle(this.scrollToTopBtnElement.nativeElement, 'display', 'none');
      }
    };
  }
  scrollToTopHandler() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
  ngOnDestroy() {
    return super.onDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  init() {
    // Tooltip init
    setTimeout(() => {
      $('.tooltip-container').tooltip({
        html: true,
        trigger: 'click',
      });
    }, 0);
    return super.init();
  }

  switchShowAllRelative() {
    this.showAllRelatives = !this.showAllRelatives;
  }
  cancelModal(index) {
    $('#modal-' + index).modal('hide');
  }
  handleLoader() {
    this.showLoader.emit();
  }
  viewReport(url) {
    this.router.navigate([url]);
  }

  openPhotoModal(photos) {
    this.selectedPhotos = photos;
    this.swiperIndex = 0;
    if (this.selectedPhotos?.length < 2) {
      return;
    }
    this.serviceHelperService.trackingService.report({
      page: BaseComponent.PAGE.detail,
      pageType: BaseComponent.PAGE_TYPE.member,
      type: peopleSearchProductKeys.nameSearch,
      section: 'family',
      action: 'view_more_images',
    });
    $('#personPhotoModal').modal({
      backdrop: 'static',
    });
  }

  moveStep(step) {
    if (step < 0) {
      if (this.swiperIndex === 0) {
        this.swiperIndex = this.selectedPhotos.length - 1;
      } else {
        this.swiperIndex--;
      }
    } else {
      if (this.swiperIndex === this.selectedPhotos.length - 1) {
        this.swiperIndex = 0;
      } else {
        this.swiperIndex++;
      }
    }
  }
}

@NgModule({
  declarations: [MiniFamilyComponent],
  imports: [
    CommonModule,

    MiniReportHeaderModule,
    MiniButtonComponentModule,
    MemberUpsellGlobalComponentModule,
    MiniReportNotesComponentModule,
    MiniReportFooterModule,
    AdComponentModule,
    MiniReportAdProtectionComponentModule,
  ],
  exports: [MiniFamilyComponent],
})
export class MiniFamilyComponentModule {}
