import { Injectable } from '@angular/core';

type ToastrType = 'success' | 'error';

type Toastr = {
  message: string;
  title: string;
  type: ToastrType;
  id: number;
}

@Injectable({
  providedIn: 'root'
})
export class ToastrService {

  public toastrs: Toastr[] = [];
  defaultTimeout = 2000;

  constructor() { }

  error(message, title, timeOut?: number) {
    const newId = new Date().getTime();
    this.toastrs.push({
      type: 'error',
      id: newId,
      message,
      title,
    });

    setTimeout(() => {
      const index = this.toastrs.findIndex((t) => t.id === newId);
      if (index > -1) {
        this.toastrs.splice(index, 1);
      }
    }, timeOut || this.defaultTimeout)
  }

  success(message, title, timeOut?: number) {
    const newId = new Date().getTime();
    this.toastrs.push({
      type: 'success',
      id: newId,
      message,
      title,
    });

    setTimeout(() => {
      const index = this.toastrs.findIndex((t) => t.id === newId);
      if (index > -1) {
        this.toastrs.splice(index, 1);
      }
    }, timeOut || this.defaultTimeout)
  }

  public close(toast: Toastr) {
    const index = this.toastrs.findIndex((t) => t.id === toast.id);
      if (index > -1) {
        this.toastrs.splice(index, 1);
      }
  }
}
