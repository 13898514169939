import {ApplicationRef, Injectable, NgZone} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {DomSanitizer} from "@angular/platform-browser";
import {UserInputService} from "../services/userInput.service";
import {UserInfoService} from "../services/userInfo.service";
import {AlertService, AuthenticationService, CrudService, JsonService, ReferralService, UxcService} from "../services";
import {StorageService} from "../services/storage.service";
import {CommerceService} from "../services/commerce.service";
import {ReferService} from "../services/refer.service";
import {PageService} from "../services/page.service";
import {TrackingService} from "../services/tracking.service";
import {EmailService} from "../services/email.service";
import {SpinnerService} from "../services/spinner.service";
import {ProgressorService} from "../services/ProgressorService";
import {serviceCollection} from "../services/service.collection";
import {CustomServiceHelperService} from "../custom/services/customServiceHelper.service";
import {InputTrackingService} from "./InputTracking.service";
import { RemoveInfoService } from "../services/removeInfo.service";

import { EmailRecordService } from "./email-record.service";
import { BehaviorSubject, Subject } from "rxjs";
import { ActionRecommenderService } from "./actionRecommender.service";
import { CommerceOfferService } from "./commerceOffer.service";
import { LimitStorageService } from "./limitStorage.service";
import { ScriptLoaderService } from "./scriptLoader.service";

@Injectable()
//serves as parent helperservice that grants access to all other helper services
export class ServiceHelperService extends CustomServiceHelperService {
  public activatedRoute: ActivatedRoute;
  public searchMyself: boolean;
  public emailFilter$ = new Subject()
  public uxHistorySearchFilter$ = new Subject();
  public isFreestarAdEnabled$ = new BehaviorSubject(false);

  constructor(public customServiceHelperService: CustomServiceHelperService,
              public userInputService: UserInputService,
              public userInfoService: UserInfoService,
              public inputTrackingService: InputTrackingService,
              public jsonService: JsonService,
              public storageService: StorageService,
              public uxcService: UxcService,
              public commerceService: CommerceService,
              public commerceOfferService: CommerceOfferService,
              public authenticationService: AuthenticationService,
              public referService: ReferService,
              public pageService: PageService,
              public trackingService: TrackingService,
              public emailService: EmailService,
              public crudService: CrudService,
              public spinnerService: SpinnerService,
              public alertService: AlertService,
              public progressorService: ProgressorService,
              public location: Location,
              public applicationRef: ApplicationRef,
              public domSanitizer: DomSanitizer,
              public ngZone: NgZone,
              public emailRecordService:EmailRecordService,
              public router: Router,
              public actionRecommenderService: ActionRecommenderService,
              public limitStorageService: LimitStorageService,
              public scriptLoaderService: ScriptLoaderService,
              public removeInfoService: RemoveInfoService,
              public referralService: ReferralService,
) {
    super();
    this.setCustomServices(customServiceHelperService);

    serviceCollection.serviceHelperService = this;
  }
}
