import {Injectable} from "@angular/core";
import {parameterdUtils} from "../utils/parameterUtils";
import {UserInputService} from "./userInput.service";
import {UserInfoService} from "./userInfo.service";
import {AddressUtils} from "../../common/utils/addressUtils";

@Injectable()
export class ParameterService {
  STORAGE_NAME = 'refer';

  public storage: any = {};
  stateOptions = AddressUtils.getAddressField("US").getAdministrativeAreaOptionsArray();

  constructor(private userInputService: UserInputService,
              private userInfoService: UserInfoService) {
  }

  /**
   * procesing all the url param variables
   * storing all the params starting with `ui_` into the the userInput
   * storing all the params starting with `uf_` into the the userInfo
   */
  processParameter() {
    let params = parameterdUtils.getAllParams();
    this.processUiFullname(params);
    this.processUiFullnameAndState(params);


    let userInput = this.userInputService.getUserInput();
    let userInfo = this.userInfoService.getUserInfo();
    Object.keys(params).forEach((key) => {
      if (key.startsWith("ui_")) {
        userInput[key.substr(3)] = params[key];
      }

      if (key.startsWith("uf_")) {
        userInfo[key.substr(3)] = params[key];
      }
    });

    this.userInputService.storeUserInput();
  }

  /**
   * processing to format the name params staring with `ui_`
   * @param params to be processed
   */
  processUiFullname(params) {
    if (params && params.process_ui_fullname) {
      let fullname: string = params.process_ui_fullname;
      if (fullname) {
        fullname = fullname.replace(/\+/g, " ");
        let index = fullname.indexOf(" ");
        if (index > 0 && (index + 1) < fullname.length) {
          if ((!params.ui_fname) && (!params.ui_lname)) {
            params.ui_fname = fullname.substring(0, index).trim();
            params.ui_lname = fullname.substring(index + 1).trim();
          }
        }
      }
    }
  }

    /**
   * processing to format the state & fulname params staring with `ui_`
   * @param params to be processed
   */
  processUiFullnameAndState(params) {
    if (params && params.process_ui_fullnameState) {
      let fullnameState = params.process_ui_fullnameState.replace(/_/g, " ").trim();
      params.process_ui_fullname = fullnameState;

      this.stateOptions.some((option) => {
        let index = fullnameState.toLowerCase().indexOf(option.name.toLowerCase());
        if (index !== -1) {
          params.ui_state = option.code;
          params.process_ui_fullname = fullnameState.substring(0, index).replace(/ (in|of) /i, '');

          return true;
        }
      });

      this.processUiFullname(params);
    }
  }
}
