import {Injectable} from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {AlertService} from "../../clientCommon/services/alert.service";
import {LogUtils} from "../../common/utils/logUtils";
import {clientPaths} from "../../common/helpers/pathHelpers";
import {AuthenticationService} from "../../clientCommon/services/authentication.service";

@Injectable()
export class AuthGuard  {

  constructor(private router: Router,
              private authenticationService: AuthenticationService,
              private alertSerivce: AlertService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!!this.authenticationService.getUserId()) {
      // logged in so return true
      return true;
    } else {
      // not logged in so redirect to login page with the return url
      this.router.navigate(['/' + clientPaths.memberLogin], {queryParams: {returnUrl: state.url}});
      this.alertSerivce.error("Unauthorized");
      LogUtils.error("Unauthorized access");
      return false;
    }
  }
}
