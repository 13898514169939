import {
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  EventEmitter,
  Input,
  NgModule,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceHelperService } from '../../../../../../clientCommon/services/serviceHelper.service';
import { PersonDetail } from '../../../personDetail';
import { ToastrService } from '../../../../../service/toastr.service';
import { AuthenticationService, NoteService } from '../../../../../../clientCommon/services';
import { CommonModule } from '@angular/common';

declare var $: any;

@Component({
    selector: 'app-court-details',
    templateUrl: 'courtDetails.component.html',
    styleUrls: ['courtDetails.component.scss'],
    standalone: false
})
export class CourtDetailsComponent extends PersonDetail implements OnInit, OnDestroy, OnChanges {
  @Input() courtDetails: any;
  href = '';

  constructor(
    public serviceHelperService: ServiceHelperService,
    public changeDetectorRef: ChangeDetectorRef,
    activatedRoute: ActivatedRoute,
    private router: Router,
    public toastr: ToastrService,
    public authService: AuthenticationService,
    public noteService: NoteService
  ) {
    super(serviceHelperService, activatedRoute, toastr, noteService, authService);
  }

  ngOnInit() {
    this.href = this.router.url;
  }

  ngOnDestroy() {
    return super.onDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {}
}

@NgModule({
  declarations: [CourtDetailsComponent],
  imports: [CommonModule],
  exports: [CourtDetailsComponent],
})
export class CourtDetailsComponentModule {}
