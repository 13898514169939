import { ChangeDetectorRef, Component, Input, Output, EventEmitter, OnInit, NgModule } from '@angular/core';
import { BaseComponent } from '@clientCommon/components/BaseComponent';
import { ServiceHelperService } from '@clientCommon/services/serviceHelper.service';
import { SignUpPeopleSearch } from '@sales/peopleSearch/signUpPeopleSearchSales';
import { MembershipType, UxComp } from '@ux5.2/types';

@Component({
  selector: 'ux-5-2-membership-card',
  templateUrl: './membership-card.component.html',
  styleUrls: ['./membership-card.component.scss'],
})
export class MembershipCardComponent implements OnInit {
  @Input() selected: string = 'one_month';
  @Input() base: SignUpPeopleSearch;
  @Input() checkedOne: boolean = true;
  @Input() checkedTwo: boolean = false;
  @Input() flag: Number = 0;
  @Input() title: UxComp;
  @Output() newItemEvent = new EventEmitter<MembershipType>();
  uxComposite;
  offer;

  constructor(private _cdr: ChangeDetectorRef, private serviceHelperService: ServiceHelperService) {}

  ngOnInit(): void {
    this.uxComposite = this.base.uxHelper.uxComposite;
    this.firstClick();
  }

  public firstClick() {
    this.checkedOne = true;
    this.checkedTwo = false;
    this.selected = 'one_month';
    this.addNewItem('one_month');
    this.updateOffer('main1x');
  }

  public secondClick() {
    this.checkedOne = false;
    this.checkedTwo = true;
    this.selected = 'two_month';
    this.addNewItem('two_month');
    this.updateOffer('main2x');
  }

  updateOffer(offerName: string): void {
    sessionStorage.setItem('membership-plan', offerName);
    this.offer = this.base.structuredCommerceOffers[offerName][0];
    const priceDetails = this.offer.tempClientSecured.prices?.[1]?.[0];
    this.uxComposite.setCode('offerPrice', priceDetails.amount);
    this.uxComposite.setCode('offerFrequency', priceDetails.periodString);
    this.uxComposite.setCode('billingDate', this.offer.tempClientSecured.billingDatesString[1]);
  }

  addNewItem(value: MembershipType) {
    this.newItemEvent.emit(value);

    const GET_THIN_MATCH_ENABLED: boolean =
      this.serviceHelperService?.storageService?.getSession('thinMatchEnabled') ?? false;
    const THIN_MATCH_TYPE: string = this.serviceHelperService?.storageService?.getSession('thinMatchType') ?? '';

    this.serviceHelperService.trackingService.report({
      page: BaseComponent.PAGE.signup,
      pageType: BaseComponent.PAGE_TYPE.sales,
      type: 'ux_5_2',
      customerSelect: this.selected,
      thinMatch: {
        enabled: GET_THIN_MATCH_ENABLED,
        thinMatchType: THIN_MATCH_TYPE,
      },
      commerceContentId: this?.base?.commerceContent?._id || '',
    });
  }
}
