import {CollectionClass} from "../decorators/database/collectionClass";
import {ModelBase} from "./modelBase";
import {Raw} from "./raw";
import {IndexField} from "../decorators/database/indexField";
import {ForeignKeyField} from "../decorators/database/foreignKeyField";
import {ReferenceField} from "../decorators/database/referenceField";
import {TransientField} from "../decorators/database/transientField";
import {ForeignObjectField} from "../decorators/database/foreignObjectField";
@CollectionClass({name: "emailRecords", revisionEnabled: true, draftEnabled: false})
export class EmailRecord extends ModelBase<EmailRecord> {

    from: string;
    fromName: string;
    to: string;
    toName: string;
    replyTo: string;
    replyToName: string;
    subject: string;
    date: Date;
    emailDate: Date;
    body: string;
    meta: any;
    uid: any;
    directory: string;
    assignedTo: string;
    customerId: string;   // to represent customer changed 

    @IndexField() @ReferenceField(Raw) @ForeignKeyField(Raw) rawId: string;
    @TransientField @ForeignObjectField("rawId") raw: Raw;
  
    @IndexField() @ReferenceField(Raw) @ForeignKeyField(Raw) rawIds: string[] = [];
    @TransientField @ForeignObjectField("rawIds") raws: Raw[] = [];
    @IndexField() public uniqueId: string;

    constructor(doc?, draftFlag?: boolean) {
        super(doc, draftFlag);
        this.init(doc, this.draftFlag);
    }

    createUniqueId(config, msgId) {
        let id = "";
        id += "|" + config.credentials.imaps[0].host;
        id += "|" + config.credentials.imaps[0].user;
        id += "|" + msgId;

        this.uniqueId = id;
    }

    getMainRaw(): Raw {
        let index = this.tempClientSecured.mainRawIndex ? this.tempClientSecured.mainRawIndex : 0;
        if (this.raws && this.raws.length) {
          return this.raws[index];
        }
    
        return null;
    }
}

