import {ModelBase} from "../modelBase";
import {CollectionClass} from "../../decorators/database/collectionClass";
import {CommerceStock, CommerceStockDetail} from "./commerceStock";
import {ForeignKeyField} from "../../decorators/database/foreignKeyField";
import {ReferenceField} from "../../decorators/database/referenceField";
import {TransientField} from "../../decorators/database/transientField";
import {ForeignObjectField} from "../../decorators/database/foreignObjectField";
import {IndexField} from "../../decorators/database/indexField";

export class CommerceProductDetail {
  commerceProductId: string;
  commerceProductRevisionId?: string;
  commerceStockDetails: CommerceStockDetail[] = [];
}

@CollectionClass({name: "commerceProducts"})
export class CommerceProduct extends ModelBase<CommerceProduct> {
  public static TYPE = {
    item: "item",
    adjustment: "adjustment",
  };

  @IndexField() name: string;
  description: string;
  contentType: string;

  @IndexField() @ReferenceField(CommerceStock) @ForeignKeyField(CommerceStock) commerceStockIds: string[] = [];
  @TransientField @ForeignObjectField("commerceStockIds") commerceStocks: CommerceStock[];
  commerceStockDetails: CommerceStockDetail[] = [];

  constructor(doc?, draftFlag?: boolean) {
    super(doc, draftFlag);
    this.init(doc, this.draftFlag);
  }

  getSecuredDoc() {
    let obj: CommerceProduct = super.getSecuredDoc();

    obj.name = this.name;
    obj.contentType = this.contentType;
    obj.commerceStockIds = this.commerceStockIds;
    obj.commerceStockDetails = this.commerceStockDetails;

    return obj;
  }

  getCommerceStock(commerceStockId: string): CommerceStock {
    let stock;

    this.commerceStocks.some((commerceStock) => {
      if (commerceStock._id === commerceStockId) {
        stock = commerceStock;
        return true;
      }
    });

    return stock;
  }

  getCommerceQuantity(commerceStockId: string): number {
    let quantity;

    this.commerceStockDetails.some((commerceStockDetail) => {
      if (commerceStockDetail.commerceStockId === commerceStockId) {
        quantity = commerceStockDetail.quantity;
        return true;
      }
    });

    return quantity;
  }
}
