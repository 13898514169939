import {Ux4Dot0LandingNameSearchComponent} from "./landing.component";
import {Ux4Dot0LoaderNameSearchComponent} from "./loader.component";
import {Ux4Dot0SearchResultNameSearchComponent} from "./searchResult.component";
import {Ux4Dot0SignupNameSearchComponent} from "./signup.component";
import {Ux4Dot0ReportReviewNameSearchComponent} from "./reportReview.component";

export const NAME_SEARCH_PEOPLE_SEARCH_UX_4_0_THEME_DECLARATIONS = [
  Ux4Dot0LandingNameSearchComponent,
  Ux4Dot0LoaderNameSearchComponent,
  Ux4Dot0SearchResultNameSearchComponent,
  Ux4Dot0ReportReviewNameSearchComponent,
  Ux4Dot0SignupNameSearchComponent,
];
