import {IndexField} from "../../decorators/database/indexField";
import {ModelBase} from "../modelBase";
import {CollectionClass} from "../../decorators/database/collectionClass";

@CollectionClass({name: "managedMails", revisionEnabled: false})
export class ManagedMail extends ModelBase<ManagedMail> {
  public static SUB_STATUS = {
    none: "none",
    unsubscribed: "unsubscribed",
  };

  public static ACTIONS = {
    sent: "sent",
    ignored: "ignored",
    opened: "opened",
    clicked: "clicked",
    unsubscribed: "unsubscribed",
    bounced: "bounced",
    error: "error",
  };

  @IndexField({unique: true}) public uniqueId: string; // Brand id + email. This is for unique-check
  @IndexField() public email: string;
  @IndexField() declare public subStatus: string;
  @IndexField() public hash: string;
  @IndexField() dueTimestamp: number;
  @IndexField() optin: boolean;
  @IndexField() sessionhash: string;
  optinUrl: string;

  uxcId: string;
  uxlId: string;

  meta: any = {};

  campaigns: Campaign[] = [];

  tracking: { hash: string, action: string, timestamp: number, meta }[] = [];


  constructor(doc?, draftFlag?: boolean) {
    super(doc, draftFlag);
    this.init(doc, this.draftFlag);
  }

  getSecuredDoc(): ManagedMail {
    var obj: ManagedMail = super.getSecuredDoc();

    obj.email = this.email;
    obj.meta = this.meta;

    return obj;
  }

  isUnsubscribed() {
    return this.subStatus === ManagedMail.SUB_STATUS.unsubscribed;
  }

  createUniqueId() {
    return `${this.brandId}|${this.email}`.toLowerCase();
  }

  hasCampaign(key) {
    return this.getCampaignsByKey(key).length > 0;
  }

  getCampaignsByKey(key) {
    var campaigns = [];
    this.campaigns.forEach((campaign) => {
      if (campaign.key === key) {
        campaigns.push(campaign);
      }
    });

    return campaigns;
  }

  getCampaignsByHash(hash) {
    let campaigns = []; // In case of dup hash. returning all campaigns with same hash
    this.campaigns.forEach((campaign) => {
      if (campaign.hash === hash) {
        campaigns.push(campaign);
      }
    });

    return campaigns;
  }

  addTracking(hash, action, meta) {
    let timestamp = new Date().getTime();
    this.tracking.push({hash: hash, action: action, timestamp: timestamp, meta: meta});
  }

  setMinDueTimestamp() {
    this.dueTimestamp = null;
    if (this.campaigns) {
      this.campaigns.forEach((campaign) => {
        if (campaign && campaign.dueTimestamp && (!campaign.done)) {
          if ((!this.dueTimestamp) || this.dueTimestamp > campaign.dueTimestamp) {
            this.dueTimestamp = campaign.dueTimestamp;
          }
        }
      });
    }
  }

  getDueCampaigns(): Campaign[] {
    var timestamp = new Date().getTime();
    var dueCampaigns = [];
    this.campaigns.forEach((campaign) => {
      if (campaign && campaign.done != true && campaign.dueTimestamp && campaign.dueTimestamp < timestamp) {
        dueCampaigns.push(campaign);
      }
    });

    return dueCampaigns;
  }

  setDoneCampaign(hash) {
    var campaigns = this.getCampaignsByHash(hash);
    campaigns.forEach((campaign) => {
      campaign.done = true;
    });
  }

  setAdditionalToCampaign(hash, data) {
    var campaigns = this.getCampaignsByHash(hash);
    campaigns.forEach((campaign) => {
      Object.keys(data).forEach((key) => {
        campaign[key] = data[key];
      });
    });
  }

  getLastEngageTracking() {
    let lastTracking;
    if (this.tracking) {
      this.tracking.forEach((tracking) => {
        if (tracking.action === ManagedMail.ACTIONS.clicked || tracking.action === ManagedMail.ACTIONS.opened) {
          if ((!lastTracking) || lastTracking.timestamp < tracking.timestamp) {
            lastTracking = tracking;
          }
        }
      });
    }

    return lastTracking;
  }

  getLastEngageTimestamp() {
    let timestamp = 0;
    let lastTracking = this.getLastEngageTracking();

    if (lastTracking) {
      timestamp = lastTracking.timestamp;
    }

    return timestamp;
  }

  getNonEngageSeriesCount() {
    let lastEnaggeTimestamp = this.getLastEngageTimestamp();
    let count = 0;

    if (this.tracking) {
      this.tracking.forEach((tracking) => {
        if (tracking.action === ManagedMail.ACTIONS.sent) {
          if (lastEnaggeTimestamp < tracking.timestamp) {
            count += 1;
          }
        }
      });
    }

    return count;
  }

  isValidToSendCampaign(transactional: boolean,
                        rules?: {
                          domain,
                          priority: number,
                          engage: {
                            series: number
                          }
                        }[]) {
    let flag = true;
    if (rules) {
      let domain = this.email.split("@")[1];
      let nonEngageSeriesCount = this.getNonEngageSeriesCount();

      rules.sort((a, b) => {
        return b.priority - a.priority;
      });

      rules.forEach((rule) => {
        let regex = new RegExp(rule.domain, "i");

        if (regex.test(domain)) {
          if (rule.engage && rule.engage.series < nonEngageSeriesCount) {
            flag = false;
          }
        }
      });
    }

    return this.status === ModelBase.STATUS.active &&
      (transactional || (this.subStatus !== ManagedMail.SUB_STATUS.unsubscribed && flag));

  }

  reflectExtraInfo(extraInfo) {
    // To be secure explicit extra info copy used
    if (typeof extraInfo === "object") {
      if (typeof extraInfo.optin === "boolean") {
        this.optin = extraInfo.optin;
        this.optinUrl = extraInfo.optinUrl;
      }
    }
  }

  getTrackings() {
    let campaigns: {
      [hash: string]: {
        key: string,
        name: string,
        hooks: any[],
      },
    } = {};
    this.campaigns.forEach((campaign) => {
      campaigns[campaign.hash] = {key: campaign.key, name: campaign.name, hooks: campaign.hooks};
    });

    let trackings: {
      [hash: string]: {
        keyName: string,
        hooks: any,
        actions: {
          [name: string]: { timestamp: number }[],
        }
      },
    } = {};

    this.tracking.forEach((tracking) => {
      let campaign = campaigns[tracking.hash];
      let keyName = campaign.key + "." + campaign.name;
      if (!trackings[tracking.hash]) {
        trackings[tracking.hash] = {
          keyName: keyName,
          hooks: campaign.hooks,
          actions: {},
        };
      }
      if (!trackings[tracking.hash].actions[tracking.action]) {
        trackings[tracking.hash].actions[tracking.action] = [];
      }
      trackings[tracking.hash].actions[tracking.action].push({
        timestamp: tracking.timestamp,
      });
    });

    return trackings;
  }
}

export interface Campaign {
  hash: string,
  key: string,
  name: string,
  createdTimestamp: number,
  dueTimestamp: number,
  externalId?: string,
  transactional: boolean,
  done?: boolean,
  uxcId?: string,
  uxlId?: string,
  hooks?: { category: string, param: any }[],
}
