import {Component, Input, OnChanges, OnInit} from "@angular/core";
import {UxHelper} from "../../../../../clientCommon/helper/uxHelper";
import {LoaderPeopleSearch} from "../../../../peopleSearch/loaderPeopleSearch";

@Component({
  selector: 'ux-3-0-loader-name-search',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class Ux3Dot0LoaderNameSearchComponent implements OnInit, OnChanges {
  @Input() base: LoaderPeopleSearch;
  @Input() uxHelper: UxHelper;

  submitted = false;

  constructor() {
  }

  init() {
  }

  ngOnInit() {
    return this.init();
  }

  ngOnChanges() {
    return this.init();
  }

  search() {
    this.submitted = true;
    if (this.base.isValidCityState())
      this.base.reSearch({});
  }
}
