import { Component, Input, OnInit } from "@angular/core";
import { UxHelper } from "../../../clientCommon/helper/uxHelper";
import { ServiceHelperService } from "../../../clientCommon/services/serviceHelper.service";
import { ActivatedRoute } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { ScriptModel } from "@clientCommon/services/scriptLoader.service";
import { commerceUtils } from "@common/utils/commerceUtils";

declare var ThreeDS: any;

@Component({
    selector: 'three-ds',
    templateUrl: 'threeDS.component.html',
    standalone: false
})

export class ThreeDSComponent implements OnInit {

  @Input() base;
  @Input() uxHelper: UxHelper;
  amount;

  id = "paay-3ds-billing-form"; // Provide your form ID DOM element name
  url = "/assets/common/misc/threeds.2.2.20231219.min.js";

  scriptLoadPromise: Promise<ScriptModel>;
  sequences = [0, 1];

  constructor( private serviceHelperService: ServiceHelperService, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.addModalTracking("init");
    this.loadScript();
  }

  private loadScript() {
    this.addModalTracking("load");
    this.scriptLoadPromise = firstValueFrom(this.serviceHelperService.scriptLoaderService.loadSrc(this.url).pipe());
  }

  getEndPoint() {
    if(!this.uxHelper?.uxComposite.isDevServer()) {
      return "https://api.3dsintegrator.com/v2.2";
    } else {
      return "https://api-sandbox.3dsintegrator.com/v2.2";
    }
  }

  authorize(options) {
    this.addModalTracking("try");
    const endPoint = this.getEndPoint();
    this.amount = this.calculateAmount(options);
    return new Promise((fulfill, reject) => {
      this.scriptLoadPromise.then(() => {
        var tds = new ThreeDS( // Instantiate the ThreeDS class from the library
          this.id,
          this.uxHelper.getValue("comp.billing.paay3ds.key"),
          null, // Pass null to indicate the SDK to generate a JWT on our end, or pass your own
          {
            endpoint: endPoint,
            verbose: false, // Setting to true, will print all in the browser console to help debug during integration  
            autoSubmit: false,
            clientTransactionId: this.base.hash,
            challengeIndicator: "06",
          });
        tds.verify(
          fulfill,
          reject,
          {},
          null,
          null,
          fulfill,
          reject,
        );
      });
    }).then((r: any) => { 
      if(r) {
        r.meta = {
          sequences: this.sequences,
        }
      }
      this.addModalTracking("success");
      return r;
    }).catch((e) => { 
      this.addModalTracking("error");
      return Promise.reject(e);
    });
  }

  addModalTracking(action) {
      this.serviceHelperService.trackingService.report({type: "3ds", action: action});
  }

  calculateAmount(options) {

    let totalAmount = 0;
    let sequenceOptions = commerceUtils.createSequenceOptionsByThinMatchObject(options.requestParam.thinMatch);
    options?.requestParam?.offerRuleKeyDetails?.forEach((value) => {
      let keyedCommerceOffer = options?.structuredCommerceOffers?.[value?.key];
      keyedCommerceOffer?.forEach((commerceOffer) => {
        this.sequences?.forEach((sequence) => { 

          let price = commerceOffer?.commercePrice?.getTotalPrice(sequence, 0, sequenceOptions);
          if (price?.amount) {
            totalAmount += price?.amount;
          }
        });
      });
    });

    return totalAmount;
  }
}
