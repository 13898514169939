// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.landing-search-submit {
  white-space: nowrap;
}

.image {
  margin-top: 10px;
  width: 261px;
  height: 29px;
  max-width: 200%;
}

.div-block-2 {
  position: absolute;
  display: flex;
  width: 100%;
  margin-top: 0px;
  justify-content: right;
}
@media (max-width: 769px) {
  .div-block-2 {
    justify-content: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/themes/ux.2.0/peopleSearch/nameSearch/landing.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AACA;EACE,gBAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;AAEF;;AACA;EACE,kBAAA;EAGA,aAAA;EACA,WAAA;EACA,eAAA;EAEA,sBAAA;AADF;AAEE;EATF;IAUI,uBAAA;EACF;AACF","sourcesContent":[".landing-search-submit {\n  white-space: nowrap;\n}\n.image {\n  margin-top: 10px;\n  width: 261px;\n  height: 29px;\n  max-width: 200%;\n}\n\n.div-block-2 {\n  position: absolute;\n  // left: 0%;\n  // right: 0px;\n  display: flex;\n  width: 100%;\n  margin-top: 0px;\n  //padding-left: 17px;\n  justify-content: right;\n  @media (max-width: 769px) {\n    justify-content: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
