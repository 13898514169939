// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  opacity: 0.5;
  padding-top: 5em;
  padding-bottom: 7em;
  transition: all 0.5s ease;
  border-top: 1px solid #e1e1e1;
  height: auto !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.footer ul {
  list-style-type: none;
  padding: 0;
}

.footer ul li {
  margin-bottom: 10px;
}

.footer .secure-logos {
  margin: 20px 0;
}

.footer .secure-logos img {
  max-width: 80px;
  margin-right: 20px;
}

@media print {
  footer, .footer {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .footer {
    text-align: center;
    /* font-weight: bold; */
  }
  .footer li {
    font-weight: bold;
  }
  .search-text-befre {
    font-weight: 400;
  }

}

/* .footer ::ng-deep a {
  font-weight: bold;
} */
@media only screen and (max-width: 425px) {
  .footer {
    font-size : 11px;
    font-weight : 500;
    line-height: 16px;

    opacity: 0.5;
    padding-top: 4em;
    padding-bottom: 4em;
    transition: all 0.5s ease;
    border-top: 1px solid #e1e1e1;
    height: auto !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/sales/components/salesFooter.component.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,yBAAyB;EACzB,6BAA6B;EAC7B,uBAAuB;EACvB,wCAAwC;EACxC,2BAA2B;AAC7B;;AAEA;EACE,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE;IACE,wBAAwB;EAC1B;AACF;;AAEA;EACE;IACE,kBAAkB;IAClB,uBAAuB;EACzB;EACA;IACE,iBAAiB;EACnB;EACA;IACE,gBAAgB;EAClB;;AAEF;;AAEA;;GAEG;AACH;EACE;IACE,gBAAgB;IAChB,iBAAiB;IACjB,iBAAiB;;IAEjB,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;IACnB,yBAAyB;IACzB,6BAA6B;IAC7B,uBAAuB;IACvB,wCAAwC;IACxC,2BAA2B;EAC7B;AACF","sourcesContent":[".footer {\n  opacity: 0.5;\n  padding-top: 5em;\n  padding-bottom: 7em;\n  transition: all 0.5s ease;\n  border-top: 1px solid #e1e1e1;\n  height: auto !important;\n  background-color: transparent !important;\n  box-shadow: none !important;\n}\n\n.footer ul {\n  list-style-type: none;\n  padding: 0;\n}\n\n.footer ul li {\n  margin-bottom: 10px;\n}\n\n.footer .secure-logos {\n  margin: 20px 0;\n}\n\n.footer .secure-logos img {\n  max-width: 80px;\n  margin-right: 20px;\n}\n\n@media print {\n  footer, .footer {\n    display: none !important;\n  }\n}\n\n@media (max-width: 991px) {\n  .footer {\n    text-align: center;\n    /* font-weight: bold; */\n  }\n  .footer li {\n    font-weight: bold;\n  }\n  .search-text-befre {\n    font-weight: 400;\n  }\n\n}\n\n/* .footer ::ng-deep a {\n  font-weight: bold;\n} */\n@media only screen and (max-width: 425px) {\n  .footer {\n    font-size : 11px;\n    font-weight : 500;\n    line-height: 16px;\n\n    opacity: 0.5;\n    padding-top: 4em;\n    padding-bottom: 4em;\n    transition: all 0.5s ease;\n    border-top: 1px solid #e1e1e1;\n    height: auto !important;\n    background-color: transparent !important;\n    box-shadow: none !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
